import React, { useEffect, useState } from 'react';

import { Modal, StyledCheckbox, WrapFilterItem, WrapFilterList } from './styles';
import Row from 'components/atoms/Row';
import { Headline, SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

import { IcCheckSVG, IcClose48SVG, IcRefreshSVG } from 'assets/ic';

interface ParkingFilterBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ParkingFilterBottomModal: React.FC<ParkingFilterBottomModalProps> = ({
  isVisible,
  onClose,
}) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [selectedCarTypes, setSelectedCarTypes] = useState<string[]>([]);
  const [selectedTicketTypes, setSelectedTicketTypes] = useState<string[]>([]);
  const [selectedParkingTypes, setSelectedParkingTypes] = useState<string[]>([]);

  const handleChange = () => {
    setIsAvailable((prev: boolean) => !prev);
  };

  const carTypes = ['SUV', '경차', '승용차', '오토바이', '탑차', '마마마마'];
  const ticketTypes = ['평일용', '전일용'];
  const parkingTypes = ['자주식', '기계식'];

  const toggleSelection = (item: string, type: string) => {
    switch (type) {
      case 'car':
        setSelectedCarTypes(prev =>
          prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item],
        );
        break;
      case 'ticket':
        setSelectedTicketTypes(prev =>
          prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item],
        );
        break;
      case 'parking':
        setSelectedParkingTypes(prev =>
          prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item],
        );
        break;
      default:
        break;
    }
  };
  const isSelected = (item: string, type: string) => {
    switch (type) {
      case 'car':
        return selectedCarTypes.includes(item);
      case 'ticket':
        return selectedTicketTypes.includes(item);
      case 'parking':
        return selectedParkingTypes.includes(item);
      default:
        return false;
    }
  };

  const init = () => {
    setSelectedCarTypes([]);
    setSelectedTicketTypes([]);
    setSelectedParkingTypes([]);
    setIsAvailable(false);
  };

  let filterCount = 1000;
  let isDisabled = filterCount === 0 ? true : false;

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Row
          justifyContent={'space-between'}
          style={{
            margin: '0px 6px 0px 20px',
          }}
        >
          <Headline weight={'medium'} color={'black1'}>
            필터
          </Headline>
          <IcClose48SVG onClick={onClose} />
        </Row>

        <div
          style={{
            overflowY: 'auto',
          }}
        >
          <Row style={{ marginTop: 32, marginBottom: 10, marginLeft: 20, marginRight: 20 }}>
            <StyledCheckbox checked={isAvailable} onClick={handleChange}>
              <IcCheckSVG width={16} height={16} stroke={isAvailable ? '#fff' : '#E4E4E4'} />
            </StyledCheckbox>
            <SubHeadline weight={'semibold'} color={'black3'}>
              이용 가능 주차장만 보기
            </SubHeadline>
          </Row>
          <Divider style={{ backgroundColor: '#E4E4E4', marginTop: 20 }} />

          <WrapFilterList>
            <SubHeadline
              weight={'semibold'}
              color={'gray07'}
              style={{
                marginLeft: 20,
              }}
            >
              이용 차량
            </SubHeadline>
            <Row
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                marginTop: 10,
              }}
            >
              {carTypes.map((el, index) => {
                return (
                  <WrapFilterItem
                    key={index}
                    style={{
                      marginLeft: index === 0 ? 20 : 0,
                      borderColor: isSelected(el, 'car') ? '#8B63BB' : '#CCC',
                      backgroundColor: isSelected(el, 'car') ? '#EBEBFB' : 'transparent', // 선택된 아이템 배경 색
                      cursor: 'pointer',
                    }}
                    onClick={() => toggleSelection(el, 'car')}
                  >
                    <SubHeadline weight={'medium'} color={'gray09'}>
                      {el}
                    </SubHeadline>
                  </WrapFilterItem>
                );
              })}
            </Row>
          </WrapFilterList>

          <WrapFilterList>
            <SubHeadline
              weight={'semibold'}
              color={'gray07'}
              style={{
                marginLeft: 20,
              }}
            >
              주차권 유형
            </SubHeadline>
            <Row
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                marginTop: 10,
              }}
            >
              {ticketTypes.map((el, index) => {
                return (
                  <WrapFilterItem
                    key={index}
                    style={{
                      marginLeft: index === 0 ? 20 : 0,
                      borderColor: isSelected(el, 'ticket') ? '#8B63BB' : '#CCC',
                      backgroundColor: isSelected(el, 'ticket') ? '#EBEBFB' : 'transparent', // 선택된 아이템 배경 색
                      cursor: 'pointer', // 클릭 가능한 커서 스타일
                    }}
                    onClick={() => toggleSelection(el, 'ticket')}
                  >
                    <SubHeadline weight={'medium'} color={'gray09'}>
                      {el}
                    </SubHeadline>
                  </WrapFilterItem>
                );
              })}
            </Row>
          </WrapFilterList>

          <WrapFilterList>
            <SubHeadline
              weight={'semibold'}
              color={'gray07'}
              style={{
                marginLeft: 20,
              }}
            >
              주차장 유형
            </SubHeadline>
            <Row
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                marginTop: 10,
              }}
            >
              {parkingTypes.map((el, index) => {
                return (
                  <WrapFilterItem
                    key={index}
                    style={{
                      marginLeft: index === 0 ? 20 : 0,
                      borderColor: isSelected(el, 'parking') ? '#8B63BB' : '#CCC',
                      backgroundColor: isSelected(el, 'parking') ? '#EBEBFB' : 'transparent', // 선택된 아이템 배경 색
                      cursor: 'pointer', // 클릭 가능한 커서 스타일
                    }}
                    onClick={() => toggleSelection(el, 'parking')}
                  >
                    <SubHeadline weight={'medium'} color={'gray09'}>
                      {el}
                    </SubHeadline>
                  </WrapFilterItem>
                );
              })}
            </Row>
          </WrapFilterList>

          <Row
            style={{
              gap: 10,
              position: 'fixed',
              bottom: 40,
              margin: '0px 20px',
              width: 'calc(100% - 40px)',
            }}
          >
            <Button
              borderColor={colors.gray04}
              backgroundColor={colors.white}
              style={{
                height: 46,
                width: 100,
              }}
              onClick={() => {
                init();
              }}
            >
              <Row>
                <IcRefreshSVG style={{ marginRight: 4 }} />
                <SubHeadline weight={'medium'} color={'gray09'}>
                  초기화
                </SubHeadline>
              </Row>
            </Button>

            <Button
              borderWidth={0}
              backgroundColor={isDisabled ? colors.gray06 : colors.primary}
              style={{
                height: 46,
                flex: 1,
              }}
              disabled={isDisabled}
              onClick={onClose}
            >
              <Row>
                <SubHeadline weight={'medium'} color={'white'}>
                  주차장 {filterCount > 999 ? '999+' : filterCount}개 보기
                </SubHeadline>
              </Row>
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ParkingFilterBottomModal;
