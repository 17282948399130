import React, { useEffect, useRef, useState } from 'react';

import { Container, WrapItem } from './styles';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { dailyDetail } from 'containers/home/HomeMap/mock';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { formatPriceWithCommas } from 'utils/number';
import RoadView from 'components/atoms/RoadView';

import imgParkingForeground from 'assets/images/img_parking_foreground.png';
import imgParkingGate from 'assets/images/img_parking_gate.png';
import imgParkingInside1 from 'assets/images/img_parking_inside1.png';
import DetailPageFooter from 'components/atoms/DetailPageFooter';

interface Props {}

interface Image {
  src: string;
  alt: string;
}

const images: Image[] = [
  { src: imgParkingForeground, alt: '주차장 전경 이미지' },
  { src: imgParkingGate, alt: '주차장 게이트 이미지' },
  { src: imgParkingInside1, alt: '주차장 내부 이미지' },
];

const TimeTicketOperatingTime: React.FC<Props> = ({}) => {
  const { memberData } = userInfoStore;

  const data = dailyDetail;

  return (
    <Container>
      <SubHeadline weight={'bold'} color={'black1'}>
        운영시간
      </SubHeadline>

      <Row justifyContent={'space-between'} style={{ marginTop: 16 }}>
        <Caption1 weight={'regular'} color={'gray06'}>
          평일
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {data.weeklyOperTime}
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'regular'} color={'gray06'}>
          토요일
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {data.satOperTime}
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'regular'} color={'gray06'}>
          일요일
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {data.sunOperTime}
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'regular'} color={'gray06'}>
          공휴일
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {data.holidayOperTime}
        </Caption1>
      </Row>

      <RoadView
        // photoAvailableYN={photoAvailableYN ?? true}
        photoAvailableYN={true}
        images={images}
      />
    </Container>
  );
};

export default TimeTicketOperatingTime;
