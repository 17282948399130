import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import myParkingUseHistoryService from 'service/MyParking/myParkingUseHistoryService';
import { IMyParkingUseHistoryData } from 'models/MyParking/myParkingUseHistoryModel';

class MyParkingUseListStore {
  isLoading = false;
  error: string | null = null;
  parkingUseHistoryList: IMyParkingUseHistoryData[] = [];
  totalCount = 0;
  currentPage = 1; // 현재 페이지 번호
  itemsPerPage = 20; // 페이지당 아이템 수

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setParkingUseHistoryList(data: IMyParkingUseHistoryData[]) {
    this.parkingUseHistoryList = data;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setError(error: string) {
    this.error = error;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  async fetchGetMyParkingUseHistory(mLinkUID: number, page: number) {
    const requestBody = { mLinkUID };

    this.setLoading(true);

    const response = await myParkingUseHistoryService.getUseHistory(requestBody, page);

    if (response instanceof BaseError) {
      this.setError(response.errorMessage);
      this.setLoading(false);
      return false;
    } else {
      const { data, totalCount } = response;

      if (page === 1) {
        this.setParkingUseHistoryList(data);
      } else {
        this.setParkingUseHistoryList([...this.parkingUseHistoryList, ...data]);
      }
      this.setTotalCount(totalCount);
      this.setCurrentPage(page);
      this.setLoading(false);
      return true;
    }
  }
}

export const myParkingUseHistoryStore = new MyParkingUseListStore();
