import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MainContainer } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import CarInfoList from './CarInfoList';
import { Container, BottomContainer, CarNumberInput, CarNumberInputContainer, CarNumberErrorContainer } from './styles';
import Row from 'components/atoms/Row';
import { Caption1, Caption3, SubHeadline, Title3 } from 'styles/typography';
import { CheckBoxLabel, StyledCheckbox } from 'pages/AgreeTerms/styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import VehicleRegistrationBottomModal from 'components/atoms/Modal/VehicleRegistrationBottomModal';
import { carStore } from 'stores/store/Car/carStore';
import { CarInfoContentsText, CarInfoNotifyContainer } from 'pages/Car/CarInfo/CarInfoList/styles';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import icArrowRightGraySmall from 'assets/ic/ic_arrow_right_gray_small.svg';
import { Controller, useForm } from 'react-hook-form';
import LoadingSpinnerScreen from 'components/atoms/LoadingSpinner/LoadingSpinnerScreen';

interface IFormInputs {
  carNumber: string;
}

const CarInfo = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [checkboxes, setCheckboxes] = React.useState({
    serviceTerms: false,
    privacyPolicy: true,
    locationBased: false,
    marketing: false,
  });
  const [isCarNumberCheckBottomModal, setIsCarNumberCheckBottomModal] = useState(false);
  const [animatedIndex, setAnimatedIndex] = useState<number | null>(null);
  const [isCarDeleteSuccessPopup, setIsCarDeleteSuccessPopup] = useState(false);
  const [isLimitCarRegiPopup, setIsLimitCarRegiPopup] = useState(false);
  const { carList, setCarList } = carStore;
  const { memberUID } = userInfoStore;

  const handleLeftOnClick = () => {
    navigate('/myInfo');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (isCarDeleteSuccessPopup || isLimitCarRegiPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isCarDeleteSuccessPopup, isLimitCarRegiPopup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await carStore.fetchGetMemberCarInfo(memberUID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData().catch(error => {
      console.error('자동차 정보 불러오기 에러', error);
    });
  }, [isCarDeleteSuccessPopup]);

  const handleToggle = async (index: number) => {
    if (index === 0) return
    setAnimatedIndex(index)

    await carStore.fetchUpdateCarMain({
      mLinkUID: memberUID,
      carNumber: carList[index].carNumber,
    })

    setTimeout(() => {
      const mainCar = carList[index]
      const otherCars = carList.filter((_, i) => i !== index)

      const sortedList = [mainCar, ...otherCars].map((car, i) => ({
        ...car,
        isMain: i === 0 ? 'Y' : 'N',
      }));

      setCarList(sortedList)
      setAnimatedIndex(null)
    }, 300)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof checkboxes,
  ) => {
    const { checked } = event.target;
    setCheckboxes(prev => ({
      ...prev,
      [key]: checked,
    }));
  };

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      carNumber: sessionStorage.getItem('carNumber') || '',
    },
  });

  const carNumber = watch('carNumber', '');
  const carCount = carList.length;
  const isDisabled = !checkboxes.privacyPolicy || carNumber === '';
  const [isCarInputFocus, setIsCarInputFocus] = useState(false);

  useEffect(() => {
    const storedCarNumber = localStorage.getItem('myInfo');
    if (storedCarNumber) {
      setValue('carNumber', storedCarNumber);
    }
  }, [setValue]);

  useEffect(() => {
    if (carNumber) {
      localStorage.setItem('myInfo', carNumber);
    }
  }, [carNumber]);

  useEffect(() => {
    const fromPage = sessionStorage.getItem('myInfo');
    if (fromPage) {
      console.log('Came from:', fromPage);
      if (fromPage === '/myInfo') {
        setValue('carNumber', '');
        localStorage.removeItem('carNumber');
      }
    }
  }, []);

  useEffect(() => {
    const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
      carNumber,
    );

    if (carNumber && !isValidCarNumber) {
      setError('carNumber', { type: 'manual', message: '차량번호 형식이 일치하지 않습니다.' });
    } else if (carNumber && isValidCarNumber) {
      clearErrors('carNumber');
    } else {
      clearErrors('carNumber');
    }
  }, [carNumber, setError, clearErrors]);

  const handleFocus = () => {
    setIsCarInputFocus(true);
  };

  const handleBlur = () => {
    setIsCarInputFocus(false);
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('carNumber')?.focus();
    }, 300);
  }, []);

  if (carStore.isGetMyCarInfoLoading) {
    console.log('차 정보 호출')
    return <LoadingSpinnerScreen/>
  }

  return (
    <MainContainer>
      <Title
        title="차량 관리"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={!!carCount}
        rightTitle={`${carCount}/20`}
      />
      <Container>
        {carList.length ? (
          <div
            ref={scrollContainerRef}
            style={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            {carList.map((car, index) => (
              <CarInfoList
                key={index}
                car={car}
                animate={animatedIndex === index}
                onToggle={() => handleToggle(index)}
                setIsPopup={setIsCarDeleteSuccessPopup}
              />
            ))}

            <CarInfoNotifyContainer>
              <CarInfoContentsText>대표차량은 한대만 등록 가능합니다.</CarInfoContentsText>
              <CarInfoContentsText>
                주차장 이용 시 대표차량이 기본으로 선택 됩니다.
              </CarInfoContentsText>
            </CarInfoNotifyContainer>
            <div style={{ height: '100px' }}></div>
            <BottomContainer>
              <Button
                radius={4}
                backgroundColor={colors.primary}
                style={{
                  width: '100%',
                  height: '46px ',
                  marginBottom: 40,
                  marginTop: 16,
                  border: 'none',
                  fontWeight: 700,
                }}
                onClick={() => {
                  if (carList.length >= 20) {
                    setIsLimitCarRegiPopup(true);
                  } else {
                    sessionStorage.setItem('carInfo', location.pathname);
                    navigate('/carRegistration');
                  }
                }}
              >
                <SubHeadline weight={'semibold'} color={'white'}>
                  차량 등록
                </SubHeadline>
              </Button>
            </BottomContainer>
          </div>
        ) : (
          <>
            <Row>
              <Title3 weight={'bold'} color={'primary'}>
                차량번호
              </Title3>
              <Title3 weight={'bold'} color={'black3'}>
                를 입력해 주세요
              </Title3>
            </Row>
            <form>
              <CarNumberInputContainer isFocus={isCarInputFocus}>
                <Caption1 weight={'regular'} color={'gray08'}>
                  차량번호
                </Caption1>
                <Controller
                  name="carNumber"
                  control={control}
                  render={({ field }) => (
                    <CarNumberInput
                      type="text"
                      id="carNumber"
                      placeholder="예시 : 12가3456, 서울12가3456"
                      {...field}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={e => setValue('carNumber', e.target.value)}
                      isFocus={isCarInputFocus}
                      value={carNumber}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          (e.target as HTMLInputElement).blur();
                        }
                      }}
                    />
                  )}
                />
              </CarNumberInputContainer>
              <CarNumberErrorContainer>
                {carNumber && errors.carNumber ? (
                  <Caption3 weight={'regular'} color={'red'}>
                    {errors.carNumber.message}
                  </Caption3>
                ) : (
                  carNumber &&
                  !errors.carNumber && (
                    <Caption3 weight={'regular'} color={'blue'}>
                      올바른 차량번호 형식이에요.
                    </Caption3>
                  )
                )}
              </CarNumberErrorContainer>
            </form>

            <BottomContainer>
              <CheckBoxLabel
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledCheckbox
                    type="checkbox"
                    checked={checkboxes.privacyPolicy}
                    onChange={e => handleChange(e, 'privacyPolicy')}
                  />
                  <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                    (필수)
                  </SubHeadline>
                  <SubHeadline weight={'regular'} color={'black1'}>
                    개인정보 수집 및 이용동의
                  </SubHeadline>
                </div>
                <div
                  style={{ flexShrink: 0 }}
                  onClick={e => {
                    e.stopPropagation();
                    sessionStorage.removeItem('myInfo');
                    navigate('/policyPersonal');
                  }}
                >
                  <img src={icArrowRightGraySmall} alt="arrow.png" />
                </div>
              </CheckBoxLabel>
              <Button
                radius={4}
                backgroundColor={isDisabled ? colors.gray01 : colors.primary}
                style={{
                  width: '100%',
                  height: '46px ',
                  marginBottom: 40,
                  marginTop: 16,
                  border: 'none',
                }}
                onClick={() => {
                  setIsCarNumberCheckBottomModal(true);
                }}
                disabled={isDisabled}
              >
                <SubHeadline weight={'semibold'} color={isDisabled ? 'primary' : 'white'}>
                  다음
                </SubHeadline>
              </Button>
            </BottomContainer>
          </>
        )}
      </Container>

      <VehicleRegistrationBottomModal
        isVisible={isCarNumberCheckBottomModal}
        onClose={() => setIsCarNumberCheckBottomModal(false)}
        carNumber={carNumber}
        onClick={async () => {
          try {
            await carStore.fetchInsertMemberCarInfo({
              carNumber,
              agreementYN: 'Y',
              mLinkUID: memberUID,
            });

            navigate('/reductionCategory', {
              state: {
                type: 'post',
              },
            });
          } catch (error) {
            console.error('차량번호 불러오기 에러', error);
          }
        }}
      />

      <PopupModal
        isVisible={isCarDeleteSuccessPopup}
        onClose={() => setIsCarDeleteSuccessPopup(false)}
        title1={'차량 정보 삭제가 완료되었습니다'}
        leftClick={() => {
          setIsCarDeleteSuccessPopup(false);
          setTimeout(() => {
            document.getElementById('carNumber')?.focus();
          }, 300);
        }}
        leftText={'확인'}
      />

      <PopupModal
        isVisible={isLimitCarRegiPopup}
        onClose={() => setIsLimitCarRegiPopup(false)}
        title1={'차량 등록은 20개까지만 가능합니다'}
        leftClick={() => setIsLimitCarRegiPopup(false)}
        leftText={'확인'}
      />
    </MainContainer>
  );
});

export default CarInfo;
