import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';
import { IMyPageInfo, IMyPageResponse } from 'models/UserInfo/myPageInfoModel';

interface AuthApiEndpoint {
  GET_MY_PAGE_INFO: string;
}

class MyPageService extends BaseService<AuthApiEndpoint> {
  apiEndpoints: AuthApiEndpoint = {
    GET_MY_PAGE_INFO: 'getMyPageInfo',
  };

  constructor() {
    super();
  }

  getMyPageInfo = async (requestBody: IMyPageInfo): Promise<IMyPageResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()

        .data({ ...requestBody })
        .url(`${this.apiEndpoints.GET_MY_PAGE_INFO}`)
        .build<IMyPageResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new MyPageService();
