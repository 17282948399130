import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ButtonModify,
  ImgMyPage,
  ImgUserProfile,
  LogoutSpan,
  MyPageGreetingPhoneNumberContainer,
  MyPageSettingListContainer,
  MyPageUserInfoContainer,
  MyPageUserInfoPhotoContainer,
  MyPageUserInfoPhotoEllipseContainer,
} from './styles';
import { Caption2, SubHeadline, Title3 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import { myPageStore } from 'stores/store/UserInfo/myPageStore';
import { setRegularInfo } from 'utils/auth';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';

import icProfileUser from 'assets/ic/ic_profile_user.png';
import icProfile from 'assets/ic/ic_profile.svg';
import icLocation from 'assets/ic/ic_location.svg';
import icCar from 'assets/ic/ic_car.svg';
import icDotPurple from 'assets/ic/ic_dot_purple.svg';
import icSpeechBubble from 'assets/ic/ic_speech_bubble.svg';
import { moveJSP } from 'utils/common';

const MyPageList = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPhoneChangeModal, setIsPhoneChangeModal] = useState(false);

  const { memberData, phoneNumber, memberUID } = userInfoStore;
  const { myPageInfo } = myPageStore;
  const { fetchUserInfo } = useFetchUserInfo();

  const userInfoItems = [
    {
      textSetting: '휴대폰 번호',
      textUserInfo: phoneNumber ? phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') : '',
    },
  ];

  const fetchData = async () => {
    if (memberUID) {
      await myPageStore.fetchGetMyPageInfo(memberUID);
    }
  };

  useEffect(() => {
    fetchData();
  }, [memberData]);

  const onClosePhoneChangeModal = () => {
    setIsPhoneChangeModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <MyPageUserInfoContainer>
          <MyPageGreetingPhoneNumberContainer>
            <Caption2 weight={'regular'} color={'white'}>
              안녕하세요!
            </Caption2>
            <Row alignItems={'flex-end'}>
              <Title3
                weight={'bold'}
                color={'white'}
                style={{
                  fontWeight: 800,
                }}
              >
                {phoneNumber}
              </Title3>
              <Caption2
                weight={'regular'}
                color={'white'}
                style={{ marginBottom: 2, marginLeft: 4 }}
              >
                님
              </Caption2>
            </Row>
          </MyPageGreetingPhoneNumberContainer>
          <MyPageUserInfoPhotoContainer>
            <MyPageUserInfoPhotoEllipseContainer>
              <ImgUserProfile src={icProfileUser} alt={'프로필 타원 아이콘'} />
            </MyPageUserInfoPhotoEllipseContainer>
          </MyPageUserInfoPhotoContainer>
        </MyPageUserInfoContainer>
        <div>
          <div style={{ marginTop: 36 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icProfile} alt="프로필 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  프로필
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  setIsPhoneChangeModal(true);
                }}
              >
                수정
              </ButtonModify>
            </MyPageSettingListContainer>
            {userInfoItems.map((el, index) => {
              return (
                <Row justifyContent={'space-between'} style={{ marginTop: 10 }} key={index}>
                  <Row>
                    <img src={icDotPurple} alt={'점 아이콘'} style={{ marginRight: '8px' }} />
                    <Caption2 weight={'regular'} color={'gray06'}>
                      {el.textSetting}
                    </Caption2>
                  </Row>
                  <Caption2 weight={'regular'} color={'black1'}>
                    {el.textUserInfo}
                  </Caption2>
                </Row>
              );
            })}
          </div>
          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icLocation} alt="로케이션 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  월 주차 희망지역
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  navigate('/desiredAreaManagement', {
                    state: {
                      nextPage: '/myInfo',
                    },
                  });
                }}
              >
                관리
              </ButtonModify>
            </MyPageSettingListContainer>
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              {myPageInfo && myPageInfo.monthlyParkingDesiredAreas.length > 0 ? (
                <>
                  {myPageInfo.monthlyParkingDesiredAreas.length === 1 ? (
                    <Caption2 weight={'regular'} color={'black1'}>
                      {myPageInfo.monthlyParkingDesiredAreas[0]}
                    </Caption2>
                  ) : (
                    <>
                      <Caption2 weight={'regular'} color={'black1'}>
                        {myPageInfo.monthlyParkingDesiredAreas[0]}
                      </Caption2>
                      <Caption2 weight={'regular'} color={'black1'} style={{ marginLeft: 4 }}>
                        {'외 ' + (myPageInfo.monthlyParkingDesiredAreas.length - 1)}
                      </Caption2>
                    </>
                  )}
                </>
              ) : (
                <Caption2 weight={'regular'} color={'gray06'}>
                  월 주차 희망 지역이 없습니다.
                </Caption2>
              )}
            </Row>
          </div>
          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icSpeechBubble} alt="지갑 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  빈자리 신청 내역{' '}
                  {myPageInfo?.waitingListCnt === 0 || myPageInfo?.waitingListCnt === undefined
                    ? ''
                    : myPageInfo?.waitingListCnt > 10
                      ? 10
                      : myPageInfo?.waitingListCnt}
                </SubHeadline>
              </div>

              {/* 신청 내역이 있다면 보기 보여 주자 없다면 GONE 처리 */}
              {myPageInfo?.waitingListCnt !== 0 ? (
                <ButtonModify
                  onClick={() => {
                    navigate('/emptyParkingArea');
                  }}
                >
                  보기
                </ButtonModify>
              ) : null}
            </MyPageSettingListContainer>
            <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
              {myPageInfo?.waitingListCnt === 0 ? (
                <Caption2 weight={'regular'} color={'gray06'}>
                  빈자리 신청 내역이 없습니다
                </Caption2>
              ) : (
                <Caption2 weight={'regular'} color={'black1'}>
                  빈자리 생기면 알려드려요
                </Caption2>
              )}
            </Row>
          </div>

          <div style={{ marginTop: 40.5 }}>
            <MyPageSettingListContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImgMyPage src={icCar} alt="자동차 아이콘" />
                <SubHeadline weight={'regular'} color={'black1'}>
                  차량 관리
                </SubHeadline>
              </div>
              <ButtonModify
                onClick={() => {
                  sessionStorage.setItem('myInfo', location.pathname);
                  navigate('/carInfo');
                }}
              >
                {myPageInfo?.carNumber ? '수정' : '등록'}
              </ButtonModify>
            </MyPageSettingListContainer>

            {myPageInfo?.carNumber ? (
              <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
                <Row>
                  <img src={icDotPurple} alt={'점 아이콘'} style={{ marginRight: '8px' }} />
                  <Caption2 weight={'regular'} color={'gray06'}>
                    대표차량
                  </Caption2>
                </Row>
                <Row>
                  <Caption2 weight={'regular'} color={'black1'}>
                    {myPageInfo.carNumber}
                  </Caption2>

                  {myPageInfo.taxReductionInfo ? (
                    <Caption2
                      weight={'regular'}
                      color={'black1'}
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {' '}
                      / {myPageInfo.taxReductionInfo}
                    </Caption2>
                  ) : null}
                </Row>
              </Row>
            ) : (
              <Row justifyContent={'flex-end'} style={{ marginTop: 10 }}>
                <Caption2 weight={'regular'} color={'gray06'}>
                  등록된 차량이 없습니다
                </Caption2>
              </Row>
            )}
          </div>
        </div>
      </div>
      <LogoutSpan
        onClick={() => {
          setRegularInfo('nonMember', 'nonMember', '');
          fetchUserInfo();
          // navigate(-1);
          moveJSP();
        }}
      >
        로그아웃
      </LogoutSpan>

      <CustomBottomModal
        isVisible={isPhoneChangeModal}
        onClose={onClosePhoneChangeModal}
        title1={'휴대폰 번호를 변경하나요?'}
        description={'변경 시 변경한 휴대폰 번호로 본인인증을 해야 해요.'}
        leftClick={onClosePhoneChangeModal}
        leftText={'취소'}
        rightText={'번호 변경할래요'}
        rightClick={() => {
          navigate('/identityVerification');
        }}
      />
    </div>
  );
});

export default MyPageList;
