import { styled, globalCss } from '@stitches/react';
import { colors } from 'styles/colors';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ColorText = styled('div', {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'normal',
  color: colors.gray08,
  '& span': {
    color: colors.primary,
  },
});
