import React, { useState, useEffect } from 'react';

import { Modal } from 'components/atoms/Modal/FileSelectPopup/styles';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
// @ts-ignore
import { IcCameraSVG, IcFolderSVG, IcGallerySVG } from 'assets/ic';

interface FileSelectPopupProps {
  isVisible: boolean;
  onClose: () => void;
  handleImageChange: (evnet: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileSelectPopup: React.FC<FileSelectPopupProps> = ({
  isVisible,
  onClose,
  handleImageChange,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <div style={{ width: '100%' }}>
          <label htmlFor="albumInput">
            <div>
              <Row
                style={{
                  padding: '16px 24px',
                }}
                justifyContent={'space-between'}
              >
                <Caption2 weight={'regular'} color={'black1'}>
                  사진 보관함
                </Caption2>
                <IcGallerySVG />
              </Row>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="albumInput"
              />
            </div>
          </label>
          <Divider style={{ backgroundColor: '#D9D9D9' }} />
          <label htmlFor="cameraInput">
            <div>
              <Row
                style={{
                  padding: '16px 24px',
                }}
                justifyContent={'space-between'}
              >
                <Caption2 weight={'regular'} color={'black1'}>
                  사진 찍기
                </Caption2>
                <IcCameraSVG />
              </Row>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="cameraInput"
              />
            </div>
          </label>
          <label htmlFor="fileInput">
            <Divider style={{ backgroundColor: '#D9D9D9' }} />
            <div>
              <Row
                style={{
                  padding: '16px 24px',
                }}
                justifyContent={'space-between'}
              >
                <Caption2 weight={'regular'} color={'black1'}>
                  파일 선택
                </Caption2>
                <IcFolderSVG />
              </Row>
              <input
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="fileInput"
              />
            </div>
          </label>
        </div>
      </Modal>
    </>
  );
};

export default FileSelectPopup;
