import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CarReductionBottomModal,
  Description,
  Image,
  ParkingButtonContainer,
  ParkingButtonListContainer,
} from './styles';
import Alexander from 'assets/images/alexander.png';
import ParkImg from 'assets/images/img_park.png';
import { Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcClose48SVG, IcTooltipAlertSVG, IcTooltipHelpSVG } from 'assets/ic';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (selectedItem: string) => void;
  selectedItem?: string | null; // Allow null here
}

const SharedParkingBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  onConfirm,
  selectedItem,
}) => {
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const buttons = [
    {
      title: '부설 주차장',
      desc1: '빌라, 오피스텔, 상가 등',
      desc2: '일반 주차장',
      src: Alexander,
      onClick: () => navigate('/shareRequestMingan'),
    },
    {
      title: '거주자 우선 주차장',
      desc1: '거주자 우선주차 배정자가',
      desc2: '배정 받은 주차장',
      src: ParkImg,
      onClick: () => navigate('/shareRequestGeoju'),
    },
  ];

  const handleOverlayClick = () => {
    onClose();
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={handleOverlayClick}
      />
      <CarReductionBottomModal isVisible={isVisible}>
        <IcClose48SVG onClick={onClose} style={{ position: 'absolute', top: 10, right: 16 }} />

        <Headline weight={'medium'} color={'black1'} style={{ marginTop: 28 }}>
          어떤 주차장을 공유하시나요?
        </Headline>
        <ParkingButtonListContainer>
          {buttons.map((el, index) => {
            return (
              <ParkingButtonContainer key={index} isFirst={index === 0} onClick={el.onClick}>
                <Description>
                  <SubHeadline weight={'semibold'} color={'black3'}>
                    {el.title}
                  </SubHeadline>
                  <div>
                    <Caption2 weight={'regular'} color={'gray08'}>
                      {el.desc1}
                    </Caption2>
                    <Caption2 weight={'regular'} color={'gray08'}>
                      {el.desc2}
                    </Caption2>
                  </div>
                </Description>
                <Image src={el.src} alt={'부설 주차장'} />
              </ParkingButtonContainer>
            );
          })}
          <Row style={{ marginTop: 28, marginBottom: 40 }}>
            <IcTooltipAlertSVG fill={'#47474A'} />
            <Caption2 weight={'regular'} color={'gray09'} style={{ marginLeft: 4 }}>
              신청 시 내 연락처로 순차적 안내 드려요.
            </Caption2>
          </Row>
        </ParkingButtonListContainer>
      </CarReductionBottomModal>
    </>
  );
};

export default SharedParkingBottomModal;
