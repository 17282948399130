import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { IcNotRoadViewGraySVG, IcZoomansaSVG } from 'assets/ic';
import Title from 'components/atoms/Title/PageTitle';
import { Caption2 } from 'styles/typography';
import { NotRoadViewContainer, RoadViewContainer } from 'pages/KakaoRoadView/styles';

const KakaoRoadViewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { latitude, longitude } = location.state || {};
  const [position, setPosition] = useState({ lat: latitude, lng: longitude });
  const roadViewRef = useRef<HTMLDivElement | null>(null);
  const isPositionValid = Boolean(position.lat && position.lng);
  const handleBackPress = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  useEffect(() => {
    window.onBtnBackClicked = handleBackPress;
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (!isPositionValid) return;

    if (!window.kakao || !window.kakao.maps) {
      console.error('Kakao Maps not available');
      return;
    }

    if (!roadViewRef.current) {
      console.error('Roadview container not found');
      return;
    }

    const roadView = new window.kakao.maps.Roadview(roadViewRef.current);
    const roadViewClient = new window.kakao.maps.RoadviewClient();
    const latLng = new window.kakao.maps.LatLng(position.lat, position.lng);

    roadViewClient.getNearestPanoId(latLng, 50, (panoId: number) => {
      if (!panoId) {
        console.error('로드뷰 정보를 찾을 수 없습니다.');
        return;
      }
      roadView.setPanoId(panoId, latLng);
    });
  }, [isPositionValid, position]);

  return (
    <>
      <Title
        title={<IcZoomansaSVG />}
        leftArrowButtonVisible={true}
        rightTextButtonVisible={false}
        leftOnClick={handleBackPress}
      />

      {isPositionValid ? (
        <RoadViewContainer ref={roadViewRef} />
      ) : (
        <NotRoadViewContainer>
          <IcNotRoadViewGraySVG />
          <Caption2 weight={'medium'} color={'gray06'} style={{ marginTop: 12 }}>
            표시할 로드뷰 정보가 없습니다.
          </Caption2>
        </NotRoadViewContainer>
      )}
    </>
  );
};
export default KakaoRoadViewPage;