import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainContainer, MainContainerNoMargin } from 'components/styles';
import { StyledCheckbox, CheckBoxContainer, CheckBoxLabel, BottomContainer } from './styles';
import Title from 'components/atoms/Title/PageTitle';
import icArrowRightGraySmall from 'assets/ic/ic_arrow_right_gray_small.svg';
import { SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

const DailyAgreeTermsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const productUID = location.state?.productUID || queryParams.get('productUID');

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkboxes, setCheckBoxes] = useState({
    serviceTerms: false,
    privacyPolicy: false,
    locationBased: false,
  });

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsCheckedAll(isChecked);

    setCheckBoxes({
      serviceTerms: isChecked,
      privacyPolicy: isChecked,
      locationBased: isChecked,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof checkboxes,
  ) => {
    const { checked } = event.target;

    setCheckBoxes(prev => {
      const updatedCheckboxes = {
        ...prev,
        [key]: checked,
      };

      const allChecked = Object.values(updatedCheckboxes).every(value => value);
      setIsCheckedAll(allChecked);

      return updatedCheckboxes;
    });
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const isButtonEnabled =
    isCheckedAll ||
    [checkboxes.serviceTerms, checkboxes.privacyPolicy, checkboxes.locationBased].filter(Boolean)
      .length >= 3;

  // Button style
  const buttonStyle = {
    padding: '0px',
    border: '0px',
    width: '100%',
    marginRight: '16px',
    height: '46px',
    color: isButtonEnabled ? '#FFF' : '#8B63BB',
    backgroundColor: isButtonEnabled ? '#8B63BB' : '#F2F2F2',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '5px',
    cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
  };

  // Button click handler
  const handleButtonClick = () => {
    navigate(`/dailyIdentityVerification?productUID=${productUID}`);
  };

  return (
    <MainContainerNoMargin
      style={{
        margin: '0 auto',
      }}
    >
      <Title
        title="약관 동의"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <div
        style={{
          padding: '0px 20px',
        }}
      >
        <CheckBoxContainer>
          <CheckBoxLabel style={{ marginBottom: 0 }}>
            <StyledCheckbox type="checkbox" checked={isCheckedAll} onChange={handleAllChange} />

            <SubHeadline weight={'regular'} color={'black1'}>
              전체동의
            </SubHeadline>
          </CheckBoxLabel>
          <Divider style={{ backgroundColor: colors.gray01, marginTop: 15, marginBottom: -5 }} />
          <CheckBoxLabel
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.serviceTerms}
                onChange={e => handleChange(e, 'serviceTerms')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                (필수)
              </SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>
                주만사 서비스 이용약관
              </SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={e => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('주만사 서비스 이용약관 상세');
                navigate('/policyService');
              }}
            >
              <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.privacyPolicy}
                onChange={e => handleChange(e, 'privacyPolicy')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                (필수)
              </SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>
                개인정보 수집 및 이용동의
              </SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={e => {
                e.stopPropagation();
                navigate('/policyPersonal');
                console.log('개인정보 수집 및 이용동의 상세');
              }}
            >
              <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>

          <CheckBoxLabel
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledCheckbox
                type="checkbox"
                checked={checkboxes.locationBased}
                onChange={e => handleChange(e, 'locationBased')}
              />
              <SubHeadline weight={'regular'} color={'red'} style={{ marginRight: 4 }}>
                (필수)
              </SubHeadline>
              <SubHeadline weight={'regular'} color={'black1'}>
                위치기반 서비스
              </SubHeadline>
            </div>
            <div
              style={{ flexShrink: 0 }}
              onClick={e => {
                e.stopPropagation(); // Prevent the click event from propagating to the checkbox
                console.log('위치기반 서비스 상세');
                navigate('/policyLocation');
              }}
            >
              <img src={icArrowRightGraySmall} alt="arrow.png" />
            </div>
          </CheckBoxLabel>
        </CheckBoxContainer>

        <BottomContainer>
          <button style={buttonStyle} onClick={handleButtonClick} disabled={!isButtonEnabled}>
            다음
          </button>
        </BottomContainer>
      </div>
    </MainContainerNoMargin>
  );
};

export default DailyAgreeTermsPage;
