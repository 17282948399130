import BaseService from 'service/baseService';
import { IUserLogin, IUserLoginResponse } from 'models/Login/loginModel';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';

interface LoginEndPoints {
  USER_LOGIN: string;
}

class LoginService extends BaseService<LoginEndPoints> {
  apiEndpoints: LoginEndPoints = {
    USER_LOGIN: 'userLogin',
  };

  private handleErrorResponse(response: any): BaseError {
    const { res_code, err_msg } = response.data;

    if (!err_msg) {
      throw new Error('알 수 없는 오류 발생');
    }

    return new BaseError({
      status: res_code,
      error_msg: err_msg || '알 수 없는 오류 발생',
    });
  }

  postUserLogin = async (
    requestBody: IUserLogin,
    iv: string,
    salt: string,
  ): Promise<IUserLoginResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.USER_LOGIN}`)
        .build<IUserLoginResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return this.handleErrorResponse(response);
      }
    } catch (error) {
      console.error(error);
      const handledError = ErrorHandler.create(error).getError();
      alert(handledError.message);
      return handledError;
    }
  }
}

export const loginService = new LoginService()
