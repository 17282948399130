import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { Dialog, RoadFind, ViewDivide1, WrapRoadFind } from './styles';
import { SubHeadline } from 'styles/typography';
import DetailPageFooter from 'components/atoms/DetailPageFooter';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { openNavi } from 'utils/common';
import BasicInfo from 'containers/chargingStation/BasicInfo';
import OperationInfo from 'containers/chargingStation/OperationInfo';
import ParkingImage from 'containers/chargingStation/ParkingImage';
import ChargerList from 'containers/chargingStation/ChargerList';
import { chargerListData } from 'containers/home/HomeMap/mock';
import { MAP_CHARGER_TYPE } from 'components/atoms/Modal/ChargingInfoModal';

// Props 타입 정의
interface ChargeBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ChargingStationDetailModal: React.FC<ChargeBottomModalProps> = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const [chargerTypes, setChargerTypes] = useState<any>([]);

  const { memberData } = userInfoStore;

  const handleLeftOnClick = () => {
    onClose();
  };

  useEffect(() => {
    const uniqueChargerTypes = Array.from(
      new Set(
        chargerListData?.result.map(el => el.chgerType) || [], // 중복 제거 및 null 안전 처리
      ),
    ).map(type => MAP_CHARGER_TYPE.get(type));
    setChargerTypes(uniqueChargerTypes);
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  if (!isVisible) return null;

  return (
    <Dialog>
      <MainContainerNoMargin>
        <Title
          title="상세정보"
          leftArrowButtonVisible={true}
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={false}
        />
        <div style={{ height: 'auto' }}>
          <BasicInfo data={chargerListData} />
          <ViewDivide1 />
          <OperationInfo data={chargerListData} />
          <ParkingImage data={chargerListData} />

          <ViewDivide1 />
          <ChargerList data={chargerListData} />
        </div>
        <div
          style={{
            marginTop: 28,
            padding: '0px 28px',
            marginBottom: 120,
          }}
        >
          <DetailPageFooter />
        </div>
        <WrapRoadFind>
          <RoadFind
            onClick={() => {
              const name = '현대백화점 판교점';
              const lat = 37.508725;
              const lng = 126.891295;
              openNavi(String(lat), String(lng), name, memberData?.naviType ?? '');
            }}
          >
            <SubHeadline weight={'bold'} color={'white'} style={{}}>
              길 찾기
            </SubHeadline>
          </RoadFind>
        </WrapRoadFind>
      </MainContainerNoMargin>
    </Dialog>
  );
};

export default ChargingStationDetailModal;
