import { makeAutoObservable } from 'mobx';
import deleteAccountService from 'service/DeleteAccount/deleteAccountService';
import BaseError from 'models/baseError';

class DeleteAccountStore {
  isLoading: boolean = false;

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDeleteAccount(
    mLinkUID: number,
    deactivateCategory: string,
    deactivateReason?: string,
  ) {
    this.setIsLoading(true);

    const requestBody = {
      mLinkUID,
      deactivateCategory,
      deactivateReason,
    };

    try {
      const response = await deleteAccountService.postDeleteAccount(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response;
        } else {
          console.error('회원 탈퇴 인증 번호 검증 에러');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}
export const deleteAccountStore = new DeleteAccountStore();