import React from 'react';
import { CheckModalButton, CheckModalContainer, CheckModalTitleSpan, ModalOverlay } from './styles';
import { SubHeadline } from 'styles/typography';

interface CheckModalProps {
  isVisible: boolean
  onClose: () => void
  title: string
  content: React.ReactNode
  buttonText: string
}

const CheckModal: React.FC<CheckModalProps> = ({ isVisible, onClose, title, content, buttonText }) => {
  if (!isVisible) return null

  return (
      <ModalOverlay onClick={onClose}>
        <CheckModalContainer onClick={(e) => e.stopPropagation()}>
            <div style={{
              paddingTop: '27px',
              paddingBottom: content ? '18px' : '27px'
            }}
            >
              <SubHeadline weight={'bold'} color={'primaryDark'}>
                {title}
              </SubHeadline>
            </div>
            <div style={{
              paddingBottom: content ? '22px' : '0px'
            }}>{content}</div>
          <CheckModalButton onClick={onClose}>
            {buttonText}
          </CheckModalButton>
        </CheckModalContainer>
      </ModalOverlay>
  );
};

export default CheckModal;