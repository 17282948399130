import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import icCheckGradient from 'assets/ic/ic_check_gradient.svg';
import icExclamationDarkGray from 'assets/ic/ic_exclamation_dark_gray.svg';
import { CarParkingInfoContainer, ParkingInfoContainer, ViewDivide3 } from '../TerraceTower/styles';
import { moveMain, moveMainFromPayment, setShared } from 'utils/deviceUtils';

import {
  BottomContainer,
  CheckButton,
  PaymentCheckContainer, PaymentSuccessTopContainer,
  PaymentTopContainer, SharedContainer, SharedContentContainer,
  TicketButtonContainer,
} from './styles';
import { Caption1, LargeTitle3, SubHeadline } from 'styles/typography';
import { terraceTowerCompleteProductOrderStore } from 'stores/store/TerraceTower/terraceTowerCompleteProductOrderStore';
import { observer } from 'mobx-react';
import { ITerraceTowerProductOrderData } from 'models/TerraceTower/terraceTowerProductOrder';
import { MainContainerNoMargin } from 'components/styles';
import { IcShareSVG } from 'assets/ic';

const TerraceTowerPaymentResultPage = observer(() => {
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const getProductOrder = sessionStorage.getItem('getProductOrder') ?? '{}';

  const parsedProductOrder: ITerraceTowerProductOrderData = JSON.parse(getProductOrder);

  const ticketInfoList = [
    { label: '차량정보', value: parsedProductOrder.orderData?.carNum ?? '' },
    { label: '주차장명', value: parsedProductOrder.orderData?.lotName ?? '' },
    {
      label: '이용가능 시간',
      value: `구매 당일 ${parsedProductOrder.orderData?.operationTime ?? ''}`,
    },
    { label: '입차예정', value: parsedProductOrder.orderData?.expectedEntryTime ?? '' },
  ];

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const resultCode = searchParams.get('ResultCode');

    if (resultCode) {
      switch (resultCode) {
        case '3001':
          setIsSuccess(true);
          setResultMessage('결제가 성공적으로 완료되었습니다.');

          const fetchTerraceTowerCompleteProductOrder = async () => {
            await terraceTowerCompleteProductOrderStore.fetchTerraceTowerCompleteProductOrder(
              parsedProductOrder?.orderData?.saleHistoryUID ?? 1,
              'CARD',
              searchParams.get('TID') ?? '',
            );
          };
          fetchTerraceTowerCompleteProductOrder().catch(error => {
            console.log('테라스 타워 주차정 정보 가져오는 중 에러 발생', error);
          });
          break;
        case '9838':
          setIsSuccess(false);
          setResultMessage('사용자가 결제를 취소했습니다.');
          break;
        case '3041':
          setIsSuccess(false);
          setResultMessage('1000원 미만의 금액은 신용카드 결제가 불가합니다.');
          break;
        case '3095':
          setIsSuccess(false);
          setResultMessage('카드사에서 결제를 거부했습니다.');
          break;
        default:
          setIsSuccess(false);
          setResultMessage(`결제에 실패했습니다. (결과 코드: ${resultCode})`);
      }
    } else {
      setResultMessage('결제 결과를 확인할 수 없습니다.');
      setIsSuccess(false);
    }
  }, [searchParams]);

  function onShare() {
    if (!parsedProductOrder.orderData) return;

    const { latitude: lat, longitude: lng, lotUID: sid, weeklyOperTime, satOperTime, sunOperTime, holidayOperTime } = parsedProductOrder.orderData;
    const operationTime = weeklyOperTime || satOperTime || sunOperTime || holidayOperTime;

    const shareUrl = `https://ars.zoomansa.com/shareLink.jsp?dailyParkingShareLotType=hourly&dailyParkingShareLat=${lat}&dailyParkingShareLng=${lng}&dailyParkingShareSid=${sid}`;

    const address = parsedProductOrder.orderData.jibunLotAddr || parsedProductOrder.orderData.roadLotAddr || "주소 정보가 없습니다.";

    const shareText = [
      "[주차할 땐 주만사]",
      "",
      `주차장명 : ${parsedProductOrder.orderData.lotName}`,
      `주소 : ${address}`,
      "",
      `운영시간 : ${operationTime}`,
      "",
      "자세한 정보는 주만사 앱에서 확인해 주세요!",
      "",
      `📌 ${shareUrl}`
    ].join("\n"); // 줄바꿈을 직접 제어

    setShared(shareText);
  }

  // TODO:    <PaymentSuccessTopContainer>
  //             <SharedContainer>
  //               <SharedContentContainer
  //                 onClick={() => {
  //                   onShare()
  //                 }}
  //               >
  //                 <IcShareSVG/>
  //                 <SubHeadline weight={'bold'} color={'gray06'}>
  //                   공유
  //                 </SubHeadline>
  //               </SharedContentContainer>
  //             </SharedContainer>
  // 세팅필요

  return (
    <MainContainerNoMargin
      style={{
        margin: '0 auto',
      }}
    >
      {isSuccess === null ? (
        <p>결제 결과를 확인 중입니다...</p>
      ) : isSuccess ? (
        <div>
          <PaymentTopContainer>
            {/*<SharedContainer>*/}
            {/*  <SharedContentContainer*/}
            {/*    onClick={() => {*/}
            {/*      onShare()*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <IcShareSVG/>*/}
            {/*    <SubHeadline weight={'bold'} color={'gray06'}>*/}
            {/*      공유*/}
            {/*    </SubHeadline>*/}
            {/*  </SharedContentContainer>*/}
            {/*</SharedContainer>*/}
            <img src={icCheckGradient} alt={'체크 그라디언트 아이콘'} />
            <LargeTitle3 weight={'bold'} color="primary">
              시간권 결제 완료
            </LargeTitle3>
            <PaymentCheckContainer>
              <SubHeadline css={{ color: '#6B6B6B' }}>
                [메뉴&gt;주차장 이용내역]에서 결제한
                <br />
                주차권 확인할 수 있어요.
              </SubHeadline>
            </PaymentCheckContainer>
          </PaymentTopContainer>
          <BottomContainer>
            <TicketButtonContainer>
              <CarParkingInfoContainer>
                <SubHeadline weight={'semibold'} css={{ color: '#333333' }}>
                  {parsedProductOrder.orderData?.productName ?? ''}
                </SubHeadline>
                <ViewDivide3 />
                {ticketInfoList.map((ticketItem, index) => (
                  <ParkingInfoContainer key={index} isFirst={index === 0}>
                    <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                      {ticketItem.label}
                    </Caption1>
                    <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                      {ticketItem.value}
                    </Caption1>
                  </ParkingInfoContainer>
                ))}
              </CarParkingInfoContainer>
            </TicketButtonContainer>
            <CheckButton
              onClick={() => {
                moveMainFromPayment();
              }}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                확인
              </SubHeadline>
            </CheckButton>
          </BottomContainer>
        </div>
      ) : (
        <div>
          <PaymentTopContainer>
            <img src={icExclamationDarkGray} alt={'느낌표 아이콘'} />
            <LargeTitle3 weight={'bold'} color="primary">
              결제를 실패했어요
            </LargeTitle3>
            <PaymentCheckContainer>
              <SubHeadline css={{ color: '#6B6B6B' }}>다시 결제를 진행해 주세요.</SubHeadline>
            </PaymentCheckContainer>
          </PaymentTopContainer>

          <BottomContainer>
            <CheckButton
              onClick={() => {
                moveMainFromPayment();
              }}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                확인
              </SubHeadline>
            </CheckButton>
          </BottomContainer>
        </div>
      )}
    </MainContainerNoMargin>
  );
});

export default TerraceTowerPaymentResultPage;
