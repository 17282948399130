import { styled, keyframes } from '@stitches/react';
import 'react-calendar/dist/Calendar.css';
import { colors } from 'styles/colors';

// 슬라이드 업 애니메이션
export const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)', opacity: 0 },
  '100%': { transform: 'translateY(0)', opacity: 1 },
});

export const Overlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
});

export const ModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(0%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '28px 20px 40px 20px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  height: 'auto',
});
