export interface TimeSlot {
  time: string;
}

export const generateTimeSlots = (startTime: string, endTime: string) => {
  const currentTime = new Date(); // 현재 시간을 가져옴

  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const startDate = new Date();
  startDate.setHours(startHour, startMinute, 0, 0); // 시작 시간을 설정

  let endDate = new Date();
  endDate.setHours(endHour, endMinute, 0, 0); // 종료 시간을 설정

  // 종료 시간이 시작 시간보다 이전이면, 종료 시간을 다음 날로 설정
  if (endDate.getTime() <= startDate.getTime()) {
    endDate.setDate(endDate.getDate() + 1);
  }

  const currentMinutes = currentTime.getMinutes();
  if (currentMinutes < 30) {
    currentTime.setMinutes(0, 0, 0); // 00분 ~ 30분 구간
  } else {
    currentTime.setMinutes(30, 0, 0); // 30분 ~ 00분 구간
  }

  // 현재 시간이 시작 시간보다 이르다면 시작 시간을 기준으로, 현재 시간이 운영 시간이 지났다면 현재 시간을 기준으로 설정
  const adjustedStartTime = currentTime < startDate ? startDate : currentTime;

  let slots: TimeSlot[] = [];

  // 30분 간격으로 시간대 생성
  for (
    let time = new Date(adjustedStartTime);
    time < endDate;
    time.setMinutes(time.getMinutes() + 30)
  ) {
    const startHour = time.getHours();
    const startMinutes = time.getMinutes();
    const end = new Date(time);

    const endHour = end.getHours();
    const endMinutes = end.getMinutes();

    const startTimeFormatted = `${startHour}:${startMinutes < 10 ? '0' + startMinutes : startMinutes}`;
    const endTimeFormatted = `${endHour}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;

    if (
      end.getTime() > endDate.getTime() &&
      (endMinute === 0 || endMinute === 29 || endMinute === 59 || endMinute === 30)
    ) {
      break;
    }

    // 객체 형식으로 시간대 정보 저장
    const timeSlot: TimeSlot = {
      time: `${startTimeFormatted}~${endTimeFormatted}`,
    };

    slots.push(timeSlot);
  }

  // const lastSlotTime = slots.length > 0 ? slots[slots.length - 1].time.split('~')[1] : '';
  // const lastMinutes = lastSlotTime ? parseInt(lastSlotTime.split(':')[1]) : null;
  // console.log(slots);
  // if (lastMinutes !== null) {
  //   if (lastMinutes === 0 || lastMinutes === 30) {
  //     // 마지막이 00분 또는 30분이면 마지막 2개 제거
  //     slots = slots.slice(0, -2);
  //   } else if (lastMinutes === 59 || lastMinutes === 29) {
  //     // 마지막이 59분 또는 29분이면 마지막 1개 제거
  //     slots = slots.slice(0, -1);
  //   }
  // }

  return slots;
};
