import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const WrapInfo = styled('div', {
  width: '100%',
  padding: '45px 20px 0px 20px',
  boxSizing: 'border-box',
});
