export const copyToClipboard = (text: string, showToast: Function, icSuccessCheckGreen: string, icFailCheckRed: string) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('복사 성공!');
        showToast('주소 복사 완료했어요!', icSuccessCheckGreen);
      })
      .catch((error) => {
        console.error('복사 실패:', error);
        showToast('주소 복사를 못 했어요.', icFailCheckRed);
      });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices
    try {
      const successful = document.execCommand('copy');
      const msg = successful
        ? showToast('주소 복사 완료했어요!', icSuccessCheckGreen)
        : showToast('주소 복사를 못 했어요.', icFailCheckRed);
      console.log(msg);
    } catch (err) {
      console.error('복사 실패:', err);
      showToast('앗! 다음에 다시 시도해 주세요.', icFailCheckRed);
    }
    document.body.removeChild(textArea);
  }
}

export const copyToClipboardShared = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('공유 내용이 복사되었습니다!');
        console.log('웹 환경 - 클립보드에 복사 완료');
      })
      .catch((err) => {
        alert('공유 복사를 못했어요.');
        console.error('클립보드 복사 실패:', err);
      });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // 모바일 대응
    try {
      const successful = document.execCommand('copy');
      alert(successful ? '공유 내용이 복사되었습니다!' : '공유 복사를 못했어요.');
    } catch (err) {
      console.error('복사 실패:', err);
      alert('앗! 다음에 다시 시도해 주세요.');
    }
    document.body.removeChild(textArea);
  }
};
