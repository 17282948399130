import React from 'react';

import icDotGray from 'assets/ic/ic_dot_gray.svg';
import {
  CautionListItemDotIcon,
  CautionsAndUseSlotGuideListContainer,
  TimeTicketNotCautionContainer,
} from 'pages/TerraceTower/styles';
import { Caption2 } from 'styles/typography';

interface TimeTicketCautionsComponentProps {
  cautions?: string;
}

const TimeTicketCautionsComponent: React.FC<TimeTicketCautionsComponentProps> = ({ cautions }) => {
  const formattedCautions = cautions ? (
    cautions
      .replace(/\n/g, '')
      .split(/(?<=\.)/)
      .map((sentence, index) => (
        <CautionsAndUseSlotGuideListContainer key={index} first={index === 0}>
          {sentence.trim().length > 0 && (
            <CautionListItemDotIcon src={icDotGray} alt="dot icon" />
          )}
          <Caption2 color="gray08" weight="regular">
            {sentence.trim()}
          </Caption2>
        </CautionsAndUseSlotGuideListContainer>
      ))
  ) : (
    <TimeTicketNotCautionContainer>
      <CautionListItemDotIcon src={icDotGray} alt="dot icon" />
      <Caption2 color="gray08" weight="regular">
        주차권 유의사항이 없습니다.
      </Caption2>
    </TimeTicketNotCautionContainer>
  )
  return <div>{formattedCautions}</div>
}

export default TimeTicketCautionsComponent;
