import { styled } from '@stitches/react';

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
});

export const TwoButtonModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 50px)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  height: 'auto',
});

export const TwoButtonModalTitleSpan = styled('span', {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginBottom: '14px',
  color: '#514263',
});

export const TwoButtonModalContentContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingBottom: 0,
  whiteSpace: 'pre-line',

  variants: {
    content: {
      true: {
        paddingBottom: 30,
      },
      false: {
        paddingBottom: 0,
      },
    },
  },
});

export const TwoButtonLeftButton = styled('button', {
  flex: 1,
  padding: '15px 0',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#8B63BB',
  backgroundColor: '#E4E4E4',
  borderRadius: '0px 0px 0px 6px',
  border: 'none',
  textAlign: 'center',
});

export const TwoButtonRightButton = styled('button', {
  flex: 1,
  padding: '10px 0',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#fff',
  backgroundColor: '#8B63BB',
  borderRadius: '0px 0px 6px 0px',
  border: 'none',
  textAlign: 'center',
});
