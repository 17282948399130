import Title from 'components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainerNoMargin } from 'components/styles';
import MyParkingPastList from 'containers/myParkingTicket/list/MyParkingPastList';
import { observer } from 'mobx-react';

const MyParkingPastHistoryPage = observer(() => {
  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBackPress = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleBackPress();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin style={{ minHeight: `${windowHeight}px` }}>
      <Title
        title="지난 내역"
        leftArrowButtonVisible={true}
        leftOnClick={handleBackPress}
        rightTextButtonVisible={true}
        rightOnClick={handleBackPress}
        rightTitle={'나의 주차권'}
        titleColor={'darkPurple'}
      />
      <MyParkingPastList />
    </MainContainerNoMargin>
  );
});

export default MyParkingPastHistoryPage;
