import { styled } from '@stitches/react';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
  overflow: 'auto',
});

export const ContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
  marginTop: 30,
});

export const TitleSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const SubTitleSpan = styled('span', {
  color: '#868686',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '6px',
});

export const ChoiceDateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ChoiceDateItem = styled('div', {
  borderRadius: '5px',
  fontSize: '16px',
  marginLeft: 20,
  marginRight: 20,
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontWeight: 700,
  lineHeight: 'normal',
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    isFirst: {
      true: {
        marginTop: '28px',
      },
      false: {
        marginTop: '10px',
      },
    },
    isSelected: {
      true: {
        color: '#1A1A1A',
        border: '2px solid #8B63BB',
        backgroundColor: '#FFFFFF',
      },
      false: {
        color: '#868686',
        border: '2px solid #F8F8FA',
        backgroundColor: '#F8F8FA',
      },
    },
  },
});
