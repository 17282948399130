import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  backgroundColor: '#F3F4F6',
  paddingTop: '20px',
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  variants: {
    isEmpty: {
      true: {
        justifyContent: 'center',
      },
      false: {
        justifyContent: 'flex-start',
      },
    },
  },
});
