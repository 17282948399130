import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { MainContainer, MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import ToggleButton from 'components/atoms/Toggle';
import {
  PhoneNumberBorderContainer,
  UserInfoInput,
  TimerContainer,
  VerificationCodeInput,
  VerificationCodeInputContainer,
} from 'components/atoms/UserInfoInputsContainers/styles';
import { Body, Caption2, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { Container, PhoneCertButton } from './styles';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { isValidPhoneNumber, phoneNumberCheck } from 'utils/common';
import { authCodeStore } from 'stores/store/Auth/authCodeStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import CheckModal from 'components/atoms/Modal/CheckModal';
import { observer } from 'mobx-react';

interface IFormInputs {
  phone: string;
  certification: string;
  password: string;
  passwordVerify: string;
}

interface PopupModalProps {
  title1: string;
  leftClick: () => void;
  leftText: string;
  description?: string;
  rightClick?: () => void;
  rightText?: string;
  title2?: string;
}

const defaultPopup: PopupModalProps = {
  title1: '',
  leftText: '',
  leftClick: () => {},
  rightText: '',
  rightClick: undefined,
  description: '',
  title2: '',
};

const SearchMyInfo = observer(() => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    watch,
  } = useForm<IFormInputs>();
  const [isCertification, setIsCertification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [time, setTime] = useState(180);
  const [phoneError, setPhoneError] = useState('');

  const [authValidateError, setAuthValidateError] = useState('');
  const [isVerifyComplete, setIsVerifyComplete] = useState(true);

  const [isPopup, setIsPopup] = useState(false);
  const [popup, setPopup] = useState<PopupModalProps>(defaultPopup);
  const [isPassword, setIsPassword] = useState(false);

  const phone = watch('phone', '');
  const certification = watch('certification', '');


  const password = watch('password', '');
  const passwordVerify = watch('passwordVerify', '');
  const { iv, salt } = getRandomString();
  const [isReRequest, setIsReAuthRequest] = useState(false);

  const encryptedMemberPhone = encTextWord(phone, iv, salt);
  const [isAuthCodeTransmitModal, setIsAuthCodeTransmitModal] = useState(false);

  useEffect(() => {
    if (!isCertification) return;

    // 1초마다 타이머 업데이트
    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const reAuth = async () => {
    setTime(180);
    await requestAuthCode(encryptedMemberPhone);
  };

  const onSubmitCertCode = async (certification: string) => {
    const encryptedPassword = encTextWord(phone, iv, salt);

    try {
      await authCodeStore.fetchValidateAuthCode(
        {
          memberTell: encryptedPassword,
          authCode: certification,
        },
        iv,
        salt,
      );

      setIsAuthCodeTransmitModal(false);

      if (authCodeStore.isAuthCodeValidateSuccessful) {
        setIsVerifyComplete(false);
      } else {
        setAuthValidateError(authCodeStore.error_msg || '인증번호가 올바르지 않습니다.');
        document.getElementById('certification')?.focus();
      }
    } catch (error) {
      console.error('인증번호 검증 중 오류 발생:', error);
    }
  };


  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    if (!isCertification) {
      await requestAuthCode(encryptedMemberPhone);
      return;
    }
  }

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleToggle = (index: number) => {
    setSelectedIndex(index);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const focusCertInputField = () => {
    document.getElementById('certification')?.focus();
  };

  const buttonStyle = {
    padding: '0px',
    width: 'calc(100% - 40px)',
    height: '46px',
    backgroundColor: certification.length === 6 ? '#8B63BB' : '#fff',
    border: '1px solid #8B63BB',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'normal',
    borderRadius: '4px',
  }

  useEffect(() => {
    if (phone === '') {
      document.getElementById('phone')?.focus();
    }
  }, [])

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const requestAuthCode = async (encryptedPhone: string) => {
    try {
      await authCodeStore.fetchAuthCode(
        {
          memberTell: encryptedPhone,
        },
        iv,
        salt,
      );

      if (authCodeStore.isAuthCodeSuccessful) {
        setIsAuthCodeTransmitModal(true);
        setPhoneError('');
        setIsCertification(true);
      } else {
        setPhoneError('인증번호 요청에 실패했습니다.');
      }
    } catch (error) {
      console.error('인증 코드 요청 중 오류 발생:', error);
      setPhoneError('인증 코드 요청 중 오류가 발생했습니다.');
    }
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="아이디/비밀번호 찾기"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          <ToggleButton
            options={['아이디 찾기', '비밀번호 찾기']}
            selectedIndex={selectedIndex}
            content={[
              <div key="아이디 찾기">
                <div style={{ marginTop: '37px'}}>
                  <Body weight={'semibold'} color={'black3'}>
                    회원가입 시 입력한
                  </Body>
                  <Row>
                    <Body weight={'semibold'} color={'primary'}>
                      휴대폰 번호
                    </Body>
                    <Body weight={'semibold'} color={'black3'}>
                      를 입력 해주세요
                    </Body>
                  </Row>

                  <div style={{ marginTop: '37px' }}>
                    <PhoneNumberBorderContainer>
                      <UserInfoInput
                        type="tel"
                        id="phone"
                        {...register('phone', { required: '필수 입력 항목입니다.' })}
                        placeholder="휴대폰 번호"
                      />
                    </PhoneNumberBorderContainer>
                    {phoneError ? (
                      <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                        {phoneError}
                      </Caption2>
                    ) : null}
                  </div>

                  {isCertification ? (
                    <div>
                      <VerificationCodeInputContainer>
                        <VerificationCodeInput
                          type="number"
                          placeholder="인증번호 입력"
                          id="certification"
                          {...register('certification', { required: '필수 입력 항목입니다.' })}
                        />
                        <TimerContainer>{formatTime(time)}</TimerContainer>
                      </VerificationCodeInputContainer>
                      {certification.length !== 6 ? (
                        <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
                          인증 번호를 입력해 주세요
                        </Caption2>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <PhoneCertButton
                  type="submit"
                  isActive={certification.length === 6}
                  disabled={!phone}
                  onClick={async () => {
                    if (isCertification) {
                      setIsReAuthRequest(true);
                      alert('나호출')
                      await reAuth();
                    } else {
                      setIsReAuthRequest(false);
                      alert('아니 나호출')
                    }
                  }}
                >
                  <SubHeadline
                    weight={'regular'}
                    color={certification.length === 6 ? 'white' : 'primary'}
                  >
                    {!isCertification
                      ? '인증번호 요청'
                      : certification.length === 6
                        ? '확인'
                        : '인증번호 다시 받기'}
                  </SubHeadline>
                </PhoneCertButton>
              </div>,

              // <div key="비밀번호 찾기">
              //   {isPassword ? (
              //     <Container>
              //       <div
              //         style={{
              //           marginTop: '37px',
              //         }}
              //       >
              //         <Row>
              //           <Body weight={'semibold'} color={'primary'}>
              //             새로운 비밀번호
              //           </Body>
              //           <Body weight={'semibold'} color={'black3'}>
              //             를 입력 해주세요
              //           </Body>
              //         </Row>
              //         <div style={{ marginTop: '37px' }}>
              //           <PhoneNumberBorderContainer>
              //             <UserInfoInput
              //               type="password"
              //               id="password"
              //               {...register('password', {
              //                 required: '필수 입력 항목입니다.',
              //               })}
              //               placeholder="비밀번호"
              //             />
              //           </PhoneNumberBorderContainer>
              //         </div>
              //
              //         <div>
              //           <VerificationCodeInputContainer>
              //             <VerificationCodeInput
              //               type="password"
              //               placeholder="비밀번호"
              //               id="passwordVerify"
              //               {...register('passwordVerify', { required: '필수 입력 항목입니다.' })}
              //             />
              //           </VerificationCodeInputContainer>
              //           {passwordVerify !== password || !passwordVerify ? (
              //             <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
              //               {passwordVerify !== password
              //                 ? '비밀번호가 일치하지 않습니다'
              //                 : '비밀번호를 입력해 주세요'}
              //             </Caption2>
              //           ) : null}
              //         </div>
              //       </div>
              //       <button
              //         style={{
              //           ...buttonStyle,
              //           position: 'fixed',
              //           bottom: 40,
              //         }}
              //         onClick={handleSubmit(onSubmit)}
              //         disabled={!phone}
              //       >
              //         <SubHeadline
              //           weight={'regular'}
              //           color={certification.length === 6 ? 'white' : 'primary'}
              //         >
              //           {!isCertification
              //             ? '인증번호 요청'
              //             : certification.length === 6
              //               ? '확인'
              //               : '인증번호 다시 받기'}
              //         </SubHeadline>
              //       </button>
              //     </Container>
              //   ) : (
              //     <Container>
              //       <div
              //         style={{
              //           marginTop: '37px',
              //         }}
              //       >
              //         <Body weight={'semibold'} color={'black3'}>
              //           비밀번호 재설정을 위해
              //         </Body>
              //         <Row>
              //           <Body weight={'semibold'} color={'primary'}>
              //             휴대폰 번호
              //           </Body>
              //           <Body weight={'semibold'} color={'black3'}>
              //             를 입력 해주세요
              //           </Body>
              //         </Row>
              //         <div style={{ marginTop: '37px' }}>
              //           <PhoneNumberBorderContainer>
              //             <UserInfoInput
              //               type="number"
              //               id="phone"
              //               {...register('phone', {
              //                 required: '필수 입력 항목입니다.',
              //               })}
              //               placeholder="휴대폰 번호"
              //             />
              //           </PhoneNumberBorderContainer>
              //           {phoneError ? (
              //             <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
              //               {phoneError}
              //             </Caption2>
              //           ) : null}
              //         </div>
              //         {isCertification ? (
              //           <div>
              //             <VerificationCodeInputContainer>
              //               <VerificationCodeInput
              //                 type="number"
              //                 placeholder="인증번호 입력"
              //                 id="certification"
              //                 {...register('certification', { required: '필수 입력 항목입니다.' })}
              //               />
              //               <TimerContainer>{formatTime(time)}</TimerContainer>
              //             </VerificationCodeInputContainer>
              //             {certification.length !== 6 ? (
              //               <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7 }}>
              //                 인증 번호를 입력해 주세요
              //               </Caption2>
              //             ) : null}
              //           </div>
              //         ) : null}
              //       </div>
              //       <button
              //         style={{
              //           ...buttonStyle,
              //           position: 'fixed',
              //           bottom: 40,
              //         }}
              //         onClick={handleSubmit(onSubmit)}
              //         disabled={!phone}
              //       >
              //         <SubHeadline
              //           weight={'regular'}
              //           color={certification.length === 6 ? 'white' : 'primary'}
              //         >
              //           {!isCertification
              //             ? '인증번호 요청'
              //             : certification.length === 6
              //               ? '확인'
              //               : '인증번호 다시 받기'}
              //         </SubHeadline>
              //       </button>
              //     </Container>
              //   )}
              // </div>,
              <>
              </>
            ]}
            onToggle={handleToggle}
          />
        </div>
      </form>

      <CheckModal
        isVisible={isAuthCodeTransmitModal}
        onClose={() => {
          setIsAuthCodeTransmitModal(false);
          setTimeout(() => {
            requestAnimationFrame(() => {
              focusCertInputField();
            });
          }, 0);
        }}
        title={'인증번호가 발송되었습니다.'}
        content={''}
        buttonText={'확인'}
      />

      <PopupModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={popup.title1}
        description={popup.description ?? ''}
        leftClick={popup.leftClick}
        leftText={popup.leftText}
        rightText={popup.rightText ?? ''}
        rightClick={popup.rightClick}
      />
    </MainContainerNoMargin>
);
});

export default SearchMyInfo;
