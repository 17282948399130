import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import {
  ModalContainer,
  ModalOverlay,
  ConsultButtonWrapper,
  ConsultButtonContainer,
  MinganPlacePhotoWeekContainer,
  PhotoSizeContainer,
  MinganPlaceWeekContainer,
  PlaceSpan,
  WeekSpan,
  WeekEmphasisSpan,
  MinganParkingKindContainer,
  ShareContainer,
  ZoomansaIc,
  MinganKindListContainer,
  AmountContainer,
  DiscountText,
  DiscountPercentText,
  Bubble,
  CarKind,
  MyLocation,
} from './styles';
import TwoButtonModal from 'components/atoms/Modal/TwoButtonModal';
import { getFormattedDateHyphen } from 'utils/dateUtils';
import CustomCalendar from '../../CustomCalendar';
import { monthlyListData } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import { IcBellSVG, IcClose48SVG, IcFaqFaceSVG } from 'assets/ic';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { Body, Caption1, Caption2, Caption4, Headline, SubHeadline } from 'styles/typography';
import { handleChatClick } from 'utils/common';
import Row from 'components/atoms/Row';

import icQuestion from 'assets/ic/ic_question.svg';
import icZoomansaLogo from 'assets/ic/ic_zoomansa_logo.svg';
import icCalendarGray from 'assets/ic/ic_calendar_gray.svg';
import icKaKao from 'assets/ic/ic_kakaoTalk.png';
import PurpleOneButtonCheckModal from 'components/atoms/Modal/PurpleOneButtonCheckModal';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

interface Slot {
  lotUID: number;
  lat: number;
  lon: number;
  salePrice: number;
  productName: string;
  slotUID: string;
  productUID: number;
  weeklyOperTime: string;
  weekendOperTime: string;
  operType: string;
  productStatus: string;
  holidayIsOpen: string;
  jibunLotAddr: string;
  parkingMethod: string;
  parkingAvailCar: string;
  slotType: string;
  vehicleRegistration: string;
  parkingLotType: string;
  paymentAvailbleYN: string;
  depositAmount: string;
  useEndDate: string;
}

const targetKeys: Array<keyof Slot> = [
  'parkingMethod',
  'parkingAvailCar',
  'slotType',
  'vehicleRegistration',
  'parkingLotType',
];

interface BottomSheetProps {
  isVisible: boolean;
  onClose: () => void;
  setIsEmptyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const MinganInfoModal: React.FC<BottomSheetProps> = ({ isVisible, onClose, setIsEmptyModal }) => {
  const [isCounselingModalVisible, setIsCounselingModalVisible] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [kinds, setKinds] = useState<any>([]);
  const [isInquiry, setIsInquiry] = useState(false);
  const [isRequestComplete, setIsRequestComplete] = useState(false);
  const [windowHeight, setWindowHeight] = useState<number>(
    typeof window !== 'undefined' ? window.innerHeight : 800,
  );
  const [isFull, setIsFull] = useState(false);

  const formattedDate = selectedDate
    ? getFormattedDateHyphen(selectedDate)
    : getFormattedDateHyphen();

  const handleCalendarClose = () => {
    setIsCalendarVisible(false);
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setIsCalendarVisible(false);
  };

  // 초기 노출 비율 30%
  const initialSheetRatio = 0.3;
  const initialY = windowHeight * (1 - initialSheetRatio); // 시트 상단의 초기 y offset

  // react-spring을 사용한 애니메이션 설정 (y: 시트의 현재 offset)
  const [{ y }, api] = useSpring(() => ({ y: initialY }));

  useEffect(() => {
    const result: any = [];
    if (monthlyListData.slotList.length > 0) {
      const slot = monthlyListData.slotList[0];

      targetKeys.forEach(key => {
        const value = slot[key];
        if (value && value !== '해당없음' && value !== '필요없음') {
          result.push(value);
        }
      });
    }

    setKinds(result);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setWindowHeight(window.innerHeight);
      api.start({ y: window.innerHeight * (1 - initialSheetRatio), immediate: false });
    }
  }, [isVisible, api]);

  // 드래그 제스처 처리
  const bind = useDrag(
    ({ movement: [, my], last, memo = y.get() }) => {
      const currentWindowHeight = window.innerHeight;

      if (currentWindowHeight !== windowHeight) {
        setWindowHeight(currentWindowHeight);
      }

      // 드래그 중 현재 y 위치 계산
      const newY = memo + my;

      // newY는 0(시트가 완전히 펼쳐진 상태) ~ windowHeight(시트가 모두 내려간 상태) 사이여야 함
      const clampedY = Math.min(Math.max(0, newY));

      const TENSION = 500;
      const FRICTION = 50;

      if (newY < 200) {
        setIsFull(true);
        api.start({ y: 200, immediate: false, config: { tension: TENSION, friction: FRICTION } });
        return;
      }

      api.start({
        y: clampedY,
        immediate: false,
        config: { tension: TENSION, friction: FRICTION },
      });

      // 드래그가 끝났을 때(마우스/터치 릴리즈) 처리
      if (last) {
        // 현재 시트 높이 비율: ratio = 1 - (y / windowHeight)
        const currentRatio = 1 - clampedY / windowHeight;

        if (clampedY < memo) {
          setIsFull(true);
          api.start({ y: 200, immediate: false, config: { tension: TENSION, friction: FRICTION } });
        } else {
          setIsFull(false);
          if (currentRatio < 0.3) {
            api.start({
              y: windowHeight,
              immediate: false,
              config: { tension: TENSION, friction: FRICTION },
            });
            onClose();
          }
          api.start({
            y: windowHeight * (1 - initialSheetRatio),
            immediate: false,
            config: { tension: TENSION, friction: FRICTION },
          });
        }
      }
      return memo;
    },
    {
      bounds: { bottom: windowHeight },
      rubberband: true,
    },
  );

  const data = monthlyListData.slotList[1];
  const isSale = data.paymentAvailbleYN !== 'NO';

  // 모달이 닫힌 상태면 렌더링하지 않음
  if (!isVisible) return null;

  return (
    <animated.div
      style={{
        // position: 'fixed',
        inset: 0,
        // background: 'rgba(0, 0, 0, 0.3)',
        // zIndex: 1000,
        touchAction: 'none', // 드래그가 원활하도록 설정
      }}
      // 드래그가 아닌 단순 클릭일 경우 onClick으로 닫기 처리
      onClick={e => {
        // 드래그 후 이벤트 전파 방지
        e.stopPropagation();
        if (e.cancelable) onClose();
      }}
    >
      <animated.div
        {...bind()}
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          // 전체 창 높이를 가진 div이지만 transform translateY로 보이는 부분만 조정됨
          height: windowHeight,
          background: 'white',
          borderTopLeftRadius: isFull ? 0 : 16,
          borderTopRightRadius: isFull ? 0 : 16,
          transform: y.to(py => `translateY(${py}px)`),
          touchAction: 'none',
        }}
        onClick={e => e.stopPropagation()}
      >
        {!isFull ? (
          <div
            style={{
              width: 40,
              height: 5,
              background: '#ccc',
              borderRadius: 3,
              margin: '8px auto',
            }}
          />
        ) : (
          <Row justifyContent={'center'} alignItems={'center'} style={{ height: 66 }}>
            <IcClose48SVG
              onClick={onClose}
              style={{
                position: 'absolute',
                left: 4,
                top: 10,
              }}
            />

            <Headline weight={'bold'} color={'black1'}>
              상세정보
            </Headline>
          </Row>
        )}

        <animated.img
          src="http://test.zoomansa.com/slotImage/2/2768_3.jpg"
          alt="image"
          style={{
            position: 'absolute',
            right: 0,
            top: y.to(py => {
              // y가 커질수록 이미지가 작아지도록 조정
              const minTop = -200; // 최대로 올라갔을 때
              const maxTop = -100; // 초기값
              const ratio = (initialY - py) / (initialY - 200); // y가 줄어들수록 top 증가
              return Math.max(minTop, maxTop + ratio * (minTop - maxTop));
            }),
            objectFit: 'cover',
            width: y.to(py => {
              // y 값이 작아질수록 점점 커짐 (최소 100px -> 최대 100%)
              const minWidth = 100; // px
              const maxWidth = window.innerWidth; // 100%
              const ratio = (initialY - py) / (initialY - 200);
              return `${Math.min(maxWidth, minWidth + ratio * (maxWidth - minWidth))}px`;
            }),
            height: y.to(py => {
              // 초기 height: 100px, 최대 height: 200px
              const minHeight = 100;
              const maxHeight = 200;
              const ratio = (initialY - py) / (initialY - 200);
              return `${Math.min(maxHeight, minHeight + ratio * (maxHeight - minHeight))}px`;
            }),
            transition: 'top 0.1s ease-out',
          }}
        />

        <div
          style={{
            padding: '16px 20px',
          }}
        >
          <MinganPlacePhotoWeekContainer>
            <MinganPlaceWeekContainer>
              <PlaceSpan>{data.slotUID} 주차장</PlaceSpan>
              <WeekSpan>
                <WeekEmphasisSpan>평일 {data.weeklyOperTime} | </WeekEmphasisSpan>
                <WeekEmphasisSpan>주말 {data.weeklyOperTime}</WeekEmphasisSpan>
              </WeekSpan>
            </MinganPlaceWeekContainer>
            <PhotoSizeContainer>
              {!isSale ? (
                <Button
                  vertical={5}
                  horizontal={8}
                  radius={4}
                  backgroundColor={'#333333CC'}
                  style={{
                    position: 'absolute',
                    right: 4,
                    top: 4,
                  }}
                >
                  <Caption4 weight={'medium'} color={'white'}>
                    매진
                  </Caption4>
                </Button>
              ) : null}
            </PhotoSizeContainer>
          </MinganPlacePhotoWeekContainer>

          <MinganParkingKindContainer>
            <ShareContainer>
              <ZoomansaIc src={icZoomansaLogo} alt="주만사 아이콘" /> 공유
            </ShareContainer>
            {kinds.map((el: any, index: number) => {
              return (
                <MinganKindListContainer key={index}>
                  <Caption4 weight={'semibold'} color={'gray07'}>
                    {el}
                  </Caption4>
                </MinganKindListContainer>
              );
            })}
          </MinganParkingKindContainer>
        </div>
      </animated.div>
    </animated.div>
  );
};

export default MinganInfoModal;
