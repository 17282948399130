import { styled } from '@stitches/react';

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  cursor: 'pointer',
  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  position: 'relative',

  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
  },
  '&:checked::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 4,
    width: 5,
    height: 10,
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});

export const CheckBoxContainer = styled('div', {
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
});

export const CheckBoxLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '1.5',
  marginTop: '20px',
});


export const RequiredText = styled('span', {
  color: '#FF0000',
  marginRight: '4px'
});


export const ViewDivide = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  margin: '8px 0',
});

export const BottomContainer = styled('div', {
  position: 'absolute',
  bottom: 40,
  width: 'calc(100% - 40px)',
});