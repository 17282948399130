import { MainContainer, MainContainerNoMargin } from '../../../components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Container,
  PhoneNumberBorderContainer,
  UserInfoInput,
  TimerContainer,
  VerificationCodeInput,
  VerificationCodeInputContainer,
  PhoneCertButton,
  SignUpButton,
  PasswordInfoContainer,
  PasswordInfoInput,
} from './styles';
import { Body, Caption2, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { idRegex, isValidName, isValidPhoneNumber, passwordRegex } from 'utils/common';
import { certTimer } from 'utils/certTimerUtil';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { authCodeStore } from 'stores/store/Auth/authCodeStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { observer } from 'mobx-react';
import { Simulate } from 'react-dom/test-utils';
import { loginStore } from 'stores/store/Login/LoginStore';
import { signUpStore } from 'stores/store/SignUp/SignUpStore';
import CheckModal from 'components/atoms/Modal/CheckModal';
import { IcShowPasswordSVG } from 'assets/ic';
import icShowPassword from 'assets/ic/ic_show_password.svg';
import { colors } from 'styles/colors';

interface IFormInputs {
  name: string;
  phone: string;
  certification: string;
  password: string;
  passwordVerify: string;
  id: string;
}

const SignUpCertPage = observer(() => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    watch,
  } = useForm<IFormInputs>();
  const { state } = useLocation();

  const phone = watch('phone', '');
  const name = watch('phone', '');
  const certification = watch('certification', '');
  const password = watch('password', '');
  const passwordVerify = watch('passwordVerify', '');
  const id = watch('id', '');

  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [phoneError, setPhoneError] = useState('');
  const [nameError, setNameError] = useState('');
  const [authValidateError, setAuthValidateError] = useState('');
  const [isVerifyComplete, setIsVerifyComplete] = useState(true);
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const { iv, salt } = getRandomString();
  const [isReRequest, setIsReAuthRequest] = useState(false);
  const [isAuthCodeTransmitModal, setIsAuthCodeTransmitModal] = useState(false);
  const [isAlreadyUserModel, setIsAlreadyUserModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);

  const encryptedMemberID = encTextWord(id, iv, salt);
  const encryptedMemberPW = encTextWord(passwordVerify, iv, salt);
  const encryptedMemberPhone = encTextWord(phone, iv, salt);

  useEffect(() => {
    if (!isCertification) return;

    const timerInterval = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [isCertification]);

  const focusNameInputField = () => {
    document.getElementById('name')?.focus();
  };

  const focusPhoneInputField = () => {
    document.getElementById('phone')?.focus();
  };

  const focusCertInputField = () => {
    document.getElementById('certification')?.focus();
  };

  const focusIdInputField = () => {
    document.getElementById('id')?.focus();
  };

  const requestAuthCode = async (encryptedPhone: string) => {
    try {
      await authCodeStore.fetchAuthCode(
        {
          memberTell: encryptedPhone,
        },
        iv,
        salt,
      );

      if (authCodeStore.isAuthCodeSuccessful) {
        setIsAuthCodeTransmitModal(true);
        setPhoneError('');
        setIsCertification(true);
      } else {
        setPhoneError('인증번호 요청에 실패했습니다.');
      }
    } catch (error) {
      console.error('인증 코드 요청 중 오류 발생:', error);
      setPhoneError('인증 코드 요청 중 오류가 발생했습니다.');
    }
  };

  const reAuth = async () => {
    setTime(180);
    await requestAuthCode(encryptedMemberPhone);
  };

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    if (!isVerifyComplete) {
      if (!passwordRegex.test(data.password)) {
        setMessage('8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.');
      }

      try {
        await signUpStore.fetchSignUp(
          {
            memberID: encryptedMemberID,
            memberPW: encryptedMemberPW,
            memberPhone: encryptedMemberPhone,
            loginType: 'ZMS',
            marketingAgreeDate: null,
          },
          iv,
          salt,
        );
        if (signUpStore.isSignUpSuccessful) {
          navigate('/signUpMonthlyDesiredOnboarding');
        }
      } catch (error) {
        console.log('인증번호 전송 에러', error);
      }
    } else {
      // const trimmedName = data.name.trim()
      // alert(trimmedName)
      if (!isValidName(data.name)) {
        setNameError('올바른 고객님 성함을 입력해주세요');
        setTimeout(() => {
          focusNameInputField();
        }, 0);
        return;
      }

      if (!isValidPhoneNumber(data.phone)) {
        setPhoneError('올바른 휴대폰 번호를 입력해주세요');
        setTimeout(() => {
          focusPhoneInputField();
        }, 0);
        alert(data.phone);
        return;
      }

      if (!isCertification) {
        await handleSignUp(data);
        // const encryptedPassword = encTextWord(data.phone, iv, salt);
        // await requestAuthCode(encryptedPassword);
        return;
      }

      if (certification.length === 6) {
        await onSubmitCertCode(certification);
      }
    }
  };

  const handleSignUp = async (data: IFormInputs) => {
    try {
      await signUpStore.fetchSignUp(
        {
          memberID: encryptedMemberID,
          memberPW: encryptedMemberPW,
          memberPhone: encryptedMemberPhone,
          loginType: 'ZMS',
          marketingAgreeDate: null,
        },
        iv,
        salt,
      );

      if (signUpStore.isAlreadyUser) {
        setIsAlreadyUserModal(true);
      } else {
        const encryptedPassword = encTextWord(data.phone, iv, salt);
        await requestAuthCode(encryptedPassword);
      }
    } catch (error) {
      console.error('회원가입 중 오류 발생:', error);
    }
  };

  const onSubmitCertCode = async (certification: string) => {
    const encryptedPassword = encTextWord(phone, iv, salt);

    try {
      await authCodeStore.fetchValidateAuthCode(
        {
          memberTell: encryptedPassword,
          authCode: certification,
        },
        iv,
        salt,
      );

      setIsAuthCodeTransmitModal(false);

      if (authCodeStore.isAuthCodeValidateSuccessful) {
        setIsVerifyComplete(false);
        setTimeout(() => {
          focusIdInputField();
        }, 0);
        setValue('id', '');
      } else {
        setAuthValidateError(authCodeStore.error_msg || '인증번호가 올바르지 않습니다.');
        document.getElementById('certification')?.focus();
      }
    } catch (error) {
      console.error('인증번호 검증 중 오류 발생:', error);
    }
  };

  // const validateId = (value: string) => {
  //   if (!value) {
  //     setIdMessage('아이디는 필수 입력 항목입니다.');
  //   } else if (value === 'test123') {
  //     setIdMessage('이미 사용 중인 아이디입니다.');
  //   } else if (!idRegex.test(value)) {
  //     setIdMessage('5~20자의 영문 소문자, 숫자, 특수기호(_)(-)만 사용 가능합니다.');
  //   } else {
  //     setIdMessage('사용 가능한 아이디입니다.');
  //   }
  // };

  // const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value
  //   setValue('id', value)
  //   validateId(value)
  // }

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!id) {
      setIdMessage('아이디는 필수 입력 항목입니다.');
      return;
    }
    if (!idRegex.test(id)) {
      setIdMessage('5~20자의 영문 소문자, 숫자, 특수기호(_)(-)만 사용 가능합니다.');
      return;
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(async () => {
      await signUpStore.fetchSignUp(
        {
          memberID: encryptedMemberID,
          memberPW: encryptedMemberPW,
          memberPhone: encryptedMemberPhone,
          loginType: 'ZMS',
          marketingAgreeDate: null,
        },
        iv,
        salt,
      );

      // 테스트용
      if (signUpStore.isAlreadyUser) {
        setIdMessage('이미 사용 중인 아이디입니다.');
        return;
      }

      // if (signUpStore.isDuplicateMemberID) {
      //   setIdMessage('이미 사용 중인 아이디입니다.');
      //   return;
      // }
      setIdMessage('사용 가능한 아이디입니다.');
    }, 300);

    setTypingTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, signUpStore.isDuplicateMemberID]);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const idPwDisabled =
    idMessage !== '사용 가능한 아이디입니다.' ||
    !password ||
    !passwordVerify ||
    password !== passwordVerify;

  useEffect(() => {
    if (name === '') {
      document.getElementById('name')?.focus();
    }
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin>
      <Title
        title="휴대폰 번호 인증"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        titleColor="darkPurple"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        {!isVerifyComplete ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <PhoneNumberBorderContainer>
                <UserInfoInput
                  type="text"
                  id="id"
                  {...register('id')}
                  // onChange={handleIdChange}
                  placeholder="아이디"
                />
              </PhoneNumberBorderContainer>
              <Caption2
                weight={'bold'}
                color={idMessage === '사용 가능한 아이디입니다.' ? 'blue' : 'red'}
                style={{ marginTop: '7px', marginLeft: 20, marginRight: 20 }}
              >
                {idMessage}
              </Caption2>
            </div>

            <PasswordInfoContainer>
              <PasswordInfoInput
                type={showPassword ? 'text' : 'password'}
                id="password"
                {...register('password')}
                placeholder="비밀번호"
              />
              <IcShowPasswordSVG
                style={{
                  marginTop: 5,
                }}
                onClick={() => setShowPassword(prev => !prev)}
              />
            </PasswordInfoContainer>

            <Caption2 color="red" style={{ marginTop: '7px', marginLeft: 20, marginRight: 20 }}>
              {errors.password && errors.password.message}
            </Caption2>

            <PasswordInfoContainer style={{ marginTop: 32 }}>
              <PasswordInfoInput
                type={showPasswordVerify ? 'text' : 'password'}
                id="passwordVerify"
                {...register('passwordVerify')}
                placeholder="비밀번호 확인"
              />
              <IcShowPasswordSVG
                style={{
                  marginTop: 5,
                }}
                onClick={() => setShowPasswordVerify(prev => !prev)}
              />
            </PasswordInfoContainer>
            {passwordVerify !== password || message ? (
              <Caption2
                weight={'bold'}
                color={'red'}
                style={{ marginTop: 7, marginLeft: 20, marginRight: 20 }}
              >
                {passwordVerify !== password ? '비밀번호가 일치하지 않습니다' : message}
              </Caption2>
            ) : null}

            <SignUpButton
              isDisabled={idPwDisabled}
              onClick={handleSubmit(onSubmit)}
              disabled={idPwDisabled}
            >
              <SubHeadline weight={'regular'} color={idPwDisabled ? 'primary' : 'white'}>
                가입하기
              </SubHeadline>
            </SignUpButton>
          </>
        ) : (
          <div style={{ marginTop: '40px' }}>
            <PhoneNumberBorderContainer>
              <UserInfoInput
                type={'text'}
                id="name"
                {...register('name', {
                  required: '필수 입력 항목입니다.',
                })}
                placeholder="이름"
              />
            </PhoneNumberBorderContainer>
            {nameError ? (
              <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7, marginLeft: 20 }}>
                {nameError}
              </Caption2>
            ) : null}

            <PhoneNumberBorderContainer>
              <UserInfoInput
                type="tel"
                id="phone"
                {...register('phone', {
                  required: '필수 입력 항목입니다.',
                })}
                placeholder="휴대폰 번호"
                maxLength={11}
                disabled={isCertification}
              />
            </PhoneNumberBorderContainer>
            {phoneError ? (
              <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7, marginLeft: 20 }}>
                {phoneError}
              </Caption2>
            ) : null}

            {isCertification ? (
              <div>
                <VerificationCodeInputContainer>
                  <VerificationCodeInput
                    type="text"
                    inputMode="numeric"
                    placeholder="인증번호 입력"
                    id="certification"
                    {...register('certification', { required: '필수 입력 항목입니다.' })}
                  />
                  <TimerContainer>{certTimer(time)}</TimerContainer>
                </VerificationCodeInputContainer>
                {certification.length !== 6 ? (
                  <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7, marginLeft: 20 }}>
                    인증 번호를 입력해 주세요
                  </Caption2>
                ) : null}
                {authValidateError ? (
                  <Caption2 weight={'bold'} color={'red'} style={{ marginTop: 7, marginLeft: 20 }}>
                    {authValidateError}
                  </Caption2>
                ) : null}
              </div>
            ) : null}

            <PhoneCertButton type="submit" isActive={certification.length === 6} disabled={!phone}>
              <SubHeadline
                weight="regular"
                color={certification.length === 6 ? 'white' : 'primary'}
              >
                {!isCertification
                  ? '인증번호 요청'
                  : certification.length === 6
                    ? '확인'
                    : '인증번호 다시 받기'}
              </SubHeadline>
            </PhoneCertButton>
          </div>
        )}
      </form>

      <CheckModal
        isVisible={isAuthCodeTransmitModal}
        onClose={() => {
          setIsAuthCodeTransmitModal(false);
          setTimeout(() => {
            requestAnimationFrame(() => {
              focusCertInputField();
            });
          }, 0);
        }}
        title={'인증번호가 발송되었습니다.'}
        content={''}
        buttonText={'확인'}
      />

      <CheckModal
        isVisible={isAlreadyUserModel}
        onClose={() => {
          setIsAlreadyUserModal(false);
        }}
        title={signUpStore.error_msg ?? ''}
        content={''}
        buttonText={'확인'}
      />
    </MainContainerNoMargin>
  );
});

export default SignUpCertPage;
