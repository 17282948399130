import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Caption3, Caption4, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcTooltipAlertSVG } from 'assets/ic';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

interface Props {}

const GeojuParkingCaution: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row alignItems={'center'}>
        <IcTooltipAlertSVG width={20} height={20} style={{ marginRight: 10 }} fill={'#47474A'} />
        <SubHeadline weight={'bold'} color={'black3'}>
          유의사항
        </SubHeadline>
      </Row>

      <Divider style={{ margin: '14px 0px', backgroundColor: colors.gray03 }} />

      <Row alignItems={'flex-start'}>
        <Caption3 weight={'bold'} color={'primary'} style={{ marginRight: 12 }}>
          01
        </Caption3>
        <Caption4 weight={'regular'} color={'gray06'}>
          운영 마감 시간 이후 자동으로 출차 처리되며, 주차 시 부정주차단속 (과태료 및 견인 조치)
        </Caption4>
      </Row>
      <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
        <Caption3 weight={'bold'} color={'primary'} style={{ marginRight: 12 }}>
          01
        </Caption3>
        <Caption4 weight={'regular'} color={'gray06'}>
          주차 등록이 되었어도 거주자 출차요청 불이행 시 부정주차단속 대상 (과태료 및 견인 조치)
        </Caption4>
      </Row>
      <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
        <Caption3 weight={'bold'} color={'primary'} style={{ marginRight: 12 }}>
          01
        </Caption3>
        <Caption4 weight={'regular'} color={'gray06'}>
          후불제로 제공 되며 이용 된 시간만큼 결제 가능
        </Caption4>
      </Row>
    </Container>
  );
};

export default GeojuParkingCaution;
