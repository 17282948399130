import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from '../../../components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
} from '../../../components/atoms/Button/styles';
import DesiredAreaSearchBottomModal from '../../../components/atoms/Modal/DesiredAreaSearchBottomModal';
import { UserInfoInput } from '../../../components/atoms/UserInfoInputsContainers/styles';
import { PasswordMessage } from './styles';

const validatePassword = (password: string) => {
  const lengthCheck = password.length >= 8 && password.length <= 16;
  const uppercaseCheck = /[A-Z]/.test(password); // 대문자 포함 여부
  const lowercaseCheck = /[a-z]/.test(password); // 소문자 포함 여부
  const numberCheck = /[0-9]/.test(password); // 숫자 포함 여부
  const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password); // 특수 문자 포함 여부

  return lengthCheck && (uppercaseCheck || lowercaseCheck) && numberCheck && specialCharCheck;
};

const validateUserId = (userId: string) => {
  const lengthCheck = userId.length >= 5 && userId.length <= 20;
  return lengthCheck;
};

const SignUpCreateUserInfo = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isUserIdInvalid, setIsUserIdInvalid] = useState<boolean>(false);
  const [isUserIdEmpty, setIsUserIdEmpty] = useState<boolean>(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState<boolean>(false);
  const [isPasswordMismatch, setIsPasswordMismatch] = useState<boolean>(false);
  const [isConfirmPasswordEmpty, setIsConfirmPasswordEmpty] = useState<boolean>(false);
  const [isDesiredAreaSearchModal, setIsDesiredAreaSearchModal] = useState(false);

  const desiredAreaSearchModal = () => {
    let valid = true;

    // 아이디 유효성 검사
    if (userId.trim() === '') {
      setIsUserIdEmpty(true);
      valid = false;
    } else if (!validateUserId(userId)) {
      setIsUserIdInvalid(true);
      valid = false;
    } else {
      setIsUserIdEmpty(false);
      setIsUserIdInvalid(false);
    }

    // 비밀번호 유효성 검사
    if (!validatePassword(password)) {
      setIsPasswordInvalid(true);
      valid = false;
    } else {
      setIsPasswordInvalid(false);
    }

    // 비밀번호 확인 검사
    if (confirmPassword.trim() === '') {
      setIsConfirmPasswordEmpty(true);
      valid = false;
    } else if (password !== confirmPassword) {
      setIsPasswordMismatch(true);
      valid = false;
    } else {
      setIsPasswordMismatch(false);
      setIsConfirmPasswordEmpty(false);
    }

    if (valid) {
      navigate('/signUpMonthlyDesiredOnboarding');
      // setIsDesiredAreaSearchModal(true);
    }
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const [addressText, setAddressText] = useState<string>('');
  const handleSelectAddress = (address: string) => {
    setAddressText(address);
  };

  return (
    <MainContainer>
      <Title
        title="회원 가입"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid #dbdbdb',
            width: '100%',
          }}
        >
          <UserInfoInput
            type={'text'}
            id={'userId'}
            placeholder={'아이디'}
            value={userId}
            onChange={e => {
              const newUserId = e.target.value.slice(0, 20);
              setUserId(newUserId);
              setIsUserIdEmpty(false);
              setIsUserIdInvalid(false);
            }}
          />
        </div>

        {/* 아이디 메시지 영역 */}
        <PasswordMessage isEmpty={isUserIdEmpty} isInvalid={isUserIdInvalid}>
          {isUserIdEmpty
            ? '사용하실 아이디를 입력해주세요.'
            : isUserIdInvalid
              ? '아이디는 5~20자 길이로 입력해 주세요.'
              : '아이디는 5~20자 길이로 입력해 주세요.'}
        </PasswordMessage>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #dbdbdb',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <UserInfoInput
            type={'password'}
            id={'password'}
            placeholder={'비밀번호'}
            value={password}
            onChange={e => {
              const newPassword = e.target.value.slice(0, 16);
              setPassword(newPassword);
              setIsPasswordInvalid(false);
              setIsPasswordMismatch(false);
            }}
          />
        </div>
        {/* 비밀번호 메시지 영역 */}
        <PasswordMessage isInvalid={isPasswordInvalid}>
          {isPasswordInvalid
            ? '8~16자 영문 대문자 또는 소문자, 숫자 및 특수 문자를 포함해야 합니다.'
            : '비밀번호는 8~16자, 영문 대문자 또는 소문자, 숫자 및 특수 문자를 포함해야 합니다.'}
        </PasswordMessage>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #dbdbdb',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <UserInfoInput
            type={'password'}
            id={'confirmPassword'}
            placeholder={'비밀번호 확인'}
            value={confirmPassword}
            onChange={e => {
              const newConfirmPassword = e.target.value;
              setConfirmPassword(newConfirmPassword);
              setIsPasswordMismatch(false);
              setIsConfirmPasswordEmpty(false);
            }}
          />
        </div>
        {/* 비밀번호 확인 메시지 영역 */}
        <PasswordMessage isMismatch={isPasswordMismatch} isEmpty={isConfirmPasswordEmpty}>
          {isPasswordMismatch
            ? '비밀번호가 일치하지 않습니다.'
            : isConfirmPasswordEmpty
              ? '비밀번호 확인을 위해 다시 한 번 입력해주세요.'
              : '비밀번호 확인을 위해 다시 한 번 입력해주세요.'}
        </PasswordMessage>
      </div>

      <BottomButtonContainer>
        <ButtonEnableDisable onClick={desiredAreaSearchModal}>가입하기</ButtonEnableDisable>
      </BottomButtonContainer>

      <DesiredAreaSearchBottomModal
        isVisible={isDesiredAreaSearchModal}
        onClose={() => {
          setIsDesiredAreaSearchModal(false);
        }}
        onSelectArea={handleSelectAddress}
        onSelectAreaSuccess={() => {
          setIsDesiredAreaSearchModal(false);
          navigate('/home');
        }}
        onNotSelectArea={() => {
          setIsDesiredAreaSearchModal(false);
          navigate('/home');
        }}
      />
    </MainContainer>
  );
};

export default SignUpCreateUserInfo;
