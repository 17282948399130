import { IEmptyParkingData, IEmptyParkingRequest } from 'models/EmptyParking/emptyParkingModel';
import emptyParkingService from 'service/EmptyParking/emptyParkingService';
import BaseError from 'models/baseError';
import { makeAutoObservable } from 'mobx';

class EmptyParkingStore {
  isLoading = true;
  error: string | null = null;
  parkingHistory: IEmptyParkingData[] = [];
  isScrollLoading = false;
  totalCount: number = 0;
  isEnd = false;

  isCancelLoading = false;
  cancelError: string | null = null;
  isCancelEnd = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setScrollLoading(value: boolean) {
    this.isScrollLoading = value;
  }

  setError(value: string) {
    this.error = value;
  }

  setParkingHistory(data: IEmptyParkingData[]) {
    this.parkingHistory = data;
  }

  setTotalCount(value: number) {
    this.totalCount = value;
  }

  setCancelLoading(value: boolean) {
    this.isCancelLoading = value;
  }

  setCancelError(value: string) {
    this.cancelError = value;
  }

  getWaitingUID(): number[] {
    return this.parkingHistory.map(item => item.waitingUID);
  }

  async fetchEmptyParkingHistory(
    requestBody: IEmptyParkingRequest,

    isScrollLoad: boolean = false,
  ) {
    if (isScrollLoad) {
      this.setScrollLoading(true);
    } else {
      this.setLoading(true);
    }

    try {
      if (this.isEnd) {
        console.log('모든 데이터를 이미 불러왔습니다. 더 이상 페이지를 증가시킬 수 없습니다.');
        return;
      }

      const response = await emptyParkingService.getEmptyParkingHistory(requestBody);
      console.log('Fetch result:', response);

      if (response instanceof BaseError) {
        this.setError(response.errorMessage);
      } else {
        this.setTotalCount(response.totalCount);
        const data = response.data;

        console.log(`토탈 카운트: ${this.totalCount}`);
        if (isScrollLoad) {
          if (this.parkingHistory.length < this.totalCount) {
            this.parkingHistory = [...this.parkingHistory, ...data];
          } else {
            console.log('모든 데이터를 불러왔습니다.');
          }
        } else {
          this.setParkingHistory(data);
        }
      }
    } catch (error) {
      console.error('Fetch error:', error);
      this.setError('An error occurred while fetching parking history.');
    } finally {
      this.setLoading(false);
      this.setScrollLoading(false);
    }
  }

  async cancelParking(waitingUID: number) {
    const waitingUIDs = this.getWaitingUID();

    if (waitingUIDs.length === 0) {
      console.log('삭제할 항목이 없습니다.');
      return;
    }

    if (!waitingUIDs.includes(waitingUID)) {
      console.log(`삭제할 항목(${waitingUID})이 리스트에 없습니다.`);
      return;
    }

    try {
      this.setCancelLoading(true);

      const targetItem = this.parkingHistory.find(item => item.waitingUID === waitingUID);
      if (!targetItem) {
        console.error(`삭제 대상(${waitingUID})을 찾을 수 없습니다.`);
        return;
      }
      await this.fetchEmptyParkingCancel(waitingUID);

      this.parkingHistory = this.parkingHistory.filter(item => item.waitingUID !== waitingUID);
      console.log(`삭제 성공: ${waitingUID}`);
    } catch (error) {
      console.error(`삭제 실패: ${waitingUID}`, error);
    } finally {
      this.setCancelLoading(false);
    }
  }

  async fetchEmptyParkingCancel(waitingUID: number) {
    try {
      if (this.isCancelEnd) {
        console.log('모든 데이터를 이미 불러왔습니다. 더 이상 페이지를 증가시킬 수 없습니다.');
        return;
      }

      const response = await emptyParkingService.postEmptyParkingCancel(waitingUID);

      if (response instanceof BaseError) {
        this.setCancelError(response.errorMessage);
      } else {
        console.log('삭제 성공');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      this.setCancelError('An error occurred while fetching parking history.');
    } finally {
      this.setCancelLoading(false);
    }
  }
}

export const emptyParkingStore = new EmptyParkingStore();
