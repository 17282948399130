import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainerNoMargin } from 'components/styles';
import { Dialog, Text, WarpImage, WrapDesc, WrapFooter, WrapParkingInfo } from './styles';
import Row from 'components/atoms/Row';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { Body, Caption1, Caption2, Caption4, Headline, SubHeadline } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';

import { hourlyLotDetail, jinjuDetail, mapData } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import Divider from 'components/atoms/Divider';
import {
  IcCardSVG,
  IcClock16SVG,
  IcCloseCircleGraySVG,
  IcLocationGraySVG,
  IcReceipSVG,
  IcRoadViewSVG,
} from 'assets/ic';
import { setDial } from 'utils/deviceUtils';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  type: string;
}

const GeojuParkingPublicDetailModal: React.FC<Props> = observer(({ isVisible, onClose, type }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleLeftOnClick = () => {
    onClose();
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '50vh',
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  const data = type === 'jinju' ? jinjuDetail.slotList[0] : hourlyLotDetail.slotList[0];

  const jinjuData = mapData.result_jinju.data[2];

  if (!isVisible) return null;

  return (
    <Dialog>
      <div style={{ overflowY: 'auto' }}>
        <MainContainerNoMargin>
          <WarpImage>
            <IcCloseCircleGraySVG
              fill={colors.gray09}
              style={{
                left: 20,
                top: 20,
                position: 'absolute',
              }}
              onClick={onClose}
            />

            <WrapDesc>
              <Body weight={'semibold'} color={'black3'}>
                주차장 사진을 준비 중입니다
              </Body>
              <a
                href="https://forms.gle/We5kTeFLwsKn2k5r6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Body
                  weight={'regular'}
                  color={'primary'}
                  style={{
                    textDecorationLine: 'underline',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    // window.open('https://forms.gle/We5kTeFLwsKn2k5r6', '_blank');
                  }}
                >
                  사진 제보하기
                </Body>
              </a>
            </WrapDesc>

            <Button
              backgroundColor={colors.white}
              radius={8}
              style={{
                left: 10,
                bottom: 20,
                position: 'absolute',
              }}
              onClick={() => {}}
            >
              <IcRoadViewSVG fill={colors.gray06} style={{ marginRight: 4 }} />
              <Caption2 weight={'regular'} color={'black3'}>
                로드뷰
              </Caption2>
            </Button>
          </WarpImage>

          <Row>
            <Button
              backgroundColor={colors.primary}
              radius={0}
              style={{
                height: 50,
                width: '50%',
              }}
              onClick={() => {
                if (data.phoneNumber === '') {
                  alert('연결전화번호가 없습니다.');
                }
                setDial(data.phoneNumber);
              }}
            >
              <SubHeadline weight={'medium'} color={'white'}>
                전화 연결
              </SubHeadline>
            </Button>
            <Button
              backgroundColor={colors.gray03}
              radius={0}
              style={{
                height: 50,
                width: '50%',
              }}
              onClick={() => {}}
            >
              <SubHeadline weight={'medium'} color={'black3'}>
                길 안내받기
              </SubHeadline>
            </Button>
          </Row>

          <WrapParkingInfo>
            <Row>
              <Button
                backgroundColor={'#C4C4C4'}
                radius={3}
                onClick={() => {}}
                horizontal={5}
                vertical={3}
                style={{
                  marginRight: 6,
                }}
              >
                <Caption4 weight={'regular'} color={'white'}>
                  {type === 'jinju' ? '진주' : '공영'}
                </Caption4>
              </Button>

              <Body weight={'medium'} color={'black3'}>
                {data.prkplceNm}
              </Body>
            </Row>

            <Row style={{ marginTop: 8 }}>
              <Caption4 weight={'regular'} color={'gray06'}>
                {data.prkcmprt}면
              </Caption4>
              <Caption4
                weight={'regular'}
                color={'gray06'}
                style={{ marginLeft: 4, marginRight: 4 }}
              >
                |
              </Caption4>
              <Caption4 weight={'regular'} color={'gray06'}>
                {data.prkplceType}
              </Caption4>
            </Row>

            <Button
              backgroundColor={'#F2F2F2'}
              radius={5}
              style={{
                width: '100%',
                height: 40,
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <Caption2 weight={'regular'} color={'gray06'}>
                앱 결제를 지원하지 않는 주차장입니다
              </Caption2>
            </Button>

            <Row justifyContent={'space-between'}>
              <Caption1 weight={'regular'} color={'gray06'}>
                기본 요금
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.basicTime}분 당 {formatPriceWithCommas(data.basicCharge)}원
              </Caption1>
            </Row>

            {type === 'jinju' ? (
              <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
                <Caption1 weight={'regular'} color={'gray06'}>
                  이용 가능 대수
                </Caption1>
                <Caption1 weight={'semibold'} color={'blue'}>
                  {Number(jinjuData.totalParkingSpace) - Number(jinjuData.usedParkingSpace)}대
                </Caption1>
              </Row>
            ) : null}

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                운영 시간
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.weekdayOperOpenHhmm} ~ {data.weekdayOperColseHhmm} 운영
              </Caption1>
            </Row>
          </WrapParkingInfo>
          <Divider
            style={{
              height: 1,
              backgroundColor: '#f2f2f2',
            }}
          />
          <WrapParkingInfo>
            <Row style={{ marginBottom: 16 }}>
              <IcLocationGraySVG style={{ marginRight: 6 }} />
              <SubHeadline weight={'semibold'} color={'black3'}>
                기본 정보
              </SubHeadline>
            </Row>

            <Row justifyContent={'space-between'}>
              <Caption1 weight={'regular'} color={'gray06'}>
                주소
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.rdnmadr}
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                전화번호
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.phoneNumber}
              </Caption1>
            </Row>
          </WrapParkingInfo>

          <Divider
            style={{
              height: 1,
              backgroundColor: '#f2f2f2',
            }}
          />
          <WrapParkingInfo>
            <Row style={{ marginBottom: 16 }}>
              <IcCardSVG style={{ marginRight: 6 }} />
              <SubHeadline weight={'semibold'} color={'black3'}>
                주차 요금
              </SubHeadline>
            </Row>

            <Row justifyContent={'space-between'}>
              <Caption1 weight={'regular'} color={'gray06'}>
                기본요금
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.basicTime}분 당 {formatPriceWithCommas(data.basicCharge)}원
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                추가요금
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.addUnitTime === ''
                  ? '정보없음'
                  : `${data.addUnitTime}분 당 ${formatPriceWithCommas(data.addUnitCharge)}원`}
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                일 주차 요금
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.dayCmmtktAdjTime === '' || data.dayCmmtktAdjTime === '0'
                  ? '정보없음'
                  : `${data.dayCmmtktAdjTime}분 당 ${formatPriceWithCommas(data.dayCmmtkt)}원`}
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                월 주차 요금
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.monthCmmtkt === ''
                  ? '정보없음'
                  : `${formatPriceWithCommas(data.monthCmmtkt)}원`}
              </Caption1>
            </Row>
          </WrapParkingInfo>

          <Divider
            style={{
              height: 1,
              backgroundColor: '#f2f2f2',
            }}
          />

          <WrapParkingInfo>
            <Row style={{ marginBottom: 16 }}>
              <IcReceipSVG style={{ marginRight: 6 }} />
              <SubHeadline weight={'semibold'} color={'black3'}>
                할인 정보
              </SubHeadline>
            </Row>

            <Caption1 weight={'regular'} color={'black3'}>
              {data.spcmnt === '' ? '정보없음' : data.spcmnt}
            </Caption1>
          </WrapParkingInfo>

          <Divider
            style={{
              height: 1,
              backgroundColor: '#f2f2f2',
            }}
          />
          <WrapParkingInfo>
            <Row style={{ marginBottom: 16 }}>
              <IcClock16SVG style={{ marginRight: 6 }} />
              <SubHeadline weight={'bold'} color={'black3'}>
                운영시간
              </SubHeadline>
            </Row>

            <Row justifyContent={'space-between'}>
              <Caption1 weight={'regular'} color={'gray06'}>
                평일
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.operDay.includes('평일')
                  ? `${data.weekdayOperOpenHhmm} ~ ${data.weekdayOperColseHhmm}`
                  : '정보없음'}
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                토요일
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.operDay.includes('토요일')
                  ? `${data.satOperOperOpenHhmm} ~ ${data.satOperCloseHhmm}`
                  : '정보없음'}
              </Caption1>
            </Row>

            <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
              <Caption1 weight={'regular'} color={'gray06'}>
                공휴일
              </Caption1>
              <Caption1 weight={'regular'} color={'black3'}>
                {data.operDay.includes('공휴일')
                  ? `${data.holidayOperOpenHhmm} ~ ${data.holidayCloseOpenHhmm}`
                  : '정보없음'}
              </Caption1>
            </Row>
          </WrapParkingInfo>

          <Divider
            style={{
              height: 1,
              backgroundColor: '#f2f2f2',
            }}
          />
          <WrapParkingInfo>
            <Row style={{ marginBottom: 16 }}>
              <IcReceipSVG style={{ marginRight: 6 }} />
              <SubHeadline weight={'bold'} color={'black3'}>
                결제 방법
              </SubHeadline>
            </Row>
            <Caption1 weight={'regular'} color={'black3'}>
              {data.metpay}
            </Caption1>
          </WrapParkingInfo>
          <WrapFooter>
            <Text>주식회사 주만사는 통신판매업 사업자로 제공하는</Text>
            <Text>주차장 정보와 일치하지 않을 수 있습니다.</Text>
          </WrapFooter>
        </MainContainerNoMargin>
      </div>
    </Dialog>
  );
});

export default GeojuParkingPublicDetailModal;
