import { styled } from '@stitches/react';
import { colors } from 'styles/colors';
import { isIOS } from 'react-device-detect';

export const TitleContainer = styled('div', {
  zIndex: 2,
  width: '100%',
  maxWidth: '480px',
  height: 66,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  boxSizing: 'border-box',
  variants: {
    background: {
      default: {
        background: '#FFFFFF',
      },
      purpleGradient: {
        background: 'linear-gradient(to right, #BB8AF4, #7749AE)',
      },
      gray: {
        background: '#F0F0F0',
      },
      lightGray: {
        background: '#F3F4F6',
      },
    },
  },

  titleColor: {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  imgBackColor: {
    default: {
      color: '#514263',
    },
    white: {
      color: '#FFF',
    },
  },

  defaultVariants: {
    background: 'default',
  },
});
