import { keyframes, styled } from '@stitches/react';

const moveToTop = keyframes({
  from: { transform: 'translateY(0)' },
  to: { transform: 'translateY(-100%)' },
});

export const CarInfoEditContainer = styled('div', {
  display: 'flex',
  backgroundColor: '#FFF',
  borderColor: '#E4E4E4',
  borderRadius: '10px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px 20px 10px 20px',
  transition: 'transform 0.3s ease-in-out',
  marginBottom: 16,

  variants: {
    isToggled: {
      true: {
        backgroundColor: '#F8F8FA',
      },
      false: {
        backgroundColor: '#FFF',
        border: '1px solid #EAEAEA',
      },
    },
    animate: {
      true: {
        animation: `${moveToTop} 0.3s ease-in-out`,
      },
    },
  },
});

export const CarNumberToggleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

export const CarNumberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const CarNumberTextSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#333',
});

export const CarRepresentTextContainer = styled('div', {
  padding: '0px 4px 0px 4px',
  height: 16,
  border: '1px solid #8B63BB',
  color: '#8B63BB',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontWeight: 700,
  lineHeight: '22px', // Adjusted line-height to center text vertically
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '6px',
});

export const CarNumberSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '25px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  color: '#555555',
});

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '#CCC',
  marginTop: '20px',
});

export const ToggleButtonContainer = styled('div', {
  width: '40px',
  height: '20px',
  borderRadius: '20px',
  backgroundColor: '#ccc',
  position: 'relative',
  transition: 'background-color 0.3s',
  cursor: 'pointer',

  '&:before': {
    content: '',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: 'white',
    position: 'absolute',
    top: '1px',
    left: '1px',
    transition: 'left 0.3s',
  },

  variants: {
    isToggled: {
      true: {
        backgroundColor: '#8B63BB',

        '&:before': {
          left: '20px',
        },
      },
      false: {
        backgroundColor: '#ccc',

        '&:before': {
          left: '1px',
        },
      },
    },
  },
});

export const ModifyDeleteContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: '#6B6B6B',
  width: '100%',
});

export const ModifyDeleteTextSpan = styled('span', {
  width: '50%',
  // height: '50%',
  textAlign: 'center',
});

export const CarInfoNotifyContainer = styled('div', {
  paddingLeft: '20px',
});

export const CarInfoContentsText = styled('li', {
  marginBottom: '2px',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#868686',
});
