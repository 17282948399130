import { styled } from '@stitches/react';

export const Modal = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 10px)',
  height: 'auto',
  background: '#fff',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 16px 20px',
  boxSizing: 'border-box',
  borderRadius: '10px', // 둥근 모서리
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translate(-50%, -50%)' }, // 중앙 유지
      false: { transform: 'translate(-50%, -50%) translateY(100%)' }, // 숨김 상태
    },
  },
});

export const WrapDesc = styled('div', {
  padding: '12px 20px',
  backgroundColor: '#ececec',
});
