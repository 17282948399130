import { keyframes, styled } from '@stitches/react';

export const PointInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  margin: '16px 20px 20px',
})

export const DateSpan = styled('div', {
  color: '#CCC',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
})

export const PointStatusContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
})

export const PointStatusSpan = styled('span', {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
  maxWidth: 'calc(100% - 50px)',
  marginTop: '10px',
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal'
})

export const PointMoneySpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal'
})

export const PointDetailContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  height: 'auto',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '10px',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  gap: '10px',
  padding: '10px 16px'
})

export const ViewDivideContainer = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '#EAEAEA',
  marginTop: '20px',
})

export const ToggleButtonContainer = styled('div', {
  display: 'flex',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingBottom: '20px',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
})

export const PointHistoryToggleFirstContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isAccountAllClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isAccountAllClicked: true,
  },
})

export const PointHistoryToggleFirstSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',

  variants: {
    isAccountAllClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#000000',
      },
    },
  },

  defaultVariants: {
    isAccountAllClicked: true,
  },
})

export const PointHistoryToggleSecondContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isAccountEarnClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isAccountEarnClicked: false,
  },
})

export const PointHistoryToggleSecondSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000', // 기본 색상

  variants: {
    isAccountEarnClicked: {
      true: {
        color: '#FFFFFF', // 클릭된 경우 색상
      },
      false: {
        color: '#000000', // 기본 색상
      },
    },
  },

  defaultVariants: {
    isAccountEarnClicked: true,
  },
})

export const PointHistoryToggleThirdContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  borderColor: '#E4E4E4',

  variants: {
    isAccountUseClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isAccountUseClicked: false,
  },
})

export const PointHistoryToggleThirdSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',

  variants: {
    isAccountUseClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#000000',
      },
    },
  },
  defaultVariants: {
    isAccountUseClicked: true,
  },
})

export const YearMonthContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 20px',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
})

export const YearMonthContentContainer = styled('div', {
  display: 'flex', alignItems: 'center'
})

export const ThreeMonthSpan = styled('span', {
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',

  variants: {
    isThreeMonthHistory: {
      true: {
        color: '#6B6B6B',
        fontWeight: 600
      },
      false: {
        color: '#B0B0B0',
        fontWeight: 400
      },
    },
    isDisabled: {
      true: {
        color: '#B0B0B0'
      },
    }
  },

  defaultVariants: {
    isThreeMonthHistory: true,
  },
})

export const SixMonthSpan = styled('span', {
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',

  variants: {
    isSixMonthHistory: {
      true: {
        color: '#6B6B6B',
        fontWeight: 600
      },
      false: {
        color: '#B0B0B0',
        fontWeight: 400
      },
    },
    isDisabled: {
      true: {
        color: '#B0B0B0'
      },
    }
  },

  defaultVariants: {
    isSixMonthHistory: false,
  },
})

export const YearContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const GaryColorSpan = styled('span', {
  color: '#B0B0B0'
})

export const DarkGrayColorSpan = styled('span', {
  color: '#6B6B6B'
})

export const LottieSpan = styled('span', {
  fontFamily: 'Pretendard',
  color: '#868686',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  display: 'block',
  marginBottom: '16px',
})

export const CommentKeySpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
})

export const CommentValue = styled('span', {
  color: '#666',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginLeft: '4px'
})


