import React from 'react';

import {
  Container,
  ImageTextContainer,
  OperatingHourContainer,
  ParkingInfoSpan,
  ParkingTitleSpan,
  PaymentAccountDepositContainer,
  PaymentAccountDepositContentContainer,
  TimeOperatingHoursSpan,
} from './styles';

import { IcChargingSVG } from 'assets/ic';

interface Props {
  data: any;
}
const OperationInfo: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <ImageTextContainer>
        <ParkingInfoSpan>충전기 정보</ParkingInfoSpan>
      </ImageTextContainer>

      <div>
        <OperatingHourContainer>
          <ParkingTitleSpan>운영기관</ParkingTitleSpan>
          <TimeOperatingHoursSpan>{data.chargerStat.busiNm}</TimeOperatingHoursSpan>
        </OperatingHourContainer>

        <PaymentAccountDepositContainer>
          <ParkingTitleSpan>충전기 유형</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <TimeOperatingHoursSpan>
              {data.chargerStat.rapidTotalCnt !== 0 ? '급속' : ''}
              {data.chargerStat.rapidTotalCnt !== 0 && data.chargerStat.slowTotalCnt !== 0
                ? '/'
                : ''}
              {data.chargerStat.slowTotalCnt !== 0 ? '완속' : ''}
            </TimeOperatingHoursSpan>
          </PaymentAccountDepositContentContainer>
        </PaymentAccountDepositContainer>

        <PaymentAccountDepositContainer>
          <ParkingTitleSpan>운영시간</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <TimeOperatingHoursSpan>{data.result[0].useTime}</TimeOperatingHoursSpan>
          </PaymentAccountDepositContentContainer>
        </PaymentAccountDepositContainer>

        <PaymentAccountDepositContainer>
          <ParkingTitleSpan>주차비</ParkingTitleSpan>
          <PaymentAccountDepositContentContainer>
            <TimeOperatingHoursSpan>
              {data.result[0].parkingFree === 'Y' ? '무료' : '유료'}
            </TimeOperatingHoursSpan>
          </PaymentAccountDepositContentContainer>
        </PaymentAccountDepositContainer>
      </div>
    </Container>
  );
};

export default OperationInfo;
