import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import { IAuthCode, IValidateAuthCode } from 'models/Auth/AuthModel';
import authCodeService from 'service/Auth/authCodeService';

class AuthCodeStore {
  isAuthCodeLoading = false;
  error_msg: string | null = null;
  isAuthCodeSuccessful = false;
  isAuthCodeValidateLoading = false;
  isAuthCodeValidateSuccessful = false;

  mLinkUID = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuthCodeLoading(value: boolean) {
    this.isAuthCodeLoading = value;
  }

  setErrorMsg(value: string) {
    this.error_msg = value;
  }

  setIsAuthCodeSuccessful(value: boolean) {
    this.isAuthCodeSuccessful = value;
  }

  setIsAuthCodeValidateLoading(value: boolean) {
    this.isAuthCodeValidateLoading = value;
  }

  setIsAuthCodeValidateSuccessful(value: boolean) {
    this.isAuthCodeValidateSuccessful = value;
  }

  setMLinkUID(data: number) {
    this.mLinkUID = data;
  }

  async fetchAuthCode(requestBody: IAuthCode, iv: string, salt: string) {
    this.setIsAuthCodeLoading(true);
    this.error_msg = null;

    try {
      const response = await authCodeService.postAuthCode(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log('Received BaseError:', response);
        this.setErrorMsg(response.error_msg);
        this.setIsAuthCodeSuccessful(false);
      } else {
        this.setIsAuthCodeSuccessful(true);
      }
    } catch (error) {
      this.error_msg = '주만사 회원가입 중 오류가 발생했습니다.';
      this.setIsAuthCodeSuccessful(false);
    } finally {
      this.isAuthCodeLoading = false;
    }
  }

  async fetchValidateAuthCode(requestBody: IValidateAuthCode, iv: string, salt: string) {
    this.setIsAuthCodeValidateLoading(true);
    this.error_msg = null;

    try {
      const response = await authCodeService.postValidateAuthCode(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log('Received BaseError:', response);
        this.setErrorMsg(response.error_msg);
        this.setIsAuthCodeValidateSuccessful(false);
      } else {
        this.setIsAuthCodeValidateSuccessful(true);
      }
    } catch (error) {
      this.error_msg = '주만사 회원가입 인증번호 검증중 발생했습니다.';
      this.setIsAuthCodeValidateSuccessful(false);
    } finally {
      this.isAuthCodeValidateLoading = false;
    }
  }

  async fetchValidateAuthAndRegisterMember(
    requestBody: IValidateAuthCode,
    iv: string,
    salt: string,
  ) {
    this.error_msg = null;

    try {
      const response = await authCodeService.postValidateAuthAndRegisterMember(
        requestBody,
        iv,
        salt,
      );

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.res_code === 200) {
          this.setMLinkUID(response.data.mLinkUID);
        }
        return response;
      }
    } catch (error) {
      this.error_msg = '주만사 회원가입 인증번호 검증중 발생했습니다.';
    } finally {
    }
  }
}

export const authCodeStore = new AuthCodeStore();
