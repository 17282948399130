import React from 'react';
import { useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainer } from 'components/styles';

import Img from 'assets/images/img_area_marker.svg';
import Img2 from 'assets/images/img_marker_locate.svg';

const ImageLinkPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  return (
    <MainContainer>
      <Title
        title="이미지 링크 확인"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />

      <img width={100} height={100} src={Img2} />
    </MainContainer>
  );
};
export default ImageLinkPage;
