import { styled } from '@stitches/react';
import icCheck from 'assets/ic/ic_check_white.svg';
import { colors } from 'styles/colors';

export const PaymentContainer = styled('div', {
  margin: '20px 20px 0px',
  display: 'flex',
  flexDirection: 'column',
})

export const TicketInfoContainer4 = styled('div', {
  backgroundColor: '#F8F8FA',
  margin: '28px 20px 0px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '20px'
})

export const TicketSpaceBetween = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const ViewDivide3 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  margin: '20px 0px'
})

export const TicketTimeInfoContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '20px'
})

export const CarParkingInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const NotPastParkingContainer = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.white,
  overflow: 'hidden',
});

export const ListDotContainer = styled('div', {
  marginTop: '20px',
  color: '#6B6B6B',
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'Pretendard',
})

export const ListCheckContainer = styled('div', {
  marginTop: '20px',
})

export const ListItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  variants: {
    hasMargin: {
      true: { marginTop: '10px' },
      false: { marginTop: '0' }
    }
  },
  wordBreak: 'keep-all'
})

export const ListItemDotIc = styled('img', {
  alignSelf: 'flex-start',
  marginTop: '8px'
})

export const ListItemCheckIc = styled('img', {
  alignSelf: 'flex-start',
})

export const ListItemSpan = styled('span', {
  marginLeft: '8px',
})

export const SubTitleSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const PaymentInfoContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const ReceiptContainer = styled('div', {
  height: '28px',
  display: 'flex',
  paddingLeft: '14px',
  paddingRight: '14px',
  flexDirection: 'row',
  borderRadius: '14px',
  border: '1px solid #CCC', // Default border color
  justifyContent: 'center',
  alignItems: 'center'
})

export const ReceiptIc = styled('img', {
  width: '13px',
  height: '14px'
})

export const ReceiptSpan = styled('span', {
  color: '#555',
  fontSize: '13px',
  fontWeight: 'normal',
  marginLeft: '4px',
})

export const PaymentValue = styled('span', {
  fontSize: '15px',
  fontWeight: 500,
})

export const PaymentCancelSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: '#6B6B6B',
})

export const BottomButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  bottom: '0',
  left: '0',
  textAlign: 'center',
  position: 'relative',
  zIndex: 10,

  '@media (max-height: 667px)': {
    position: 'relative'
  },
})

export const BottomButtonFixedContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  position: 'fixed', // 하단 고정
  bottom: '0',
  left: '0',
  textAlign: 'center',
  zIndex: 10,
  backgroundColor: 'white', // 배경 추가 (필요하면)

  '@media (max-height: 667px)': {
    position: 'fixed' // 작은 화면에서도 고정 유지
  },
})


export const BottomPurpleButton = styled('button', {
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: colors.white,
  backgroundColor: colors.primary,
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  justifyContent: 'center'
})

export const PaymentCancelContainer = styled('div', {
  width: '100%',
  height: '46px',
  display: 'flex',
  marginTop: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  boxSizing: 'border-box',
})

export const BottomLeftButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
})

export const BottomRightButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
})

export const TerraceTowerBottomContentsContainer = styled('div', {
  display: 'flex', flexDirection: 'column', margin: '28px 20px 40px'
})

export const BottomPaymentTicketButton = styled('button', {
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: colors.white,
  backgroundColor: colors.primary,
  border: 'none',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  justifyContent: 'center'
})


export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',

  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
    backgroundImage: `url(${icCheck})`, // 불러온 이미지를 URL로 설정
    backgroundSize: '12px 12px', // 이미지 크기
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&:focus': {
    borderColor: '#8B63BB',
  },
})

export const CautionContainer = styled('div', {
  padding: '10px 16px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '10px',
})

export const WarningContainer = styled('div', {
  display: 'flex',
  padding: '12px',

  backgroundColor: '#F8F8FA',
  borderRadius: '4px',
  alignItems: 'center', // 상단 정렬
  justifyContent: 'flex-start', // 왼쪽 정렬
  gap: '10px', // 이미지와 텍스트 간격 조정
  '&:first-child': {
    marginTop: 0,
  },
  marginTop: '10px'
});

export const AgreeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '20px 20px 28px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const SeasonTicketUseTimeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '28px',
  justifyContent: 'space-between',
  textAlign: 'center',
})

export const SeasonTicketGapContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '11px',
})

export const SeasonTicketContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'right',
})

export const SeasonTicketDateContainer = styled('div', {
  borderRadius: '4px',
  backgroundColor: '#F2F4F7',
  padding: '4px 8px',
})

export const TicketMainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '20px'
})

export const TopContainer = styled('div', {
  margin: '20px 20px 0px'
})


export const ExclamationBlackIc = styled('img', {
  width: '18px',
  height: '18px'
})

export const CautionTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  borderRadius: '4px',
  border: '1px solid #E4E4E4',
  padding: '12px 10px',
})

export const AddressContainer = styled('div', {
  marginTop: '10px',
  display: 'flex',
})

export const RoadSearchContainer = styled('div', {
  height: '32px',
  display: 'flex',
  paddingLeft: '12px',
  paddingRight: '12px',
  flexDirection: 'row',
  borderRadius: '16px',
  border: '1px solid #CCC',
  justifyContent: 'center',
  alignItems: 'center'
})

export const TicketContainer1 = styled('div', {
  margin: '28px 20px 0px',
  backgroundColor: colors.primaryBackground,
  borderRadius: 10,
  padding: 20,
})

export const TicketInfoContainer1 = styled('div', {
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '20px'
})

export const TicketInfoContainer2 = styled('div', {
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '20px'
})

export const TicketInfoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  variants: {
    marginTopVariant: {
      first: { marginTop: '20px' },
      default: { marginTop: '10px' },
    },
  },
})

export const ParkingInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  variants: {
    isFirst: {
      true: { marginTop:'0px' },
      false: { marginTop: '8px' }
    }
  }
})

export const PaymentItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  variants: {
    isFirst: {
      true: { marginTop: '20px' },
      false: { marginTop: '10px' }
    }
  }
})

export const MiddleContainer1 = styled('div', {
  margin: '28px 20px 0px',
})

export const MiddleContainer2 = styled('div', {
  margin: '0px 20px',
})

export const TextAlignRightContainer = styled('div', {
  textAlign: 'right',
  flex: 1
})

export const BottomBannerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 20px 20px',
})

export const BottomServiceInfoContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  padding: '20px',
})

export const SmallButton = styled('button', {
  padding: '2px 10px',
  backgroundColor: colors.white,
  color: colors.primary,
  border: '1px solid #8B63BB',
  borderRadius: '20px',
  marginLeft: '8px',
})

export const TermsUnderLineContainer = styled('div', {
  marginTop: '16px',
  display: 'inline-block',
  borderBottom: `1px solid ${colors.gray09}`,
  paddingBottom: '2px',
})

export const StatusUnderlinedText = styled('p', {
  position: 'relative',
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
  color: colors.gray09,
  marginTop: '16px',

  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-1px',
    width: '100%',
    height: '1px',
    backgroundColor: colors.gray09
  },
});

export const PurpleMiddleButtonContainer = styled('div', {
  backgroundColor: colors.primary,
  borderRadius: '4px',
  margin: '28px 20px 40px',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '46px',
  color: colors.white
})

export const BottomDifferentParkingTicketButton = styled('button', {
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  justifyContent: 'center'
})

export const ViewDivide1 = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
})

export const ViewDivide2 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '16px'
})

export const PhotoCountContainer = styled('div', {
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: '#FFF',
  backgroundColor: 'rgba(26, 26, 26, 0.50)',
  height: '20px',
  width: '48px',
  borderRadius: '8px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontWeigh: 400,
  linHeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  lineHeight: 'normal',
  zIndex: 1,
})

export const RoadViewContainer = styled('div', {
  height: '186px',
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '5px',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const RoadViewSpan = styled('span', {
  marginLeft: '4px'
})

export const RoadViewIcon = styled('img', {
  width: '13px', height: '13px'
})

export const RoadViewTextContainer = styled('div', {
  position: 'absolute',
  top: '16px',
  right: '8px',
  color: '#FFF',
  backgroundColor: 'rgba(26, 26, 26, 0.50)',
  padding: '7px 12px',
  borderRadius: '8px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontWeigh: 400,
  linHeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  lineHeight: 'normal',
  zIndex: 1
})

export const UploadedImageContainer = styled('div', {
  width: '100%',
  height: '186px',
  position: 'relative',
})

export const UploadedImage = styled('img', {
  border: '1px solid #DDD',
  width: '100%',
  borderRadius: '5px',
  height: '186px',
  position: 'relative',
})

export const UploadImageSection = styled('div', {
  position: 'relative',
  height: '186px',
  marginTop: '28px',
  overflow: 'hidden',
  width: '100%',
  borderRadius: '5px',
})


export const NavigateItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  transition: 'background-color 0.3s, box-shadow 0.3s',
  marginTop: '10px',
  marginRight: '10px',
  padding: '5px',
  borderRadius: '5px',
})

export const NavigateItemImage = styled('img', {
  width: '45px',
  height: '45px',
  marginLeft: '49px',
})

export const NavigateCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  cursor: 'pointer',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 59,

  '&:checked': {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
  '&:checked::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 4,
    width: 5,
    height: 10,
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
})

export const NavigateUsuallyContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20
})

export const NavigateUsuallyCheckBox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #CCCCCC',
  borderRadius: '4px',
  outline: 'none',
  position: 'relative',
  backgroundColor: '#CCCCCC',

  '&::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 4,
    width: 5,
    height: 10,
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
    opacity: 1,
  },
  '&:checked': {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
})

export const CautionsAndUseSlotGuideListContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    first: {
      true: { marginTop: '20px' },
      false: {
        marginTop: '10px',
      },
    },
  },
});

export const UseSlotGuideListItemDotIc = styled('img', {
  alignSelf: 'flex-start',
  marginTop: 8,
  marginLeft: 8,
  marginRight: 8,
})

export const TimeTicketNotUseSlotGuideContain = styled('div', {
  display: 'flex', alignItems: 'center', marginTop: 28
})

export const BottomContentsContainer = styled('div', {
  display: 'flex', flexDirection: 'column', margin: '28px 20px 40px'
})



