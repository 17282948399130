import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CarInfoEditContainer,
  CarNumberContainer,
  CarNumberSpan,
  CarNumberTextSpan,
  CarNumberToggleContainer,
  CarRepresentTextContainer,
  ModifyDeleteContainer,
  ModifyDeleteTextSpan,
  ToggleButtonContainer,
} from './styles';
import TwoButtonModal from 'components/atoms/Modal/TwoButtonModal';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import { ICarListData } from 'models/Car/carListModel';
import { carStore } from 'stores/store/Car/carStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

interface CarInfoListProps {
  car: ICarListData;
  animate: boolean;
  onToggle: () => void;
  setIsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const CarInfoList: React.FC<CarInfoListProps> = ({ car, animate, onToggle, setIsPopup }) => {
  const navigate = useNavigate();

  const { memberUID } = userInfoStore;

  const [isCarNumberDeleteModal, setIsCarNumberDeleteModal] = useState(false);

  const carInfoDeleteModal = () => {
    setIsCarNumberDeleteModal(true);
  };

  const isMain = car.isMain === 'Y';

  useEffect(() => {
    if (isCarNumberDeleteModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isCarNumberDeleteModal]);

  return (
    <div>
      <CarInfoEditContainer isToggled={isMain} animate={animate}>
        <CarNumberToggleContainer>
          <CarNumberContainer>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
              <CarNumberTextSpan>차량번호</CarNumberTextSpan>
              {isMain && <CarRepresentTextContainer>대표차량</CarRepresentTextContainer>}
            </div>
            <CarNumberSpan>{car.carNumber}</CarNumberSpan>
            {car.taxReductionInfo && car.confirmStatus !== 'WAITING' ? (
              <span
                style={{
                  color: '#B0B0B0',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '18px',
                  marginTop: 7,
                }}
              >
                {car.taxReductionInfo}
              </span>
            ) : null}
          </CarNumberContainer>
          <ToggleButtonContainer isToggled={isMain} onClick={onToggle} />
          {/* onToggle 함수 호출 */}
        </CarNumberToggleContainer>
        <Divider style={{ backgroundColor: colors.gray02, marginTop: 20, marginBottom: 10 }} />
        <ModifyDeleteContainer>
          <ModifyDeleteTextSpan onClick={carInfoDeleteModal}>삭제</ModifyDeleteTextSpan>
          <span style={{ height: '100%', color: colors.gray02 }}>|</span>
          <ModifyDeleteTextSpan
            onClick={() => {
              navigate('/carInfoEdit', {
                state: {
                  car: JSON.stringify(car),
                },
              });
            }}
          >
            수정
          </ModifyDeleteTextSpan>
        </ModifyDeleteContainer>
      </CarInfoEditContainer>

      <TwoButtonModal
        isVisible={isCarNumberDeleteModal}
        onClose={() => setIsCarNumberDeleteModal(false)}
        title={'차량 번호를 삭제하시겠습니까?'}
        content={''}
        leftButtonText={'취소'}
        rightButtonText={'삭제'}
        rightClick={async () => {
          await carStore.fetchDeleteMemberCarInfo(car.carInfoUID, memberUID, car.isMain);
          setIsPopup(true);
        }}
      />
    </div>
  );
};

export default CarInfoList;
