import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapItem } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { Body, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { ProductDetailData, SaleHistoryResponse } from 'models/Share/shareProductDetailModel';
import { formatPriceWithCommas } from 'utils/number';
import { formatDateDay, formatDateRangeDay } from 'utils/dateUtils';

interface Props {
  onClick: (index: number) => void;
  onClick2: (index: number) => void;
  data: SaleHistoryResponse;
}

const ApproveRequest: React.FC<Props> = ({ onClick, onClick2, data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <Body weight={'semibold'} color={'black1'}>
          승인 요청
        </Body>
        <Body weight={'semibold'} color={'primary'} style={{ marginLeft: 4 }}>
          {data.totalWaitingCnt}
        </Body>
      </Row>
      {data?.waitingDriverList.map((el, index) => (
        <WrapItem key={index}>
          <Row
            justifyContent={'space-between'}
            style={{ marginBottom: 16 }}
            alignItems={'flex-start'}
          >
            <div style={{ marginRight: 10 }}>
              <SubHeadline weight={'semibold'} color={'black1'}>
                {el.carType}
              </SubHeadline>
              <SubHeadline weight={'semibold'} color={'black1'}>
                {formatDateRangeDay(el.useDate)}
              </SubHeadline>
            </div>
            {/*<Caption2 weight={'regular'} color={'gray05'}>*/}
            {/*  no. {el.saleHistoryUID}*/}
            {/*</Caption2>*/}
          </Row>
          <Row>
            <Caption2 weight={'regular'} color={'gray07'}>
              예상 정산금 : {formatPriceWithCommas(el.expectedSettlement)}원
            </Caption2>
          </Row>
          <Row>
            <Caption2 weight={'regular'} color={'gray07'}>
              승인 요청일 : {formatDateDay(el.reqApprovalDate)}
            </Caption2>
          </Row>
          <Row>
            <Caption2 weight={'regular'} color={'red'}>
              {el.approvalDeadLineStateStr}
            </Caption2>
          </Row>
          <Row style={{ gap: '10px', marginTop: 16 }}>
            <Button
              backgroundColor={colors.gray01}
              radius={4}
              style={{
                height: 40,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClick2(index);
              }}
            >
              <Caption2 weight={'semibold'} color={'gray06'}>
                거절
              </Caption2>
            </Button>
            <Button
              backgroundColor={colors.primary}
              radius={4}
              style={{
                height: 40,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClick(index);
              }}
            >
              <Caption2 weight={'semibold'} color={'white'}>
                승인
              </Caption2>
            </Button>
          </Row>
        </WrapItem>
      ))}
    </Container>
  );
};

export default ApproveRequest;
