import { makeAutoObservable } from 'mobx';
import BaseError from '../../../models/baseError';
import terraceTowerService from '../../../service/TerraceTower/terraceTowerService';
import { ITerraceTowerProductOrderData } from 'models/TerraceTower/terraceTowerProductOrder';

class TerraceTowerProductOrderStore {
  isLoading = false;
  error: string | null = null;
  getProductOrder: ITerraceTowerProductOrderData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setGetProductOrder(data: ITerraceTowerProductOrderData) {
    this.getProductOrder = data;
  }

  async fetchTerraceTowerProductOrder(
    productUID: string,
    mLinkUID: number,
    carNum: string,
    expectedEntryTime: string,
  ) {
    const requestBody = {
      productUID,
      mLinkUID,
      carNum,
      expectedEntryTime,
    };

    this.setLoading(true);

    const response = await terraceTowerService.postTerraceTowerProductOrder(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;
      if (data) {
        this.setGetProductOrder(data);
      }
      this.setLoading(false);
      return data;
    }
  }
}

export const terraceTowerProductOrderStore = new TerraceTowerProductOrderStore();
