import BaseService from 'service/baseService';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import {
  IAuthCode,
  IAuthCodeResponse,
  IValidateAuthCode,
  IValidateAuthCodeMember,
  IValidateAuthCodeMemberResponse,
  IValidateAuthCodeResponse,
} from 'models/Auth/AuthModel';

interface AuthEndpoints {
  AUTH_CODE: string;
  AUTH_CODE_VALIDATE: string;
  AUTH_CODE_VALIDATE_MEMBER: string;
}

class AuthService extends BaseService<AuthEndpoints> {
  apiEndpoints: AuthEndpoints = {
    AUTH_CODE: 'getAuthCode',
    AUTH_CODE_VALIDATE: 'validateAuthCode',
    AUTH_CODE_VALIDATE_MEMBER: 'validateAuthAndRegisterMember',
  };

  private handleErrorResponse(response: any): BaseError {
    const { res_code, err_msg } = response.data;

    if (!err_msg) {
      throw new Error('알 수 없는 오류 발생');
    }

    return new BaseError({
      status: res_code,
      error_msg: err_msg || '알 수 없는 오류 발생',
    });
  }

  // 인증번호 요청
  postAuthCode = async (
    requestBody: IAuthCode,
    iv: string,
    salt: string,
  ): Promise<IAuthCodeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE}`)
        .build<IAuthCodeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return this.handleErrorResponse(response);
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  // 인증번호 검증
  postValidateAuthCode = async (
    requestBody: IValidateAuthCode,
    iv: string,
    salt: string,
  ): Promise<IValidateAuthCodeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE_VALIDATE}`)
        .build<IValidateAuthCodeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return this.handleErrorResponse(response);
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  // 인증번호 검증 mLinkUID 응답
  postValidateAuthAndRegisterMember = async (
    requestBody: IValidateAuthCodeMember,
    iv: string,
    salt: string,
  ): Promise<IValidateAuthCodeMemberResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE_VALIDATE_MEMBER}`)
        .build<IValidateAuthCodeMemberResponse>();

      return response.data;
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new AuthService();
