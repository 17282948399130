import React, { useEffect, useRef, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  MyParkingTicketColumnContainer,
  MyParkingTicketFlexStartContainer,
  MyParkingTicketPassTypeContainer,
  NotUseParkingContainer,
  SpinnerContainer,
  TicketContentsContainer,
  TicketContentsDetailContainer,
  TicketItemContainer,
  TicketTopContainer,
  TicketTypeContainer,
} from 'pages/MyParkingTicket/styles';
import { Body, Caption1, Caption2, Caption3 } from 'styles/typography';
import { colors } from 'styles/colors';
import { myParkingUseHistoryStore } from 'stores/store/MyParkingTicket/myParkingUseListStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import Button from 'components/atoms/Button/Button';
import Row from 'components/atoms/Row';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { moveJSP } from 'utils/common';
import { moveToPage } from 'utils/deviceUtils';

const MyParkingUseList = observer(() => {
  const navigate = useNavigate();
  // TODO: 추후 작업 세팅 필요
  const isFirstOrder = true;
  const bottomRef = useRef<HTMLDivElement>(null);

  const { isLoading, currentPage, parkingUseHistoryList, totalCount, itemsPerPage } =
    myParkingUseHistoryStore;

  const { memberUID } = userInfoStore;

  const TICKET_TYPE_SEASON = '정기권';
  const TICKET_TYPE_TIME = '시간권';
  const TICKET_PART_TIME = '시간제';

  const TICKET_STATUS_PARKING = '주차중';
  const TICKET_STATUS_WAITING_PAYMENT = '결제대기';
  const TICKET_STATUS_WAITING_APPROVAL = '승인대기';

  useEffect(() => {
    if (memberUID) {
      const fetchData = async () => {
        try {
          const response = await myParkingUseHistoryStore.fetchGetMyParkingUseHistory(memberUID, 1);
          console.log('Response:', response);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData().catch(error => {
        console.error('point history bottom List:', error);
      });
    }
  }, [memberUID]);

  useEffect(() => {
    if (memberUID == null) {
      console.warn('memberUID is null, skipping fetch.');
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isLoading && currentPage * itemsPerPage < totalCount) {
            const currentScrollY = handleScroll();
            try {
              await myParkingUseHistoryStore.fetchGetMyParkingUseHistory(
                memberUID,
                currentPage + 1,
              );
              window.scrollTo(0, currentScrollY);
            } catch (error) {
              console.error('Error fetching parking history:', error);
            }
          }
        });
      },
      { threshold: 1.0 },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [isLoading, currentPage, totalCount, itemsPerPage, memberUID]);

  const mapTicketTypeToVariant = (ticketType: string) => {
    switch (ticketType) {
      case TICKET_TYPE_SEASON:
        return 'seasonTicket';
      case TICKET_TYPE_TIME:
        return 'default';
      case TICKET_PART_TIME:
        return 'default';
      default:
        return 'default';
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    return currentScrollY;
  };

  return (
    <div style={{ backgroundColor: colors.gray10, overflowY: 'auto' }}>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : parkingUseHistoryList.length === 0 ? (
        <NotUseParkingContainer>
          <Caption1 weight={'medium'} color={'gray09'}>
            아직 이용한 주차장이 없어요.
          </Caption1>
          <Button
            style={{
              height: '2.5rem',
              marginTop: 12,
              marginBottom: 40,
              paddingLeft: 12,
              paddingRight: 16,
            }}
            radius={20}
            onClick={() => {
              moveJSP();
            }}
          >
            <Row>
              <Caption1 weight={'medium'} color={'primary'} style={{ marginLeft: 4 }}>
                이용권 보러가기
              </Caption1>
            </Row>
          </Button>
        </NotUseParkingContainer>
      ) : (
        <div>
          {parkingUseHistoryList.map((item, index) => (
            <TicketItemContainer
              ref={bottomRef}
              key={index}
              onClick={() => {
                // TODO: 리엑트 페이지 작업 완료 api 엮고 주석 처리된 페이지로 이동 navigate 시키면 됨
                //
                // sessionStorage.setItem('status', item.status)
                // sessionStorage.setItem('address', item.lotAddress || '')
                // sessionStorage.setItem('lotName', item.lotName || '')
                //
                //
                // // 정기권
                // if (item.passType === TICKET_TYPE_SEASON) {
                //   navigate('/myParkingSeasonTicket');
                //   return
                // }
                //
                // // 시간권
                // if (item.passType === TICKET_TYPE_TIME) {
                //   navigate('/myParkingTimeTicket');
                // }
                //
                // 시간제
                if (item.passType === TICKET_PART_TIME) {
                  window.location.href = item.paymentLink;
                  return;
                }

                if (item.passType === TICKET_TYPE_TIME) {
                  navigate(`/terraceTowerDetail?saleHistoryUID=${item.saleHistoryUID}`);
                  return;
                }
                moveToPage(item.paymentLink);
              }}
            >
              <TicketTopContainer>
                <Row justifyContent={'space-between'}>
                  {item.status === TICKET_STATUS_WAITING_PAYMENT ? (
                    <Caption2 weight="semibold" color="red">
                      {item.status}
                    </Caption2>
                  ) : (
                    <Caption2 weight="semibold" color="primary">
                      {item.status}
                    </Caption2>
                  )}
                  <TicketTypeContainer ticketType={mapTicketTypeToVariant(item.passType)}>
                    {item.passType === TICKET_TYPE_SEASON ? (
                      <Caption3 weight="bold" color="primary">
                        {item.passType}
                      </Caption3>
                    ) : (
                      <Caption3 weight="bold" color="blue">
                        {item.passType}
                      </Caption3>
                    )}
                  </TicketTypeContainer>
                </Row>
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: 'calc(100% - 66px)',
                  }}
                >
                  <Body
                    weight={'medium'}
                    color={'black1'}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '100%',
                    }}
                  >
                    {item.passType === TICKET_TYPE_TIME
                      ? item.productName
                      : item.passType === TICKET_PART_TIME
                        ? item.regionRealCode + ' 구획 이용권'
                        : item.passType}
                  </Body>
                </div>
              </TicketTopContainer>

              <TicketContentsContainer>
                <TicketContentsDetailContainer>
                  <Caption2
                    style={{
                      width: '28px',
                    }}
                    weight={'regular'}
                    color={'gray06'}
                  >
                    주소
                  </Caption2>
                  <Caption2
                    style={{
                      marginLeft: '8px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '100%',
                    }}
                    weight={'medium'}
                    color={'gray08'}
                  >
                    {item.lotAddress}
                  </Caption2>
                </TicketContentsDetailContainer>

                <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                  <Caption2
                    style={{
                      width: '28px',
                    }}
                    weight={'regular'}
                    color={'gray06'}
                  >
                    차량
                  </Caption2>
                  <Caption2
                    style={{ marginLeft: '8px', width: '100%' }}
                    weight={'regular'}
                    color={'gray08'}
                  >
                    {item.carNumber}
                  </Caption2>
                </TicketContentsDetailContainer>

                <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                  <Row style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <Caption2 style={{ width: 28 }} weight="regular" color="gray06">
                      이용
                    </Caption2>
                    <MyParkingTicketPassTypeContainer>
                      {(() => {
                        if (item.passType === TICKET_PART_TIME) {
                          if (item.status === TICKET_STATUS_PARKING) {
                            return (
                              <MyParkingTicketColumnContainer>
                                <MyParkingTicketFlexStartContainer>
                                  <Caption2
                                    weight="medium"
                                    color="gray08"
                                    style={{ marginRight: '8px' }}
                                  >
                                    {item.parkingStartDate}
                                  </Caption2>
                                </MyParkingTicketFlexStartContainer>
                                <div>
                                  <Caption2 weight="medium" color="primary">
                                    {item.parkingStartTime} 부터 주차 시작
                                  </Caption2>
                                </div>
                              </MyParkingTicketColumnContainer>
                            );
                          }
                          return (
                            <Caption2 weight="medium" color="gray08">
                              {item.parkingStartDateTime}
                            </Caption2>
                          );
                        }

                        if (item.passType === TICKET_TYPE_SEASON) {
                          const isPending =
                            item.status === TICKET_STATUS_WAITING_PAYMENT ||
                            item.status === TICKET_STATUS_WAITING_APPROVAL;
                          return (
                            <Caption2 weight={'medium'} color={isPending ? 'gray08' : 'primary'}>
                              {item.usingTime}
                            </Caption2>
                          );
                        }

                        if (item.passType === TICKET_TYPE_TIME) {
                          return (
                            <Caption2 weight={'medium'} color={'primary'}>
                              {item.usingTime}
                            </Caption2>
                          );
                        }
                        return (
                          <Caption2 weight={'medium'} color={'gray08'}>
                            {item.usingTime}
                          </Caption2>
                        );
                      })()}
                    </MyParkingTicketPassTypeContainer>
                  </Row>
                </TicketContentsDetailContainer>

                {item.reductionInfo && (
                  <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                    <Caption2 weight={'regular'} color={'gray06'}>
                      감면
                    </Caption2>
                    <Caption2
                      style={{
                        marginLeft: '8px',
                      }}
                      weight={'medium'}
                      color={'gray08'}
                    >
                      {item.reductionInfo}
                    </Caption2>
                  </TicketContentsDetailContainer>
                )}
                {item.refundDates && (
                  <TicketContentsDetailContainer
                    style={{
                      marginTop: '20px',
                      backgroundColor: '#F2F4F7',
                      borderRadius: '4px',
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <div style={{ margin: 10, width: '100%', overflow: 'hidden' }}>
                      <Caption3 weight={'semibold'} color={'gray07'}>
                        부분환불
                      </Caption3>
                      <Caption3
                        color={'gray07'}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          width: '100%',
                          marginTop: 8,
                        }}
                        weight={'regular'}
                      >
                        {item.refundDates}
                      </Caption3>
                    </div>
                  </TicketContentsDetailContainer>
                )}
              </TicketContentsContainer>
            </TicketItemContainer>
          ))}
          <div style={{ height: 40 }}></div>
        </div>
      )}
    </div>
  );
});
export default MyParkingUseList;
