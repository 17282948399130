import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import {
  ChoiceDateContainer,
  ChoiceDateItem,
  ContentsContainer,
  Dialog,
  SubTitleSpan,
  TitleSpan,
} from './styles';
import Title from '../../Title/PageTitle';
import { BottomButtonContainer, ButtonEnableDisable } from '../../Button/styles';
import { MainContainer, MainContainerNoMargin } from '../../../styles';
import { moveToPage, setDial } from 'utils/deviceUtils';
import { monthlyParkingStore } from 'stores/store/Monthly/monthlyParkingStore';
import { monthlyStore } from 'stores/store/Monthly/monthlyStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { MONTHLY_PAYMENT_URL } from 'constants/baseConstants';

interface DateChoiceBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  detailClose: () => void;
}

const DateChoiceBottomModal: React.FC<DateChoiceBottomModalProps> = ({
  isVisible,
  onClose,
  detailClose,
}) => {
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [dates, setDates] = useState<string[]>([]);

  const { parkingProduct } = monthlyParkingStore;
  const { memberUID } = userInfoStore;
  const { createOrderRes } = monthlyStore;

  const generateDates = (baseDate: string) => {
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

    // 주어진 날짜를 Date 객체로 변환
    const base = new Date(baseDate);

    // 날짜 배열 생성
    const dates = [];

    // 7일 간의 날짜 생성
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(base);
      newDate.setDate(base.getDate() + i); // 기준 날짜에 i일을 더함

      // 날짜 형식 만들기
      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작
      const day = String(newDate.getDate()).padStart(2, '0');
      const dayOfWeek = daysOfWeek[newDate.getDay()]; // 요일

      // "YYYY년 MM월 DD일 (요일)" 형식으로 문자열 만들기
      const formattedDate = `${year}년 ${month}월 ${day}일 (${dayOfWeek})`;
      dates.push(formattedDate);
    }

    return dates;
  };

  useEffect(() => {
    if (parkingProduct) {
      setDates(generateDates(parkingProduct.availableStartDate));
    }
  }, [parkingProduct]);

  const formatDate = (inputDate: string) => {
    // '2025년 02월 15일 (월)' 형태의 문자열에서 년, 월, 일, 괄호 부분을 제거하고, 원하는 형식으로 변환
    const match = inputDate.match(/(\d{4})년 (\d{2})월 (\d{2})일/);
    if (!match) {
      throw new Error('잘못된 날짜 형식입니다.');
    }

    const [, year, month, day] = match;

    // YYYY-MM-DD 형식으로 반환
    return `${year}-${month}-${day}`;
  };

  return (
    <Dialog isVisible={isVisible}>
      <MainContainerNoMargin>
        <Title
          title="희망일 선택"
          leftArrowButtonVisible={true}
          leftOnClick={onClose}
          rightTextButtonVisible={true}
          rightOnClick={() => setDial('1666-4369')}
          rightTitle="문의하기"
        />
        <ContentsContainer>
          <TitleSpan>이용 시작 희망일을 선택해 주세요.</TitleSpan>
          <SubTitleSpan>희망일이 없을 경우 고객센터로 문의주세요</SubTitleSpan>
          <ChoiceDateContainer>
            {dates.map((date, index) => (
              <ChoiceDateItem
                key={index}
                isSelected={selectedDate === index}
                onClick={() => setSelectedDate(index)}
                isFirst={index === 0}
              >
                {date}
              </ChoiceDateItem>
            ))}
          </ChoiceDateContainer>
        </ContentsContainer>
      </MainContainerNoMargin>
      <BottomButtonContainer>
        <ButtonEnableDisable
          onClick={async () => {
            if (selectedDate !== null && parkingProduct?.productUID) {
              const res = await monthlyStore.fetchCreateMonthlyPassOrder(
                parkingProduct?.productUID,
                memberUID,
                formatDate(dates[selectedDate]),
              );

              if (typeof res !== 'string') {
                moveToPage(`${MONTHLY_PAYMENT_URL}${res.saleHistoryUID}`);
                onClose();
                detailClose();
              } else {
                alert(res);
              }
            }
          }}
          disabled={selectedDate === null}
        >
          다음
        </ButtonEnableDisable>
      </BottomButtonContainer>
    </Dialog>
  );
};

export default observer(DateChoiceBottomModal);
