import { ITaxReductionData } from 'models/TaxReduction/taxReductionInsertModel';

export const sendFormData = (reductionFile: string[], data: ITaxReductionData) => {
  const formData = new FormData();

  reductionFile.forEach((base64Data: any, index) => {
    const [metadata, base64Content] = base64Data.split(',');
    const mimeType = metadata.match(/:(.*?);/)[1];
    const binary = atob(base64Content);
    const arrayBuffer = new Uint8Array(binary.length);

    for (let i = 0; i < binary.length; i++) {
      arrayBuffer[i] = binary.charCodeAt(i);
    }

    // Blob 생성
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // 파일로 FormData에 추가
    formData.append('files', blob, `file${index + 1}.png`);
  });

  // JSON 데이터 추가
  formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));

  return formData;
};
