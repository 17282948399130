import { useEffect, useState } from 'react';

const useMyPosition = () => {
  const [position, setPosition] = useState({
    latitude: '',
    longitude: '',
    sid: '',
  });

  const getMyPosition = async () => {
    if (window.arsparkingapp) {
      window.arsparkingapp.getMyPosition();
    }

    if (window.webkit) {
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'getMyPosition',
      });
    }
  };

  window.setMyPosition = (latitude: string, longitude: string, sid: string) => {
    setPosition({
      latitude,
      longitude,
      sid,
    });
  };

  useEffect(() => {
    getMyPosition();
  }, []);

  return { position };
};

export default useMyPosition;
