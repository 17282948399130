import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '40px 0px',
});

export const WrapEmptyItem = styled('div', {
  width: 'calc(100%)',
  height: 72,
  padding: 16,
  boxSizing: 'border-box',
  marginBottom: 16,
  border: '1px dashed #CCC',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
});

export const WrapItem = styled('div', {
  width: 'calc(100%)',
  height: 72,
  padding: '16px 0px 16px 16px',
  boxSizing: 'border-box',
  marginBottom: 16,
  borderRadius: 8,
  display: 'flex',
  backgroundColor: colors.primaryBackground,
  justifyContent: 'space-between',
  alignItems: 'center',
});
