import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';

import carService from 'service/Car/carService';
import { ICarListData } from 'models/Car/carListModel';
import { ICarInsert } from 'models/Car/carInsertModel';
import { ICarUpdateMain } from 'models/Car/carUpdateMainModel';

class CarStore {
  isGetMyCarInfoLoading: boolean = false;
  isInsertMemberCarInfoLoading: boolean = false;
  isDeleteMemberCarInfoLoading: boolean = false;
  isUpdateCarMainLoading: boolean = false;

  carList: ICarListData[] | [] = [];
  carNumber: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setIsGetMyCarInfoLoading(value: boolean) {
    this.isGetMyCarInfoLoading = value;
  }

  setIsInsertMemberCarInfoLoading(value: boolean) {
    this.isInsertMemberCarInfoLoading = value;
  }

  setIsDeleteMemberCarInfoLoading(value: boolean) {
    this.isDeleteMemberCarInfoLoading = value;
  }

  setIsUpdateCarMainLoading(value: boolean) {
    this.isUpdateCarMainLoading = value;
  }

  setCarList = (data: ICarListData[]) => {
    this.carList = data;
  };

  setCarNumber = (data: string) => {
    this.carNumber = data;
  };

  async fetchGetMemberCarInfo(mLinkUID: number) {
    this.setIsGetMyCarInfoLoading(true);

    const requestBody = {
      mLinkUID,
    };

    try {
      const response = await carService.getMemberCarInfo(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      if (!response?.data) {
        console.error('No account data found');
        return;
      }
      this.setCarList(response.data);
      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsGetMyCarInfoLoading(false);
    }
  }

  async fetchInsertMemberCarInfo(requestBody: ICarInsert) {
    this.setIsInsertMemberCarInfoLoading(true);
    this.setCarNumber(requestBody.carNumber);
    try {
      const response = await carService.insertMemberCarInfo(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      // setCarNumber(response.data);
      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsInsertMemberCarInfoLoading(false);
    }
  }

  async fetchDeleteMemberCarInfo(carInfoUID: number, mLinkUID: number, isMain: string) {
    this.setIsDeleteMemberCarInfoLoading(true);

    const requestBody = {
      carInfoUID,
      mLinkUID,
      isMain,
    };

    try {
      const response = await carService.deleteMemberCarInfo(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      // 데이터가 없을 경우 처리
      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsDeleteMemberCarInfoLoading(false);
    }
  }

  async fetchUpdateCarMain(requestBody: ICarUpdateMain) {
    this.setIsUpdateCarMainLoading(true);

    try {
      const response = await carService.updateCarMain(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      // 데이터가 없을 경우 처리
      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsUpdateCarMainLoading(false);
    }
  }
}

export const carStore = new CarStore();
