import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Row from 'components/atoms/Row';
import { Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import { colors } from 'styles/colors';
import {
  TicketContainer,
  ViewDivide,
} from 'containers/emptyParkingArea/EmptyParkingApplyHistoryList/styles';
import PurpleTwoButtonCheckModal from 'components/atoms/Modal/PurpleTwoButtonCheckModal';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { emptyParkingStore } from 'stores/store/EmptyParking/EmptyParkingStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { SpinnerContainer } from 'pages/Point/PointEarnExpectedList/styles';
import { IEmptyParkingRequest } from 'models/EmptyParking/emptyParkingModel';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { truncateText } from 'utils/common';
import { formatDateMonthDay } from 'utils/dateUtils';

import icExclamationBlackSVG from 'assets/ic/ic_exclamation_black.svg';
import icCloseBlack28SVG from 'assets/ic/ic_close_black_28.svg';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import lottieEmpty from 'assets/lottie/lottieEmpty80.json';
import MonthlyParkingInfoDetailBottomModal from 'components/atoms/Modal/MonthParkingInfoDetailBottomModal';

interface Props {
  onClickItem: (productUID: number) => void;
  onOpen: () => void;
}

const EmptyParkingApplyHistoryList = observer(({ onClickItem, onOpen }: Props) => {
  const { showToast } = useToast();

  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isNotificationCancelModal, setIsNotificationCancelModal] = useState(false);
  const [selectedWaitingUID, setSelectedWaitingUID] = useState<number | null>(null);

  const { phoneNumber } = userInfoStore;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const { parkingHistory, isLoading, isCancelLoading, isScrollLoading } = emptyParkingStore;
  const { memberUID } = userInfoStore;

  const requestBody: IEmptyParkingRequest = {
    mLinkUID: memberUID,
  };

  useEffect(() => {
    if (phoneNumber) {
      const fetchData = async () => {
        emptyParkingStore.setLoading(true);
        try {
          await emptyParkingStore.fetchEmptyParkingHistory(requestBody, false);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          emptyParkingStore.setLoading(false);
        }
      };

      fetchData().catch(error => {
        console.error('point history bottom List:', error);
      });
    }
  }, [phoneNumber]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !emptyParkingStore.isLoading &&
      !emptyParkingStore.isEnd
    ) {
      try {
        await emptyParkingStore.fetchEmptyParkingHistory(requestBody, true);
      } catch (error) {
        console.error('데이터 로드 에러:', error);
      }
    }
  };

  const handleCloseButtonClick = async (waitingUID: number) => {
    try {
      emptyParkingStore.setCancelLoading(true);
      await emptyParkingStore.cancelParking(waitingUID);
    } catch (error) {
      console.error('Error during close button click:', error);
    } finally {
      emptyParkingStore.setCancelLoading(false);
    }
  };

  return (
    <div ref={containerRef}>
      {isLoading || isCancelLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : parkingHistory.length === 0 ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie options={defaultOptions} height={80} width={80} />
          <Caption2 weight={'regular'} color={'gray07'}>
            빈자리 신청한 내역이 없어요.
          </Caption2>
        </div>
      ) : (
        parkingHistory.map((item, index) => (
          <div
            key={index}
            style={{ margin: '20px 20px 0px' }}
            onClick={() => {
              if (item.operationStatus !== 'STOP') {
                onClickItem(item.productUID);
                onOpen();
              }
            }}
          >
            <Row justifyContent={'space-between'}>
              <SubHeadline
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: item.operationStatus === 'STOP' ? colors.gray06 : colors.black3,
                }}
                weight={'medium'}
                color={'gray07'}
              >
                {item.productName}
              </SubHeadline>
              <div
                onClick={async e => {
                  e.stopPropagation();
                  if (item.operationStatus === 'STOP') {
                    await handleCloseButtonClick(item.waitingUID);
                  } else {
                    setSelectedWaitingUID(item.waitingUID);
                    setIsNotificationCancelModal(true);
                  }
                }}
              >
                <img src={icCloseBlack28SVG} alt={'닫기 아이콘'} />
              </div>
            </Row>

            <TicketContainer
              onClick={() => {
                if (item.operationStatus === 'SALE') {
                  navigate(item.paymentLink);
                }
              }}
            >
              <Row
                style={{
                  marginBottom: 8,
                }}
              >
                <Caption3 weight={'regular'} color={'gray06'} style={{ width: 50 }}>
                  위치
                </Caption3>
                <Caption3 weight={'medium'} color={'gray07'} style={{ marginLeft: 8 }}>
                  {item.location}
                </Caption3>
              </Row>
              <Row>
                <Caption3 weight={'regular'} color={'gray06'} style={{ width: 50 }}>
                  신청일자
                </Caption3>
                <Caption3 weight={'medium'} color={'gray07'} style={{ marginLeft: 8 }}>
                  {formatDateMonthDay(item.regDate)}
                </Caption3>
              </Row>
            </TicketContainer>
            {item.operationStatus === 'STOP' && (
              <Caption3
                weight={'medium'}
                color={'red'}
                style={{
                  marginTop: '10px',
                }}
              >
                미운영중인 상품이에요.
              </Caption3>
            )}
            {index !== parkingHistory.length - 1 && <ViewDivide />}
          </div>
        ))
      )}

      {parkingHistory.length !== 0 && (
        <Row
          style={{
            margin: '30px 20px 40px',
            borderRadius: '4px',
            border: `1px solid ${colors.gray02}`,
            padding: '10px 12px',
            whiteSpace: 'pre-wrap',
          }}
        >
          <img src={icExclamationBlackSVG} alt={'검정 배경 느낌표'} />
          <Caption2 style={{ marginLeft: 8 }} color={'gray09'} weight={'regular'}>
            {`주차장이 판매중으로 변경되면 알림을 보내요.\n선착순으로 이용신청 받으니 유의해 주세요.`}
          </Caption2>
        </Row>
      )}
      <PurpleTwoButtonCheckModal
        isVisible={isNotificationCancelModal}
        onClose={() => {
          setIsNotificationCancelModal(false);
        }}
        title={'빈자리 알림을 취소하시나요?'}
        content={
          selectedWaitingUID !== null ? (
            <>
              <Caption1 weight={'regular'} color={'gray08'}>
                {`[${truncateText(
                  parkingHistory.find(item => item.waitingUID === selectedWaitingUID)
                    ?.productName ?? '',
                  5,
                )}]`}
                {parkingHistory.find(item => item.waitingUID === selectedWaitingUID)?.location}
              </Caption1>
            </>
          ) : null
        }
        leftButtonText={'취소'}
        rightButtonText={'네, 취소할래요'}
        rightClick={async () => {
          if (selectedWaitingUID) {
            await handleCloseButtonClick(selectedWaitingUID);
            showToast('빈자리 알림을 취소 완료했어요.', icSuccessCheckGreen);
            setIsNotificationCancelModal(false);
          }
        }}
      />
    </div>
  );
});

export default EmptyParkingApplyHistoryList;
