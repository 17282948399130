import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import monthlyAddressService from 'service/Monthly/monthlyAddressService';
import { IDesiredAddressData, IDesiredAddressResponse } from 'models/Monthly/desiredAddressModel';
import { IDesiredAddressInsert } from 'models/Monthly/desiredAddressInsertModel';
import { ICreateMonthlyPassOrderData } from 'models/Monthly/createMonthlyPassOrderModel';
import monthlyParkingService from 'service/Monthly/monthlyParkingService';

class MonthlyStore {
  isLoading: boolean = false;

  parkingDesiredArea: IDesiredAddressData[] | [] = [];
  createOrderRes: ICreateMonthlyPassOrderData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setParkingDesiredArea = (data: IDesiredAddressData[]) => {
    this.parkingDesiredArea = data;
  };

  setCreateOrderRes = (data: ICreateMonthlyPassOrderData) => {
    this.createOrderRes = data;
  };

  async fetchGetDesiredAddrs(mLinkUID: number) {
    this.setIsLoading(true);

    const requestBody = {
      mLinkUID,
    };

    try {
      const response = await monthlyAddressService.getDesiredAddrs(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          this.setParkingDesiredArea(response.data);
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchInsertDesiredAddr(requestBody: IDesiredAddressInsert) {
    this.setIsLoading(true);
    try {
      const response = await monthlyAddressService.insertDesireAddr({ ...requestBody });

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchDeleteDesireAddr(addrUID: number) {
    this.setIsLoading(true);

    const requestBody = {
      addrUID,
    };

    try {
      const response = await monthlyAddressService.deleteDesireAddr(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchCreateMonthlyPassOrder(productUID: number, mLinkUID: number, useStartDate: string) {
    this.setIsLoading(true);

    const requestBody = {
      productUID,
      mLinkUID,
      useStartDate,
    };
    let response;
    try {
      response = await monthlyParkingService.createMonthlyPassOrder(requestBody);

      if (response instanceof BaseError) {
        console.error(response.error_msg);
        throw response;
      }

      // 정상 응답인데 데이터가 없을 경우 예외 발생
      if (!response.data || !response.data.saleHistoryUID) {
        return response.err_msg;
      }
      // 성공 케이스
      this.setCreateOrderRes(response.data);
      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
      alert(error || '오류가 발생했습니다. 다시 시도해주세요.');
      throw error;
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchCreateWaitingData(
    productUID: number,
    mLinkUID: number,
    slotUID: number,
    waitingType: string,
    funnel: string,
  ) {
    this.setIsLoading(true);

    const requestBody = {
      productUID,
      mLinkUID,
      slotUID,
      waitingType,
      funnel,
    };
    let response;
    try {
      response = await monthlyParkingService.createWaitingData(requestBody);

      if (response instanceof BaseError) {
        console.error(response.error_msg);
        throw response;
      }

      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
      alert(error || '오류가 발생했습니다. 다시 시도해주세요.');
      throw error;
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const monthlyStore = new MonthlyStore();
