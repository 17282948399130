import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie';

import { Container, WrapItem } from './styles';
import Row from 'components/atoms/Row';
import { Caption2, Caption3, SubHeadline } from 'styles/typography';
import { shareStore } from 'stores/store/Share/shareStore';
import { IcArrowDown16SVG, IcArrowUp16SVG } from 'assets/ic';

import lottieEmpty from 'assets/lottie/lottieEmpty80.json';
import Content from 'containers/notice/Content';
import { noticeStore } from 'stores/store/Notice/noticeStore';
import { formatDateDay } from 'utils/dateUtils';
import { isIOS } from 'react-device-detect';

interface Props {
  noticeUID: number;
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: lottieEmpty,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const EventList: React.FC<Props> = observer(({ noticeUID }) => {
  const navigate = useNavigate();
  const observerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const itemOffsets = useRef<number[]>([]); // 각 아이템의 초기 위치 저장
  const hasScrolled = useRef(false);

  const [pageNum, setPageNum] = useState(1);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const { events } = noticeStore;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    let response;

    response = await noticeStore.fetchGetEvents({
      category: 'event',
      pageNum,
    });

    if (response) {
      const currentCount = noticeStore.events.length;
      setHasMore(currentCount < noticeStore.noticeCount);

      if (currentCount < noticeStore.eventCount) {
        setPageNum(pageNum + 1);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!events || hasScrolled.current) return;

    // 렌더링 후 아이템의 초기 위치 저장
    itemOffsets.current = itemRefs.current.map(ref => ref?.offsetTop || 0);

    setTimeout(() => {
      const targetIndex = events.findIndex(event => event.noticeUID === Number(noticeUID));
      if (targetIndex !== -1) {
        toggleOpen(targetIndex);
        hasScrolled.current = true;
      }
    }, 100);
  }, [events]);

  const toggleOpen = (index: number) => {
    // 이미 열린 아이템을 닫고 클릭한 아이템을 열거나, 클릭한 아이템을 닫음
    setOpenIndex(prev => (prev === index ? null : index));
    setTimeout(() => {
      const itemPosition = itemOffsets.current[index ?? 0];
      let TOP_HEIGHT = isIOS ? 96 : 66;

      window.scrollTo({
        top: itemPosition - TOP_HEIGHT, // 아이템 상단을 기준으로 66px 위로 스크롤 (헤더 크기 고려)
        behavior: 'smooth',
      });
    }, 100);
  };

  return (
    <Container>
      {events?.length !== 0 ? (
        <div>
          {events?.map((el, index: number) => {
            const isOpen = openIndex === index;
            return (
              <WrapItem
                key={index}
                onClick={() => {
                  toggleOpen(index);
                }}
                ref={el => (itemRefs.current[index] = el)}
              >
                <Row justifyContent={'space-between'} alignItems={'center'}>
                  <SubHeadline
                    weight={isOpen ? 'bold' : 'medium'}
                    color={'black3'}
                    style={{
                      wordBreak: 'break-all',
                      overflow: 'hidden',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                      WebkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                      width: 'calc(100% - 40px)',
                    }}
                  >
                    {el.title}
                  </SubHeadline>
                  {isOpen ? <IcArrowUp16SVG /> : <IcArrowDown16SVG />}
                </Row>
                <Caption3 weight={'medium'} color={'gray05'} style={{ marginTop: 10 }}>
                  {formatDateDay(el.regDate)}
                </Caption3>

                {isOpen ? <Content>{el.content}</Content> : null}
              </WrapItem>
            );
          })}
          <div ref={observerRef}></div>
        </div>
      ) : (
        <div
          style={{
            position: 'absolute', // 화면 기준으로 절대 위치
            top: '50%', // 화면의 세로 가운데
            left: '50%', // 화면의 가로 가운데
            transform: 'translate(-50%, -50%)', // 정확히 중앙 정렬
          }}
        >
          <Lottie options={defaultOptions} height={80} width={80} />
          <Caption2 weight={'medium'} color={'gray08'} style={{ textAlign: 'center' }}>
            진행 중인 이벤트가 없어요
          </Caption2>
        </div>
      )}
    </Container>
  );
});

export default EventList;
