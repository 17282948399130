import { styled } from '@stitches/react';

export const MinganInfoModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto', // 모달의 높이 설정
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 25px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' }, // 모달을 화면 위로 슬라이드
      false: { transform: 'translateY(100%)' }, // 화면 아래로 숨김
    },
    isScroll: {
      true: {
        height: '360px',
      },
      false: {
        height: 'auto',
      },
    },
  },
});

export const TitleContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#1A1A1A',
});

export const MinganListNumberContainer = styled('div', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'right',
  marginTop: '5px',
  marginBottom: 10,
});

export const TicketContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  padding: '14px 20px',
  marginTop: '10px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Badge = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  padding: '2px 9px',
  borderRadius: 3,
  marginRight: 6.5,
});
