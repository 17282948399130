import axios, { AxiosInstance, AxiosPromise, Method as RequestMethod } from 'axios';
import { API_URL } from 'constants/baseConstants';

const prefix = API_URL;

const defaultConfig = {
  timeout: 200000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
};

export default class AxiosClient {
  private _method: RequestMethod = 'GET';

  private _data: Record<string, unknown> = {};

  private _url = '';

  private _headers: Record<string, string> = {};

  private _timeout = 200000;

  private _params: Record<string, unknown> = {};

  public constructor() {
    this._timeout = defaultConfig.timeout;
    this._headers = {
      ...defaultConfig.headers,
    };
  }

  public static create(): AxiosClient {
    return new AxiosClient();
  }

  public static notAuthCreate(): AxiosClient {
    return new AxiosClient();
  }

  public get(): AxiosClient {
    this._method = 'GET';
    return this;
  }

  public post(): AxiosClient {
    this._method = 'POST';
    return this;
  }

  public patch(): AxiosClient {
    this._method = 'PATCH';
    return this;
  }

  public delete(): AxiosClient {
    this._method = 'DELETE';
    return this;
  }

  public put(): AxiosClient {
    this._method = 'PUT';
    return this;
  }

  public url(url: string): AxiosClient {
    this._url = `${prefix}/${url}`;
    return this;
  }

  public timeout(timeout: number): AxiosClient {
    this._timeout = timeout;
    return this;
  }

  public data(data: Record<string, unknown>): AxiosClient {
    this._data = data;
    return this;
  }

  public headers(headers: Record<string, string>): AxiosClient {
    this._headers = {
      ...this._headers,
      ...headers,
    };
    return this;
  }

  public params(params: Record<string, unknown>): AxiosClient {
    this._params = params;
    return this;
  }

  // baseURL 일단 로컬
  public build<T>(): AxiosPromise<T> {
    const instance = axios.create();
    // this.interceptorCheck(instance);

    return instance.request({
      method: this._method,
      headers: this._headers,
      baseURL: prefix,
      url: this._url,
      data: this._data,
      timeout: this._timeout,
      params: this._params,
      withCredentials: true,
    });
  }

  public interceptorCheck(instance: AxiosInstance) {
    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.code === 'ECONNABORTED') {
          // toast.error('서버에 이상이 있습니다', TOAST_ERROR_CONFIG);
        }

        switch (error.response && error.response.status) {
          case 401:
            // localStorage.removeItem(ACCESS_TOKEN);

            if (window.location.pathname.startsWith('/customerCenterWriteContent')) {
              window.location.href = '/login?customerCenter=qna';
              break;
            }

            if (window.location.pathname.startsWith('/free/detail')) {
              break;
            }
            break;
          // case 403:
          //   toast.error('권한이 없습니다', TOAST_ERROR_CONFIG);
          //   window.history.back();
          //   break;
          case 500:
          case 504:
          case 502:
            alert('서버에 이상이 있습니다');
            window.location.href = '/login';
            break;
        }
        return Promise.reject(error);
      },
    );
  }
}
