import { styled } from '@stitches/react'
import { colors } from 'styles/colors';

export const LogoContainer = styled('div', {
  height: 'auto',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 37
});

export const UserInfoContainer = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '41px auto 0',
  paddingBottom: 5,
  width: 'calc(100% - 40px)',
})

export const InputUserInfo = styled('input', {
  border: 'none',
  color: colors.primaryDark,
  fontSize: '16px',
  outline: 'none',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  width: '100%',

  '&::placeholder': {
    color: colors.gray06,
    fontSize: '16px',
    fontWeight: 700,
  },
})

export const LoginButton = styled('button', {
  padding: '0px',
  border: '0px',
  width: 'calc(100% - 40px)',
  marginLeft: '20px',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: 38,

  variants: {
    disabled: {
      true: {
        color: '#8B63BB',
        backgroundColor: '#F2F2F2',
        cursor: 'not-allowed',
      },
      false: {
        color: 'white',
        backgroundColor: '#8B63BB',
      }
    }
  },

  defaultVariants: {
    disabled: true,
  }
})

export const UserInfoSearchContainer = styled('div', {
  marginTop: 15, textAlign: 'center', alignItems: 'center'
})

