import React, { useEffect, useRef, useState } from 'react';
import Title from '../../Title/PageTitle';
import Tab from '../../TabBar';
import MinganParkingGuideBottomModal from '../MinganParkingGuideBottomModal';
import { Dialog, ColorText } from './styles';
import { monthlyParkingStore } from 'stores/store/Monthly/monthlyParkingStore';
import MonthlyParkingInfo from './MonthlyParkingInfo';
import MonthlyParkingCaution from 'components/atoms/Modal/MonthParkingInfoDetailBottomModal/MonthlyParkingCaution';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
} from 'components/atoms/Modal/MonthParkingInfoDetailBottomModal/MonthlyParkingInfo/styles';
import TwoButtonModal from 'components/atoms/Modal/TwoButtonModal';
import DateChoiceBottomModal from 'components/atoms/Modal/DateChoiceBottomModal';
import { observer } from 'mobx-react';
import EmptyWaitingModal from 'components/atoms/Modal/EmptyWaitingModal';
import PopUpModal from 'components/atoms/Modal/PopupModal';
import { monthlyStore } from 'stores/store/Monthly/monthlyStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption4, SmallText } from 'styles/typography';
import { handleChatClick } from 'utils/common';
import { MainContainerNoMargin } from 'components/styles';
import ParkingLocation from 'components/atoms/ParkingLocation';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

interface MonthlyParkingInfoDetailProps {
  isVisible: boolean;
  onClose: () => void;
  productUID: null | number;
  type: string;
}

export const IOS_SAFE_AREA = Number('env(safe-area-inset-top)');

const MonthlyParkingInfoDetailBottomModal: React.FC<MonthlyParkingInfoDetailProps> = ({
  isVisible,
  onClose,
  productUID,
  type,
}) => {
  const parkingRef = useRef<HTMLDivElement>(null);
  const cautionRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isMinganParkingGuideBottomModal, setIsMinganParkingGuideBottomModal] = useState(false);
  const [isCautionCheckModal, setIsCautionCheckModal] = useState(false);
  const [isDateChoiceBottomModal, setIsDateChoiceBottomModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isEmptyModal, setIsEmptyModal] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const { parkingProduct } = monthlyParkingStore;
  const { memberUID } = userInfoStore;

  const popupText = {
    title: '상담 신청이 완료되었어요.',
    description: '영업시간(평일 09시~18시) 내 \n' + '상담드리겠습니다.',
  };

  const handleTabClick = (index: number) => {
    if (currentTab !== index) {
      setCurrentTab(index);
      setIsScrolling(true);
      if (index === 0 && parkingRef.current) {
        parkingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (cautionRef.current) {
        cautionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      setTimeout(() => setIsScrolling(false), 1500);
    }
  };

  const fetchData = async () => {
    if (productUID) {
      await monthlyParkingStore.fetchGetMonthlyProduct(productUID);
    }
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  useEffect(() => {
    fetchData();
  }, [productUID]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  useEffect(() => {
    const handleScroll = () => {
      if (isScrolling || !parkingRef.current || !cautionRef.current || !containerRef.current)
        return;
      const scrollTop = containerRef.current.scrollTop;
      const parkingTop = parkingRef.current.offsetTop;
      const cautionTop = cautionRef.current.offsetTop - 120;

      if (scrollTop >= parkingTop && scrollTop < cautionTop) {
        setCurrentTab(0);
      } else if (scrollTop >= cautionTop) {
        setCurrentTab(1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isScrolling]);

  return (
    <Dialog isVisible={isVisible}>
      <MainContainerNoMargin>
        <Title
          title="상세정보"
          leftArrowButtonVisible={true}
          leftOnClick={onClose}
          rightTextButtonVisible={true}
          rightOnClick={() => setIsMinganParkingGuideBottomModal(true)}
          rightTitle={'이용방법'}
        />
        <Tab menuArr={['주차장', '유의사항']} currentTab={currentTab} onTabClick={handleTabClick} />
        <div
          ref={containerRef}
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 112px - env(safe-area-inset-top))',
            // parkingProduct?.count !== 0
            //   ? 'calc(100vh - 218px - env(safe-area-inset-top))'
            //   : 'calc(100vh - 112px - env(safe-area-inset-top))',
          }}
        >
          <div ref={parkingRef}>
            <MonthlyParkingInfo />
          </div>
          <Divider style={{ height: 6, backgroundColor: colors.primaryBackground }} />

          <div style={{ padding: '28px 20px 0px 20px' }}>
            <Row justifyContent={'space-between'} style={{ marginBottom: 10 }}>
              <Caption1 weight={'regular'} color={'gray08'}>
                위치
              </Caption1>
              <Caption1 weight={'regular'} color={'black1'}>
                {parkingProduct?.lotAddr.replace(/(\d{1,5}-\d{1,5})/, '***')}
              </Caption1>
            </Row>
            <ParkingLocation
              height={'200px'}
              lat={Number(parkingProduct?.latitude)}
              lng={Number(parkingProduct?.longitude)}
            />
            <Caption4
              weight={'regular'}
              color={'gray05'}
              style={{ marginTop: 10, textAlign: 'right' }}
            >
              정확한 위치는 결제 완료 후에 안내됩니다.
            </Caption4>
          </div>

          <div ref={cautionRef}>
            <MonthlyParkingCaution />
          </div>
        </div>

        {/*{parkingProduct?.count !== 0 &&*/}
        {/*parkingProduct?.productStatus === 'SALE' &&*/}
        {/*parkingProduct?.paymentAvailbleYN === 'YES' ? (*/}
        {/*  <BottomButtonContainer>*/}
        {/*    <ButtonEnableDisable onClick={() => setIsCautionCheckModal(true)}>*/}
        {/*      정기권 구매하기*/}
        {/*    </ButtonEnableDisable>*/}
        {/*  </BottomButtonContainer>*/}
        {/*) : parkingProduct?.count !== 0 &&*/}
        {/*  parkingProduct?.productStatus === 'SALE' &&*/}
        {/*  parkingProduct?.paymentAvailbleYN === 'NO' ? (*/}
        {/*  <BottomButtonContainer>*/}
        {/*    <ButtonEnableDisable*/}
        {/*      onClick={() => {*/}
        {/*        setIsPopup(true);*/}
        {/*        monthlyStore.fetchCreateWaitingData(*/}
        {/*          parkingProduct?.productUID,*/}
        {/*          memberUID,*/}
        {/*          parkingProduct?.slotUID,*/}
        {/*          'REQUEST',*/}
        {/*          'APP_상담신청',*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      결제신청*/}
        {/*    </ButtonEnableDisable>*/}
        {/*  </BottomButtonContainer>*/}
        {/*) : type !== '빈자리' ? (*/}
        {/*  <BottomButtonContainer>*/}
        {/*    <ButtonEnableDisable*/}
        {/*      onClick={() => {*/}
        {/*        setIsPopup(true);*/}
        {/*        monthlyStore.fetchCreateWaitingData(*/}
        {/*          parkingProduct?.productUID ?? 0,*/}
        {/*          memberUID,*/}
        {/*          parkingProduct?.slotUID ?? 0,*/}
        {/*          'WAITING',*/}
        {/*          'APP_대기신청',*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      빈자리 대기 신청하기*/}
        {/*    </ButtonEnableDisable>*/}
        {/*  </BottomButtonContainer>*/}
        {/*) : null}*/}
        <TwoButtonModal
          isVisible={isCautionCheckModal}
          onClose={() => setIsCautionCheckModal(false)}
          title={'유의사항을 확인하셨나요?'}
          content={'유의사항 미숙지로 발생하는 피해는\n주만사가 책임지지 않습니다.'}
          leftButtonText={'아니오'}
          rightButtonText={'네, 확인했습니다.'}
          rightClick={() => {
            setIsDateChoiceBottomModal(true);
            console.log('DateChoiceBottomModal state set to true');
          }}
        />
        <DateChoiceBottomModal
          isVisible={isDateChoiceBottomModal}
          onClose={() => setIsDateChoiceBottomModal(false)}
          detailClose={() => {
            onClose();
          }}
        />
        <PopUpModal
          isVisible={isPopup}
          onClose={closePopup}
          title1={'상담 신청 완료'}
          description={
            <div>
              <ColorText>
                담당자 배정 후 안내까지
                <span>1~3 영업일</span>
                소요됩니다.
              </ColorText>
              <ColorText>
                <span>"주만사 월주차"</span>
                카톡채널로 문의하시면
              </ColorText>
              <ColorText>빠른 안내를 받을 수 있습니다.</ColorText>

              <Caption1
                weight={'regular'}
                color={'gray07'}
                style={{
                  textDecorationLine: 'underline',
                  marginTop: 12,
                }}
                onClick={() => {
                  handleChatClick();
                }}
              >
                카카오톡 문의하기
              </Caption1>
            </div>
          }
          leftClick={() => {
            closePopup();
          }}
          leftText={'확인'}
        />
        <MinganParkingGuideBottomModal
          isVisible={isMinganParkingGuideBottomModal}
          onClose={() => setIsMinganParkingGuideBottomModal(false)}
        />
        <EmptyWaitingModal
          isVisible={isEmptyModal}
          onClose={() => {
            setIsEmptyModal(false);
          }}
        />
      </MainContainerNoMargin>
    </Dialog>
  );
};

export default observer(MonthlyParkingInfoDetailBottomModal);
