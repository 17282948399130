export const base64UrlDecode = (input: string): string => {
  const base64 = input.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
};

export const setRegularInfo = (memberID: string, memberPW: string, loginType: string) => {
  if (window.arsparkingapp) {
    window.arsparkingapp.setRegularInfo(memberID, memberPW, loginType);
  }

  if (window.webkit) {
    window.webkit.messageHandlers.arsparkingapp.postMessage({
      func: 'setRegularInfo',
      loginID: memberID,
      loginPW: memberPW,
      loginType: loginType,
    });
  }
};
