import React from 'react';

import { Container } from './styles';
import { Caption3 } from 'styles/typography';
import Row from 'components/atoms/Row';

interface Props {}

const DetailPageFooter: React.FC<Props> = ({}) => {
  return (
    <Container>
      <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
        <Caption3 weight={'regular'} color={'gray06'} style={{ marginRight: 4 }}>
          •
        </Caption3>
        <Caption3 weight={'regular'} color={'gray06'}>
          제공된 정보는 현장의 최신 정보와 차이 있을 수 있습니다.
        </Caption3>
      </Row>
      <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
        <Caption3 weight={'regular'} color={'gray06'} style={{ marginRight: 4 }}>
          •
        </Caption3>
        <Caption3 weight={'regular'} color={'gray06'}>
          현장 정보와 일치하지 않아서 발생한 피해에 대해서는 주만사에서 책임을 지거나 보상하지
          않습니다.
        </Caption3>
      </Row>
    </Container>
  );
};

export default DetailPageFooter;
