import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

// Dialog - 다이얼로그
export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  maxWidth: 480,
  height: '100vh', // 다이얼로그의 높이 조절
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflowY: 'auto',
  zIndex: 100,

  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export const SearchBar = styled('div', {
  width: 'calc(100% - 40px)',
  backgroundColor: '#F8F8FA',
  height: 45,
  padding: '0px 16px',
  borderRadius: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '16px 0px',
});

export const SearchInput = styled('input', {
  outline: 'none',
  border: 'none',
  backgroundColor: '#F8F8FA',
  width: '100%',
  fontWeight: 500,
  fontSize: 16,
  color: '#333333',
  '&::placeholder': {
    color: '#B0B0B0',
  },
});

export const RecentSearchContainer = styled('div', {
  padding: '28px 0px',
  width: 'calc(100% - 40px)',
});

export const RecentSearchHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 28,
});

export const RecentSearchItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 16,
  padding: '8px 0px',
});

export const WrapResult = styled('div', {
  width: 'calc(100% - 40px)',
  marginTop: 20,
  marginBottom: 20,
});

export const WrapItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
  padding: '8px 0px',
});

export const Text = styled('p', {
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: 'normal',
  color: colors.gray06,
});

export const WrapSeeMore = styled('div', {
  display: 'flex',
  marginTop: 24,
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '0px 12px',
  borderRadius: 16,
  border: '1px solid #CCCCCC',
  height: 32,
  justifyContent: 'center',
});

export const WrapParkingNumberDesc = styled('div', {
  borderRadius: 4,
  border: '1px solid #F0F0F0',
  padding: 16,
  marginTop: 40,
  width: 'calc(100% - 40px)',
  marginLeft: 20,
  boxSizing: 'border-box',
});

export const WrapBadge = styled('div', {
  borderRadius: 4,
  padding: 4,
  background: '#F4F8FB',
  marginBottom: 10,
  boxSizing: 'border-box',
  width: 106,
});
