import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainerNoMargin } from 'components/styles';
import { Dialog, BottomContainer } from './styles';

import { colors } from 'styles/colors';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { dailyDetail } from 'containers/home/HomeMap/mock';
import Title from 'components/atoms/Title/PageTitle';
import TimeTicketBasicInfo from 'containers/timeTicket/TimeTicketBasicInfo';
import Divider from 'components/atoms/Divider';
import TimeTicketList from 'containers/timeTicket/TimeTicketList';
import TimeTicketOperatingTime from 'containers/timeTicket/TimeTicketOperatingTime';
import DetailPageFooter from 'components/atoms/DetailPageFooter';
import Button from 'components/atoms/Button/Button';
import { SubHeadline } from 'styles/typography';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const TimeTicketDetailModal: React.FC<Props> = observer(({ isVisible, onClose }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [selected, setSelected] = useState<number | null>(null);

  const handleLeftOnClick = () => {
    onClose();
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const handleSelected = async (index: number) => {
    setSelected(index);
  };

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    const fetchData = async () => {
      // try {
      //   await shareStore.fetchGetProductDetailOperInfo(productUID);
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    fetchData(); // 데이터 가져오기 호출
  }, []);

  const data = dailyDetail;

  if (!isVisible) return null;

  return (
    <Dialog
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div style={{ overflowY: 'auto' }}>
        <MainContainerNoMargin>
          <Title
            title="상세정보"
            leftArrowButtonVisible={true}
            leftOnClick={handleLeftOnClick}
            rightTextButtonVisible={false}
          />

          <TimeTicketBasicInfo />

          <Divider
            style={{
              backgroundColor: colors.primaryBackground,
              height: 6,
            }}
          />
          <TimeTicketList handleSelected={handleSelected} selected={selected} />

          <Divider
            style={{
              backgroundColor: colors.primaryBackground,
              height: 6,
            }}
          />
          <TimeTicketOperatingTime />
          <div
            style={{
              padding: '0px 28px',
              marginBottom: 120,
            }}
          >
            <DetailPageFooter />
          </div>

          <BottomContainer>
            <Button
              style={{
                width: 'calc(100% - 40px)',
                height: 48,
                marginBottom: 40,
                marginLeft: 20,
              }}
              backgroundColor={selected !== null ? colors.primary : colors.gray06}
              radius={4}
              onClick={onClose}
              disabled={selected === null}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                예약하기
              </SubHeadline>
            </Button>
          </BottomContainer>
        </MainContainerNoMargin>
      </div>
    </Dialog>
  );
});

export default TimeTicketDetailModal;
