import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const TabMenu = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  borderBottom: `2px solid ${colors.gray02}`,
});

export const Submenu = styled('button', {
  padding: '8px 64px',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border-color 0.3s, color 0.3s',
  position: 'relative',
  whiteSpace: 'nowrap',
  height: 46,
  '&:first-child': {
    marginLeft: '25px',
  },

  '&:last-child': {
    marginRight: '25px',
  },

  variants: {
    focused: {
      true: {
        borderBottomColor: '#8B63BB',
        color: '#8B63BB',
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
      },
      false: {
        color: '#666',
        fontFamily: 'Pretendard',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
      },
    },
  },
  defaultVariants: {
    focused: 'false',
  },
});
