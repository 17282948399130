import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapItem } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { Body, Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Divider from 'components/atoms/Divider';
import { pxToRem } from 'utils/common';
import { ProductDetailData, SaleHistoryResponse } from 'models/Share/shareProductDetailModel';
import { formatPriceWithCommas } from 'utils/number';
import { formatDateDay, formatDateRangeDay } from 'utils/dateUtils';

interface Props {
  onClick: (index: number) => void;
  data: SaleHistoryResponse;
}

const MinganParkingUsing: React.FC<Props> = ({ onClick, data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row justifyContent={data?.completeDriverList.length === 0 ? 'space-between' : 'flex-start'}>
        <Body weight={'semibold'} color={'black1'}>
          {' '}
          이용중
        </Body>
        {data?.completeDriverList.length === 0 ? (
          <Caption2 weight={'medium'} color={'gray06'}>
            현재 이용중인 운전자가 없어요
          </Caption2>
        ) : (
          <Body weight={'semibold'} color={'primary'} style={{ marginLeft: 4 }}>
            {data.totalCompleteCnt}
          </Body>
        )}
      </Row>
      {data?.completeDriverList.map((el, index) => (
        <WrapItem key={index}>
          <Caption2 weight={'medium'} color={'primary'}>
            {formatDateRangeDay(el.useDate)}
          </Caption2>
          <Row justifyContent={'space-between'} style={{ marginTop: 10 }} alignItems={'flex-start'}>
            <div style={{ marginRight: 16 }}>
              <Headline weight={'semibold'} color={'black3'}>
                {el.carType}
              </Headline>
              <Caption2 weight={'medium'} color={'gray06'} style={{ marginTop: 4 }}>
                {el.carNumber}
              </Caption2>
            </div>
            <Button
              backgroundColor={colors.gray01}
              radius={17}
              horizontal={16}
              vertical={8}
              style={{
                whiteSpace: 'nowrap ',
              }}
              onClick={() => {
                onClick(index);
              }}
            >
              <Caption1 weight={'medium'} color={'gray08'}>
                공유 중지
              </Caption1>
            </Button>
          </Row>
          <Divider style={{ margin: '16px 0px' }} />

          {el.refundPrice ? (
            <Row>
              <Caption3 weight={'regular'} color={'gray06'} style={{ width: 84, marginRight: 16 }}>
                부분환불 금액
              </Caption3>
              <Caption3 weight={'medium'} color={'gray07'}>
                {formatPriceWithCommas(el.refundPrice)}원
              </Caption3>
            </Row>
          ) : null}
          {el.refundPrice ? (
            <Row style={{ marginTop: 8 }} alignItems={'flex-start'}>
              <Caption3 weight={'regular'} color={'gray06'} style={{ width: 84, marginRight: 16 }}>
                환불 일자
              </Caption3>
              <Caption3
                weight={'medium'}
                color={'gray07'}
                style={{
                  width: 'calc(100% - 100px)',
                }}
              >
                {el.refundDates}
              </Caption3>
            </Row>
          ) : null}

          <Row style={{ marginTop: 8 }}>
            <Caption3 weight={'regular'} color={'gray06'} style={{ width: 84, marginRight: 16 }}>
              정산 예정일
            </Caption3>
            <Caption3 weight={'medium'} color={'gray07'}>
              {el.expectedSettlementDate && formatDateDay(el.expectedSettlementDate).split('(')[0]}
            </Caption3>
          </Row>
          <Row style={{ marginTop: 8 }}>
            <Caption3 weight={'regular'} color={'gray06'} style={{ width: 84, marginRight: 16 }}>
              정산 예정 금액
            </Caption3>
            <Caption3 weight={'medium'} color={'gray07'}>
              {formatPriceWithCommas(el.expectedSettlement)}원
            </Caption3>
          </Row>
        </WrapItem>
      ))}
    </Container>
  );
};

export default MinganParkingUsing;
