import { styled } from '@stitches/react';
import { isIOS } from 'react-device-detect';
import { colors } from 'styles/colors';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100vh',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const WarpImage = styled('div', {
  backgroundColor: '#F2F2F2',
  width: '100%',
  height: 180,
  marginTop: isIOS ? 20 : 0,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const WrapDesc = styled('div', {
  textAlign: 'center',
});

export const WrapParkingInfo = styled('div', {
  padding: '20px',
});

export const WrapFooter = styled('div', {
  padding: '40px 20px',
  backgroundColor: colors.primaryBackground,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Text = styled('p', {
  color: colors.gray06,
  fontSize: 12,
  fontWeight: 400,
  whiteSpace: 'pre-wrap',
});
