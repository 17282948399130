import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  marginBottom: 50,
});

export const WrapItem = styled('div', {
  backgroundColor: '#fff',
  padding: '20px',
  borderBottom: '1px solid #DFE0E4',
});
