// tabsConfig.ts
import React from 'react';

import icSiren from 'assets/ic/ic_siren.png';

import icDotGray from 'assets/ic/ic_dot_gray.svg';
import icParkingPurple from 'assets/ic/ic_parking_purple.svg';

import {
  BlackColorSpan,
  BottomContentsContainer,
  CautionBottomTextContainer,
  CautionContentsContainer,
  CautionDetailContainer,
  CautionDetailNumberSpan,
  CautionDetailNumberTextContainer,
  CautionDetailTextContainer,
  CautionDetailTextSpan,
  CautionDotTextContainer,
  CautionFirstContainer,
  CautionIcon,
  CautionRemainContainer,
  CautionSirenTitleContainer,
  CautionSirenTitleContentsContainer,
  CautionTextContainer,
  CautionTitleContainer,
  CautionTopTextContainer,
  DotIcon,
  GrayColorSpan,
  MainColorSpan,
  ParkingIcon,
  ParkingInfoSpan,
  RedColorSpan,
  SeasonTicketTitleContainer,
  SirenIcon,
  ViewDivide2,
} from './styles';
import { IcCautionFirstSVG, IcCautionSecondSVG, IcCautionThirdSVG } from 'assets/ic';

const MonthlyParkingCaution = () => {
  return (
    <div>
      <CautionSirenTitleContentsContainer>
        <CautionSirenTitleContainer>
          <SirenIcon src={icSiren} alt={'사이렌 아이콘'} />
          <CautionTitleContainer>
            <div>
              <span>아래</span>
              <MainColorSpan>유의사항</MainColorSpan>
              을
              <br />
              꼼꼼하게 확인해 주세요!
            </div>
          </CautionTitleContainer>
        </CautionSirenTitleContainer>

        <CautionContentsContainer>
          <CautionRemainContainer>
            <IcCautionFirstSVG />

            <CautionTextContainer>
              <CautionTopTextContainer>
                <GrayColorSpan>정기권 결제 완료 후</GrayColorSpan>
                <RedColorSpan> 즉시 이용 불가</RedColorSpan>
              </CautionTopTextContainer>
              <CautionBottomTextContainer>
                <MainColorSpan>공유자의 최종 승인</MainColorSpan>
                <BlackColorSpan>이 필요합니다.</BlackColorSpan>
              </CautionBottomTextContainer>
            </CautionTextContainer>
          </CautionRemainContainer>

          <CautionRemainContainer>
            <CautionIcon>
              <IcCautionSecondSVG />
            </CautionIcon>

            <CautionTextContainer>
              <CautionTopTextContainer>
                <GrayColorSpan>공유자</GrayColorSpan>
                <RedColorSpan> 최종 승인 후</RedColorSpan>
              </CautionTopTextContainer>
              <CautionBottomTextContainer>
                <MainColorSpan>카카오 알림톡 수신 후</MainColorSpan>
                <BlackColorSpan>이용 가능 합니다.</BlackColorSpan>
              </CautionBottomTextContainer>
            </CautionTextContainer>
          </CautionRemainContainer>

          <CautionRemainContainer>
            <IcCautionThirdSVG />

            <CautionTextContainer>
              <CautionTopTextContainer>
                <GrayColorSpan>유의사항 미숙지로 발생된 피해는</GrayColorSpan>
              </CautionTopTextContainer>
              <CautionBottomTextContainer>
                <MainColorSpan>운전자에게 책임</MainColorSpan>
                <BlackColorSpan>이 있습니다.</BlackColorSpan>
              </CautionBottomTextContainer>
            </CautionTextContainer>
          </CautionRemainContainer>

          <SeasonTicketTitleContainer>
            <ParkingIcon src={icParkingPurple} alt={'주차장 보라색 아이콘'} />
            <ParkingInfoSpan>정기권 구매 유의사항</ParkingInfoSpan>
          </SeasonTicketTitleContainer>
          <ViewDivide2 />

          <BottomContentsContainer>
            <CautionDetailContainer>
              <CautionDetailNumberTextContainer>
                <CautionDetailNumberSpan>01</CautionDetailNumberSpan>
                <CautionDetailTextContainer>
                  <CautionDetailTextSpan>
                    해당 주차장은 <RedColorSpan>주차비 결제 완료 후 공유자의 승인</RedColorSpan>
                    을 받
                    <br />
                    아야 이용 가능하며,
                    <RedColorSpan>최종 이용 확정</RedColorSpan> 내용의 카카오 알림
                    <br />톡 및 APP Push 수신 시부터 이용 가능합니다.
                  </CautionDetailTextSpan>
                  <CautionDotTextContainer>
                    <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                    공유자의 최종승인 전 이용이 불가하며, 발생되는 별도의 주차비 책임은 운전자에게
                    있습니다.
                  </CautionDotTextContainer>
                </CautionDetailTextContainer>
              </CautionDetailNumberTextContainer>
            </CautionDetailContainer>

            <CautionDetailContainer>
              <CautionDetailNumberTextContainer>
                <CautionDetailNumberSpan>02</CautionDetailNumberSpan>
                <CautionDetailTextContainer>
                  <CautionDetailTextSpan>
                    <RedColorSpan>
                      미성년자, 대포차, 압류차량, 무면허, 음주운전, 차량번호
                      <br />
                      오기재 등
                    </RedColorSpan>
                    <GrayColorSpan>
                      {' '}
                      주차장 이용 중 운전자의 과실이 명확한 책임은 운전자에게 있으며 강제 출차 및
                      견인 조치 될 수 있습니다.
                    </GrayColorSpan>
                  </CautionDetailTextSpan>
                  <CautionDotTextContainer>
                    <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                    불법주차 시 주차비는 환불되지 않습니다.
                  </CautionDotTextContainer>
                </CautionDetailTextContainer>
              </CautionDetailNumberTextContainer>
            </CautionDetailContainer>

            <CautionDetailContainer>
              <CautionDetailNumberTextContainer>
                <CautionDetailNumberSpan>03</CautionDetailNumberSpan>
                <CautionDetailTextContainer>
                  <CautionDetailTextSpan>
                    <span>
                      기계식, 카리프트 주차장의 경우 차량 제원에 따라 이용이 제한 될 수 있습니다.
                    </span>
                  </CautionDetailTextSpan>
                </CautionDetailTextContainer>
              </CautionDetailNumberTextContainer>
            </CautionDetailContainer>

            <CautionDetailContainer>
              <CautionDetailNumberTextContainer>
                <CautionDetailNumberSpan>04</CautionDetailNumberSpan>
                <CautionDetailTextContainer>
                  <CautionDetailTextSpan>
                    <RedColorSpan>환불은 이용 시작일로부터 7일차 사용 전까지만</RedColorSpan>
                    <GrayColorSpan>가능합니다.</GrayColorSpan>
                  </CautionDetailTextSpan>
                  <CautionDotTextContainer>
                    <DotIcon src={icDotGray} alt={'회색 점 아이콘'} />
                    잔여 기간은 일할 계산 환불되며, 환불 정책에 따라 환불 금액이 없을 수 있습니다.
                  </CautionDotTextContainer>
                </CautionDetailTextContainer>
              </CautionDetailNumberTextContainer>
            </CautionDetailContainer>

            <CautionDetailContainer>
              <CautionDetailNumberTextContainer>
                <CautionDetailNumberSpan>05</CautionDetailNumberSpan>
                <CautionDetailTextContainer>
                  <CautionDetailTextSpan>
                    <span>
                      정기주차 이용 시작일 개시 후 실제 주차이용을 하지
                      <br />
                      않았더라도 주차장 대여 기간 초과분에 대해서는 환불이
                      <br />
                      불가합니다.
                    </span>
                  </CautionDetailTextSpan>
                </CautionDetailTextContainer>
              </CautionDetailNumberTextContainer>
            </CautionDetailContainer>
          </BottomContentsContainer>
        </CautionContentsContainer>
      </CautionSirenTitleContentsContainer>
    </div>
  );
};

export default MonthlyParkingCaution;
