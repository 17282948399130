import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useToast } from 'components/atoms/Toast/ToastProvider';
import { MainContainerNoMargin } from 'components/styles';
import { IcApproveSVG, IcCloseEntrySVG, IcExclamationMarkSVG } from 'assets/ic';
import { Body, Caption1, Caption4 } from 'styles/typography';
import { Container, WrapInfo } from './styles';
import Row from 'components/atoms/Row';
import { handleChatClick } from 'utils/common';
import { entryCheckStore } from 'stores/store/TerraceTower/EntryCheckStore';

const EntryCheckPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [title, setTitle] = useState('');
  const [ticketInfoList, setTicketInfoList] = useState([
    { label: '주차장명', value: '' },
    { label: '상품명', value: '' },
    { label: '차량번호', value: '' },
  ]);

  const { entryData } = entryCheckStore;

  const saleHistoryUID = new URLSearchParams(window.location.search).get('saleHistoryUID');

  useEffect(() => {
    if (saleHistoryUID) {
      const fetchData = async () => {
        entryCheckStore.fetchGetUsageStatusCheck(saleHistoryUID);
      };
      fetchData();
    }
  }, [saleHistoryUID]);
  useEffect(() => {
    let updatedTicketInfoList: any = [];

    updatedTicketInfoList = [
      { label: '주차장명', value: entryData?.lotName },
      { label: '상품명', value: entryData?.productName },
      { label: '차량번호', value: entryData?.carNumber },
    ];

    switch (entryData?.usageStatus) {
      case '이용완료':
        updatedTicketInfoList.push({
          label: '입차예정 시간',
          value: `${entryData?.expectedEntryTime ?? ''}`,
        });
        updatedTicketInfoList.push({
          label: '입차확인 시간',
          value: `${entryData?.usageDateTime ?? ''}`,
        });
        setTitle('입차 확인이 완료 되었습니다.');

        break;

      case '기간만료':
        updatedTicketInfoList.push({
          label: '입차예정 시간',
          value: `${entryData?.expectedEntryTime ?? ''}`,
        });

        setTitle('기간 만료 된 주차권입니다.');

        break;
      case '결제취소':
        updatedTicketInfoList.push({
          label: '입차예정 시간',
          value: `${entryData?.expectedEntryTime ?? ''}`,
        });
        updatedTicketInfoList.push({
          label: '결제취소 시간',
          value: `${entryData?.refundDateTime ?? ''}`,
        });
        setTitle('입차 전 환불 처리 된 차량입니다.');

        break;
      case '이용취소':
        updatedTicketInfoList.push({
          label: '입차확인 시간',
          value: `${entryData?.expectedEntryTime ?? ''}`,
        });
        updatedTicketInfoList.push({
          label: '결제취소 시간',
          value: `${entryData?.refundDateTime ?? ''}`,
        });
        setTitle('입차 후 환불처리 된 차량입니다.');
        break;

      default:
        setTitle('존재하지 않는 내역입니다.\n주만사 고객센터로 문의해 주세요.');
        updatedTicketInfoList = [];
        break;
    }

    setTicketInfoList(updatedTicketInfoList);
  }, [entryData]);

  const checkImage = () => {
    switch (entryData?.usageStatus) {
      case '이용완료':
        return <IcApproveSVG style={{ marginTop: 30 }} />;
      case '기간만료':
        return <IcExclamationMarkSVG style={{ marginTop: 30 }} />;
      case '결제취소':
        return <IcExclamationMarkSVG style={{ marginTop: 30 }} />;
      case '이용취소':
        return <IcExclamationMarkSVG style={{ marginTop: 30 }} />;
      default:
        return <IcCloseEntrySVG style={{ marginTop: 30 }} />;
    }
  };

  return (
    <MainContainerNoMargin>
      <Container>
        {checkImage()}

        <Body
          weight={'medium'}
          color={'black3'}
          style={{ marginTop: 16, whiteSpace: 'pre-line', textAlign: 'center' }}
        >
          {title}
        </Body>
        <WrapInfo>
          {ticketInfoList.map((el: any, index: number) => {
            return (
              <Row justifyContent={'space-between'} style={{ marginTop: 8 }} key={index}>
                <Caption1 weight={'regular'} color={'gray06'}>
                  {el.label}
                </Caption1>
                <Caption1
                  weight={'medium'}
                  color={
                    el.label === '주차장명' || el.label === '상품명' || el.label === '차량번호'
                      ? 'gray09'
                      : 'primary'
                  }
                >
                  {el.value}
                </Caption1>
              </Row>
            );
          })}
        </WrapInfo>
        <Caption4
          weight={'medium'}
          color={'gray09'}
          style={{ textDecorationLine: 'underline', marginTop: saleHistoryUID !== '300' ? 45 : 0 }}
          onClick={() => {
            handleChatClick();
          }}
        >
          고객센터 문의하기
        </Caption4>
      </Container>
    </MainContainerNoMargin>
  );
});

export default EntryCheckPage;
