import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { moveJSP } from 'utils/common';
import NewTabBarV2 from 'components/atoms/NewTabBarV2';
import NoticeList from 'containers/notice/NoticeList';
import EventList from 'containers/notice/EventList';

const tabs = [
  {
    name: '공지사항',
  },
  {
    name: '이벤트',
  },
];

const NoticePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  //
  const tab = location.state?.tab || queryParams.get('tab');
  const noticeUID = location.state?.slotUID || queryParams.get('noticeUID');

  const [currentTab, setCurrentTab] = useState(0);

  const handleLeftOnClick = () => {
    moveJSP();
    // navigate('/home');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveJSP();
      // handleLeftOnClick();
    };

    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (tab) {
      setCurrentTab(Number(tab));
    }
  }, [tab]);

  const handleTabClick = (index: number) => {
    setCurrentTab(index);
  };

  return (
    <MainContainerNoMargin
      style={{
        minHeight: '100vh',
        margin: 'env(safe-area-inset-top) auto 0 auto',
      }}
    >
      <Title
        title="공지사항"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />

      <NewTabBarV2 menuArr={tabs} currentTab={currentTab} onClick={handleTabClick} />

      {currentTab === 0 ? (
        <NoticeList noticeUID={noticeUID} />
      ) : (
        <EventList noticeUID={noticeUID} />
      )}
    </MainContainerNoMargin>
  );
};
export default NoticePage;
