import React, { useEffect } from 'react';
import {
  ImgSnsLogin,
  ImgHalf,
  ImgHalf2,
  MemberLoginContainer,
  SpanLink,
  LogoContainer,
  ContentsContainer,
} from './styles';

import { isIOS } from 'react-device-detect';
import { MainContainerNoMargin } from 'components/styles';
import { useNavigate } from 'react-router-dom';
import { Caption2, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import {
  APPLE_CLIENT_ID,
  APPLE_NONCE,
  APPLE_REDIRECT_URI,
  APPLE_SCOPE,
  APPLE_STATE,
  NAVER_AUTH_URL,
  OAUTH_REDIRECT_URI,
} from 'constants/baseConstants';
import { moveJSP } from 'utils/common';

import imgZoomansaSmall from 'assets/images/img_zoomansa_small.svg';
import imgkakaoLoginBtn from 'assets/images/img_kakao_login_btn.png';
import imgNaverLoginBtn from 'assets/images/img_naver_login_btn.png';
import imgAppleLoginBtn from 'assets/images/img_apple_login_btn.png';
import imgRentParking from 'assets/images/img_reent_parking.png';
import imgFastSignUp from 'assets/images/img_fast_sign_up.png';

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.Kakao) {
      console.error('Kakao 로드 실패!');
      return;
    }
    console.log('Kakao 로드 완료!');
  }, []);

  // TODO: 카카오 로그인 후 redirectUri 실제 주소로 바꾸자 디벨로에도
  const handleKakaoLogin = () => {
    if (!window.Kakao || !window.Kakao.isInitialized()) {
      console.error('카카오 SDK가 로드되지 않았습니다.');
      return;
    }

    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&redirect_uri=${OAUTH_REDIRECT_URI}&response_type=code`;
    // 로그인 성공/실패 여부 확인 (setTimeout을 사용하여 로그인 후 처리)
  };

  // TODO: 네이버 로그인 redirectUri 실제 주소로 바꾸자 디벨롭에도
  const handleNaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // TODO: 서버가 추후 요청 하면 로그인 코드 보내주고 성공이면 home 으로 이동 시키자
    if (code) {
      // Handle the code received from the URL
      console.log('네이버 로그인 코드:', code);
    }
  }, []);

  // TODO: OAUTH 로그인으로 받아온 값 추후 서버 통신 시 필요힌 값 보내주자
  useEffect(() => {
    window.setSSOUserInfo = (
      name: string,
      id: string,
      phone: string,
      gender: string,
      birthYearDay: string,
      age: string,
      email: string,
      loginType: string,
    ) => {
      console.log('SSO 정보 받음:', {
        name,
        id,
        phone,
        gender,
        birthYearDay,
        age,
        email,
        loginType,
      });
    };
  }, []);

  // TODO: 애플 로그인 후 redirectUri 실제 주소로 바꾸자 애플은 애플 개발자 사이트에서 추가 해줘야됨 일단 페이지 로그인으로 세팅 코드 성공적으로 보내면 홈으로 이동
  const handleAppleLogin = async () => {
    console.log('sign in with apple');

    window.AppleID.auth.init({
      clientId: APPLE_CLIENT_ID,
      scope: APPLE_SCOPE,
      redirectURI: APPLE_REDIRECT_URI,
      state: APPLE_STATE,
      nonce: APPLE_NONCE,
      usePopup: true,
    });

    // TODO: 서버가 추후 요청 하면 로그인 코드 보내주고 성공이면 home 으로 이동 시키자
    try {
      const res = await window.AppleID.auth.signIn();
      console.log(res);
      const authorizationCode = res.authorization.code;
      // Handle the authorization code
    } catch (error) {
      console.log('Error occurred during sign-in:', error);
      console.log('Detailed error:', JSON.stringify(error, null, 2));
    }
  };

  const handleLoginClick = () => {
    navigate('/zmsLogin');
  };

  const handleSignupClick = () => {
    navigate('/agreeTerms');
  };

  return (
    <MainContainerNoMargin
      style={{ height: isIOS ? 'calc(100vh - env(safe-area-inset-top))' : '100vh' }}
    >
      <ContentsContainer style={{ textAlign: 'center' }}>
        <LogoContainer>
          <img src={imgZoomansaSmall} alt="imgZoomansaSmall" />
        </LogoContainer>
        <ImgHalf2 src={imgRentParking} alt="imgRentParking" />
      </ContentsContainer>
      <ContentsContainer style={{ justifyContent: 'space-between' }}>
        <div>
          <ImgHalf src={imgFastSignUp} alt="imgFastSignUp" />
          <ImgSnsLogin
            src={imgkakaoLoginBtn}
            alt="KakaoLoginBtn"
            onClick={() => {
              console.log('카카오 로그인 클릭');
              handleKakaoLogin();
            }}
          />
          <ImgSnsLogin
            src={imgNaverLoginBtn}
            alt="imgNaverLoginBtn"
            onClick={() => {
              console.log('네이버 로그인 클릭');
              // 핸들러 이용한 로그인
              // reqNaverLogin();
              handleNaverLogin();
            }}
          />
          {isIOS && (
            <ImgSnsLogin
              src={imgAppleLoginBtn}
              alt="imgAppleLoginBtn"
              onClick={async () => {
                console.log('애플 로그인 클릭');
                await handleAppleLogin();
              }}
            />
          )}
          <MemberLoginContainer>
            <Row>
              <SpanLink onClick={handleLoginClick}>
                <Caption2 weight={'regular'} color={'gray07'}>
                  아이디 로그인
                </Caption2>
              </SpanLink>
              <Caption2 weight={'regular'} color={'gray03'}>
                {' '}
                |{' '}
              </Caption2>
              <SpanLink onClick={handleSignupClick}>
                <Caption2 weight={'regular'} color={'gray07'}>
                  회원가입
                </Caption2>
              </SpanLink>
            </Row>
          </MemberLoginContainer>
        </div>
        <Caption4
          weight={'regular'}
          color={'gray06'}
          style={{ textDecoration: 'underline', marginBottom: 40 }}
          onClick={() => {
            // TODO: 일단 jsp
            moveJSP();
          }}
        >
          비회원으로 이용하기
        </Caption4>
      </ContentsContainer>
    </MainContainerNoMargin>
  );
};

export default LoginPage;
