import { useState, useEffect } from 'react';

const useMainPopup = (key = 'hidePopup', durationDays = 7) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    const hideUntil = localStorage.getItem(key);

    if (!hideUntil || new Date() > new Date(hideUntil)) {
      setIsPopupVisible(true);
    }
  }, [key]);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const closeForAWeek = () => {
    const hideUntil = new Date();
    hideUntil.setDate(hideUntil.getDate() + durationDays);
    localStorage.setItem(key, hideUntil.toISOString());
    setIsPopupVisible(false);
  };

  return { isPopupVisible, closePopup, closeForAWeek };
};

export default useMainPopup;
