import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, ExitContainer } from './styles';
import { Body, Caption1, Caption2, Caption3 } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcTooltipHelpSVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { residentParkingLots } from 'models/Share/shareHistoryModel';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import CustomListModal from 'components/atoms/Modal/CustomListModal';
import { shareStore } from 'stores/store/Share/shareStore';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import ExitExpectedTooltip from 'containers/share/ExitExpectedTooltip';
import { getCurrentTimeInKorea } from 'utils/dateUtils';

const ResidentParking = ({ data }: { data: residentParkingLots }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [isTooltip, setIsTooltip] = useState(false);
  const [formattedTime, setFormattedTime] = useState('');
  const [formattedEndTime, setFormattedEndTime] = useState('');
  const [carNumber, setCarNumber] = useState('');
  const [times, setTimes] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [isTimeSelectBottomModal, setIsTimeSelectBottomModal] = useState(false);
  const [isExitRequestModal, setIsExitRequestModal] = useState(false);

  const caseType: number = data.useShared === 1 ? 3 : data.carNumber === null ? 2 : 1;

  useEffect(() => {
    if (data.parkingStartTime) {
      const [hours, minutes] = data.parkingStartTime.split(':');
      const [endHours, endMinutes] = data.reserveOutTime.split(':') ?? '12:00';

      setFormattedTime(`${hours}시 ${minutes}분`);
      setFormattedEndTime(`${endHours}시 ${endMinutes}분`);
      setCarNumber(data.carNumber ?? '1234');
    }

    if (data.slotOperEndTime) {
      const createTimeList = (targetTime: string): string[] => {
        const targetHour = parseInt(targetTime.split(':')[0]); // 타겟 시간의 시간 부분 추출
        const currentHour = new Date().getHours(); // 현재 시간의 시간 부분 추출

        const timeList: string[] = [];

        for (let hour = targetHour; hour > currentHour; hour--) {
          timeList.push(`${hour}시`);
        }

        timeList.push('즉시'); // 즉시 추가
        return timeList;
      };

      setTimes(createTimeList(data.slotOperEndTime));
    }
  }, [data.useShared]);

  const handleSelected = async (index: number) => {
    setSelectedTime(index);
  };

  const onCloseExitRequestModal = () => {
    setIsExitRequestModal(false);
  };

  return caseType === 1 ? (
    <Container
      onClick={() => {
        if (isExitRequestModal || isTimeSelectBottomModal) return;
        navigate('/residentMyParking', {
          state: {
            slotCode: data.slotCode,
            regionCode: data.regionCode,
            slotIdx: data.slotIdx,
            slotOperEndTime: data.slotOperEndTime,
          },
        });
      }}
    >
      <Body weight={'medium'} color={'black1'} style={{ marginBottom: 4 }}>
        {data.regionName}
      </Body>
      <Caption2 weight={'regular'} color={'gray07'}>
        구획 번호 : {data.slotCode}
      </Caption2>
      <Divider style={{ margin: '16px 0px', backgroundColor: colors.gray03 }} />
      <Caption1 weight={'medium'} color={'black3'}>
        *** {carNumber.slice(-4)} 차량이 이용중이에요
      </Caption1>

      {data.unitKind === 'R' ? (
        <>
          <ExitContainer>
            <Row>
              <Caption3 weight={'regular'} color={'gray06'}>
                이용 시작
              </Caption3>
              <Caption3 weight={'medium'} color={'gray07'} style={{ marginLeft: 8 }}>
                {formattedTime}
              </Caption3>
            </Row>
            <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
              <Caption3 weight={'regular'} color={'gray06'}>
                출차 예정
              </Caption3>
              <Caption3
                weight={'medium'}
                color={'gray07'}
                style={{ marginLeft: 8, marginRight: 4 }}
              >
                {formattedEndTime}
              </Caption3>
              <div
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  setIsTooltip(prev => !prev);
                  event.stopPropagation();
                }}
              >
                <IcTooltipHelpSVG width={16} height={16} />
                {isTooltip ? (
                  <div
                    style={{
                      position: 'absolute',
                      left: 32,
                      top: 'calc(100% - 8px)',
                    }}
                  >
                    <ExitExpectedTooltip onClick={setIsTooltip} isOn={isTooltip} />
                  </div>
                ) : null}
              </div>
            </Row>
          </ExitContainer>

          <div
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              setIsExitRequestModal(true);
            }}
          >
            <Button
              radius={4}
              borderColor={colors.gray04}
              style={{
                width: '100%',
                height: '40px',
                marginBottom: 16,
              }}
            >
              <Caption2 weight={'semibold'} color={'gray07'}>
                출차 요청하기
              </Caption2>
            </Button>
          </div>
          <Caption2 weight={'regular'} color={'primary'}>
            도착 30분 전에 요청해야 원할한 이용이 가능해요.
          </Caption2>
        </>
      ) : null}

      <CustomBottomModal
        isVisible={isExitRequestModal}
        onClose={onCloseExitRequestModal}
        title1={`${data.carNumber.slice(-4)}차량에게\n출차 요청하나요?`}
        description={
          '출차 요청 후에는 취소할 수 없으니 신중하게 진행해 주세요. 출차 요청 시 해당 주차장은 오늘 하루만 공유 중단됩니다.'
        }
        title2={'도착 30분 전 미리 요청하면 원활한 출차가 가능해요.'}
        leftClick={onCloseExitRequestModal}
        leftText={'취소'}
        rightClick={() => {
          onCloseExitRequestModal();
          setIsTimeSelectBottomModal(true);
        }}
        rightText={'출차 요청하기'}
      />
      <CustomListModal
        isVisible={isTimeSelectBottomModal}
        onClose={() => {
          setIsTimeSelectBottomModal(false);
          showToast(`출차 요청이 취소 되었어요.`);
        }}
        onSelectItem={handleSelected}
        data={times}
        title={'언제까지 출차해달라고 할까요?'}
        onSuccess={async () => {
          if (selectedTime !== null) {
            try {
              await shareStore.fetchReserveOutRequest(
                data?.tranUID ?? 1,
                times.length - 1 === selectedTime
                  ? getCurrentTimeInKorea()
                  : `${times[selectedTime].slice(0, 2)}:00:00`,
                data.slotIdx,
                data.sharePerDayIdx,
              );
              showToast(
                `${data?.carNumber.slice(-4)} 차량 운전자에게 출차 요청했어요.`,
                icSuccessCheckGreen,
              );

              setIsTimeSelectBottomModal(false);
            } catch (err) {
              console.log(err);
            }
          }
        }}
      />
    </Container>
  ) : caseType === 2 ? (
    <Container
      onClick={() =>
        navigate('/residentMyParking', {
          state: {
            slotCode: data.slotCode,
            regionCode: data.regionCode,
            slotIdx: data.slotIdx,
            slopOperTime: data.slotOperEndTime,
          },
        })
      }
    >
      <Body weight={'medium'} color={'black1'} style={{ marginBottom: 4 }}>
        {data.regionName}
      </Body>
      <Caption2 weight={'regular'} color={'gray07'}>
        구획 번호 : {data.slotCode}
      </Caption2>
      <Divider style={{ margin: '16px 0px', backgroundColor: colors.gray03 }} />

      <Caption2 weight={'regular'} color={'gray06'}>
        이용 중인 차량이 없어요
      </Caption2>
    </Container>
  ) : (
    <Container
      onClick={() =>
        navigate('/residentMyParking', {
          state: {
            slotCode: data.slotCode,
            regionCode: data.regionCode,
            slotIdx: data.slotIdx,
            slopOperTime: data.slotOperEndTime,
          },
        })
      }
    >
      <Caption1 weight={'semibold'} color={'red'} style={{ marginBottom: 10 }}>
        공유 중지
      </Caption1>
      <Body weight={'medium'} color={'gray05'} style={{ marginBottom: 4 }}>
        {data.regionName}
      </Body>
      <Caption2 weight={'regular'} color={'gray05'}>
        구획 번호 : {data.slotCode}
      </Caption2>
      <Divider style={{ margin: '16px 0px', backgroundColor: colors.gray03 }} />
      <Caption2 weight={'medium'} color={'gray06'}>
        중단 시간 : {data.shareStopTime}
      </Caption2>
    </Container>
  );
};

export default ResidentParking;
