import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100vh',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  overflowY: 'auto',
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ViewDivide1 = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
});

export const WrapRoadFind = styled('div', {
  position: 'fixed',
  right: 20,
  bottom: 0,
  width: 'calc(100% - 40px)',
  backgroundColor: '#fff',
});

export const RoadFind = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.primary,
  borderRadius: 4,
  width: '100%',
  height: 46,
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 40,
});
