import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';
import { ITaxReductionTypeResponse } from 'models/TaxReduction/taxReductionTypeModel';
import {
  ITaxRequirements,
  ITaxRequirementsResponse,
} from 'models/TaxReduction/taxRequirementsModel';
import {
  ITaxReductionData,
  ITaxReductionInsert,
  ITaxReductionInsertResponse,
} from 'models/TaxReduction/taxReductionInsertModel';
import {
  ITaxReductionDelete,
  ITaxReductionDeleteResponse,
} from 'models/TaxReduction/taxReductionDeleteModel';

interface TexReductionApiEndpoint {
  TAX_REDUCTION_TYPE: string;
  TAX_REDUCTION_REQUIREMENTS: string;
  TAX_REDUCTION_INSERT: string;
  TAX_REDUCTION_UPDATE: string;
  TAX_REDUCTION_DELETE: string;
}

class TexReduction extends BaseService<TexReductionApiEndpoint> {
  apiEndpoints: TexReductionApiEndpoint = {
    TAX_REDUCTION_TYPE: 'getTaxReductionType',
    TAX_REDUCTION_REQUIREMENTS: 'getTaxRequirements',
    TAX_REDUCTION_INSERT: 'insertTaxReductionInfo',
    TAX_REDUCTION_UPDATE: 'updateTaxReductionInfo',
    TAX_REDUCTION_DELETE: 'deleteTaxReductionInfo',
  };

  constructor() {
    super();
  }

  getTaxReductionType = async (): Promise<ITaxReductionTypeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .get()
        .url(`${this.apiEndpoints.TAX_REDUCTION_TYPE}`)
        .build<ITaxReductionTypeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getTaxRequirements = async (
    requestBody: ITaxRequirements,
  ): Promise<ITaxRequirementsResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.TAX_REDUCTION_REQUIREMENTS}/${requestBody.taxReductionTypeUID}`)
        .build<ITaxRequirementsResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  insertTaxReductionInfo = async (
    requestBody: Partial<FormData>,
  ): Promise<ITaxReductionInsertResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data(requestBody)
        .headers({
          'Content-Type': 'multipart/form-data',
        })
        .url(`${this.apiEndpoints.TAX_REDUCTION_INSERT}`)
        .build<ITaxReductionInsertResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.log(error);

      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  updateTaxReductionInfo = async (
    requestBody: Partial<FormData>,
  ): Promise<ITaxReductionInsertResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data(requestBody)
        .headers({
          'Content-Type': 'multipart/form-data',
        })
        .url(`${this.apiEndpoints.TAX_REDUCTION_UPDATE}`)
        .build<ITaxReductionInsertResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.log(error);

      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  deleteTaxReductionInfo = async (
    requestBody: ITaxReductionDelete,
  ): Promise<ITaxReductionDeleteResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.TAX_REDUCTION_DELETE}`)
        .build<ITaxReductionDeleteResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.log(error);

      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new TexReduction();
