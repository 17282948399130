import { makeAutoObservable, toJS } from 'mobx';
import BaseError from 'models/baseError';
import { ISharePageMatchingData } from 'models/Share/sharePageMatchingModel';
import shareParkingService from 'service/Share/shareParkingService';
import { IShareHistoryData, parkingLotInfo } from 'models/Share/shareHistoryModel';
import { IShareProductListData } from 'models/Share/shareProductListModel';
import {
  CompleteDriver,
  ProductDetailData,
  WaitingDriverList,
} from 'models/Share/shareProductDetailModel';
import { IShareSlotData } from 'models/Share/shareSlotModel';
import { IShareResidentSlotData } from 'models/Share/shareResidentSlot';
import { IShareExistAnyUsingData } from 'models/Share/shareExistAnyUsing';
import { IShareEndProductSaleHistoryData } from 'models/Share/shareEndProductSaleHistory';
import { IShareApprovalRequestApprovalData } from 'models/Share/shareApprovalRequestApproval';
import { IShareApprovalRequestDenyData } from 'models/Share/shareApprovalRequestDeny';
import { IShareReserveOutRequestData } from 'models/Share/shareReserveOutRequest';
import { IShareAreaListData } from 'models/Share/shareAreaList';
import { ProductDetailInfoData } from 'models/Share/shareProductDetailInfoModel';

class ShareStore {
  isLoading = false;
  isMatchingValueLoading = false;
  isProductDetailLoading = false;

  error: string | null = null;
  matchingValue: ISharePageMatchingData | null = null;
  parkingLotInfo: IShareHistoryData = {
    parkingLotInfo: {
      parkingLotTotalCnt: 0, // 총 주차 공간 수
      parkingLots: [],
    },
    residentParkingLotInfo: {
      parkingLotTotalCnt: 0,
      parkingLots: [],
    },
  };
  productList: IShareProductListData | null = null;
  productDetail: ProductDetailData | null = null;
  shareSlot: IShareSlotData | null = null;
  shareResidentSlot: IShareResidentSlotData | null = null;
  existUsing: IShareExistAnyUsingData | null = null;
  endProductSaleHistory: any[] = [];
  approval: IShareApprovalRequestApprovalData | null = null;
  deny: IShareApprovalRequestDenyData | null = null;
  reserveOutRequest: IShareReserveOutRequestData | null = null;
  areaList: IShareAreaListData[] | null = null;
  productDetailInfo: ProductDetailInfoData | null = null;
  waitingDriverList: WaitingDriverList[] | null = null;
  usingDriverList: CompleteDriver[] | null = null;

  endProductSaleHistoryTotalCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsProductDetailLoading(value: boolean) {
    this.isProductDetailLoading = value;
  }

  setMatchingValue(data: ISharePageMatchingData) {
    this.matchingValue = data;
  }

  setMatchingValueLoading(data: boolean) {
    this.isMatchingValueLoading = data;
  }

  setParkingLotInfo(data: IShareHistoryData) {
    this.parkingLotInfo = data;
  }

  setProductList(data: IShareProductListData) {
    this.productList = data;
  }

  setProductDetail(data: ProductDetailData) {
    this.productDetail = data;
  }

  setShareSlot(data: IShareSlotData) {
    this.shareSlot = data;
  }

  setShareResidentSlot(data: IShareResidentSlotData) {
    this.shareResidentSlot = data;
  }

  setExistAnyUsing(data: IShareExistAnyUsingData) {
    this.existUsing = data;
  }

  setEndProductSaleHistory(data: IShareEndProductSaleHistoryData[]) {
    this.endProductSaleHistory = data;
  }

  setEndProductSaleHistoryTotalCount(data: number) {
    this.endProductSaleHistoryTotalCount = data;
  }

  setApproval(data: IShareApprovalRequestApprovalData) {
    this.approval = data;
  }

  setDeny(data: IShareApprovalRequestDenyData) {
    this.deny = data;
  }

  setReserveOutRequest(data: IShareReserveOutRequestData) {
    this.reserveOutRequest = data;
  }

  setAreaList(data: IShareAreaListData[]) {
    this.areaList = data;
  }

  setProductDetailInfo(data: ProductDetailInfoData) {
    this.productDetailInfo = data;
  }

  setWaitingDriverList(data: WaitingDriverList[]) {
    this.waitingDriverList = data;
  }

  setUsingDriverList(data: CompleteDriver[]) {
    this.usingDriverList = data;
  }

  async fetchGetSlotSharePageMatchingValue(mLinkUID: number, reqTell: string) {
    const requestBody = {
      mLinkUID,
      reqTell,
    };

    this.setMatchingValueLoading(true);

    const response = await shareParkingService.getSlotSharePageMatchingValue(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setMatchingValueLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setMatchingValue(data);
      }
      this.setMatchingValueLoading(false);
      return data;
    }
  }

  async fetchGetShareSlotList(mLinkUID: number, pageNum: number) {
    const requestBody = {
      mLinkUID,
    };

    try {
      const response = await shareParkingService.getSharingSlotList(requestBody, pageNum);

      if (response instanceof BaseError) {
        console.error('데이터를 가져오는 중 오류 발생:', response.errorMessage);
        return;
      }

      const { data } = response;

      if (data) {
        if (pageNum === 1) {
          this.setParkingLotInfo(data);
        } else {
          this.setParkingLotInfo({
            parkingLotInfo: {
              parkingLotTotalCnt: data.parkingLotInfo.parkingLotTotalCnt,
              parkingLots: [
                ...this.parkingLotInfo.parkingLotInfo.parkingLots,
                ...data.parkingLotInfo.parkingLots,
              ],
            },
            residentParkingLotInfo: {
              parkingLotTotalCnt: data.residentParkingLotInfo.parkingLotTotalCnt,
              parkingLots: [
                ...this.parkingLotInfo.residentParkingLotInfo.parkingLots,
                ...data.residentParkingLotInfo.parkingLots,
              ],
            },
          });
        }
        return data;
      }
    } catch (error) {
      console.error('API 호출 중 오류:', error);
    } finally {
    }
  }

  async fetchGetLotProductList(slotUID: number) {
    const requestBody = {
      slotUID,
    };

    this.setLoading(true);

    const response = await shareParkingService.getLotProductList(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setProductList(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchGetProductDetail(productUID: number) {
    const requestBody = {
      productUID,
    };

    this.setIsProductDetailLoading(true);
    const response = await shareParkingService.getProductDetail(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setIsProductDetailLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setProductDetail(data);
      }
      this.setIsProductDetailLoading(false);
      return data;
    }
  }

  async fetchShareSlot(tell: string, address: string) {
    const requestBody = {
      tell,
      address,
    };

    this.setLoading(true);

    const response = await shareParkingService.shareSlot(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setShareSlot(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchShareResidentSlot(
    tell: string,
    carNumber: string,
    slotNumber: string,
    areaUID: number,
  ) {
    const requestBody = {
      tell,
      carNumber,
      slotNumber,
      areaUID,
    };

    this.setLoading(true);

    const response = await shareParkingService.shareResidentSlot(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setShareResidentSlot(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchExitAnyUsing(slotUID: number) {
    const requestBody = {
      slotUID,
    };

    this.setLoading(true);

    const response = await shareParkingService.exitAnyUsing(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setExistAnyUsing(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchGetEndProductSaleHistory(
    productUID: number,
    filter: string,
    type: string,
    pageNum: number,
  ) {
    const requestBody = {
      productUID,
      filter,
      type,
    };

    const response = await shareParkingService.endProductSaleHistory(requestBody, pageNum);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      return false;
    } else {
      const { data, totalCount } = response;

      if (pageNum === 1) {
        this.setEndProductSaleHistory(data);
      } else {
        this.setEndProductSaleHistory([...(this.endProductSaleHistory ?? []), ...data]);
      }

      this.setEndProductSaleHistoryTotalCount(totalCount);
      return { data, totalCount };
    }
  }

  async fetchApprovalRequestApproval(saleHistoryUID: number) {
    const requestBody = {
      saleHistoryUID,
    };

    this.setLoading(true);

    const response = await shareParkingService.approvalRequestApproval(requestBody);
    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;
      if (data) {
        this.setApproval(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchApprovalRequestDeny(saleHistoryUID: number, refundReason: string) {
    const requestBody = {
      saleHistoryUID,
      refundReason,
    };

    this.setLoading(true);

    const response = await shareParkingService.approvalRequestDeny(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setDeny(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchReserveOutRequest(
    tranUID: number,
    reserveOutTime: string,
    slotIdx: number,
    sharePerDayIdx: number,
  ) {
    const requestBody = {
      tranUID,
      reserveOutTime,
      slotIdx,
      sharePerDayIdx,
    };

    this.setLoading(true);

    const response = await shareParkingService.reserveOutRequest(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setReserveOutRequest(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchGetAreaList() {
    this.setLoading(true);
    const response = await shareParkingService.getAreaList();

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setAreaList(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchGetProductDetailOperInfo(productUID: number) {
    const requestBody = {
      productUID,
    };

    this.setLoading(true);
    const response = await shareParkingService.getProductDetailOperInfo(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setProductDetailInfo(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchProductSaleResumeRequest(tell: string, keyword: string) {
    const requestBody = {
      tell,
      keyword,
    };

    this.setLoading(true);
    const response = await shareParkingService.productSaleResumeRequest(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      this.setLoading(false);
      return data;
    }
  }

  async fetchUpdateProductQuantity(
    productUID: number,
    decreaseCount: number,
    increaseCount: number,
  ) {
    const requestBody = {
      productUID,
      decreaseCount,
      increaseCount,
    };

    this.setLoading(true);
    const response = await shareParkingService.updateProductQuantity(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      this.setLoading(false);
      return data;
    }
  }
}

export const shareStore = new ShareStore();
