import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const WrapContent = styled('div', {
  padding: '28px 20px',
});

export const AmountPointContainer = styled('div', {
  margin: '12px 20px 28px',
  backgroundColor: colors.primaryBackground,
  borderRadius: '10px',
  padding: '20px'
})

export const ListText = styled('div', {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'normal',
  marginLeft: 10,
  color: '#555555',
  '& span': {
    color: '#1D75CA',
  },
});

export const CautionTitleContentsContainer = styled('div', {
  border: '2px solid #8B63BB',
  borderRadius: '10px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
});

export const CautionSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  marginTop: '20px',
});

export const CautionListContainer = styled('div', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.6',
  marginTop: '20px',
  marginLeft: '16px',
  marginRight: '16px',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '30px',
  height: '26px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  cursor: 'pointer',
  marginRight: '10px',
  position: 'relative',

  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
  },
  '&:checked::after': {
    content: "''",
    position: 'absolute',
    top: 4,
    left: 7,
    width: 5,
    height: 10,
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});

export const CheckBoxLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  justifyContent: 'space-between',
  textAlign: 'left',
});

export const DeleteAccountEssentialSpan = styled('span', {
  color: '#868686', // Change to your desired color
  marginRight: '4px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontSize: '13px',
  fontWeight: 400,
});

export const BottomButtonContainer = styled('div', {
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  bottom: '0',
  left: '0',
  backgroundColor: '#fff',
  textAlign: 'center',
  zIndex: 10,
});

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  userSelect: 'none',
  '&:disabled': {
    backgroundColor: '#d3d3d3',
    color: '#fff',
    cursor: 'not-allowed',
  },
});
