import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import imgZoomansaLarge from 'assets/images/img_zoomansa_large.svg';
import {
  LogoContainer,
  LoginButton,
  InputUserInfo,
  UserInfoSearchContainer,
  UserInfoContainer,
} from './styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Caption2, SubHeadline } from 'styles/typography';
import TowButtonModal from 'components/atoms/Modal/TwoButtonModal';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { loginStore } from 'stores/store/Login/LoginStore';
import { moveJSP } from 'utils/common';
import PurpleOneButtonCheckModal from 'components/atoms/Modal/PurpleOneButtonCheckModal';
import { colors } from 'styles/colors';
import { setDial } from 'utils/deviceUtils';
import { observer } from 'mobx-react';
import { IcShowPasswordSVG } from 'assets/ic';
import { setRegularInfo } from 'utils/auth';

interface IFormInputs {
  memberID: string;
  memberPW: string;
}

const ZMSLoginPage = observer(() => {
  const navigate = useNavigate();
  const { iv, salt } = getRandomString();
  const [isNotMemberPopup, setIsNotMemberPopup] = useState(false);
  const [isWithdrawMemberPopup, setIsWithdrawMemberPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const LOGIN_TYPE_ZMS = 'ZMS';

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IFormInputs>({
    mode: 'onChange',
  });

  const memberID = watch('memberID', '');

  useEffect(() => {
    if (memberID === '') {
      document.getElementById('memberID')?.focus();
    }
  }, []);

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const { memberID, memberPW } = data;
    const encryptedPassword = encTextWord(memberPW, iv, salt);
    (document.activeElement as HTMLElement | null)?.blur();
    try {
      await loginStore.fetchUserLogin(
        { memberID, memberPW: encryptedPassword, loginType: LOGIN_TYPE_ZMS },
        iv,
        salt,
      );

      if (loginStore.error_msg === '회원정보 확인불가') {
        setIsNotMemberPopup(true);
        return;
      }

      if (loginStore.error_msg === '탈퇴한 회원') {
        setIsWithdrawMemberPopup(true);
        return;
      }

      if (loginStore.isLoginSuccessful) {
        // moveJSP()
        setRegularInfo(memberID, memberPW, LOGIN_TYPE_ZMS);
        navigate('/home');
        return;
      }
    } catch (error) {
      console.error('로그인 오류:', error);
    }
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="아이디 로그인"
        leftArrowButtonVisible={true}
        leftOnClick={() => navigate(-1)}
        rightTextButtonVisible={false}
        titleColor="darkPurple"
      />
      <LogoContainer>
        <img src={imgZoomansaLarge} alt="Zoomansa Logo" />
      </LogoContainer>

      <form onSubmit={handleSubmit(onSubmit)}>
        <UserInfoContainer style={{ marginTop: 57 }}>
          <InputUserInfo
            type="text"
            id="memberID"
            placeholder="아이디"
            {...register('memberID', { required: '아이디를 입력해주세요.' })}
          />
        </UserInfoContainer>
        {errors.memberID && (
          <Caption2
            color={'red'}
            weight={'bold'}
            style={{
              marginTop: 7,
              marginLeft: 20,
            }}
          >
            {errors.memberID.message}
          </Caption2>
        )}

        <UserInfoContainer style={{ marginTop: 41 }}>
          <InputUserInfo
            type={showPassword ? 'text' : 'password'}
            id="userPassword"
            placeholder="비밀번호"
            {...register('memberPW', { required: '비밀번호를 입력해주세요.' })}
          />
          <IcShowPasswordSVG onClick={() => setShowPassword(prev => !prev)} />
        </UserInfoContainer>
        {errors.memberPW && (
          <Caption2 color={'red'} weight={'bold'} style={{ marginTop: 7, marginLeft: 20 }}>
            {errors.memberPW.message}
          </Caption2>
        )}

        <LoginButton type="submit" disabled={!isValid}>
          <SubHeadline weight="bold" color={!isValid ? 'primary' : 'white'}>
            로그인
          </SubHeadline>
        </LoginButton>
      </form>

      <UserInfoSearchContainer onClick={() => navigate('/searchMyInfo')}>
        <Caption2 weight="regular" color="gray07">
          아이디 · 비밀번호 찾기
        </Caption2>
      </UserInfoSearchContainer>

      <TowButtonModal
        isVisible={isNotMemberPopup}
        onClose={() => setIsNotMemberPopup(false)}
        title={'가입된 정보가 없습니다.\n회원가입을 진행할까요?'}
        content={''}
        leftButtonText={'취소'}
        rightButtonText={'확인'}
        rightClick={() => {
          navigate('/agreeTerms');
        }}
      />

      <PurpleOneButtonCheckModal
        isVisible={isWithdrawMemberPopup}
        onClose={() => {
          setIsWithdrawMemberPopup(false);
        }}
        title={''}
        content={
          <div
            style={{
              fontWeight: 'bold',
              color: colors.primaryDark,
            }}
          >
            <SubHeadline>탈퇴 후 3개월 동안은</SubHeadline>
            <SubHeadline>주만사에 가입할 수 없어요.</SubHeadline>
          </div>
        }
        buttonText={'문의하기'}
        buttonClick={() => {
          setDial('1666-4369');
        }}
      />
    </MainContainerNoMargin>
  );
});

export default ZMSLoginPage;
