import React, { useState, useEffect, MouseEvent } from 'react';
import { YearSelectModalContainer, Overlay, ListItem, ConfirmButton } from './styles'; // styled components를 import 합니다.

interface YearSelectBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectYear: (year: number) => void;
  year: string[];
}

const startYear = 2024;

const YearSelectBottomModal: React.FC<YearSelectBottomModalProps> = ({
  isVisible,
  onClose,
  onSelectYear,
  year,
}) => {
  const [selectedYear, setSelectedYear] = useState<number>(0);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, year: number) => {
    event.stopPropagation();
    setSelectedYear(year);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Overlay onClick={handleClose} />
      <YearSelectModalContainer isVisible={isVisible} isScroll={year.length > 4}>
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            marginBottom: '16px',
            height: year.length > 4 ? 210 : 'auto',
          }}
        >
          {year.map((year, index) => (
            <ListItem
              key={year}
              isSelected={selectedYear === index}
              onClick={event => handleListItemClick(event, index)}
            >
              {year}
            </ListItem>
          ))}
        </div>
        <ConfirmButton
          onClick={() => {
            onSelectYear(selectedYear);
            onClose();
          }}
        >
          확인
        </ConfirmButton>
      </YearSelectModalContainer>
    </>
  );
};
export default YearSelectBottomModal;
