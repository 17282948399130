import BaseError from 'models/baseError';
import { signUpService } from 'service/SignUp/signUpService';
import { ISignUp } from 'models/SignUp/signUpModel';
import { encTextWord } from 'utils/cryptoJS';
import { memoize } from 'lodash';

class SignUpStore {
  isSignUpLoading = false;
  error_msg: string | null = null;
  isSignUpSuccessful = false;
  isAlreadyUser = false
  isDuplicateMemberID = false

  setIsSignUpLoading(value: boolean) {
    this.isSignUpLoading = value;
  }

  setErrorMsg(value: string) {
    this.error_msg = value;
  }

  setIsSignUpSuccessful(value: boolean) {
    this.isSignUpSuccessful = value;
  }

  setIsAlreadyUser(value: boolean) {
    this.isAlreadyUser = value
  }

  setIsDuplicateMemberID(value: boolean) {
    this.isDuplicateMemberID = value
  }

  async fetchSignUp(requestBody: ISignUp, iv: string, salt: string) {
    this.setIsSignUpLoading(true)
    this.setIsAlreadyUser(false)
    this.setIsDuplicateMemberID(false)
    this.error_msg = null;

    try {
      const response = await signUpService.postUserSignUp(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log('Received BaseError:', response);
        this.setErrorMsg(response.error_msg);
        this.setIsSignUpSuccessful(false);

        console.log(response.error_msg)

        if (response.error_msg.match(/이미 가입된 회원 입니다.*/)) {
          this.setIsAlreadyUser(true)
          this.setErrorMsg(response.error_msg)
          return
        }

        if (response.error_msg.includes('이용이 불가한 ID')) {
          this.setIsDuplicateMemberID(true)
          this.setErrorMsg(response.error_msg)
          return
        }

        // if (response.error_msg.includes('인증번호 검증에 실패했습니다.')) {
        //   alert('하위')
        // }
      } else {
        this.setIsSignUpSuccessful(true);
      }
    } catch (error) {
      this.error_msg = '주만사 로그인 중 오류가 발생했습니다.';
      this.setIsSignUpSuccessful(false);
    } finally {
      this.isSignUpLoading = false;
    }
  }
}

export const signUpStore = new SignUpStore();