import BaseService from 'service/baseService';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';

import { INotice, INoticeResponse } from 'models/Notice/NoticeModel';

interface AuthEndpoints {
  NOTICE: string;
}

class AuthService extends BaseService<AuthEndpoints> {
  apiEndpoints: AuthEndpoints = {
    NOTICE: 'notices',
  };

  private handleErrorResponse(response: any): BaseError {
    const { res_code, err_msg } = response.data;

    if (!err_msg) {
      throw new Error('알 수 없는 오류 발생');
    }

    return new BaseError({
      status: res_code,
      error_msg: err_msg || '알 수 없는 오류 발생',
    });
  }

  // 인증번호 요청
  getNotices = async (requestBody: INotice): Promise<INoticeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .get()
        .headers({
          'Content-Type': 'application/json',
        })
        .params({ ...requestBody })
        .url(`${this.apiEndpoints.NOTICE}`)
        .build<INoticeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return this.handleErrorResponse(response);
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new AuthService();
