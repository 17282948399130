import React, { startTransition, useEffect, useRef, useState } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps } from 'react-naver-maps';
import { AREA_LOCATION_MARKER, LOCATION_MARKER } from 'constants/baseConstants';

interface MapProps {
  height?: string;
  address: string;
  draggable?: boolean;
  zoomControl?: boolean;
}

const Map: React.FC<MapProps> = ({
  height = '100vh',
  address,
  draggable = true,
  zoomControl = true,
}) => {
  const navermaps = useNavermaps();
  const [marker, setMarker] = useState<any>(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    if (address && map && navermaps) {
      startTransition(() => {
        console.log(map);
        console.log(navermaps);
        handleSelectAddress(address);
      });
    }
  }, [address, map, navermaps]);

  const handleSelectAddress = async (address: string) => {
    if (typeof window === 'undefined' || !window.kakao || !window.kakao.maps) {
      console.error('Kakao Maps SDK가 로드되지 않았습니다.');
      return;
    }

    if (!map || !navermaps) {
      console.warn('지도 객체가 아직 초기화되지 않았습니다.');
      return;
    }

    try {
      const ps = new window.kakao.maps.services.Places();

      const getCoordinates = (address: string) => {
        return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
          ps.keywordSearch(address, (result: any, status: any) => {
            if (status === window.kakao.maps.services.Status.OK) {
              resolve({
                lat: parseFloat(result[0].y),
                lng: parseFloat(result[0].x),
              });
            } else {
              reject(new Error('주소 검색 실패'));
            }
          });
        });
      };

      // 비동기적으로 좌표 가져오기
      const addressLocation = await getCoordinates(address);
      const latLng = new navermaps.LatLng(addressLocation.lat, addressLocation.lng);

      // 기존 마커 삭제
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }

      map.setCenter(latLng);
      map.setZoom(17, true);

      const imageSize = window.innerWidth - 60;
      const smallSize = 36;

      // 새로운 마커 생성
      const newMarker = draggable
        ? new navermaps.Marker({
            position: addressLocation,
            map: map,
            icon: {
              url: LOCATION_MARKER, // 마커 이미지 URL
              size: new navermaps.Size(smallSize, smallSize), // 이미지 크기
              origin: new navermaps.Point(0, 0), // 이미지 원점
              anchor: new navermaps.Point(smallSize / 2, smallSize / 2),
            },
          })
        : new navermaps.Marker({
            position: addressLocation,
            map: map,
            icon: {
              url: AREA_LOCATION_MARKER,
              size: new navermaps.Size(imageSize, imageSize), // 이미지 크기
              origin: new navermaps.Point(0, 0), // 이미지 원점
              anchor: new navermaps.Point(imageSize / 2.2, imageSize / 2),
            },
          });

      setMarker(newMarker);
    } catch (error) {
      console.error('주소 검색 중 오류 발생:', error);
    }
  };

  return (
    <MapDiv
      style={{
        width: '100%',
        height,
      }}
    >
      <NaverMap
        ref={setMap}
        minZoom={13}
        maxZoom={20}
        draggable={draggable}
        pinchZoom={zoomControl}
        mapTypeControl={false}
        scrollWheel={draggable}
        logoControl={draggable}
        disableDoubleClickZoom={draggable}
        disableDoubleTapZoom={draggable}
      ></NaverMap>
    </MapDiv>
  );
};

export default Map;
