import React, { useEffect, useRef, useState } from 'react';

import { Container, RoadViewImageViewContainer, ToggleStyled } from './styles';
import ToggleButton from 'components/atoms/Toggle';
import icNotImage from 'assets/ic/ic_not_image.svg';
import icNotRoadView from 'assets/ic/ic_not_road_view.svg';
import { chargerListData } from 'containers/home/HomeMap/mock';
import { IcRoadViewFullSVG } from 'assets/ic';
import { moveToPage } from 'utils/deviceUtils';
import { RoadViewContainer } from 'components/atoms/Modal/MonthParkingInfoDetailBottomModal/MonthlyParkingInfo/styles';

interface Props {
  data: any;
}
const ParkingImage: React.FC<Props> = ({ data }) => {
  const roadViewRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!data?.result[0].lat) return;

    if (!window.kakao || !window.kakao.maps) {
      console.error('Kakao Maps not available');
      return;
    }

    if (!roadViewRef.current) {
      console.error('Roadview container not found');
      return;
    }

    const roadView = new window.kakao.maps.Roadview(roadViewRef.current);
    const roadViewClient = new window.kakao.maps.RoadviewClient();
    const latLng = new window.kakao.maps.LatLng(data?.result[0].lat, data?.result[0].lng);

    roadViewClient.getNearestPanoId(latLng, 50, (panoId: number) => {
      if (!panoId) {
        console.error('로드뷰 정보를 찾을 수 없습니다.');
        return;
      }
      roadView.setPanoId(panoId, latLng);
    });
  }, []);

  return (
    <Container>
      {data?.result[0].lat ? (
        <div
          style={{
            position: 'relative',
            height: 180,
            margin: '10px 0px',
          }}
        >
          <IcRoadViewFullSVG
            onClick={() => {
              moveToPage(
                'https://parking.zoomansa.com/mobileAPI/service/roadView.jsp?lat=' +
                  data?.result[0].lat +
                  '&lng=' +
                  data?.result[0].lng,
              );
            }}
            style={{
              position: 'absolute',
              right: 5,
              top: 5,
              zIndex: 1,
            }}
          />
          <RoadViewContainer ref={roadViewRef} />
        </div>
      ) : (
        <div style={{ position: 'relative', height: 180, margin: '10px 0px' }}>
          <RoadViewImageViewContainer>
            <img src={icNotRoadView} alt={'로드뷰 없는 아이콘'} />
            <span>로드뷰가 제공 되지 않는 곳입니다.</span>
          </RoadViewImageViewContainer>
        </div>
      )}
    </Container>
  );
};

export default ParkingImage;
