import React, { useEffect, useState } from 'react';

import { Dialog, Title, WrapMap } from './styles'; // 경로는 실제 파일 위치에 맞게 조정하세요
import { Caption2, Headline } from 'styles/typography';
import Map from 'components/atoms/Map';
import Row from 'components/atoms/Row';
import { IcClose48SVG } from 'assets/ic';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

// Props 타입 정의
interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
}

const MonthlyDesiredAreaBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  title,
}) => {
  const [isMap, setIsMap] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsMap(true);
    }, 300);
  }, []);

  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Dialog isVisible={isVisible}>
        <Title>
          <Row justifyContent={'space-between'}>
            <Headline
              weight={'medium'}
              color={'black1'}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'calc(100% - 48px)',
              }}
            >
              {title}
            </Headline>
            <IcClose48SVG onClick={onClose} />
          </Row>
        </Title>

        <WrapMap>
          {isMap ? (
            <Map address={title} height={'100%'} draggable={false} zoomControl={false} />
          ) : null}
        </WrapMap>

        <Button
          backgroundColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100% - 40px)',
            marginTop: 20,
            marginBottom: 40,
            marginLeft: 20,
          }}
          onClick={() => {
            onClose();
          }}
        >
          <Caption2 weight={'semibold'} color={'white'}>
            닫기
          </Caption2>
        </Button>
      </Dialog>
    </>
  );
};

export default MonthlyDesiredAreaBottomModal;
