import React, { useEffect } from 'react';
import { MainContainer } from 'components/styles';
import Title from '../../../components/atoms/Title/PageTitle';
import { useNavigate } from 'react-router-dom';
import icDocument from '../../../assets/ic/ic_document.png';

const ReductionApplySuccess = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    navigate('/carInfo');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainer>
      <Title
        title="복지대상 신청"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      {/*페이지를 하나로 팔지 고민 필요 reductionRegistration 친구들이랑*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: '#555',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '23px',
            }}
          >
            공영주차장 요금감면 대상
          </span>
          <span
            style={{
              color: '#8B63BB',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              marginTop: '11px',
            }}
          >
            신청 완료
          </span>
          <img
            style={{
              marginTop: '30px',
            }}
            src={icDocument}
            alt="도큐멘트 아이콘"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#333',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              marginTop: '20px',
            }}
          >
            <span>공영주차장 요금감면 신청이 완료 되었습니다.</span>
            <span>신청해 주신 내용 및 증빙 서류 검토까지</span>
            <div
              style={{
                display: 'inline',
                fontFamily: 'Pretendard',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  lineHeight: '22px',
                }}
              >
                최대 3일이
              </span>
              <span>소요될 수 있습니다</span>
            </div>
          </div>
        </div>
      </div>
      <button
        style={{
          width: 'calc(100% - 40px)',
          height: '46px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          border: 'none',
          backgroundColor: '#8B63BB',
          color: '#fff',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
          fontSize: '16px',
          position: 'fixed',
          bottom: '40px',
        }}
        onClick={handleLeftOnClick}
      >
        나가기
      </button>
    </MainContainer>
  );
};

export default ReductionApplySuccess;
