import axios from 'axios';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';

import { IDesiredAddress, IDesiredAddressResponse } from 'models/Monthly/desiredAddressModel';
import { ICarList, ICarListResponse } from 'models/Car/carListModel';
import { ICarInsert, ICarInsertResponse } from 'models/Car/carInsertModel';
import { ICarDelete, ICarDeleteResponse } from 'models/Car/carDeleteModel';
import { ICarUpdateMain, ICarUpdateMainResponse } from 'models/Car/carUpdateMainModel';

interface CarApiEndpoint {
  CAR_INFO: string;
  CAR_INFO_INSERT: string;
  CAR_INFO_DELETE: string;
  CAR_MAIN_UPDATE: string;
}

class CarService extends BaseService<CarApiEndpoint> {
  apiEndpoints: CarApiEndpoint = {
    CAR_INFO: 'getMemberCarInfo',
    CAR_INFO_INSERT: 'insertMemberCarInfo',
    CAR_INFO_DELETE: 'deleteMemberCarInfo',
    CAR_MAIN_UPDATE: 'updateCarMain',
  };

  constructor() {
    super();
  }

  getMemberCarInfo = async (requestBody: ICarList): Promise<ICarListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.CAR_INFO}/${requestBody.mLinkUID}`)
        .build<ICarListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  insertMemberCarInfo = async (
    requestBody: ICarInsert,
  ): Promise<ICarInsertResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.CAR_INFO_INSERT}`)
        .build<ICarInsertResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  deleteMemberCarInfo = async (
    requestBody: ICarDelete,
  ): Promise<ICarDeleteResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .delete()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.CAR_INFO_DELETE}`)
        .build<ICarDeleteResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  updateCarMain = async (
    requestBody: ICarUpdateMain,
  ): Promise<ICarUpdateMainResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.CAR_MAIN_UPDATE}`)
        .build<ICarUpdateMainResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new CarService();
