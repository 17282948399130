import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import { IDesiredAddressData, IDesiredAddressResponse } from 'models/Monthly/desiredAddressModel';
import terraceTowerService from 'service/TerraceTower/terraceTowerService';
import { IEntryCheckData } from 'models/TerraceTower/entryCheckModel';

class EntryCheckStore {
  isLoading: boolean = false;

  entryData: IEntryCheckData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setParkingDesiredArea = (data: IEntryCheckData) => {
    this.entryData = data;
  };

  async fetchGetUsageStatusCheck(saleHistoryUID: string) {
    this.setIsLoading(true);

    const requestBody = {
      saleHistoryUID,
    };

    try {
      const response = await terraceTowerService.getUsageStatusCheck(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          this.setParkingDesiredArea(response.data);
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const entryCheckStore = new EntryCheckStore();
