import { styled, keyframes } from '@stitches/react';
import 'react-calendar/dist/Calendar.css';

// 슬라이드 업 애니메이션
export const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)', opacity: 0 },
  '100%': { transform: 'translateY(0)', opacity: 1 },
});

export const ModalContainer = styled('div', {
  position: 'fixed',
  left: 0,
  bottom: 0,
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 40px)',
  maxWidth: '480px',
  margin: '20px',
  marginBottom: '40px',
  padding: '16px',
  border: '1px solid #E4E4E4',
  animation: `${slideUp} 0.3s ease-out`,
  zIndex: 10000,
  boxShadow: '0px 3px 16px 0px rgba(0, 0, 0, 0.16)',
});

export const PlacePhotoWeekContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const PlaceWeekContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Badge = styled('div', {
  borderRadius: '4px',
  padding: '4px 8px',
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
