import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { Caption1 } from 'styles/typography';
import { Container } from 'pages/Share/ResidentMyParkingPage/styles';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import Row from 'components/atoms/Row';
import ResidentParkInfo from 'containers/share/ResidentParkingInfo';
import ResidentParkingUsing from 'containers/share/ResidentParkingUsing';
import ResidentParkingUsingHistory from 'containers/share/ResidentParkingUsingHistory';
import { IcClock16SVG } from 'assets/ic';
import CustomListModal from 'components/atoms/Modal/CustomListModal';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { shareResidentStore } from 'stores/store/Share/shareResidentStore';
import YearSelectBottomModal from 'components/atoms/Modal/YearSelectBottomModal';
import { UsingDriver } from 'models/Share/shareResidentSlotDetailInfo';
import { shareStore } from 'stores/store/Share/shareStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { residentParkingLots } from 'models/Share/shareHistoryModel';
import { getCurrentTimeInKorea } from 'utils/dateUtils';

const ResidentMyParkingPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { state } = useLocation();
  const { slotCode, regionCode, slotIdx, slotOperEndTime } = state;

  const [selectYear, setSelectYear] = useState(0);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [isTimeSelectBottomModal, setIsTimeSelectBottomModal] = useState(false);
  const [isExitRequestModal, setIsExitRequestModal] = useState(false);
  const [isSale, setIsSale] = useState<boolean>(false);
  const [isYearSelectBottomModal, setIsYearSelectBottomModal] = useState(false);
  const [usingDriver, setUsingDriver] = useState<UsingDriver | null>(null);
  const [isShareStopModal, setIsShareStopModal] = useState(false);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState<residentParkingLots | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { parkingLotInfo } = shareStore;
  const { residentSlotDetailInfo, isDetailLoading } = shareResidentStore;
  const { memberData } = userInfoStore;

  useEffect(() => {
    if (parkingLotInfo?.parkingLotInfo && slotIdx) {
      const matchingSlot = parkingLotInfo.residentParkingLotInfo.parkingLots.find(
        lot => lot.slotIdx === slotIdx,
      );

      // 상태 업데이트
      setSelectedSlotInfo(matchingSlot ?? null);
    }
  }, [parkingLotInfo, slotIdx]);

  const createYearList = (baseYear: string) => {
    const currentYear = new Date().getFullYear(); // 현재 연도 가져오기
    const yearList = [];

    for (let year = currentYear; year >= Number(baseYear); year--) {
      yearList.push(year + '년'); // 현재 연도부터 주어진 연도까지 추가
    }
    return yearList;
  };

  const year = createYearList(memberData?.regDate.split('-')[0] ?? '2018');

  const createTimeList = (targetTime: string): string[] => {
    if (!targetTime) return [];
    const targetHour = parseInt(targetTime.split(':')[0]); // 타겟 시간의 시간 부분 추출
    const currentHour = new Date().getHours(); // 현재 시간의 시간 부분 추출

    const timeList: string[] = [];

    for (let hour = targetHour; hour > currentHour; hour--) {
      timeList.push(`${hour}시`);
    }

    timeList.push('즉시'); // 즉시 추가
    return timeList;
  };

  const times = createTimeList(slotOperEndTime);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await shareResidentStore.fetchResidentSlotDetailInfo(slotCode, regionCode);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setTimeout(() => {
      setIsLoading(true);
      fetchData();
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (residentSlotDetailInfo) {
      setIsSale(residentSlotDetailInfo.useShared === 0);
    }
  }, [residentSlotDetailInfo]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const onCloseExitRequestModal = () => {
    setIsExitRequestModal(false);
  };

  const onCloseShareStopModal = () => {
    setIsShareStopModal(false);
  };

  const handleSelectYear = async (index: number) => {
    setSelectYear(index);
    setHasMore(true);
  };
  const handleSelected = async (index: number) => {
    setSelectedTime(index);
  };

  function isPastTime(time: string): boolean {
    // 기준 시간 18:00:00
    const [hours, minutes, seconds] = time.split(':').map(Number);

    const targetTime = new Date();
    targetTime.setHours(hours, minutes, seconds, 0); // 주어진 기준 시간으로 설정

    // 현재 시간
    const currentTime = new Date();

    // 현재 시간이 기준 시간보다 지났는지 확인
    return currentTime > targetTime;
  }

  if (!residentSlotDetailInfo || isDetailLoading || isLoading)
    return (
      <MainContainerNoMargin>
        <Title
          title="내 주차장"
          leftArrowButtonVisible
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={true}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <Spinner />
        </div>
      </MainContainerNoMargin>
    );

  return (
    <MainContainerNoMargin>
      <Title
        title="내 주차장"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
      />
      <Container>
        <ResidentParkInfo
          isSale={isSale}
          data={residentSlotDetailInfo}
          selectedSlotInfo={selectedSlotInfo}
        />

        {residentSlotDetailInfo.unitKind === 'R' ? (
          <>
            {residentSlotDetailInfo.useShared === 0 ? (
              <>
                <Divider style={{ height: 8, backgroundColor: colors.gray01 }} />
                <ResidentParkingUsing
                  onClick={index => {
                    setUsingDriver(residentSlotDetailInfo?.usingDriverList[index]);
                    setIsExitRequestModal(true);
                  }}
                  data={residentSlotDetailInfo}
                />
              </>
            ) : null}
            <Divider style={{ height: 8, backgroundColor: colors.gray01 }} />
            <ResidentParkingUsingHistory
              onClick={() => setIsYearSelectBottomModal(true)}
              selectYear={year[selectYear]}
              slotCode={slotCode}
              regionCode={regionCode}
              hasMore={hasMore}
              setHasMore={setHasMore}
            />
          </>
        ) : null}

        {(residentSlotDetailInfo.useShared === 1 && residentSlotDetailInfo.unitKind === 'R') ||
        (residentSlotDetailInfo.unitKind === 'R' &&
          isPastTime(residentSlotDetailInfo.shareEndTime)) ? null : (
          <Button
            style={{
              height: 40,
              boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.25)',
              position: 'fixed',
              bottom: 40,
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
            horizontal={16}
            radius={20}
            borderColor={colors.gray09}
            onClick={() => {
              if (
                residentSlotDetailInfo?.usingDriverList.length &&
                residentSlotDetailInfo.unitKind === 'R'
              ) {
                setIsShareStopModal(true);
              } else {
                navigate('/residentShareManagement', {
                  state: {
                    isGangseo: residentSlotDetailInfo.unitKind === 'S',
                    slotCode,
                    regionCode,
                    slotIdx,
                    sharePerDayIdx: residentSlotDetailInfo?.sharePerDayIdx,
                    name: residentSlotDetailInfo?.slotCode,
                  },
                });
              }
            }}
          >
            <Row>
              <IcClock16SVG />
              <Caption1 weight={'medium'} color={'gray09'} style={{ marginLeft: 8 }}>
                {'공유 관리'}
              </Caption1>
            </Row>
          </Button>
        )}
      </Container>
      <CustomBottomModal
        isVisible={isShareStopModal}
        onClose={onCloseShareStopModal}
        title1={`이용 중인 차량이 있어요.`}
        description={
          '이용 중인 차량이 있을 때는 할 수 없어요. 차량이 출차된 이후에 다시 시도해 주세요.'
        }
        leftClick={onCloseShareStopModal}
        leftText={'확인'}
      />

      <CustomBottomModal
        isVisible={isExitRequestModal}
        onClose={onCloseExitRequestModal}
        title1={`${usingDriver?.carNumber.slice(-4)} 차량에게\n출차 요청하나요?`}
        description={
          '출차 요청 후에는 취소할 수 없으니 신중하게 진행해 주세요. 출차 요청 시 해당 주차장은 오늘 하루만 공유 중단됩니다.'
        }
        title2={'도착 30분 전 미리 요청하면 원활한 출차가 가능해요.'}
        leftClick={onCloseExitRequestModal}
        leftText={'취소'}
        rightClick={() => {
          onCloseExitRequestModal();
          setIsTimeSelectBottomModal(true);
        }}
        rightText={'출차 요청하기'}
      />
      <CustomListModal
        isVisible={isTimeSelectBottomModal}
        onClose={() => {
          setIsTimeSelectBottomModal(false);
          showToast(`출차 요청이 취소 되었어요.`);
        }}
        onSelectItem={handleSelected}
        data={times}
        title={'언제까지 출차해달라고 할까요?'}
        onSuccess={async () => {
          if (selectedTime !== null) {
            try {
              await shareStore.fetchReserveOutRequest(
                usingDriver?.tranUID ?? 1,
                times.length - 1 === selectedTime
                  ? getCurrentTimeInKorea()
                  : `${times[selectedTime].slice(0, 2)}:00:00`,
                slotIdx,
                residentSlotDetailInfo?.sharePerDayIdx,
              );
              showToast(
                `${usingDriver?.carNumber.slice(-4)} 차량 운전자에게 출차 요청했어요.`,
                icSuccessCheckGreen,
              );

              await shareResidentStore.fetchResidentSlotDetailInfo(slotCode, regionCode);

              setIsTimeSelectBottomModal(false);
            } catch (err) {
              console.log(err);
            }
          }
        }}
      />
      <YearSelectBottomModal
        isVisible={isYearSelectBottomModal}
        onClose={() => {
          setIsYearSelectBottomModal(false);
        }}
        onSelectYear={handleSelectYear}
        year={year}
      />
    </MainContainerNoMargin>
  );
});

export default ResidentMyParkingPage;
