import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Caption3, Caption4, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { IcTooltipAlertSVG } from 'assets/ic';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';

interface Props {}

const AutomaticCaution: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row alignItems={'center'}>
        <IcTooltipAlertSVG width={20} height={20} style={{ marginRight: 10 }} fill={'#47474A'} />
        <SubHeadline weight={'bold'} color={'black3'}>
          자동결제란?
        </SubHeadline>
      </Row>

      <Divider style={{ margin: '14px 0px', backgroundColor: colors.gray03 }} />

      <Row alignItems={'flex-start'}>
        <Caption4 weight={'regular'} color={'gray06'}>
          내 카드 정보를 암호화 등록하여 결제 시 카드정보 입력 없이 결제되도록 하는 방식 (삭제 요청
          시 삭제 가능)
        </Caption4>
      </Row>
    </Container>
  );
};

export default AutomaticCaution;
