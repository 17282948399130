import React, { useState, useEffect } from 'react';

import { Modal } from 'components/atoms/Modal/PopupModal/styles';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

type Align = 'flex-start' | 'center' | 'flex-end';

interface PopupModalProps {
  isVisible: boolean;
  onClose: () => void;
  title1: string | React.ReactNode;
  leftClick: () => void;
  leftText: string;
  description?: string | React.ReactNode;
  rightClick?: () => void;
  rightText?: string;
  title2?: string;
  isBackgroundTouchable?: boolean;
  titleAlign?: Align;
}

const PopupModal: React.FC<PopupModalProps> = ({
  isVisible,
  onClose,
  title1,
  description,
  leftText,
  leftClick,
  rightClick,
  rightText,
  title2,
  isBackgroundTouchable = true,
  titleAlign = 'center',
}) => {
  if (!isVisible) return null;

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (isBackgroundTouchable) {
      onClose();
    }
    e.stopPropagation(); // 모달 안의 다른 요소들에 이벤트 전파 방지
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={handleBackgroundClick}
      />
      <Modal isVisible={isVisible}>
        <Row
          style={{
            whiteSpace: 'pre-wrap',
            marginTop: description ? 0 : 12.5,
            marginBottom: description ? 16 : 0,
            width: '100%',
          }}
          justifyContent={titleAlign}
        >
          <SubHeadline weight={'medium'} color={'black1'}>
            {title1}
          </SubHeadline>
        </Row>
        <Caption2
          weight={'regular'}
          color={'gray08'}
          style={{
            wordBreak: 'keep-all',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
        >
          {description}
        </Caption2>
        {title2 ? (
          <Caption2
            weight={'regular'}
            color={'primary'}
            style={{ marginTop: 8, wordBreak: 'keep-all' }}
          >
            {title2}
          </Caption2>
        ) : null}
        {rightText ? (
          <Row style={{ gap: '10px', marginTop: 20, width: 'calc(100%)' }}>
            <Button
              backgroundColor={colors.gray01}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClose();
                leftClick && leftClick();
              }}
            >
              <Caption2 weight={'semibold'} color={'gray06'}>
                {leftText}
              </Caption2>
            </Button>
            <Button
              backgroundColor={colors.primary}
              radius={4}
              style={{
                height: 46,
                width: 'calc(100%)',
              }}
              onClick={() => {
                onClose();
                rightClick && rightClick();
              }}
            >
              <Caption2 weight={'semibold'} color={'white'}>
                {rightText}
              </Caption2>
            </Button>
          </Row>
        ) : (
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 20,
            }}
            onClick={() => {
              onClose();
              leftClick && leftClick();
            }}
          >
            <Caption2 weight={'semibold'} color={'white'}>
              {leftText}
            </Caption2>
          </Button>
        )}
      </Modal>
    </>
  );
};

export default PopupModal;
