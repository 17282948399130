// 템플릿 생성 함수들
import { formatPriceWithCommas } from 'utils/number';
import { HourlyParkingLot, MonthlyParkingLot } from 'models/Map/mapModel';
import { colors, MARKER_COLORS } from 'styles/colors';
import { PaymentAvailability, RegionType, UsageStatus } from 'enums/markerState';

export const createMonthlyMarkerContent = (
  marker: MonthlyParkingLot,
  selectItem: string | number | null,
): string => {
  const isAvailable = marker.paymentAvailbleYN === PaymentAvailability.YES;
  const bgColor =
    isAvailable && selectItem === marker.lotUID
      ? MARKER_COLORS.NAVY
      : isAvailable
        ? MARKER_COLORS.WHITE
        : !isAvailable && selectItem === marker.lotUID
          ? MARKER_COLORS.DARK_GRAY
          : MARKER_COLORS.GRAY;
  const borColor = isAvailable ? MARKER_COLORS.DARK_NAVY : MARKER_COLORS.LIGHT_GRAY;

  const textColor =
    selectItem === marker.lotUID
      ? MARKER_COLORS.WHITE
      : isAvailable
        ? MARKER_COLORS.NAVY
        : MARKER_COLORS.LIGHT_GRAY;

  const priceColor =
    selectItem === marker.lotUID
      ? MARKER_COLORS.WHITE
      : isAvailable
        ? MARKER_COLORS.TEXT_BLACK
        : MARKER_COLORS.LIGHT_GRAY;

  const productCount = Number(marker.productCount);
  return `
  <div 
    style="display: flex; box-sizing: border-box; background: ${bgColor}; border: 1px solid ${borColor}; padding: 10px; border-radius: 4px; flex-direction: column; white-space: nowrap; "
    >
    <span style="font-weight: 600; font-size: 12px; color: ${textColor}">
      ${marker.operTime === '0' ? `전일(${marker.formattedOperTime})` : `평일(${marker.formattedOperTime})`}
    </span>
    <div style="display: flex; align-items: flex-end;">

      <span style="font-weight: 600; font-size: 15px; color: ${priceColor}">
        ${formatPriceWithCommas(marker.salePrice ?? 0)}원
      </span>
      ${
        productCount > 1
          ? ` 
           <span style="font-weight: 600; font-size: 10px; color: ${priceColor}; margin-left: 2px;">
              외 ${productCount}종
            </span>
        `
          : ``
      }
     
    </div>
  </div>
`;
};

export const createDailyMarker = (marker: any, selectItem: string | number | null) => {
  const disabled = marker.paymentAvailbleYN === PaymentAvailability.NO;
  const id = marker.lotUID ?? marker.regionCode;
  const isSelected = selectItem === id;

  const dailyBgColor =
    !disabled && isSelected
      ? MARKER_COLORS.PURPLE
      : !disabled
        ? MARKER_COLORS.WHITE
        : disabled && isSelected
          ? MARKER_COLORS.DARK_GRAY
          : MARKER_COLORS.GRAY;
  const dailyBorColor = !disabled ? MARKER_COLORS.PURPLE : MARKER_COLORS.LIGHT_GRAY;

  const dailyTextColor = isSelected
    ? MARKER_COLORS.WHITE
    : !disabled
      ? MARKER_COLORS.PURPLE
      : MARKER_COLORS.LIGHT_GRAY;

  const dailyPriceColor = isSelected
    ? MARKER_COLORS.WHITE
    : !disabled
      ? MARKER_COLORS.TEXT_BLACK
      : MARKER_COLORS.LIGHT_GRAY;

  if (marker.regionType === RegionType.DAILY) {
    return `
       <div 
    style="display: flex; box-sizing: border-box; background: ${dailyBgColor}; border: 1px solid ${dailyBorColor}; padding: 10px; border-radius: 4px; flex-direction: column; white-space: nowrap; "
    >
    <span style="font-weight: 600; font-size: 12px; color: ${dailyTextColor}">
      ${marker.productName.split(',')[0]}
    </span>

    <div style="display: flex; align-items: flex-end;">
      <span style="font-weight: 600; font-size: 15px; color: ${dailyPriceColor}">
        ${formatPriceWithCommas(marker.salePrice ?? 0)}원
      </span>
      ${
        marker.productCount > 1
          ? ` 
           <span style="font-weight: 600; font-size: 10px; color: ${dailyPriceColor}; margin-left: 2px;">
              외 ${marker.productCount}종
            </span>
        `
          : ``
      }
    </div>
    
    
        <div style="
            content: ''; 
            position: absolute; 
            bottom: -7px; 
            left: 50%; 
            transform: translateX(-50%); 
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent; 
            border-right: 6px solid transparent; 
            border-top: 8px solid #868686;
        "/>
      <div style="
            content: ''; 
            position: absolute; 
            bottom: 1px; 
            left: 50%; 
            transform: translateX(-50%); 
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent; 
            border-right: 6px solid transparent; 
            border-top: ${`8px solid ${dailyBgColor}`};
        "/>
        
           <div style="
            position: absolute; 
            bottom: -16px; 
            left: 50%; 
            transform: translateX(-50%); 
        ">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
              <g opacity="0.15" filter="url(#filter0_d_21337_29241)">
              <path d="M14 2C14 3.10457 11.7614 4 9 4C6.23858 4 4 3.10457 4 2C4 0.89543 6.23858 0 9 0C11.7614 0 14 0.89543 14 2Z" fill="black"/>
              <path d="M10.4355 0.0836474C11.5701 0.219435 12.5354 0.512041 13.1741 0.898564L12.9153 1.32634C13.3892 1.61315 13.5 1.86074 13.5 2C13.5 2.13926 13.3892 2.38685 12.9153 2.67366L13.1741 3.10144C12.5354 3.48796 11.5701 3.78056 10.4355 3.91635L10.3761 3.4199C9.9416 3.47189 9.47958 3.5 9 3.5C8.52042 3.5 8.0584 3.47189 7.62393 3.4199L7.56452 3.91635C6.42992 3.78056 5.46462 3.48796 4.82588 3.10144L5.08474 2.67366C4.61078 2.38685 4.5 2.13926 4.5 2C4.5 1.86074 4.61078 1.61315 5.08474 1.32634L4.82588 0.898564C5.46462 0.512041 6.42992 0.219435 7.56452 0.0836474L7.62393 0.580105C8.0584 0.528108 8.52042 0.5 9 0.5C9.47958 0.5 9.9416 0.528108 10.3761 0.580105L10.4355 0.0836474Z" stroke="black" stroke-linejoin="bevel" stroke-dasharray="2 2"/>
              </g>
              <defs>
              <filter id="filter0_d_21337_29241" x="0" y="0" width="18" height="12" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21337_29241"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21337_29241" result="shape"/>
              </filter>
              </defs>
              </svg>
          </div>
  </div>
    `;
  }

  return `<div></div>`;
};

export const createShareMarkerContent = (
  marker: any,
  selectItem: string | number | null,
): string => {
  const disabled = marker.paymentAvailbleYN === PaymentAvailability.NO;
  const id = marker.lotUID ?? marker.regionCode;
  const isSelected = selectItem === id;

  const bgColor =
    isSelected && disabled
      ? MARKER_COLORS.DARK_GRAY
      : isSelected
        ? MARKER_COLORS.PURPLE
        : disabled
          ? colors.gray01
          : MARKER_COLORS.WHITE;

  const borderColor = isSelected ? MARKER_COLORS.DARK_GRAY : MARKER_COLORS.BORDER_COLOR;

  const textColor = isSelected ? 'white' : disabled ? colors.gray06 : MARKER_COLORS.BLACK;
  const timeTextColor = isSelected ? 'white' : colors.gray06;
  const logoColor = isSelected ? MARKER_COLORS.WHITE : MARKER_COLORS.TEXT_BLACK;
  const productCountColor = isSelected
    ? MARKER_COLORS.WHITE
    : !disabled
      ? MARKER_COLORS.PURPLE
      : colors.gray06;

  const isPricePublic =
    marker.basicTime && marker.basicCharge && marker.regionType === RegionType.PUBLIC;

  const type =
    marker.productCount === 1 && marker.regionType !== RegionType.DAILY
      ? disabled
        ? isSelected
          ? 4
          : 3
        : isSelected
          ? 2
          : 1
      : 5;

  if (type === 1) {
    return `

   <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none">
<g opacity="0.15" filter="url(#filter0_d_21799_47165)">
<ellipse cx="13.0193" cy="31.1097" rx="3.78" ry="1.68" fill="black"/>
<path d="M14.1283 29.5032C15.0006 29.622 15.7387 29.8773 16.2164 30.213L15.9749 30.5566C16.2919 30.7795 16.3793 30.9764 16.3793 31.1097C16.3793 31.243 16.2919 31.4399 15.9749 31.6628L16.2164 32.0064C15.7387 32.3421 15.0006 32.5974 14.1283 32.7162L14.0716 32.3001C13.7401 32.3452 13.3867 32.3697 13.0193 32.3697C12.6518 32.3697 12.2984 32.3452 11.9669 32.3001L11.9102 32.7162C11.0379 32.5974 10.2999 32.3421 9.82214 32.0064L10.0636 31.6628C9.74657 31.4399 9.65926 31.243 9.65926 31.1097C9.65926 30.9764 9.74657 30.7795 10.0636 30.5566L9.82214 30.213C10.2999 29.8773 11.0379 29.622 11.9102 29.5032L11.9669 29.9193C12.2984 29.8742 12.6518 29.8497 13.0193 29.8497C13.3867 29.8497 13.7401 29.8742 14.0716 29.9193L14.1283 29.5032Z" stroke="black" stroke-width="0.84" stroke-linejoin="bevel" stroke-dasharray="1.68 1.68"/>
</g>
<mask id="path-3-inside-1_21799_47165" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 5.91907C-0.00244141 2.67163 2.63012 0.0390625 5.87756 0.0390625H20.1576C23.405 0.0390625 26.0376 2.67163 26.0376 5.91906V20.1991C26.0376 23.4465 23.405 26.0791 20.1576 26.0791H16.9341L13.7169 30.9084C13.3845 31.4075 12.6512 31.4075 12.3188 30.9084L9.10159 26.0791H5.87756C2.63013 26.0791 -0.00244141 23.4465 -0.00244141 20.1991V5.91907Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 5.91907C-0.00244141 2.67163 2.63012 0.0390625 5.87756 0.0390625H20.1576C23.405 0.0390625 26.0376 2.67163 26.0376 5.91906V20.1991C26.0376 23.4465 23.405 26.0791 20.1576 26.0791H16.9341L13.7169 30.9084C13.3845 31.4075 12.6512 31.4075 12.3188 30.9084L9.10159 26.0791H5.87756C2.63013 26.0791 -0.00244141 23.4465 -0.00244141 20.1991V5.91907Z" fill="#F7F8FF"/>
<path d="M16.9341 26.0791V25.2391H16.4844L16.235 25.6134L16.9341 26.0791ZM13.7169 30.9084L14.416 31.3741L14.416 31.3741L13.7169 30.9084ZM12.3188 30.9084L11.6197 31.3741L11.6197 31.3741L12.3188 30.9084ZM9.10159 26.0791L9.80067 25.6134L9.55133 25.2391H9.10159V26.0791ZM5.87756 -0.800938C2.1662 -0.800938 -0.842441 2.20771 -0.842441 5.91907H0.837559C0.837559 3.13555 3.09404 0.879063 5.87756 0.879063V-0.800938ZM20.1576 -0.800938H5.87756V0.879063H20.1576V-0.800938ZM26.8776 5.91906C26.8776 2.20771 23.8689 -0.800938 20.1576 -0.800938V0.879063C22.9411 0.879063 25.1976 3.13555 25.1976 5.91906H26.8776ZM26.8776 20.1991V5.91906H25.1976V20.1991H26.8776ZM20.1576 26.9191C23.8689 26.9191 26.8776 23.9104 26.8776 20.1991H25.1976C25.1976 22.9826 22.9411 25.2391 20.1576 25.2391V26.9191ZM16.9341 26.9191H20.1576V25.2391H16.9341V26.9191ZM16.235 25.6134L13.0179 30.4427L14.416 31.3741L17.6332 26.5448L16.235 25.6134ZM13.0179 30.4427H13.0179L11.6197 31.3741C12.2846 32.3722 13.7511 32.3722 14.416 31.3741L13.0179 30.4427ZM13.0179 30.4427L9.80067 25.6134L8.40251 26.5448L11.6197 31.3741L13.0179 30.4427ZM5.87756 26.9191H9.10159V25.2391H5.87756V26.9191ZM-0.842441 20.1991C-0.842441 23.9104 2.16621 26.9191 5.87756 26.9191V25.2391C3.09405 25.2391 0.837559 22.9826 0.837559 20.1991H-0.842441ZM-0.842441 5.91907V20.1991H0.837559V5.91907H-0.842441Z" fill="#CCCCCC" mask="url(#path-3-inside-1_21799_47165)"/>
<path d="M17.9838 16.1914H7.88212C6.77254 16.1914 5.87305 17.0859 5.87305 18.1892V18.2067C5.87305 19.3101 6.77254 20.2045 7.88212 20.2045H17.9838C19.0934 20.2045 19.9928 19.3101 19.9928 18.2067V18.1892C19.9928 17.0859 19.0934 16.1914 17.9838 16.1914Z" fill="#7B59A3"/>
<g style="mix-blend-mode:multiply">
<path d="M18.1596 5.91797H8.0579C6.94832 5.91797 6.04883 6.81242 6.04883 7.91579V7.93329C6.04883 9.03665 6.94832 9.93111 8.0579 9.93111H18.1596C19.2691 9.93111 20.1686 9.03665 20.1686 7.93329V7.91579C20.1686 6.81242 19.2691 5.91797 18.1596 5.91797Z" fill="#FFEA00"/>
</g>
<g style="mix-blend-mode:multiply">
<path d="M16.718 6.49507L6.45299 16.7921C5.67183 17.5757 5.67738 18.8406 6.46539 19.6174L6.47788 19.6298C7.26589 20.4065 8.53795 20.401 9.31911 19.6174L19.5842 9.32038C20.3653 8.53678 20.3598 7.27184 19.5718 6.49506L19.5593 6.48274C18.7713 5.70595 17.4992 5.71147 16.718 6.49507Z" fill="#20AEE5"/>
</g>
<defs>
<filter id="filter0_d_21799_47165" x="5.87926" y="29.4297" width="14.2801" height="10.0794" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3.36"/>
<feGaussianBlur stdDeviation="1.68"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21799_47165"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21799_47165" result="shape"/>
</filter>
</defs>
</svg>`;
  }
  if (type === 2) {
    return `
   <svg xmlns="http://www.w3.org/2000/svg" width="29" height="36" viewBox="0 0 29 36" fill="none">
  <g opacity="0.15" filter="url(#filter0_d_21799_47188)">
    <ellipse cx="14.3409" cy="34.1952" rx="4.21836" ry="1.68735" fill="black"/>
    <path d="M15.552 32.5784C16.5092 32.6929 17.3236 32.9398 17.8625 33.2659L17.6451 33.6252C18.0451 33.8673 18.1393 34.0767 18.1393 34.1952C18.1393 34.3136 18.0451 34.523 17.6451 34.7651L17.8625 35.1244C17.3236 35.4505 16.5092 35.6974 15.552 35.8119L15.5021 35.3949C15.1355 35.4388 14.7456 35.4625 14.3409 35.4625C13.9362 35.4625 13.5464 35.4388 13.1798 35.3949L13.1298 35.8119C12.1726 35.6974 11.3582 35.4505 10.8193 35.1244L11.0368 34.7651C10.6367 34.523 10.5426 34.3136 10.5426 34.1952C10.5426 34.0767 10.6367 33.8673 11.0368 33.6252L10.8193 33.2659C11.3582 32.9398 12.1726 32.6929 13.1298 32.5784L13.1798 32.9954C13.5464 32.9515 13.9362 32.9278 14.3409 32.9278C14.7456 32.9278 15.1355 32.9515 15.5021 32.9954L15.552 32.5784Z" stroke="black" stroke-width="0.84" stroke-linejoin="bevel" stroke-dasharray="1.68 1.68"/>
  </g>
  <mask id="path-3-inside-1_21799_47188" fill="white">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 6.46002C-0.00244141 2.91383 2.87232 0.0390625 6.41852 0.0390625H22.1366C25.6828 0.0390625 28.5576 2.91383 28.5576 6.46002V22.1781C28.5576 25.7243 25.6828 28.5991 22.1366 28.5991H18.5734L15.0422 33.8998C14.6791 34.4447 13.8784 34.4447 13.5154 33.8998L9.9842 28.5991H6.41852C2.87232 28.5991 -0.00244141 25.7243 -0.00244141 22.1781V6.46002Z"/>
  </mask>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 6.46002C-0.00244141 2.91383 2.87232 0.0390625 6.41852 0.0390625H22.1366C25.6828 0.0390625 28.5576 2.91383 28.5576 6.46002V22.1781C28.5576 25.7243 25.6828 28.5991 22.1366 28.5991H18.5734L15.0422 33.8998C14.6791 34.4447 13.8784 34.4447 13.5154 33.8998L9.9842 28.5991H6.41852C2.87232 28.5991 -0.00244141 25.7243 -0.00244141 22.1781V6.46002Z" fill="#8B63BB"/>
  <path d="M18.5734 28.5991V27.6818H18.0822L17.81 28.0905L18.5734 28.5991ZM15.0422 33.8998L14.2788 33.3912L14.2788 33.3912L15.0422 33.8998ZM13.5154 33.8998L12.752 34.4083L13.5154 33.8998ZM9.9842 28.5991L10.7476 28.0905L10.4753 27.6818H9.9842V28.5991ZM6.41852 -0.878218C2.36572 -0.878218 -0.919721 2.40723 -0.919721 6.46002H0.914839C0.914839 3.42043 3.37892 0.956343 6.41852 0.956343V-0.878218ZM22.1366 -0.878218H6.41852V0.956343H22.1366V-0.878218ZM29.4748 6.46002C29.4748 2.40723 26.1894 -0.878218 22.1366 -0.878218V0.956343C25.1762 0.956343 27.6403 3.42042 27.6403 6.46002H29.4748ZM29.4748 22.1781V6.46002H27.6403V22.1781H29.4748ZM22.1366 29.5163C26.1894 29.5163 29.4748 26.2309 29.4748 22.1781H27.6403C27.6403 25.2177 25.1762 27.6818 22.1366 27.6818V29.5163ZM18.5734 29.5163H22.1366V27.6818H18.5734V29.5163ZM17.81 28.0905L14.2788 33.3912L15.8056 34.4083L19.3368 29.1076L17.81 28.0905ZM14.2788 33.3912L14.2788 33.3912L12.752 34.4083C13.4781 35.4983 15.0795 35.4983 15.8056 34.4083L14.2788 33.3912ZM14.2788 33.3912L10.7476 28.0905L9.2208 29.1076L12.752 34.4083L14.2788 33.3912ZM6.41852 29.5163H9.9842V27.6818H6.41852V29.5163ZM-0.919721 22.1781C-0.919721 26.2309 2.36572 29.5163 6.41852 29.5163V27.6818C3.37892 27.6818 0.914839 25.2177 0.914839 22.1781H-0.919721ZM-0.919721 6.46002V22.1781H0.914839V6.46002H-0.919721Z" fill="#6E5988" mask="url(#path-3-inside-1_21799_47188)"/>
  <rect x="6.76074" y="6.59375" width="15.248" height="4.33313" rx="2.16656" fill="white"/>
  <rect x="6.57129" y="17.6836" width="15.248" height="4.33313" rx="2.16656" fill="white"/>
  <rect width="20.0243" height="4.34954" rx="2.17477" transform="matrix(0.705995 -0.708217 0.741625 0.670814 5.66846 19.8633)" fill="white"/>
  <defs>
    <filter id="filter0_d_21799_47188" x="6.76256" y="32.5078" width="15.1565" height="10.095" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3.36"/>
      <feGaussianBlur stdDeviation="1.68"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21799_47188"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21799_47188" result="shape"/>
    </filter>
  </defs>
</svg>`;
  }

  if (type === 3) {
    return `
   <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none">
  <g opacity="0.15" filter="url(#filter0_d_21799_47207)">
    <ellipse cx="13.0193" cy="31.1097" rx="3.78" ry="1.68" fill="black"/>
    <path d="M14.1283 29.5032C15.0006 29.622 15.7387 29.8773 16.2164 30.213L15.9749 30.5566C16.2919 30.7795 16.3793 30.9764 16.3793 31.1097C16.3793 31.243 16.2919 31.4399 15.9749 31.6628L16.2164 32.0064C15.7387 32.3421 15.0006 32.5974 14.1283 32.7162L14.0716 32.3001C13.7401 32.3452 13.3867 32.3697 13.0193 32.3697C12.6518 32.3697 12.2984 32.3452 11.9669 32.3001L11.9102 32.7162C11.0379 32.5974 10.2999 32.3421 9.82214 32.0064L10.0636 31.6628C9.74657 31.4399 9.65926 31.243 9.65926 31.1097C9.65926 30.9764 9.74657 30.7795 10.0636 30.5566L9.82214 30.213C10.2999 29.8773 11.0379 29.622 11.9102 29.5032L11.9669 29.9193C12.2984 29.8742 12.6518 29.8497 13.0193 29.8497C13.3867 29.8497 13.7401 29.8742 14.0716 29.9193L14.1283 29.5032Z" stroke="black" stroke-width="0.84" stroke-linejoin="bevel" stroke-dasharray="1.68 1.68"/>
  </g>
  <mask id="path-3-inside-1_21799_47207" fill="white">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 5.91907C-0.00244141 2.67163 2.63012 0.0390625 5.87756 0.0390625H20.1576C23.405 0.0390625 26.0376 2.67163 26.0376 5.91906V20.1991C26.0376 23.4465 23.405 26.0791 20.1576 26.0791H16.9341L13.7169 30.9084C13.3845 31.4075 12.6512 31.4075 12.3188 30.9084L9.10159 26.0791H5.87756C2.63013 26.0791 -0.00244141 23.4465 -0.00244141 20.1991V5.91907Z"/>
  </mask>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 5.91907C-0.00244141 2.67163 2.63012 0.0390625 5.87756 0.0390625H20.1576C23.405 0.0390625 26.0376 2.67163 26.0376 5.91906V20.1991C26.0376 23.4465 23.405 26.0791 20.1576 26.0791H16.9341L13.7169 30.9084C13.3845 31.4075 12.6512 31.4075 12.3188 30.9084L9.10159 26.0791H5.87756C2.63013 26.0791 -0.00244141 23.4465 -0.00244141 20.1991V5.91907Z" fill="#F2F2F2"/>
  <path d="M16.9341 26.0791V25.2391H16.4844L16.235 25.6134L16.9341 26.0791ZM13.7169 30.9084L14.416 31.3741L14.416 31.3741L13.7169 30.9084ZM12.3188 30.9084L11.6197 31.3741L11.6197 31.3741L12.3188 30.9084ZM9.10159 26.0791L9.80067 25.6134L9.55133 25.2391H9.10159V26.0791ZM5.87756 -0.800938C2.1662 -0.800938 -0.842441 2.20771 -0.842441 5.91907H0.837559C0.837559 3.13555 3.09404 0.879063 5.87756 0.879063V-0.800938ZM20.1576 -0.800938H5.87756V0.879063H20.1576V-0.800938ZM26.8776 5.91906C26.8776 2.20771 23.8689 -0.800938 20.1576 -0.800938V0.879063C22.9411 0.879063 25.1976 3.13555 25.1976 5.91906H26.8776ZM26.8776 20.1991V5.91906H25.1976V20.1991H26.8776ZM20.1576 26.9191C23.8689 26.9191 26.8776 23.9104 26.8776 20.1991H25.1976C25.1976 22.9826 22.9411 25.2391 20.1576 25.2391V26.9191ZM16.9341 26.9191H20.1576V25.2391H16.9341V26.9191ZM16.235 25.6134L13.0179 30.4427L14.416 31.3741L17.6332 26.5448L16.235 25.6134ZM13.0179 30.4427H13.0179L11.6197 31.3741C12.2846 32.3722 13.7511 32.3722 14.416 31.3741L13.0179 30.4427ZM13.0179 30.4427L9.80067 25.6134L8.40251 26.5448L11.6197 31.3741L13.0179 30.4427ZM5.87756 26.9191H9.10159V25.2391H5.87756V26.9191ZM-0.842441 20.1991C-0.842441 23.9104 2.16621 26.9191 5.87756 26.9191V25.2391C3.09405 25.2391 0.837559 22.9826 0.837559 20.1991H-0.842441ZM-0.842441 5.91907V20.1991H0.837559V5.91907H-0.842441Z" fill="#B0B0B0" mask="url(#path-3-inside-1_21799_47207)"/>
  <g style="mix-blend-mode:luminosity">
    <path d="M18.5071 16.5234H7.34214C6.11576 16.5234 5.12158 17.512 5.12158 18.7316V18.7509C5.12158 19.9704 6.11576 20.959 7.34214 20.959H18.5071C19.7335 20.959 20.7277 19.9704 20.7277 18.7509V18.7316C20.7277 17.512 19.7335 16.5234 18.5071 16.5234Z" fill="#7B59A3"/>
    <g style="mix-blend-mode:multiply">
      <path d="M18.701 5.16797H7.53598C6.30961 5.16797 5.31543 6.15658 5.31543 7.37609V7.39543C5.31543 8.61494 6.30961 9.60355 7.53598 9.60355H18.701C19.9273 9.60355 20.9215 8.61494 20.9215 7.39543V7.37609C20.9215 6.15658 19.9273 5.16797 18.701 5.16797Z" fill="#FFEA00"/>
    </g>
    <g style="mix-blend-mode:multiply">
      <path d="M17.1081 5.80088L5.76252 17.1818C4.89913 18.0479 4.90527 19.446 5.77622 20.3045L5.79003 20.3182C6.66099 21.1767 8.06695 21.1706 8.93034 20.3045L20.2759 8.92359C21.1393 8.05751 21.1332 6.65942 20.2622 5.80087L20.2484 5.78726C19.3775 4.9287 17.9715 4.9348 17.1081 5.80088Z" fill="#20AEE5"/>
    </g>
  </g>
  <defs>
    <filter id="filter0_d_21799_47207" x="5.87926" y="29.4297" width="14.2801" height="10.0794" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3.36"/>
      <feGaussianBlur stdDeviation="1.68"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21799_47207"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21799_47207" result="shape"/>
    </filter>
  </defs>
</svg>`;
  }
  if (type === 4) {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="36" viewBox="0 0 29 36" fill="none">
  <g clip-path="url(#clip0_21799_47229)">
    <g opacity="0.15" filter="url(#filter0_d_21799_47229)">
      <ellipse cx="14.3414" cy="34.1873" rx="4.21836" ry="1.68735" fill="black"/>
      <path d="M15.5525 32.5706C16.5097 32.6851 17.3241 32.932 17.863 33.2581L17.6456 33.6174C18.0456 33.8595 18.1398 34.0689 18.1398 34.1873C18.1398 34.3058 18.0456 34.5152 17.6456 34.7573L17.863 35.1166C17.3241 35.4427 16.5097 35.6896 15.5525 35.8041L15.5026 35.3871C15.136 35.431 14.7461 35.4547 14.3414 35.4547C13.9367 35.4547 13.5469 35.431 13.1802 35.3871L13.1303 35.8041C12.1731 35.6896 11.3587 35.4427 10.8198 35.1166L11.0373 34.7573C10.6372 34.5152 10.543 34.3058 10.543 34.1873C10.543 34.0689 10.6372 33.8595 11.0373 33.6174L10.8198 33.2581C11.3587 32.932 12.1731 32.6851 13.1303 32.5706L13.1802 32.9876C13.5469 32.9437 13.9367 32.92 14.3414 32.92C14.7461 32.92 15.136 32.9437 15.5026 32.9876L15.5525 32.5706Z" stroke="black" stroke-width="0.84" stroke-linejoin="bevel" stroke-dasharray="1.68 1.68"/>
    </g>
    <mask id="path-3-inside-1_21799_47229" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 6.46002C-0.00244141 2.91383 2.87232 0.0390625 6.41852 0.0390625H22.1366C25.6828 0.0390625 28.5576 2.91383 28.5576 6.46002V22.1781C28.5576 25.7243 25.6828 28.5991 22.1366 28.5991H18.5734L15.0422 33.8998C14.6791 34.4447 13.8784 34.4447 13.5154 33.8998L9.9842 28.5991H6.41852C2.87232 28.5991 -0.00244141 25.7243 -0.00244141 22.1781V6.46002Z"/>
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00244141 6.46002C-0.00244141 2.91383 2.87232 0.0390625 6.41852 0.0390625H22.1366C25.6828 0.0390625 28.5576 2.91383 28.5576 6.46002V22.1781C28.5576 25.7243 25.6828 28.5991 22.1366 28.5991H18.5734L15.0422 33.8998C14.6791 34.4447 13.8784 34.4447 13.5154 33.8998L9.9842 28.5991H6.41852C2.87232 28.5991 -0.00244141 25.7243 -0.00244141 22.1781V6.46002Z" fill="#6B6B6B"/>
    <path d="M18.5734 28.5991V27.6818H18.0822L17.81 28.0905L18.5734 28.5991ZM15.0422 33.8998L14.2788 33.3912L14.2788 33.3912L15.0422 33.8998ZM13.5154 33.8998L12.752 34.4083L13.5154 33.8998ZM9.9842 28.5991L10.7476 28.0905L10.4753 27.6818H9.9842V28.5991ZM6.41852 -0.878218C2.36572 -0.878218 -0.919721 2.40723 -0.919721 6.46002H0.914839C0.914839 3.42043 3.37892 0.956343 6.41852 0.956343V-0.878218ZM22.1366 -0.878218H6.41852V0.956343H22.1366V-0.878218ZM29.4748 6.46002C29.4748 2.40723 26.1894 -0.878218 22.1366 -0.878218V0.956343C25.1762 0.956343 27.6403 3.42042 27.6403 6.46002H29.4748ZM29.4748 22.1781V6.46002H27.6403V22.1781H29.4748ZM22.1366 29.5163C26.1894 29.5163 29.4748 26.2309 29.4748 22.1781H27.6403C27.6403 25.2177 25.1762 27.6818 22.1366 27.6818V29.5163ZM18.5734 29.5163H22.1366V27.6818H18.5734V29.5163ZM17.81 28.0905L14.2788 33.3912L15.8056 34.4083L19.3368 29.1076L17.81 28.0905ZM14.2788 33.3912L14.2788 33.3912L12.752 34.4083C13.4781 35.4983 15.0795 35.4983 15.8056 34.4083L14.2788 33.3912ZM14.2788 33.3912L10.7476 28.0905L9.2208 29.1076L12.752 34.4083L14.2788 33.3912ZM6.41852 29.5163H9.9842V27.6818H6.41852V29.5163ZM-0.919721 22.1781C-0.919721 26.2309 2.36572 29.5163 6.41852 29.5163V27.6818C3.37892 27.6818 0.914839 25.2177 0.914839 22.1781H-0.919721ZM-0.919721 6.46002V22.1781H0.914839V6.46002H-0.919721Z" fill="#555555" mask="url(#path-3-inside-1_21799_47229)"/>
    <rect x="6.76074" y="6.59375" width="15.248" height="4.33313" rx="2.16656" fill="white"/>
    <rect x="6.57129" y="17.6836" width="15.248" height="4.33313" rx="2.16656" fill="white"/>
    <rect width="20.0243" height="4.34954" rx="2.17477" transform="matrix(0.705995 -0.708217 0.741625 0.670814 5.66846 19.8633)" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_21799_47229" x="6.76305" y="32.5" width="15.1565" height="10.095" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="3.36"/>
      <feGaussianBlur stdDeviation="1.68"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21799_47229"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21799_47229" result="shape"/>
    </filter>
    <clipPath id="clip0_21799_47229">
      <rect width="28.56" height="35.85" fill="white" transform="translate(0 0.0234375)"/>
    </clipPath>
  </defs>
</svg>`;
  }

  return `
       <div 
        style="display: flex; box-sizing: border-box; background: ${bgColor}; border: 1px solid ${borderColor}; padding: ${isPricePublic ? '10px' : '3.5px'}; border-radius: ${isPricePublic ? '4px' : '8px'}; flex-direction: column; white-space: nowrap; "
        >
      
       ${
         marker.basicTime && marker.basicCharge && marker.regionType === RegionType.PUBLIC
           ? `
          <div >
             ${
               marker.basicTime !== '0'
                 ? `
            <p style="font-size: 12px; font-weight: 500; color: ${timeTextColor}; margin-bottom: 2px;">
              ${marker.basicTime}분 기준
            </p>
            `
                 : ``
             }
            <p style="font-size: 15px; font-weight: 600; color: ${textColor};">
              ${marker.basicTime === '0' ? '무료' : `${marker.basicCharge}원`}
            </p>
          </div>`
           : marker.regionType === RegionType.PUBLIC
             ? `
           <div style=" color: ${
             marker.regionType === RegionType.PUBLIC && isSelected
               ? MARKER_COLORS.PURPLE_LIGHT
               : MARKER_COLORS.WHITE
           };">
                 <p style="font-weight: 800; font-size: 22px; color: ${logoColor}">
                  P
                </p>
           </div>
            `
             : marker.regionType !== RegionType.PUBLIC && isSelected
               ? `
              <div style="display: flex; align-items: flex-end;">
                 <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <rect x="2.05078" y="1.89844" width="18.1524" height="5.15848" rx="2.57924" fill="white"/>
                  <rect x="1.82617" y="15.1055" width="18.1524" height="5.15848" rx="2.57924" fill="white"/>
                  <rect width="23.8384" height="5.17802" rx="2.58901" transform="matrix(0.705995 -0.708217 0.741625 0.670814 0.750977 17.6992)" fill="white"/>
                 </svg>
                 <p style="font-size: 10px;font-style: normal; font-weight: 600; color: ${productCountColor}; margin-left: 4px;">
                  외 ${marker.productCount - 1}면
                 </p>
              </div> `
               : `
                <div style="display: flex; align-items: flex-end;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                      <path d="M15.4112 13.3203H3.3854C2.06448 13.3203 0.993652 14.3851 0.993652 15.6987V15.7195C0.993652 17.033 2.06448 18.0979 3.3854 18.0979H15.4112C16.7321 18.0979 17.8029 17.033 17.8029 15.7195V15.6987C17.8029 14.3851 16.7321 13.3203 15.4112 13.3203Z" fill="#7B59A3"/>
                      <g style="mix-blend-mode:multiply">
                      <path d="M15.6202 1.09375H3.59439C2.27346 1.09375 1.20264 2.15858 1.20264 3.47211V3.49294C1.20264 4.80647 2.27346 5.8713 3.59439 5.8713H15.6202C16.9411 5.8713 18.0119 4.80647 18.0119 3.49294V3.47211C18.0119 2.15858 16.9411 1.09375 15.6202 1.09375Z" fill="#FFEA00"/>
                      </g>
                      <g style="mix-blend-mode:multiply">
                      <path d="M13.9041 1.77519L1.68383 14.0336C0.753876 14.9664 0.760484 16.4723 1.69859 17.3971L1.71346 17.4117C2.65157 18.3365 4.16593 18.3299 5.09588 17.397L17.3162 5.13866C18.2461 4.20581 18.2395 2.69993 17.3014 1.77518L17.2865 1.76052C16.3484 0.835771 14.8341 0.842342 13.9041 1.77519Z" fill="#20AEE5"/>
                      </g>
                    </svg>
                     <p style="font-size: 10px;font-style: normal; font-weight: 600; color: ${productCountColor}; margin-left: 4px;">
                      외 ${marker.productCount - 1}면
                     </p>
                </div>
                `
       }
       
        <div style="
            content: ''; 
            position: absolute; 
            bottom: -7px; 
            left: 50%; 
            transform: translateX(-50%); 
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent; 
            border-right: 6px solid transparent; 
            border-top: 8px solid ${borderColor};
        "/>
      <div style="
            content: ''; 
            position: absolute; 
            bottom: 1px; 
            left: 50%; 
            transform: translateX(-50%); 
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent; 
            border-right: 6px solid transparent; 
            border-top: ${`8px solid ${bgColor}`};
        "/>
           <div style="
            position: absolute; 
            bottom: -16px; 
            left: 50%; 
            transform: translateX(-50%); 
        ">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
              <g opacity="0.15" filter="url(#filter0_d_21337_29241)">
              <path d="M14 2C14 3.10457 11.7614 4 9 4C6.23858 4 4 3.10457 4 2C4 0.89543 6.23858 0 9 0C11.7614 0 14 0.89543 14 2Z" fill="black"/>
              <path d="M10.4355 0.0836474C11.5701 0.219435 12.5354 0.512041 13.1741 0.898564L12.9153 1.32634C13.3892 1.61315 13.5 1.86074 13.5 2C13.5 2.13926 13.3892 2.38685 12.9153 2.67366L13.1741 3.10144C12.5354 3.48796 11.5701 3.78056 10.4355 3.91635L10.3761 3.4199C9.9416 3.47189 9.47958 3.5 9 3.5C8.52042 3.5 8.0584 3.47189 7.62393 3.4199L7.56452 3.91635C6.42992 3.78056 5.46462 3.48796 4.82588 3.10144L5.08474 2.67366C4.61078 2.38685 4.5 2.13926 4.5 2C4.5 1.86074 4.61078 1.61315 5.08474 1.32634L4.82588 0.898564C5.46462 0.512041 6.42992 0.219435 7.56452 0.0836474L7.62393 0.580105C8.0584 0.528108 8.52042 0.5 9 0.5C9.47958 0.5 9.9416 0.528108 10.3761 0.580105L10.4355 0.0836474Z" stroke="black" stroke-linejoin="bevel" stroke-dasharray="2 2"/>
              </g>
              <defs>
              <filter id="filter0_d_21337_29241" x="0" y="0" width="18" height="12" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21337_29241"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21337_29241" result="shape"/>
              </filter>
              </defs>
              </svg>
          </div>
      </div>
    `;
};

export const createChargerMarkerContent = (
  marker: any,
  selectItem: string | number | null,
): string => {
  const isSelected = marker.statId === selectItem;
  return `
  <div 
    style="
      display: flex; 
      align-items: center;
      box-sizing: border-box; 
      padding: 4px; 
      border-radius: 4px; 
      background-color: ${isSelected ? '#8B63BB' : MARKER_COLORS.WHITE};
    ">
    <div 
      style="
        background: ${MARKER_COLORS.WHITE}; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        width: 18px; 
        height: 18px; 
        border-radius: 50%;
        margin-right: 6px;
      ">
      <img 
        width="12" 
        height="12" 
        src="${marker.logoImagePath}" 
        alt="logo"
      />
    </div>
    ${
      marker.slowTotalCnt !== 0
        ? `<span 
      style="
        font-weight: bold; 
        font-size: 14px; 
        color: ${isSelected ? '#fff' : '#333'};
      ">
            완 ${marker.slowTotalCnt}
        </span>`
        : ``
    }
    ${
      marker.slowTotalCnt !== 0 && marker.rapidTotalCnt !== 0
        ? `<span
              style="
                margin-left: 4px;
                margin-right: 4px;
                font-size: 14px;
                color: ${MARKER_COLORS.BORDER_COLOR};
              "
            >
            |
            </span>`
        : ''
    }
       ${
         marker.rapidTotalCnt !== 0
           ? `
  
        <span 
              style="
                font-weight: bold; 
                font-size: 14px; 
                color: ${isSelected ? MARKER_COLORS.WHITE : MARKER_COLORS.BLACK};
              ">
           급 ${marker.rapidTotalCnt}
          </span>`
           : ``
       }
   
    <div 
      style="
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-top:  ${isSelected ? '6px solid #8B63BB' : '6px solid #fff'};
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      "/>
  </div>
`;
};

export const createJinjuMarkerContent = (
  sensor: any,
  data: any,
  selectItem: string | number | null,
): string => `
  <div style="
  background-color: ${selectItem === sensor.parkingLocation ? MARKER_COLORS.BLACK : sensor.isUsed === UsageStatus.USED ? MARKER_COLORS.LIGHT_GRAY : MARKER_COLORS.WHITE}; 
  border-radius: 4px; 
  align-items: center; 
  justify-content: center; 
  display: inline-flex; 
  padding: 4px 2px;
  border: ${sensor.isUsed === UsageStatus.USED ? '1px solid #868686' : `1px solid ${MARKER_COLORS.BLACK}`};
  box-sizing: border-box;
  white-space: nowrap; 
   "
  >
  <p style="
    color: ${selectItem === sensor.parkingLocation ? MARKER_COLORS.WHITE : sensor.isUsed === UsageStatus.USED ? '#F2F2F2' : MARKER_COLORS.BLACK}; 
    font-size: 14px;
    ">
${data.parkingName}</p>


     <div style="
      content: ''; 
      position: absolute; 
      bottom: -7px; 
      left: 50%; 
      transform: translateX(-50%); 
      width: 0; 
      height: 0; 
      border-left: 6px solid transparent; 
      border-right: 6px solid transparent; 
      border-top: 8px solid #868686;"/>
      <div style="
        content: ''; 
        position: absolute; 
        bottom: 1px; 
        left: 50%; 
        transform: translateX(-50%); 
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent; 
        border-right: 6px solid transparent; 
        border-top: 
        ${selectItem === sensor.parkingLocation ? `8px solid ${MARKER_COLORS.BLACK}` : sensor.isUsed === UsageStatus.USED ? `8px solid ${MARKER_COLORS.LIGHT_GRAY}` : `8px solid ${MARKER_COLORS.WHITE}`}
        "/>
    </div>
`;

export const createJinjuClusterContent = (name: string, totalParkingSpace: number): string => `
  <div style="
  background-color: #8B63BB;
  border-radius: 20px; 
  align-items: center; 
  justify-content: center; 
  display: inline-flex; 
  padding: 4px 6px;
  box-sizing: border-box;
  opacity: 0.8;
      white-space: nowrap; 
   "
  >
     <div style="
    width: 30px;
    height: 30px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 6px;
    background-color: ${MARKER_COLORS.WHITE};
      ">
        <p style=" color: ${MARKER_COLORS.BLACK}; font-size: 15px; font-weight: 700; ">${totalParkingSpace}</p>
      </div>
    <p style=" color: ${MARKER_COLORS.WHITE}; font-size: 18px; font-weight: 700; ">${name}</p>
  </div>
`;
