import { styled } from '@stitches/react';

export const Modal = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '16px',
  paddingBottom: 110,
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  height: '86vh',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' }, // 모달을 화면 위로 슬라이드
      false: { transform: 'translateY(100%)' }, // 화면 아래로 숨김
    },
  },
});

export const StyledCheckbox = styled('div', {
  width: '28px',
  height: '28px',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px', // 체크박스와 레이블 간격
  transition: 'background-color 0.2s ease, border-color 0.2s ease',

  variants: {
    checked: {
      true: {
        backgroundColor: '#8B63BB',
        border: '1px solid #8B63BB',
      },
      false: {
        backgroundColor: '#fff',
        border: '1px solid #CCC',
      },
    },
  },
});

export const WrapFilterList = styled('div', {
  marginTop: 28,
});

export const WrapFilterItem = styled('div', {
  padding: 10,
  borderRadius: 8,
  border: '1px solid #CCC',
  marginRight: 8,
});
