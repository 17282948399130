import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const PhoneNumberBorderContainer = styled('div', {
  padding: '12px 15px',
  backgroundColor: colors.primaryBackground,
  borderRadius: 10,
  marginTop: 40,
});

export const CertificationContainer = styled('div', {
  padding: '12px 15px',
  backgroundColor: colors.white,
  marginTop: 10,
  border: '1px solid #ccc',
  borderRadius: 10,

  variants: {
    isFocused: {
      true: {
        border: '1px solid #8B63BB',
      },
      false: {
        border: '1px solid #ccc',
      },
    },
  },
});

export const UserInfoInput = styled('input', {
  border: '0px',
  outline: 'none',
  fontWeight: 400,
  fontSize: '17px',
  color: '#1A1A1A',
  width: 'calc(100% - 80px)',

  '&::placeholder': {
    color: '#B0B0B0',
  },
});

export const BottomButtonContainer = styled('div', {
  margin: '20px 20px 40px 0px',
  width: 'calc(100%)',
  bottom: '0',
  left: '0',
  backgroundColor: '#fff',
  textAlign: 'center',
  zIndex: 10,
});

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  userSelect: 'none',
  '&:disabled': {
    backgroundColor: '#d3d3d3',
    color: '#fff',
    cursor: 'not-allowed',
  },
});

export const CertNumberContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F8FA',
  borderRadius: '10px',
  color: '#6B6B6B',
  marginTop: '10px',
  justifyContent: 'space-between',
  padding: '16px 16px 10px',

  variants: {
    selected: {
      true: {
        border: '1px solid #8B63BB',
        backgroundColor: '#FFF',
      },
      false: {
        border: '1px solid transparent',
        backgroundColor: '#F8F8FA',
      },
    },
  }
})
