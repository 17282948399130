export const mapData = {
  result: [
    {
      lotUID: 15,
      salePrice: 300,
      lat: 37.5129480156762,
      lon: 126.898260025571,
      operTime: '0',
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      operationYN: '1',
      productCount: '7',
      formattedOperTime: '24시',
      regionType: 'zoomansaMonthly',
      tranUID: 0,
      infra_id: '',
    },
    {
      lotUID: 18,
      salePrice: 450,
      lat: 37.5163887012653,
      lon: 126.899975370783,
      operTime: '0',
      productStatus: 'SALE',
      paymentAvailbleYN: 'NO',
      operationYN: '1',
      productCount: '2',
      formattedOperTime: '24시',
      regionType: 'zoomansaMonthly',
      tranUID: 0,
      infra_id: '',
    },
    {
      lotUID: 21,
      salePrice: 200000,
      lat: 37.5136195784248,
      lon: 126.899469731603,
      operTime: '0',
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      operationYN: '1',
      productCount: '3',
      formattedOperTime: '24시',
      regionType: 'zoomansaMonthly',
      tranUID: 0,
      infra_id: '',
    },
    {
      lotUID: 23,
      salePrice: 134000,
      lat: 37.5137094042012,
      lon: 126.90020844733,
      operTime: '0',
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      operationYN: '1',
      productCount: '4',
      formattedOperTime: '24시',
      regionType: 'zoomansaMonthly',
      tranUID: 0,
      infra_id: '',
    },
    {
      lotUID: 28,
      salePrice: 300,
      lat: 37.5109275681194,
      lon: 126.892323795203,
      operTime: '0',
      productStatus: 'SALE',
      paymentAvailbleYN: 'NO',
      operationYN: '1',
      productCount: '1',
      formattedOperTime: '24시',
      regionType: 'zoomansaMonthly',
      tranUID: 0,
      infra_id: '',
    },
    {
      lat: '37.5194349',
      lon: '126.9003849',
      regionCode: 2791,
      slotCode: 2791,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 영등포로34길 9',
      lnmadr: '서울특별시 영등포구 영등포동4가 123',
      prkplceNm: '영남',
      prkplceNo: '118-2-000003',
    },
    {
      lat: '37.5133616',
      lon: '126.895222',
      regionCode: 2942,
      slotCode: 2942,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 문래동3가 54-12',
      lnmadr: '서울특별시 영등포구 문래동3가 54-12',
      prkplceNm: '문래1동',
      prkplceNo: '118-1-000003',
    },
    {
      lat: '37.50820335',
      lon: '126.8967334',
      regionCode: 13266,
      slotCode: 13266,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 도신로15길 36',
      lnmadr: '',
      prkplceNm: '도림동 공영주차장',
      prkplceNo: '118-2-000006',
    },
    {
      lat: '37.516205',
      lon: '126.8878482',
      regionCode: 13267,
      slotCode: 13267,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '',
      lnmadr: '서울특별시 영등포구 문래동5가 3',
      prkplceNm: '문래동 공영주차장',
      prkplceNo: '118-2-000007',
    },
    {
      lat: '37.50750302',
      lon: '126.9072907',
      regionCode: 13276,
      slotCode: 13276,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 가마산로65길 22',
      lnmadr: '',
      prkplceNm: '도림초교공영주차장',
      prkplceNo: '118-2-000017',
    },
    {
      lat: '37.51723371',
      lon: '126.903716',
      regionCode: 13282,
      slotCode: 13282,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 영중로 15',
      lnmadr: '',
      prkplceNm: '영등포동제3공영주차장',
      prkplceNo: '118-2-000023',
    },
    {
      lat: '37.51512033',
      lon: '126.8936472',
      regionCode: 13285,
      slotCode: 13285,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 당산로1',
      lnmadr: '',
      prkplceNm: '문래근린공원공영주차장',
      prkplceNo: '118-2-000026',
    },
    {
      lat: '37.523768',
      lon: '126.9000061',
      regionCode: 13307,
      slotCode: 13307,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 양산로 147',
      lnmadr: '',
      prkplceNm: '당산1동 공영주차장',
      prkplceNo: '118-2-000004',
    },
    {
      lat: '37.50744252',
      lon: '126.8922692',
      regionCode: 15613,
      slotCode: 15613,
      prkplceSe: 'private',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 구로구 새말로 117-6',
      lnmadr: '서울특별시 구로구 구로동3-55',
      prkplceNm: '신도림역 주차장',
      prkplceNo: '116-4-000021',
    },
  ],
  result_jinju: {
    data: [
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 79,
        sensorList: [
          {
            parkingLocation: '128',
            lastUpdateDtm: '20250211124652342',
            con: 'aa20108f181d007f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083172,
            deui: '5c86c1fffe202551',
            locY: 35.192215,
            idx: '13mo8bGQkHx8J-A8EvJ3S9',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '127',
            lastUpdateDtm: '20250211130811610',
            con: 'aa20109f221801707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.08315,
            deui: '5c86c1fffe20256d',
            locY: 35.19221,
            idx: '8IwJDPIq4eJaSIw~Z5c~Bg',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '126',
            lastUpdateDtm: '20250211130156173',
            con: 'aa201097231900627e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083117,
            deui: '5c86c1fffe2025e0',
            locY: 35.1922,
            idx: 'dyrmbmyiklS8Q54TOOHXCI',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '130',
            lastUpdateDtm: '20250211134703173',
            con: 'aa201098211901627e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083249,
            deui: 'd02544fffef6cff1',
            locY: 35.192241,
            idx: 'ekdmlrbZ4ON9Bv6NRIhh0E',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '116',
            lastUpdateDtm: '20250211111727600',
            con: 'aa2010941f19fd9b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.08263,
            deui: 'd02544fffef6d002',
            locY: 35.192035,
            idx: 'bsfDAqwok2f92tRE9dpQxp',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '131',
            lastUpdateDtm: '20250210201429162',
            con: 'aa201099221800627e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083326,
            deui: 'd02544fffef6d284',
            locY: 35.192246,
            idx: 'fO1ep1uH4xObtxZCODr9xh',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '117',
            lastUpdateDtm: '20250211125652232',
            con: 'aa20108c141cfea27e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082639,
            deui: 'd02544fffef6de13',
            locY: 35.192042,
            idx: 'dFuOCCB~kdE9OG9PTC4Mj0',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '123',
            lastUpdateDtm: '20250211134521754',
            con: 'aa201097271a006e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082947,
            deui: 'd02544fffef6de14',
            locY: 35.192146,
            idx: '71lXG28dkDY9Cp~oydHQjH',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '124',
            lastUpdateDtm: '20250211122643716',
            con: 'aa20109e2414ff987e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082975,
            deui: 'd02544fffef6de19',
            locY: 35.192155,
            idx: '9p~Tzm1tA~U8lMg9SHJNRC',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '125',
            lastUpdateDtm: '20250211131950347',
            con: 'aa200097281f01747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083067,
            deui: 'd02544fffef6de24',
            locY: 35.192184,
            idx: '9xEohfT7AEuanPWgP~h1QP',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '115',
            lastUpdateDtm: '20250211130926565',
            con: 'aa201099231bfe987e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082607,
            deui: 'd02544fffef6de2b',
            locY: 35.192021,
            idx: '3r2fXwB8AAk9hJpoljzcOB',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '122',
            lastUpdateDtm: '20250211131811919',
            con: 'aa2010a41b1602747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082928,
            deui: 'd02544fffef6de2c',
            locY: 35.192139,
            idx: 'aEQfan4G4Lv93HC-sWBUYB',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '121',
            lastUpdateDtm: '20250211133854217',
            con: 'aa2000921a1f00767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082877,
            deui: 'd02544fffef6de4a',
            locY: 35.192121,
            idx: '2eOyiAQmAhKbFxqRdZ2X2G',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '118',
            lastUpdateDtm: '20250211095547071',
            con: 'aa2010911717fd877e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082738,
            deui: 'd02544fffef6de53',
            locY: 35.192078,
            idx: 'aBrlGxk4kycbd8U6rEhR-O',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '129',
            lastUpdateDtm: '20250211134713024',
            con: 'aa20009b261000667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.083199,
            deui: 'd02544fffef6de55',
            locY: 35.192227,
            idx: '6eKqj~cP4aabBgJ0Lm7AVO',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '114',
            lastUpdateDtm: '20250211134834246',
            con: 'aa200092231501597e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082553,
            deui: 'd02544fffef6de5c',
            locY: 35.192019,
            idx: 'b2YSdc894FW9563TgBJfdP',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '119',
            lastUpdateDtm: '20250211113226359',
            con: 'aa20109e2a15fda47e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082757,
            deui: 'd02544fffef6deea',
            locY: 35.192084,
            idx: '6DsKBlLkAia9I4EZeA7r0F',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '120',
            lastUpdateDtm: '20250211133237359',
            con: 'aa20009c2816006f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082834,
            deui: 'd02544fffef6deeb',
            locY: 35.192111,
            idx: '5Ko6WBsd4Xtbm52Rsahc~O',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '113',
            lastUpdateDtm: '20250211123130065',
            con: 'aa200097211bfe937e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000012',
            locX: 128.082497,
            deui: 'd02544fffef6deee',
            locY: 35.192001,
            idx: 'cxD0ciFcABU87B3U~2UIwD',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '93',
            lastUpdateDtm: '20250211134822502',
            con: 'aa2010a2251604687e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082058,
            deui: '5c86c1fffe1fafde',
            locY: 35.191912,
            idx: '1TmSmQRkkBIa8Ocj3AzLXH',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '92',
            lastUpdateDtm: '20250211131449021',
            con: 'aa20109e291305757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082012,
            deui: '5c86c1fffe1fb1a2',
            locY: 35.191892,
            idx: '8PY5~5LG4WnadK2wpVO3Zs',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '91',
            lastUpdateDtm: '20250211134417026',
            con: 'aa2000931e1b02797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.081863,
            deui: '5c86c1fffe1fb1d7',
            locY: 35.191855,
            idx: '7wfGU964X~83oi5hpyQlQ',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '235',
            lastUpdateDtm: '20250211070753168',
            con: 'aa2010a22913fa8c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.08183794370137,
            deui: '5c86c1fffe21e4c6',
            locY: 35.19183245317333,
            idx: '1EFtZtPzAdf9TsJzLtTTAt',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '109',
            lastUpdateDtm: '20250211113153604',
            con: 'aa2000a72919fd997e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082325,
            deui: 'd02544fffef6cf67',
            locY: 35.192366,
            idx: '3g38wh72465b4QtYL9jOS3',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '110',
            lastUpdateDtm: '20250211133234730',
            con: 'aa2010a02215005e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082349,
            deui: 'd02544fffef6d02c',
            locY: 35.192318,
            idx: '1BHHi6do4kk8hCLHLyHdRM',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '112',
            lastUpdateDtm: '20250211092914201',
            con: 'aa2010a32710fd8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082405,
            deui: 'd02544fffef6d282',
            locY: 35.192161,
            idx: '1dIKjVCgQIh8V15bKNF4VS',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '95',
            lastUpdateDtm: '20250211120713473',
            con: 'aa20109b2414046d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082225,
            deui: 'd02544fffef6de1a',
            locY: 35.191961,
            idx: '6pwMuz0K4u49BRETKxXIZ8',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '90',
            lastUpdateDtm: '20250211102109116',
            con: 'aa20109f271dfca97e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.081748,
            deui: 'd02544fffef6de1f',
            locY: 35.191811,
            idx: '96tSEZKekni9R7sfuK825K',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '96',
            lastUpdateDtm: '20250211094044048',
            con: 'aa2010982616fe8e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082305,
            deui: 'd02544fffef6de2e',
            locY: 35.191981,
            idx: '44v4cvNg4Uy8Vj3TPatDFf',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '94',
            lastUpdateDtm: '20250211133711155',
            con: 'aa2000b3221d01717e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082145,
            deui: 'd02544fffef6de54',
            locY: 35.191934,
            idx: '7j37h-5-klR96P9hKqbcl7',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '111',
            lastUpdateDtm: '20250211133453313',
            con: 'aa2000991e1603747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000013',
            locX: 128.082389,
            deui: 'd02544fffef6de57',
            locY: 35.192208,
            idx: 'bXWMqaBQQF19W4S1OYN-ab',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '108',
            lastUpdateDtm: '20250211101647116',
            con: 'aa2010a52610fd8c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081788,
            deui: 'd02544fffef6d03d',
            locY: 35.1914,
            idx: '1dSG7lGFAmdagsoa-hITgD',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '104',
            lastUpdateDtm: '20250211131620902',
            con: 'aa20009e2215026c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081697,
            deui: 'd02544fffef6dd79',
            locY: 35.191667,
            idx: 'dw5ks1jPkGZ8XuOX5R5VgY',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '101',
            lastUpdateDtm: '20250211130132243',
            con: 'aa2010a2261f067b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081647,
            deui: 'd02544fffef6dd9e',
            locY: 35.19157,
            idx: '8kWUQR9M43fa0w8~giC6w3',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '100',
            lastUpdateDtm: '20250211124956837',
            con: 'aa2000a223180f797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081625,
            deui: 'd02544fffef6de33',
            locY: 35.19161,
            idx: '6fIXTAcr4uU99OdfwoCvo7',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '107',
            lastUpdateDtm: '20250211131436348',
            con: 'aa2000a72513086d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081767,
            deui: 'd02544fffef6de35',
            locY: 35.19147,
            idx: 'bgpeIunJQGd8Opd-i8Qn8y',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '102',
            lastUpdateDtm: '20250211133829034',
            con: 'aa2000962212045c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081673,
            deui: 'd02544fffef6de42',
            locY: 35.191513,
            idx: 'dYjzxK0KAYcaek9qEUD0Uv',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '99',
            lastUpdateDtm: '20250211133009880',
            con: 'aa2000a02415ff8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081614,
            deui: 'd02544fffef6de45',
            locY: 35.191652,
            idx: 'f08YPbksABLbmaWeplbmBW',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '103',
            lastUpdateDtm: '20250211123302026',
            con: 'aa2000a42211fe887e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081698,
            deui: 'd02544fffef6de46',
            locY: 35.191447,
            idx: '80EO2ODbksmaa-AT2SvcbN',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '106',
            lastUpdateDtm: '20250211134417342',
            con: 'aa2000a125120d727e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081747,
            deui: 'd02544fffef6de49',
            locY: 35.191536,
            idx: '61vwcgHi4Xc8yIrvucBljL',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '105',
            lastUpdateDtm: '20250211121224302',
            con: 'aa2010ab221109757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000014',
            locX: 128.081694,
            deui: 'd02544fffef6de4b',
            locY: 35.191632,
            idx: 'eFDCqSkg4~IbN2dDiPh-NI',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '73',
            lastUpdateDtm: '20250211090631277',
            con: 'aa201092201cfb8c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.079294,
            deui: '5c86c1fffe1fb0c9',
            locY: 35.191059,
            idx: 'bJT5mHkxQM0bFuNQ~pnjNQ',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '85',
            lastUpdateDtm: '20240911080142863',
            con: 'aa20109f22191b837e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.081346,
            deui: 'd02544fffef6dd5e',
            locY: 35.191692,
            idx: 'crvU6JiSQ2faQbIGAXWikp',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '82',
            lastUpdateDtm: '20241219003931729',
            con: 'aa20119a1d12fea17e',
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.080974,
            deui: 'd02544fffef6dd6e',
            locY: 35.191589,
            idx: 'eOT3zh-FkbyaeLfYrzvSQE',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '74',
            lastUpdateDtm: '20250120191529136',
            con: 'aa20008e0f12057d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.079514,
            deui: 'd02544fffef6dd6f',
            locY: 35.191115,
            idx: '4kXRCNuUQA1bzkzUboH-aL',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '79',
            lastUpdateDtm: '20250211113743095',
            con: 'aa2000890414005c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.080434,
            deui: 'd02544fffef6dd72',
            locY: 35.191412,
            idx: '2p8X7OunQjHbqA97BpnhgJ',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '80',
            lastUpdateDtm: '20250210130105547',
            con: 'aa201097281800667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.080601,
            deui: 'd02544fffef6dd75',
            locY: 35.191468,
            idx: '2zeznNCs4ow8yzUAiC8-cl',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '81',
            lastUpdateDtm: '20250211133051573',
            con: 'aa2010911912005a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.08089,
            deui: 'd02544fffef6dd76',
            locY: 35.191552,
            idx: 'fzDMGdqrksw82VyyJy7e5~',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '87',
            lastUpdateDtm: '20250211074538519',
            con: 'aa20109e2019fa947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.08151,
            deui: 'd02544fffef6de18',
            locY: 35.191745,
            idx: 'aytTkkDmQ6Gae1fbhm7MrM',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '84',
            lastUpdateDtm: '20250211112742083',
            con: 'aa2010a02914fe8f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.081261,
            deui: 'd02544fffef6de20',
            locY: 35.191679,
            idx: '3HgzzTHY4eY9kwrnAzbu04',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '86',
            lastUpdateDtm: '20250211074230391',
            con: 'aa2010961e14fa947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.081456,
            deui: 'd02544fffef6de28',
            locY: 35.191721,
            idx: '1VEBK5UYkHDbA1iJ2kgmM-',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '77',
            lastUpdateDtm: '20250211131108683',
            con: 'aa20108b081613707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.080214,
            deui: 'd02544fffef6de31',
            locY: 35.191313,
            idx: 'dqpG3EDaQUs9hQ7wfkdjya',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '78',
            lastUpdateDtm: '20250211123042879',
            con: 'aa2000931d1617727e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.080333,
            deui: 'd02544fffef6de38',
            locY: 35.191372,
            idx: '9Mih9k7Xk6F8vJ38VC~jmo',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '75',
            lastUpdateDtm: '20250211104415498',
            con: 'aa20008d0f1301797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.079978,
            deui: 'd02544fffef6de3b',
            locY: 35.191267,
            idx: 'cbPwsFspQ9xamxfXBJHOJE',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '76',
            lastUpdateDtm: '20250211123223943',
            con: 'aa20008c0e1cffb27e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.0800086,
            deui: 'd02544fffef6de3d',
            locY: 35.191317,
            idx: '4Ss1MS7Z4O69tf8JhN7C54',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '83',
            lastUpdateDtm: '20250211110043449',
            con: 'aa2000992613fe8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000015',
            locX: 128.081105,
            deui: 'd02544fffef6de47',
            locY: 35.191629,
            idx: '4eM7OPpG4pV8Xjtx1spJx5',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '1',
            lastUpdateDtm: '20241023081918860',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0001',
            locY: 35.192299,
            idx: '0001',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '2',
            lastUpdateDtm: '20241023114019858',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0002',
            locY: 35.192299,
            idx: '0002',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '3',
            lastUpdateDtm: '20241023131502027',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0003',
            locY: 35.192299,
            idx: '0003',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '4',
            lastUpdateDtm: '20241022181056913',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0004',
            locY: 35.192299,
            idx: '0004',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '5',
            lastUpdateDtm: '20241023114538306',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0005',
            locY: 35.192299,
            idx: '0005',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '6',
            lastUpdateDtm: '20241023120557377',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0006',
            locY: 35.192299,
            idx: '0006',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '7',
            lastUpdateDtm: '20241022144647544',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0007',
            locY: 35.192299,
            idx: '0007',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '8',
            lastUpdateDtm: '20241023105151120',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0008',
            locY: 35.192299,
            idx: '0008',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '9',
            lastUpdateDtm: '20241023131328831',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0009',
            locY: 35.192299,
            idx: '0009',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '10',
            lastUpdateDtm: '20241023095832247',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0010',
            locY: 35.192299,
            idx: '0010',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '11',
            lastUpdateDtm: '20241023103525591',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0011',
            locY: 35.192299,
            idx: '0011',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '12',
            lastUpdateDtm: '20241023093702599',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0012',
            locY: 35.192299,
            idx: '0012',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '13',
            lastUpdateDtm: '20241023085803663',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0013',
            locY: 35.192299,
            idx: '0013',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '14',
            lastUpdateDtm: '20241020233034313',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0014',
            locY: 35.192299,
            idx: '0014',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '15',
            lastUpdateDtm: '20241023124157095',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0015',
            locY: 35.192299,
            idx: '0015',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '16',
            lastUpdateDtm: '20241023115740900',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0016',
            locY: 35.192299,
            idx: '0016',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '17',
            lastUpdateDtm: '20241023114854970',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0017',
            locY: 35.192299,
            idx: '0017',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '18',
            lastUpdateDtm: '20241022154609019',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0018',
            locY: 35.192299,
            idx: '0018',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '19',
            lastUpdateDtm: '20241023113135206',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0019',
            locY: 35.192299,
            idx: '0019',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '20',
            lastUpdateDtm: '20241023133251384',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0020',
            locY: 35.192299,
            idx: '0020',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '21',
            lastUpdateDtm: '20241023103544395',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0021',
            locY: 35.192299,
            idx: '0021',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '22',
            lastUpdateDtm: '20241023133202225',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0022',
            locY: 35.192299,
            idx: '0022',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '23',
            lastUpdateDtm: '20241023083350694',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000001',
            locX: 128.082848,
            deui: '0023',
            locY: 35.192299,
            idx: '0023',
            isDisSpace: 'false',
            isUsed: '1',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-2',
        usedParkingSpace: 43,
        parkingName: '수련관인근',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 30,
        sensorList: [
          {
            parkingLocation: '61',
            lastUpdateDtm: '20250211113749329',
            con: 'aa2010ab2515ff9f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081113,
            deui: '5c86c1fffe1faf5a',
            locY: 35.193305,
            idx: '4rwGBQalQlO8H9MM1BQtug',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '60',
            lastUpdateDtm: '20250211111830283',
            con: 'aa2000a12414005f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081046,
            deui: '5c86c1fffe1fafdb',
            locY: 35.193452,
            idx: 'bCTRhbvnAk~bee6CMvM6mb',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '68',
            lastUpdateDtm: '20250211133723361',
            con: 'aa2010ab261302747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081261,
            deui: '5c86c1fffe1fb129',
            locY: 35.192885,
            idx: '43yHX5qv4dVb0w2NOOdE8E',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '66',
            lastUpdateDtm: '20250211062214136',
            con: 'aa2010ad2613fa9b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081226,
            deui: 'd02544fffef6d3c3',
            locY: 35.192983,
            idx: 'cJDIQpxEQmtazOG4zQ~v6e',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '64',
            lastUpdateDtm: '20250209124333740',
            con: 'aa2000a4241201617e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081188,
            deui: 'd02544fffef6de1b',
            locY: 35.193088,
            idx: '1EW0ViuwQOC9qM9u8z85rO',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '65',
            lastUpdateDtm: '20250211071129604',
            con: 'aa2010b32117fa8a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081212,
            deui: 'd02544fffef6de34',
            locY: 35.193038,
            idx: '21O70WqEAkxbWIWqWNlUzw',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '67',
            lastUpdateDtm: '20241106175157694',
            con: 'aa2000a1251510627e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081247,
            deui: 'd02544fffef6def0',
            locY: 35.192931,
            idx: '6mMN7uRBkQp8HqWb67e9Nr',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '63',
            lastUpdateDtm: '20250211072948485',
            con: 'aa201097231af9897e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081145,
            deui: 'd02544fffef6def1',
            locY: 35.193178,
            idx: 'fxp-xXTqkYo8VSkEo3VbBE',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '62',
            lastUpdateDtm: '20250211083912282',
            con: 'aa2010ae2412fc9b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-1-000006',
            locX: 128.081124,
            deui: 'd02544fffef6def4',
            locY: 35.193266,
            idx: 'f1Rf0mVzAFKbKc1BFby7Di',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '74',
            lastUpdateDtm: '20250211112636122',
            con: 'aa20109c241d08827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e0de',
            locY: 35.19338037420822,
            idx: '9vWX1R1yA~L8L6Cq7TULno',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '71',
            lastUpdateDtm: '20250211083801324',
            con: 'aa20109b2014fc8e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e6ff',
            locY: 35.19338037420822,
            idx: '94D-fC2ekaA8LvWhnJceu3',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '76',
            lastUpdateDtm: '20250211074336792',
            con: 'aa2010991e11fb957e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e782',
            locY: 35.19338037420822,
            idx: 'd3t-TW7Rkgb82KaInWQKqN',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '75',
            lastUpdateDtm: '20250211080540833',
            con: 'aa20109d1e1efdb67e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e793',
            locY: 35.19338037420822,
            idx: 'bhshYdzRQodavGLl~AhDg-',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '70',
            lastUpdateDtm: '20250211090543311',
            con: 'aa2010991d19fc9d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e794',
            locY: 35.19338037420822,
            idx: 'aYZiYf4gAu~83gygqPYsQC',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '72',
            lastUpdateDtm: '20250211131508107',
            con: 'aa20109e231d007b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e7a0',
            locY: 35.19338037420822,
            idx: 'c2T-YEoKQcY8QQiE6jj4Xg',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '69',
            lastUpdateDtm: '20250211134254964',
            con: 'aa20109b231cffa37e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e7a3',
            locY: 35.19338037420822,
            idx: 'byU4n7544REbqTHGBcslEo',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '73',
            lastUpdateDtm: '20250211132353664',
            con: 'aa2010a01b1effa97e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0004',
            locX: 128.08218537643802,
            deui: '5c86c1fffe21e7a4',
            locY: 35.19338037420822,
            idx: 'eP6Cd25AkeDaKb1doTRLG~',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '89',
            lastUpdateDtm: '20250211060531449',
            con: 'aa2010a22b1ffaa87e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e143',
            locY: 35.19348167563457,
            idx: '93pVxZNNQHPaOhX6pJVc6r',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '79',
            lastUpdateDtm: '20250211134016658',
            con: 'aa20109e281902777e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e1ff',
            locY: 35.19348167563457,
            idx: 'cYC3HlwGAW2bB8nAfrD9JO',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '82',
            lastUpdateDtm: '20250211081451983',
            con: 'aa2010972012f7757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e54a',
            locY: 35.19348167563457,
            idx: 'bdhQwfnlQhBbatZHu9Jl1a',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '87',
            lastUpdateDtm: '20250211083048785',
            con: 'aa2010a52815fa907e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e5a6',
            locY: 35.19348167563457,
            idx: '3pa8wxVvks-8DU8UNZFyTN',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '77',
            lastUpdateDtm: '20250211095149316',
            con: 'aa2010a02416fc8a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e6c8',
            locY: 35.19348167563457,
            idx: '7JQ5eay~QpBaoAGjymxUAv',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '78',
            lastUpdateDtm: '20250211132736573',
            con: 'aa20109f2317ff9b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e6cf',
            locY: 35.19348167563457,
            idx: 'blDhdpqLkuG9UeWHG-clK4',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '86',
            lastUpdateDtm: '20250211081712185',
            con: 'aa201099221bfa937e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e6d5',
            locY: 35.19348167563457,
            idx: 'z206BxM4fO8bk~~JCbuP7',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '81',
            lastUpdateDtm: '20250211134901366',
            con: 'aa20108e0f16fea87e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e6d9',
            locY: 35.19348167563457,
            idx: '6qMk3e1mA-X9yv8XUvxYcR',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '84',
            lastUpdateDtm: '20250211083721226',
            con: 'aa2010921e18f98c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e789',
            locY: 35.19348167563457,
            idx: '2FFP4e9rQlT9DOzfKZqCJ4',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '83',
            lastUpdateDtm: '20250211100154721',
            con: 'aa20109b2617fa957e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e795',
            locY: 35.19348167563457,
            idx: 'eVjUDuI4ALd9oiXxNJoIGD',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '85',
            lastUpdateDtm: '20250211074517263',
            con: 'aa2010982314f87c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e7ed',
            locY: 35.19348167563457,
            idx: '2vgXUuiNkelaXSd~JHZaCk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '88',
            lastUpdateDtm: '20250211134024561',
            con: 'aa2010982116005e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e81e',
            locY: 35.19348167563457,
            idx: 'fWFtMAg641zb-l4qDca9WG',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '80',
            lastUpdateDtm: '20250211134331773',
            con: 'aa200097211bfe937e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0005',
            locX: 128.08152307617428,
            deui: '5c86c1fffe21e82d',
            locY: 35.19348167563457,
            idx: '1Yw8NgPN4ljbdO~GQmdP~Q',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-8',
        usedParkingSpace: 26,
        parkingName: '경찰서인근',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 46,
        sensorList: [
          {
            parkingLocation: '71',
            lastUpdateDtm: '20250211134906651',
            con: 'aa20109a211502687e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000004',
            locX: 128.083128,
            deui: '5c86c1fffe1fb0ce',
            locY: 35.192926,
            idx: '5zzY7X6vQ9r9HpPlef5nms',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '70',
            lastUpdateDtm: '20250211094255952',
            con: 'aa20109a2414fc917e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000004',
            locX: 128.083107,
            deui: '5c86c1fffe1fb0d6',
            locY: 35.192987,
            idx: '4FXoaR1MQki8R5Y3LRN~t4',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '72',
            lastUpdateDtm: '20250211131407098',
            con: 'aa2010a12c1a017d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000004',
            locX: 128.08316,
            deui: '5c86c1fffe1fb148',
            locY: 35.192849,
            idx: 'dlYlKhnBAf7bINBXZs9gXQ',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '69',
            lastUpdateDtm: '20250211090331541',
            con: 'aa20108d0a18faa07e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000004',
            locX: 128.08309,
            deui: '5c86c1fffe1fb14b',
            locY: 35.193038,
            idx: 'a~RMoNEe41Xb1ObMkJOKTx',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '165',
            lastUpdateDtm: '20250211130943591',
            con: 'aa2010a2261202637e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08271545900533,
            deui: '5c86c1fffe21e11f',
            locY: 35.19399911078955,
            idx: 'bDmdnntV4UG9n5Ob2Uo6m8',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '160',
            lastUpdateDtm: '20250211134804952',
            con: 'aa20008b0a1503767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e170',
            locY: 35.19360285347928,
            idx: '1~RLwPfTQsk96lyrtlRW7m',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '158',
            lastUpdateDtm: '20250211055252798',
            con: 'aa2010961c14fa927e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e2ac',
            locY: 35.19360285347928,
            idx: '5v7bHfTYAov86oKzfgKEnj',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '159',
            lastUpdateDtm: '20250211105608776',
            con: 'aa201088fb1affb97e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e37c',
            locY: 35.19360285347928,
            idx: 'eka7CB8-4Mg9EUwd1Di1QL',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '162',
            lastUpdateDtm: '20250211134855493',
            con: 'aa200094211c02687e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e523',
            locY: 35.19360285347928,
            idx: '2o1uOLA6Q4G8E9Dn1PV-kV',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '161',
            lastUpdateDtm: '20250211124047768',
            con: 'aa201085f017016f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e52c',
            locY: 35.19360285347928,
            idx: '5BiKOv-rkK69UUW6UQcl0m',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '164',
            lastUpdateDtm: '20250211114718703',
            con: 'aa20109c241406707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08271545900533,
            deui: '5c86c1fffe21e560',
            locY: 35.19399911078955,
            idx: '9BijxcWXkwk94wFniwuVVN',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '166',
            lastUpdateDtm: '20250211084327057',
            con: 'aa2010a22218fb8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08271545900533,
            deui: '5c86c1fffe21e75b',
            locY: 35.19399911078955,
            idx: 'chpZF-0O4Iyap-B11HTWrp',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '163',
            lastUpdateDtm: '20250211132804495',
            con: 'aa20109b1b1402787e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0010',
            locX: 128.08285903039808,
            deui: '5c86c1fffe21e82c',
            locY: 35.19360285347928,
            idx: '2om8x6WhAIPbaQHPPq-BvK',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '173',
            lastUpdateDtm: '20250211133202311',
            con: 'aa20109b241902707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e55c',
            locY: 35.19487590655061,
            idx: '5-vONZ7vk6MaBr~IeotA9x',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '176',
            lastUpdateDtm: '20250211131157141',
            con: 'aa20109c231b01787e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e6eb',
            locY: 35.19487590655061,
            idx: '8bZyUkGF4luaEQF5snb2M8',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '175',
            lastUpdateDtm: '20250211114224608',
            con: 'aa2010961e12016c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e708',
            locY: 35.19487590655061,
            idx: 'dos5JBsU4n7bItfRBxzL85',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '169',
            lastUpdateDtm: '20250211133750706',
            con: 'aa201091fe1a018b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.0825852282399,
            deui: '5c86c1fffe21e710',
            locY: 35.19437339539168,
            idx: '3~~rnAyNQCgbSKEF2NUSPH',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '167',
            lastUpdateDtm: '20250211133155951',
            con: 'aa2010a02315005f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.0825852282399,
            deui: '5c86c1fffe21e760',
            locY: 35.19437339539168,
            idx: '8yH2tUaTknE8kyWeKHDcqS',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '172',
            lastUpdateDtm: '20250211133358274',
            con: 'aa201090141105587e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e763',
            locY: 35.19487590655061,
            idx: 'bo0T8wZt4YLbbT82iJeVMr',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '174',
            lastUpdateDtm: '20250211114837244',
            con: 'aa2010921b1a04777e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e779',
            locY: 35.19487590655061,
            idx: '637nyICU4eK9uAC4Q~A8~5',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '170',
            lastUpdateDtm: '20250211114602362',
            con: 'aa201092f61105717e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.0825852282399,
            deui: '5c86c1fffe21e785',
            locY: 35.19437339539168,
            idx: '35aC6txpQNm9B0YhRkVsKa',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '177',
            lastUpdateDtm: '20250211095101111',
            con: 'aa20109b2317fd957e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.08240644122407,
            deui: '5c86c1fffe21e78f',
            locY: 35.19487590655061,
            idx: '6DCbHsj7QpR9z9EG7J7Sqm',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '171',
            lastUpdateDtm: '20250211131932508',
            con: 'aa20109d1d18007e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.0825852282399,
            deui: '5c86c1fffe21e79f',
            locY: 35.19437339539168,
            idx: 'fIbd9xMm4y4aazIhn26v1Y',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '168',
            lastUpdateDtm: '20250211132257106',
            con: 'aa201098201702607e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0011',
            locX: 128.0825852282399,
            deui: '5c86c1fffe21e7d9',
            locY: 35.19437339539168,
            idx: '1NaxDIUqQAH81DQukAHx6L',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '194',
            lastUpdateDtm: '20250211022722576',
            con: 'aa2010981d1efca87e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e0a1',
            locY: 35.19566637664842,
            idx: 'qyiqfrUAZB9mZDv9PycpS',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '190',
            lastUpdateDtm: '20250211132507968',
            con: 'aa20108f121100667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e0a9',
            locY: 35.19566637664842,
            idx: '2bk2S6VX4ngbwg1jQF6Qux',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '187',
            lastUpdateDtm: '20250211101240473',
            con: 'aa2010982215fe897e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08201049204098,
            deui: '5c86c1fffe21e11a',
            locY: 35.19606483816601,
            idx: '7wbGKMe0AFX990a9emnG2t',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '189',
            lastUpdateDtm: '20250211133429584',
            con: 'aa201087f61400737e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e352',
            locY: 35.19566637664842,
            idx: '5Y2F~TJVkT49pHRKCt71K-',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '197',
            lastUpdateDtm: '20250211132935116',
            con: 'aa20109e2415026f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08307029289477,
            deui: '5c86c1fffe21e38f',
            locY: 35.19582521705047,
            idx: '8p5LoYSy4b6bV4UdRY002m',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '186',
            lastUpdateDtm: '20250211130642519',
            con: 'aa201097201e016c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08201049204098,
            deui: '5c86c1fffe21e393',
            locY: 35.19606483816601,
            idx: '6mBTiBOfQygaPOC4~463cK',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '198',
            lastUpdateDtm: '20250211130114585',
            con: 'aa20108e111e037b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08307029289477,
            deui: '5c86c1fffe21e3ca',
            locY: 35.19582521705047,
            idx: '7~UnxFWo4Tf8Ap52fGbbGK',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '184',
            lastUpdateDtm: '20250211092745074',
            con: 'aa2010982610fe887e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08212658975265,
            deui: '5c86c1fffe21e434',
            locY: 35.19571557518968,
            idx: 'cWTKa0rtAh39iFr4CJRJ1q',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '185',
            lastUpdateDtm: '20250211130122625',
            con: 'aa2010941c1705717e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08201049204098,
            deui: '5c86c1fffe21e45c',
            locY: 35.19606483816601,
            idx: '8kgEfoa~AjL8UFJ5f0T2rp',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '182',
            lastUpdateDtm: '20250211133539799',
            con: 'aa20108a0614ff937e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08212658975265,
            deui: '5c86c1fffe21e535',
            locY: 35.19571557518968,
            idx: '1Ko5P3Fz4x58Vj-d~0qp4C',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '195',
            lastUpdateDtm: '20250211133951925',
            con: 'aa2010982210025b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08307029289477,
            deui: '5c86c1fffe21e582',
            locY: 35.19582521705047,
            idx: '7ElV~7gx4u2a1laCRj7sng',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '193',
            lastUpdateDtm: '20250211125355538',
            con: 'aa20109a1f14027b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e587',
            locY: 35.19566637664842,
            idx: 'fnCdTOqikplbJpX--mB09p',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '180',
            lastUpdateDtm: '20250211131340732',
            con: 'aa20008a071e01797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08224848999066,
            deui: '5c86c1fffe21e58f',
            locY: 35.195359172601016,
            idx: '4Vq3yN2UA359pCppGAPTSL',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '192',
            lastUpdateDtm: '20250211133158282',
            con: 'aa20008d1218036d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e6c4',
            locY: 35.19566637664842,
            idx: '2dxhKwtEAsFaArXqRhu2EN',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '181',
            lastUpdateDtm: '20250211131601508',
            con: 'aa200090171913617e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08224848999066,
            deui: '5c86c1fffe21e756',
            locY: 35.195359172601016,
            idx: '6zDRdtPQk3M9nuQNZzjR5I',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '178',
            lastUpdateDtm: '20250122190213946',
            con: 'aa2000a9021b05767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08224848999066,
            deui: '5c86c1fffe21e77c',
            locY: 35.195359172601016,
            idx: 'b-2lHBUf4tc8YWpJORgJcr',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '196',
            lastUpdateDtm: '20250211124325870',
            con: 'aa20108d0f1d018b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08307029289477,
            deui: '5c86c1fffe21e781',
            locY: 35.19582521705047,
            idx: 'ajzYvT9eAsQav2i9sIuW-7',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '179',
            lastUpdateDtm: '20250211132131719',
            con: 'aa201098261c02727e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08224848999066,
            deui: '5c86c1fffe21e786',
            locY: 35.195359172601016,
            idx: '8KyDdh0HAunaO6oH8TDLqJ',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '183',
            lastUpdateDtm: '20250211133132009',
            con: 'aa2010911a1401657e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08212658975265,
            deui: '5c86c1fffe21e79e',
            locY: 35.19571557518968,
            idx: 'cmXxmoRGA7u934KnMqV~hE',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '188',
            lastUpdateDtm: '20250211134916777',
            con: 'aa20008b0c19017a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08201049204098,
            deui: '5c86c1fffe21e7b8',
            locY: 35.19606483816601,
            idx: '9n3V1RjIAOp9KByEfJzlix',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '199',
            lastUpdateDtm: '20250211134354525',
            con: 'aa2010911a19026b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.08307029289477,
            deui: '5c86c1fffe21e805',
            locY: 35.19582521705047,
            idx: '5kyIH5k6AlvbmGPZS4lYvZ',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '191',
            lastUpdateDtm: '20250211131124825',
            con: 'aa2010921c1c02787e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0012',
            locX: 128.0825170528919,
            deui: '5c86c1fffe21e80b',
            locY: 35.19566637664842,
            idx: '6Ahw808bQQraQcT1P5NRx5',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-9',
        usedParkingSpace: 39,
        parkingName: '차없는거리',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 35,
        sensorList: [
          {
            parkingLocation: '202',
            lastUpdateDtm: '20250211125740509',
            con: 'aa201098241a07737e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08642005790125,
            deui: '5c86c1fffe21e0ca',
            locY: 35.19769771183799,
            idx: '7vwCs~bgAHm8DtFD9jE6mR',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '204',
            lastUpdateDtm: '20250211120047626',
            con: 'aa2010961b1c127c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e11e',
            locY: 35.19713839125892,
            idx: 'axY4kJcbkb98xdu1wfXxdc',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '203',
            lastUpdateDtm: '20250211134653574',
            con: 'aa2000911c19016b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08642005790125,
            deui: '5c86c1fffe21e136',
            locY: 35.19769771183799,
            idx: '5BIokvXZ4YZbJ7oS5L0yWi',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '210',
            lastUpdateDtm: '20250210085007014',
            con: 'aa20109d1c17ffa87e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e246',
            locY: 35.19713839125892,
            idx: '55ILBQRBAHz8iCCuyNURzU',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '205',
            lastUpdateDtm: '20250211130705587',
            con: 'aa20109a2113086c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e2ad',
            locY: 35.19713839125892,
            idx: 'cFgYl08yAvM9CTamo2aMLu',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '233',
            lastUpdateDtm: '20250211085921664',
            con: 'aa2010911a11fd8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.0867279824098,
            deui: '5c86c1fffe21e2da',
            locY: 35.1974358395165,
            idx: '5~~oylO-kA59du3A2IruXL',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '212',
            lastUpdateDtm: '20250211123402271',
            con: 'aa201096201c0a797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e31d',
            locY: 35.19713839125892,
            idx: '3w2RGrWN43M97J9uzpLp6d',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '211',
            lastUpdateDtm: '20250211114322076',
            con: 'aa20109a26120a797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e32a',
            locY: 35.19713839125892,
            idx: '8wSdLsDK490asyI7HKZmYI',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '206',
            lastUpdateDtm: '20250211133811866',
            con: 'aa2010a0231007617e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e336',
            locY: 35.19713839125892,
            idx: 'b8-UlMsFkDzbHZGNkijMNb',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '208',
            lastUpdateDtm: '20250210151145782',
            con: 'aa20108f141dffa77e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e38b',
            locY: 35.19713839125892,
            idx: '3x~XQ5HiAEl8ouHwBfvWH7',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '207',
            lastUpdateDtm: '20250211123422469',
            con: 'aa201097231607667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e3c6',
            locY: 35.19713839125892,
            idx: '3oLivIVEk-XadHeQyfozyY',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '209',
            lastUpdateDtm: '20250211132708571',
            con: 'aa201090171e036d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e3d2',
            locY: 35.19713839125892,
            idx: '5n6gZGmukc78V9~42z~kph',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '213',
            lastUpdateDtm: '20250211115322307',
            con: 'aa20109f1a1805827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08668935354547,
            deui: '5c86c1fffe21e3fa',
            locY: 35.19713839125892,
            idx: 'es59qYOCQhqa5xCCD4VPlT',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '234',
            lastUpdateDtm: '20250210140517896',
            con: 'aa20108a071f017b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.0867279824098,
            deui: '5c86c1fffe21e415',
            locY: 35.1974358395165,
            idx: '78sS1WGMQho8n2~2IhFdjs',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '232',
            lastUpdateDtm: '20250131140455585',
            con: 'aa20109f2211036b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.0867279824098,
            deui: '5c86c1fffe21e49e',
            locY: 35.1974358395165,
            idx: '2YMjAHh2kFG8scx7fMmrAf',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '231',
            lastUpdateDtm: '20250211134915933',
            con: 'aa200098201906657e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.0867279824098,
            deui: '5c86c1fffe21e572',
            locY: 35.1974358395165,
            idx: '6NrVRir4kfvbV7hMqjDSj~',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '214',
            lastUpdateDtm: '20250211122752673',
            con: 'aa2010981b1701707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08689132373638,
            deui: '5c86c1fffe21e6d3',
            locY: 35.19666158926411,
            idx: '4a7Wer7VkocbVJkKKKlwy7',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '215',
            lastUpdateDtm: '20250211091114287',
            con: 'aa2010911818fe8a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08689132373638,
            deui: '5c86c1fffe21e784',
            locY: 35.19666158926411,
            idx: 'dS6ENGwwkH69wcpnT-HuXy',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '201',
            lastUpdateDtm: '20250211120951199',
            con: 'aa201098251a106e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08642005790125,
            deui: '5c86c1fffe21e7bb',
            locY: 35.19769771183799,
            idx: '24oeyOfcAkeaqOoWvCIx~U',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '200',
            lastUpdateDtm: '20250211134125119',
            con: 'aa20008c0d18017b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0014',
            locX: 128.08642005790125,
            deui: '5c86c1fffe21e7bd',
            locY: 35.19769771183799,
            idx: 'aHfnJ11HAVaa8SmP~QQsdI',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '216',
            lastUpdateDtm: '20250211044717025',
            con: 'aa20109f2119fb977e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08709654886496,
            deui: '5c86c1fffe2161c5',
            locY: 35.19616933669572,
            idx: 'fFchaeJ4Q0-bk0mzasPR70',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '219',
            lastUpdateDtm: '20250211133520591',
            con: 'aa20109b241902707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08709654886496,
            deui: '5c86c1fffe21e205',
            locY: 35.19616933669572,
            idx: '43clF~aAAOR9bKWcfCERqM',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '217',
            lastUpdateDtm: '20250211133826644',
            con: 'aa20109d1c1e048e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08709654886496,
            deui: '5c86c1fffe21e28e',
            locY: 35.19616933669572,
            idx: 'aHFg-nKtkRY8Uxx-7UOzDr',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '226',
            lastUpdateDtm: '20250211103329107',
            con: 'aa20109c2414ff967e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08720486011083,
            deui: '5c86c1fffe21e319',
            locY: 35.19640429933864,
            idx: '6NrVRir4kfvbV7hMqjDSj~',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '218',
            lastUpdateDtm: '20250211114617822',
            con: 'aa201097221702617e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08709654886496,
            deui: '5c86c1fffe21e33e',
            locY: 35.19616933669572,
            idx: '49~BHF4jQ9s865xq7uRHnh',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '230',
            lastUpdateDtm: '20250211110136647',
            con: 'aa201093191b006c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08720486011083,
            deui: '5c86c1fffe21e460',
            locY: 35.19640429933864,
            idx: '5Fy8Yk9uAvGbxUrUeJYubF',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '223',
            lastUpdateDtm: '20250211113654474',
            con: 'aa20009f191400707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0876200242265,
            deui: '5c86c1fffe21e56c',
            locY: 35.195606565952076,
            idx: '3xN~aAPXkeEbKNMfd~Xxnk',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '229',
            lastUpdateDtm: '20250211132438335',
            con: 'aa20109c261908797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08720486011083,
            deui: '5c86c1fffe21e57a',
            locY: 35.19640429933864,
            idx: '78sS1WGMQho8n2~2IhFdjs',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '224',
            lastUpdateDtm: '20250211114158593',
            con: 'aa20009a2119066f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0876200242265,
            deui: '5c86c1fffe21e595',
            locY: 35.195606565952076,
            idx: '8~UNQ5~xk2-9tYMCYEHgU8',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '221',
            lastUpdateDtm: '20250211134908400',
            con: 'aa20009a1811056c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0874012210009,
            deui: '5c86c1fffe21e596',
            locY: 35.19565699800444,
            idx: '8YXO8L7yk6J9vRx4zVGYoa',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '227',
            lastUpdateDtm: '20250211134918728',
            con: 'aa20109e1f1f05947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08720486011083,
            deui: '5c86c1fffe21e6c7',
            locY: 35.19640429933864,
            idx: '2YMjAHh2kFG8scx7fMmrAf',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '222',
            lastUpdateDtm: '20250211130704098',
            con: 'aa2010921a170e7d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0876200242265,
            deui: '5c86c1fffe21e6d0',
            locY: 35.195606565952076,
            idx: 'bEg62g-RkxfaYSmLC~2z-u',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '228',
            lastUpdateDtm: '20250211131150643',
            con: 'aa20109d221605707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.08720486011083,
            deui: '5c86c1fffe21e6d2',
            locY: 35.19640429933864,
            idx: '5~~oylO-kA59du3A2IruXL',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '220',
            lastUpdateDtm: '20250211132533432',
            con: 'aa2010a11f1e03857e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0874012210009,
            deui: '5c86c1fffe21e725',
            locY: 35.19565699800444,
            idx: '7kGVr0lVQMi91XObhntxZc',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '225',
            lastUpdateDtm: '20250211073656793',
            con: 'aa2000921b16fd917e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0015',
            locX: 128.0876200242265,
            deui: '5c86c1fffe21e780',
            locY: 35.195606565952076,
            idx: '5Fy8Yk9uAvGbxUrUeJYubF',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-1',
        usedParkingSpace: 28,
        parkingName: '수정노상',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 28,
        sensorList: [
          {
            parkingLocation: '7',
            lastUpdateDtm: '20250211130250022',
            con: 'aa2000921813055f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: '5c86c1fffe1faf82',
            locY: 35.19591821069115,
            idx: '4LwbT-bZkdfb9TF-inhwbt',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '28',
            lastUpdateDtm: '20250211132402256',
            con: 'aa1510962114015f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0d1',
            locY: 35.19537752003963,
            idx: '6~SqfUDzQsZa5RZFFWzDbn',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '27',
            lastUpdateDtm: '20250211132358295',
            con: 'aa1510992314fe8e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0d4',
            locY: 35.19537752003963,
            idx: '760S5BuPkOxbTkfNKHvzNz',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '26',
            lastUpdateDtm: '20250211110525998',
            con: 'aa15108c071afda57e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0e9',
            locY: 35.19537752003963,
            idx: 'a9VCUhDcA2zayEhkbnhnn0',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '25',
            lastUpdateDtm: '20250211131314433',
            con: 'aa1510911e1900717e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0f2',
            locY: 35.19537752003963,
            idx: '3HAZ~PK8QuP8971PMyoleA',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '24',
            lastUpdateDtm: '20250211132949049',
            con: 'aa151084eb1cffba7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0f3',
            locY: 35.19537752003963,
            idx: 'fZBgGsXjQMP81KLOSJGnxq',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '23',
            lastUpdateDtm: '20250211124138438',
            con: 'aa15008afe17febd7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb0fb',
            locY: 35.19537752003963,
            idx: 'cODdAixLAJybPr51-Xydw5',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '22',
            lastUpdateDtm: '20250211125504950',
            con: 'aa15108b0418ff9a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb12b',
            locY: 35.19537752003963,
            idx: 'eF8yfOiv4m5anNbUr-jCNg',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '6',
            lastUpdateDtm: '20250211105215801',
            con: 'aa2010911b160c7a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: '5c86c1fffe1fb137',
            locY: 35.19591821069115,
            idx: 'dP6D9gUr4zzaP~WTRs6g-u',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '21',
            lastUpdateDtm: '20250211114827835',
            con: 'aa15109a2311ff947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07649133323173,
            deui: '5c86c1fffe1fb14c',
            locY: 35.19537752003963,
            idx: 'eYIUH4hoAzf9bqf8mIrAOk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '20',
            lastUpdateDtm: '20250211130639520',
            con: 'aa15109e291b11857e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb14e',
            locY: 35.195507751235155,
            idx: '8MvduULGA4U8FEOFAgxyy9',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '19',
            lastUpdateDtm: '20250211120325893',
            con: 'aa150084f01705757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb189',
            locY: 35.195507751235155,
            idx: 'eCyfKYZqkic8NMXsJd3DdM',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '18',
            lastUpdateDtm: '20250211131226261',
            con: 'aa1510921e110a7b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb19f',
            locY: 35.195507751235155,
            idx: 'dtKrQKvDQs6aMZKLOGIqqT',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '17',
            lastUpdateDtm: '20250211114029158',
            con: 'aa15109e201e017e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb1b3',
            locY: 35.195507751235155,
            idx: '5pFE~v8mAS19omAdQSawLh',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '16',
            lastUpdateDtm: '20250211134836663',
            con: 'aa150091ff19048a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb1c3',
            locY: 35.195507751235155,
            idx: '7BUcS2nXQWTbBH8qctkROQ',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '15',
            lastUpdateDtm: '20250211134436747',
            con: 'aa15108e121606747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb1c5',
            locY: 35.195507751235155,
            idx: 'xuk3B1XQDu9nlfX~WNKOR',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '5',
            lastUpdateDtm: '20250211122330840',
            con: 'aa2010a2241606697e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.0763277000376,
            deui: '5c86c1fffe1fb1d9',
            locY: 35.19577402686946,
            idx: 'aFXYP59xAhK8j1NHuLMYXd',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '14',
            lastUpdateDtm: '20250211132314918',
            con: 'aa15107fd61a10917e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07698080991327,
            deui: '5c86c1fffe1fb1dd',
            locY: 35.195507751235155,
            idx: '3ZQFIbGUQIB8tD1zksYt2u',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '13',
            lastUpdateDtm: '20250211101015912',
            con: 'aa150098221d04747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: '5c86c1fffe1fb295',
            locY: 35.19591821069115,
            idx: '6JMMUPih4LU9wjqETVE6y4',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '12',
            lastUpdateDtm: '20250211134404104',
            con: 'aa150086f1100a7d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: '5c86c1fffe1fb2b3',
            locY: 35.19591821069115,
            idx: 'dl6S4xcrATXaixtuf0NkYc',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '11',
            lastUpdateDtm: '20250211132434101',
            con: 'aa15108e13160f857e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: '5c86c1fffe1fb2d7',
            locY: 35.19591821069115,
            idx: 'TQbE4AmAIvbGr4sDCd3DP',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '4',
            lastUpdateDtm: '20250211122611089',
            con: 'aa20109826110e737e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.0763277000376,
            deui: '5c86c1fffe202c98',
            locY: 35.19577402686946,
            idx: '8Re8zzBckkib36lovzD4QC',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '3',
            lastUpdateDtm: '20250211095412173',
            con: 'aa2010982012fe847e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.0763277000376,
            deui: 'd02544fffef6cd30',
            locY: 35.19577402686946,
            idx: '3i4aDbTdkCV90jTJxu6mHD',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '2',
            lastUpdateDtm: '20241128055649622',
            con: 'aa2011911f17016e7e',
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.0763277000376,
            deui: 'd02544fffef6cf57',
            locY: 35.19577402686946,
            idx: '5aMrbcRXA-a9uTjIREt6G~',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '10',
            lastUpdateDtm: '20250211134541795',
            con: 'aa15109e251d05867e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: 'd02544fffef6d27d',
            locY: 35.19591821069115,
            idx: 'dxVz8oRRk8BbS0nKvrvY7x',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '9',
            lastUpdateDtm: '20250211133249977',
            con: 'aa15109e251208777e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: 'd02544fffef6dd5b',
            locY: 35.19591821069115,
            idx: 'ekd4nXxD4I-8090FjIvWZX',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '8',
            lastUpdateDtm: '20250211133853409',
            con: 'aa151093181209687e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.07679725615787,
            deui: 'd02544fffef6dd5f',
            locY: 35.19591821069115,
            idx: 'dTw~eWVtAZ78m7OmlcYIcX',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '1',
            lastUpdateDtm: '20250211115112406',
            con: 'aa20008c1219ff967e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0001',
            locX: 128.0763277000376,
            deui: 'd02544fffef6df89',
            locY: 35.19577402686946,
            idx: '1z9o9iHqkNHbJU05f9~2-R',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-6',
        usedParkingSpace: 21,
        parkingName: '봉곡광장',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 40,
        sensorList: [
          {
            parkingLocation: '50',
            lastUpdateDtm: '20250211110947254',
            con: 'aa1510a32b1dffb07e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe1faf01',
            locY: 35.19849082417724,
            idx: 'cMDNoi4pANk9uYupRWRTXD',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '49',
            lastUpdateDtm: '20250211101207963',
            con: 'aa1510b12815ff967e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf06',
            locY: 35.1982939569948,
            idx: 'd1Y594HlkkT99jMYxhJxt-',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '48',
            lastUpdateDtm: '20250211090144712',
            con: 'aa1510ad2216fb9f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf15',
            locY: 35.1982939569948,
            idx: '3vCwYTqIAkI8nPgZpkZEFv',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '47',
            lastUpdateDtm: '20250211085858829',
            con: 'aa15107cc515fba77e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf1c',
            locY: 35.1982939569948,
            idx: 'any4lpKjkuK9oHnMb~CA9V',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '46',
            lastUpdateDtm: '20250211134437650',
            con: 'aa15109c201d0c8d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf27',
            locY: 35.1982939569948,
            idx: '78I4SF~EkDYa8dLluX5hKB',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '45',
            lastUpdateDtm: '20250211080834864',
            con: 'aa1510ae1f1ff8b97e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf2d',
            locY: 35.1982939569948,
            idx: 'fzD8NyK7QX-be~qUQidGyh',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '44',
            lastUpdateDtm: '20250211091051375',
            con: 'aa151079c51cfcac7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf30',
            locY: 35.1982939569948,
            idx: 'bb-bTD1U4q4aC~LIcoy5pG',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '43',
            lastUpdateDtm: '20250211084934461',
            con: 'aa1510b0251ffa9e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf32',
            locY: 35.1982939569948,
            idx: 'c1mfYr7CkWTbzrEe~uuCQE',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '42',
            lastUpdateDtm: '20250211131655563',
            con: 'aa1500a72b1fffb57e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf3b',
            locY: 35.1982939569948,
            idx: 'b68H-54KAm~8N2pUTF52bU',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '41',
            lastUpdateDtm: '20250211095037142',
            con: 'aa1510ac2c1efcbf7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf3f',
            locY: 35.1982939569948,
            idx: 'aT1Jua~4kDebg-i5F3~USk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '40',
            lastUpdateDtm: '20250211132218680',
            con: 'aa1500921e1f02807e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08111528825208,
            deui: '5c86c1fffe1faf40',
            locY: 35.1982939569948,
            idx: '90S7h-bU4DFa5mWC525UVC',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '39',
            lastUpdateDtm: '20250211075549182',
            con: 'aa1510ac281efab27e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf70',
            locY: 35.19809673426201,
            idx: '2tL2mzJhAWM9iXsGPxFW2f',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '38',
            lastUpdateDtm: '20250211074218620',
            con: 'aa1510a7271dfca67e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf71',
            locY: 35.19809673426201,
            idx: '5Z~e81QL4ZzbN1J2shcKm5',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '37',
            lastUpdateDtm: '20250211085054377',
            con: 'aa1510a8281efca97e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf80',
            locY: 35.19809673426201,
            idx: '8uwQfgOykwzanU8h7DXqid',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '36',
            lastUpdateDtm: '20250211100537967',
            con: 'aa1510a02413fe8d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf81',
            locY: 35.19809673426201,
            idx: '9yDbj~9V49x9riea1TKUAs',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '35',
            lastUpdateDtm: '20250211095431609',
            con: 'aa151097111afe967e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf93',
            locY: 35.19809673426201,
            idx: '9wxIl1TaAUDbUEuOUys4bV',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '34',
            lastUpdateDtm: '20250211081534432',
            con: 'aa15109f231cfba77e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.080413688745,
            deui: '5c86c1fffe1faf94',
            locY: 35.19809673426201,
            idx: '2BjlOzwBQsg9B2MyWDY5Rs',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '33',
            lastUpdateDtm: '20250211083218879',
            con: 'aa1500941f18fe9e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.07978998397243,
            deui: '5c86c1fffe1faf9e',
            locY: 35.19794361464078,
            idx: '24RFIQUAkC08APgJMVovxu',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '32',
            lastUpdateDtm: '20250203080500376',
            con: 'aa15008f181801777e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.07978998397243,
            deui: '5c86c1fffe1fb0a1',
            locY: 35.19794361464078,
            idx: 'f-h3Jkgy4is8PmmhVvbSuX',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '31',
            lastUpdateDtm: '20250211125554414',
            con: 'aa15008d0d1c048e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.07978998397243,
            deui: '5c86c1fffe1fb0a4',
            locY: 35.19794361464078,
            idx: '7vHIMUGCQCMaAwYQ1JR5gE',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '30',
            lastUpdateDtm: '20250211125701084',
            con: 'aa15109418170d797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.07978998397243,
            deui: '5c86c1fffe1fb0cb',
            locY: 35.19794361464078,
            idx: 'e6jQPBH9AoXbtMn32VSbWz',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '29',
            lastUpdateDtm: '20250211133118305',
            con: 'aa1500991f19027b7e',
            batteryStatus: '0',
            needUpdate: 1,
            parkingLotIdx: 'park_0002',
            locX: 128.07978998397243,
            deui: '5c86c1fffe1fb0cf',
            locY: 35.19794361464078,
            idx: '1mrHcAU0AUF935WeGQRLPr',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '57',
            lastUpdateDtm: '20250211022054587',
            con: 'aa20108f0d13faa07e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e0fd',
            locY: 35.19849082417724,
            idx: '7jdn5rd6k4PaAdSE9Q0Lpo',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '54',
            lastUpdateDtm: '20250211093153116',
            con: 'aa201094181afc947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e117',
            locY: 35.19849082417724,
            idx: '3H2uV88I46cayvtU52ubNo',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '55',
            lastUpdateDtm: '20250211132757134',
            con: 'aa2010ae181fffb57e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e121',
            locY: 35.19849082417724,
            idx: '3R7ec8RMQEl92D1biLansx',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '53',
            lastUpdateDtm: '20250211104322221',
            con: 'aa200090181300587e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e12a',
            locY: 35.19849082417724,
            idx: '6~T~2AlGknW9SRj3hpbPaQ',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '51',
            lastUpdateDtm: '20250211121447560',
            con: 'aa20108a071200667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e55b',
            locY: 35.19849082417724,
            idx: 'fppM5jWkA2Z9X417FvwhdA',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '52',
            lastUpdateDtm: '20250210080242078',
            con: 'aa2010b41e19faa07e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e585',
            locY: 35.19849082417724,
            idx: 'cIzOVMSO4KP9AkfR4csnt0',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '56',
            lastUpdateDtm: '20250211121441259',
            con: 'aa2000a72816fe967e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0002',
            locX: 128.08189692685204,
            deui: '5c86c1fffe21e6df',
            locY: 35.19849082417724,
            idx: 'aU5tyE1c4oa9KxDg~hfvWQ',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '60',
            lastUpdateDtm: '20250211133636691',
            con: 'aa2000a9201001607e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.08236541450214,
            deui: '5c86c1fffe21e0ee',
            locY: 35.19823925603558,
            idx: '48cDNVszk19a4F2eowE31t',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '59',
            lastUpdateDtm: '20250211113706851',
            con: 'aa20108e141b0c8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.08236541450214,
            deui: '5c86c1fffe21e14a',
            locY: 35.19823925603558,
            idx: 'eT7ToxA-QkBauzRwh1fxBu',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '61',
            lastUpdateDtm: '20250211131106446',
            con: 'aa2000a9181c00797e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.08236541450214,
            deui: '5c86c1fffe21e14c',
            locY: 35.19823925603558,
            idx: 'cDOzMeZr4i18zLqHAjVabB',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '66',
            lastUpdateDtm: '20250211123551868',
            con: 'aa2010af211909807e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e52d',
            locY: 35.1982611302049,
            idx: 'H5aJJXR4tubMpxGSqu7hd',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '67',
            lastUpdateDtm: '20250211082825323',
            con: 'aa2010982010fb7f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e538',
            locY: 35.1982611302049,
            idx: 'bzIp558kAaZa~PXphvrdEV',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '62',
            lastUpdateDtm: '20250211132648311',
            con: 'aa2010972114025d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.08236541450214,
            deui: '5c86c1fffe21e55f',
            locY: 35.19823925603558,
            idx: '393sjU3x4r89p0lGT3H1No',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '64',
            lastUpdateDtm: '20250211123749641',
            con: 'aa2010991e140d847e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e56a',
            locY: 35.1982611302049,
            idx: '5MamkTKN4KW8U01MiAQWa7',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '63',
            lastUpdateDtm: '20250211134700324',
            con: 'aa2000961a160a7b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e56b',
            locY: 35.1982611302049,
            idx: '9i1NM0SKAsfaTWLu8h3t5X',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '68',
            lastUpdateDtm: '20250211130408591',
            con: 'aa20108c0b150e8b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e76c',
            locY: 35.1982611302049,
            idx: '9tbVKcTS4AJ8XaydQcSvXi',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '58',
            lastUpdateDtm: '20250211132900978',
            con: 'aa20108b0518016c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.08236541450214,
            deui: '5c86c1fffe21e7c3',
            locY: 35.19823925603558,
            idx: 'fmXVxh-sQLRbqpnzJFsdNA',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '65',
            lastUpdateDtm: '20250211134504037',
            con: 'aa201087f91809837e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0003',
            locX: 128.0824912261935,
            deui: '5c86c1fffe21e808',
            locY: 35.1982611302049,
            idx: '4F0rZ6i-QF3alkEtwFcedn',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-7',
        usedParkingSpace: 29,
        parkingName: '진주중노상',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 82,
        sensorList: [
          {
            parkingLocation: '1',
            lastUpdateDtm: '20250206083136314',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0001',
            locY: 35.190779,
            idx: '0001',
            isDisSpace: 'true',
            isUsed: '0',
          },
          {
            parkingLocation: '2',
            lastUpdateDtm: '20250131153908355',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0002',
            locY: 35.190779,
            idx: '0002',
            isDisSpace: 'true',
            isUsed: '0',
          },
          {
            parkingLocation: '3',
            lastUpdateDtm: '20250211111941504',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0003',
            locY: 35.190779,
            idx: '0003',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '4',
            lastUpdateDtm: '20250211094658690',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0004',
            locY: 35.190779,
            idx: '0004',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '5',
            lastUpdateDtm: '20241007072108075',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0005',
            locY: 35.190779,
            idx: '0005',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '6',
            lastUpdateDtm: '20241002133108886',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0006',
            locY: 35.190779,
            idx: '0006',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '7',
            lastUpdateDtm: '20250211121435527',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0007',
            locY: 35.190779,
            idx: '0007',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '8',
            lastUpdateDtm: '20250211080221033',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0008',
            locY: 35.190779,
            idx: '0008',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '9',
            lastUpdateDtm: '20250211095540703',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0009',
            locY: 35.190779,
            idx: '0009',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '10',
            lastUpdateDtm: '20250211112445845',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0010',
            locY: 35.190779,
            idx: '0010',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '11',
            lastUpdateDtm: '20250211131056000',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0011',
            locY: 35.190779,
            idx: '0011',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '12',
            lastUpdateDtm: '20250211131116475',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0012',
            locY: 35.190779,
            idx: '0012',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '13',
            lastUpdateDtm: '20250210144606091',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0013',
            locY: 35.190779,
            idx: '0013',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '14',
            lastUpdateDtm: '20250206170149184',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0014',
            locY: 35.190779,
            idx: '0014',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '15',
            lastUpdateDtm: '20250211111517540',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0015',
            locY: 35.190779,
            idx: '0015',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '16',
            lastUpdateDtm: '20250211120650916',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0016',
            locY: 35.190779,
            idx: '0016',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '17',
            lastUpdateDtm: '20240531173921152',
            con: null,
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0017',
            locY: 35.190779,
            idx: '0017',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '18',
            lastUpdateDtm: '20250211113556272',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0018',
            locY: 35.190779,
            idx: '0018',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '19',
            lastUpdateDtm: '20250209171007683',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0019',
            locY: 35.190779,
            idx: '0019',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '20',
            lastUpdateDtm: '20250211085138946',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0020',
            locY: 35.190779,
            idx: '0020',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '21',
            lastUpdateDtm: '20241002111906723',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0021',
            locY: 35.190779,
            idx: '0021',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '22',
            lastUpdateDtm: '20240812084728039',
            con: null,
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0022',
            locY: 35.190779,
            idx: '0022',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '23',
            lastUpdateDtm: '20250211132352026',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0023',
            locY: 35.190779,
            idx: '0023',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '24',
            lastUpdateDtm: '20250211133838414',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0024',
            locY: 35.190779,
            idx: '0024',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '25',
            lastUpdateDtm: '20250210193325946',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0025',
            locY: 35.190779,
            idx: '0025',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '26',
            lastUpdateDtm: '20250209160348795',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0026',
            locY: 35.190779,
            idx: '0026',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '27',
            lastUpdateDtm: '20250208162417602',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0027',
            locY: 35.190779,
            idx: '0027',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '28',
            lastUpdateDtm: '20250211104320935',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0028',
            locY: 35.190779,
            idx: '0028',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '29',
            lastUpdateDtm: '20250211112558249',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0029',
            locY: 35.190779,
            idx: '0029',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '30',
            lastUpdateDtm: '20250211132614831',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0030',
            locY: 35.190779,
            idx: '0030',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '31',
            lastUpdateDtm: '20250211131240783',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0031',
            locY: 35.190779,
            idx: '0031',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '32',
            lastUpdateDtm: '20250211102655155',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0032',
            locY: 35.190779,
            idx: '0032',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '33',
            lastUpdateDtm: '20250211124456834',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0033',
            locY: 35.190779,
            idx: '0033',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '34',
            lastUpdateDtm: '20250208160659884',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0034',
            locY: 35.190779,
            idx: '0034',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '35',
            lastUpdateDtm: '20250211124449605',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0035',
            locY: 35.190779,
            idx: '0035',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '36',
            lastUpdateDtm: '20250209143552838',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0036',
            locY: 35.190779,
            idx: '0036',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '37',
            lastUpdateDtm: '20250211090454452',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0037',
            locY: 35.190779,
            idx: '0037',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '38',
            lastUpdateDtm: '20250211121541389',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0038',
            locY: 35.190779,
            idx: '0038',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '39',
            lastUpdateDtm: '20250211131827306',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0039',
            locY: 35.190779,
            idx: '0039',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '40',
            lastUpdateDtm: '20240919193746208',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0040',
            locY: 35.190779,
            idx: '0040',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '41',
            lastUpdateDtm: '20250210084212936',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0041',
            locY: 35.190779,
            idx: '0041',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '42',
            lastUpdateDtm: '20250211103308662',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0042',
            locY: 35.190779,
            idx: '0042',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '43',
            lastUpdateDtm: '20250211083757971',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0043',
            locY: 35.190779,
            idx: '0043',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '44',
            lastUpdateDtm: '20250210220157914',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0044',
            locY: 35.190779,
            idx: '0044',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '45',
            lastUpdateDtm: '20250211124245644',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0045',
            locY: 35.190779,
            idx: '0045',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '46',
            lastUpdateDtm: '20250202201307551',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0046',
            locY: 35.190779,
            idx: '0046',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '47',
            lastUpdateDtm: '20250211123249507',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0047',
            locY: 35.190779,
            idx: '0047',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '48',
            lastUpdateDtm: '20240915021222885',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0048',
            locY: 35.190779,
            idx: '0048',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '49',
            lastUpdateDtm: '20250209181300256',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0049',
            locY: 35.190779,
            idx: '0049',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '50',
            lastUpdateDtm: '20250211131842900',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0050',
            locY: 35.190779,
            idx: '0050',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '51',
            lastUpdateDtm: '20250204131750768',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0051',
            locY: 35.190779,
            idx: '0051',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '52',
            lastUpdateDtm: '20250210132913406',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0052',
            locY: 35.190779,
            idx: '0052',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '53',
            lastUpdateDtm: '20250211110309596',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0053',
            locY: 35.190779,
            idx: '0053',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '54',
            lastUpdateDtm: '20250210132247134',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0054',
            locY: 35.190779,
            idx: '0054',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '55',
            lastUpdateDtm: '20250210155929451',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0055',
            locY: 35.190779,
            idx: '0055',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '56',
            lastUpdateDtm: '20240302144659693',
            con: null,
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0056',
            locY: 35.190779,
            idx: '0056',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '57',
            lastUpdateDtm: '20250209191048044',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0057',
            locY: 35.190779,
            idx: '0057',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '58',
            lastUpdateDtm: '20240511180621785',
            con: null,
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0058',
            locY: 35.190779,
            idx: '0058',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '59',
            lastUpdateDtm: '20250206144655328',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0059',
            locY: 35.190779,
            idx: '0059',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '60',
            lastUpdateDtm: '20250131105111070',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0060',
            locY: 35.190779,
            idx: '0060',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '61',
            lastUpdateDtm: '20250130151418622',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0061',
            locY: 35.190779,
            idx: '0061',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '62',
            lastUpdateDtm: '20250128165538854',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0062',
            locY: 35.190779,
            idx: '0062',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '63',
            lastUpdateDtm: '20241008131850433',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0063',
            locY: 35.190779,
            idx: '0063',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '64',
            lastUpdateDtm: '20250130180422511',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0064',
            locY: 35.190779,
            idx: '0064',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '65',
            lastUpdateDtm: '20250131145953844',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0065',
            locY: 35.190779,
            idx: '0065',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '66',
            lastUpdateDtm: '20250209175642295',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0066',
            locY: 35.190779,
            idx: '0066',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '67',
            lastUpdateDtm: '20250211114729690',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0067',
            locY: 35.190779,
            idx: '0067',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '68',
            lastUpdateDtm: '20241204094123090',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0068',
            locY: 35.190779,
            idx: '0068',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '69',
            lastUpdateDtm: '20250211072002020',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0069',
            locY: 35.190779,
            idx: '0069',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '70',
            lastUpdateDtm: '20240915125945977',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0070',
            locY: 35.190779,
            idx: '0070',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '71',
            lastUpdateDtm: '20250211134736645',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0071',
            locY: 35.190779,
            idx: '0071',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '72',
            lastUpdateDtm: '20250211105651607',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0072',
            locY: 35.190779,
            idx: '0072',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '73',
            lastUpdateDtm: '20250211084915460',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0073',
            locY: 35.190779,
            idx: '0073',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '74',
            lastUpdateDtm: '20250210141101805',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0074',
            locY: 35.190779,
            idx: '0074',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '75',
            lastUpdateDtm: '20250211093611442',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0075',
            locY: 35.190779,
            idx: '0075',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '76',
            lastUpdateDtm: '20250210175352643',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0076',
            locY: 35.190779,
            idx: '0076',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '77',
            lastUpdateDtm: '20250209150329726',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0077',
            locY: 35.190779,
            idx: '0077',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '78',
            lastUpdateDtm: '20250109181248441',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0078',
            locY: 35.190779,
            idx: '0078',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '79',
            lastUpdateDtm: '20250209171904451',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0079',
            locY: 35.190779,
            idx: '0079',
            isDisSpace: 'false',
            isUsed: '0',
          },
          {
            parkingLocation: '80',
            lastUpdateDtm: '20231215210931503',
            con: null,
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0080',
            locY: 35.190779,
            idx: '0080',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '81',
            lastUpdateDtm: '20250211074719208',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0081',
            locY: 35.190779,
            idx: '0081',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '84',
            lastUpdateDtm: '20240927105301717',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0084',
            locY: 35.190779,
            idx: '0084',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '85',
            lastUpdateDtm: '20250211083653579',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0085',
            locY: 35.190779,
            idx: '0085',
            isDisSpace: 'false',
            isUsed: '1',
          },
          {
            parkingLocation: '86',
            lastUpdateDtm: '20250211134124355',
            con: null,
            batteryStatus: '1',
            needUpdate: 0,
            parkingLotIdx: '381-3-000002',
            locX: 128.078576,
            deui: '0086',
            locY: 35.190779,
            idx: '0086',
            isDisSpace: 'false',
            isUsed: '0',
          },
        ],
        disParkingSpace: 2,
        idx: 'group-4',
        usedParkingSpace: 32,
        parkingName: '진주성공영',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 68,
        sensorList: [
          {
            parkingLocation: '100',
            lastUpdateDtm: '20250211055103312',
            con: 'aa2010a41b19f9947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e0a6',
            locY: 35.19385214834574,
            idx: '4Xf1NkNAkr2agjlEzMeRBa',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '92',
            lastUpdateDtm: '20250211081046596',
            con: 'aa2010972519f8827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e0c3',
            locY: 35.19385214834574,
            idx: '7OLlLnFjQhd8wRn0~fLDlm',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '93',
            lastUpdateDtm: '20250211131252336',
            con: 'aa2010931e19006f7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e129',
            locY: 35.19385214834574,
            idx: '6Nq55oOQKYbaCqqEWW6xL',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '94',
            lastUpdateDtm: '20250211080842289',
            con: 'aa20109b231ef9987e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e346',
            locY: 35.19385214834574,
            idx: 'clsONnJw4po8Q9-sXGNxaU',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '90',
            lastUpdateDtm: '20250211054833335',
            con: 'aa2010972519fc8d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e46e',
            locY: 35.19385214834574,
            idx: 'aOGFitq0Q7i9QOjX-xqt8d',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '91',
            lastUpdateDtm: '20250211064200524',
            con: 'aa2010982517fa8a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e48a',
            locY: 35.19385214834574,
            idx: 'dJYyPGo0QG99g0XBJvDYL1',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '95',
            lastUpdateDtm: '20250211134457892',
            con: 'aa2010991e1e05867e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e58c',
            locY: 35.19385214834574,
            idx: '8OKtZCV74tMa7BPwd4FpRx',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '96',
            lastUpdateDtm: '20250211123318056',
            con: 'aa20108e1410ff927e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e58e',
            locY: 35.19385214834574,
            idx: '9I2pkNZkk6s8JZeKatzXPD',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '97',
            lastUpdateDtm: '20250211110336899',
            con: 'aa20109d2513ff977e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e714',
            locY: 35.19385214834574,
            idx: '7IuE7zc7Q3m9TXMbKnNiyk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '98',
            lastUpdateDtm: '20250211081817571',
            con: 'aa20109b2017f9877e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e76d',
            locY: 35.19385214834574,
            idx: 'aCMA05VhA4-a928IlsAARw',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '99',
            lastUpdateDtm: '20250211134321523',
            con: 'aa20109f23130c7e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0006',
            locX: 128.08146640877135,
            deui: '5c86c1fffe21e7ec',
            locY: 35.19385214834574,
            idx: 'aY31l6F-QXb8rAQ3UwkyRv',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '120',
            lastUpdateDtm: '20250211133749440',
            con: 'aa200080d913026d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08037027506856,
            deui: '5c86c1fffe216ac8',
            locY: 35.195227877924495,
            idx: '1wOGH-UJ4NTa6gTOPqvYN2',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '110',
            lastUpdateDtm: '20250211122003715',
            con: 'aa2010992516076a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e0a3',
            locY: 35.194414586456034,
            idx: 'bTv-4uE-4Fo83GtNQNmxEj',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '129',
            lastUpdateDtm: '20250211112248833',
            con: 'aa20008c0d1400767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e0ad',
            locY: 35.19520182926513,
            idx: 'b~rcSfVp4wk9qSvdcrmnbn',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '122',
            lastUpdateDtm: '20250211113003811',
            con: 'aa20109f261c0c917e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0802286057509,
            deui: '5c86c1fffe21e0ce',
            locY: 35.19560123662875,
            idx: 'aIcNvi0GkaM8u7LPepBN7r',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '101',
            lastUpdateDtm: '20250211130833381',
            con: 'aa2010a31e1e05887e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e0f6',
            locY: 35.19397081502905,
            idx: '5X7PcH48QrjaiFKTXhU5Xa',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '116',
            lastUpdateDtm: '20250211134414948',
            con: 'aa2000931c1805707e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0804942350099,
            deui: '5c86c1fffe21e135',
            locY: 35.19485162293513,
            idx: '3qkQPB9CQtGaO85UeHZMD7',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '107',
            lastUpdateDtm: '20250211133937122',
            con: 'aa2010a51f14097e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e13d',
            locY: 35.19397081502905,
            idx: 'fimOAjOCQyg8zIKDk9LgNQ',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '126',
            lastUpdateDtm: '20250210222920953',
            con: 'aa2010ab2212fd977e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08011527094487,
            deui: '5c86c1fffe21e151',
            locY: 35.195948545784006,
            idx: 'agwnUkDO4oPaEBUWmbl-NY',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '102',
            lastUpdateDtm: '20250211132750332',
            con: 'aa20109a19120c7d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e152',
            locY: 35.19397081502905,
            idx: 'ai5fYrCVAt3a6FjunGfqyt',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '119',
            lastUpdateDtm: '20250211120251071',
            con: 'aa2010a025150e767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08037027506856,
            deui: '5c86c1fffe21e3d9',
            locY: 35.195227877924495,
            idx: 'zba1dSsAOEbHJ7-D8DQmX',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '121',
            lastUpdateDtm: '20250211134044001',
            con: 'aa201099231a0f827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0802286057509,
            deui: '5c86c1fffe21e3fc',
            locY: 35.19560123662875,
            idx: '7IblkE7-4JQbp3rTLK2oqy',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '105',
            lastUpdateDtm: '20250211131331299',
            con: 'aa20109e1a1a078c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e549',
            locY: 35.19397081502905,
            idx: '5Bp3XzcGAow91xegCzXcxk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '109',
            lastUpdateDtm: '20250211134430619',
            con: 'aa20109e1b1d048d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e552',
            locY: 35.194414586456034,
            idx: 'bI1UatfG4l389INijloZaJ',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '132',
            lastUpdateDtm: '20250211133934086',
            con: 'aa201094211a01667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e553',
            locY: 35.19520182926513,
            idx: '1jXRmYTfQKPbHspZOFK34Q',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '112',
            lastUpdateDtm: '20250211131722360',
            con: 'aa20109c2214126b7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e566',
            locY: 35.194414586456034,
            idx: '3zDoPfX7ke6992ikFhzQ2E',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '134',
            lastUpdateDtm: '20250211132523293',
            con: 'aa20109c2414036d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e573',
            locY: 35.19547095511828,
            idx: 'e6ATzT2bkXMa09iPas1YM1',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '124',
            lastUpdateDtm: '20250210141136980',
            con: 'aa2010a4241214667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0802286057509,
            deui: '5c86c1fffe21e580',
            locY: 35.19560123662875,
            idx: '7gxXbZzC4fE8G2ZXQM7d1M',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '125',
            lastUpdateDtm: '20250211110302312',
            con: 'aa2010a52618026c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08011527094487,
            deui: '5c86c1fffe21e584',
            locY: 35.195948545784006,
            idx: '4u1ZLNMQ4xPaLoIifHYRuF',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '103',
            lastUpdateDtm: '20250211134721078',
            con: 'aa200098271c04747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e589',
            locY: 35.19397081502905,
            idx: '7-MD6chxAhhaxb6nKXNkUy',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '118',
            lastUpdateDtm: '20250211122640305',
            con: 'aa2010931f1f13817e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08037027506856,
            deui: '5c86c1fffe21e59e',
            locY: 35.195227877924495,
            idx: 'abO6xMvpAX08q56Q~m2rA4',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '111',
            lastUpdateDtm: '20250210224948898',
            con: 'aa20109e1f1bfeb67e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e5a0',
            locY: 35.194414586456034,
            idx: '9Wnn-q-bkoN9ooTI~KUaOd',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '104',
            lastUpdateDtm: '20250211121808470',
            con: 'aa2010961a190d827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e5a3',
            locY: 35.19397081502905,
            idx: 'MNhPLTxk0ib4ow3OazCH1',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '123',
            lastUpdateDtm: '20250211123032199',
            con: 'aa2010a520150d757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0802286057509,
            deui: '5c86c1fffe21e5a4',
            locY: 35.19560123662875,
            idx: '5k3Ym6u41J90MSCdU2nVS',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '128',
            lastUpdateDtm: '20250211122810524',
            con: 'aa2000991f1603757e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e5ab',
            locY: 35.19520182926513,
            idx: 'fSf~kH-ZAl1bX9fpSpL-Dh',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '108',
            lastUpdateDtm: '20250211115327407',
            con: 'aa2010991c1d02807e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e5b7',
            locY: 35.194414586456034,
            idx: 'eG~lGpUQun8A3qfsMHRfB',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '115',
            lastUpdateDtm: '20250211134927197',
            con: 'aa20009b23180b7d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0804942350099,
            deui: '5c86c1fffe21e5b8',
            locY: 35.19485162293513,
            idx: '9gLskp1ZAVJ8snvvMeBcAD',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '114',
            lastUpdateDtm: '20250211113421284',
            con: 'aa200097231408647e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.0804942350099,
            deui: '5c86c1fffe21e716',
            locY: 35.19485162293513,
            idx: 'fDWL8kHFkFmbNeiOdHGKmG',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '136',
            lastUpdateDtm: '20250211134339853',
            con: 'aa2010901b1100617e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e71b',
            locY: 35.19547095511828,
            idx: 'biUwNj~J4HBbUjJ3ykpE~2',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '133',
            lastUpdateDtm: '20250211131643021',
            con: 'aa20109d281b017e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e752',
            locY: 35.19547095511828,
            idx: 'edR83qvJ4RobNrVNbVJKN3',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '130',
            lastUpdateDtm: '20250211124132761',
            con: 'aa20008e0d1200767e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e757',
            locY: 35.19520182926513,
            idx: '9EXKE~onA359DdAThfdrqJ',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '138',
            lastUpdateDtm: '20250211091351690',
            con: 'aa20108c0913fc947e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e75c',
            locY: 35.19547095511828,
            idx: '7lNeAwKWk0fbTIXb5AQMDD',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '131',
            lastUpdateDtm: '20250211110624619',
            con: 'aa2010932015fe827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e776',
            locY: 35.19520182926513,
            idx: 'bt0Gu7oT4ErbuIAmg8MHRM',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '137',
            lastUpdateDtm: '20250211131738149',
            con: 'aa201097201fff987e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e78c',
            locY: 35.19547095511828,
            idx: '4tg0HawE4Dmb0BqVmetEdq',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '113',
            lastUpdateDtm: '20250201153643245',
            con: 'aa2010991b1e04827e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08066777913194,
            deui: '5c86c1fffe21e799',
            locY: 35.194414586456034,
            idx: '1nPiErtuQ4b8q2xRCAsTeK',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '135',
            lastUpdateDtm: '20250211134446629',
            con: 'aa2000940f1100677e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08176590187554,
            deui: '5c86c1fffe21e79a',
            locY: 35.19547095511828,
            idx: '4zGjVqixQvH8uRfztBPWsN',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '106',
            lastUpdateDtm: '20250211134547762',
            con: 'aa20109b2312076d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08083598392142,
            deui: '5c86c1fffe21e81c',
            locY: 35.19397081502905,
            idx: '9YBj2DUykMA9m9bsxCuFuv',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '117',
            lastUpdateDtm: '20250211132528371',
            con: 'aa20008c0f16027c7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08037027506856,
            deui: '5c86c1fffe21e82b',
            locY: 35.195227877924495,
            idx: '9Y0dBTy41A9yvfoo4VMth',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '127',
            lastUpdateDtm: '20250210211354375',
            con: 'aa20109b241e007a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0007',
            locX: 128.08089090601453,
            deui: '5c86c1fffe21e82f',
            locY: 35.19520182926513,
            idx: '4k~Ivkz8AIJ8Qeburo3WBF',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '156',
            lastUpdateDtm: '20250211090822010',
            con: 'aa2000961b11fd907e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e0d3',
            locY: 35.19428230967779,
            idx: '~ECK1YYkrZ8IMYKOZf5Qp',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '151',
            lastUpdateDtm: '20250211094157532',
            con: 'aa20008d0a11ff9d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e14d',
            locY: 35.19428230967779,
            idx: 'a7diG5Kck6-9IDa7bZeSCU',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '142',
            lastUpdateDtm: '20250211134533384',
            con: 'aa2000941d1409727e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e335',
            locY: 35.19455636577351,
            idx: '9KUE-zMZAos8bLZBrWwlFD',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '152',
            lastUpdateDtm: '20250211134848662',
            con: 'aa20109e281704777e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e34d',
            locY: 35.19428230967779,
            idx: 'fYvxoP2FAyXaCP69wlUz9m',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '157',
            lastUpdateDtm: '20250211131126449',
            con: 'aa20008f111202677e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e37a',
            locY: 35.19428230967779,
            idx: '8s9U22DbAbIbUoSEzdnxMp',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '144',
            lastUpdateDtm: '20250211122208530',
            con: 'aa2010a2261205667e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e40d',
            locY: 35.19455636577351,
            idx: 'dgUBfhEdQ7P8JGo5GBzHRJ',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '155',
            lastUpdateDtm: '20250211095931909',
            con: 'aa2000a0251bfd977e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e4dd',
            locY: 35.19428230967779,
            idx: '8v7oixz7QSXa0iULwHAWDm',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '154',
            lastUpdateDtm: '20250211020027932',
            con: 'aa200086f214016e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e502',
            locY: 35.19428230967779,
            idx: '9oPP2ydrkh~9K5-Vq-67u9',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '143',
            lastUpdateDtm: '20250211012021250',
            con: 'aa20008b0015ff8e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e52b',
            locY: 35.19455636577351,
            idx: '8JOeqb25AEk8JpQAIkEty7',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '148',
            lastUpdateDtm: '20250211100326131',
            con: 'aa2010962113fd837e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e54e',
            locY: 35.19455636577351,
            idx: 'bYqnFWZikEy89ZSu21dEC4',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '145',
            lastUpdateDtm: '20250211123014435',
            con: 'aa200093211a04677e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e569',
            locY: 35.19455636577351,
            idx: '4P~SCMhBQERbsLLUtnBgIK',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '146',
            lastUpdateDtm: '20250211133818925',
            con: 'aa20009c2414046d7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e56e',
            locY: 35.19455636577351,
            idx: 'fPe4vPk0Qo-9eaGhyY9I5y',
            isDisSpace: 'FALSE',
            isUsed: '0',
          },
          {
            parkingLocation: '147',
            lastUpdateDtm: '20250211130552796',
            con: 'aa20109f1f1900837e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e593',
            locY: 35.19455636577351,
            idx: 'aIAHaSpa41qa~7hooRG0Zk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '139',
            lastUpdateDtm: '20250211132513438',
            con: 'aa201099221904677e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e70c',
            locY: 35.19455636577351,
            idx: '6oUob36YQLK962AielHfl2',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '149',
            lastUpdateDtm: '20250211071212559',
            con: 'aa20109a1b12f9927e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e726',
            locY: 35.19428230967779,
            idx: '4bztyEIX4rwaX3JJwx801t',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '150',
            lastUpdateDtm: '20250211123234086',
            con: 'aa2010911c1001637e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e764',
            locY: 35.19428230967779,
            idx: '6lzW3ZiqQdz9qnnUi258jf',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '140',
            lastUpdateDtm: '20250211134933839',
            con: 'aa2010931d1519747e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e771',
            locY: 35.19455636577351,
            idx: '6BIXS~P74nU9kFosi-FFdk',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '141',
            lastUpdateDtm: '20250211094626910',
            con: 'aa20109a1e1f048e7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.08004817124583,
            deui: '5c86c1fffe21e7a7',
            locY: 35.19455636577351,
            idx: '2~UDjhfFkFzbUrf~vqI-Za',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
          {
            parkingLocation: '153',
            lastUpdateDtm: '20250211134738057',
            con: 'aa2010941b13036a7e',
            batteryStatus: '0',
            needUpdate: 0,
            parkingLotIdx: 'park_0008',
            locX: 128.07912744933992,
            deui: '5c86c1fffe21e80a',
            locY: 35.19428230967779,
            idx: 'aaUNAE8EAqD8rJ1LMXplUw',
            isDisSpace: 'FALSE',
            isUsed: '1',
          },
        ],
        disParkingSpace: 0,
        idx: 'group-10',
        usedParkingSpace: 49,
        parkingName: '장덕병원옆',
      },
      {
        usedDisParkingSpace: 0,
        totalParkingSpace: 0,
        sensorList: [],
        disParkingSpace: 0,
        idx: 'group-5',
        usedParkingSpace: 0,
        parkingName: '중앙 공영주차타워(설치예정)',
      },
    ],
    responseMessage: 'success',
    responseCode: '2000',
  },
  statusCode: '200',
};

export const hourlyData = {
  result: [
    {
      lotUID: 4,
      lat: 37.5668021171335,
      lon: 127.007358177138,
      productType: 'DAILY',
      productName: '12시간권',
      regionType: 'DAILY',
      operTime: 0,
      salePrice: 7000,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 1,
    },
    {
      lotUID: 7,
      lat: 37.5673399451193,
      lon: 126.985208946848,
      productType: 'DAILY',
      productName: '휴일 당일권',
      regionType: 'DAILY',
      operTime: 2,
      salePrice: 7000,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 1,
    },
    {
      lotUID: 8,
      lat: 37.5661364222652,
      lon: 126.993512315812,
      productType: 'DAILY',
      productName: '평일 당일권',
      regionType: 'DAILY',
      operTime: 1,
      salePrice: 7000,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 1,
    },
    {
      lotUID: 14,
      lat: 37.5675850282068,
      lon: 126.983924059182,
      productType: 'DAILY',
      productName: '평일 3시간권',
      regionType: 'DAILY',
      operTime: 1,
      salePrice: 7000,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 2,
    },
    {
      lotUID: 39,
      lat: 37.5688849422849,
      lon: 127.008775114213,
      productType: 'DAILY',
      productName: '3시간권',
      regionType: 'DAILY',
      operTime: 0,
      salePrice: 500,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 1,
    },
    {
      lotUID: 31,
      lat: 37.5136891218964,
      lon: 126.899347479276,
      productType: 'DAILY',
      productName: '평일 심야권, 주중 4시간권, 주말 2시간권',
      regionType: 'DAILY',
      operTime: 1,
      salePrice: 1000,
      productStatus: 'SALE',
      paymentAvailbleYN: 'YES',
      productCount: 1,
    },
    {
      lat: 37.516637,
      lon: 126.891505,
      tranUID: 0,
      regionCode: 1090,
      slotCode: '002',
      regionType: 'zoomansaHourly',
      isParking: 0,
      infra_id: '',
      feeMinute: 5,
      feeAmount: 100,
      defAmount: 1200,
      operationYN: true,
      productCount: 4,
    },
    {
      lat: 37.51607271705618,
      lon: 126.8967270503698,
      tranUID: 0,
      regionCode: 1091,
      slotCode: '11-11-1',
      regionType: 'zoomansaHourly',
      isParking: 0,
      infra_id: '',
      feeMinute: 5,
      feeAmount: 100,
      defAmount: 1200,
      operationYN: true,
      productCount: 5,
    },
    {
      lat: 37.5128239,
      lon: 126.8985316,
      tranUID: 0,
      regionCode: 1096,
      slotCode: '54-01-01',
      regionType: 'zoomansaHourly',
      isParking: 0,
      infra_id: '',
      feeMinute: 5,
      feeAmount: 100,
      defAmount: 1200,
      operationYN: true,
      productCount: 1,
    },
    {
      lat: '37.5194349',
      lon: '126.9003849',
      regionCode: 2791,
      slotCode: 2791,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 영등포로34길 9',
      lnmadr: '서울특별시 영등포구 영등포동4가 123',
      prkplceNm: '영남',
      prkplceNo: '118-2-000003',
      basicCharge: '160',
      basicTime: '5',
    },
    {
      lat: '37.5242838',
      lon: '126.8932749',
      regionCode: 2793,
      slotCode: 2793,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 당산동3가 370-3',
      lnmadr: '서울특별시 영등포구 당산동3가 370-3',
      prkplceNm: '영등포구청역',
      prkplceNo: '118-2-000001',
      basicCharge: '260',
      basicTime: '5',
    },
    {
      lat: '37.5133616',
      lon: '126.895222',
      regionCode: 2942,
      slotCode: 2942,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 문래동3가 54-12',
      lnmadr: '서울특별시 영등포구 문래동3가 54-12',
      prkplceNm: '문래1동',
      prkplceNo: '118-1-000003',
      basicCharge: '130',
      basicTime: '5',
    },
    {
      lat: '37.5262101',
      lon: '126.8972277',
      regionCode: 3526,
      slotCode: 3526,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 당산동3가 2-1',
      lnmadr: '서울특별시 영등포구 당산동3가 2-1',
      prkplceNm: '당산노외',
      prkplceNo: '118-2-000004',
      basicCharge: '260',
      basicTime: '5',
    },
    {
      lat: '37.5286217',
      lon: '126.894421',
      regionCode: 8556,
      slotCode: 8556,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 선유동1로 80',
      lnmadr: '',
      prkplceNm: '별관청사공영주차장',
      prkplceNo: '118-2-000028',
      basicCharge: '150',
      basicTime: '5',
    },
    {
      lat: '37.52875339',
      lon: '126.8909801',
      regionCode: 8558,
      slotCode: 8558,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 국회대로22길 20',
      lnmadr: '',
      prkplceNm: '양평유수지 공영주차장',
      prkplceNo: '118-2-000030',
      basicCharge: '250',
      basicTime: '5',
    },
    {
      lat: '37.50820335',
      lon: '126.8967334',
      regionCode: 13266,
      slotCode: 13266,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 도신로15길 36',
      lnmadr: '',
      prkplceNm: '도림동 공영주차장',
      prkplceNo: '118-2-000006',
      basicCharge: '50',
      basicTime: '5',
    },
    {
      lat: '37.516205',
      lon: '126.8878482',
      regionCode: 13267,
      slotCode: 13267,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '',
      lnmadr: '서울특별시 영등포구 문래동5가 3',
      prkplceNm: '문래동 공영주차장',
      prkplceNo: '118-2-000007',
      basicCharge: '150',
      basicTime: '5',
    },
    {
      lat: '37.524747',
      lon: '126.8955205',
      regionCode: 13275,
      slotCode: 13275,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 양산로 111',
      lnmadr: '',
      prkplceNm: '영등포구청환승공영주차장',
      prkplceNo: '118-2-000016',
      basicCharge: '100',
      basicTime: '5',
    },
    {
      lat: '37.5252165',
      lon: '126.895135',
      regionCode: 13280,
      slotCode: 13280,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 당산로27길 12',
      lnmadr: '',
      prkplceNm: '당산근린공원공영주차장',
      prkplceNo: '118-2-000021',
      basicCharge: '150',
      basicTime: '5',
    },
    {
      lat: '37.51512033',
      lon: '126.8936472',
      regionCode: 13285,
      slotCode: 13285,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 당산로1',
      lnmadr: '',
      prkplceNm: '문래근린공원공영주차장',
      prkplceNo: '118-2-000026',
      basicCharge: '250',
      basicTime: '5',
    },
    {
      lat: '37.523768',
      lon: '126.9000061',
      regionCode: 13307,
      slotCode: 13307,
      prkplceSe: 'public',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 영등포구 양산로 147',
      lnmadr: '',
      prkplceNm: '당산1동 공영주차장',
      prkplceNo: '118-2-000004',
      basicCharge: '100',
      basicTime: '5',
    },
    {
      lat: '37.50744252',
      lon: '126.8922692',
      regionCode: 15613,
      slotCode: 15613,
      prkplceSe: 'private',
      regionType: 'public',
      infra_id: '',
      rdnmadr: '서울특별시 구로구 새말로 117-6',
      lnmadr: '서울특별시 구로구 구로동3-55',
      prkplceNm: '신도림역 주차장',
      prkplceNo: '116-4-000021',
      basicCharge: '3000',
      basicTime: '60',
    },
  ],
};

export const chargerData = {
  result: [
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'CV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/대영채비.png',
      statId: 'CV002514',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.520662',
      lng: '126.89438',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 3,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EC',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/이지차저.png',
      statId: 'EC002510',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5189235',
      lng: '126.8973744',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 3,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EC',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/이지차저.png',
      statId: 'EC002586',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5190824',
      lng: '126.8971245',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 2,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EC',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/이지차저.png',
      statId: 'EC004823',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5189127',
      lng: '126.8946094',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 2,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EC',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/이지차저.png',
      statId: 'EC110024',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5174632',
      lng: '126.8964938',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 4,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV003167',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5093079',
      lng: '126.8935694',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 3,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV003168',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5096645',
      lng: '126.894372',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 3,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV003800',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5151022',
      lng: '126.8954335',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 4,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012392',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 7,
      rapidTotalCnt: 1,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 6,
      rapidTotalCnt: 2,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'GS',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/GS칼텍스.png',
      statId: 'GS000043',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.513178',
      lng: '126.896482',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 2,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'IM',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/아이마켓코리아.png',
      statId: 'IM000319',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5194432',
      lng: '126.8980628',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 3,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'JA',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/중앙제어.png',
      statId: 'JA110027',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5204139',
      lng: '126.8974592',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 14,
      rapidTotalCnt: 2,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'JA',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/중앙제어.png',
      statId: 'JA110060',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5183791',
      lng: '126.896492',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 11,
      rapidTotalCnt: 6,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'KE',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/한국전기차인프라기술.png',
      statId: 'KE001173',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5147464',
      lng: '126.8975368',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 5,
      rapidTotalCnt: 2,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'KE',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/한국전기차인프라기술.png',
      statId: 'KE001174',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5148955',
      lng: '126.8980474',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 4,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'KE',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/한국전기차인프라기술.png',
      statId: 'KE001175',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5145572',
      lng: '126.8990857',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 5,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'KP',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/한국전력.png',
      statId: 'KP000021',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.52031651',
      lng: '126.892762',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 1,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'ME',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/환경부.png',
      statId: 'ME22C1B9',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5090784',
      lng: '126.8960627',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 1,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PC',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/파킹클라우드.png',
      statId: 'PC000736',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5181173',
      lng: '126.9000768',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 8,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PI121458',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5181',
      lng: '126.898',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 2,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PI200825',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.512064298',
      lng: '126.89689465',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 4,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PI204111',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.517109750',
      lng: '126.89952330',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 1,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PI645059',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.522417442',
      lng: '126.89393026',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 10,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PI645210',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5177939',
      lng: '126.9000769',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 20,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PIH00027',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.512040000',
      lng: '126.89697900',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 30,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PIH00080',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.520714200',
      lng: '126.89633630',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 14,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PI',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/차지비.png',
      statId: 'PIH00564',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5139448',
      lng: '126.898656',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 1,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'PL',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/플러그링크.png',
      statId: 'PL003158',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.509307880',
      lng: '126.89356946',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 6,
      rapidTotalCnt: 0,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'ST',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에스트래픽.png',
      statId: 'ST115611',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5145528',
      lng: '126.8923103',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 1,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: null,
      limitYn: null,
      output: null,
      busiId: 'ST',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에스트래픽.png',
      statId: 'ST115619',
      statNm: null,
      busiNm: null,
      stat: null,
      statUpdDt: null,
      nowTsdt: null,
      lastTedt: null,
      chgerType: null,
      lat: '37.5188522',
      lng: '126.8941147',
      bnm: null,
      zcode: null,
      zscode: null,
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 1,
      elapsedTimeText: null,
      busiCall: null,
      useTime: null,
      unknownCnt: 0,
      parkingFree: null,
      limitDetail: null,
      addr: null,
      chgerId: null,
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
  ],
  monthly: [],
  statusCode: 200,
};

export const minyoung = {
  slotList: [
    {
      stickerYN: null,
      address: null,
      map: null,
      carNumber: null,
      areaUID: 0,
      sp_rtn: 0,
      tranUID: 0,
      weekendShareYN: 'Y',
      regionCode: 1090,
      slotCode: '002',
      useShared: 0,
      shareStartTime: '09:00',
      shareEntTime: '18:00',
      sharePerDayIdx: 0,
      unitkind: null,
      ownerUID: 0,
      unitseqno: null,
      unitlabel: null,
      infra_id: null,
      arsNumber: 16660478,
      regionRealName: '주만사 제2 공영주차장',
      feeAmount: 100,
      feeMinute: 5,
      slotUniqueCode: '',
      latitude: 0,
      longitude: 0,
      defaultAmount: 1200,
      operationYN: true,
      sensorUUID: null,
      bottomLeftLat: 0,
      topRightLat: 0,
      bottomLeftLon: 0,
      topRightLon: 0,
      defaultMinute: 0,
      sectionseqno: null,
      sectionlabel: null,
      limitMileage: 0,
      startShareDate: null,
      endShareDate: null,
      amount: null,
      limitStart: 0,
      limitSize: 0,
      slotIdx: 25833,
    },
    {
      stickerYN: null,
      address: null,
      map: null,
      carNumber: null,
      areaUID: 0,
      sp_rtn: 0,
      tranUID: 0,
      weekendShareYN: 'N',
      regionCode: 1090,
      slotCode: '003',
      useShared: 0,
      shareStartTime: '09:00',
      shareEntTime: '18:00',
      sharePerDayIdx: 0,
      unitkind: null,
      ownerUID: 0,
      unitseqno: null,
      unitlabel: null,
      infra_id: null,
      arsNumber: 16660478,
      regionRealName: '주만사 제3 공영주차장',
      feeAmount: 100,
      feeMinute: 5,
      slotUniqueCode: '',
      latitude: 0,
      longitude: 0,
      defaultAmount: 1200,
      operationYN: true,
      sensorUUID: null,
      bottomLeftLat: 0,
      topRightLat: 0,
      bottomLeftLon: 0,
      topRightLon: 0,
      defaultMinute: 0,
      sectionseqno: null,
      sectionlabel: null,
      limitMileage: 0,
      startShareDate: null,
      endShareDate: null,
      amount: null,
      limitStart: 0,
      limitSize: 0,
      slotIdx: 25880,
    },
  ],
  regionType: 'zoomansaHourly',
  isHoliday: false,
  statusCode: '200',
};

export const publicDetail = {
  slotList: [
    {
      basicTime: '5',
      latitude: '37.5194349',
      longitude: '126.9003849',
      apiUID: 2791,
      prkplceSe: 'public',
      rdnmadr: '서울특별시 영등포구 영등포로34길 9',
      lnmadr: '서울특별시 영등포구 영등포동4가 123',
      prkplceNm: '영남',
      prkplceNmSub: null,
      prkplceNo: '118-2-000003',
      slotNumber: null,
      basicCharge: '160',
      prkplceType: '노외',
      prkcmprt: '570',
      feedingSe: '3',
      enforceSe: '미시행',
      operDay: '평일+토요일+공휴일',
      weekdayOperOpenHhmm: '00:00',
      weekdayOperColseHhmm: '00:00',
      satOperOperOpenHhmm: '00:00',
      satOperCloseHhmm: '00:00',
      holidayOperOpenHhmm: '00:00',
      holidayCloseOpenHhmm: '00:00',
      parkingchrgeInfo: '유료',
      addUnitTime: '',
      addUnitCharge: '',
      dayCmmtktAdjTime: '',
      dayCmmtkt: '',
      monthCmmtkt: '',
      metpay: '',
      spcmnt: null,
      institutionNm: '',
      phoneNumber: '02-777-1234',
      referenceDate: '2021-07-30',
      insttCode: 'B553774',
      bottomLeftLatitude: 0,
      bottomLeftLongitude: 0,
      topRightLatitude: 0,
      topRightLongitude: 0,
    },
  ],
  regionType: 'public',
  statusCode: '200',
};

export const chargerListData = {
  result: [
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '100',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '3',
      statUpdDt: '20250117133000',
      nowTsdt: '20250115172102',
      lastTedt: '20250108202714',
      chgerType: '04',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: '3일 전 시작',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '01',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '100',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '2',
      statUpdDt: '20250120135326',
      nowTsdt: '',
      lastTedt: '20250120135310',
      chgerType: '03',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: ' 1시간 7분 전 사용',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '02',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '9',
      statUpdDt: '20250118103810',
      nowTsdt: '',
      lastTedt: '20250117101459',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: '2일 전 사용',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '03',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '3',
      statUpdDt: '20250120130523',
      nowTsdt: '20250120130510',
      lastTedt: '20250120124319',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: ' 1시간 55분 전 시작',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '04',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '3',
      statUpdDt: '20250120103916',
      nowTsdt: '20250120103838',
      lastTedt: '20250119215940',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: ' 4시간 21분 전 시작',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '05',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '2',
      statUpdDt: '20250120132924',
      nowTsdt: '',
      lastTedt: '20250120132708',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: ' 1시간 31분 전 사용',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '06',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '3',
      statUpdDt: '20250120130322',
      nowTsdt: '20250120130304',
      lastTedt: '20250120100219',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: ' 1시간 57분 전 시작',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '07',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
    {
      location: '지하2층 주차램프 앞 급속',
      limitYn: 'N',
      output: '7',
      busiId: 'EV',
      logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
      statId: 'EV012393',
      statNm: '서울영등포 세미콜론N타워',
      busiNm: '에버온',
      stat: '2',
      statUpdDt: '20250120144530',
      nowTsdt: '',
      lastTedt: '20250120144352',
      chgerType: '02',
      lat: '37.5163887',
      lng: '126.8999754',
      bnm: '에버온',
      zcode: '11',
      zscode: '11560',
      inputDate: null,
      slowTotalCnt: 0,
      rapidTotalCnt: 0,
      elapsedTimeText: '15분 전 사용',
      busiCall: '1661-7766',
      useTime: '24시간 이용가능',
      unknownCnt: 0,
      parkingFree: 'N',
      limitDetail: '',
      addr: '서울특별시 영등포구 문래로28길 25 (문래동3가)',
      chgerId: '08',
      slowAvailableCnt: 0,
      rapidAvailableCnt: 0,
    },
  ],
  chargerStat: {
    statId: 'EV012393',
    rapidTotalCnt: 2,
    rapidStatusText: '충전중',
    slowTotalCnt: 6,
    busiNm: '에버온',
    slowAvailableCnt: 2,
    rapidAvailableCnt: 1,
    unknownAvailableCnt: 0,
    unknownCnt: 0,
    logoImagePath: 'https://ars.zoomansa.com/datafiles/evchargerimage/에버온.png',
    slowStatusText: '충전중',
    unknownStatusText: '',
    statNm: '서울영등포 세미콜론N타워',
  },
  statusCode: 200,
};

export const monthlyListData = {
  imageMap: {},
  slotList: [
    {
      lotUID: 5761,
      lat: 37.5238211603221,
      lon: 126.892883338762,
      salePrice: 100000,
      productName: '월 정기주차 상품',
      slotUID: '2721',
      productUID: 2734,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'SALE',
      holidayIsOpen: 'YES',
      jibunLotAddr: '서울특별시 영등포구 당산동2가 159-16',
      parkingMethod: '해당없음',
      parkingAvailCar: '승용차전용',
      slotType: '해당없음',
      vehicleRegistration: '필요없음',
      parkingLotType: '기계식(승용)',
      paymentAvailbleYN: 'YES',
      depositAmount: '50000',
      useEndDate: '2024-12-06',
    },
    {
      lotUID: 600002,
      lat: 37.5136891218964,
      lon: 126.899347479276,
      salePrice: 27000,
      productName: '월정기권',
      slotUID: '30087',
      productUID: 300076,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '운영안함',
      operType: '1',
      productStatus: 'SALE',
      holidayIsOpen: 'YES',
      jibunLotAddr: '서울 영등포구 문래동1가 23',
      parkingMethod: '해당없음',
      parkingAvailCar: 'SUV이하',
      slotType: '해당없음',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'NO',
      depositAmount: '0',
    },
    {
      lotUID: 5893,
      lat: 37.5147464245043,
      lon: 126.897536835727,
      salePrice: 80000,
      productName: '월정기권',
      slotUID: '30060',
      productUID: 300054,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'COMPLETE',
      holidayIsOpen: 'NO',
      jibunLotAddr: '서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티',
      parkingMethod: '빈자리',
      parkingAvailCar: 'SUV이하,경차전용,탑차가능',
      slotType: '단일주차',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'YES',
      depositAmount: '2500',
    },
    {
      lotUID: 5893,
      lat: 37.5147464245043,
      lon: 126.897536835727,
      salePrice: 80000,
      productName: '월정기권',
      slotUID: '30061',
      productUID: 300055,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'COMPLETE',
      holidayIsOpen: 'NO',
      jibunLotAddr: '서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티',
      parkingMethod: '빈자리',
      parkingAvailCar: 'SUV이하,경차전용,탑차가능',
      slotType: '단일주차',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'YES',
      depositAmount: '2500',
      useEndDate: '2025-02-01',
    },
    {
      lotUID: 5893,
      lat: 37.5147464245043,
      lon: 126.897536835727,
      salePrice: 80000,
      productName: '월정기권',
      slotUID: '30062',
      productUID: 300056,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'COMPLETE',
      holidayIsOpen: 'NO',
      jibunLotAddr: '서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티',
      parkingMethod: '빈자리',
      parkingAvailCar: 'SUV이하,경차전용,탑차가능',
      slotType: '단일주차',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'YES',
      depositAmount: '2500',
      useEndDate: '2025-02-01',
    },
    {
      lotUID: 5893,
      lat: 37.5147464245043,
      lon: 126.897536835727,
      salePrice: 80000,
      productName: '월정기권',
      slotUID: '30063',
      productUID: 300057,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'COMPLETE',
      holidayIsOpen: 'NO',
      jibunLotAddr: '서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티',
      parkingMethod: '빈자리',
      parkingAvailCar: 'SUV이하,경차전용,탑차가능',
      slotType: '단일주차',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'YES',
      depositAmount: '2500',
      useEndDate: '2025-02-01',
    },
    {
      lotUID: 5893,
      lat: 37.5147464245043,
      lon: 126.897536835727,
      salePrice: 80000,
      productName: '월정기권',
      slotUID: '30064',
      productUID: 300058,
      weeklyOperTime: '00:00~23:59',
      weekendOperTime: '00:00~23:59',
      operType: '0',
      productStatus: 'COMPLETE',
      holidayIsOpen: 'NO',
      jibunLotAddr: '서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티',
      parkingMethod: '빈자리',
      parkingAvailCar: 'SUV이하,경차전용,탑차가능',
      slotType: '단일주차',
      vehicleRegistration: '필요없음',
      parkingLotType: '자주식',
      paymentAvailbleYN: 'YES',
      depositAmount: '2500',
      useEndDate: '2025-02-21',
    },
  ],
  regionType: 'zoomansaMonthly',
  statusCode: '200',
};

export const hourlyLotDetail = {
  slotList: [
    {
      basicTime: '5',
      latitude: '37.5194349',
      longitude: '126.9003849',
      apiUID: 2791,
      prkplceSe: 'public',
      rdnmadr: '서울특별시 영등포구 영등포로34길 9',
      lnmadr: '서울특별시 영등포구 영등포동4가 123',
      prkplceNm: '영남',
      prkplceNmSub: null,
      prkplceNo: '118-2-000003',
      slotNumber: null,
      basicCharge: '160',
      prkplceType: '노외',
      prkcmprt: '570',
      feedingSe: '3',
      enforceSe: '미시행',
      operDay: '평일+토요일+공휴일',
      weekdayOperOpenHhmm: '00:00',
      weekdayOperColseHhmm: '00:00',
      satOperOperOpenHhmm: '00:00',
      satOperCloseHhmm: '00:00',
      holidayOperOpenHhmm: '00:00',
      holidayCloseOpenHhmm: '00:00',
      parkingchrgeInfo: '유료',
      addUnitTime: '',
      addUnitCharge: '',
      dayCmmtktAdjTime: '',
      dayCmmtkt: '',
      monthCmmtkt: '',
      metpay: '',
      spcmnt: null,
      institutionNm: '',
      phoneNumber: '',
      referenceDate: '2021-07-30',
      insttCode: 'B553774',
      bottomLeftLatitude: 0,
      bottomLeftLongitude: 0,
      topRightLatitude: 0,
      topRightLongitude: 0,
    },
  ],
  regionType: 'public',
  statusCode: '200',
};

export const jinjuDetail = {
  slotList: [
    {
      prkplceType: '노상',
      prkcmprt: '46',
      feedingSe: '1',
      enforceSe: '미시행',
      operDay: '평일+토요일+공휴일',
      weekdayOperOpenHhmm: '10:00',
      weekdayOperColseHhmm: '21:00',
      satOperOperOpenHhmm: '10:00',
      satOperCloseHhmm: '21:00',
      holidayOperOpenHhmm: '10:00',
      holidayCloseOpenHhmm: '21:00',
      parkingchrgeInfo: '유료',
      addUnitTime: '10',
      addUnitCharge: '200',
      dayCmmtktAdjTime: '0',
      dayCmmtkt: '0',
      monthCmmtkt: '75000',
      metpay: '현금',
      spcmnt: '',
      institutionNm: '경상남도 진주시청',
      referenceDate: '2024-06-04',
      insttCode: '5310000',
      bottomLeftLatitude: 0.0,
      bottomLeftLongitude: 0.0,
      topRightLatitude: 0.0,
      topRightLongitude: 0.0,
      apiUID: 16248,
      prkplceSe: '공영',
      rdnmadr: null,
      lnmadr: null,
      prkplceNm: '차없는거리',
      prkplceNmSub: null,
      prkplceNo: 'group-9',
      basicCharge: '500',
      basicTime: '30',
      latitude: '35.192849',
      longitude: '128.08316',
      phoneNumber: '055-749-8725',
      slotNumber: null,
    },
  ],
  regionType: 'public',
  statusCode: '200',
};

export const dailyDetail = {
  jibunLotAddr: '서울 중구 을지로3가 282-7',
  sumCount: 9,
  lotUID: 8,
  holidayOperTime: '00:00~23:59',
  imageMap: {},
  slotType: '단일주차',
  latitude: '37.5661364222652',
  parkingAvailCar: '경차,세단(소, 중형),세단(대형),SUV,RV',
  useSlotGuide: '이용방법 테스트.테스트2.',
  lotName: '을지로16길 주차장',
  satOperTime: '00:00~23:59',
  parkingMethod: '빈자리',
  sunOperTime: '00:00~23:59',
  weeklyOperTime: '00:00~23:59',
  slotCount: 457,
  vehicleRegistration: '차량등록필요',
  parkingLotType: '자주식',
  productList: [
    {
      saleStartTime: '1',
      parkingAvailableText: '구매 당일 00:00~23:59 주차가능',
      isOpenNow: true,
      salePrice: '7000',
      saleAvailable: '판매 중',
      count: 9,
      productStatus: 'SALE',
      totalCount: '10',
      productUID: 9,
      productName: '평일 당일권',
    },
  ],
  longitude: '126.993512315812',
  statusCode: '200',
};
