import { styled } from '@stitches/react';

export const SeasonTicketTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '25px',
});

export const ParkingIcon = styled('img', {
  marginRight: '8px',
});

export const ViewDivide2 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '13px',
});

export const BottomContentsContainer = styled('div', {
  paddingBottom: '25px',
});

export const CautionSirenTitleContentsContainer = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
  marginTop: '40px',
});

export const CautionSirenTitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: '30px',
});

export const SirenIcon = styled('img', {
  width: '48px',
  height: '48px',
  marginTop: '30px',
});

export const CautionTitleContainer = styled('div', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '19px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  marginTop: '12px',
});

export const ParkingInfoSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const CautionContentsContainer = styled('div', {
  margin: '0px 25px',
});

export const CautionFirstContainer = styled('div', {
  height: '70px',
  backgroundColor: '#F8F8FA',
  display: 'flex',
  borderRadius: '5px',
  marginTop: '27px',
});

export const CautionRemainContainer = styled('div', {
  height: '70px',
  backgroundColor: '#F8F8FA',
  display: 'flex',
  borderRadius: '5px',
  marginTop: '12px',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingLeft: 12,
  // paddingLeft: 12,
});

export const CautionIcon = styled('div', {
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  borderRadius: 50,
});

export const CautionTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '9px',
});

export const CautionTopTextContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const CautionBottomTextContainer = styled('div', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginTop: '3px',
});

export const CautionDetailContainer = styled('div', {
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
});

export const CautionDetailNumberTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});

export const CautionDetailTextContainer = styled('div', {
  marginLeft: '8px',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: '#6B6B6B',
});

export const CautionDetailNumberSpan = styled('div', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
});

export const CautionDetailTextSpan = styled('div', {
  color: '',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});

export const RedColorSpan = styled('span', {
  color: '#ED1659',
});

export const GrayColorSpan = styled('span', {
  color: '#6B6B6B',
});

export const MainColorSpan = styled('span', {
  color: '#8B63BB',
});

export const BlackColorSpan = styled('span', {
  color: '#1A1A1A',
});

export const CautionDotTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '8px',
  gap: '8px',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});

export const DotIcon = styled('img', {
  marginTop: '6px',
});
