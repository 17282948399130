import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainer } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import {
  PhotoContainer,
  ReductionNotifyButtonContainer,
  ReductionNotifyNumberText,
  ReductionNotifyNumberTitleText,
  ReductionNotifyTitleContainer,
  ReductionNotifyTitleText,
  ReductionTitleContainer,
  ViewDivideContainer,
} from '../styles';
import {
  ReductionNotifyFirstContentsText,
  ReductionNotifyFirstListContainer,
  UploadedImage,
  UploadedImageRemoveButton,
  UploadedImageWrapper,
  UploadImageSection,
} from './styles';
import ReductionFileSubmitBottomModal from 'components/atoms/Modal/ReductionFileSubmitBottomModal';
import { colors } from 'styles/colors';
import FileSelectPopup from 'components/atoms/Modal/FileSelectPopup';
import { useImageUploader } from 'hooks/useImageUploader';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { taxReductionStore } from 'stores/store/TaxReduction/taxReductionStore';
import { carStore } from 'stores/store/Car/carStore';
import { sendFormData } from 'utils/imageFormData';
import { useToast } from 'components/atoms/Toast/ToastProvider';

import { IcDeleteTextSVG } from 'assets/ic';
import icPhoto from 'assets/ic/ic_photo.svg';
import icExclamationPurple from 'assets/ic/ic_exclamation_purple.svg';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

const ReductionFileAttach = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { taxReductionTypeUID, type } = state;
  const { showToast } = useToast();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilePopup, setIsFilePopup] = useState(false);
  const [isPopup, setIsPopup] = useState(false);

  const { selectedImages, handleImageChange, removeImage, error } = useImageUploader();
  const { taxRegisterRes } = taxReductionStore;
  const { memberUID } = userInfoStore;

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (isFilePopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isFilePopup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await taxReductionStore.fetchGetTaxRequirements({
          taxReductionTypeUID,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData().catch(error => {
      console.log('감면 정보 가져오기 중 오류가 발생했습니다.', error);
    });
  }, [taxReductionTypeUID]);

  const handleSubmitClick = () => {
    setIsModalVisible(true);
  };

  const onCloseFilePopup = () => {
    setIsFilePopup(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    const data = {
      mLinkUID: memberUID,
      carNumber: carStore.carNumber,
      taxReductionTypeUID,
    };
    console.log(data);
    if (type === 'post') {
      taxReductionStore.fetchInsertTaxReductionInfo(sendFormData(selectedImages, data));
    } else {
      taxReductionStore.fetchUpdateTaxReductionInfo(sendFormData(selectedImages, data));
    }
    navigate('/reductionApplySuccess');
  };

  useEffect(() => {
    onCloseFilePopup();
  }, [selectedImages]);

  useEffect(() => {
    if (error) {
      setIsPopup(true);
    }
  }, [error]);

  return (
    <MainContainer>
      <Title
        title="복지대상 신청"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          carStore.setCarNumber('');
          navigate('/carInfo');
        }}
        rightTitle={'건너뛰기'}
      />
      <ReductionTitleContainer>
        <span>공영 주차장 요금감면</span>
        <br />
        <span style={{ color: '#8B63BB' }}>
          증빙자료<span style={{ color: '#333' }}>를 업로드 해주세요.</span>
        </span>
      </ReductionTitleContainer>
      <PhotoContainer
        onClick={() => {
          setIsFilePopup(true);
        }}
      >
        <img src={icPhoto} alt={'포토 아이콘'} />
        <span
          style={{
            fontFamily: 'Pretendard',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            color: '#B0B0B0',
          }}
        >
          파일첨부
        </span>
      </PhotoContainer>
      <div style={{ marginTop: '11px' }}>
        <UploadImageSection>
          {selectedImages.map((image, index) => (
            <UploadedImageWrapper key={index}>
              <UploadedImage src={image} alt={`uploaded-${index}`} />
              <UploadedImageRemoveButton onClick={() => removeImage(index)}>
                <IcDeleteTextSVG />
              </UploadedImageRemoveButton>
            </UploadedImageWrapper>
          ))}
        </UploadImageSection>
      </div>
      <ReductionNotifyButtonContainer>
        <div style={{ marginTop: '71px' }}>
          <ReductionNotifyTitleContainer>
            <img src={icExclamationPurple} alt="유의사항 아이콘" style={{ marginRight: '8px' }} />
            <ReductionNotifyTitleText>유의사항</ReductionNotifyTitleText>
          </ReductionNotifyTitleContainer>
          <ViewDivideContainer />
          <div style={{ marginBottom: '6px' }}>
            <ReductionNotifyNumberText>01</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              <span style={{ color: '#ED1659' }}>필수 제출 서류</span>
              <span>를 꼭 업로드 해주세요.</span>
            </ReductionNotifyNumberTitleText>
          </div>
          <div style={{ backgroundColor: '#F8F8FA', borderRadius: '10px' }}>
            <ReductionNotifyFirstListContainer>
              {taxReductionStore.taxRequirements.map((el, index) => {
                return (
                  <ReductionNotifyFirstContentsText key={index}>
                    {el}
                  </ReductionNotifyFirstContentsText>
                );
              })}
            </ReductionNotifyFirstListContainer>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <ReductionNotifyNumberText>02</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              요금 감면 증빙자료 미제출 시 할인 적용이 불가합니다.
            </ReductionNotifyNumberTitleText>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <ReductionNotifyNumberText>03</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              증빙서류를 촬영 후 이미지 파일로 첨부해주세요.
            </ReductionNotifyNumberTitleText>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <ReductionNotifyNumberText>04</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              <span>제출 시 주민등록번호 뒷자리, 카드 번호와 주소 등 </span>
              <br />
              <span style={{ marginLeft: '26px' }}> 개인정보를 가려서 전송해 주세요.</span>
            </ReductionNotifyNumberTitleText>
          </div>
        </div>
        <button
          style={{
            padding: '0px',
            border: '0px',
            width: '100%',
            height: '46px',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: 'normal',
            borderRadius: '5px',
            marginBottom: '40px',
            marginTop: '26px',
            cursor: 'pointer',
            backgroundColor: selectedImages.length ? colors.primary : colors.gray01,
            color: selectedImages.length ? colors.white : colors.primary,
          }}
          disabled={!selectedImages.length}
          onClick={async () => {
            handleSubmitClick();
          }}
        >
          다음
        </button>
      </ReductionNotifyButtonContainer>
      <ReductionFileSubmitBottomModal
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        onClick={handleModalClose}
      />
      <FileSelectPopup
        isVisible={isFilePopup}
        onClose={onCloseFilePopup}
        handleImageChange={handleImageChange}
      />
      <PopupModal
        isVisible={isPopup}
        onClose={() => {
          setIsPopup(false);
        }}
        title1={error?.title ?? ''}
        description={error?.description ?? ''}
        leftClick={() => {
          setIsPopup(false);
        }}
        leftText={'확인'}
      />
    </MainContainer>
  );
};

export default observer(ReductionFileAttach);
