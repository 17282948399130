import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie';

import { Caption1, Caption2, Caption3, SmallText } from 'styles/typography';
import {
  BorderBottomSpan,
  DivideContainer,
  DivideLine,
  NotPastParkingContainer,
  PastContainer,
  PastContentsContainer,
  PastStatusContainer,
  PastTicketContentsContainer,
  SixMonthSpan,
  SpinnerContainer,
  ThreeMonthSpan,
  TicketContentsDetailContainer,
  TicketTypeContainer,
  ToggleButtonContainer,
  ToggleContainer,
  ToggleSpan,
  YearContainer,
  YearMonthContentContainer,
} from 'pages/MyParkingTicket/styles';
import { myParkingPastHistoryStore } from 'stores/store/MyParkingTicket/myParkingPastListStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import YearSelectBottomModal from 'components/atoms/Modal/YearSelectBottomModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { YearMonthContainer } from 'pages/Point/PointHistoryBottomList/styles';
import { colors } from 'styles/colors';

import icDotDarkGray from 'assets/ic/ic_dot_dark_gray.svg';
import icArrowBottomGray from 'assets/ic/ic_arrow_bottom_gray.svg';
import lottieEmpty from 'assets/lottie/lottieEmpty80.json';

const MyParkingPastList = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const currentYear = new Date().getFullYear();
  const [isYearSelectBottomModal, setIsYearSelectBottomModal] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState<string>('전체');
  const { memberData } = userInfoStore;

  const memberUID = memberData?.memberUID ?? 0;
  // const memberUID = 534209;

  const TICKET_TYPE_ALL = '전체';
  const TICKET_TYPE_SEASON = '정기권';
  const TICKET_TYPE_TIME = '시간권';
  const TICKET_PART_TIME = '시간제';

  const PERIOD_3_MONTH = '3개월';
  const PERIOD_6_MONTH = '6개월';

  const TICKET_STATUS_PAST_USE_CANCEL = '이용취소';
  const TICKET_STATUS_PAST_PAYMENT_CANCEL = '결제취소';
  const TICKET_STATUS_USE_COMPLETE = '이용완료';

  const NOT_MONEY = '0원';

  const [isYearSelected, setIsYearSelected] = useState<boolean>(false);

  const createYearList = (baseYear: string): string[] => {
    const currentYear = new Date().getFullYear();
    const yearList = [];

    for (let year = currentYear; year >= Number(baseYear); year--) {
      yearList.push(String(year));
    }
    return yearList;
  };

  const year = createYearList(memberData?.regDate.split('-')[0] ?? '2018');

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  const [selectedPeriod, setSelectedPeriod] = useState<'threeMonth' | 'sixMonth' | string>('');

  const bottomRef = useRef<HTMLDivElement>(null);

  const {
    // 전체 조회
    isAllTicketLoading,
    allCurrentPage,
    allItemsPerPage,
    allTotalCount,
    allParkingPastHistoryList,

    // 정기권
    isSeasonTicketLoading,
    seasonTicketCurrentPage,
    seasonTicketItemsPerPage,
    seasonTicketTotalCount,
    seasonTicketParkingPastHistoryList,

    // 시간권, 시간제
    isTimeTicketLoading,
    timeTicketCurrentPage,
    timeTicketItemsPerPage,
    timeTicketTotalCount,
    timeTicketParkingPastHistoryList,
  } = myParkingPastHistoryStore;

  const handleScroll = () => window.scrollY;

  // 전체
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isAllTicketLoading) {
            if (allCurrentPage * allItemsPerPage < allTotalCount) {
              const currentScrollY = handleScroll();
              try {
                if (selectedPeriod === 'threeMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                    memberUID,
                    PERIOD_3_MONTH,
                    allCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                if (selectedPeriod === 'sixMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                    memberUID,
                    PERIOD_6_MONTH,
                    allCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
                  memberUID,
                  selectedYear,
                  allCurrentPage + 1,
                );
                window.scrollTo(0, currentScrollY);
              } catch (error) {
                console.error('Error fetching parking history:', error);
              }
            }
          }
        });
      },
      { threshold: 1.0 },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [
    isAllTicketLoading,
    allCurrentPage,
    allItemsPerPage,
    allTotalCount,
    selectedTicketType,
    selectedYear,
    selectedPeriod,
    currentYear,
    memberUID,
  ]);

  // 정기권
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isSeasonTicketLoading) {
            if (seasonTicketCurrentPage * seasonTicketItemsPerPage < seasonTicketTotalCount) {
              const currentScrollY = handleScroll();
              try {
                if (selectedPeriod === 'threeMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                    memberUID,
                    PERIOD_3_MONTH,
                    seasonTicketCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                if (selectedPeriod === 'sixMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                    memberUID,
                    PERIOD_6_MONTH,
                    seasonTicketCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
                  memberUID,
                  selectedYear,
                  seasonTicketCurrentPage + 1,
                );
                window.scrollTo(0, currentScrollY);
              } catch (error) {
                console.error('Error fetching season ticket past history:', error);
              }
            }
          }
        });
      },
      { threshold: 1.0 },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [
    isSeasonTicketLoading,
    seasonTicketCurrentPage,
    seasonTicketTotalCount,
    seasonTicketItemsPerPage,
    selectedTicketType,
    selectedYear,
    selectedPeriod,
    currentYear,
    memberUID,
  ]);

  // 시간권, 시간제
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isTimeTicketLoading) {
            if (timeTicketCurrentPage * timeTicketItemsPerPage < timeTicketTotalCount) {
              const currentScrollY = handleScroll();
              try {
                if (selectedPeriod === 'threeMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                    memberUID,
                    PERIOD_3_MONTH,
                    timeTicketCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                if (selectedPeriod === 'sixMonth') {
                  await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                    memberUID,
                    PERIOD_6_MONTH,
                    timeTicketCurrentPage + 1,
                  );
                  window.scrollTo(0, currentScrollY);
                  return;
                }

                await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
                  memberUID,
                  selectedYear,
                  timeTicketCurrentPage + 1,
                );
                window.scrollTo(0, currentScrollY);
              } catch (error) {
                console.error('Error fetching time ticket past history:', error);
              }
            }
          }
        });
      },
      { threshold: 1.0 },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [
    isTimeTicketLoading,
    timeTicketCurrentPage,
    timeTicketTotalCount,
    timeTicketItemsPerPage,
    selectedTicketType,
    selectedYear,
    selectedPeriod,
    currentYear,
    memberUID,
  ]);

  const handleSelectYear = async (index: number) => {
    const selectedYearFromList = year[index];
    setSelectedYear(selectedYearFromList);
    setSelectedPeriod(selectedYearFromList);
    setIsYearSelectBottomModal(false);
    setIsYearSelected(true);

    try {
      if (selectedTicketType === TICKET_TYPE_ALL) {
        const response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
          memberUID,
          selectedYearFromList,
          1,
        );
        console.log(`${selectedYearFromList} 전체 데이터:`, response);
      } else if (selectedTicketType === TICKET_TYPE_SEASON) {
        const response = await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
          memberUID,
          selectedYearFromList,
          1,
        );
        console.log(`${selectedYearFromList} 정기권 데이터:`, response);
      } else if (selectedTicketType === TICKET_TYPE_TIME) {
        const response = await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
          memberUID,
          selectedYearFromList,
          1,
        );
        console.log(`${selectedYearFromList} 시간권 데이터:`, response);
      }
    } catch (error) {
      console.error(`${selectedYearFromList} 데이터 불러오기 에러:`, error);
    }
  };

  const handlePeriodClick = async (period: 'threeMonth' | 'sixMonth') => {
    setSelectedPeriod(period);

    const periodStr = period === 'threeMonth' ? PERIOD_3_MONTH : PERIOD_6_MONTH;

    if (selectedYear !== String(currentYear)) {
      try {
        const response = await fetchParkingHistory(periodStr, selectedTicketType);
        console.log('전체 데이터:', response);
      } catch (error) {
        console.error('전체 데이터 불러오기 에러:', error);
      }
      return;
    }

    if (selectedYear === String(currentYear)) {
      await fetchParkingHistory(periodStr, selectedTicketType);
      return;
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const fetchAllTickets = async () => {
    try {
      const response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
        memberUID,
        String(currentYear),
        1,
      );
      console.log('전체 데이터:', response);
    } catch (error) {
      console.error('전체 데이터 불러오기 에러:', error);
    }
  };

  useEffect(() => {
    if (selectedTicketType === '전체') {
      fetchAllTickets().catch(error => {
        console.error('초기 데이터 불러오기 에러:', error);
      });
    }
  }, [selectedTicketType, memberUID]);

  const mapTicketTypeToVariant = (ticketType: string) => {
    switch (ticketType) {
      case TICKET_TYPE_SEASON:
        return 'seasonTicket';
      case TICKET_TYPE_TIME:
        return 'default';
      case TICKET_PART_TIME:
        return 'default';
      default:
        return 'default';
    }
  };

  const fetchParkingHistory = async (period: '3개월' | '6개월', ticketType: string) => {
    let response;
    try {
      if (ticketType === TICKET_TYPE_ALL) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
          memberUID,
          period,
          1,
        );
      } else if (ticketType === TICKET_TYPE_SEASON) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
          memberUID,
          period,
          1,
        );
      } else if (ticketType === TICKET_TYPE_TIME) {
        response = await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
          memberUID,
          period,
          1,
        );
      }
      console.log('전체 데이터:', response);
    } catch (error) {
      console.error('전체 데이터 불러오기 에러:', error);
    }
  };

  const toggleButtons = [
    {
      key: TICKET_TYPE_ALL,
      label: TICKET_TYPE_ALL,
      onClick: async () => {
        // 전체 토글 클릭
        try {
          setSelectedTicketType(TICKET_TYPE_ALL);

          if (selectedPeriod === 'threeMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
              memberUID,
              PERIOD_3_MONTH,
              1,
            );
            return;
          }

          if (selectedPeriod === 'sixMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
              memberUID,
              PERIOD_6_MONTH,
              1,
            );
            return;
          }

          try {
            await myParkingPastHistoryStore.fetchGetMyParkingAllPastHistory(
              memberUID,
              selectedYear,
              1,
            );
            console.log('fetchGetMyParkingAllPastHistory 호출 완료');
          } catch (error) {
            console.error('에러 발생:', error);
          }
        } catch (error) {
          console.error('Error fetching all parking history:', error);
        }
      },
    },
    {
      key: TICKET_TYPE_SEASON,
      label: TICKET_TYPE_SEASON,
      onClick: async () => {
        // 정기권 토글 클릭
        try {
          setSelectedTicketType(TICKET_TYPE_SEASON);

          if (selectedPeriod === 'threeMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
              memberUID,
              PERIOD_3_MONTH,
              1,
            );
            return;
          }

          if (selectedPeriod === 'sixMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
              memberUID,
              PERIOD_6_MONTH,
              1,
            );
            return;
          }

          try {
            await myParkingPastHistoryStore.fetchGetMyParkingSeasonTicketPastHistory(
              memberUID,
              selectedYear,
              1,
            );
            console.log('fetchGetMyParkingSeasonTicketPastHistory 호출 완료');
          } catch (error) {
            console.error('에러 발생:', error);
          }
        } catch (error) {
          console.error('Error fetching season ticket history:', error);
        }
      },
    },
    {
      key: TICKET_TYPE_TIME,
      label: TICKET_TYPE_TIME,
      onClick: async () => {
        // 시간권 토글 클릭 (시간권, 시간제)
        try {
          setSelectedTicketType(TICKET_TYPE_TIME);

          if (selectedPeriod === 'threeMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
              memberUID,
              PERIOD_3_MONTH,
              1,
            );
            return;
          }

          if (selectedPeriod === 'sixMonth') {
            await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
              memberUID,
              PERIOD_6_MONTH,
              1,
            );
            return;
          }

          try {
            await myParkingPastHistoryStore.fetchGetMyParkingTimeTicketPastHistory(
              memberUID,
              selectedYear,
              1,
            );
            console.log('fetchGetMyParkingTimeTicketPastHistory 호출 완료');
          } catch (error) {
            console.error('에러 발생:', error);
          }
        } catch (error) {
          console.error('Error fetching time ticket history:', error);
        }
      },
    },
  ];

  const filteredList =
    selectedTicketType === TICKET_TYPE_ALL
      ? allParkingPastHistoryList
      : selectedTicketType === TICKET_TYPE_SEASON
        ? seasonTicketParkingPastHistoryList
        : selectedTicketType === TICKET_TYPE_TIME
          ? timeTicketParkingPastHistoryList
          : allParkingPastHistoryList.filter(item => item.passType === selectedTicketType);

  const totalCount =
    selectedTicketType === TICKET_TYPE_ALL
      ? allTotalCount
      : selectedTicketType === TICKET_TYPE_SEASON
        ? seasonTicketTotalCount
        : selectedTicketType === TICKET_TYPE_TIME
          ? timeTicketTotalCount
          : 0;

  useEffect(() => {
    console.log('Updated totalCount:', totalCount);
  }, [totalCount, selectedPeriod, selectedTicketType]);

  return (
    <div>
      <ToggleButtonContainer>
        {toggleButtons.map(button => (
          <ToggleContainer
            key={button.key}
            isClicked={selectedTicketType === button.key}
            onClick={button.onClick}
          >
            <ToggleSpan isClicked={selectedTicketType === button.key}>{button.label}</ToggleSpan>
          </ToggleContainer>
        ))}
      </ToggleButtonContainer>

      <YearMonthContainer>
        <div
          style={{
            display: 'flex',
          }}
        >
          <SmallText color={'gray06'} weight={'regular'}>
            총
          </SmallText>
          <SmallText color={'gray08'} weight={'medium'}>
            &nbsp;{totalCount}&nbsp;
          </SmallText>
          <SmallText color={'gray06'} weight={'regular'}>
            건
          </SmallText>
        </div>
        <YearMonthContentContainer>
          <ThreeMonthSpan
            onClick={() => handlePeriodClick('threeMonth')}
            isThreeMonthHistory={selectedPeriod === 'threeMonth' || selectedPeriod === ''}
            isDisabled={selectedYear !== String(currentYear)}
            style={{
              color:
                selectedPeriod === ''
                  ? colors.gray05
                  : selectedPeriod === 'threeMonth'
                    ? colors.gray08
                    : colors.gray05,
              fontWeight: selectedPeriod === '' ? 400 : selectedPeriod === 'threeMonth' ? 600 : 400,
            }}
          >
            {PERIOD_3_MONTH}&nbsp;
          </ThreeMonthSpan>
          <SmallText color={'gray06'} weight={'regular'}>
            |
          </SmallText>
          <SixMonthSpan
            onClick={() => handlePeriodClick('sixMonth')}
            isSixMonthHistory={selectedPeriod === 'sixMonth' || selectedPeriod === ''}
            isDisabled={selectedYear !== String(currentYear)}
            style={{
              color:
                selectedPeriod === ''
                  ? colors.gray05
                  : selectedPeriod === 'sixMonth'
                    ? colors.gray08
                    : colors.gray05,
              fontWeight: selectedPeriod === '' ? 400 : selectedPeriod === 'sixMonth' ? 600 : 400,
            }}
          >
            &nbsp;{PERIOD_6_MONTH}&nbsp;
          </SixMonthSpan>
          <SmallText color={'gray06'} weight={'regular'}>
            |
          </SmallText>
          <YearContainer onClick={() => setIsYearSelectBottomModal(true)}>
            <SmallText
              style={{
                color:
                  selectedPeriod === 'threeMonth' || selectedPeriod === 'sixMonth'
                    ? colors.gray05
                    : colors.gray08,
                fontWeight:
                  selectedPeriod === 'threeMonth' || selectedPeriod === 'sixMonth' ? 400 : 600,
              }}
            >
              &nbsp;{selectedYear}&nbsp;
            </SmallText>
            <img src={icArrowBottomGray} alt="보라색 화살표 아래 아이콘" />
          </YearContainer>
        </YearMonthContentContainer>
      </YearMonthContainer>

      {isAllTicketLoading || isSeasonTicketLoading || isTimeTicketLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : filteredList.length === 0 ? (
        <NotPastParkingContainer>
          <Lottie options={defaultOptions} height={80} width={80} />
          <Caption1 weight={'medium'} color={'gray09'}>
            주차권 이용 내역이 없어요
          </Caption1>
        </NotPastParkingContainer>
      ) : (
        <div>
          {filteredList.map((item, index) => (
            <PastContainer
              ref={bottomRef}
              key={index}
              css={{
                marginTop: index === 0 ? '24px' : '0px',
              }}
              onClick={() => {
                // TODO: 리엑트 페이지 작업 완료 api 엮고 주석 처리된 페이지로 이동 navigate 시키면 됨
                // sessionStorage.setItem('status', item.status)
                // sessionStorage.setItem('address', item.lotAddress || '')
                // sessionStorage.setItem('lotName', item.lotName || '')
                //
                // // 정기권
                // if (item.passType === TICKET_TYPE_SEASON) {
                //   navigate('/myParkingSeasonTicket');
                //   return;
                // }
                //
                // // 시간권
                // if (item.passType === TICKET_TYPE_TIME) {
                //   navigate('/myParkingTimeTicket');
                //   return;
                // }
                //
                // // 시간제
                // if (item.passType === TICKET_PART_TIME) {
                //   navigate('/myParkingPartTime');
                //   return;
                // }

                if (item.passType === TICKET_TYPE_TIME) {
                  navigate(`/terraceTowerDetail?saleHistoryUID=${item.saleHistoryUID}`);
                  return;
                }

                window.location.href = item.paymentLink ?? '';
              }}
            >
              <DivideContainer>
                <img src={icDotDarkGray} alt="회색 점 아이콘" width={8} height={8} />
                <DivideLine />
              </DivideContainer>

              <PastContentsContainer>
                <PastStatusContainer>
                  <div
                    style={{
                      width: 'calc(100% - 66px)',
                    }}
                  >
                    {item.status === TICKET_STATUS_PAST_USE_CANCEL ||
                    item.status === TICKET_STATUS_PAST_PAYMENT_CANCEL ? (
                      <Caption2 weight={'semibold'} color={'red'}>
                        {item.status}
                      </Caption2>
                    ) : (
                      <Caption2 weight={'semibold'} color={'gray06'}>
                        {item.status}
                      </Caption2>
                    )}
                    <BorderBottomSpan>
                      {item.passType === TICKET_TYPE_TIME
                        ? item.productName
                        : item.passType === TICKET_PART_TIME && 'regionRealCode' in item
                          ? item.regionRealCode + ' 구획 이용권'
                          : item.passType}
                    </BorderBottomSpan>
                  </div>

                  <TicketTypeContainer ticketType={mapTicketTypeToVariant(item.passType)}>
                    {item.passType === TICKET_TYPE_SEASON ? (
                      <Caption3 weight="bold" color="primary">
                        {item.passType}
                      </Caption3>
                    ) : (
                      <Caption3 weight="bold" color="blue">
                        {item.passType}
                      </Caption3>
                    )}
                  </TicketTypeContainer>
                </PastStatusContainer>

                <PastTicketContentsContainer>
                  <TicketContentsDetailContainer
                    style={{
                      display: 'flex', // Flexbox 사용
                      alignItems: 'center', // 수직 정렬
                      width: '100%',
                    }}
                  >
                    <Caption2
                      style={{
                        width: '40px',
                        flexShrink: 0,
                      }}
                      weight={'regular'}
                      color={'gray06'}
                    >
                      주소
                    </Caption2>

                    <Caption2
                      style={{
                        marginLeft: '8px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        width: '100%',
                      }}
                      weight={'medium'}
                      color={'gray08'}
                    >
                      {item.lotAddress}
                    </Caption2>
                  </TicketContentsDetailContainer>

                  {/* 이용 섹션*/}
                  <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                    <Caption2 style={{ width: '40px' }} weight="regular" color="gray06">
                      이용
                    </Caption2>
                    <Caption2 style={{ marginLeft: '8px' }} weight="medium" color="gray08">
                      {item.passType === '시간제' ? item.parkingStartDateTime : item.usingTime}
                    </Caption2>
                  </TicketContentsDetailContainer>

                  {/* 차량 섹션 */}
                  {!(
                    item.passType === TICKET_TYPE_SEASON &&
                    item.status === TICKET_STATUS_PAST_PAYMENT_CANCEL
                  ) && (
                    <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                      <Caption2
                        style={{
                          width: '40px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        차량
                      </Caption2>
                      <Caption2 style={{ marginLeft: '8px' }} weight={'medium'} color={'gray08'}>
                        {item.carNumber}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  )}

                  {/* 금액 섹션 */}
                  {!(
                    item.status === TICKET_STATUS_PAST_USE_CANCEL ||
                    item.status === TICKET_STATUS_PAST_PAYMENT_CANCEL
                  ) && (
                    <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                      <Caption2
                        style={{
                          width: '40px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        금액
                      </Caption2>
                      <Caption2 style={{ marginLeft: '8px' }} weight={'bold'} color={'gray08'}>
                        {item.amount}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  )}

                  {item.status === TICKET_STATUS_PAST_USE_CANCEL ||
                  (item.passType === TICKET_TYPE_SEASON &&
                    item.status === TICKET_STATUS_USE_COMPLETE &&
                    item.refundPrice !== NOT_MONEY) ? (
                    <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                      <Caption2
                        style={{
                          width: '40px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        환불금
                      </Caption2>
                      <Caption2 style={{ marginLeft: '8px' }} weight={'bold'} color={'gray08'}>
                        {item.refundPrice}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  ) : null}

                  {/* 감면섹션 섹션 */}
                  {item.passType === '시간제' && item.reductionInfo && (
                    <TicketContentsDetailContainer style={{ marginTop: '8px' }}>
                      <Caption2
                        style={{
                          width: '40px',
                        }}
                        weight={'regular'}
                        color={'gray06'}
                      >
                        감면
                      </Caption2>
                      <Caption2 style={{ marginLeft: '8px' }} weight={'bold'} color={'gray08'}>
                        {item.reductionInfo}
                      </Caption2>
                    </TicketContentsDetailContainer>
                  )}
                </PastTicketContentsContainer>
              </PastContentsContainer>
            </PastContainer>
          ))}
        </div>
      )}
      <YearSelectBottomModal
        isVisible={isYearSelectBottomModal}
        onClose={() => {
          setIsYearSelectBottomModal(false);
        }}
        onSelectYear={handleSelectYear}
        year={year}
      />
    </div>
  );
});

export default MyParkingPastList;
