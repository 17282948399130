import BaseService from 'service/baseService';
import { IAuthCodeByTell, IAuthCodeByTellResponse } from 'models/Auth/AuthCodeByTellModel';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import { IDeleteAccountUser, IDeleteAccountUserResponse } from 'models/DeleteAccount/deleteAccountUserModel';

interface DeleteAccountApiEndpoints {
  DELETE_ACCOUNT: string
}

class DeleteAccountService extends BaseService<DeleteAccountApiEndpoints> {
  apiEndpoints: DeleteAccountApiEndpoints = {
    DELETE_ACCOUNT: 'deactivateUser',
  };

  postDeleteAccount = async (
    requestBody: IDeleteAccountUser,
  ): Promise<IDeleteAccountUserResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.DELETE_ACCOUNT}`)
        .build<IDeleteAccountUserResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new DeleteAccountService();




