import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import {
  CarInfoRegistrationSpan,
  CarInfoRepresentContainer,
  CarInfoRepresentSpan,
  Container,
  LengthDividerSpan,
  ModifyDeleteContainer,
  ParkingReductionContainer,
  ParkingReductionStatus,
  ToggleButtonContainer,
  CarNumberBackGroundContainer,
  CarNumberOutlineContainer,
  CarNumberText,
  LeftDotImg,
  RightDotImg,
} from './styles';
import TwoButtonModal from 'components/atoms/Modal/TwoButtonModal';
import CheckModal from 'components/atoms/Modal/CheckModal';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import { carStore } from 'stores/store/Car/carStore';
import { ICarListData } from 'models/Car/carListModel';
import { getFormattedDateString } from 'utils/dateUtils';
import { Body2 } from 'styles/typography';
import { taxReductionStore } from 'stores/store/TaxReduction/taxReductionStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

import icDotBlack from 'assets/ic/ic_dot_black.svg';

const CarInfoEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const car: ICarListData = state.car ? JSON.parse(state.car) : null;

  const [isToggled, setIsToggled] = useState(false);
  const [isReductionDeleteModal, setIsReductionDeleteModal] = useState(false);
  const [isCheckModalVisible, setIsCheckModalVisible] = useState(false);
  const [isCarInfoDeleteModal, setIsCarInfoDeleteModal] = useState(false);

  const { memberUID } = userInfoStore;

  const handleLeftOnClick = () => {
    navigate('/carInfo');
  };

  useEffect(() => {
    setIsToggled(car.isMain === 'Y');
  }, []);

  const handleToggle = () => {
    if (!isToggled) {
      setIsToggled(true);
    }

    carStore.fetchUpdateCarMain({
      mLinkUID: memberUID,
      carNumber: car.carNumber,
    });
  };

  const reductionDeleteModal = () => {
    setIsReductionDeleteModal(true);
  };

  const carInfoDeleteModal = () => {
    setIsCarInfoDeleteModal(true);
  };

  const showCheckModal = () => {
    setIsCheckModalVisible(true);
  };

  const hideCheckModal = async () => {
    await carStore.fetchDeleteMemberCarInfo(car.carInfoUID, memberUID, car.isMain);

    setIsCheckModalVisible(false);
    navigate(-1);
    // navigate('/carRegistration');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin>
      <Title
        title="차량 정보 수정"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          carInfoDeleteModal();
        }}
        rightTitle={'삭제'}
      />

      <Container>
        <CarInfoRepresentContainer>
          <Row justifyContent={'space-between'} style={{ width: '100%' }}>
            <CarInfoRepresentSpan>대표 차량으로 설정하기</CarInfoRepresentSpan>
            <ToggleButtonContainer isToggled={isToggled} onClick={handleToggle} />
          </Row>
          <CarNumberBackGroundContainer>
            <CarNumberOutlineContainer>
              <LeftDotImg src={icDotBlack} alt={'왼쪽 점 아이콘'} />
              <CarNumberText>{car.carNumber}</CarNumberText>
              <RightDotImg src={icDotBlack} alt={'점 아이콘2'} />
            </CarNumberOutlineContainer>
          </CarNumberBackGroundContainer>
        </CarInfoRepresentContainer>

        <Divider style={{ backgroundColor: colors.primaryBackground, height: 8 }} />
        <div style={{ margin: '30px 25px 25px' }}>
          <span
            style={{
              color: '#6B6B6B',
              fontFamily: 'Pretendard',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            주차요금 감면 대상
          </span>

          <ParkingReductionContainer>
            {car.confirmStatus === 'WAITING' ? (
              <ParkingReductionStatus>감면 신청 진행 중</ParkingReductionStatus>
            ) : car.confirmStatus === 'CONFIRM' ? (
              <ParkingReductionStatus>{car.taxReductionInfo}</ParkingReductionStatus>
            ) : (
              <Body2 weight={'bold'} color={'gray06'}>
                등록 된 정보가 없습니다
              </Body2>
            )}

            {car.confirmStatus === 'WAITING' || car.confirmStatus === 'CONFIRM' ? (
              <ModifyDeleteContainer>
                <span
                  onClick={() => {
                    reductionDeleteModal();
                  }}
                >
                  삭제
                </span>
                <LengthDividerSpan>|</LengthDividerSpan>
                <span
                  onClick={() => {
                    carStore.setCarNumber(car.carNumber);
                    navigate('/reductionCategory', {
                      state: {
                        type: 'edit',
                      },
                    });
                  }}
                >
                  수정
                </span>
              </ModifyDeleteContainer>
            ) : (
              <ModifyDeleteContainer>
                <span
                  onClick={() => {
                    carStore.setCarNumber(car.carNumber);
                    navigate('/reductionCategory', {
                      state: {
                        type: 'post',
                      },
                    });
                  }}
                >
                  {'신청 >'}
                </span>
              </ModifyDeleteContainer>
            )}
          </ParkingReductionContainer>
        </div>
        <div
          style={{
            margin: '40px 25px',
            flexDirection: 'column',
          }}
        >
          <CarInfoRegistrationSpan>차량 정보 등록일</CarInfoRegistrationSpan>
          <Body2 weight={'bold'} color={'black3'}>
            {getFormattedDateString(car.regDate)}
          </Body2>
        </div>

        {isReductionDeleteModal && (
          <TwoButtonModal
            isVisible={isReductionDeleteModal}
            onClose={() => setIsReductionDeleteModal(false)}
            title={'감면 정보를 삭제하시겠습니까?'}
            content={''}
            leftButtonText={'취소'}
            rightButtonText={'삭제'}
            rightClick={async () => {
              await taxReductionStore.fetchDeleteTaxReductionInfo({
                mLinkUID: memberUID,
                carNumber: car.carNumber,
              });
              navigate('/carInfo');
            }}
          />
        )}

        {isCarInfoDeleteModal && (
          <TwoButtonModal
            isVisible={isCarInfoDeleteModal}
            onClose={() => setIsCarInfoDeleteModal(false)}
            title={'차량 정보를 삭제하시겠습니까?'}
            content={''}
            leftButtonText={'취소'}
            rightButtonText={'삭제'}
            rightClick={() => {
              showCheckModal(); // 체크 모달 띄우기
            }}
          />
        )}

        {isCheckModalVisible && (
          <CheckModal
            isVisible={isCheckModalVisible}
            onClose={hideCheckModal}
            title={'차량 정보 삭제가 완료되었습니다.'}
            content={''}
            buttonText={'확인'}
          />
        )}
      </Container>
    </MainContainerNoMargin>
  );
};

export default CarInfoEdit;
