import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';

import myPageService from 'service/UserInfo/myPageService';
import { IMyPageData } from 'models/UserInfo/myPageInfoModel';

class MyPageStore {
  myPageInfo: IMyPageData | null = null;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(data: boolean) {
    this.isLoading = data;
  }

  setMyPageInfo(data: IMyPageData) {
    this.myPageInfo = data;
  }

  async fetchGetMyPageInfo(mLinkUID: number) {
    this.setIsLoading(true);

    const requestBody = {
      mLinkUID,
    };

    try {
      const response = await myPageService.getMyPageInfo(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          this.setMyPageInfo(response.data);
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const myPageStore = new MyPageStore();
