import React, { useState, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import {
  WrapMap,
  Sidebar,
  SidebarOpen,
  NonMemberContainer,
  UserIconSize,
  ArrowIconSize,
  UserText,
  BurgerIc,
  NotificationIc,
  AddressContainer,
  MenuTopContainer,
  MenuBottomContainer,
  CoinIc1,
  CoinIc2,
  BottomBannerContainer,
  HandCoinImage,
  ParkingShareSpan,
  ProfitsSpan,
  WrapTabItem,
  WrapTimeTicketCautionDesc,
} from './styles';

import BottomSlideBanner from 'components/atoms/BottomSlideBanner';
import ParkingSearchModal from 'components/atoms/Modal/ParkingSearchModal';
import TwoButtonModal from 'components/atoms/Modal/TwoButtonModal';
import { toggleStore } from 'stores/toggle/toggleStore';
import MinganInfoListBottomModal from 'components/atoms/Modal/MinganListBottomModal';
import GeojuInfoBottomModal from 'components/atoms/Modal/GeojuInfoModal';

import MinganInfoModal from 'components/atoms/Modal/MinganInfoModal';
import useMyPosition from 'hooks/useMyPosition';
import HomeMap from 'containers/home/HomeMap';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Body, Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import ChargingInfoModal from 'components/atoms/Modal/ChargingInfoModal';
import ChargingInfoListModal from 'components/atoms/Modal/ChargingInfoListModal';
import EmptyWaitingModal from 'components/atoms/Modal/EmptyWaitingModal';
import GeojuCautionModal from 'components/atoms/Modal/GeojuCautionModal';
import { chargerListData, monthlyListData } from 'containers/home/HomeMap/mock';
import GeojuPublicInfoModal from 'components/atoms/Modal/GeojuPublicInfoModal';
import MainPopupModal from 'components/atoms/Modal/MainPopupModal';
import { setShared, showWebBrowser } from 'utils/deviceUtils';
import { MyLocation, WrapFilter, WrapFilterCount } from 'containers/home/HomeMap/styles';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { handleChatClick } from 'utils/common';
import ParkingFilterBottomModal from 'components/atoms/Modal/ParkingFilterBottomModal';
import JinjuInfoModal from 'components/atoms/Modal/JinjuInfoModal';
import icBurger from 'assets/ic/ic_burger.svg';
import icArrowRightWhite from 'assets/ic/ic_arrow_right_white.svg';
import icParkingHistory from 'assets/ic/ic_parking_history.svg';
import icShareHistory from 'assets/ic/ic_share_history.svg';
import icCamera from 'assets/ic/ic_camera.svg';
import icNotificationHome from 'assets/ic/ic_notification_home.svg';
import icNotificationSetting from 'assets/ic/ic_notification_setting.svg';
import icSetting from 'assets/ic/ic_setting.svg';
import icArrowRightPurple from 'assets/ic/ic_arrow_right_purple.svg';
import icArrowRightGrayMedium from 'assets/ic/ic_arrow_right_gray_medium.svg';
import icGoldCoin1 from 'assets/ic/ic_gold_coin1.png';
import icGoldCoin2 from 'assets/ic/ic_gold_coin2.png';
import imgHandCoin from 'assets/images/img_hand_coin.png';
import icZooPoint from 'assets/ic/ic_zoomansa_z_logo.svg';
import { colors } from 'styles/colors';
import TimeTicketInfoModal from 'components/atoms/Modal/TimeTicketInfoModal';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import TimeTicketDetailModal from 'components/atoms/Modal/TimeTicketDetailModal';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';
import {
  IcDotGraySVG,
  IcDropdownSVG,
  IcFaqFaceSVG,
  IcFilterSVG,
  IcNotificationSettingSVG,
  IcParkingHistorySVG,
  IcShareHistorySVG,
  IcUserSVG,
  IcZoomansaZLogoSVG,
} from 'assets/ic';
import Divider from 'components/atoms/Divider';
interface MenuItemProps {
  isSelected: boolean;
  onClick: () => void;
  label: string;
}

const menuItems = [
  {
    id: 'share',
    label: '공유주차',
  },
  {
    id: 'daily',
    label: '일주차',
  },

  {
    id: 'monthly',
    label: '월주차',
  },

  {
    id: 'charger',
    label: '충전',
  },
] as const;

const Home = () => {
  const navigate = useNavigate();

  const { position } = useMyPosition();

  const sidebarRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [addressText, setAddressText] = useState('');
  const [positionAddress, setPositionAddress] = useState('');
  const [isLoginRequestModal, setIsLoginRequestModal] = useState(false);
  const [isGeojuInfoModal, setIsGeojuInfoModal] = useState(false);
  const [isMinganInfoListModal, setIsMinganInfoListModal] = useState(false);
  const [isMinganInfoModal, setIsMinganInfoModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('monthly');
  const [selectItem, setSelectItem] = useState<string | number | null>(null);
  const [isChargingModal, setIsChargingModal] = useState(false);
  const [isChargingListModal, setIsChargingListModal] = useState(false);
  const [lat, setLat] = useState('0');
  const [lon, setLon] = useState('0');
  const [isCautionModal, setIsCautionModal] = useState(false);
  const [isGeojuPublicInfo, setIsGeojuPublicInfo] = useState(false);
  const [isInquiry, setIsInquiry] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isJinjuModal, setIsJinjuModal] = useState(false);
  const [isEmptyModal, setIsEmptyModal] = useState(false);
  const [isTimeTicketModal, setIsTimeTicketModal] = useState(false);
  const [isTimeTicketCautionModal, setIsTimeTicketCautionModal] = useState(false);
  const [isTimeTicketDetail, setIsTimeTicketDetail] = useState(false);

  const { phoneNumber, memberData } = userInfoStore;

  const { fetchUserInfo } = useFetchUserInfo();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    console.log('isTimeTicketDetail in useEffect:', isTimeTicketDetail);

    window.checkInfoDailyBottomDialogState = function () {
      console.log('isTimeTicketDetail:', isTimeTicketDetail);
      return isTimeTicketDetail ? 'true' : 'false';
    };

    window.mobileMoveMainFromPayment = function () {
      setIsTimeTicketDetail(false);
    };

    return () => {
      delete window.checkInfoDailyBottomDialogState;
      delete window.mobileMoveMainFromPayment;
    };
  }, [isTimeTicketDetail]);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleMyPageClick = () => {
    navigate('/myInfo');
  };

  const handleNotifyClick = () => {
    {
      /* 로그인이 되어있지 않다면 로그인 요청 모달, 되어 있다면 NotiCenter로 이동 */
    }
    toggleStore.setSelectedIndex(0);
    navigate('/notificationCenter');
  };

  const addressBottomModal = () => {
    setIsAddressModalVisible(true);
  };

  const closeAddressModal = () => {
    setIsAddressModalVisible(false);
  };

  // 햄버거 클릭
  const burgerClick = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const onClose = () => {
    if (isGeojuInfoModal) {
      setIsGeojuInfoModal(false);
    } else if (isJinjuModal) {
      setIsJinjuModal(false);
    } else if (isGeojuPublicInfo) {
      setIsGeojuPublicInfo(false);
    } else if (isTimeTicketModal) {
      setIsTimeTicketModal(false);
    } else if (isChargingModal) {
      setIsChargingModal(false);
    } else if (isChargingListModal) {
      setIsChargingListModal(false);
    } else if (isMinganInfoListModal) {
      setIsMinganInfoListModal(false);
    } else if (isMinganInfoModal) {
      setIsMinganInfoModal(false);
    } else if (isTimeTicketCautionModal) {
      setIsTimeTicketCautionModal(false);
    }
    setSelectItem(null);
  };

  const handelItem = (id: number | string, label: string, lat: string, lon: string) => {
    setTimeout(() => {
      setSelectItem(id);

      setLat(lat);
      setLon(lon);

      if (label === 'zoomansaHourly') {
        setIsGeojuInfoModal(true);
      } else if (label === 'jinju') {
        setIsJinjuModal(true);
      } else if (label === 'public') {
        setIsGeojuPublicInfo(true);
      } else if (label === 'DAILY') {
        setIsTimeTicketModal(true);
      } else if (selectedMenu === 'charger') {
        if (chargerListData.result.length === 1) {
          setIsChargingModal(true);
        } else {
          setIsChargingListModal(true);
        }
      } else if (monthlyListData.slotList.length > 1) {
        setIsMinganInfoListModal(true);
      } else {
        setIsMinganInfoModal(true);
      }
    }, 0);
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node) &&
      backgroundRef.current &&
      !backgroundRef.current.contains(event.target as Node)
    ) {
      setSideBarOpen(false);
    }
  };

  const onSelectAddress = (result: any) => {
    setAddressText(result); // 주소 설정
    setIsAddressModalVisible(false);
  };

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault(); // 기본 우클릭 메뉴 막기
      event.stopPropagation(); // 이벤트 전파 막기
      return false;
    };

    window.addEventListener('contextmenu', handleContextMenu);
    // 데스크탑 환경
    document.addEventListener('mousedown', handleClickOutside);
    // 모바일 환경`
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (position && window.kakao) {
      const geocoder = new window.kakao.maps.services.Geocoder();

      const coords = new window.kakao.maps.LatLng(position.latitude, position.longitude);

      geocoder.coord2Address(coords.getLng(), coords.getLat(), (result: any, status: any) => {
        if (status === window.kakao.maps.services.Status.OK) {
          const address = result[0].address.address_name;
          setPositionAddress(address); // 변환된 주소 상태 저장
        }
      });
    }
  }, [position]);

  // 사이드바 아이템 데이터
  // 리스트 항목의 데이터
  // {/* 로그인이 되어있지 않다면 로그인 요청 모달, 되어 있다면 NotiCenter로 이동 */}

  const items = [
    {
      itemImg: <IcZoomansaZLogoSVG width={24} height={24} />,
      text: '내 포인트',
      onClick: () => navigate('/pointHistory'),
    },
    {
      itemImg: <IcParkingHistorySVG width={24} height={24} stroke={colors.black3} />,
      text: '이용내역',
      onClick: () => navigate('/myParkingMain'),
    },
    {
      itemImg: <IcShareHistorySVG width={24} height={24} />,
      text: '공유내역',
      onClick: () => navigate('/shareHistory'),
      subText: '빈자리 공유하고 수익창출!',
    },
    {
      itemImg: <IcNotificationSettingSVG width={24} height={24} stroke={colors.black3} />,
      text: '알림',
      onClick: () => navigate('/shareHistory'),
    },
  ];

  // 사이드바 아이템 렌더링
  const SideBarItem = ({ index, style }: { index: number; style?: React.CSSProperties }) => {
    const { itemImg, text, onClick, subText } = items[index];
    return (
      <div
        style={{
          ...style,
          padding: '14px 20px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'space-between',
        }}
        onClick={onClick}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {itemImg}
          <SubHeadline weight={'medium'} color={'black3'} style={{ marginLeft: 8 }}>
            {text}
          </SubHeadline>

          <Caption3 weight={'medium'} color={'black3'} style={{ marginLeft: 8, color: '#2378D2' }}>
            {subText}
          </Caption3>
        </div>
      </div>
    );
  };

  const MenuBottomItem: React.FC<MenuItemProps> = ({ isSelected, onClick, label }) => {
    return (
      <WrapTabItem
        onClick={onClick}
        style={{
          backgroundColor:
            isSelected && label === '일주차'
              ? '#8B63BB'
              : isSelected && label === '월주차'
                ? '#0F3D92'
                : isSelected
                  ? '#B799DB33'
                  : '#fff',
          borderColor:
            isSelected && label === '일주차'
              ? '#8B63BB'
              : isSelected && label === '월주차'
                ? '#0F3D92'
                : isSelected
                  ? '#8B63BB'
                  : '#EAEAEA',
        }}
      >
        <Caption1
          weight={'bold'}
          color={
            (isSelected && label === '일주차') || (isSelected && label === '월주차')
              ? 'white'
              : 'gray09'
          }
        >
          {label}
        </Caption1>
      </WrapTabItem>
    );
  };

  const filterCount = 12;

  return (
    <div
      ref={backgroundRef}
      onClick={() => {
        onClose();
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #E4E4E4',
            borderRadius: '3px',
            position: 'fixed',
            top: isIOS ? 60 : 40,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 2,
            backgroundColor: 'white',
            width: 'calc(100% - 40px)',
            maxWidth: 480,
          }}
        >
          <MenuTopContainer>
            <BurgerIc onClick={burgerClick} src={icBurger} alt="Burger Icon" />
            <AddressContainer
              onClick={addressBottomModal}
              style={{
                color: !addressText && !positionAddress ? colors.gray05 : colors.black3,
              }}
            >
              {addressText
                ? addressText
                : positionAddress
                  ? positionAddress
                  : '주소를 검색해주세요'}
              <IcDropdownSVG fill={'black'} style={{ marginLeft: 5 }} />
            </AddressContainer>

            <NotificationIc
              onClick={handleNotifyClick}
              src={icNotificationHome}
              alt="홈화면 알림 아이콘"
            />
          </MenuTopContainer>

          <MenuBottomContainer>
            {menuItems.map((item, index) => (
              <MenuBottomItem
                key={index}
                isSelected={selectedMenu === item.id}
                onClick={() => setSelectedMenu(item.id)}
                label={item.label}
              />
            ))}
          </MenuBottomContainer>
          <WrapFilter
            onClick={e => {
              e.preventDefault();
              setIsFilter(true);
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 44,
                height: 44,
              }}
            >
              <WrapFilterCount>
                <Caption2 weight={'semibold'} color={'white'}>
                  {filterCount}
                </Caption2>
              </WrapFilterCount>
              <IcFilterSVG />
            </div>
          </WrapFilter>
        </div>

        <WrapMap
          onClick={() => {
            if (sideBarOpen) {
              setSideBarOpen(false);
            }
          }}
        >
          <HomeMap
            lat={position.latitude === '' ? 37.5665 : Number(position.latitude)}
            lng={position.longitude === '' ? 126.97 : Number(position.longitude)}
            address={addressText}
            onClick={handelItem}
            selectedMenu={selectedMenu}
            selectItem={selectItem}
            setAddressText={setAddressText}
          />
        </WrapMap>

        {sideBarOpen ? (
          <SidebarOpen
            ref={sidebarRef}
            style={{ display: 'flex', flexDirection: 'column', width: '280px' }}
          >
            <NonMemberContainer>
              <Row justifyContent={'space-between'}>
                <Row>
                  <IcUserSVG style={{ marginRight: 12 }} />
                  <div>
                    {phoneNumber ? (
                      <Caption2 weight={'regular'} color={'white'}>
                        안녕하세요!
                      </Caption2>
                    ) : null}
                    <Row>
                      <UserText
                        onClick={() => {
                          if (phoneNumber) {
                            handleMyPageClick();
                          } else {
                            handleLoginClick();
                          }
                        }}
                      >
                        {phoneNumber ? `${phoneNumber}` : '로그인을 해주세요'}
                      </UserText>
                      {phoneNumber ? (
                        <Caption2 weight={'regular'} color={'white'}>
                          님
                        </Caption2>
                      ) : null}
                    </Row>
                  </div>
                </Row>

                <ArrowIconSize
                  src={icArrowRightWhite}
                  alt="icArrowRightWhite"
                  style={{
                    marginLeft: 6,
                  }}
                />
              </Row>
            </NonMemberContainer>

            <div
              style={{
                background: '#FFF',
                height: '100%',
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderTopLeftRadius: 20,
                width: '100%',
              }}
            >
              <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <div style={{ marginTop: 20 }}>
                  {items.map((_, index) => (
                    <SideBarItem key={index} index={index} />
                  ))}
                </div>

                <Divider
                  style={{
                    margin: '13px 20px',
                  }}
                />
                <div>
                  <div
                    style={{ padding: '14px 20px' }}
                    onClick={() => {
                      navigate('/notice');
                    }}
                  >
                    <Caption2 weight={'medium'} color={'gray05'}>
                      공지사항
                    </Caption2>
                  </div>
                  <div
                    style={{ padding: '14px 20px' }}
                    onClick={() => {
                      navigate('/setting');
                    }}
                  >
                    <Caption2 weight={'medium'} color={'gray05'}>
                      설정
                    </Caption2>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: '63px' }}>
                <CoinIc1 src={icGoldCoin1} alt="코인 이미지1" />
              </div>
              <CoinIc2 src={icGoldCoin2} alt="코인 이미지2" />

              <BottomBannerContainer
                onClick={() => window.open('http://hostlanding.zoomansa.com/?type=25', '_blank')}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '16px' }}>
                    <HandCoinImage src={imgHandCoin} alt="핸드코인 이미지" />
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      marginLeft: '18px',
                      color: '#1A1A1A',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      fontSize: 14,
                    }}
                  >
                    <Row>
                      <ParkingShareSpan>빈 주차장 공유</ParkingShareSpan>
                      <ProfitsSpan>하고</ProfitsSpan>
                    </Row>
                    <ProfitsSpan>매달 수익금 받아가세요!</ProfitsSpan>
                  </div>
                </div>
              </BottomBannerContainer>
            </div>
          </SidebarOpen>
        ) : (
          <Sidebar ref={sidebarRef}>
            <div>Sidebar Content</div>
          </Sidebar>
        )}

        <BottomSlideBanner />

        <TwoButtonModal
          isVisible={isLoginRequestModal}
          onClose={() => setIsLoginRequestModal(false)}
          title={'공유 신청이 완료되었습니다.'}
          content={<div>로그인을 진행후 이용해주세요.</div>}
          leftButtonText={'닫기'}
          rightButtonText={'로그인'}
          rightClick={() => {
            navigate('/');
          }}
        />

        <MinganInfoListBottomModal
          isVisible={isMinganInfoListModal}
          onClose={() => {
            setIsMinganInfoListModal(false);
          }}
          onOpenAnotherModal={() => {
            setIsMinganInfoListModal(false);
            setIsMinganInfoModal(true);
          }}
          setIsEmptyModal={setIsEmptyModal}
        />

        <MinganInfoModal
          isVisible={isMinganInfoModal}
          onClose={() => {
            setIsMinganInfoModal(false);
          }}
          setIsEmptyModal={setIsEmptyModal}
        />

        <ChargingInfoListModal
          isVisible={isChargingListModal}
          onClose={() => {
            setIsChargingListModal(false);
            setSelectItem(null);
          }}
          setIsChargingModal={setIsChargingModal}
        />
        <ChargingInfoModal
          isVisible={isChargingModal}
          onClose={() => {
            setIsChargingModal(false);
            setSelectItem(null);
          }}
        />

        <TimeTicketInfoModal
          isVisible={isTimeTicketModal}
          onClose={() => {
            setIsTimeTicketModal(false);
            setIsTimeTicketCautionModal(true);
            // setSelectItem(null);
          }}
        />

        <CustomBottomModal
          isVisible={isTimeTicketCautionModal}
          onClose={() => {
          }}
          title1={'구매 전 꼭 확인해 주세요!'}
          description={
            <div>
              <WrapTimeTicketCautionDesc>
                <Row>
                  <IcDotGraySVG />
                  <Body weight={'bold'} color={'primary'} style={{ margin: '0px 4px 0px 8px' }}>
                    입차 후 구매 시
                  </Body>
                  <Body weight={'semibold'} color={'gray08'}>
                    주차권 적용 불가
                  </Body>
                </Row>
                <Row style={{ marginTop: 8 }}>
                  <IcDotGraySVG />
                  <Body weight={'bold'} color={'primary'} style={{ margin: '0px 4px 0px 8px' }}>
                    2개 이상 주차권
                  </Body>
                  <Body weight={'semibold'} color={'gray08'}>
                    연속 사용 불가
                  </Body>
                </Row>
              </WrapTimeTicketCautionDesc>
              <Row>
                <IcDotGraySVG />
                <Caption3 weight={'regular'} color={'gray08'} style={{ marginLeft: 8 }}>
                  유의사항을 확인하지 않고 발생한 피해는 책임지지 않습니다.
                </Caption3>
              </Row>

              <Row style={{ marginTop: 5 }}>
                <IcDotGraySVG />
                <Caption3 weight={'regular'} color={'gray08'} style={{ marginLeft: 8 }}>
                  만차 혹은 현장 사정에 따라 주차가 어려울 수 있습니다.
                </Caption3>
              </Row>
            </div>
          }
          leftText={'확인했어요'}
          leftClick={() => {
            setSelectItem(null);
            setIsTimeTicketDetail(true);
          }}
        />

        <TimeTicketDetailModal
          isVisible={isTimeTicketDetail}
          onClose={() => {
            setIsTimeTicketDetail(false);
          }}
        />

        <GeojuInfoBottomModal
          isVisible={isGeojuInfoModal}
          onClose={() => {
            setIsGeojuInfoModal(false);
            setSelectItem(null);
          }}
          lat={lat}
          lon={lon}
          setIsCautionModal={setIsCautionModal}
        />

        <GeojuCautionModal
          isVisible={isCautionModal}
          onClose={() => {
            setIsCautionModal(false);
          }}
        />

        <GeojuPublicInfoModal
          isVisible={isGeojuPublicInfo}
          onClose={() => {
            setIsGeojuPublicInfo(false);
            setSelectItem(null);
          }}
          lat={lat}
          lon={lon}
        />

        <JinjuInfoModal
          isVisible={isJinjuModal}
          onClose={() => {
            setIsJinjuModal(false);
            setSelectItem(null);
          }}
          lat={lat}
          lon={lon}
        />

        <MainPopupModal />

        {!isMinganInfoModal ? (
          <MyLocation
            onClick={e => {
              e.preventDefault();
              setIsInquiry(true);
            }}
            style={{
              bottom: 185,
            }}
          >
            <IcFaqFaceSVG />
          </MyLocation>
        ) : null}
        <PopupModal
          isVisible={isInquiry}
          onClose={() => {
            setIsInquiry(false);
          }}
          title1={
            <SubHeadline weight={'medium'} color={'black3'} style={{ textAlign: 'left' }}>
              도움이 필요하신가요?
            </SubHeadline>
          }
          description={
            <Caption1 weight={'regular'} color={'gray08'} style={{ textAlign: 'left' }}>
              "주만사 월주차" 카톡 채널에 문의 내용을 남겨 주세요. 평일 09시~18시에 확인 후
              순차적으로 답변 드려요.
            </Caption1>
          }
          leftClick={() => {
            setIsInquiry(false);
          }}
          leftText={'취소'}
          rightClick={() => {
            handleChatClick();
          }}
          rightText={'문의하러 가기'}
          titleAlign={'flex-start'}
        />

        <ParkingFilterBottomModal
          isVisible={isFilter}
          onClose={() => {
            setIsFilter(false);
          }}
        />

        <EmptyWaitingModal
          isVisible={isEmptyModal}
          onClose={() => {
            setIsEmptyModal(false);
          }}
        />
        <ParkingSearchModal
          isVisible={isAddressModalVisible}
          onClose={closeAddressModal}
          onSelectAddress={onSelectAddress}
        />
      </div>
    </div>
  );
};

export default Home;
