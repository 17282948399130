import React, { useEffect, useRef, useState } from 'react';

import { Container, ImageTextContainer, TopParkingInfoSpan } from './styles';
import { Caption1, Caption2, Headline } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { openNavi } from 'utils/common';
import Row from 'components/atoms/Row';
import { IcDirections16SVG, IcLocationGraySVG, IcLocationSVG, IcNoteSVG } from 'assets/ic';
import { dailyDetail } from 'containers/home/HomeMap/mock';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

import icLocation from 'assets/ic/ic_location_gray.svg';
import icCallGray from 'assets/ic/ic_call_gray.svg';

const TimeTicketBasicInfo = () => {
  const { memberData } = userInfoStore;

  const data = dailyDetail;

  return (
    <Container>
      <Row alignItems={'flex-start'} justifyContent={'space-between'}>
        <Headline weight={'bold'} color={'black1'}>
          {data.lotName}
        </Headline>
        <Button
          backgroundColor={'#fff'}
          radius={16}
          style={{
            height: 32,
          }}
          borderColor={'#CCC'}
          onClick={() => {
            const name = dailyDetail.lotName;
            const lat = data.latitude;
            const lng = data.longitude;
            openNavi(String(lat), String(lng), name, memberData?.naviType ?? '');
          }}
        >
          <Row>
            <IcDirections16SVG fill={'#1D75CA'} />
            <Caption2 weight={'semibold'} color={'gray07'} style={{ marginLeft: 4 }}>
              길찾기
            </Caption2>
          </Row>
        </Button>
      </Row>

      <ImageTextContainer>
        <IcLocationGraySVG width={16} height={16} />
        <TopParkingInfoSpan>{data.jibunLotAddr}</TopParkingInfoSpan>
        <Caption2
          weight={'medium'}
          color={'blue'}
          style={{ marginLeft: 4 }}
          onClick={() => {
            //https 만 적용
            window.navigator.clipboard.writeText(data.jibunLotAddr);
          }}
        >
          복사
        </Caption2>
      </ImageTextContainer>
      <ImageTextContainer>
        <IcNoteSVG width={16} height={16} />
        <Caption2 weight={'regular'} color={'gray08'} style={{ marginLeft: 7 }}>
          제휴 | 카드결제
        </Caption2>
      </ImageTextContainer>
    </Container>
  );
};

export default TimeTicketBasicInfo;
