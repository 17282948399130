import React from 'react';
import {
  ContentsContainer,
  ModalOverlay,
  PurpleOneButtonCheckModalButton,
  PurpleOneButtonCheckModalContainer,
  PurpleOneButtonCheckModalTitleSpan,
  TitleContainer,
} from './styles';

interface PurpleOneButtonCheckModal {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  buttonText: string;
  buttonClick?: () => void;
}

const PurpleOneButtonCheckModal: React.FC<PurpleOneButtonCheckModal> = ({
  isVisible,
  onClose,
  title,
  content,
  buttonText,
  buttonClick
}) => {
  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <PurpleOneButtonCheckModalContainer onClick={e => e.stopPropagation()}>
          <TitleContainer>
            <PurpleOneButtonCheckModalTitleSpan>{title}</PurpleOneButtonCheckModalTitleSpan>
          </TitleContainer>
          <ContentsContainer>{content}</ContentsContainer>
          <PurpleOneButtonCheckModalButton onClick={() => {
            onClose()
            if (buttonClick) {
              buttonClick()
            }
          }}>
            {buttonText}
          </PurpleOneButtonCheckModalButton>
        </PurpleOneButtonCheckModalContainer>
      </ModalOverlay>
    </>
  );
};

export default PurpleOneButtonCheckModal;
