import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { colors } from 'styles/colors';
import { observer } from 'mobx-react';
import MyParkingUseList from 'containers/myParkingTicket/list/MyParkingUseList';
import { JSP_URL } from 'constants/baseConstants';
import { moveJSP } from 'utils/common';

const MyParkingMainPage = () => {
  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBackPress = () => {
    moveJSP();
    // navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleBackPress();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin style={{ backgroundColor: colors.gray10, height: `${windowHeight}px` }}>
      <Title
        title="나의 주차권"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          handleBackPress();
        }}
        rightTextButtonVisible={true}
        rightOnClick={() => {
          navigate('/myParkingPastHistory');
        }}
        background={'lightGray'}
        rightTitle={'지난 내역'}
        titleColor={'darkPurple'}
      />
      <MyParkingUseList />
    </MainContainerNoMargin>
  );
};

export default MyParkingMainPage;
