import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AgreeContainer,
  CautionContainer,
  TerraceCautionColumnContainer,
  TerraceCautionContainer,
  TerraceCautionIcon,
  TerraceTowerContainer,
  ViewDivide1,
  ViewDivide2,
  WarningContainer,
} from '../styles';
import icWarningPurple from 'assets/ic/ic_warning_purple.svg';
import { CautionTitleContainer, ExclamationBlackIc } from '../TerraceTowerDetail/styles';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import {
  AgreeButton,
  BottomButtonContainer,
  RequiredText,
  StyledCheckbox,
  TerraceCautionsContentsContainer,
  TimeTicketAllContentsContainer,
  TimeTicketCautionBottomContentsContainer,
} from './styles';
import { webViewClose } from 'utils/deviceUtils';
import { terraceTowerProductStore } from 'stores/store/TerraceTower/terraceTowerProductStore';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { colors } from 'styles/colors';
import TimeTicketCautionsComponent from 'containers/timeTicket/TimeTicketCautions/TimeTicketCautionsComponent';
import { MainContainerNoMargin } from 'components/styles';
import NonSafeAreaTitle from 'components/atoms/Title/NonSafeAreaTitle';

import icCarCaution from 'assets/ic/ic_car_caution.svg';
import icSteeringWheel from 'assets/ic/ic_steering_wheel_caution.svg';
import { generateTimeSlots } from 'utils/timeUtils';
import PopupModal from 'components/atoms/Modal/PopupModal';

const TerraceTowerReservationCautionPage = observer(() => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isPaymentFail, setIsPaymentFail] = useState(false);

  const handleAgreeClick = () => {
    setIsChecked(prev => !prev);
  };

  const { productName, cautions, operationTime } = terraceTowerProductStore.terraceTowerOrder || {};

  useEffect(() => {
    const handleSetProductUIDEvent = (event: CustomEvent) => {
      const productUID = event.detail;

      if (productUID) {
        console.log('Received productUID:', productUID);

        sessionStorage.setItem('productUID', productUID);

        const fetchTerraceTowerProduct = async () => {
          await terraceTowerProductStore.fetchTerraceTowerProduct(productUID);
          console.log(productUID);
        };
        fetchTerraceTowerProduct().catch(error => {
          console.log('주문정보 불러오는 중 에러', error);
          alert(error);
        });
      } else {
        console.error('No productUID found in event');
      }
    };
    window.addEventListener('setProductUID', handleSetProductUIDEvent as EventListener);

    return () => {
      window.removeEventListener('setProductUID', handleSetProductUIDEvent as EventListener);
    };
  }, []);

  const handleButtonClick = () => {
    if (operationTime) {
      const [startTime, endTime] = operationTime.split('~');
      if (generateTimeSlots(startTime, endTime).length === 1) {
        setIsPaymentFail(true);
      } else {
        if (isChecked) {
          sessionStorage.setItem('carNumber', '');
          sessionStorage.setItem('isNotFocus', 'false');
          sessionStorage.setItem('isPolicyPersonalCheck', 'false');
          navigate('/terraceTowerPayment');
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsChecked(true);
  }, []);

  const handleWebViewClose = () => {
    webViewClose();
  };

  return (
    <MainContainerNoMargin>
      <NonSafeAreaTitle
        title="유의사항"
        leftArrowButtonVisible={true}
        leftOnClick={handleWebViewClose}
      />
      <TimeTicketAllContentsContainer>
        <TerraceCautionContainer>
          <TerraceCautionColumnContainer>
            <Headline weight={'bold'} style={{ color: colors.primary }}>
              {productName ? productName : ''}
            </Headline>
            <Headline weight={'bold'} style={{ color: colors.black3 }}>
              유의사항을 확인해 주세요
            </Headline>
          </TerraceCautionColumnContainer>
          <TerraceCautionIcon src={icWarningPurple} alt={'보라색 경고'} />
        </TerraceCautionContainer>

        <TerraceTowerContainer>
          <WarningContainer>
            <img src={icCarCaution} alt={'첫번째 경고 이미지'} width="50px" height="50px" />
            <TerraceCautionColumnContainer>
              <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>
                만차 및 현장 사정으로
              </Caption2>
              <Caption1 weight={'semibold'}>주차가 어려울 수 있습니다</Caption1>
            </TerraceCautionColumnContainer>
          </WarningContainer>
          <WarningContainer>
            <img src={icSteeringWheel} alt={'두번째 경고 이미지'} width="50px" height="50px" />
            <TerraceCautionColumnContainer>
              <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>
                유의사항 미숙지로 발생된 피해는
              </Caption2>
              <Caption1 weight={'semibold'}>운전자에게 책임이 있습니다</Caption1>
            </TerraceCautionColumnContainer>
          </WarningContainer>
        </TerraceTowerContainer>
        <ViewDivide1 />

        <TerraceCautionsContentsContainer>
          <CautionTitleContainer>
            <ExclamationBlackIc src={icExclamationBlack} alt={'검정 배경 느낌표'} />
            <SubHeadline weight={'bold'} style={{ color: colors.black3, marginLeft: 10 }}>
              주차권 유의사항
            </SubHeadline>
          </CautionTitleContainer>
          <ViewDivide2 />
          <TimeTicketCautionsComponent cautions={cautions} />
        </TerraceCautionsContentsContainer>
      </TimeTicketAllContentsContainer>

      <TimeTicketCautionBottomContentsContainer>
        <CautionContainer style={{ margin: '0 20px' }}>
          <Caption3 weight={'regular'} css={{ color: colors.gray06 }}>
            유의사항 미숙지로 인해 발생한 피해와 주차장 내에서
          </Caption3>
          <Caption3 weight={'regular'} css={{ color: colors.gray06 }}>
            발생한 사고에 대해서는 일체 책임지지 않습니다.
          </Caption3>
        </CautionContainer>
        <AgreeContainer onClick={handleAgreeClick}>
          <StyledCheckbox type="checkbox" checked={isChecked} readOnly />
          <RequiredText>(필수)</RequiredText>
          <Caption2 weight={'regular'} style={{ color: colors.gray09 }}>
            주차권 유의사항 동의
          </Caption2>
        </AgreeContainer>
        <BottomButtonContainer onClick={handleButtonClick}>
          <AgreeButton state={isChecked ? 'agreeCheck' : 'notCheck'}>
            유의사항 확인했어요
          </AgreeButton>
        </BottomButtonContainer>
      </TimeTicketCautionBottomContentsContainer>

      <PopupModal
        isVisible={isPaymentFail}
        onClose={() => {
          setIsPaymentFail(false);
        }}
        title1={'결제 가능한 시간이 없습니다'}
        leftClick={() => {
          setIsPaymentFail(false);
        }}
        leftText={'확인'}
      />
    </MainContainerNoMargin>
  );
});

export default TerraceTowerReservationCautionPage;
