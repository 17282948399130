import icArrowRightPurple from '../../../assets/ic/ic_arrow_right_purple.svg';
import React from 'react';
import icArrowRightGrayMedium from '../../../assets/ic/ic_arrow_right_gray_medium.svg';
import { useNavigate } from 'react-router-dom';
import {
  ArrowIcon,
  TitleSpan,
  PhoneNumberContainer,
  AppVersionContainer,
  ListItemContainer,
  SettingAppInfoContainer,
  SectionHeaderContainer,
  DescSpan,
} from './styles';
import { IcCall16SVG, IcCallSVG } from 'assets/ic';
import { setDial } from 'utils/deviceUtils';
import { Caption1 } from 'styles/typography';

// 비회원 유저라면 회원탈퇴 gone 처리 필요
// 앱 버전은 추후 핸들러 이용해서 불러오자
const SettingAppInfoList = () => {
  const navigate = useNavigate();

  const appInfoItems = [
    {
      textTitle: '탈퇴하기',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/deleteAccountCert'),
    },

    {
      textTitle: '앱 버전',
      textAppVersion: '1.0.0',
    },
  ];

  return (
    <>
      <SectionHeaderContainer></SectionHeaderContainer>
      <SettingAppInfoContainer>
        {appInfoItems.map((item, index) => {
          const { textTitle, imgArrowLeft, textAppVersion, onClick } = item;
          const shouldShowAppVersion = textTitle === '앱 버전';

          return (
            <ListItemContainer
              key={index}
              onClick={onClick}
              style={{ cursor: onClick ? 'pointer' : 'default' }}
            >
              <div style={{ flex: 1 }}>
                <Caption1 weight={'regular'} color={'gray09'}>
                  {textTitle}
                </Caption1>
              </div>
              {shouldShowAppVersion ? (
                <AppVersionContainer>
                  <DescSpan>{textAppVersion}</DescSpan>
                </AppVersionContainer>
              ) : (
                <ArrowIcon src={imgArrowLeft} alt="애로우 아이콘" />
              )}
            </ListItemContainer>
          );
        })}
      </SettingAppInfoContainer>
    </>
  );
};

export default SettingAppInfoList;
