import React, { useEffect, useState } from 'react';
import {
  Dialog,
  RecentSearchContainer,
  RecentSearchHeader,
  RecentSearchItem,
  SearchBar,
  SearchInput,
  Container,
  Text,
  WrapItem,
  WrapResult,
  WrapSeeMore,
  WrapParkingNumberDesc,
  WrapBadge,
} from './styles'; // 경로는 실제 파일 위치에 맞게 조정하세요
import Title from '../../Title/PageTitle';
import { Caption1, Caption2, Caption3, Caption4 } from 'styles/typography';

import Divider from 'components/atoms/Divider';
import Row from 'components/atoms/Row';
import icArrowRightGrayMedium from 'assets/ic/ic_arrow_right_gray_medium.svg';
import { MainContainerNoMargin } from 'components/styles';
import { IcCloseBlack28SVG, IcSearchSVG, IcSearchTipSVG } from 'assets/ic';
import TabBarV2 from 'components/atoms/TabBarV2';

// Props 타입 정의
interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectAddress: (address: string) => void;
}

const ParkingSearchModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  onSelectAddress,
}) => {
  const [recentSearch, setRecentSearch] = useState<any[]>([]); // 배열로 초기화
  const [value, setValue] = useState<string>(''); // 추가된 상태
  const [places, setPlaces] = useState<any[]>([]);
  const [visiblePlacesCount, setVisiblePlacesCount] = useState<number>(5);
  const [address, setAddress] = useState<any[]>([]);
  const [visibleAddressCount, setVisibleAddressCount] = useState<number>(5);
  const [currentTab, setCurrentTab] = useState(0);

  const [parkingValue, setParkingValue] = useState('');
  const [parkingRecentSearch, setParkingRecentSearch] = useState<any[]>([]);

  const tab = [
    {
      name: '주소/장소',
      content: '',
    },
    {
      name: '주차장 번호',
      content: '',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const recentSearch = sessionStorage.getItem('recentSearch');

      if (recentSearch) {
        setRecentSearch(JSON.parse(recentSearch));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (value !== '' && window.kakao) {
      let ps = new window.kakao.maps.services.Places();
      const geocoder = new window.kakao.maps.services.Geocoder();

      geocoder.addressSearch(value, searchResultAddr);
      ps.keywordSearch(value, placesSearchCB);
      // @ts-ignore
      function placesSearchCB(data: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          setPlaces(data);
        }
      }

      // @ts-ignore
      function searchResultAddr(result: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          setAddress(result);
        }
      }
    }
  }, [value]);

  const handleShowMoreAddress = () => {
    setVisibleAddressCount(prevCount => prevCount + 5); // 5개씩 추가로 보여주기
  };

  const handleShowMorePlaces = () => {
    setVisiblePlacesCount(prevCount => prevCount + 5); // 5개씩 추가로 보여주기
  };

  const handleTabClick = (index: number) => {
    setCurrentTab(index);
  };

  const saveRecentSearch = (item: any) => {
    const existingSearches = sessionStorage.getItem('recentSearch');

    // existingSearches가 null이 아니고 배열이라면 파싱하고, 아니면 빈 배열로 초기화
    let recentSearches = existingSearches ? JSON.parse(existingSearches) : [];

    // 만약 recentSearches가 배열이 아니라면 빈 배열로 초기화

    if (!Array.isArray(recentSearches)) {
      recentSearches = [];
    }

    recentSearches = recentSearches.filter(
      (search: any) => search.address_name !== item.address_name,
    );

    // 새 항목을 배열의 가장 앞에 추가
    recentSearches.unshift({
      address_name: item.address_name ?? '',
      place_name: item.place_name ?? '',
    });

    // 최대 저장 항목 수를 제한하려면 아래처럼 할 수 있음 (예: 10개로 제한)
    if (recentSearches.length > 10) {
      recentSearches = recentSearches.slice(0, 10); // 10개 초과하면 오래된 항목 삭제
    }
    setRecentSearch(recentSearches);
    // updated 최근 검색을 다시 sessionStorage에 저장
    sessionStorage.setItem('recentSearch', JSON.stringify(recentSearches));
    init();
  };

  const saveParkingRecentSearch = (item: any) => {
    const existingSearches = sessionStorage.getItem('parkingRecentSearch');

    // existingSearches가 null이 아니고 배열이라면 파싱하고, 아니면 빈 배열로 초기화
    let recentSearches = existingSearches ? JSON.parse(existingSearches) : [];

    // 만약 recentSearches가 배열이 아니라면 빈 배열로 초기화

    if (!Array.isArray(recentSearches)) {
      recentSearches = [];
    }

    recentSearches = recentSearches.filter(
      (search: any) => search.address_name !== item.address_name,
    );

    // 새 항목을 배열의 가장 앞에 추가
    recentSearches.unshift({
      address_name: item.address_name ?? '',
      place_name: item.place_name ?? '',
    });

    // 최대 저장 항목 수를 제한하려면 아래처럼 할 수 있음 (예: 10개로 제한)
    if (recentSearches.length > 10) {
      recentSearches = recentSearches.slice(0, 10); // 10개 초과하면 오래된 항목 삭제
    }
    setParkingRecentSearch(recentSearches);
    // updated 최근 검색을 다시 sessionStorage에 저장
    sessionStorage.setItem('parkingRecentSearch', JSON.stringify(recentSearches));
    init();
  };

  const deleteRecentSearch = (address_name: string) => {
    // 기존 recentSearch 데이터를 불러옴
    const existingSearches = sessionStorage.getItem('recentSearch');
    let recentSearches = existingSearches ? JSON.parse(existingSearches) : [];

    // 해당 address_name을 가진 항목 삭제
    recentSearches = recentSearches.filter((search: any) => search.address_name !== address_name);
    // 업데이트된 검색어 목록을 sessionStorage에 다시 저장
    setRecentSearch(recentSearches);
    sessionStorage.setItem('recentSearch', JSON.stringify(recentSearches));
  };

  const deleteParkingRecentSearch = (address_name: string) => {
    // 기존 recentSearch 데이터를 불러옴
    const existingSearches = sessionStorage.getItem('parkingRecentSearch');
    let recentSearches = existingSearches ? JSON.parse(existingSearches) : [];
    // 해당 address_name을 가진 항목 삭제
    recentSearches = recentSearches.filter((search: any) => search.address_name !== address_name);

    // 업데이트된 검색어 목록을 sessionStorage에 다시 저장
    setParkingRecentSearch(recentSearches);
    sessionStorage.setItem('parkingRecentSearch', JSON.stringify(parkingRecentSearch));
  };

  const init = () => {
    setValue('');
    setPlaces([]);
    setAddress([]);
    setParkingValue('');
  };

  if (currentTab === 0)
    return (
      <>
        <Dialog isVisible={isVisible}>
          <MainContainerNoMargin>
            <Title
              title="주차장 검색"
              leftArrowButtonVisible={true}
              leftOnClick={onClose}
              rightTextButtonVisible={false}
            />
            <Container>
              <div style={{ width: '100%' }}>
                <TabBarV2 menuArr={tab} currentTab={currentTab} onClick={handleTabClick} />
              </div>

              <SearchBar>
                <SearchInput
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  placeholder="희망하는 주소 또는 장소 검색"
                />
                <IcSearchSVG />
              </SearchBar>
              <Divider style={{ height: 6, backgroundColor: '#F2F2F2', width: '100%' }} />
              {recentSearch.length && value === '' ? (
                <RecentSearchContainer>
                  <RecentSearchHeader>
                    <Caption2 weight={'medium'} color={'gray06'}>
                      최근 검색
                    </Caption2>
                    <button
                      style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                      onClick={() => {
                        sessionStorage.removeItem('recentSearch');
                        setRecentSearch([]);
                      }}
                    >
                      <Caption2 weight={'medium'} color={'blue'}>
                        모두 삭제
                      </Caption2>
                    </button>
                  </RecentSearchHeader>
                  {recentSearch.map((item, index) => (
                    <RecentSearchItem
                      key={index}
                      onClick={() => {
                        onClose();
                        saveRecentSearch(item);
                        onSelectAddress(item.address_name);
                      }}
                    >
                      <div>
                        <Caption1 weight={'medium'} color={'black1'}>
                          {item?.place_name !== '' ? item?.place_name : item?.address_name}
                        </Caption1>

                        {item?.place_name ? (
                          <Text style={{ marginTop: 4 }}>{item?.address_name}</Text>
                        ) : null}
                      </div>

                      <IcCloseBlack28SVG
                        onClick={e => {
                          e.stopPropagation();
                          deleteRecentSearch(item.address_name);
                        }}
                      />
                    </RecentSearchItem>
                  ))}
                </RecentSearchContainer>
              ) : value.length ? (
                <>
                  {address.length ? (
                    <WrapResult>
                      <Caption3
                        weight={'regular'}
                        color={'primary'}
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        주소 결과
                      </Caption3>

                      {address.slice(0, visibleAddressCount).map((el, index) => {
                        return (
                          <WrapItem
                            key={index}
                            onClick={() => {
                              onClose();
                              saveRecentSearch(el);
                              onSelectAddress(el.address_name);
                            }}
                          >
                            <Row justifyContent={'space-between'}>
                              <Caption3 weight={'bold'} color={'black1'}>
                                {el.address_name}
                              </Caption3>
                              <img
                                src={icArrowRightGrayMedium}
                                alt={'애로우 아이콘'}
                                style={{ width: 10, height: 14 }}
                              />
                            </Row>
                          </WrapItem>
                        );
                      })}
                      {visibleAddressCount < address.length ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <WrapSeeMore onClick={handleShowMoreAddress}>
                            <Caption2 weight={'semibold'} color={'gray07'}>
                              주소 결과 더보기
                            </Caption2>
                          </WrapSeeMore>
                        </div>
                      ) : null}
                    </WrapResult>
                  ) : null}

                  {places.length ? (
                    <WrapResult>
                      <Caption3
                        weight={'regular'}
                        color={'primary'}
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        장소 결과
                      </Caption3>
                      {places.slice(0, visiblePlacesCount).map((el, index) => {
                        return (
                          <WrapItem
                            key={index}
                            onClick={() => {
                              onClose();
                              saveRecentSearch(el);
                              onSelectAddress(el.address_name);
                            }}
                          >
                            <Caption3 weight={'bold'} color={'black1'}>
                              {el.place_name}
                            </Caption3>
                            <Row justifyContent={'space-between'} style={{ marginTop: 4 }}>
                              <Row>
                                <Text>{el.address_name}</Text>
                              </Row>
                              <img
                                src={icArrowRightGrayMedium}
                                alt={'애로우 아이콘'}
                                style={{ width: 10, height: 14 }}
                              />
                            </Row>
                          </WrapItem>
                        );
                      })}
                      {visiblePlacesCount < places.length ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <WrapSeeMore onClick={handleShowMorePlaces}>
                            <Caption2 weight={'semibold'} color={'gray07'}>
                              장소 결과 더보기
                            </Caption2>
                          </WrapSeeMore>
                        </div>
                      ) : null}
                    </WrapResult>
                  ) : null}
                </>
              ) : (
                <Caption2
                  weight={'regular'}
                  color={'gray06'}
                  style={{ textAlign: 'center', marginTop: 32 }}
                >
                  최근 검색이 없어요
                </Caption2>
              )}
            </Container>
          </MainContainerNoMargin>
        </Dialog>
      </>
    );

  return (
    <>
      <Dialog isVisible={isVisible}>
        <MainContainerNoMargin>
          <Title
            title="주차장 검색"
            leftArrowButtonVisible={true}
            leftOnClick={onClose}
            rightTextButtonVisible={false}
          />
          <Container>
            <div style={{ width: '100%' }}>
              <TabBarV2 menuArr={tab} currentTab={currentTab} onClick={handleTabClick} />
            </div>

            <SearchBar>
              <SearchInput
                value={parkingValue}
                onChange={e => setParkingValue(e.target.value)}
                placeholder="주차장 번호 검색(숫자만 입력)"
                type={'number'}
              />
              <IcSearchSVG />
            </SearchBar>
            <Divider style={{ height: 6, backgroundColor: '#F2F2F2', width: '100%' }} />
            {parkingRecentSearch.length && parkingValue === '' ? (
              <RecentSearchContainer>
                <RecentSearchHeader>
                  <Caption2 weight={'medium'} color={'gray06'}>
                    최근 검색
                  </Caption2>
                  <button
                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                    onClick={() => {
                      sessionStorage.removeItem('parkingRecentSearch');
                      setParkingRecentSearch([]);
                    }}
                  >
                    <Caption2 weight={'medium'} color={'blue'}>
                      모두 삭제
                    </Caption2>
                  </button>
                </RecentSearchHeader>
                {parkingRecentSearch.map((item, index) => (
                  <RecentSearchItem
                    key={index}
                    onClick={() => {
                      onClose();
                      saveParkingRecentSearch(item);
                      onSelectAddress(item.address_name);
                    }}
                  >
                    <div>
                      <Caption1 weight={'medium'} color={'black1'}>
                        {item?.place_name !== '' ? item?.place_name : item?.address_name}
                      </Caption1>

                      {item?.place_name ? (
                        <Text style={{ marginTop: 4 }}>{item?.address_name}</Text>
                      ) : null}
                    </div>

                    <IcCloseBlack28SVG
                      onClick={e => {
                        e.stopPropagation();
                        deleteParkingRecentSearch(item.address_name);
                      }}
                    />
                  </RecentSearchItem>
                ))}
              </RecentSearchContainer>
            ) : value.length ? (
              <>
                {address.length ? (
                  <WrapResult>
                    <Caption3
                      weight={'regular'}
                      color={'primary'}
                      style={{
                        marginBottom: 8,
                      }}
                    >
                      주차장 결과
                    </Caption3>

                    {address.slice(0, visibleAddressCount).map((el, index) => {
                      return (
                        <WrapItem
                          key={index}
                          onClick={() => {
                            onClose();
                            saveParkingRecentSearch(el);
                            onSelectAddress(el.address_name);
                          }}
                        >
                          <Row justifyContent={'space-between'}>
                            <Caption3 weight={'bold'} color={'black1'}>
                              {el.address_name}
                            </Caption3>
                            <img
                              src={icArrowRightGrayMedium}
                              alt={'애로우 아이콘'}
                              style={{ width: 10, height: 14 }}
                            />
                          </Row>
                        </WrapItem>
                      );
                    })}
                  </WrapResult>
                ) : null}
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                }}
              >
                <Caption2
                  weight={'regular'}
                  color={'gray06'}
                  style={{ textAlign: 'center', marginTop: 32 }}
                >
                  최근 검색이 없어요
                </Caption2>

                <WrapParkingNumberDesc>
                  <WrapBadge>
                    <Row>
                      <IcSearchTipSVG />
                      <Caption3 weight={'medium'} color={'gray09'} style={{ marginLeft: 4 }}>
                        주차장 번호란?
                      </Caption3>
                    </Row>
                  </WrapBadge>
                  <Row
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Caption3 weight={'regular'} color={'gray09'}>
                      월{' '}
                    </Caption3>
                    <Caption3 weight={'semibold'} color={'gray09'}>
                      정기권
                    </Caption3>
                    <Caption3 weight={'regular'} color={'gray09'}>
                      으로 판매 되는{' '}
                    </Caption3>
                    <Caption3 weight={'semibold'} color={'gray09'}>
                      공유 주차장 번호
                    </Caption3>
                    <Caption3 weight={'regular'} color={'gray09'}>
                      입니다.
                    </Caption3>
                  </Row>

                  <Row
                    style={{
                      whiteSpace: 'pre-wrap',
                      marginTop: 4,
                    }}
                  >
                    <Caption3 weight={'regular'} color={'gray09'}>
                      예){' '}
                    </Caption3>
                    <Caption3 weight={'regular'} color={'gray09'}>
                      12345번 주차장은 '
                    </Caption3>
                    <Caption3 weight={'semibold'} color={'primary'}>
                      12345
                    </Caption3>
                    <Caption3 weight={'regular'} color={'gray09'}>
                      '만 입력
                    </Caption3>
                  </Row>
                </WrapParkingNumberDesc>
              </div>
            )}
          </Container>
        </MainContainerNoMargin>
      </Dialog>
    </>
  );
};

export default ParkingSearchModal;
