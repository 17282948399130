import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps, Overlay } from 'react-naver-maps';

import { MyLocation } from './styles';
//@ts-ignore
import { makeMarkerClustering } from 'containers/home/HomeMap/MarkerCluster/marker-cluster';
import { chargerData, hourlyData, mapData } from 'containers/home/HomeMap/mock';
import {
  createShareMarkerContent,
  createChargerMarkerContent,
  createDailyMarker,
  createJinjuClusterContent,
  createJinjuMarkerContent,
  createMonthlyMarkerContent,
} from 'containers/home/HomeMap/MarkerCluster/markes';

import { IcSearchLocateSVG } from 'assets/ic';
import { LOCATION_MARKER } from 'constants/baseConstants';

interface MapProps {
  address: string;
  onClick: (id: number | string, label: string, lat: string, lon: string) => void;
  lat?: number;
  lng?: number;
  selectedMenu: string;
  selectItem: string | number | null;
  setAddressText: React.Dispatch<React.SetStateAction<string>>;
}

const geocoder = window.kakao && new window.kakao.maps.services.Geocoder();

const HomeMap: React.FC<MapProps> = ({
  address,
  onClick,
  lat,
  lng,
  selectedMenu,
  selectItem,
  setAddressText,
}) => {
  const navermaps = useNavermaps();
  const clusterRef = useRef(null);

  const [map, setMap] = useState(null);
  // @ts-ignore
  const individualMarkers = useRef<naver.maps.Marker[]>([]);
  // @ts-ignore
  const clusterMarkers = useRef<naver.maps.Marker[]>([]);
  const markers: any = [];
  const MarkerClustering = makeMarkerClustering(window.naver);

  let newCluster: any;

  // @ts-ignore
  const clearMarkers = (markers: naver.maps.Marker[]) => {
    markers.forEach(marker => marker.setMap(null));
    markers.length = 0; // 배열 초기화
  };

  const htmlMarker1 = {
    content: `<div style="
        cursor:pointer;
        width:56px;
        height:56px;
        font-size:22px;
        color:#8B63BB;
        text-align:center;
        font-weight:600;
        background-color: #ffffff;
        display: flex;
        border-radius: 56px;
        justify-content: center;
        align-items: center;">
         <span id="cluster-count">0</span>
         <span id="cluster-text" style="font-size: 15px; color: #8B63BB;"></span>
        </div>`,
    size: navermaps.Size(56, 56),
    anchor: navermaps.Point(28, 28),
  };

  const htmlMarker2 = {
    content: `<div style="
        cursor:pointer;
        font-size:22px;
        padding: 10px;
        color:#8B63BB;
        text-align:center;
        font-weight:600;
        background-color: #ffffff;
        display: flex;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        white-space: nowrap;
        ">
            <span id="cluster-text" style="font-size: 15px; color: #8B63BB;"></span>
            <span id="cluster-count" style="font-size: 18px; color: #333;"></span>
        </div>`,
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  };

  let center = new navermaps.LatLng(lat, lng);
  let addressLocation = {
    lat: 0,
    lng: 0,
  };

  useEffect(() => {
    if (lat && lng) {
      if (map) {
        //@ts-ignore
        map.setCenter(center);

        new navermaps.Marker({
          position: { lat, lng },
          map: map,
          icon: {
            url: 'https://test.web.zoomansa.co.kr/static/media/img_marker_locate.bb8850eb801d18e4d9a717b5d86442f7.svg',
            size: new navermaps.Size(36, 36), // 이미지 크기
            origin: new navermaps.Point(0, 0), // 이미지 원점 (수정 가능)
            anchor: new navermaps.Point(18, 18), // 앵커 (마커의 기준 위치)
          },
        });
      }
    }
  }, [lat, lng, map]);

  useEffect(() => {
    if (address !== '') {
      handleSelectAddress(address);
    }
  }, [address]);

  const hideMarker = (zoomLevel: number) => {};

  const getGridSize = (zoomLevel: number) => {
    const zoomMapping: Record<string, number[]> = {
      monthly: [500, 300],
      share: [500, 300, 100],
    };

    if (selectedMenu === 'daily' || selectedMenu === 'charger') {
      return 0;
    }

    const gridSizes = zoomMapping[selectedMenu];

    if (gridSizes) {
      if (zoomLevel <= 14) return gridSizes[0];
      if (zoomLevel <= 15) return gridSizes[1];
      if (selectedMenu === 'share' && zoomLevel <= 16) return gridSizes[2];
    }

    return 0;
  };

  const zoomListener = () => {
    if (!map) return;
    // @ts-ignore
    const zoomLevel = map.getZoom();

    const updatedIcons = zoomLevel <= 14 ? [htmlMarker2] : [htmlMarker1];
    const newGridSize = getGridSize(zoomLevel);

    if (selectedMenu === 'daily' && zoomLevel <= 13) {
      // @ts-ignore
      clearMarkers(markers);
      return;
    } else if (markers.length === 0) {
      initializeCluster();
    }

    if (selectedMenu === 'charger' && zoomLevel <= 14) {
      // @ts-ignore
      clearMarkers(markers);
      return;
    } else if (markers.length === 0) {
      initializeCluster();
    }

    if (clusterRef.current) {
      // @ts-ignore
      clusterRef.current.setIcons(updatedIcons); // 아이콘 업데이트
      // @ts-ignore
      clusterRef.current.setGridSize(newGridSize); // gridSize 업데이트
    }

    createJinjuMarker();
  };

  const createJinjuMarker = () => {
    if (!map) return;
    // @ts-ignore
    const zoomLevel = map.getZoom();
    const jinjuData = mapData.result_jinju.data;
    let marker;
    let clusterMarker: any;
    if (zoomLevel >= 16) {
      clearMarkers(clusterMarkers.current);
      jinjuData.forEach(data => {
        data.sensorList.forEach((sensor: any) => {
          const latlng = new window.naver.maps.LatLng(sensor.locY, sensor.locX);
          marker = new window.naver.maps.Marker({
            position: latlng,
            map,
            icon: {
              content: createJinjuMarkerContent(sensor, data, selectItem),
            },
          });
          individualMarkers.current.push(marker);
          window.naver.maps.Event.addListener(marker, 'click', () => {
            onClick(sensor.parkingLocation, 'jinju', String(sensor.locY), String(sensor.locX));
          });
        });
      });
    } else if (zoomLevel >= 14) {
      clearMarkers(individualMarkers.current);
      jinjuData.forEach(data => {
        if (data.sensorList.length > 0) {
          const latlng = new window.naver.maps.LatLng(
            data.sensorList[0].locY,
            data.sensorList[0].locX,
          );
          clusterMarker = new window.naver.maps.Marker({
            position: latlng,
            map,
            icon: {
              content: createJinjuClusterContent(data.parkingName, data.totalParkingSpace),
            },
          });
          window.naver.maps.Event.addListener(data, 'click', () => {
            // @ts-ignore
            map.setZoom(zoomLevel + 1);
          });
          clusterMarkers.current.push(clusterMarker);
        }
      });
    } else {
      clearMarkers(individualMarkers.current);
      clearMarkers(clusterMarkers.current);
    }
  };

  const handleSelectAddress = useCallback(async (address: string) => {
    geocoder.addressSearch(address, (result: any, status: any) => {
      if (status === window.kakao.maps.services.Status.OK) {
        addressLocation = { lat: parseFloat(result[0].y), lng: parseFloat(result[0].x) };
        const latLng = new navermaps.LatLng(parseFloat(result[0].y), parseFloat(result[0].x));
        if (map) {
          // @ts-ignore
          map.setCenter(latLng);
          // @ts-ignore
          map.setZoom(16, true);

          new navermaps.Marker({
            position: addressLocation,
            map: map,
            icon: {
              url: LOCATION_MARKER,
              size: new navermaps.Size(36, 36), // 이미지 크기
              origin: new navermaps.Point(0, 0), // 이미지 원점
              anchor: new navermaps.Point(18, 18),
            },
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }

    setTimeout(() => {
      initializeCluster(); // 맵이 처음 로드될 때만 실행
    }, 100);
  }, [map, selectedMenu, selectItem]); // 의존성 배열 최적화

  const initializeCluster = () => {
    if (clusterRef.current) {
      //@ts-ignore
      clusterRef.current.setMap(null);
      clusterRef.current = null;
    }

    let data;

    if (selectedMenu === 'monthly') {
      data = mapData.result;
      for (let i = 0, ii = data.length; i < ii; i++) {
        let marker = data[i];
        if (marker.regionType !== 'zoomansaMonthly') continue;

        let latlng = new window.naver.maps.LatLng(marker.lat, marker.lon);

        let content = createMonthlyMarkerContent(marker, selectItem);

        // 임시로 DOM에 추가 후 크기 측정
        let tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.innerHTML = content;
        document.body.appendChild(tempDiv);

        let width = tempDiv.offsetWidth;
        let height = tempDiv.offsetHeight;
        // 임시 요소 제거
        document.body.removeChild(tempDiv);

        let item = new window.naver.maps.Marker({
          position: latlng,
          icon: {
            content: content,
            size: new window.naver.maps.Size(width, height),
            scaledSize: new window.naver.maps.Size(width, height),
            origin: new window.naver.maps.Point(0, 0),
            anchor: new window.naver.maps.Point(width / 2, height), // 하단 중앙을 기준으로 설정
          },
        });
        window.naver.maps.Event.addListener(item, 'click', () => {
          onClick(marker.lotUID ?? 0, marker.regionType, String(marker.lat), String(marker.lon));
        });

        markers.push(item);
      }
    } else if (selectedMenu === 'daily') {
      data = hourlyData.result;

      for (let i = 0, ii = data.length; i < ii; i++) {
        let marker = data[i];

        let latlng = new window.naver.maps.LatLng(marker.lat, marker.lon);

        let content = createDailyMarker(marker, selectItem);

        let tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.innerHTML = content;
        document.body.appendChild(tempDiv);

        let width = tempDiv.offsetWidth;
        let height = tempDiv.offsetHeight;
        // 임시 요소 제거
        document.body.removeChild(tempDiv);

        let item = new window.naver.maps.Marker({
          position: latlng,
          icon: {
            content: content,
            size: new window.naver.maps.Size(width, height),
            scaledSize: new window.naver.maps.Size(width, height),
            origin: new window.naver.maps.Point(0, 0),
            anchor: new window.naver.maps.Point(width / 2, height), // 하단 중앙을 기준으로 설정
          },
        });

        window.naver.maps.Event.addListener(item, 'click', () => {
          onClick(
            marker.lotUID ?? marker.regionCode,
            marker.regionType,
            String(marker.lat),
            String(marker.lon),
          );
        });
        markers.push(item);
      }
    } else if (selectedMenu === 'share') {
      data = hourlyData.result;

      for (let i = 0, ii = data.length; i < ii; i++) {
        let marker = data[i];

        let latlng = new window.naver.maps.LatLng(marker.lat, marker.lon);

        let content = createShareMarkerContent(marker, selectItem);

        let tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.innerHTML = content;
        document.body.appendChild(tempDiv);

        let width = tempDiv.offsetWidth;
        let height = tempDiv.offsetHeight;
        // 임시 요소 제거
        document.body.removeChild(tempDiv);

        let item = new window.naver.maps.Marker({
          position: latlng,
          icon: {
            content: content,
            size: new window.naver.maps.Size(width, height),
            scaledSize: new window.naver.maps.Size(width, height),
            origin: new window.naver.maps.Point(0, 0),
            anchor: new window.naver.maps.Point(width / 2, height), // 하단 중앙을 기준으로 설정
          },
        });

        window.naver.maps.Event.addListener(item, 'click', () => {
          onClick(
            marker.lotUID ?? marker.regionCode,
            marker.regionType,
            String(marker.lat),
            String(marker.lon),
          );
        });
        markers.push(item);
      }
    } else if (selectedMenu === 'charger') {
      data = chargerData.result;

      for (let i = 0, ii = data.length; i < ii; i++) {
        let marker = data[i];

        let latlng = new window.naver.maps.LatLng(marker.lat, marker.lng);

        let content = createChargerMarkerContent(marker, selectItem);

        let tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.innerHTML = content;
        document.body.appendChild(tempDiv);

        let width = tempDiv.offsetWidth;
        let height = tempDiv.offsetHeight;
        // 임시 요소 제거
        document.body.removeChild(tempDiv);

        let item = new window.naver.maps.Marker({
          position: latlng,
          icon: {
            content: content,
            size: new window.naver.maps.Size(width, height),
            scaledSize: new window.naver.maps.Size(width, height),
            origin: new window.naver.maps.Point(0, 0),
            anchor: new window.naver.maps.Point(width / 2, height), // 하단 중앙을 기준으로 설정
          },
        });

        window.naver.maps.Event.addListener(item, 'click', () => {
          onClick(marker.statId, '', String(marker.lat), String(marker.lng));
        });
        markers.push(item);
      }
    }

    let recordedCoordinates = new Map<string, string>(); // 예: 'lat_lng' => '주소'

    // @ts-ignore
    const zoomLevel = map.getZoom();

    newCluster = new MarkerClustering({
      minClusterSize: 3,
      maxZoom: 18,
      map: map,
      markers: markers,
      disableClickZoom: false,
      gridSize: getGridSize(zoomLevel),
      icons: zoomLevel <= 14 ? [htmlMarker2] : [htmlMarker1], // 기본 아이콘은 htmlMarker1
      indexGenerator: [5],
      stylingFunction: function (clusterMarker: any, count: any) {
        const clusterElement = clusterMarker.getElement();
        const countElement = clusterElement.querySelector('#cluster-count');
        const textElement = clusterElement.querySelector('#cluster-text');

        countElement.innerText = count;

        const lat = clusterMarker.position._lat;
        const lng = clusterMarker.position._lng;

        const coordinateKey = `${lat}_${lng}`;

        if (clusterMarker.getIcon() === htmlMarker2) {
          // 좌표가 이미 처리된 경우, 이미 저장된 주소를 사용하여 표시
          if (recordedCoordinates.has(coordinateKey)) {
            // 기존에 저장된 주소를 클러스터 텍스트에 표시
            textElement.innerText = recordedCoordinates.get(coordinateKey);

            // 클러스터링을 하지 않음
            countElement.innerText = count;
            return;
          }

          // 주소를 얻는 부분 (최초 한 번만 요청)
          if (window.kakao) {
            const coords = new window.kakao.maps.LatLng(lat, lng);

            geocoder.coord2Address(coords.getLng(), coords.getLat(), (result: any, status: any) => {
              if (status === window.kakao.maps.services.Status.OK) {
                const address =
                  result[0].address.region_3depth_name ?? result[0].address.region_2depth_name;

                recordedCoordinates.set(coordinateKey, address);

                textElement.innerText = address;
              }
            });
          }
        }
      },
    });

    zoomListener();
    // @ts-ignore
    clusterRef.current = newCluster;
  };

  useEffect(() => {
    if (!map) return;
    createJinjuMarker();
  }, [map]);

  return (
    <MapDiv style={{ width: '100%', height: '100vh' }}>
      <NaverMap
        ref={setMap}
        minZoom={13}
        maxZoom={18}
        mapTypeControl={false}
        onZoomChanged={zoomListener}
      ></NaverMap>

      <MyLocation
        onClick={e => {
          e.preventDefault();
          if (map) {
            // @ts-ignore
            map.setCenter(center);
            // @ts-ignore
            map.setZoom(16, true);

            setAddressText('');
          }
        }}
      >
        <IcSearchLocateSVG />
      </MyLocation>
    </MapDiv>
  );
};

export default HomeMap;
