import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const MyLocation = styled('div', {
  backgroundColor: colors.white,
  width: 44,
  height: 44,
  borderRadius: 44,
  filter: 'drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.25))',
  position: 'fixed',
  right: 20,
  bottom: 130,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const WrapFilter = styled('div', {
  backgroundColor: colors.white,
  width: 44,
  height: 44,
  borderRadius: 44,
  filter: 'drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.25))',
  position: 'absolute',
  bottom: -60,
  right: 0,
});

export const WrapFilterCount = styled('div', {
  backgroundColor: colors.primary,
  height: 20,
  padding: '0px 6px',
  borderRadius: 10,
  position: 'absolute',
  top: -8,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
