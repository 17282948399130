import { MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmptyParkingApplyHistoryList from 'containers/emptyParkingArea/EmptyParkingApplyHistoryList';
import MonthlyParkingInfoDetailBottomModal from 'components/atoms/Modal/MonthParkingInfoDetailBottomModal';

const EmptyParkingAreaPage = () => {
  const navigate = useNavigate();

  const [selectedProductUID, setSelectedProductUID] = useState<number | null>(null);
  const [isMonthlyDetail, setIsMonthlyDetail] = useState(false);

  const handleLeftOnClick = () => {
    if (isMonthlyDetail) {
      setIsMonthlyDetail(false);
    } else {
      navigate('/myInfo');
    }
  };

  const onClickItem = (productUID: number) => {
    setSelectedProductUID(productUID);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin>
      <Title
        title="빈 자리 신청 내역"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <EmptyParkingApplyHistoryList
        onClickItem={onClickItem}
        onOpen={() => {
          setIsMonthlyDetail(true);
        }}
      />

      <MonthlyParkingInfoDetailBottomModal
        isVisible={isMonthlyDetail}
        onClose={() => {
          setIsMonthlyDetail(false);
        }}
        productUID={selectedProductUID}
        type={'빈자리'}
      />
    </MainContainerNoMargin>
  );
};

export default EmptyParkingAreaPage;
