import BaseService from '../baseService';
import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  IEmptyParkingCancelResponse,
  IEmptyParkingRequest,
  IEmptyParkingResponse,
} from 'models/EmptyParking/emptyParkingModel';

interface EmptyParkingServiceEndPoints {
  EMPTY_PARKING_APPLY_HISTORY: string;
  EMPTY_PARKING_CANCEL: string;
}

class EmptyParkingService extends BaseService<EmptyParkingServiceEndPoints> {
  apiEndpoints: EmptyParkingServiceEndPoints = {
    EMPTY_PARKING_APPLY_HISTORY: 'getWaitingList',
    EMPTY_PARKING_CANCEL: 'cancelWaiting',
  };

  getEmptyParkingHistory = async (
    requestBody: IEmptyParkingRequest,
  ): Promise<IEmptyParkingResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()

        .data({ ...requestBody })
        .url(`${this.apiEndpoints.EMPTY_PARKING_APPLY_HISTORY}`)
        .build<IEmptyParkingResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  postEmptyParkingCancel = async (
    waitingUID: number,
  ): Promise<IEmptyParkingCancelResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.EMPTY_PARKING_CANCEL}/${waitingUID}`)
        .build<IEmptyParkingCancelResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new EmptyParkingService();
