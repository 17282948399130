// tabsConfig.ts
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Slider from 'react-slick';

import icLocation from 'assets/ic/ic_location_gray.svg';
import icCallGray from 'assets/ic/ic_call_gray.svg';
import icNotRoadView from 'assets/ic/ic_not_road_view.svg';
import icParkingPurple from 'assets/ic/ic_parking_purple.svg';
import icExclamationGray from 'assets/ic/ic_exclamation.svg';
import icNotImage from 'assets/ic/ic_not_image.svg';
import icBoxTruck from 'assets/ic/ic_box_truck.svg';
import icCarRegi from 'assets/ic/ic_car_regi.svg';
import icCircuitBreaker from 'assets/ic/ic_circuit_breaker.svg';
import icCompactCarExclusive from 'assets/ic/ic_compact_car_exclusive.svg';
import icDualParking from 'assets/ic/ic_dual_parking.svg';
import icMachine from 'assets/ic/ic_machine.svg';
import icMotorcycle from 'assets/ic/ic_motorcycle.svg';
import icReservedSeat from 'assets/ic/ic_reserved_seat.svg';
import icSedan from 'assets/ic/ic_sedan.svg';
import icSelfParking from 'assets/ic/ic_self_parking.svg';
import icSingleParking from 'assets/ic/ic_single_parking.svg';
import icSuvBellow from 'assets/ic/ic_suv_below.svg';
import icVacancy from 'assets/ic/ic_vacancy.svg';
import icRV from 'assets/ic/ic_rv.svg';
import {
  CenterContentsContainer,
  DepositStatusContainer,
  ExclamationIcon,
  ImageTextContainer,
  RoadViewImageViewContainer,
  OperatingHourContainer,
  ParkingIcon,
  ParkingInfoSpan,
  ParkingInfoStatusContainer,
  ParkingNumberSpan,
  ParkingTitleSpan,
  PaymentAccountDepositContainer,
  PaymentAccountDepositContentContainer,
  TimeOperatingHoursSpan,
  ToggleStyled,
  TopParkingInfoContainer,
  TopParkingInfoSpan,
  ViewDivide1,
  WeekContainer,
  WeekInfoContainer,
  ParkingInfoIconStyled,
  RoadViewContainer,
  DiscountAmountSpan,
  SliderIndicator,
} from './styles';
import { ViewDivide } from 'pages/AgreeTerms/styles';
import ToggleButton from '../../../Toggle';
import { monthlyParkingStore } from 'stores/store/Monthly/monthlyParkingStore';
import MinganParkingInfoBottomModal from 'components/atoms/Modal/MinganParkingInfoBottomModal';
import { formatPriceWithCommas } from 'utils/number';
import { IcRoadViewFullSVG } from 'assets/ic';
import { moveToPage } from 'utils/deviceUtils';
import { Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';

const iconMap: { [key: string]: string } = {
  차량등록: icCarRegi,
  단일주차: icSingleParking,
  이중주차: icDualParking,
  빈자리: icVacancy,
  지정석: icReservedSeat,
  SUV이하: icSuvBellow,
  경차전용: icCompactCarExclusive,
  '승용차 전용': icSedan,
  '오토바이 전용': icMotorcycle,
  탑차가능: icBoxTruck,
  자주식: icSelfParking,
  '기계식 (승용)': icMachine,
  '기계식 (RV)': icRV,
  차단기: icCircuitBreaker,
};

const MonthlyParkingInfoDetail = () => {
  const roadViewRef = useRef<HTMLDivElement | null>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isMonthParkingInfoBottomModal, setIsMonthParkingInfoBottomModal] = useState(false);
  const [attribute, setAttribute] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { parkingProduct } = monthlyParkingStore;

  const slideSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    zIndex: 1,
    autoplay: false,
    afterChange: (index: number) => setCurrentIndex(index),
  };

  const handleToggle = (index: number) => {
    setSelectedIndex(index);
  };

  const getIconForAttribute = (attribute: string) => {
    const attributes = attribute.split(',');
    return attributes.map(item => iconMap[item.trim()] || 'icNotImage');
  };

  useEffect(() => {
    if (parkingProduct?.attribute) {
      const attributeArray = parkingProduct?.attribute.flatMap(item => {
        return getIconForAttribute(item); // 아이콘 배열을 하나로 평탄화하여 반환
      });
      setAttribute(attributeArray); // 상태 업데이트
    }
  }, [parkingProduct]);
  useEffect(() => {
    if (!parkingProduct?.latitude) return;

    if (!window.kakao || !window.kakao.maps) {
      console.error('Kakao Maps not available');
      return;
    }

    if (!roadViewRef.current) {
      console.error('Roadview container not found');
      return;
    }

    const roadView = new window.kakao.maps.Roadview(roadViewRef.current);
    const roadViewClient = new window.kakao.maps.RoadviewClient();
    const latLng = new window.kakao.maps.LatLng(
      parkingProduct?.latitude,
      parkingProduct?.longitude,
    );

    roadViewClient.getNearestPanoId(latLng, 50, (panoId: number) => {
      if (!panoId) {
        console.error('로드뷰 정보를 찾을 수 없습니다.');
        return;
      }
      roadView.setPanoId(panoId, latLng);
    });
  }, [parkingProduct, selectedIndex]);

  return (
    <div>
      <TopParkingInfoContainer>
        <ParkingNumberSpan>{parkingProduct?.slotUID}번 주차장</ParkingNumberSpan>
        <ImageTextContainer>
          <img src={icLocation} alt={'로케이션 회색 아이콘'} />
          <TopParkingInfoSpan>
            {parkingProduct?.lotAddr.replace(/(\d{1,5}-\d{1,5})/, '***')}
          </TopParkingInfoSpan>
        </ImageTextContainer>
        <ImageTextContainer>
          <img src={icCallGray} alt={'전화기 회색 아이콘'} />
          <TopParkingInfoSpan>1666-4369</TopParkingInfoSpan>
        </ImageTextContainer>
      </TopParkingInfoContainer>
      <ViewDivide1 />
      <CenterContentsContainer>
        <ImageTextContainer>
          <ParkingIcon src={icParkingPurple} alt={'주차장 보라색 아이콘'} />
          <ParkingInfoSpan>주차장 정보</ParkingInfoSpan>
          <ExclamationIcon
            onClick={() => {
              setIsMonthParkingInfoBottomModal(true);
            }}
            src={icExclamationGray}
            alt={'느낌표 회색 아이콘'}
          />
        </ImageTextContainer>
        <ParkingInfoStatusContainer>
          {attribute.map((el, index) => {
            if (el === 'icNotImage') return null;
            return <ParkingInfoIconStyled key={index} src={el} alt={`Icon ${index}`} />;
          })}
        </ParkingInfoStatusContainer>
        <ViewDivide />
        <div>
          <OperatingHourContainer>
            <ParkingTitleSpan>운영시간</ParkingTitleSpan>
            <PaymentAccountDepositContentContainer>
              <WeekContainer>평일</WeekContainer>
              <TimeOperatingHoursSpan>{parkingProduct?.weeklyOperTime}</TimeOperatingHoursSpan>
            </PaymentAccountDepositContentContainer>
          </OperatingHourContainer>
          <WeekInfoContainer>
            <WeekContainer>주말·공휴일</WeekContainer>
            <TimeOperatingHoursSpan>{parkingProduct?.weekendOperTime}</TimeOperatingHoursSpan>
          </WeekInfoContainer>

          <PaymentAccountDepositContainer>
            <ParkingTitleSpan>결제금액</ParkingTitleSpan>
            <PaymentAccountDepositContentContainer>
              <DiscountAmountSpan>
                {parkingProduct?.salePrice &&
                  formatPriceWithCommas(parkingProduct?.salePrice * 1.15)}
                원
              </DiscountAmountSpan>
              <TimeOperatingHoursSpan>
                {formatPriceWithCommas(parkingProduct?.salePrice ?? 0)}원
              </TimeOperatingHoursSpan>
            </PaymentAccountDepositContentContainer>
          </PaymentAccountDepositContainer>

          <PaymentAccountDepositContainer>
            <ParkingTitleSpan>보증금</ParkingTitleSpan>
            <PaymentAccountDepositContentContainer>
              {parkingProduct?.depositAmount !== 0 ? (
                <DepositStatusContainer>주자 이용 종료 후 반환</DepositStatusContainer>
              ) : null}
              <TimeOperatingHoursSpan>
                {formatPriceWithCommas(parkingProduct?.depositAmount ?? 0)}원
              </TimeOperatingHoursSpan>
            </PaymentAccountDepositContentContainer>
          </PaymentAccountDepositContainer>
        </div>

        <ToggleStyled>
          <ToggleButton
            options={['사진', '로드뷰']}
            selectedIndex={selectedIndex}
            content={[
              <div key="사진">
                {parkingProduct?.photoURL.length ? (
                  <div style={{ position: 'relative', height: 180, margin: '10px 0px' }}>
                    <Slider {...slideSettings}>
                      {parkingProduct?.photoURL.map((el, index) => {
                        return (
                          <RoadViewImageViewContainer key={index}>
                            <img
                              src={el}
                              alt={'주차장 이미지'}
                              width={'100%'}
                              height={'100%'}
                              style={{
                                borderRadius: 5,
                              }}
                            />
                          </RoadViewImageViewContainer>
                        );
                      })}
                    </Slider>
                    <SliderIndicator>
                      <Row>
                        <Caption4 weight={'regular'} color={'white'} style={{ marginRight: 4 }}>
                          {currentIndex + 1}
                        </Caption4>
                        <Caption4 weight={'regular'} color={'white'} style={{ marginRight: 4 }}>
                          /
                        </Caption4>
                        <Caption4 weight={'regular'} color={'white'}>
                          {parkingProduct?.photoURL.length}
                        </Caption4>
                      </Row>
                    </SliderIndicator>
                  </div>
                ) : (
                  <div style={{ position: 'relative', height: 180, margin: '10px 0px' }}>
                    <RoadViewImageViewContainer>
                      <img src={icNotImage} alt={'이미지 없는 아이콘'} />
                      <span>등록된 사진이 없습니다.</span>
                    </RoadViewImageViewContainer>
                  </div>
                )}
              </div>,
              <div key="로드뷰">
                {parkingProduct?.latitude ? (
                  <div
                    style={{
                      position: 'relative',
                      height: 180,
                      margin: '10px 0px',
                    }}
                  >
                    <IcRoadViewFullSVG
                      onClick={() => {
                        moveToPage(
                          'https://parking.zoomansa.com/mobileAPI/service/roadView.jsp?lat=' +
                            parkingProduct?.latitude +
                            '&lng=' +
                            parkingProduct?.longitude,
                        );
                      }}
                      style={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        zIndex: 1,
                      }}
                    />
                    <RoadViewContainer ref={roadViewRef} />
                  </div>
                ) : (
                  <div style={{ position: 'relative', height: 180, margin: '10px 0px' }}>
                    <RoadViewImageViewContainer>
                      <img src={icNotRoadView} alt={'로드뷰 없는 아이콘'} />
                      <span>로드뷰가 제공 되지 않는 곳입니다.</span>
                    </RoadViewImageViewContainer>
                  </div>
                )}
              </div>,
            ]}
            onToggle={handleToggle}
          />
        </ToggleStyled>
      </CenterContentsContainer>

      <MinganParkingInfoBottomModal
        isVisible={isMonthParkingInfoBottomModal}
        onClose={() => setIsMonthParkingInfoBottomModal(false)}
      />
    </div>
  );
};

export default observer(MonthlyParkingInfoDetail);
