import BaseService from 'service/baseService';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import { ISignUp, ISignUpResponse } from 'models/SignUp/signUpModel';

interface SignUpEndPoint {
  SIGN_UP: string;
}

class SignUpService extends BaseService<SignUpEndPoint> {
  apiEndpoints: SignUpEndPoint = {
    SIGN_UP: 'signup',
  };

  private handleErrorResponse(response: any): BaseError {
    const { res_code, err_msg } = response.data;

    if (!err_msg) {
      throw new Error('알 수 없는 오류 발생');
    }

    return new BaseError({
      status: res_code,
      error_msg: err_msg || '알 수 없는 오류 발생',
    });
  }

  postUserSignUp = async (
    requestBody: ISignUp,
    iv: string,
    salt: string,
  ): Promise<ISignUpResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv: iv,
          salt: salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SIGN_UP}`)
        .build<ISignUpResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return this.handleErrorResponse(response);
      }
    } catch (error) {
      console.error(error);
      const handledError = ErrorHandler.create(error).getError();
      alert(handledError.message);
      return handledError;
    }
  };
}

export const signUpService = new SignUpService();