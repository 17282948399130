import { styled } from '@stitches/react';

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
});

export const PurpleOneButtonCheckModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 50px)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  height: 'auto',
  whiteSpace: 'pre-line',
});

export const TitleContainer = styled('div', {
  paddingTop: '20px',
});

export const ContentsContainer = styled('div', {
  fontFamily: 'Pretendard',

  variants: {
    hasContent: {
      true: {
        paddingBottom: '20px',
      },
      false: {
        paddingBottom: '0px',
      },
    },
  },
});

export const PurpleOneButtonCheckModalTitleSpan = styled('span', {
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  marginBottom: '14px',
  color: '#333',
  whiteSpace: 'pre-wrap',
});

export const PurpleOneButtonCheckModalButton = styled('button', {
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#fff',
  backgroundColor: '#8B63BB',
  border: 'none',
  cursor: 'pointer',
  textAlign: 'center',
  borderRadius: '4px',
  margin: '15px',
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
