export enum ResponseStatus {
  // 인증번호 틀림
  VALIDATE_FAIL = 'VALIDATE FAIL',

  // 이용중인 주차장은 있고, 공유 중인 주차장은 없다
  USING_SLOT_EXIST = 'USING SLOT EXIST',

  // 공유중인 주차장은 있고, 이용 중인 주차장은 없다
  SHARING_SLOT_EXIST = 'SHARING SLOT EXIST',

  // 이용내역 공유내역 보유
  SLOT_EXIST = 'SLOT EXIST',

  // 성공
  SUCCESS = 'SUCCESS',
}