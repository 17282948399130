import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';

import Title from 'components/atoms/Title/PageTitle';

import {
  AgreeButton,
  BottomButtonContainer,
  CarNumberErrorContainer,
  CarNumberInput,
  CarNumberInputContainer,
  CarNumberText,
  CautionTitleContainer,
  ExclamationIcon,
  PolicyPersonalCheckBoxContainer,
  PolicyPersonalClickSpan,
  PolicyPersonalContainer,
  RequiredText,
  StyledCheckbox,
  ListCheckContainer,
  ListItemCheckIc,
  ListItemContainer,
  PaymentInfoContentContainer,
  PaymentItemContainer,
  PaymentValue,
  TerraceTowerContainer,
  ViewDivide1,
  ViewDivide2,
} from './styles';
import CarNumberCheckBottomModal from 'components/atoms/Modal/CarNumberCheckBottomModal';
import { terraceTowerProductOrderStore } from 'stores/store/TerraceTower/terraceTowerProductOrderStore';
import { terraceTowerProductStore } from 'stores/store/TerraceTower/terraceTowerProductStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Body, Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import { TERRACE_PAYMENT_RETURN_URL } from 'constants/baseConstants';
import { MainContainerNoMargin } from 'components/styles';
import Row from 'components/atoms/Row';
import ExpectedEntryTimePickerModal from 'components/atoms/Modal/ExpectedEntryTimePickerModal';
import { generateTimeSlots, TimeSlot } from 'utils/timeUtils';
import { useToast } from 'components/atoms/Toast/ToastProvider';

import icCheckBlue from 'assets/ic/ic_check_blue.svg';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import { IcArrowDown16SVG } from 'assets/ic';

interface IFormInputs {
  carNumber: string;
}

const DailyPaymentPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const productUID = location.state?.productUID || queryParams.get('productUID');
  const mLinkUID = location.state?.productUID || queryParams.get('mLinkUID');

  const setIsPolicyPersonalCheckState = sessionStorage.getItem('isPolicyPersonalCheck');

  const [isCarInputFocus, setIsCarInputFocus] = useState(false);
  const [hasText, setHasText] = useState(false);
  const [isChecked, setIsChecked] = useState(setIsPolicyPersonalCheckState === 'true');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExpectedEntryTimePicker, setIsExpectedEntryTimePicker] = useState(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [selectTime, setSelectTime] = useState<number | null>(null);

  const { memberUID } = userInfoStore;
  const { productName, lotName, operationTime, salePrice } =
    terraceTowerProductStore.terraceTowerOrder || {};

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      carNumber: sessionStorage.getItem('carNumber') || '',
    },
  });

  const carNumber = watch('carNumber', '');

  const paymentInfo = [
    { label: '상품명', value: productName },
    { label: '주차장명', value: lotName },
    { label: '이용가능 시간', value: operationTime },
  ];

  const cautionList = [
    '오늘 하루만 사용 가능한 주차권입니다.',
    '출차 후 재입차에는 사용 불가합니다.',
    '이용 시간 외 주차 시 주차권 적용은 취소되며, 이용시간 전체에 대하여 전액 현장 요금으로 부과됩니다.',
    '저공해차, 경차 등 별도 할인 없습니다.',
  ];

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const handleFocus = () => {
    setIsCarInputFocus(true);
  };

  const handleBlur = () => {
    setIsCarInputFocus(false);
  };

  useEffect(() => {
    if (operationTime) {
      const [startTime, endTime] = operationTime.split('~');
      setTimeSlots(generateTimeSlots(startTime, endTime));
    }
  }, []);

  useEffect(() => {
    setIsExpectedEntryTimePicker(true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('carNumber', carNumber);
  }, [carNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (carNumber) {
      setHasText(true);
    } else {
      setHasText(false);
    }
  }, [carNumber]);

  const [isCarNumberCheckBottomModalVisible, setIsCarNumberCheckBottomModalVisible] =
    useState(false);

  const carNumberInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchTerraceTowerProduct = async () => {
      await terraceTowerProductStore.fetchTerraceTowerProduct(productUID);
    };
    fetchTerraceTowerProduct().catch(error => {
      console.log('주문정보 불러오는 중 에러', error);
    });
  }, []);

  const handleAgreeClick = () => {
    const newCheckState = !isChecked;
    setIsChecked(newCheckState);
    sessionStorage.setItem('isPolicyPersonalCheck', newCheckState.toString());
  };

  const onSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
        carNumber,
      );

      if (isChecked && isValidCarNumber && selectTime !== null) {
        setIsCarNumberCheckBottomModalVisible(true);
      }
    } finally {
      setIsSubmitting(false); // 처리 완료 후 초기화
    }
  };

  useEffect(() => {
    const isValidCarNumber = /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(
      carNumber,
    );

    if (carNumber && !isValidCarNumber) {
      setError('carNumber', { type: 'manual', message: '차량번호 형식이 올바르지 않아요.' });
    } else if (carNumber && isValidCarNumber) {
      clearErrors('carNumber');
    } else {
      clearErrors('carNumber');
    }
  }, [carNumber, setError, clearErrors]);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);
  useEffect(() => {
    if (isExpectedEntryTimePicker) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isExpectedEntryTimePicker]);

  const handlePayment = async () => {
    if (!window.innopay) {
      console.error('Innopay script is not loaded.');
      return;
    }

    if (memberUID == null) {
      console.error('memberUID is null or invalid.');
      return;
    }

    try {
      const res = await terraceTowerProductOrderStore.fetchTerraceTowerProductOrder(
        productUID,
        mLinkUID,
        carNumber,
        timeSlots[selectTime ?? 0].time,
      );

      if (res) {
        const params = {
          PayMethod: 'CARD',
          MID: res?.sejongData?.mid,
          MerchantKey: res?.sejongData?.merchantKey,
          GoodsName: '주만사',
          Amt: res?.sejongData?.amt,
          BuyerName: res?.orderData.carNum,
          BuyerTel: '01012345678',
          BuyerEmail: 'ars@zoomansa.com',
          ResultYN: 'N',
          Moid: res?.sejongData?.moid,
          ReturnURL: TERRACE_PAYMENT_RETURN_URL,
        };
        sessionStorage.setItem('getProductOrder', JSON.stringify(res));

        setIsCarNumberCheckBottomModalVisible(false);

        window.innopay.goPay(params);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSelectTime = (index: number) => {
    setSelectTime(index);
  };

  return (
    <MainContainerNoMargin
      style={{
        margin: '0 auto',
      }}
    >
      <Title
        title="결제"
        leftArrowButtonVisible={true}
        leftOnClick={() => {
          navigate(-1);
        }}
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <TerraceTowerContainer>
        <CautionTitleContainer>
          <ExclamationIcon src={icExclamationBlack} alt="검정 배경 느낌표" />
          <Caption2 weight={'regular'} color={'gray08'}>
            해당 주차권은 카드 결제만 가능하며, 구매한 날짜에만 사용 가능합니다.
          </Caption2>
        </CautionTitleContainer>
        <form>
          <CarNumberInputContainer isFocus={isCarInputFocus}>
            <CarNumberText>차량번호</CarNumberText>
            <Controller
              name="carNumber"
              control={control}
              render={({ field }) => (
                <CarNumberInput
                  type="text"
                  id="carNumber"
                  placeholder="예시 : 12가3456, 서울12가3456"
                  {...field}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (carNumberInputRef.current) {
                        carNumberInputRef.current.blur();
                      }
                    }
                  }}
                  ref={carNumberInputRef}
                  onChange={e => setValue('carNumber', e.target.value)}
                  hasText={hasText}
                  isFocus={isCarInputFocus}
                />
              )}
            />
          </CarNumberInputContainer>
          <CarNumberErrorContainer>
            {carNumber && errors.carNumber ? (
              <Caption3 weight={'regular'} color={'red'}>
                {errors.carNumber.message}
              </Caption3>
            ) : (
              carNumber &&
              !errors.carNumber && (
                <Caption3 weight={'regular'} color={'blue'}>
                  올바른 차량번호 형식이에요.
                </Caption3>
              )
            )}
          </CarNumberErrorContainer>
        </form>
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'semibold'} color={'black3'}>
            주차권 정보
          </SubHeadline>
        </PaymentInfoContentContainer>

        {paymentInfo.map((info, index) => (
          <PaymentItemContainer key={index} isFirst={index === 0}>
            <Caption1 weight={'regular'} color={'gray06'}>
              {info.label}
            </Caption1>
            <PaymentValue isUsePayment={index === Object.keys(info).length}>
              {info.value}
            </PaymentValue>
          </PaymentItemContainer>
        ))}
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <Row justifyContent={'space-between'}>
          <SubHeadline weight={'regular'} color={'gray06'}>
            입차 예정시간
          </SubHeadline>
          <div
            onClick={() => {
              setIsExpectedEntryTimePicker(true);
            }}
          >
            <Row>
              {selectTime !== null ? (
                <Caption1 weight={'medium'} color={'gray09'} style={{ marginRight: 5 }}>
                  {timeSlots[selectTime].time}
                </Caption1>
              ) : (
                <Caption1 weight={'medium'} color={'gray09'} style={{ marginRight: 5 }}>
                  예상 입차시간 선택
                </Caption1>
              )}

              <IcArrowDown16SVG />
            </Row>
          </div>
        </Row>

        <Row
          justifyContent={'space-between'}
          style={{
            marginTop: 10,
          }}
        >
          <SubHeadline weight={'semibold'} color={'black3'}>
            결제 정보
          </SubHeadline>

          <Body weight={'bold'} color={'black3'}>
            {salePrice}
          </Body>
        </Row>
      </TerraceTowerContainer>
      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'semibold'} color={'black3'}>
            유의해 주세요!
          </SubHeadline>
        </PaymentInfoContentContainer>
        <Caption2 weight={'regular'} color={'blue'} style={{ marginTop: 10 }}>
          미숙지로 인한 피해는 주만사에서 책임지지 않습니다.
        </Caption2>
        <ListCheckContainer>
          {cautionList.map((cautionItem, index) => (
            <ListItemContainer key={index} hasMargin={index !== 0}>
              <ListItemCheckIc src={icCheckBlue} alt="파란색 체크 아이콘" />
              <Caption1 weight={'regular'} color={'gray09'} style={{ marginLeft: 10 }}>
                {cautionItem}
              </Caption1>
            </ListItemContainer>
          ))}
        </ListCheckContainer>
        <ViewDivide2 />
      </TerraceTowerContainer>
      <PolicyPersonalContainer>
        <PolicyPersonalCheckBoxContainer onClick={handleAgreeClick}>
          <StyledCheckbox type="checkbox" checked={isChecked} readOnly />
          <RequiredText>(필수)</RequiredText>
          <Caption2 weight={'regular'} color={'gray09'}>
            서비스 이용약관 및 개인정보 수집 동의
          </Caption2>
        </PolicyPersonalCheckBoxContainer>
        <PolicyPersonalClickSpan
          onClick={() => {
            navigate('/policyPersonal');
          }}
        >
          보기
        </PolicyPersonalClickSpan>
      </PolicyPersonalContainer>
      <BottomButtonContainer onClick={onSubmit}>
        <AgreeButton
          state={
            isChecked &&
            /^([가-힣]{2}[0-9]{1,3}|[0-9]{2,3})[가-힣]{1}[0-9]{4}$/.test(carNumber) &&
            selectTime !== null
              ? 'agreeCheck'
              : 'notCheck'
          }
        >
          {productName} 결제하기
        </AgreeButton>
      </BottomButtonContainer>
      <CarNumberCheckBottomModal
        isVisible={isCarNumberCheckBottomModalVisible}
        onClose={() => setIsCarNumberCheckBottomModalVisible(false)}
        carNumber={carNumber}
        modifyCarNumber={() => {
          if (carNumberInputRef.current) {
            carNumberInputRef.current.focus();
          }
        }}
        handlePayment={handlePayment}
      />
      <ExpectedEntryTimePickerModal
        isVisible={isExpectedEntryTimePicker}
        onClose={() => {
          if (selectTime === null) {
            showToast('입차 예정시간을 선택해 주세요');
          } else {
            carNumberInputRef.current && carNumberInputRef.current.focus();
          }
          setIsExpectedEntryTimePicker(false);
        }}
        timeSlots={timeSlots}
        onSelectTime={onSelectTime}
        selectTime={selectTime}
      />
    </MainContainerNoMargin>
  );
});

export default DailyPaymentPage;
