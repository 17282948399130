import { styled } from '@stitches/react';

export const Container = styled('div', {
  padding: '28px 20px',
});

export const WrapItem = styled('div', {
  borderRadius: 5,
  marginTop: 10,
  padding: 16,
});
