import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {})

export const PhoneNumberBorderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #dbdbdb',
  marginLeft: 20,
  marginRight: 20
})

export const PasswordInfoContainer = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '22px auto 0',

  paddingBottom: 5,
  width: 'calc(100% - 40px)',
})

export const PasswordInfoInput = styled('input', {
  border: 'none',
  fontSize: '16px',
  outline: 'none',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  width: '100%',

  '&::placeholder': {
    color: colors.gray06,
    fontSize: '16px',
    fontWeight: 700,
  },
})

export const UserInfoInput = styled('input', {
  paddingBottom: '5px',
  border: '0px',
  width: '100%',
  outline: 'none',
  fontWeight: 700,
  fontSize: '16px',
  backgroundColor: '#FFF',
  color: '#1A1A1A',
  marginTop: '20px',
  '&::placeholder': {
    color: '#868686',
  },
})

export const VerificationCodeInput = styled('input', {
  paddingBottom: '5px',
  border: '0px',
  width: '100%',
  outline: 'none',
  fontWeight: 700,
  color: '#1A1A1A',
  fontSize: '16px',
  backgroundColor: '#FFF',
  '&::placeholder': {
    color: '#868686',
  },
});


export const VerificationCodeInputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  position: 'relative',
  borderBottom: '1px solid #dbdbdb',
  marginLeft: 20,
  marginRight: 20,
});

export const TimerContainer = styled('div', {
  position: 'absolute',
  right: '0',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '16px',
  fontWeight: 700,
  textAlign: 'right',
}, {
  variants: {
    expired: {
      true: { color: 'red' },
      false: { color: '#868686' }
    }
  }
})

export const PhoneCertButton = styled('button', {
  padding: '0px',
  width: 'calc(100% - 40px)',
  marginLeft: 20,
  height: '46px',
  border: '1px solid #8B63BB',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '4px',
  marginTop: '28px',

  variants: {
    isActive: {
      true: {
        backgroundColor: '#8B63BB',
        color: 'white',
      },
      false: {
        backgroundColor: '#fff',
        color: '#8B63BB',
      },
    },
  },
});

export const SignUpButton = styled('button', {
  padding: '0px',
  width: 'calc(100% - 40px)',
  marginLeft: 20,
  height: '46px',
  border: 'none',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '4px',
  marginTop: '28px',
  cursor: 'pointer',

  // 조건부 스타일
  variants: {
    isDisabled: {
      true: {
        backgroundColor: '#f2f2f2',
        cursor: 'not-allowed',
      },
      false: {
        backgroundColor: '#8B63BB',
        cursor: 'pointer',
      },
    },
  },
});
