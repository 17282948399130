import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';
import { IAuthCodeByTell, IAuthCodeByTellResponse } from 'models/Auth/AuthCodeByTellModel';
import {
  IAuthDeactivation,
  IAuthModifyMemberTell,
  IAuthModifyMemberTellResponse,
  IWithdrawalMembershipResponse,
} from 'models/Auth/AuthModifyMemberTell';

interface AuthApiEndpoints {
  AUTH_CODE_BY_TELL: string;
  AUTH_VALIDATE_MODIFY_MEMBER_TELL: string;
  AUTH_CODE_BY_TELL_WHEN_DEACTIVATION: string;
  AUTH_VALIDATE_AUTH_AND_CHECK_USING_OR_SHARING_SLOTS: string;
}

class MyInfoAuthService extends BaseService<AuthApiEndpoints> {
  apiEndpoints: AuthApiEndpoints = {
    AUTH_CODE_BY_TELL: 'getAuthCodeByTell',
    AUTH_VALIDATE_MODIFY_MEMBER_TELL: 'validateAuthAndModifyMemberTell',
    AUTH_CODE_BY_TELL_WHEN_DEACTIVATION: 'getAuthCodeByTellWhenDeactivation',
    AUTH_VALIDATE_AUTH_AND_CHECK_USING_OR_SHARING_SLOTS: 'validateAuthAndCheckUsingOrSharingSlots',
  };

  getAuthCodeByTell = async (
    requestBody: IAuthCodeByTell,
    iv: string,
    salt: string,
  ): Promise<IAuthCodeByTellResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE_BY_TELL}`)
        .build<IAuthCodeByTellResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  validateAuthAndModifyMemberTell = async (
    requestBody: IAuthModifyMemberTell,
    iv: string,
    salt: string,
  ): Promise<IAuthModifyMemberTellResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_VALIDATE_MODIFY_MEMBER_TELL}`)
        .build<IAuthModifyMemberTellResponse>();
      return response.data;
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getAuthCodeByTellWhenDeactivation = async (
    requestBody: IAuthDeactivation,
    iv: string,
    salt: string,
  ): Promise<IWithdrawalMembershipResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_CODE_BY_TELL_WHEN_DEACTIVATION}`)
        .build<IWithdrawalMembershipResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        console.error('서버 에러:');
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  validateAuthAndCheckUsingOrSharingSlots = async (
    requestBody: IAuthModifyMemberTell,
    iv: string,
    salt: string,
  ): Promise<IWithdrawalMembershipResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .headers({
          'Content-Type': 'application/json',
          iv,
          salt,
        })
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.AUTH_VALIDATE_AUTH_AND_CHECK_USING_OR_SHARING_SLOTS}`)
        .build<IWithdrawalMembershipResponse>();
      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new MyInfoAuthService();
