import BaseService from '../baseService';

import BaseError from '../../models/baseError';
import AxiosClient from '../../utils/axiosClient';
import ErrorHandler from '../../utils/error/errorHandler';
import {
  ISharePageMatching,
  ISharePageMatchingResponse,
} from 'models/Share/sharePageMatchingModel';
import { IShareHistory, IShareHistoryResponse } from 'models/Share/shareHistoryModel';
import { IShareProductList, IShareProductListResponse } from 'models/Share/shareProductListModel';
import {
  IProductDetail,
  IProductDetailResponse,
  ProductDetailData,
} from 'models/Share/shareProductDetailModel';
import { IShareSlot, IShareSlotResponse } from 'models/Share/shareSlotModel';
import { IShareResidentSlot, IShareResidentSlotResponse } from 'models/Share/shareResidentSlot';
import { IShareExistAnyUsing, IShareExistAnyUsingResponse } from 'models/Share/shareExistAnyUsing';
import {
  IShareReserveOutRequest,
  IShareReserveOutRequestResponse,
} from 'models/Share/shareReserveOutRequest';
import {
  IShareApprovalRequestApproval,
  IShareApprovalRequestApprovalResponse,
} from 'models/Share/shareApprovalRequestApproval';
import {
  IShareApprovalRequestDeny,
  IShareApprovalRequestDenyResponse,
} from 'models/Share/shareApprovalRequestDeny';
import {
  IShareResidentSlotDetailInfo,
  IShareResidentSlotDetailInfoResponse,
} from 'models/Share/shareResidentSlotDetailInfo';
import {
  IShareEndProductSaleHistory,
  IShareEndProductSaleHistoryResponse,
} from 'models/Share/shareEndProductSaleHistory';
import {
  IShareResidentUseCompleteSlot,
  IShareResidentUseCompleteSlotListResponse,
} from 'models/Share/shareResidentUseCompleteSlotListModel';
import {
  IShareResidentSlotShareTimeDetail,
  IShareResidentSlotShareTimeDetailResponse,
} from 'models/Share/shareResidentSlotShareTimeDetail';
import {
  IShareModifyResidentSlotShareStopTime,
  IShareModifyResidentSlotShareStopTimeResponse,
} from 'models/Share/shareModifyResidentSlotShareStopTime';
import {
  IShareStartResidentSlotShare,
  IShareStartResidentSlotShareResponse,
} from 'models/Share/shareStartResidentSlotShare';
import { IShareAreaListResponse } from 'models/Share/shareAreaList';
import {
  IShareModifyResidentSlotShareTime,
  IShareModifyResidentSlotShareTimeResponse,
} from 'models/Share/shareModifyResidentSlotShareTime';
import {
  IProductDetailInfo,
  IProductDetailInfoResponse,
} from 'models/Share/shareProductDetailInfoModel';
import {
  IShareResidentSlotShareStop,
  IShareResidentSlotShareStopResponse,
} from 'models/Share/shareResidentSlotShareStop';
import {
  IShareProductResumeRequest,
  IShareProductResumeRequestResponse,
} from 'models/Share/shareProductSaleResumeRequest';
import {
  IShareUpdateQuantity,
  IShareUpdateQuantityData,
  IShareUpdateQuantityResponse,
} from 'models/Share/ShareUpdateProductQuantity';

interface ShareParkingServiceEndPoint {
  SHARE_PARK_MATCHING: string;
  SHARE_HISTORY: string;
  SHARE_PRODUCT_LIST: string;
  SHARE_PRODUCT_DETAIL: string;
  SHARE_SLOT: string;
  SHARE_RESIDENT_SLOT: string;
  SHARE_EXIT_ANY_USTING: string;
  SHARE_RESERVE_OUT_REQUEST: string;
  SHARE_END_PRODUCT_SALE_HISTORY: string;
  SHARE_APPROVAL_REQUEST_APPROVAL: string;
  SHARE_APPROVAL_REQUEST_DENY: string;
  SHARE_RESIDENT_SLOT_DETAIL_INFO: string;
  SHARE_RESIDENT_USE_COMPLETE_SLOT_LIST: string;
  SHARE_RESIDENT_SLOT_SHARE_TIME_DETAIL: string;
  SHARE_MODIFY_RESIDENT_SLOT_SHARE_TIME: string;
  SHARE_MODIFY_RESIDENT_SLOT_SHARE_STOP_TIME: string;
  SHARE_START_RESIDENT_SLOT_SHARE: string;
  SHARE_AREA_LIST: string;
  SHARE_PRODUCT_DETAIL_OPER_INFO: string;
  SHARE_RESIDENT_SLOT_SHARE_STOP: string;
  SHARE_PRODUCT_SALE_RESUME_REQUEST: string;
  SHARE_UPDATE_PRODUCT_QUANTITY: string;
}

class shareParkingService extends BaseService<ShareParkingServiceEndPoint> {
  apiEndpoints: ShareParkingServiceEndPoint = {
    SHARE_PARK_MATCHING: 'getSlotSharePageMatchingValue',
    SHARE_HISTORY: 'getSharingSlotList',
    SHARE_PRODUCT_LIST: 'getLotProductList',
    SHARE_PRODUCT_DETAIL: 'getProductDetailInfo',
    SHARE_SLOT: 'shareSlot',
    SHARE_RESIDENT_SLOT: 'shareResidentSlot',
    SHARE_EXIT_ANY_USTING: 'getExistAnyUsingDriversYn',
    SHARE_RESERVE_OUT_REQUEST: 'reserveOutRequest',
    SHARE_END_PRODUCT_SALE_HISTORY: 'getEndProductSaleHistory',
    SHARE_APPROVAL_REQUEST_APPROVAL: 'approvalRequestApproval',
    SHARE_APPROVAL_REQUEST_DENY: 'approvalRequestDeny',
    SHARE_RESIDENT_SLOT_DETAIL_INFO: 'getResidentSlotDetailInfo',
    SHARE_RESIDENT_USE_COMPLETE_SLOT_LIST: 'getResidentUseCompleteSlotList',
    SHARE_RESIDENT_SLOT_SHARE_TIME_DETAIL: 'getResidentSlotShareTimeDetail',
    SHARE_MODIFY_RESIDENT_SLOT_SHARE_STOP_TIME: 'modifyResidentSlotShareStopTime',
    SHARE_MODIFY_RESIDENT_SLOT_SHARE_TIME: 'modifyResidentSlotShareTime',
    SHARE_START_RESIDENT_SLOT_SHARE: 'startResidentSlotShare',
    SHARE_AREA_LIST: 'getAreaList',
    SHARE_PRODUCT_DETAIL_OPER_INFO: 'getProductOperInfo',
    SHARE_RESIDENT_SLOT_SHARE_STOP: 'residentSlotShareStop',
    SHARE_PRODUCT_SALE_RESUME_REQUEST: 'productSaleResumeRequest',
    SHARE_UPDATE_PRODUCT_QUANTITY: 'updateProductQuantity',
  };

  getSlotSharePageMatchingValue = async (
    requestBody: ISharePageMatching,
  ): Promise<ISharePageMatchingResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_PARK_MATCHING}`)
        .build<ISharePageMatchingResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getSharingSlotList = async (
    requestBody: IShareHistory,
    pageNum: number,
  ): Promise<IShareHistoryResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_HISTORY}/${requestBody.mLinkUID}?pageNum=${pageNum}`)
        .build<IShareHistoryResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getLotProductList = async (
    requestBody: IShareProductList,
  ): Promise<IShareProductListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.SHARE_PRODUCT_LIST}/${requestBody.slotUID}`)
        .build<IShareProductListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getProductDetail = async (
    requestBody: IProductDetail,
  ): Promise<IProductDetailResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.SHARE_PRODUCT_DETAIL}/${requestBody.productUID}?pageNum=1`)
        .build<IProductDetailResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  shareSlot = async (requestBody: IShareSlot): Promise<IShareSlotResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_SLOT}`)
        .build<IShareSlotResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  shareResidentSlot = async (
    requestBody: IShareResidentSlot,
  ): Promise<IShareResidentSlotResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_RESIDENT_SLOT}`)
        .build<IShareResidentSlotResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  exitAnyUsing = async (
    requestBody: IShareExistAnyUsing,
  ): Promise<IShareExistAnyUsingResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.SHARE_EXIT_ANY_USTING}/${requestBody.slotUID}`)
        .build<IShareExistAnyUsingResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  reserveOutRequest = async (
    requestBody: IShareReserveOutRequest,
  ): Promise<IShareReserveOutRequestResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_RESERVE_OUT_REQUEST}`)
        .build<IShareReserveOutRequestResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  endProductSaleHistory = async (
    requestBody: IShareEndProductSaleHistory,
    pageNum: number,
  ): Promise<IShareEndProductSaleHistoryResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ [requestBody.type]: requestBody.filter })
        .url(
          `${this.apiEndpoints.SHARE_END_PRODUCT_SALE_HISTORY}/${requestBody.productUID}?pageNum=${pageNum}`,
        )
        .build<IShareEndProductSaleHistoryResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  approvalRequestApproval = async (
    requestBody: IShareApprovalRequestApproval,
  ): Promise<IShareApprovalRequestApprovalResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.SHARE_APPROVAL_REQUEST_APPROVAL}/${requestBody.saleHistoryUID}`)
        .build<IShareApprovalRequestApprovalResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  approvalRequestDeny = async (
    requestBody: IShareApprovalRequestDeny,
  ): Promise<IShareApprovalRequestDenyResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_APPROVAL_REQUEST_DENY}`)
        .build<IShareApprovalRequestDenyResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getResidentSlotDetailInfo = async (
    requestBody: IShareResidentSlotDetailInfo,
  ): Promise<IShareResidentSlotDetailInfoResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_RESIDENT_SLOT_DETAIL_INFO}`)
        .build<IShareResidentSlotDetailInfoResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getResidentUseCompleteSlotList = async (
    requestBody: IShareResidentUseCompleteSlot,
    pageNum: number,
  ): Promise<IShareResidentUseCompleteSlotListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_RESIDENT_USE_COMPLETE_SLOT_LIST}?pageNum=${pageNum}`)
        .build<IShareResidentUseCompleteSlotListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getResidentSlotShareTimeDetail = async (
    requestBody: IShareResidentSlotShareTimeDetail,
  ): Promise<IShareResidentSlotShareTimeDetailResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(
          `${this.apiEndpoints.SHARE_RESIDENT_SLOT_SHARE_TIME_DETAIL}/${requestBody.sharePerDayIdx}`,
        )
        .build<IShareResidentSlotShareTimeDetailResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  modifyResidentSlotShareTime = async (
    requestBody: IShareModifyResidentSlotShareTime,
  ): Promise<IShareModifyResidentSlotShareTimeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_MODIFY_RESIDENT_SLOT_SHARE_TIME}`)
        .build<IShareModifyResidentSlotShareTimeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  modifyResidentSlotShareStopTime = async (
    requestBody: IShareModifyResidentSlotShareStopTime,
  ): Promise<IShareModifyResidentSlotShareStopTimeResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_MODIFY_RESIDENT_SLOT_SHARE_STOP_TIME}`)
        .build<IShareModifyResidentSlotShareStopTimeResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  startResidentSlotShare = async (
    requestBody: IShareStartResidentSlotShare,
  ): Promise<IShareStartResidentSlotShareResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_START_RESIDENT_SLOT_SHARE}`)
        .build<IShareStartResidentSlotShareResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getAreaList = async (): Promise<IShareAreaListResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .get()
        .url(`${this.apiEndpoints.SHARE_AREA_LIST}`)
        .build<IShareAreaListResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  getProductDetailOperInfo = async (
    requestBody: IProductDetailInfo,
  ): Promise<IProductDetailInfoResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .url(`${this.apiEndpoints.SHARE_PRODUCT_DETAIL_OPER_INFO}/${requestBody.productUID}`)
        .build<IProductDetailInfoResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  residentSlotShareStop = async (
    requestBody: IShareResidentSlotShareStop,
  ): Promise<IShareResidentSlotShareStopResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_RESIDENT_SLOT_SHARE_STOP}`)
        .build<IShareResidentSlotShareStopResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  productSaleResumeRequest = async (
    requestBody: IShareProductResumeRequest,
  ): Promise<IShareProductResumeRequestResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_PRODUCT_SALE_RESUME_REQUEST}`)
        .build<IShareProductResumeRequestResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  updateProductQuantity = async (
    requestBody: IShareUpdateQuantity,
  ): Promise<IShareUpdateQuantityResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.SHARE_UPDATE_PRODUCT_QUANTITY}`)
        .build<IShareUpdateQuantityResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new shareParkingService();
