import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  TextContainer,
  ParkingButtonListContainer,
  ParkingButtonContainer,
  Image,
  Description,
} from './styles';
import { Body, Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Alexander from 'assets/images/alexander.png';
import ParkImg from 'assets/images/img_park.png';
import Row from 'components/atoms/Row';
// @ts-ignore
import { IcTooltipAlertSVG, IcTooltipHelpSVG } from 'assets/ic';

const NotSharedParking: React.FC = () => {
  const navigate = useNavigate();

  const buttons = [
    {
      title: '부설 주차장',
      desc1: '빌라, 오피스텔, 상가 등',
      desc2: '일반 주차장',
      src: Alexander,
      onClick: () => navigate('/shareRequestMingan'),
    },
    {
      title: '거주자 우선 주차장',
      desc1: '거주자 우선주차 배정자가',
      desc2: '배정 받은 주차장',
      src: ParkImg,
      onClick: () => navigate('/shareRequestGeoju'),
    },
  ];

  return (
    <Container>
      <TextContainer>
        <Headline weight={'medium'} color={'black1'} style={{ marginBottom: 20 }}>
          공유 중인 주차장이 없어요.
        </Headline>
        <Caption1 weight={'regular'} color={'gray07'}>
          빈 주차 공간을 공유하고, 우리동네 부정주차
        </Caption1>
        <Caption1 weight={'regular'} color={'gray07'}>
          문제해결과 부가수익을 창출해 보세요.
        </Caption1>
      </TextContainer>
      <ParkingButtonListContainer>
        {buttons.map((el, index) => {
          return (
            <ParkingButtonContainer key={index} isFirst={index === 0} onClick={el.onClick}>
              <Description>
                <SubHeadline weight={'semibold'} color={'black3'}>
                  {el.title}
                </SubHeadline>
                <div>
                  <Caption2 weight={'regular'} color={'gray08'}>
                    {el.desc1}
                  </Caption2>
                  <Caption2 weight={'regular'} color={'gray08'}>
                    {el.desc2}
                  </Caption2>
                </div>
              </Description>
              <Image src={el.src} alt={'부설 주차장'} />
            </ParkingButtonContainer>
          );
        })}
        <Row style={{ marginTop: 28, marginBottom: 40 }}>
          <IcTooltipAlertSVG fill={'#47474A'} />
          <Caption2 weight={'regular'} color={'gray09'} style={{ marginLeft: 4 }}>
            신청 시 내 연락처로 순차적 안내 드려요.
          </Caption2>
        </Row>
      </ParkingButtonListContainer>
    </Container>
  );
};

export default NotSharedParking;
