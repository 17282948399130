import { styled } from '@stitches/react';

export const MinganParkingGuideModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
});

export const CloseIcon = styled('img', {
  cursor: 'pointer',
  width: '18px',
  height: '18px',
});

export const DashContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  borderBottom: '2px dashed #CCC',
  margin: '0px 25px',
  paddingTop: '15px',
  variants: {
    isLast: {
      true: {
        paddingBottom: '30px',
        borderBottom: 'none', // 마지막 인덱스에서 borderBottom 제거
      },
      false: {
        paddingBottom: '15px', // 기본 패딩값
      },
    },
  },
});

export const NumberSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const TitleSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ContentsSpan = styled('span', {
  color: '#868686',
  fontSize: '14px',
  marginTop: '4px',
  wordBreak: 'keep-all',
});

export const NumberTittleContentsContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

export const TitleContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
});

export const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: '0 0 auto',
});

export const BottomContainer = styled('div', {
  textAlign: 'center',
  // marginTop: '30px',
  marginBottom: 36,
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const OperatingHoursContainer = styled('div', {
  width: '163px',
  height: '19px',
  backgroundColor: '#8B63BB',
  color: '#fff', // Text color
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
});
