import React, { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';

import { ImgClose, Content, Modal, Image } from './styles';
import SliderPosition from 'components/atoms/SliderPosition';

import icCloseWhite from 'assets/ic/ic_close_white.svg';
import { IcCloseWhiteSVG } from 'assets/ic';

interface ParkingPhotoExpansionModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  images: string[];
  startIndex?: number;
}

const ParkingPhotoExpansionModal: React.FC<ParkingPhotoExpansionModalProps> = ({
  isOpen,
  onRequestClose,
  images,
  startIndex = 0,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const startX = useRef(0);

  useEffect(() => {
    setCurrentIndex(startIndex);
  }, [startIndex]);

  const handleTouchStart = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isOpen) return;
    const endX = e.changedTouches[0].clientX;
    const diff = startX.current - endX;

    if (diff > 50) {
      // 왼쪽 스와이프 (다음 슬라이드)
      setCurrentIndex(prev => (prev + 1) % images.length);
    } else if (diff < -50) {
      // 오른쪽 스와이프 (이전 슬라이드)
      setCurrentIndex(prev => (prev - 1 + images.length) % images.length);
    }
  };

  return (
    <>
      <Modal isVisible={isOpen}>
        <Content ref={containerRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
          <ImgClose onClick={onRequestClose}>
            <IcCloseWhiteSVG />
          </ImgClose>
          <div
            style={{
              display: 'flex',
              width: window.innerWidth,
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  width: window.innerWidth,
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Image
                  key={index}
                  src={image}
                  alt={`Slide ${index}`}
                  style={{ width: window.innerWidth, height: 'auto', objectFit: 'contain' }}
                />
              </div>
            ))}
          </div>
          <SliderPosition startIndex={currentIndex + 1} length={images.length} />
        </Content>
      </Modal>
    </>
  );
};

export default ParkingPhotoExpansionModal;
