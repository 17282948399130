import React, { useEffect, useState } from 'react';
import Title from '../../../../components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import { useNavigate } from 'react-router-dom';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  CertNumberContainer,
  CertNumberContentsContainer,
  CertNumberInput,
  CertTransmissionButtonContainer,
  CertTransmissionContainer,
  PhoneInfoContainer,
  PhoneNumberSpan,
  PhoneNumberTextSpan,
  SafeTextSpan,
  TimerSpan,
  TitleContainer,
  TransmissionButton,
} from './styles';
import { useForm } from 'react-hook-form';
import PurpleOneButtonCheckModal from '../../../../components/atoms/Modal/PurpleOneButtonCheckModal';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { observer } from 'mobx-react';
import icFailCheckRed from '../../../../assets/ic/ic_fail_check_red.svg';
import { authCodeStore } from 'stores/store/Auth/authCodeStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { colors } from 'styles/colors';
import { IcCloseCircleGraySVG } from 'assets/ic';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

interface IFormInputs {
  certNumber: string;
}

const PointWithdrawalIdentityCert = observer(() => {
  const navigate = useNavigate();
  const { phoneNumber } = userInfoStore;
  const [buttonText, setButtonText] = useState('전송');
  const [timerVisible, setTimerVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [inputPlaceholder, setInputPlaceholder] = useState('[전송]을 눌러 주세요.');
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isCertSuccess, setIsCertSuccess] = useState(false);
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false);
  const { showToast } = useToast();
  const { iv, salt } = getRandomString();
  const phoneEnc = encTextWord(phoneNumber, iv, salt);

  const { register, watch, setValue } = useForm<IFormInputs>({
    defaultValues: {
      certNumber: '',
    },
  });

  const certNumberValue = watch('certNumber', '');

  const handleLeftOnClick = () => {
    navigate('/pointHistory');
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleClearInput = () => {
    setValue('certNumber', '');
    document.getElementById('certNumber')?.focus();
  };

  const handleSendClick = async () => {
    setButtonText('재전송');
    setTimerVisible(true);
    setTimeLeft(180);
    setInputPlaceholder('6자리를 입력해주세요.');
    setIsInputDisabled(false);
    setIsCloseIconVisible(true);

    try {
      await authCodeStore.fetchAuthCode({ memberTell: phoneEnc }, iv, salt);
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  };

  const handleCertSuccess = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authCodeStore.fetchValidateAuthCode(
        {
          memberTell: phoneEnc,
          authCode: certNumberValue,
        },
        iv,
        salt,
      );

      if (authCodeStore.isAuthCodeValidateSuccessful) {
        document.getElementById('certNumber')?.blur();
        setIsCertSuccess(true);
        return;
      }
      setIsCertSuccess(false);
      showToast('인증 번호가 틀렸어요.', icFailCheckRed);
      setValue('certNumber', '');
      document.getElementById('certNumber')?.blur();
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  };

  const handleFocusAndScroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handleEnterKey = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleCertSuccess(e as any);
    }
  };

  useEffect(() => {
    document.getElementById('certNumber')?.focus();

    const handleVisualViewportResize = () => {
      window.scrollTo(0, 0);
    };

    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewportResize;
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.onresize = null;
      }
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timerVisible && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setButtonText('전송');
      setTimerVisible(false);
      setIsInputDisabled(true);
      setInputPlaceholder('[전송]을 눌러 주세요');
      setIsCloseIconVisible(false);
      showToast('인증 시간이 지났어요.[전송]을 눌러 주세요.', icFailCheckRed);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timerVisible, timeLeft]);

  return (
    <MainContainerNoMargin>
      <Title
        title="본인 인증"
        leftArrowButtonVisible={true}
        leftOnClick={() => handleLeftOnClick()}
        rightTextButtonVisible={false}
      />
      <TitleContainer>
        <SafeTextSpan>안전한 서비스 이용</SafeTextSpan>
        <span>을 위해</span>
        <br />
        <span>본인인증을 먼저 진행해 주세요.</span>
      </TitleContainer>
      <PhoneInfoContainer>
        <PhoneNumberTextSpan>휴대폰 번호</PhoneNumberTextSpan>
        <PhoneNumberSpan>{phoneNumber}</PhoneNumberSpan>
      </PhoneInfoContainer>
      <form onSubmit={handleCertSuccess}>
        <CertNumberContainer css={{ marginTop: 13 }}>
          <CertNumberContentsContainer>
            <span>인증번호</span>
            {timerVisible && <TimerSpan>{formatTime(timeLeft)}</TimerSpan>}
          </CertNumberContentsContainer>

          <CertNumberContentsContainer>
            <CertTransmissionContainer>
              <CertNumberInput
                type="text"
                inputMode="numeric"
                id={'certNumber'}
                placeholder={inputPlaceholder}
                value={certNumberValue}
                {...register('certNumber', {
                  required: '인증번호를 입력해주세요.',
                  onChange: e => {
                    const value = e.target.value.slice(0, 6);
                    setValue('certNumber', value);
                    setIsCloseIconVisible(value.length > 0);
                  },
                })}
                maxLength={6}
                disabled={isInputDisabled}
                style={{
                  backgroundColor: colors.white,
                }}
                onKeyDown={handleEnterKey}
                onClick={() => {
                  handleFocusAndScroll();
                }}
              />
              <CertTransmissionButtonContainer>
                {buttonText === '재전송' && isCloseIconVisible && (
                  <IcCloseCircleGraySVG
                    fill={colors.gray04}
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={handleClearInput}
                  />
                )}
                <TransmissionButton type={'button'} onClick={handleSendClick}>
                  {buttonText}
                </TransmissionButton>
              </CertTransmissionButtonContainer>
            </CertTransmissionContainer>
          </CertNumberContentsContainer>
        </CertNumberContainer>
        <BottomButtonContainer>
          <ButtonEnableDisable type={'submit'} disabled={certNumberValue.length < 6}>
            {certNumberValue.length >= 6 ? '완료' : '다음'}
          </ButtonEnableDisable>
        </BottomButtonContainer>
      </form>
      {isCertSuccess && (
        <PurpleOneButtonCheckModal
          isVisible={isCertSuccess}
          onClose={() => {
            setIsCertSuccess(false);
            navigate('/pointWithdrawalPasswordSetting');
          }}
          title={'본인 인증이 완료되었습니다.'}
          content={''}
          buttonText={'확인'}
        />
      )}
    </MainContainerNoMargin>
  );
});

export default PointWithdrawalIdentityCert;
