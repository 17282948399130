import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import { IAuthCode, IValidateAuthCode } from 'models/Auth/AuthModel';
import authCodeService from 'service/Auth/authCodeService';
import shareParkingService from 'service/Share/shareParkingService';
import { INotice, INoticeData, INoticeListData } from 'models/Notice/NoticeModel';
import noticeService from 'service/Notice/noticeService';

class NoticeStore {
  error_msg: string | null = null;
  notices: INoticeListData[] | [] = [];
  events: INoticeListData[] | [] = [];
  noticeCount: number = 0;
  eventCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setNotices(data: INoticeListData[]) {
    this.notices = data;
  }

  setNoticeCount(data: number) {
    this.noticeCount = data;
  }

  setEvents(data: INoticeListData[]) {
    this.events = data;
  }

  setEventCount(data: number) {
    this.eventCount = data;
  }

  async fetchGetNotices(requestBody: INotice) {
    try {
      const response = await noticeService.getNotices(requestBody);

      if (response instanceof BaseError) {
        console.error('데이터를 가져오는 중 오류 발생:', response.errorMessage);
        return;
      }

      const { data } = response;

      if (data) {
        this.setNoticeCount(data.usingNoticeTotalCount);
        if (requestBody.pageNum === 1) {
          this.setNotices(data.noticeList);
        } else {
          this.setNotices([...this.notices, ...data.noticeList]);
        }
        return data;
      }
    } catch (error) {
      console.error('API 호출 중 오류:', error);
    } finally {
    }
  }

  async fetchGetEvents(requestBody: INotice) {
    try {
      const response = await noticeService.getNotices(requestBody);

      if (response instanceof BaseError) {
        console.error('데이터를 가져오는 중 오류 발생:', response.errorMessage);
        return;
      }

      const { data } = response;

      if (data) {
        this.setEventCount(data.usingNoticeTotalCount);
        if (requestBody.pageNum === 1) {
          this.setEvents(data.noticeList);
        } else {
          this.setEvents([...this.events, ...data.noticeList]);
        }
        return data;
      }
    } catch (error) {
      console.error('API 호출 중 오류:', error);
    } finally {
    }
  }
}

export const noticeStore = new NoticeStore();
