import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 20px',
});

export const UserInfoInput = styled('input', {
  border: '0px',
  outline: 'none',
  fontWeight: 400,
  fontSize: '17px',
  color: '#1A1A1A',
  width: 'calc(100% - 80px)',
  backgroundColor: '#FFF',

  '&::placeholder': {
    color: '#B0B0B0',
  },

  variants: {
    isCertification: {
      true: {
        border: '1px solid #CCC',
      },
      false: {
        border: '1px solid #8B63BB',
      }
    }
  }
});
