import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';

import taxReductionService from 'service/TaxReduction/taxReductionService';
import { ITaxReductionTypeData } from 'models/TaxReduction/taxReductionTypeModel';
import { ITaxRequirements } from 'models/TaxReduction/taxRequirementsModel';
import { ITaxReductionDelete } from 'models/TaxReduction/taxReductionDeleteModel';

class TaxReductionStore {
  isLoading: boolean = false;

  taxReductionList: ITaxReductionTypeData[] | [] = [];
  taxRequirements: string[] | [] = [];
  taxRegisterRes: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setTaxReductionList = (data: ITaxReductionTypeData[]) => {
    this.taxReductionList = data;
  };

  setTaxRequirements = (data: string[]) => {
    this.taxRequirements = data;
  };

  setTaxRegisterRes = (data: boolean) => {
    this.taxRegisterRes = data;
  };

  async fetchGetTaxReductionType() {
    this.setIsLoading(true);

    try {
      const response = await taxReductionService.getTaxReductionType();

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      // 데이터가 없을 경우 처리
      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      this.setTaxReductionList(response.data);
      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchGetTaxRequirements(requestBody: ITaxRequirements) {
    this.setIsLoading(true);

    try {
      const response = await taxReductionService.getTaxRequirements(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      // 데이터가 없을 경우 처리
      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      this.setTaxRequirements(response.data);
      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchInsertTaxReductionInfo(requestBody: Partial<FormData>) {
    this.setIsLoading(true);

    try {
      const response = await taxReductionService.insertTaxReductionInfo(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        this.setTaxRegisterRes(false);
        return;
      }

      if (!response?.data) {
        console.error('No account data found');
        this.setTaxRegisterRes(false);
        return;
      }

      if (response.res_code === 200) {
        this.setTaxRegisterRes(true);
      }

      return response.data;
    } catch (error) {
      this.setTaxRegisterRes(false);
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchUpdateTaxReductionInfo(requestBody: Partial<FormData>) {
    this.setIsLoading(true);

    try {
      const response = await taxReductionService.updateTaxReductionInfo(requestBody);
      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      // 데이터가 없을 경우 처리
      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchDeleteTaxReductionInfo(requestBody: ITaxReductionDelete) {
    this.setIsLoading(true);

    try {
      const response = await taxReductionService.deleteTaxReductionInfo(requestBody);

      if (response instanceof BaseError) {
        console.error(response.errorMessage);
        return;
      }

      if (!response?.data) {
        console.error('No account data found');
        return;
      }

      return response.data;
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const taxReductionStore = new TaxReductionStore();
