import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalContainer, RoadWrap } from './styles';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { Body, Caption1, Caption2, Caption4 } from 'styles/typography';
import { jinjuDetail, mapData, publicDetail } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import { setDial } from 'utils/deviceUtils';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import GeojuParkingPublicDetailModal from 'components/atoms/Modal/GeojuParkingPublicDetailModal';

import { IcRoadFindSVG } from 'assets/ic';

interface JinjuInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
  lat: string;
  lon: string;
}

const JinjuInfoModal: React.FC<JinjuInfoModalProps> = ({ isVisible, onClose, lat, lon }) => {
  const navigate = useNavigate();
  const { memberData } = userInfoStore;

  const [isModal, setIsModal] = useState(false);

  const data = mapData.result_jinju.data[2];
  const detailData = jinjuDetail.slotList[0];

  if (!isVisible) return null;

  return (
    <>
      <ModalContainer
        isVisible={isVisible}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Row alignItems={'center'} justifyContent={'space-between'}>
          <div>
            <Row>
              <Button backgroundColor={'#C4C4C4'} radius={3} horizontal={5} vertical={3}>
                <Caption4 weight={'regular'} color={'white'}>
                  진주
                </Caption4>
              </Button>

              <Body weight={'regular'} color={'black1'} style={{ marginLeft: 8 }}>
                {detailData.prkplceNm}
              </Body>
            </Row>

            <Row style={{ marginTop: 8 }}>
              <Caption4 weight={'regular'} color={'gray06'}>
                {detailData.prkcmprt}면
              </Caption4>
              <Caption4
                weight={'regular'}
                color={'gray06'}
                style={{ marginLeft: 4, marginRight: 4 }}
              >
                |
              </Caption4>
              <Caption4 weight={'regular'} color={'gray06'}>
                {detailData.prkplceType}
              </Caption4>
            </Row>
          </div>
          <RoadWrap
            onClick={() => {
              const name = data.parkingName;
              openNavi(
                String(detailData.latitude),
                String(detailData.longitude),
                name,
                memberData?.naviType ?? '',
              );
            }}
          >
            <IcRoadFindSVG fill={'#fff'} />
          </RoadWrap>
        </Row>

        <Button
          backgroundColor={'#F2F2F2'}
          radius={5}
          style={{
            width: '100%',
            height: 40,
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Caption2 weight={'regular'} color={'gray06'}>
            앱 결제를 지원하지 않는 주차장입니다
          </Caption2>
        </Button>

        <Row justifyContent={'space-between'}>
          <Caption1 weight={'regular'} color={'gray06'}>
            주차 요금
          </Caption1>
          <Caption1 weight={'regular'} color={'black3'}>
            {detailData.basicTime}분 당 {formatPriceWithCommas(detailData.basicCharge)}원
          </Caption1>
        </Row>
        <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
          <Caption1 weight={'regular'} color={'gray06'}>
            이용 가능 대수
          </Caption1>
          <Caption1 weight={'regular'} color={'black3'}>
            {Number(data.totalParkingSpace) - Number(data.usedParkingSpace)}대
          </Caption1>
        </Row>

        <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
          <Caption1 weight={'regular'} color={'gray06'}>
            운영 시간
          </Caption1>
          <Caption1 weight={'regular'} color={'black3'}>
            {detailData.weekdayOperOpenHhmm} ~ {detailData.weekdayOperColseHhmm} 운영
          </Caption1>
        </Row>

        <Row style={{ gap: '10px', marginTop: 20 }}>
          <Button
            backgroundColor={colors.white}
            borderColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
            }}
            onClick={() => {
              if (detailData.phoneNumber === '') {
                alert('연결전화번호가 없습니다.');
              } else {
                setDial(detailData.phoneNumber);
              }
              // onClose();
            }}
          >
            <Caption2 weight={'bold'} color={'primary'}>
              전화연결
            </Caption2>
          </Button>
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
            }}
            onClick={() => {
              setIsModal(true);
            }}
          >
            <Caption2 weight={'bold'} color={'white'}>
              상세보기
            </Caption2>
          </Button>
        </Row>

        <GeojuParkingPublicDetailModal
          isVisible={isModal}
          onClose={() => {
            setIsModal(false);
          }}
          type={'jinju'}
        />
      </ModalContainer>
    </>
  );
};

export default observer(JinjuInfoModal);
