import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TitleSpan,
  PhoneNumberContainer,
  ListItemContainer,
  SettingAppInfoContainer,
  SectionHeaderContainer,
  DescSpan,
} from './styles';
import { IcCall16SVG, IcChatSVG } from 'assets/ic';
import { setDial } from 'utils/deviceUtils';
import { handleChatClick } from 'utils/common';
import { Caption1, Caption2 } from 'styles/typography';
import Row from 'components/atoms/Row';

const SettingCustomerSupport = () => {
  const navigate = useNavigate();

  const appInfoItems = [
    {
      textTitle: '채팅 문의',
      content: '문의 남기기',
      onClick: () => {
        handleChatClick();
      },
    },
    {
      textTitle: '전화 문의',
      content: '1666-4369',
      onClick: () => {
        setDial('1666-4369');
      },
    },
  ];

  return (
    <>
      <SectionHeaderContainer>
        <Caption2 weight={'regular'} color={'gray06'}>
          고객지원
        </Caption2>
      </SectionHeaderContainer>
      <SettingAppInfoContainer>
        {appInfoItems.map((item, index) => {
          const { textTitle, content, onClick } = item;

          return (
            <ListItemContainer key={index} onClick={onClick}>
              <Row>
                {textTitle === '채팅 문의' ? <IcChatSVG /> : <IcCall16SVG />}
                <Caption1 weight={'regular'} color={'gray09'} style={{ marginLeft: 8 }}>
                  {textTitle}
                </Caption1>
              </Row>

              <PhoneNumberContainer onClick={onClick}>
                <DescSpan>{content}</DescSpan>
              </PhoneNumberContainer>
            </ListItemContainer>
          );
        })}
      </SettingAppInfoContainer>
    </>
  );
};

export default SettingCustomerSupport;
