import React, { useEffect, useState } from 'react';

import { Container, ParkingInfoSpan, WarpChargerItem } from './styles';
import Row from 'components/atoms/Row';
import { Body, Caption3, Caption4, SubHeadline } from 'styles/typography';
import { colors } from 'styles/colors';
import { MAP_CHARGER_TYPE } from 'components/atoms/Modal/ChargingInfoModal';

import { IcLightningSVG } from 'assets/ic';
import Button from 'components/atoms/Button/Button';

interface Props {
  data: any;
}

const ChargerList: React.FC<Props> = ({ data }) => {
  const [rapidChargers, setRapidChargers] = useState<any[]>([]);
  const [slowChargers, setSlowChargers] = useState<any[]>([]);
  const [rapidChargerTypes, setRapidChargerTypes] = useState<any[]>([]);
  const [slowChargerTypes, setSlowChargerTypes] = useState<any[]>([]);
  const [rapidStat2Count, setRapidStat2Count] = useState(0); // 급속 차저타입 stat = '2'인 개수
  const [slowStatNot2Count, setSlowStatNot2Count] = useState(0); // 완속 차저타입 stat != '2'인 개수

  useEffect(() => {
    // 기존 급속과 완속 충전기 분리
    const rapid: any = [];
    const slow: any = [];
    const rapidTypes = new Set(); // 급속 차저타입을 저장할 Set
    const slowTypes = new Set(); // 완속 차저타입을 저장할 Set
    let rapidStat2 = 0;
    let slowStat2 = 0;

    data.result.forEach((charger: any) => {
      const chargerType = MAP_CHARGER_TYPE.get(charger.chgerType);

      // `output` 값에 따라 급속과 완속을 분리
      if (Number(charger.output) >= 50) {
        rapid.push({ ...charger });
        rapidTypes.add(chargerType); // 급속 차저타입 추가
        if (charger.stat === '2') {
          rapidStat2 += 1; // 급속이면서 stat = '2'
        }
      } else {
        slow.push({ ...charger });
        slowTypes.add(chargerType); // 완속 차저타입 추가
        if (charger.stat === '2') {
          slowStat2 += 1; // 급속이면서 stat = '2'
        }
      }
    });

    setRapidChargers(rapid);
    setSlowChargers(slow);
    setRapidChargerTypes(Array.from(rapidTypes)); // 급속 차저타입 리스트
    setSlowChargerTypes(Array.from(slowTypes)); // 완속 차저타입 리스트
    setRapidStat2Count(rapidStat2); // 급속 충전가능 여부
    setSlowStatNot2Count(slowStat2); // 완속 충전가능 여부
  }, [data]);

  const RenderItem = ({ list }: { list: any }) => {
    return list.map((el: any, index: number) => (
      <WarpChargerItem
        key={index}
        style={{
          backgroundColor: el.stat === '2' ? '#fff' : colors.primaryBackground,
          border: el.stat === '2' ? '1px solid #CCC' : 'none',
        }}
      >
        <Row style={{ marginBottom: 8 }} justifyContent={'space-between'}>
          <SubHeadline weight={'semibold'} color={el.stat === '2' ? 'primary' : 'gray09'}>
            {el.stat === '2' ? '충전가능' : '충전중'}
          </SubHeadline>
          <SubHeadline weight={'semibold'} color={'gray06'}>
            {String(index + 1).padStart(2, '0')}
          </SubHeadline>
        </Row>

        <Caption3 weight={'medium'} color={'gray06'}>
          {el.elapsedTimeText}
        </Caption3>
      </WarpChargerItem>
    ));
  };

  return (
    <Container>
      <Row justifyContent={'space-between'}>
        <Row>
          <ParkingInfoSpan>충전기 목록</ParkingInfoSpan>
        </Row>
        <Row style={{ whiteSpace: 'pre-wrap' }}>
          <Caption3 weight={'medium'} color={'gray07'}>
            현재 충전 가능 :{' '}
          </Caption3>
          <Caption3 weight={'medium'} color={'gray07'}>
            급속{' '}
          </Caption3>
          <Caption3 weight={'medium'} color={'primary'}>
            {rapidStat2Count}
          </Caption3>
          <Caption3 weight={'medium'} color={'gray07'}>
            {' '}
            /{' '}
          </Caption3>
          <Caption3 weight={'medium'} color={'gray07'}>
            완속{' '}
          </Caption3>
          <Caption3 weight={'medium'} color={'primary'}>
            {slowStatNot2Count}
          </Caption3>
        </Row>
      </Row>

      {data.chargerStat.rapidTotalCnt !== 0 ? (
        <>
          <Row style={{ marginTop: 28, marginBottom: 4 }} justifyContent={'space-between'}>
            <Row>
              <Body weight={'semibold'} color={'black3'}>
                급속
              </Body>
              <IcLightningSVG fill={'#FFD600'} />
            </Row>
            <Button
              disabled={true}
              backgroundColor={colors.gray01}
              radius={4}
              horizontal={8}
              style={{
                height: 24,
              }}
            >
              <Caption4 weight={'semibold'} color={'gray07'}>
                {rapidChargerTypes.join('+')}
              </Caption4>
            </Button>
          </Row>
          <RenderItem list={rapidChargers} />
        </>
      ) : null}

      {data.chargerStat.slowTotalCnt !== 0 ? (
        <>
          <Row style={{ marginTop: 28, marginBottom: 4 }} justifyContent={'space-between'}>
            <Body weight={'semibold'} color={'black3'}>
              완속
            </Body>
            <Button
              disabled={true}
              backgroundColor={colors.gray01}
              radius={4}
              horizontal={8}
              style={{
                height: 24,
              }}
            >
              <Caption4 weight={'semibold'} color={'gray07'}>
                {slowChargerTypes.join('+')}
              </Caption4>
            </Button>
          </Row>
          <RenderItem list={slowChargers} />
        </>
      ) : null}
    </Container>
  );
};

export default ChargerList;
