import React, { useEffect, useState } from 'react';
import Title from 'components/atoms/Title/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { isIOS } from 'react-device-detect';

import {
  Container,
  SearchInput,
  WrapSearch,
  SearchIcon,
  WrapMap,
  PhoneNumberContainer,
} from './styles';
import { MainContainerNoMargin } from 'components/styles';
import { Headline, SubHeadline } from 'styles/typography';
import Map from 'components/atoms/Map';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import PopUpModal from 'components/atoms/Modal/PopupModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Row from 'components/atoms/Row';
import AddressSearch from 'containers/monthlyParking/AddressSearch';
import { IcDeleteTextSVG, IcSearchSVG } from 'assets/ic';
import { fetchAddressData } from 'service/Monthly/monthlyAddressService';
import { monthlyStore } from 'stores/store/Monthly/monthlyStore';

const keysToKeep = [
  'mLinkUID',
  'jibunAddr',
  'roadAddr',
  'addr',
  'zipNo',
  'admCd',
  'bdNm',
  'siNm',
  'sggNm',
  'emdNm',
  'emdNo',
  'latitude',
  'longitude',
  'placeName',
];

const AreaSettingPage: React.FC = () => {
  const navigate = useNavigate();

  const [addressText, setAddressText] = useState<string>(''); // 추가된 상태
  const [isPopup, setIsPopup] = useState(false);
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchResult, setSearchResult] = useState<any>({});

  const { memberUID } = userInfoStore;

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const onSelectAddress = async (result: any) => {
    const res = await fetchAddressData(result.road_address_name);
    setSearchResult(res.addr === '' ? { addr: result.address_name } : res);
    setAddressText(result.place_name);
    setValue(result.place_name);
    setIsFocused(false);
  };

  const init = () => {
    setValue('');
    setIsFocused(false);
    setAddressText('');
    setSearchResult([]);
  };

  const popupText = {
    title: '지역 설정 안내',
    description: '· 월 주차 희망지역이 설정되었습니다.',
  };

  return (
    <MainContainerNoMargin
      style={{ height: isIOS ? 'calc(100vh - env(safe-area-inset-top))' : 'calc(100vh)' }}
    >
      <Title
        title="지역 설정"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <PhoneNumberContainer>
          <Row>
            <Headline weight={'semibold'} color={'primary'}>
              월 주차 희망 지역
            </Headline>
            <Headline weight={'semibold'} color={'black1'}>
              을 알려주세요
            </Headline>
          </Row>

          <WrapSearch>
            {/* 주소 입력창 */}
            <SearchInput
              value={value}
              onChange={e => setValue(e.target.value)}
              placeholder={'주소, 장소 검색'}
              onFocus={() => setIsFocused(true)}
            />
            {value ? (
              <IcDeleteTextSVG
                onClick={() => {
                  init();
                }}
              />
            ) : (
              <IcSearchSVG />
            )}
          </WrapSearch>
        </PhoneNumberContainer>
        {isFocused ? <AddressSearch onSelectAddress={onSelectAddress} value={value} /> : null}

        <WrapMap
          style={{
            opacity: addressText && !isFocused ? 1 : 0,
          }}
        >
          {addressText ? (
            <Map height={'100%'} address={addressText} draggable={false} zoomControl={false} />
          ) : null}
          <Button
            style={{
              position: 'fixed',
              bottom: '30px',
              left: 20,
              height: 46,
              width: 'calc(100% - 40px)',
              zIndex: 1000,
            }}
            backgroundColor={colors.primary}
            onClick={openPopup}
          >
            <SubHeadline weight={'regular'} color={'white'}>
              이 위치로 설정
            </SubHeadline>
          </Button>
        </WrapMap>
      </Container>

      <PopUpModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={'월 주차 희망 지역 등록 완료했어요.'}
        leftClick={async () => {
          closePopup();
          if (addressText) {
            const filteredData = Object.keys(searchResult)
              .filter(key => keysToKeep.includes(key)) // 필요한 키들만 필터링
              .reduce((obj: any, key) => {
                obj[key] = searchResult[key]; // 필터링된 키를 새로운 객체에 추가
                return obj;
              }, {});

            const result = {
              ...filteredData,
              mLinkUID: memberUID,
              placeName: addressText,
            };
            await monthlyStore.fetchInsertDesiredAddr(result);
          }
          navigate(-1);
        }}
        leftText={'확인'}
      />
    </MainContainerNoMargin>
  );
};

export default AreaSettingPage;
