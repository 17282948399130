import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { GeojuInfoModalContainer } from './styles';
import { minyoung } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import Dropdown from 'components/atoms/Dropdown';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { Caption2 } from 'styles/typography';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import CancelReasonStore from 'stores/store/CancelReason/CancelResaonStore';

import icZoomansaLogo from 'assets/ic/ic_zoomansa_logo.svg';
import icIotLabel from 'assets/ic/ic_iot_label.svg';

interface GeojuInfoBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  lat: string;
  lon: string;
  setIsCautionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GeojuInfoBottomModal: React.FC<GeojuInfoBottomModalProps> = ({
  isVisible,
  onClose,
  lat,
  lon,
  setIsCautionModal,
}) => {
  const [selectIndex, setSelectIndex] = useState(0);
  const [dropdownItems, setDropdownItems] = useState<string[]>([]);

  const { memberData } = userInfoStore;

  const handleOverlayClick = () => {
    onClose();
  };

  useEffect(() => {
    const items: string[] = [];
    minyoung.slotList.map(el => items.push(el.slotCode));

    setDropdownItems(items);
  }, []);

  useEffect(() => {
    if (CancelReasonStore.selectedReason) {
      setSelectIndex(CancelReasonStore.selectedReason - 1);
    }
  }, [CancelReasonStore.selectedReason]);

  if (!isVisible) return null;

  return (
    <GeojuInfoModalContainer
      isVisible={isVisible}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontFamily: 'Pretendard',
          fontSize: '13px',
          fontWeight: 400,
          fontStyle: 'normal',
          lineHeight: '13px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            borderRadius: '4px',
            border: '1px solid #999',
            padding: '4px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              width: '13px',
              height: '13px',
            }}
            src={icZoomansaLogo}
            alt={'주만사 아이콘'}
          />
          <span>공유</span>
        </div>

        <div
          style={{
            borderRadius: '4px',
            border: '1px solid #999',
            padding: '4px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '4px',
          }}
        >
          <img
            style={{
              width: '13px',
              height: '13px',
            }}
            src={icIotLabel}
            alt={'iot 아이콘'}
          />
          <span>IoT</span>
        </div>
        <div
          style={{
            borderRadius: '4px',
            border: '1px solid #999',
            padding: '4px 4px',
            marginLeft: '4px',
          }}
        >
          거주자
        </div>
        <div
          style={{
            borderRadius: '4px',
            border: '1px solid #999',
            padding: '4px 4px',
            marginLeft: '4px',
          }}
        >
          시간제
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '12px',
        }}
      >
        <div
          style={{
            color: '#1A1A1A',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            textAlign: 'left',
            flex: '1',
            width: '50%',
          }}
        >
          {minyoung.slotList[selectIndex].regionRealName}
        </div>

        <div
          style={{
            width: '30%',
          }}
        >
          <Dropdown reasons={dropdownItems} placeholder={minyoung.slotList[selectIndex].slotCode} />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <span
          style={{
            fontSize: 15,
            color: '#989898',
          }}
        >
          5분 당
        </span>
        <div
          style={{
            textAlign: 'right',
            marginLeft: 'auto',
            fontSize: 15,
            color: '#444446',
            fontWeight: 700,
          }}
        >
          {formatPriceWithCommas(minyoung.slotList[selectIndex].feeAmount)}원
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            fontSize: 15,
            color: '#989898',
          }}
        >
          1시간
        </div>
        <div
          style={{
            textAlign: 'right',
            marginLeft: 'auto',
            fontSize: 15,
            color: '#444446',
            fontWeight: 700,
          }}
        >
          {formatPriceWithCommas(minyoung.slotList[selectIndex].defaultAmount)}원
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            fontSize: 15,
            color: '#989898',
          }}
        >
          평일
        </div>
        <div
          style={{
            textAlign: 'right',
            marginLeft: 'auto',
            fontSize: 15,
            color: '#444446',
          }}
        >
          {minyoung.slotList[selectIndex].shareStartTime} ~{' '}
          {minyoung.slotList[selectIndex].shareEntTime}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            fontSize: 15,
            color: '#989898',
          }}
        >
          주말(공휴일)
        </div>
        <div
          style={{
            textAlign: 'right',
            marginLeft: 'auto',
            fontWeight: 700,
            color: minyoung.slotList[selectIndex].weekendShareYN === 'Y' ? '#333' : colors.red,
          }}
        >
          {minyoung.slotList[selectIndex].weekendShareYN === 'Y'
            ? '토, 일 (공)휴일 00:00 ~ 24:00'
            : '이용불가'}
        </div>
      </div>

      <Row style={{ gap: '10px', marginTop: 20 }}>
        <Button
          backgroundColor={colors.white}
          borderColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
          }}
          onClick={() => {
            onClose();
            openNavi(
              String(lat),
              String(lon),
              minyoung.slotList[selectIndex].regionRealName,
              memberData?.naviType ?? null,
            );
          }}
        >
          <Caption2 weight={'bold'} color={'primary'}>
            길 안내받기
          </Caption2>
        </Button>
        <Button
          backgroundColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
          }}
          onClick={() => {
            setIsCautionModal(true);
          }}
        >
          <Caption2 weight={'bold'} color={'white'}>
            바로 이용하기
          </Caption2>
        </Button>
      </Row>
    </GeojuInfoModalContainer>
  );
};

export default observer(GeojuInfoBottomModal);
