import { styled } from '@stitches/react';

export const PaymentSuccessTopContainer = styled('div', {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
});

export const SharedContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  height: 66
})

export const SharedContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginRight: 20,
  gap: 8
})

export const PaymentTopContainer = styled('div', {
  marginTop: '80px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
});

export const PaymentCheckContainer = styled('div', {
  marginTop: '16px',
});

export const BottomContainer = styled('div', {
  position: 'fixed',
  bottom: 20,
  left: 20,
  width: '100%',
  // padding: '20px'
});

export const TicketButtonContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  width: 'calc(100% - 40px)',
  padding: '20px',
});

export const CheckButton = styled('button', {
  height: '46px',
  backgroundColor: '#8B63BB',
  border: 'none',
  borderRadius: '4px',
  marginTop: '28px',

  width: 'calc(100% - 40px)',
});
