import React, { useState, useEffect } from 'react';

import { Modal } from './styles';

import useMainPopup from 'hooks/useMainPopup';
import Row from 'components/atoms/Row';

import IMAGE from 'assets/images/img_monthly_popup.svg';
import { Caption1 } from 'styles/typography';

interface PopupModalProps {}

const MainPopupModal: React.FC<PopupModalProps> = ({}) => {
  const { isPopupVisible, closePopup, closeForAWeek } = useMainPopup();

  if (!isPopupVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}
        onClick={closePopup}
      />

      <Modal isVisible={isPopupVisible}>
        <img src={IMAGE} width={'100%'} height={'100%'} />
        <Row
          justifyContent={'space-between'}
          style={{ width: '100%', padding: 10, boxSizing: 'border-box' }}
        >
          <Caption1 weight={'regular'} color={'white'} onClick={closeForAWeek}>
            일주일 동안 보지 않기
          </Caption1>

          <Caption1 weight={'regular'} color={'white'} onClick={closePopup}>
            닫기
          </Caption1>
        </Row>
      </Modal>
    </>
  );
};

export default MainPopupModal;
