import { useState, useEffect } from 'react';

const useKeyboardVisible = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      setIsKeyboardVisible(true);  // 키보드가 올라갔을 때
    }

    const handleBlur = () => {
      setIsKeyboardVisible(false);  // 키보드가 내려갔을 때
    };

    window.addEventListener('focus', handleFocus, true);
    window.addEventListener('blur', handleBlur, true);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return { isKeyboardVisible };
};

export default useKeyboardVisible;

