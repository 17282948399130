import React from 'react';

import { Container, ImageTextContainer, ParkingNumberSpan, TopParkingInfoSpan } from './styles';
import Row from 'components/atoms/Row';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Button from 'components/atoms/Button/Button';
import { Caption2 } from 'styles/typography';

import icLocation from 'assets/ic/ic_location_gray.svg';
import icCallGray from 'assets/ic/ic_call_gray.svg';
import { IcDirections16SVG, IcZoomansaLogoSVG } from 'assets/ic';

interface Props {
  data: any;
}

const BasicInfo: React.FC<Props> = ({ data }) => {
  const { memberData } = userInfoStore;

  return (
    <Container>
      <Row justifyContent={'space-between'}>
        <Row>
          <img
            width={18}
            height={18}
            src={data.chargerStat.logoImagePath}
            style={{ marginRight: 5 }}
            alt={'기관로고'}
          />
          <ParkingNumberSpan>{data.chargerStat.statNm}</ParkingNumberSpan>
        </Row>

        <Button
          backgroundColor={'#fff'}
          radius={16}
          style={{
            height: 32,
          }}
          borderColor={'#CCC'}
          onClick={() => {
            const name = data.chargerStat.statNm;
            const lat = data.result[0].lat;
            const lng = data.result[0].lng;
            openNavi(String(lat), String(lng), name, memberData?.naviType ?? '');
          }}
        >
          <Row>
            <IcDirections16SVG fill={'#1D75CA'} />
            <Caption2 weight={'semibold'} color={'gray07'} style={{ marginLeft: 4 }}>
              길찾기
            </Caption2>
          </Row>
        </Button>
      </Row>

      <ImageTextContainer>
        <img src={icLocation} alt={'로케이션 회색 아이콘'} />
        <TopParkingInfoSpan>{data.result[0].addr}</TopParkingInfoSpan>
        <Caption2
          weight={'medium'}
          color={'blue'}
          style={{ marginLeft: 4 }}
          onClick={() => {
            //https 만 적용
            window.navigator.clipboard.writeText(data.result[0].addr);
          }}
        >
          복사
        </Caption2>
      </ImageTextContainer>
      <ImageTextContainer>
        <img src={icCallGray} alt={'전화기 회색 아이콘'} />
        <TopParkingInfoSpan>{data.result[0].busiCall}</TopParkingInfoSpan>
      </ImageTextContainer>
    </Container>
  );
};

export default BasicInfo;
