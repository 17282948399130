import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '28px 20px',
});

export const ImageTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  margin: '12px 8px 0px 0px',
});

export const TopParkingInfoSpan = styled('span', {
  marginLeft: '7px',
  fontSize: 14,
  fontWeight: 400,
});
