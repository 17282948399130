import { TabMenu, Submenu } from './styles';

interface TabProps {
  menuArr: string[];
  currentTab: number;
  onTabClick?: (index: number) => void;
}

const Tab: React.FC<TabProps> = ({ menuArr, onTabClick, currentTab }) => {
  const selectMenuHandler = (index: number) => {
    if (onTabClick) {
      onTabClick(index);
    }
  };

  return (
    <TabMenu>
      {menuArr.map((el, index) => (
        <Submenu
          key={index}
          focused={index === currentTab}
          onClick={() => selectMenuHandler(index)} // 클릭 시 인덱스 전달
        >
          {el}
        </Submenu>
      ))}
    </TabMenu>
  );
};

export default Tab;
