import { styled, keyframes, globalCss } from '@stitches/react';
import 'react-calendar/dist/Calendar.css';
import { colors } from 'styles/colors';

// 슬라이드 업 애니메이션
export const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)', opacity: 0 },
  '100%': { transform: 'translateY(0)', opacity: 1 },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
});

export const ModalContainer = styled('div', {
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 50px)',
  margin: '25px',
  padding: '15px',
  border: '2px solid #E4E4E4',
  animation: `${slideUp} 0.3s ease-out`,
  zIndex: 10000,
  position: 'relative',
  boxSizing: 'border-box',
});

export const ConsultButtonWrapper = styled('div', {
  position: 'absolute',
  top: '-35px',
  right: '0px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 10001,
});

export const ConsultButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 9px',
  backgroundColor: '#8B63BB',
  color: '#fff',
  border: 'none',
  borderRadius: '20px',
  cursor: 'pointer',
  width: 'auto',
  textAlign: 'center',
  gap: '4px',
  fontSize: '14px',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 700,
  '& img': {
    height: 'auto',
    width: 'auto',
  },
});

export const MinganPlacePhotoWeekContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MinganPlaceWeekContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const PlaceSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const WeekSpan = styled('span', {
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '4px',
});

export const WeekEmphasisSpan = styled('span', {
  fontWeight: 700,
});

export const PhotoSizeContainer = styled('div', {
  width: '64px',
  height: '64px',
  borderRadius: '4px',
  position: 'relative',
});

export const MinganParkingKindContainer = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '10px',
  paddingBottom: '13px',
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
});

export const ShareContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '3px',
  border: '2px solid #8B63BB',
  padding: '3px 9px',
  textAlign: 'center',
  gap: '4px',
  fontSize: '13px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  color: '#8B63BB',
});

export const ZoomansaIc = styled('img', {
  width: '7px',
  height: '8px',
});

export const MinganKindListContainer = styled('div', {
  marginLeft: '4px',
  height: 24,
  padding: '0px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F2F4F7',
  borderRadius: 4,
});

export const AmountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const DiscountText = styled('div', {
  color: '#B0B0B0',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '13px',
  textDecorationLine: 'line-through',
});

export const DiscountPercentText = styled('span', {
  color: '#ED1659',
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginRight: '4px',
});

export const Bubble = styled('div', {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '70%',
  height: '200px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  border: '1px solid #dbdbdb',
  zIndex: 1000,
  overflow: 'hidden',

  '@media (max-height: 932px)': {
    top: '36%',
  },

  '@media (max-height: 915px)': {
    top: '35%',
  },

  '@media (max-height: 896px)': {
    top: '35%',
  },

  '@media (max-height: 844px)': {
    top: '34%',
  },

  '@media (max-height: 740px)': {
    top: '33%',
  },

  '@media (max-height: 667px)': {
    top: '30%',
  },
});

export const CarKind = styled('input', {
  padding: '5px',
  border: '1px solid #dbdbdb',
  width: '174px',
  outline: 'none',
  fontWeight: 400,
  fontFamily: 'Pretendard',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#868686',
  height: '35px',
  borderRadius: '5px',
  marginTop: '4px',

  '&::placeholder': {
    color: '#B0B0B0',
  },
});

export const MyLocation = styled('div', {
  backgroundColor: colors.white,
  width: 44,
  height: 44,
  borderRadius: 44,
  filter: 'drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.08))',
  position: 'absolute',
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
