export enum PaymentAvailability {
  YES = 'YES',
  NO = 'NO',
}

export enum RegionType {
  PUBLIC = 'public',
  DAILY = 'DAILY',
  ZOOMANSA_HOURLY = 'zoomansaHourly',
}

export enum UsageStatus {
  USED = '1',
  UNUSED = '0',
}
