import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ItemTextContainer,
  ItemText,
  ArrowIcon,
  SectionHeaderContainer,
  ItemListContainer,
  ItemContainer,
  SettingTermsListContainer,
} from './styles';
import icArrowRightGrayMedium from '../../../assets/ic/ic_arrow_right_gray_medium.svg';
import { Caption1, Caption2 } from 'styles/typography';

const SettingTermsList = () => {
  const navigate = useNavigate();

  const termsItems = [
    {
      textTitle: '주만사 서비스 이용 약관',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/policyService'),
    },
    {
      textTitle: '개인정보 취급방침',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/policyPersonal'),
    },
    {
      textTitle: '위치 기반 서비스',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/policyLocation'),
    },
    {
      textTitle: '마케팅 수신 동의',
      imgArrowLeft: icArrowRightGrayMedium,
      onClick: () => navigate('/policyMarketing'),
    },
  ];

  return (
    <SettingTermsListContainer>
      <SectionHeaderContainer>
        <Caption2 weight={'regular'} color={'gray06'}>
          이용 약관
        </Caption2>
      </SectionHeaderContainer>
      <ItemListContainer>
        {termsItems.map((item, index) => (
          <ItemContainer key={index} onClick={item.onClick}>
            <ItemTextContainer>
              <Caption1 weight={'regular'} color={'gray09'}>
                {item.textTitle}
              </Caption1>
            </ItemTextContainer>
            <ArrowIcon src={item.imgArrowLeft} alt="애로우 아이콘" />
          </ItemContainer>
        ))}
      </ItemListContainer>
    </SettingTermsListContainer>
  );
};

export default SettingTermsList;
