import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Container, WrapEmptyItem, WrapItem } from './styles';
import { Body2, Caption1, Caption2, Caption3, SubHeadline } from 'styles/typography';
import { IcCloseBlack28SVG, IcCloseBlackSVG, IcPlusSVG } from 'assets/ic';
import { colors } from 'styles/colors';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { truncateText } from 'utils/common';
import MonthlyDesiredAreaBottomModal from 'components/atoms/Modal/MonthlyDesiredAreaBottomModal';
import { monthlyStore } from 'stores/store/Monthly/monthlyStore';
import { IDesiredAddressData } from 'models/Monthly/desiredAddressModel';

const DesiredAreaRegisterList = observer(() => {
  const navigate = useNavigate();

  const [isPopup, setIsPopup] = useState(false);
  const [selected, setSelected] = useState(0);
  const [isModal, setIsModal] = useState(false);

  const { parkingDesiredArea, setParkingDesiredArea, isLoading } = monthlyStore;

  const openPopup = () => {
    setIsPopup(true);
  };

  const closePopup = () => {
    setIsPopup(false);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const EmptyItem = ({}) => (
    <WrapEmptyItem onClick={() => navigate('/areaSetting')}>
      <IcPlusSVG fill={colors.primary} style={{ marginRight: 8 }} />
      <SubHeadline weight="medium" color="black3">
        희망 지역 등록하기
      </SubHeadline>
    </WrapEmptyItem>
  );

  if (parkingDesiredArea.length === 0) {
    return (
      <Container>
        <EmptyItem />
        <EmptyItem />
      </Container>
    );
  }

  return (
    <Container>
      {parkingDesiredArea.map((el, index) => (
        <WrapItem
          key={index} // 고유한 key 생성
          onClick={() => {
            setIsModal(true);
            setSelected(index);
          }}
        >
          <div>
            <Caption1 weight="medium" color="black3">
              {el.placeName ? el.placeName : el.addr}
            </Caption1>
            {el.placeName ? (
              <Caption3 weight="regular" color="gray06" style={{ marginTop: 4 }}>
                {el.addr}
              </Caption3>
            ) : null}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: 60,
              height: '100%',
              justifyContent: 'center',
            }}
            onClick={e => {
              e.stopPropagation();
              openPopup();
              setSelected(index);
            }}
          >
            <IcCloseBlack28SVG />
          </div>
        </WrapItem>
      ))}
      {parkingDesiredArea.length === 1 && <EmptyItem />}

      <PopupModal
        isVisible={isPopup}
        onClose={closePopup}
        title1={
          selected !== null
            ? `${truncateText(parkingDesiredArea[selected].placeName ?? parkingDesiredArea[selected].addr ?? '', 15)}을 삭제하시나요?`
            : ''
        }
        leftClick={closePopup}
        leftText={'취소'}
        rightText={'삭제하기'}
        rightClick={() => {
          closePopup();

          setParkingDesiredArea(
            parkingDesiredArea.filter(
              item => item?.addrUID !== parkingDesiredArea[selected]?.addrUID,
            ),
          );
          monthlyStore.fetchDeleteDesireAddr(parkingDesiredArea[selected].addrUID);
        }}
      />
      <MonthlyDesiredAreaBottomModal
        isVisible={isModal}
        onClose={closeModal}
        title={parkingDesiredArea[selected].placeName ?? parkingDesiredArea[selected].addr}
      />
    </Container>
  );
});

export default DesiredAreaRegisterList;
