import React, { CSSProperties, ReactNode } from 'react';
import { TitleContainer } from './styles';
import icArrowLeftPurple from '../../../../assets/ic/ic_arrow_left_purple.svg';
import icArrowLeftWhite from '../../../../assets/ic/ic_arrow_left_white.svg';
import { isIOS } from 'react-device-detect';

import Spacer from '../../Spacer/Spacer';
import { colors } from 'styles/colors';

interface IProps {
  leftOnClick?: () => void;
  rightOnClick?: () => void;
  title?: string | React.ReactNode;
  style?: CSSProperties;
  leftArrowButtonVisible?: boolean;
  rightTextButtonVisible?: boolean;
  rightTitle?: string | React.ReactNode;
  children?: React.ReactNode;
  background?: 'default' | 'purpleGradient' | 'gray' | 'lightGray';
  titleColor?: 'default' | 'white' | 'darkPurple';
  imgBackPurpleColor?: boolean;
  showSpacer?: boolean;
}

const TitleComponent: React.FC<IProps> = ({
  leftOnClick,
  rightOnClick,
  title,
  style,
  leftArrowButtonVisible,
  rightTextButtonVisible,
  rightTitle,
  children,
  background = 'default',
  titleColor = 'default',
  showSpacer = true,
  imgBackPurpleColor = true,
}) => {
  const getTitleColor = () => {
    switch (titleColor) {
      case 'white':
        return colors.white;
      case 'default':
        return colors.black3;
      case 'darkPurple':
        return colors.darkPurple;
      default:
        return colors[titleColor] || colors.black3;
    }
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          background: '#fff',
          zIndex: 2,
          height: isIOS ? 'calc(66px + env(safe-area-inset-top))' : '66px',
        }}
      >
        <div
          style={{
            height: 'env(safe-area-inset-top)',
          }}
        />

        <TitleContainer
          background={background}
          style={{ ...style }} // Combine styles
        >
          {leftArrowButtonVisible ? (
            <button
              type="button"
              style={{
                cursor: 'none',
                background: 'none',
                border: 'none',
                position: 'absolute',
                left: 12,
                padding: 0,
              }}
              onClick={leftOnClick}
              data-id="back"
            >
              <img
                width={'30px'}
                height={'30px'}
                src={imgBackPurpleColor ? icArrowLeftPurple : icArrowLeftWhite}
                alt="타이틀 뒤로가기 버튼"
              />
            </button>
          ) : null}

          <div
            style={{
              color: getTitleColor(),
              fontFamily: 'Pretendard',
              fontStyle: 'normal',
              lineHeight: 'normal',
              fontSize: '18px',
              fontWeight: '700',
              textAlign: 'center',
            }}
          >
            {title}
          </div>
          {rightTextButtonVisible ? (
            <div
              style={{
                fontSize: '16px',
                fontWeight: 500,
                fontFamily: 'Pretendard',
                fontStyle: 'normal',
                color: colors.gray05,
                lineHeight: 'normal',
                position: 'absolute',
                right: 20,
              }}
              onClick={rightOnClick}
            >
              {rightTitle}
            </div>
          ) : null}
        </TitleContainer>
      </div>
      {showSpacer && (
        <div
          style={{
            height: 66,
          }}
        />
      )}
      {children}
    </>
  );
};

export default TitleComponent;
