import React, { useState, useEffect } from 'react';
import imgHostBanner from '../../../assets/images/img_hostBanner.svg';
import imgGuestBanner from '../../../assets/images/img_guestBanner.svg';

const images = [imgHostBanner, imgGuestBanner]; // 이미지 배열
const intervalTime = 4000; // 이미지 전환 시간 (3초)

const SlideShow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    if (currentIndex === 0) {
      window.open('http://guestlanding.zoomansa.com/?type=24', '_blank');
    } else if (currentIndex === 1) {
      window.open('http://hostlanding.zoomansa.com/?type=25', '_blank');
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 40,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'calc(100% - 40px)',
        maxWidth: 480,
        textAlign: 'center',
      }}
    >
      <img
        src={images[currentIndex]}
        alt="Host Banner"
        style={{
          width: '100%',
          height: 'auto',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
    </div>
  );
};

export default SlideShow;
