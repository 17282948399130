import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../../components/atoms/Title/PageTitle';
import { MainContainer } from 'components/styles';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import SettingServiceList from '../SettingServiceList';
import SettingTermsList from '../SettingTermsList';
import SettingAppInfoList from '../SettingAppInfoList';
import SettingCustomerSupport from 'pages/Setting/SettingCustomerSupport';

const SettingMainPage: React.FC = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleLeftOnClick = () => {
    navigate('/home');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainer>
      <Title
        title="환경설정"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <SettingServiceList />
      <SettingTermsList />
      <SettingCustomerSupport />
      <SettingAppInfoList />
    </MainContainer>
  );
};

export default SettingMainPage;
