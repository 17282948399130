import React, { useEffect, useState } from 'react';
import {
  MinganInfoModalContainer,
  MinganListNumberContainer,
  MinganParkingWeekStatusText,
  ParkingNumberText,
  TicketContainer,
  TitleContainer,
} from './styles';
import MinganInfoModal from '../MinganInfoModal';
import { monthlyListData } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { Caption2, Caption3 } from 'styles/typography';
import { colors } from 'styles/colors';
import { IcBellSVG } from 'assets/ic';
import EmptyWaitingModal from 'components/atoms/Modal/EmptyWaitingModal';

interface MinganInfoListBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  onOpenAnotherModal: () => void;
  setIsEmptyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ParkingInfo {
  status: string;
  type: string;
  price: string;
  parkingNumber: string;
}

const MinganInfoListBottomModal: React.FC<MinganInfoListBottomModalProps> = ({
  isVisible,
  onClose,
  onOpenAnotherModal,
  setIsEmptyModal,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <MinganInfoModalContainer
        onClick={e => {
          e.stopPropagation();
        }}
        isScroll={monthlyListData.slotList.length > 2}
      >
        <TitleContainer>
          <span style={{ color: '#8B63BB' }}>희망하는 주차장</span>
          <span>을 선택해 주세요</span>
        </TitleContainer>

        <MinganListNumberContainer>
          총 {monthlyListData.slotList.length}건
        </MinganListNumberContainer>

        <div
          style={{
            overflowY: 'auto',
            padding: '0px 25px',
          }}
        >
          {monthlyListData.slotList.map((item, index) => {
            const isSale = item.productStatus === 'SALE';
            return (
              <TicketContainer key={index} onClick={() => onOpenAnotherModal()}>
                <Row alignItems={'center'} justifyContent={'space-between'}>
                  <div
                    style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}
                  >
                    <Button
                      vertical={3}
                      horizontal={8}
                      radius={4}
                      backgroundColor={isSale ? colors.primarySubSub : colors.gray03}
                    >
                      <Caption3 weight={'medium'} color={isSale ? 'primary' : 'gray07'}>
                        {isSale ? '판매중' : '판매완료'}
                      </Caption3>
                    </Button>
                    <ParkingNumberText
                      style={{ color: item.paymentAvailbleYN === 'YES' ? '#B0B0B0' : '#000000' }}
                    >
                      {item.slotUID} 주차장
                    </ParkingNumberText>
                  </div>
                  <Row>
                    <MinganParkingWeekStatusText
                      style={{ color: item.paymentAvailbleYN === 'YES' ? '#B0B0B0' : '#8B63BB' }}
                    >
                      <span
                        style={{
                          marginRight: '3px',
                          color: item.paymentAvailbleYN === 'YES' ? '#B0B0B0' : '#8B63BB',
                        }}
                      >
                        {item.operType === '0' ? '전일권' : '평일권'}
                      </span>
                      <span
                        style={{
                          color: item.paymentAvailbleYN === 'YES' ? '#B0B0B0' : '#333333',
                        }}
                      >
                        {formatPriceWithCommas(item.salePrice)}원
                      </span>
                    </MinganParkingWeekStatusText>
                  </Row>
                </Row>

                {!isSale ? (
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      onClose();
                      setIsEmptyModal(true);
                    }}
                  >
                    <Button
                      borderColor={colors.gray04}
                      style={{
                        width: '100%',
                        marginTop: 10,
                      }}
                      vertical={8}
                      radius={4}
                    >
                      <Row>
                        <IcBellSVG />
                        <Caption2 weight={'semibold'} color={'primary'}>
                          빈자리 알림받기
                        </Caption2>
                      </Row>
                    </Button>
                  </div>
                ) : null}
              </TicketContainer>
            );
          })}
        </div>
      </MinganInfoModalContainer>
    </>
  );
};

export default MinganInfoListBottomModal;
