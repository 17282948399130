import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { Container } from './styles';
import { SubHeadline } from 'styles/typography';
import ShareParkingAddress from 'containers/share/ShareParkingAddress';
import ShareStopTime from 'containers/share/ShareStopTime';
import ShareStopModal from 'components/atoms/Modal/ShareStopModal';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import ParkingAreaShare from 'containers/share/ParkingAreaShare';
import ShareDay, { DaySchedule } from 'containers/share/ShareDay';
import { BottomContainer } from 'pages/Share/ResidentShareManagementPage/styles';
import { shareResidentStore } from 'stores/store/Share/shareResidentStore';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';

const ResidentShareManagementPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { state } = useLocation();
  const { isGangseo, slotCode, regionCode, slotIdx, sharePerDayIdx, name } = state;

  const [isTimeStop, setIsTimeStop] = useState(false);
  const [isStopModal, setIsStopModal] = useState(false);
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [isToggle, setIsToggle] = useState<boolean | null>(null);
  const [isGangseoModal, setIsGangseoModal] = useState(false);
  const [list, setList] = useState<DaySchedule[]>([]);

  const { memberUID } = userInfoStore;
  const { residentSlotDetailInfo, residentSlotShareTimeDetail, isLoading } = shareResidentStore;

  const isStatusChange = isToggle !== (residentSlotDetailInfo?.useShared !== 0);

  const ModalText = {
    title: `${name}을\n공유 중지하시나요?`,
    description:
      '공유 중단된 후에는 오늘 다시 공유 재개할 수 없어요.\n' +
      '공유는 내일부터 자동으로 시작되어요.',
  };

  const GangseoStartModalText = {
    title: `${residentSlotDetailInfo?.address}을\n공유하시나요?`,
    description: '[확인] 후 [저장하기]까지 눌러야 반영돼요.',
  };

  const GangseoStopModalText = {
    title: `${name}을\n공유 중지하시나요?`,
    description: '공유 중지 시 그동안 공유가점이 안 쌓여요.',
  };

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    if (isGangseo) {
      const fetchData = async () => {
        try {
          await shareResidentStore.fetchGetResidentSlotShareTimeDetail(sharePerDayIdx);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [isGangseo]);

  useEffect(() => {
    setIsToggle(residentSlotDetailInfo?.useShared === 0 ? true : false);
  }, []);

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    const transformToList = () => {
      const days = [
        {
          day: '월',
          isActive: residentSlotShareTimeDetail?.mondayShareYN ?? 'Y',
          startTime: residentSlotShareTimeDetail?.mondayShareTime.split('|')[0] ?? '09:00',
          endTime: residentSlotShareTimeDetail?.mondayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '화',
          isActive: residentSlotShareTimeDetail?.tuesdayShareYN ?? 'Y',
          startTime: residentSlotShareTimeDetail?.tuesdayShareTime.split('|')[0] ?? '09:00',
          endTime: residentSlotShareTimeDetail?.tuesdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '수',
          isActive: residentSlotShareTimeDetail?.wednesdayShareYN ?? 'Y',
          startTime: residentSlotShareTimeDetail?.wednesdayShareTime.split('|')[0] ?? '09:00',
          endTime: residentSlotShareTimeDetail?.wednesdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '목',
          isActive: residentSlotShareTimeDetail?.thursdayShareYN ?? 'Y',
          startTime: residentSlotShareTimeDetail?.thursdayShareTime.split('|')[0] ?? '09:00',
          endTime: residentSlotShareTimeDetail?.thursdayShareTime.split('|')[1] ?? '18:00',
        },
        {
          day: '금',
          isActive: residentSlotShareTimeDetail?.fridayShareYN ?? 'Y',
          startTime: residentSlotShareTimeDetail?.fridayShareTime.split('|')[0] ?? '09:00',
          endTime: residentSlotShareTimeDetail?.fridayShareTime.split('|')[1] ?? '18:00',
        },
      ];
      const result = days.map(el => {
        return {
          day: el.day,
          startTime: el.startTime,
          endTime: el.endTime,
          isActive: el.isActive,
        };
      });
      setList(result);
    };

    transformToList();
  }, [residentSlotShareTimeDetail]);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const onCloseTimeStopModal = () => {
    setIsTimeStop(false);
  };

  const onCloseStopModal = () => {
    setIsStopModal(false);
  };

  const onCloseGangseoModal = () => {
    setIsGangseoModal(false);
  };

  const onChange = (hour: string, minute: string) => {
    setHour(hour);
    setMinute(minute);
  };

  const onToggle = (state: boolean) => {
    if (isGangseo && isToggle) {
      setIsGangseoModal(true);
    } else {
      setList([
        { day: '월', isActive: 'Y', startTime: '09:00', endTime: '18:00' },
        {
          day: '화',
          isActive: 'Y',
          startTime: '09:00',
          endTime: '18:00',
        },
        {
          day: '수',
          isActive: 'Y',
          startTime: '09:00',
          endTime: '18:00',
        },
        {
          day: '목',
          isActive: 'Y',
          startTime: '09:00',
          endTime: '18:00',
        },
        {
          day: '금',
          isActive: 'Y',
          startTime: '09:00',
          endTime: '18:00',
        },
      ]);

      setIsToggle(state);
    }
  };

  if (isLoading)
    return (
      <MainContainerNoMargin>
        <Title
          title="내 주차장"
          leftArrowButtonVisible
          leftOnClick={handleLeftOnClick}
          rightTextButtonVisible={true}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <Spinner />
        </div>
      </MainContainerNoMargin>
    );

  return (
    <MainContainerNoMargin>
      <Title
        title="공유 관리"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
      />
      <Container>
        <ShareParkingAddress name={name} />
        <Divider style={{ backgroundColor: colors.gray01, height: 8 }} />
        {isGangseo ? (
          <>
            <ParkingAreaShare isToggle={isToggle} onToggle={onToggle} />
            <Divider style={{ backgroundColor: colors.gray01, height: 8 }} />
            {isToggle ? (
              <ShareDay
                data={residentSlotShareTimeDetail}
                setList={setList}
                list={list}
                setIsToggle={setIsToggle}
              />
            ) : null}
          </>
        ) : (
          <>
            <ShareStopTime onClick={() => setIsTimeStop(true)} hour={hour} minute={minute} />
          </>
        )}

        {isGangseo ? (
          isToggle ? (
            //강서이면서 토글이 켜졌을 때 버튼 항상 활성화
            <BottomContainer>
              <Button
                radius={4}
                backgroundColor={colors.primary}
                style={{
                  height: 46,
                  width: 'calc(100% - 40px)',
                  marginBottom: 40,
                  marginTop: 20,
                }}
                onClick={async () => {
                  const updates: Record<string, string> = {};

                  list.forEach(item => {
                    switch (item.day) {
                      case '월':
                        updates.mondayShareYnUpdate = item.isActive;
                        updates.mondayShareTimeUpdate = `${item.startTime}|${item.endTime}`;
                        break;
                      case '화':
                        updates.tuesdayShareYnUpdate = item.isActive;
                        updates.tuesdayShareTimeUpdate = `${item.startTime}|${item.endTime}`;
                        break;
                      case '수':
                        updates.wednesdayShareYnUpdate = item.isActive;
                        updates.wednesdayShareTimeUpdate = `${item.startTime}|${item.endTime}`;
                        break;
                      case '목':
                        updates.thursdayShareYnUpdate = item.isActive;
                        updates.thursdayShareTimeUpdate = `${item.startTime}|${item.endTime}`;
                        break;
                      case '금':
                        updates.fridayShareYnUpdate = item.isActive;
                        updates.fridayShareTimeUpdate = `${item.startTime}|${item.endTime}`;
                        break;
                      default:
                        break;
                    }
                  });

                  try {
                    // 강서에서 공유 할 때
                    await shareResidentStore.fetchModifyResidentSlotShareTime(
                      slotCode,
                      regionCode,
                      sharePerDayIdx,
                      updates,
                    );

                    if (residentSlotDetailInfo?.useShared !== 0) {
                      showToast(`해당 주차면을 공유 시작했어요.`, icSuccessCheckGreen);
                    } else {
                      showToast(`저장 완료! 수정한 공유 시간은 내일부터 적용돼요.`);
                    }
                    navigate(-1);
                  } catch (err) {}
                }}
              >
                <SubHeadline weight={'bold'} color={'white'}>
                  저장하기
                </SubHeadline>
              </Button>
            </BottomContainer>
          ) : (
            // 강서면서 토글이 꺼졌을 때 기존에 꺼진 상태에서
            // 접근 후 켰다 꺼도 비활성화이여야 하므로 상태 비교
            <BottomContainer>
              <Button
                radius={4}
                backgroundColor={isStatusChange ? colors.gray06 : colors.primary}
                style={{
                  height: 46,
                  width: 'calc(100% - 40px)',
                  marginBottom: 40,
                  marginTop: 20,
                }}
                onClick={async () => {
                  setIsStopModal(true);
                }}
                disabled={isStatusChange}
              >
                <SubHeadline weight={'bold'} color={'white'}>
                  저장하기
                </SubHeadline>
              </Button>
            </BottomContainer>
          )
        ) : (
          //강서가 아닐 때
          <BottomContainer>
            <Button
              radius={4}
              backgroundColor={!hour ? colors.gray06 : colors.primary}
              style={{
                height: 46,
                width: 'calc(100% - 40px)',
                marginBottom: 40,
                marginTop: 20,
              }}
              onClick={() => {
                setIsStopModal(true);
                // navigate(-1)
              }}
              disabled={!hour}
            >
              <SubHeadline weight={'bold'} color={'white'}>
                저장하기
              </SubHeadline>
            </Button>
          </BottomContainer>
        )}
      </Container>

      {/*공유 중지 시간 바텀모달*/}
      <ShareStopModal
        isVisible={isTimeStop}
        onClose={onCloseTimeStopModal}
        rightClick={onChange}
        shareEndTime={residentSlotDetailInfo?.shareEndTime}
        title={'공유 중단 시간'}
      />

      {/* 중지 저장하기 누른 후 바텀시트 */}
      <CustomBottomModal
        isVisible={isStopModal}
        onClose={onCloseStopModal}
        title1={ModalText.title}
        description={ModalText.description}
        leftClick={onCloseStopModal}
        leftText={'취소'}
        rightClick={async () => {
          onCloseStopModal();
          navigate(-1);

          if (isGangseo) {
            // 강서이면서 공유 중지 할 때
            try {
              await shareResidentStore.fetchResidentSlotShareStop(slotIdx, sharePerDayIdx);
              showToast(`해당 주차면을 공유 중지시켰어요.`);
            } catch (err) {}
          } else {
            // 강서가 아니면서 공유 중지할 때
            try {
              await shareResidentStore.fetchModifyResidentSlotShareStopTime(
                slotIdx,
                `${hour}:${minute}`,
              );
              showToast(
                `${minute === '00' ? `${hour}시` : `${hour}시 ${minute}분`}부터 공유 중지돼요.`,
                icSuccessCheckGreen,
              );
            } catch (err) {}
          }
        }}
        rightText={'중지하기'}
      />

      {/* 강서구 면공유 토글 클릭 시 */}
      <CustomBottomModal
        isVisible={isGangseoModal}
        onClose={onCloseGangseoModal}
        title1={!isToggle ? GangseoStartModalText.title : GangseoStopModalText.title}
        description={
          !isToggle ? GangseoStartModalText.description : GangseoStopModalText.description
        }
        leftClick={onCloseGangseoModal}
        leftText={'취소'}
        rightClick={() => {
          setIsToggle(prev => !prev);

          onCloseGangseoModal();
        }}
        rightText={isToggle ? '중지하기' : '확인'}
      />
    </MainContainerNoMargin>
  );
});

export default ResidentShareManagementPage;
