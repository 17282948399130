import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer, MainContainerNoMargin } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import {
  CautionListContainer,
  CautionSpan,
  CheckBoxLabel,
  DeleteAccountEssentialSpan,
  StyledCheckbox,
  CautionTitleContentsContainer,
  WrapContent,
  ListText,
  BottomButtonContainer,
  ButtonEnableDisable,
  AmountPointContainer,
} from './styles';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { IcCheckBlueSVG, IcCheckSVG, IcTooltipAlertSVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import IcCheck18SVG from 'assets/ic/ic_check_18.svg';
import { observer } from 'mobx-react';

const DeleteAccountNotificationPage = observer(() => {
  const navigate = useNavigate();

  const [deleteAccountAgreement, setDeleteAccountAgreement] = React.useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteAccountAgreement(event.target.checked);
  };
  const handleSubmit = () => {
    if (!deleteAccountAgreement) {
      console.log('주의 사항에 동의하셔야 합니다.');
      return;
    }
    console.log('정보 삭제 요청');
    navigate('/deleteAccountReason');
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={() => navigate(-2)}
        rightTextButtonVisible={false}
      />

      <WrapContent>
        <Headline weight={'semibold'} color={'black1'}>
          주만사를 떠나신다니 아쉬워요.
        </Headline>
        <Caption1
          weight={'regular'}
          color={'gray09'}
          style={{ wordBreak: 'keep-all', marginTop: 20 }}
        >
          탈퇴 시 지금 이용하시는 계정 정보는 복구 불가하며, 탈퇴 후 3개월 동안은 주만사에 가입할 수
          없어요.
        </Caption1>
      </WrapContent>

      <AmountPointContainer>
        <SubHeadline weight={'semibold'} color={'black3'}>
          탈퇴하면 내가 보유한 주페이가 사라져요
        </SubHeadline>

        <Divider
          style={{ height: 1, backgroundColor: colors.gray02, marginTop: 16, marginBottom: 20 }}
        />
        <Row justifyContent={'space-between'}>
          <Caption3 weight={'regular'} color={'gray06'}>
            보유 주머니
          </Caption3>
          <Caption3 weight={'medium'} color={'gray09'}>
            430,000원
          </Caption3>
        </Row>
        <Row
          justifyContent={'space-between'}
          style={{
            marginTop: '10px',
          }}
        >
          <Caption3 weight={'regular'} color={'gray06'}>
            보유 포인트
          </Caption3>
          <Caption3 weight={'medium'} color={'gray09'}>
            13,500P
          </Caption3>
        </Row>
      </AmountPointContainer>

      <Divider style={{ height: 8, backgroundColor: colors.primaryBackground }} />
      <WrapContent>
        <Row>
          <IcTooltipAlertSVG width={18} height={18} />
          <SubHeadline weight={'bold'} color={'black1'} style={{ marginLeft: 8 }}>
            떠나기 전에 확인해 주세요.
          </SubHeadline>
        </Row>
        <Divider
          style={{ height: 1, backgroundColor: colors.gray02, marginTop: 16, marginBottom: 20 }}
        />

        <Row alignItems={'flex-start'}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            <span> 보유 중인 포인트 및 정산 예정금은 탈퇴 시 소멸</span>
            되며 어떠한 경우에도 복구할 수 없습니다.잔여 포인트 및 정산금은 회원탈퇴 전 미리
            사용하시거나 교환해 주세요.
          </ListText>
        </Row>

        <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            보존이 끝난 후 <span>회원님의 연락처</span>를 포함한{' '}
            <span>모든 정보는 영구적으로 삭제</span>되며 복구할 수 없습니다.
          </ListText>
        </Row>
        <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            <span>휴대폰 번호가 변경</span>었을 경우 <span>고객센터로 문의</span>
            주시면 처리 도와드리겠습니다.
          </ListText>
        </Row>
        <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            회원탈퇴 시 <span>불량 이용자의 재가입 방지, 부정 이용 방지, 명예훼손 등</span> 권리침해
            분쟁 및 수사협조 목적으로 최초 접속 시 제공한 정보를{' '}
            <span>회원 탈퇴 후 3개월 간 보존</span>
            합니다.
          </ListText>
        </Row>
        <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            주차장을 공유하고 계시거나 이용 중이신 경우 <span>공유를 종료하신 후 탈퇴</span>할 수
            있습니다.
          </ListText>
        </Row>
        <Row alignItems={'flex-start'} style={{ marginTop: 10 }}>
          <img src={IcCheck18SVG} style={{ width: 18, height: 18 }} alt={'check'} />
          <ListText>
            탈퇴 후 <span>3개월 동안</span>은 주만사에 다시 가입할 수 없습니다.
          </ListText>
        </Row>
        <Divider style={{ height: 1, backgroundColor: colors.gray02, marginTop: 20 }} />
      </WrapContent>
      <BottomButtonContainer>
        <CheckBoxLabel>
          <StyledCheckbox
            type="checkbox"
            checked={deleteAccountAgreement}
            onChange={handleCheckboxChange}
          />
          <Caption2 weight={'medium'} color={'gray09'} style={{ wordBreak: 'keep-all' }}>
            유의사항을 확인하였으며, 모든 정보를 삭제하는 것에 동의했어요.
          </Caption2>
        </CheckBoxLabel>

        <ButtonEnableDisable onClick={handleSubmit} disabled={!deleteAccountAgreement}>
          탈퇴 이유 남기기
        </ButtonEnableDisable>
      </BottomButtonContainer>
    </MainContainerNoMargin>
  );
});

export default DeleteAccountNotificationPage;
