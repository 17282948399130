import React from 'react';
import {
  ModalOverlay,
  TwoButtonLeftButton,
  TwoButtonModalContainer,
  TwoButtonModalTitleSpan,
  TwoButtonRightButton,
} from './styles';
import { TwoButtonModalContentContainer } from 'components/atoms/Modal/TwoButtonModal/styles';

interface CheckModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  leftButtonText: string;
  rightButtonText: string;
  rightClick: () => void;
}

const TowButtonModal: React.FC<CheckModalProps> = ({
  isVisible,
  onClose,
  title,
  content,
  leftButtonText,
  rightButtonText,
  rightClick,
}) => {
  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <TwoButtonModalContainer onClick={e => e.stopPropagation()}>
        <div style={{ padding: '30px' }}>
          <TwoButtonModalTitleSpan style={{ whiteSpace: 'pre-line' }}>
            {title}
          </TwoButtonModalTitleSpan>
        </div>
        <TwoButtonModalContentContainer content={!!content}>
          {content}
        </TwoButtonModalContentContainer>

        <div style={{ display: 'flex', width: '100%' }}>
          <TwoButtonLeftButton onClick={onClose}>{leftButtonText}</TwoButtonLeftButton>
          <TwoButtonRightButton
            onClick={() => {
              rightClick();
              onClose();
            }}
          >
            {' '}
            {rightButtonText}{' '}
          </TwoButtonRightButton>
        </div>
      </TwoButtonModalContainer>
    </ModalOverlay>
  );
};

export default TowButtonModal;
