// App.tsx
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'components/atoms/Toast/ToastProvider';
import { Provider } from 'mobx-react';
import { NavermapsProvider } from 'react-naver-maps';

import LoginPage from './pages/Login';
import ZMSLoginPage from './pages/ZMSLogin';
import AgreeTermsPage from './pages/AgreeTerms';
import Home from './pages/Home';
import SearchMyInfo from './pages/SearchMyInfo';
import NotificationCenter from './pages/NotificationCenter/NotificationCenterMain';
import ShareRequestMingan from './pages/Share/ShareRequestMingan';
import ParkingPhotoUpload from './pages/ParkingPhotoUpload';
import SettingMainPage from './pages/Setting/SettingMainPage';
import ShareRequestGeoju from './pages/Share/ShareRequestGeoju';
import MyPage from './pages/MyPage';
import PolicyServicePage from './pages/Policy/PolicyService';
import PolicyPersonalPage from './pages/Policy/PolicyPersonal';
import PolicyLocationPage from './pages/Policy/PolicyLocation';
import PolicyMarketingPage from './pages/Policy/PolicyMarketing';
import CarRegistration from './pages/Car/CarRegistration';
import ReductionCategory from './pages/Reduction/ReductionCategory';
import ReductionFileAttach from './pages/Reduction/ReductionFileAttach';
import ReductionApplySuccess from './pages/Reduction/ReductionApplySuccess';
import ReductionRegistrationSuccess from './pages/Reduction/ReductionRegistrationSuccess';
import ReductionRegistrationFail from './pages/Reduction/ReductionRegistrationFail';
import CarInfo from './pages/Car/CarInfo';
import CarInfoEdit from './pages/Car/CareInfoEdit';
import ParkingPhotoUploadDetail from './pages/ParkingPhotoUpload/ParkingPhotoUploadDetail';
import ParkingPhotoUploadComplete from './pages/ParkingPhotoUpload/ParkingPhotoUploadComplete';
import NotificationCenterGeneralMessageDetail from './pages/NotificationCenter/NotificationCenterGeneralMessageDetail';
import NotificationCenterImportantMessageDetail from './pages/NotificationCenter/NotificationCenterImportantMessageDetail';
import DeleteAccountReason from './pages/DeleteAccount/DeleteAccountReason';
import SignUpCertPage from './pages/SignUp/SignUpCert';
import SignUpCreateUserInfo from './pages/SignUp/SignUpCreateUserInfo';
import PaymentUserInfoCheck from './pages/Payment/PaymentUserInfoCheck';
import PointHistoryPage from './pages/Point/PointHistory';
import PointAccountRegiPage from './pages/Point/PointAccountRegi';
import PointWithdrawalPasswordPage from './pages/Point/PointWithdrawal/PointWithdrawalPassword';
import PointEarnExpectedPage from './pages/Point/PointEarnExpected';
import PointLoseExpectedPage from './pages/Point/PointLoseExpected';
import PointWithdrawalIdentityCertPage from './pages/Point/PointWithdrawal/PointWithdrawalIdentityCert';
import PointWithdrawalMoneyPage from './pages/Point/PointWithdrawal/PointWithdrawalMoney';
import PointWithdrawalFinalCheckPage from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalCheck';
import PointWithdrawalFinalSuccessPage from './pages/Point/PointWithdrawalFinal/PointWithdrawalFinalSuccess';
import PointMallBrandPage from './pages/Point/PointMall/PointMallBrand';
import PointWithdrawalPasswordSettingPage from './pages/Point/PointWithdrawal/PointWithdrawalPasswordSetting';
import PointMallBrandDetailPage from 'pages/Point/PointMall/PointMallBrandDetail';
import PointMallStorageBoxPage from 'pages/Point/PointMall/PointMallStorageBox';
import PointWithdrawalPasswordSettingRePage from 'pages/Point/PointWithdrawal/PointWithdrawalPasswordSettingRe';
import { toggleStore } from 'stores/toggle/toggleStore';
import PaymentVirtualAccount from './pages/Payment/PaymentVirtualAccount';
import TerraceTowerDetailPage from './pages/TerraceTower/TerraceTowerDetail';
import TerraceTowerPaymentCancelRequestPage from './pages/TerraceTower/TerraceTowerPaymentCancelRequest';
import TerraceTowerReservationCautionPage from './pages/TerraceTower/TerraceTowerReservationCaution';
import TerraceTowerPaymentCancelPage from './pages/TerraceTower/TerraceTowerPaymentCancel';
import TerraceTowerPaymentPage from './pages/TerraceTower/TerraceTowerPayment';
import TerraceTowerPaymentResultPage from './pages/ExternalPayment';
import PolicyPersonalSupplyPage from 'pages/Policy/PolicyPersonalSupply';

import ShareHistoryPage from 'pages/Share/ShareHistoryPage';
import MinganProductDetailPage from 'pages/Share/MinganProductDetailPage';
import ResidentMyParkingPage from 'pages/Share/ResidentMyParkingPage';
import ResidentShareManagementPage from 'pages/Share/ResidentShareManagementPage';
import MinganMyParkingPage from 'pages/Share/MinganMyParkingPage';
import OperationInfoPage from 'pages/Share/OperationInfoPage';
import EmptyParkingAreaPage from 'pages/EmptyParkingArea';
import IdentityVerificationPage from 'pages/MyPage/IdentityVerification';
import MyParkingMainPage from 'pages/MyParkingTicket/MyPakringMain';
import MyParkingPastHistoryPage from 'pages/MyParkingTicket/MyParkingPastHistory';

import GeojuParkingUsingPage from 'pages/GeojuParking/GeojuParkingUsingPage';
import GeojuParkingStopPage from 'pages/GeojuParking/GeojuParkingStopPage';
import GeojuParkingPaymentPage from 'pages/GeojuParking/GeojuParkingPaymentPage';
import GeojuParkingPaymentSuccessPage from 'pages/GeojuParking/GeojuParkingPaymentSuccessPage';
import DesiredAreaManagementPage from 'pages/MonthlyParkingDesired/DesiredAreaManagementPage';
import AreaSettingPage from 'pages/MonthlyParkingDesired/AreaSettingPage';
import SignUpMonthlyDesiredOnboardingPage from 'pages/SignUp/SignUpMonthlyDesiredOnboardingPage';
import DeleteAccountCertPage from './pages/DeleteAccount/DeleteAccountCert';
import ImageLinkPage from 'pages/ImageLink';
import { useFetchUserInfo } from 'hooks/useFetchUserInfo';
import DeleteAccountNotificationPage from 'pages/DeleteAccount/DeleteAccountNotification';
import EntryCheckPage from 'pages/TerraceTower/EntryCheckPage';
import KakaoRoadViewPage from 'pages/KakaoRoadView';

import DailyReservationCautionWebPage from 'pages/Daily/DailyReservationCautionWebPage';
import DailyAgreeTermsPage from 'pages/Daily/DailyAgreeTermsPage';
import DailyIdentityVerificationPage from 'pages/Daily/DailyIdentityVerificationPage';
import DailyPaymentPage from 'pages/Daily/DailyPaymentPage';
import NoticePage from 'pages/Notice';

function App() {
  const { fetchUserInfo } = useFetchUserInfo();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    window.checkInfoDailyBottomDialogState = function () {
      // 홈의 일주차 세팅할때만 호출 되어야됨 일단 주석처리 jsp는 정상 동작 중
    };
    return () => {
      delete window.checkInfoDailyBottomDialogState;
    };
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <NavermapsProvider
        ncpClientId={process.env.REACT_APP_NAVER_CLIENT_ID ?? ''}
        // or finClientId, govClientId
      >
        <ToastProvider>
          <Provider toggleStore={toggleStore}>
            <Router>
              <Routes>
                // 포인트
                <Route path="/pointHistory" element={<PointHistoryPage />} />
                <Route path="/pointAccountRegi" element={<PointAccountRegiPage />} />
                <Route path="/pointEarnExpected" element={<PointEarnExpectedPage />} />
                <Route path="/pointLoseExpected" element={<PointLoseExpectedPage />} />
                <Route
                  path="/pointWithdrawalIdentityCert"
                  element={<PointWithdrawalIdentityCertPage />}
                />
                <Route path="/pointWithdrawalPassword" element={<PointWithdrawalPasswordPage />} />
                <Route
                  path="/pointWithdrawalPasswordSetting"
                  element={<PointWithdrawalPasswordSettingPage />}
                />
                <Route
                  path="/pointWithdrawalPasswordSettingRe"
                  element={<PointWithdrawalPasswordSettingRePage />}
                />
                <Route path="/pointWithdrawalMoney" element={<PointWithdrawalMoneyPage />} />
                <Route
                  path="/pointWithdrawalFinalCheck"
                  element={<PointWithdrawalFinalCheckPage />}
                />
                <Route
                  path="/pointWithdrawalFinalSuccess"
                  element={<PointWithdrawalFinalSuccessPage />}
                />
                <Route path="/pointMallBrand" element={<PointMallBrandPage />} />
                <Route
                  path="/pointMallBrandDetail/:brandName"
                  element={<PointMallBrandDetailPage />}
                />
                <Route path="/pointMallStorageBox" element={<PointMallStorageBoxPage />} />
                <Route path="/zmsLogin" element={<ZMSLoginPage />} />
                <Route path="/Login" element={<LoginPage />} />
                <Route path="/agreeTerms" element={<AgreeTermsPage />} />
                <Route path="/signUpCert" element={<SignUpCertPage />} />
                <Route path="/signUpCreateUserInfo" element={<SignUpCreateUserInfo />} />
                <Route path="/home" element={<Home />} />
                <Route path="/searchMyInfo" element={<SearchMyInfo />} />
                // 내주차권
                <Route path="/myParkingMain" element={<MyParkingMainPage />} />
                // 내주차권 지난내역
                <Route path="/myParkingPastHistory" element={<MyParkingPastHistoryPage />} />
                <Route path="/shareRequestMingan" element={<ShareRequestMingan />} />
                <Route path="/shareRequestGeoju" element={<ShareRequestGeoju />} />
                <Route path="/notificationCenter" element={<NotificationCenter />} />
                <Route
                  path="/notificationCenterGeneralMessageDetail"
                  element={<NotificationCenterGeneralMessageDetail />}
                />
                <Route
                  path="/notificationCenterImportantMessageDetail"
                  element={<NotificationCenterImportantMessageDetail />}
                />
                <Route path="/settingMain" element={<SettingMainPage />} />
                <Route path="/myInfo" element={<MyPage />} />
                <Route path="/identityVerification" element={<IdentityVerificationPage />} />
                <Route path="/policyPersonalSupply" element={<PolicyPersonalSupplyPage />} />
                <Route path="/policyService" element={<PolicyServicePage />} />
                <Route path="/policyPersonal" element={<PolicyPersonalPage />} />
                <Route path="/policyLocation" element={<PolicyLocationPage />} />
                <Route path="/policyMarketing" element={<PolicyMarketingPage />} />
                <Route path="/deleteAccountCert" element={<DeleteAccountCertPage />} />
                <Route
                  path="/deleteAccountNotification"
                  element={<DeleteAccountNotificationPage />}
                />
                <Route path="/deleteAccountReason" element={<DeleteAccountReason />} />
                <Route path="/carRegistration" element={<CarRegistration />} />
                <Route path="/carInfo" element={<CarInfo />} />
                <Route path="/carInfoEdit" element={<CarInfoEdit />} />
                <Route path="/reductionCategory" element={<ReductionCategory />} />
                <Route path="/reductionFileAttach" element={<ReductionFileAttach />} />
                <Route path="/reductionApplySuccess" element={<ReductionApplySuccess />} />
                <Route
                  path="/reductionRegistrationSuccess"
                  element={<ReductionRegistrationSuccess />}
                />
                <Route path="/reductionRegistrationFail" element={<ReductionRegistrationFail />} />
                <Route path="/parkingPhotoUpload" element={<ParkingPhotoUpload />} />
                <Route path="/parkingPhotoUploadDetail" element={<ParkingPhotoUploadDetail />} />
                <Route
                  path="/parkingPhotoUploadComplete"
                  element={<ParkingPhotoUploadComplete />}
                />
                <Route path="/shareHistory" element={<ShareHistoryPage />} />
                <Route path="/minganMyParking" element={<MinganMyParkingPage />} />
                <Route path="/minganProductDetail" element={<MinganProductDetailPage />} />
                <Route path="/residentMyParking" element={<ResidentMyParkingPage />} />
                <Route path="/shareRequestMingan" element={<ShareRequestMingan />} />
                <Route path="/shareRequestGeoju" element={<ShareRequestGeoju />} />
                <Route path="/residentShareManagement" element={<ResidentShareManagementPage />} />
                <Route path="/operatingInfo" element={<OperationInfoPage />} />
                <Route path="/notificationCenter" element={<NotificationCenter />} />
                <Route
                  path="/notificationCenterGeneralMessageDetail"
                  element={<NotificationCenterGeneralMessageDetail />}
                />
                <Route
                  path="/notificationCenterImportantMessageDetail"
                  element={<NotificationCenterImportantMessageDetail />}
                />
                // 결제
                <Route path="/paymentUserInfoCheck" element={<PaymentUserInfoCheck />} />
                <Route path="/paymentVirtualAccount" element={<PaymentVirtualAccount />} />
                // 테라스타워 상세
                <Route path="/terraceTowerDetail" element={<TerraceTowerDetailPage />} />
                // 테라스타워 결제 취소 요청
                <Route
                  path="/terraceTowerPaymentCancelRequest"
                  element={<TerraceTowerPaymentCancelRequestPage />}
                />
                // 테라스타워 결제 취소
                <Route
                  path="/terraceTowerPaymentCancel"
                  element={<TerraceTowerPaymentCancelPage />}
                />
                // 테라스타워 예약 전 주의
                <Route
                  path="/terraceTowerReservationCaution"
                  element={<TerraceTowerReservationCautionPage />}
                />
                // 테라스타워 결제
                <Route path="/terraceTowerPayment" element={<TerraceTowerPaymentPage />} />
                // 세종결제
                <Route
                  path="/terraceTowerPaymentResult"
                  element={<TerraceTowerPaymentResultPage />}
                />
                // 빈자리 신청 화면
                <Route path="/emptyParkingArea" element={<EmptyParkingAreaPage />} />
                //거주자 주차장 이용
                <Route path="/geojuParkingUsing" element={<GeojuParkingUsingPage />} />
                //거주자 주차장 이용 종료
                <Route path="/geojuParkingStop" element={<GeojuParkingStopPage />} />
                //거주자 주차장 이용 결제
                <Route path="/geojuParkingPayment" element={<GeojuParkingPaymentPage />} />
                //거주자 주차장 이용 결제 성공
                <Route
                  path="/geojuParkingPaymentSuccess"
                  element={<GeojuParkingPaymentSuccessPage />}
                />
                <Route path="/desiredAreaManagement" element={<DesiredAreaManagementPage />} />
                <Route path="/areaSetting" element={<AreaSettingPage />} />
                <Route
                  path="/signUpMonthlyDesiredOnboarding"
                  element={<SignUpMonthlyDesiredOnboardingPage />}
                />
                // 배포 이미지 링크 확인용 페이지
                <Route path="/imageLink" element={<ImageLinkPage />} />
                <Route path="/entryCheck" element={<EntryCheckPage />} />
                <Route path="/KakaoRoadView" element={<KakaoRoadViewPage />} />
                <Route
                  path="/dailyReservationCautionWeb"
                  element={<DailyReservationCautionWebPage />}
                />
                <Route path="/dailyAgreeTerms" element={<DailyAgreeTermsPage />} />
                <Route
                  path="/dailyIdentityVerification"
                  element={<DailyIdentityVerificationPage />}
                />
                <Route path="/dailyPayment" element={<DailyPaymentPage />} />
                <Route path="/notice" element={<NoticePage />} />
              </Routes>
            </Router>
          </Provider>
        </ToastProvider>
      </NavermapsProvider>
    </Suspense>
  );
}

export default App;
