import React, { useEffect, useState } from 'react';

import { ModalContainer, Overlay } from './styles';
import Lottie from 'react-lottie';
import lottie from 'assets/lottie/lottie_waiting_alram.json';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const EmptyWaitingModal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  if (!isVisible) return null;

  return (
    <>
      <Overlay onClick={onClose}>
        <ModalContainer
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Lottie options={defaultOptions} height={100} width={100} />
          <Headline weight={'medium'} color={'black1'} style={{ marginTop: 8, marginBottom: 16 }}>
            대기 신청 완료했어요!
          </Headline>
          <Caption1 weight={'medium'} color={'gray08'}>
            빈자리가 생기면 카카오톡으로 알려드립니다.
          </Caption1>
          <Caption1 weight={'medium'} color={'gray08'}>
            결제한 순으로 이용 가능하니 유의해 주세요.
          </Caption1>
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              onClose();
            }}
          >
            <SubHeadline weight={'bold'} color={'white'}>
              확인
            </SubHeadline>
          </Button>
        </ModalContainer>
      </Overlay>
    </>
  );
};

export default EmptyWaitingModal;
