import Title from '../../../components/atoms/Title/PageTitle';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CautionTitleContainer,
  ExclamationBlackIc,
  PhotoCountContainer,
  RoadViewContainer,
  RoadViewIcon,
  RoadViewSpan,
  RoadViewTextContainer,
  TimeTicketNotUseSlotGuideContain,
  UploadedImage,
  UploadedImageContainer,
  UploadImageSection,
} from './styles';
import icDotGray from 'assets/ic/ic_dot_gray.svg';
import icReceipt from 'assets/ic/ic_receip.svg';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import icRoadView from 'assets/ic/ic_road_view.svg';
import {
  AddressContainer,
  BottomButtonContainer,
  BottomDifferentParkingTicketButton,
  BottomLeftButton,
  BottomRightButton,
  CarParkingInfoContainer,
  CautionContainer,
  CautionsAndUseSlotGuideListContainer,
  ParkingInfoContainer,
  PaymentCancelContainer,
  PaymentInfoContentContainer,
  PaymentItemContainer,
  PaymentValue,
  ReceiptContainer,
  ReceiptIc,
  TerraceTowerBottomContentsContainer,
  TerraceTowerContainer,
  TicketTimeInfoContainer,
  UseSlotGuideListItemDotIc,
  ViewDivide1,
  ViewDivide2,
  ViewDivide3,
} from '../styles';
import imgParkingForeground from 'assets/images/img_parking_foreground.png';
import imgParkingGate from 'assets/images/img_parking_gate.png';
import imgParkingInside1 from 'assets/images/img_parking_inside1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import icNotImage from 'assets/ic/ic_not_image.svg';
import { observer } from 'mobx-react';
import { terraceTowerUsageHistoryDetailStore } from 'stores/store/TerraceTower/terraceTowerUsageHistoryDetailStore';
import { copyToClipboard } from 'utils/clipboardUtils';
import { setShared, showWebBrowser } from 'utils/deviceUtils';
import { Caption1, Caption2, Caption3, Caption4, Headline, SubHeadline } from 'styles/typography';
import LoadingSpinnerScreen from 'components/atoms/LoadingSpinner/LoadingSpinnerScreen';
import RefundBottomModal from 'components/atoms/Modal/RefundBottomModal';
import { MainContainerNoMargin } from 'components/styles';
import { moveJSP } from 'utils/common';
import TimeTicketCautionsComponent from 'containers/timeTicket/TimeTicketCautions/TimeTicketCautionsComponent';
import { IcShareSVG } from 'assets/ic';
import Row from 'components/atoms/Row';
import ParkingLocation from 'components/atoms/ParkingLocation';

interface Image {
  src: string;
  alt: string;
}

type useSlotGuideComponentProps = {
  useSlotGuide?: string;
};

const TerraceTowerDetailPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    usageStatus,lotUID,
    useSlotGuide,
    cautions,
    lotName,
    address,
    latitude,
    longitude,
    photoURL,
    productName,
    carNumber,
    parkingAvailTime,
    paymentMethod,
    paymentAmount,
    operationTime,
    paymentDate,
    receiptURL,
    usageDate,
    refundReqDate,
  } = terraceTowerUsageHistoryDetailStore.usageHistoryDetail || {};

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const saleHistoryUID = queryParams.get('saleHistoryUID') || '';
  const type = queryParams.get('type') || '';

  const [isRefundBottomModal, setIsRefundBottomModal] = useState(false);
  const [images, setImages] = useState<Image[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  if (saleHistoryUID != null) {
    sessionStorage.setItem('saleHistoryUID', saleHistoryUID);
  }

  function UseSlotGuideComponent({ useSlotGuide }: useSlotGuideComponentProps) {
    const formattedSlotGuide = useSlotGuide ? (
      useSlotGuide
        .replace(/\n/g, '')
        .split(/(?<=\.)/)
        .map((useSlotGuide, index) => (
          <CautionsAndUseSlotGuideListContainer key={index} first={index === 0}>
            {useSlotGuide.trim().length > 0 && (
              <UseSlotGuideListItemDotIc src={icDotGray} alt="점 아이콘" />
            )}
            <SubHeadline color={'gray08'} weight={'regular'}>
              {useSlotGuide.trim()}
            </SubHeadline>
          </CautionsAndUseSlotGuideListContainer>
        ))
    ) : (
      <TimeTicketNotUseSlotGuideContain>
        <UseSlotGuideListItemDotIc src={icDotGray} alt="점 아이콘" />
        <SubHeadline color={'gray08'} weight={'regular'}>
          이용 안내가 없습니다.
        </SubHeadline>
      </TimeTicketNotUseSlotGuideContain>
    );
    return <div>{formattedSlotGuide}</div>;
  }

  function onShare() {

    const shareUrl = `https://ars.zoomansa.com/shareLink.jsp?dailyParkingShareLotType=hourly&dailyParkingShareLat=${latitude}&dailyParkingShareLng=${longitude}&dailyParkingShareSid=${lotUID}`;

    const shareText = [
      "[주차할 땐 주만사]",
      "",
      `주차장명 : ${lotName}`,
      `주소 : ${address}`,
      "",
      `운영시간 : ${operationTime}`,
      "",
      "자세한 정보는 주만사 앱에서 확인해 주세요!",
      "",
      `📌 ${shareUrl}`
    ].join("\n"); // 줄바꿈을 직접 제어

    setShared(shareText);
  }

  useEffect(() => {
    if (photoURL) {
      const images = photoURL?.map((url, index) => ({
        src: url,
        alt: `주차장 이미지 ${index + 1}`,
      }));

      setImages(images);
    }
  }, [photoURL]);

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTerraceTowerUsageHistoryDetail = async () => {
      await terraceTowerUsageHistoryDetailStore.fetchTerraceTowerUsageHistoryDetail(saleHistoryUID);
    };

    fetchTerraceTowerUsageHistoryDetail().catch(error => {
      console.log('테라스 타워 주차정 정보 가져오는 중 에러 발생', error);
    });
  }, []);

  const handleBackPress = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleBackPress();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const ticketInfoList = [
    { label: '차량정보', value: carNumber },
    { label: '주차가능 일시', value: parkingAvailTime },
    usageStatus === '결제취소'
      ? { label: '취소일시', value: refundReqDate }
      : usageStatus === '이용완료'
        ? { label: '이용완료', value: usageDate }
        : null,
  ];

  const paymentInfo = [
    { label: '결제수단', value: paymentMethod },
    { label: '결제금액', value: paymentAmount },
    { label: '결제일시', value: paymentDate },
    usageStatus === '결제취소' ? { label: '취소일시', value: refundReqDate } : null,
  ];

  const slideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    zIndex: 1,
    afterChange: handleAfterChange,
  };

  if (terraceTowerUsageHistoryDetailStore.isLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <MainContainerNoMargin>
      <Title
        title="상세내역"
        leftArrowButtonVisible={type !== 'web'}
        leftOnClick={() => {
          handleBackPress();
        }}
        rightTextButtonVisible={false}
        rightImage={ <IcShareSVG/> }
        rightTitle={ <SubHeadline weight={'bold'} color={'gray06'}>공유</SubHeadline> }
        rightOnClick={() => {
          onShare()
        }}
      />

      <TerraceTowerContainer>
        <Headline weight={'bold'} color={'primary'}>
          {usageStatus}
        </Headline>
        <SubHeadline weight={'semibold'} color={'black1'} css={{ marginTop: 6 }}>
          {lotName}
        </SubHeadline>
        <AddressContainer
          onClick={() => {
            if (address) {
              copyToClipboard(address, showToast, icSuccessCheckGreen, icFailCheckRed);
            } else {
              console.error('주소가 없습니다.');
            }
          }}
        >
          <Caption2 weight={'regular'} css={{ color: '#6b6b6b', marginRight: 4 }}>
            {address}
          </Caption2>
          <Caption2 weight={'medium'} color={'blue'}>
            복사
          </Caption2>
        </AddressContainer>
        {usageStatus === '이용전' ? (
          <div style={{ paddingTop: 10 }}>
            <ParkingLocation height={'200px'} lat={Number(latitude)} lng={Number(longitude)} />
          </div>
        ) : null}
      </TerraceTowerContainer>

      {/* TODO 추후에 세팅 */}
      {/*<MapContainer />*/}
      {/*<GuideLoadContainer>*/}
      {/*  <GuideLoadIc src={icGuideLoad} alt={'길 안내 아이콘'} />*/}
      {/*  <GuideLoadSpan>길 안내받기</GuideLoadSpan>*/}
      {/*</GuideLoadContainer>*/}

      <ViewDivide1 />
      {usageStatus === '이용전' ? (
        <TerraceTowerContainer>
          <SubHeadline weight={'bold'} css={{ color: '#333' }} style={{ marginTop: '8px' }}>
            이렇게 이용해요
          </SubHeadline>
          <ViewDivide2 />
          <UseSlotGuideComponent useSlotGuide={useSlotGuide} />

          <TicketTimeInfoContainer>
            <SubHeadline weight={'semibold'} css={{ color: '#333' }}>
              {productName}
            </SubHeadline>
            <ViewDivide3 />
            <CarParkingInfoContainer>
              {ticketInfoList.map((ticketItem, index) => {
                if (ticketItem === null) {
                  return null;
                }
                return (
                  <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                    <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                      {ticketItem.label}
                    </Caption1>
                    <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                      {ticketItem.value}
                    </Caption1>
                  </ParkingInfoContainer>
                );
              })}
            </CarParkingInfoContainer>
          </TicketTimeInfoContainer>

          {/* 로드뷰는 추후 세팅 예정*/}
          {images.length ? (
            <UploadImageSection>
              <RoadViewTextContainer
                onClick={() => {
                  navigate('/KakaoRoadView', {
                    state: {
                      latitude: latitude,
                      longitude: longitude,
                    },
                  });
                }}
              >
                <RoadViewIcon src={icRoadView} alt={'로드뷰 아이콘'} />
                <RoadViewSpan>로드뷰</RoadViewSpan>
              </RoadViewTextContainer>
              <PhotoCountContainer>
                {currentSlide + 1}/{images.length}
              </PhotoCountContainer>
              <Slider {...slideSettings}>
                {images.map((image, index) => (
                  <UploadedImageContainer key={index}>
                    <UploadedImage src={image.src} alt={image.alt} />
                  </UploadedImageContainer>
                ))}
              </Slider>
            </UploadImageSection>
          ) : (
            <RoadViewContainer>
              <img src={icNotImage} alt={'이미지 없는 아이콘'} />
              <span style={{ marginTop: '10px' }}>이미지 준비중이에요</span>
            </RoadViewContainer>
          )}
        </TerraceTowerContainer>
      ) : (
        <div style={{ margin: '0px 20px' }}>
          <TicketTimeInfoContainer>
            <SubHeadline weight={'semibold'} css={{ color: '#333' }}>
              {productName}
            </SubHeadline>
            <ViewDivide3 />
            <CarParkingInfoContainer>
              {ticketInfoList.map((ticketItem, index) => {
                if (ticketItem === null) {
                  return null;
                }
                return (
                  <ParkingInfoContainer key={index} style={{ marginTop: index === 0 ? 0 : '10px' }}>
                    <Caption1 weight={'regular'} css={{ color: '#868686' }}>
                      {ticketItem.label}
                    </Caption1>
                    <Caption1 weight={'medium'} css={{ color: '#555555' }}>
                      {ticketItem.value}
                    </Caption1>
                  </ParkingInfoContainer>
                );
              })}
            </CarParkingInfoContainer>
          </TicketTimeInfoContainer>
        </div>
      )}

      <ViewDivide1 />
      <TerraceTowerContainer>
        <PaymentInfoContentContainer>
          <SubHeadline weight={'bold'} css={{ color: '#333333' }}>
            결제정보
          </SubHeadline>
          <ReceiptContainer
            onClick={() => {
              if (!receiptURL) {
                console.error('receiptURL이 정의되지 않음');
                return;
              }
              showWebBrowser(receiptURL);
            }}
          >
            <ReceiptIc src={icReceipt} alt={'영수증 아이콘'} />
            <Caption3 weight={'medium'} css={{ color: '#555555', marginLeft: 4 }}>
              영수증
            </Caption3>
          </ReceiptContainer>
        </PaymentInfoContentContainer>
        {paymentInfo.map((info, index) => {
          if (info)
            return (
              <PaymentItemContainer key={index} isFirst={index === 0}>
                <Caption1 weight={'regular'} style={{ color: '#868686' }}>
                  {info.label}
                </Caption1>
                <PaymentValue isUsePayment={index === 0}>{info.value}</PaymentValue>
              </PaymentItemContainer>
            );
        })}
      </TerraceTowerContainer>

      {usageStatus === '이용전' && <ViewDivide1 />}

      {usageStatus === '이용전' && (
        <TerraceTowerBottomContentsContainer>
          <CautionTitleContainer>
            <ExclamationBlackIc src={icExclamationBlack} alt={'검정 배경 느낌표'} />
            <SubHeadline weight={'bold'} css={{ color: '#333333', marginLeft: 10 }}>
              주차권 유의사항
            </SubHeadline>
          </CautionTitleContainer>
          <ViewDivide2 />
          <TimeTicketCautionsComponent cautions={cautions} />
          <CautionContainer>
            <Caption3 weight={'regular'} css={{ color: '#868686' }}>
              유의사항 미숙지로 인해 발생한 피해와 주차장 내에서
            </Caption3>
            <Caption3 weight={'regular'} css={{ color: '#868686' }}>
              발생한 사고에 대해서는 일체 책임지지 않습니다.
            </Caption3>
          </CautionContainer>
          <PaymentCancelContainer>
            <BottomLeftButton
              onClick={() => {
                navigate('/terraceTowerPaymentCancelRequest');
              }}
            >
              환불 요청하기
            </BottomLeftButton>
            <BottomRightButton
              onClick={() => {
                moveJSP();
              }}
            >
              홈으로
            </BottomRightButton>
          </PaymentCancelContainer>
        </TerraceTowerBottomContentsContainer>
      )}

      {usageStatus !== '이용전' && (
        <BottomButtonContainer>
          {usageStatus === '이용완료' || usageStatus === '기간만료' ? (
            <BottomButtonContainer>
              <BottomLeftButton
                onClick={() => {
                  setIsRefundBottomModal(true);
                }}
              >
                환불 요청하기
              </BottomLeftButton>
              <BottomRightButton
                onClick={() => {
                  moveJSP();
                }}
              >
                다른 주차권 보기
              </BottomRightButton>
            </BottomButtonContainer>
          ) : (
            <BottomDifferentParkingTicketButton
              onClick={() => {
                moveJSP();
              }}
            >
              홈으로
            </BottomDifferentParkingTicketButton>
          )}
        </BottomButtonContainer>
      )}

      <RefundBottomModal
        isVisible={isRefundBottomModal}
        onClose={() => {
          setIsRefundBottomModal(false);
        }}
        usageStatus={usageStatus ?? ''}
      />
    </MainContainerNoMargin>
  );
});

export default TerraceTowerDetailPage;
