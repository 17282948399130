import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapInfo } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { IShareResidentSlotDetailInfoData } from 'models/Share/shareResidentSlotDetailInfo';
import { residentParkingLots } from 'models/Share/shareHistoryModel';

interface Props {
  isSale: boolean;
  data: IShareResidentSlotDetailInfoData;
  selectedSlotInfo: residentParkingLots | null;
}

const ResidentParkInfo: React.FC<Props> = ({ isSale, data, selectedSlotInfo }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        backgroundColor={isSale ? colors.primarySubSub : colors.gray03}
        vertical={3}
        horizontal={8}
      >
        <Row>
          <Caption2 weight={'medium'} color={isSale ? 'primary' : 'gray07'}>
            {isSale ? '공유중' : '공유중지'}
          </Caption2>
        </Row>
      </Button>
      <Headline weight={'semibold'} color={'black1'} style={{ marginTop: 8 }}>
        {selectedSlotInfo?.regionName}
      </Headline>
      <WrapInfo>
        <Row justifyContent={'space-between'} style={{ marginBottom: 9 }}>
          <Caption2 weight={'medium'} color={'gray09'}>
            관할 자치구
          </Caption2>
          <SubHeadline weight={'bold'} color={'black3'}>
            {data.areasggNm}
          </SubHeadline>
        </Row>
        <Row justifyContent={'space-between'}>
          <Caption2 weight={'medium'} color={'gray09'}>
            구획 번호
          </Caption2>
          <SubHeadline weight={'bold'} color={'black3'}>
            {data.slotCode}
          </SubHeadline>
        </Row>
        {data.useShared === 0 && data.unitKind === 'R' ? (
          <Row justifyContent={'space-between'} style={{ marginTop: 9 }}>
            <Caption2 weight={'medium'} color={'gray09'}>
              공유시간
            </Caption2>
            <SubHeadline weight={'bold'} color={'black3'}>
              {data.sharedTime}
            </SubHeadline>
          </Row>
        ) : null}
      </WrapInfo>
    </Container>
  );
};

export default ResidentParkInfo;
