import React, { useState } from 'react';
import { TabMenu, Submenu, Desc } from './styles';
import { Caption1 } from 'styles/typography';

interface TabProps {
  menuArr: {
    name: string;
    content?: React.ReactNode;
    ref?: React.RefObject<HTMLDivElement>;
  }[];
  currentTab: number; // 현재 탭 상태 추가
  onClick?: (index: number) => void; // onTabClick prop 추가
}

const NewTabBar: React.FC<TabProps> = ({ menuArr, currentTab, onClick }) => {
  const selectMenuHandler = (index: number) => {
    // onTabClick 호출
    if (onClick) {
      onClick(index); // 상위 컴포넌트에 인덱스 전달
    }
  };

  return (
    <>
      <TabMenu>
        {menuArr.map((el, index) => (
          <Submenu
            key={index}
            focused={index === currentTab}
            onClick={() => selectMenuHandler(index)} // 클릭 시 인덱스 전달
          >
            <Caption1
              weight={index === currentTab ? 'semibold' : 'medium'}
              color={index === currentTab ? 'primaryDark' : 'gray04'}
              style={{ marginLeft: 4 }}
            >
              {' '}
              {el.name}
            </Caption1>
            <Caption1
              weight={'bold'}
              color={index === currentTab ? 'primary' : 'gray04'}
              style={{ marginLeft: 4 }}
            >
              {' '}
              {el.content}
            </Caption1>
          </Submenu>
        ))}
      </TabMenu>
    </>
  );
};

export default NewTabBar;
