import { styled } from '@stitches/react'

export const BeforeUseSpan = styled('div', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
})

export const MapContainer = styled('div', {
  height: '140px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '5px',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const GuideLoadContainer = styled('div', {
  width: '100%',
  height: '46px',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#F8F8FA',
  borderRadius: '3px',
  marginTop: '20px',
  color: '#fff',
  border: '1px solid #CCC', // Default border color
  justifyContent: 'center',
  alignItems: 'center'
})

export const UseCautionContainer = styled('div', {
  marginTop: '20px'
})

export const UseCautionItem = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  variants: {
    hasMargin: {
      true: { marginTop: '10px' },
      false: { marginTop: '0' }
    }
  }
})

export const RoadViewContainer = styled('div', {
  height: '186px',
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '5px',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
})

export const UploadImageSection = styled('div', {
  position: 'relative',
  height: '186px',
  marginTop: '28px',
  overflow: 'hidden',
  width: '100%',
  borderRadius: '5px',
})

export const UploadedImageContainer = styled('div', {
  width: '100%',
  height: '186px',
  position: 'relative',
})

export const UploadedImage = styled('img', {
  border: '1px solid #DDD',
  width: '100%',
  borderRadius: '5px',
  height: '186px',
  position: 'relative',
})

export const RoadViewTextContainer = styled('div', {
  position: 'absolute',
  top: '16px',
  right: '8px',
  color: '#FFF',
  backgroundColor: 'rgba(26, 26, 26, 0.50)',
  padding: '7px 12px',
  borderRadius: '8px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontWeigh: 400,
  linHeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  lineHeight: 'normal',
  zIndex: 1
})

export const RoadViewIcon = styled('img', {
  width: '13px', height: '13px'
})

export const RoadViewSpan = styled('span', {
  marginLeft: '4px'
})

export const PhotoCountContainer = styled('div', {
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: '#FFF',
  backgroundColor: 'rgba(26, 26, 26, 0.50)',
  height: '20px',
  width: '48px',
  borderRadius: '8px',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '13px',
  fontWeigh: 400,
  linHeight: 'normal',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  lineHeight: 'normal',
  zIndex: 1,
})

export const ExclamationBlackIc = styled('img', {
  width: '18px',
  height: '18px'
})

export const CautionTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal'
})

// 나중에 변경 필요하기 때문에 컨테이너 이름을 TimeTicket 으로 설정 합니다
export const TimeTicketNotUseSlotGuideContain = styled('div', {
  display: 'flex', alignItems: 'center', marginTop: 28
})

export const RoadViewModal = styled('div', {
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  backgroundColor: 'white',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  height: '50vh', // Adjust height as necessary
  padding: '20px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',

  '.modal-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },

  button: {
    alignSelf: 'flex-end',
    padding: '10px',
    backgroundColor: '#8B63BB',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
});
