import { styled } from '@stitches/react';
import { isIOS } from 'react-device-detect';
import { colors } from 'styles/colors';

export const WrapMap = styled('div', {
  flexGrow: 1,
});

export const Sidebar = styled('div', {
  position: 'fixed',
  top: 0,
  left: '-280px', // 사이드바가 화면에서 벗어나 있음
  width: '280px',
  height: '100%', // 사이드바의 전체 높이 설정
  backgroundColor: '#8B63BB',
  color: 'white',
  transition: 'left 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  zIndex: 1,
  '.whiteBg': {
    width: '100%',
    top: 0,
    height: '94px',
    backgroundColor: '#fff',
    borderRadius: '35px 0 0 0',
    position: 'relative',
  },
});

export const MenuTopContainer = styled('div', {
  display: 'flex',
  padding: 'auto',
  margin: '20px 16px',
  justifyContent: 'space-between',
  position: 'relative',
});

export const MenuBottomContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px 15px 15px 15px',
  gap: 8,
});

export const WrapTabItem = styled('div', {
  border: '1px solid',
  borderRadius: '20px',
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: '6px 10px',
  whiteSpace: 'nowrap',
});

export const BurgerIc = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

export const AddressContainer = styled('div', {
  fontWeight: 600,
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

export const NotificationIc = styled('img', {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});
export const SidebarOpen = styled(Sidebar, {
  left: 0,
  zIndex: 3,
});

export const NonMemberContainer = styled('div', {
  display: 'flex',
  paddingTop: isIOS ? 68 : 38,
  paddingBottom: '36px',
  color: '#fff',
  whiteSpace: 'nowrap',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
  paddingLeft: '20px',
  paddingRight: '20px',
});

export const UserIconSize = styled('img', {
  width: '34px',
  height: '34px',
});

export const ArrowIconSize = styled('img', {
  width: '8px',
  height: '15px',
});

// 텍스트 스타일
export const UserText = styled('span', {
  fontSize: '22px',
  fontWeight: 800,
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontFamily: 'Pretendard',
});

export const CoinIc1 = styled('img', {
  width: '17px',
  height: '15px',
});

export const CoinIc2 = styled('img', {
  width: '17px',
  height: '15px',
  marginLeft: '38px',
});

export const BottomBannerContainer = styled('div', {
  background: '#B0D0FF',
  height: '70px',
  marginBottom: '60px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
});

export const HandCoinImage = styled('img', {
  width: '74px',
  height: '53px',
  flexShrink: 0,
});

export const ParkingShareSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#5372DE',
});

export const ProfitsSpan = styled('span', {});

export const WrapTimeTicketCautionDesc = styled('div', {
  padding: 10,
  backgroundColor: colors.primaryBackground,
  borderRadius: 8,
  marginBottom: 10,
});
