import { makeAutoObservable } from 'mobx';

import BaseError from 'models/baseError';
import { IMonthlyParkingProductData } from 'models/Monthly/parkingProductModel';
import monthlyParkingService from 'service/Monthly/monthlyParkingService';

class MonthlyParkingStore {
  isLoading: boolean = false;

  parkingProduct: IMonthlyParkingProductData | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setParkingProduct = (data: IMonthlyParkingProductData) => {
    this.parkingProduct = data;
  };

  async fetchGetMonthlyProduct(productUID: number) {
    this.setIsLoading(true);

    const requestBody = {
      productUID,
    };

    try {
      const response = await monthlyParkingService.getMonthlyProduct(requestBody);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          this.setParkingProduct(response.data);
          return response.data;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const monthlyParkingStore = new MonthlyParkingStore();
