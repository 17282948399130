import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import authService from 'service/UserInfo/myInfoAuthService';

class IdentityVerificationStore {
  memberUID: number = 0;
  isLoading: boolean = false;
  isWithdrawalMember: boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsWithdrawalMember(value: boolean) {
    this.isWithdrawalMember = value
  }

  async fetchGetAuthCodeByTell(memberTell: string, iv: string, salt: string) {
    this.setIsLoading(true);

    const requestBody = {
      memberTell,
    };

    try {
      const response = await authService.getAuthCodeByTell(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          console.log(response.data, 1234);
          return true;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchValidateAuthAndModifyMemberTell(
    memberTell: string,
    mLinkUID: number,
    authCode: string,
    iv: string,
    salt: string,
  ) {
    this.setIsLoading(true);

    const requestBody = {
      memberTell,
      mLinkUID,
      authCode,
    };

    try {
      const response = await authService.validateAuthAndModifyMemberTell(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response;
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchAuthCodeByTellWhenDeactivation(
    memberTell: string,
    iv: string,
    salt: string,
  ) {
    this.setIsLoading(true);

    const requestBody = {
      memberTell,
    };

    try {
      const response = await authService.getAuthCodeByTellWhenDeactivation(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
        if (response.status === 500) {
          this.setIsWithdrawalMember(true)
        }
      } else {
        if (response.data) {
          return response;
        } else {
          console.error('회원탈퇴 인증 에러');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }

  async fetchValidateAuthAndCheckUsingOrSharingSlots(
    memberTell: string,
    mLinkUID: number,
    authCode: string,
    iv: string,
    salt: string,
  ) {
    this.setIsLoading(true);

    const requestBody = {
      memberTell,
      mLinkUID,
      authCode,
    };

    try {
      const response = await authService.validateAuthAndCheckUsingOrSharingSlots(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
      } else {
        if (response.data) {
          return response;
        } else {
          console.error('회원 탈퇴 인증 번호 검증 에러');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export const identityVerificationStore = new IdentityVerificationStore();
