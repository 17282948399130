import React from 'react';
import {
  CloseIcon,
  NumberSpan,
  TitleSpan,
  IconContainer,
  ContentsSpan,
  DashContainer,
  TitleContentsContainer,
  NumberTittleContentsContainer,
  MinganParkingGuideModalContainer,
  BottomContainer,
  OperatingHoursContainer,
  ModalOverlay,
} from './styles';
import icGuideParking from '../../../../assets/ic/ic_guide_parking.svg';
import icGuideUser from '../../../../assets/ic/ic_guide_user.svg';
import icGuideCar from '../../../../assets/ic/ic_guide_car.svg';
import icGuideWallet from '../../../../assets/ic/ic_guide_wallet.svg';
import icCloseLightGray from 'assets/ic/ic_close_light_gray.svg';

interface MinganParkingGuideBottomModalProps {
  isVisible: boolean;
  onClose: () => void;
}

// TODO: 서버에서 받아온다면 서버에서 받아온 값으로 세팅 필요
const items = [
  {
    number: '1',
    title: '정기권 구매',
    content: '금액, 운영시간, 주차장 정보 검토 후 결제 바랍니다',
    icon: icGuideParking,
  },
  {
    number: '2',
    title: '승인 대기',
    content: '공유자의 승인 후 이용확정 카카오 알림톡이 올 때까지 기다려주세요.',
    icon: icGuideUser,
  },
  {
    number: '3',
    title: '주차장 이용',
    content: '유의사항을 꼭 확인 후 주차장 이용을 시작해주세요.',
    icon: icGuideCar,
  },
  {
    number: '4',
    title: '연장결제',
    content: '월정기권 연장은 알림 센터를 통해 이용기간 종료 전 결제해주세요.',
    icon: icGuideWallet,
  },
];

const MinganParkingGuideBottomModal: React.FC<MinganParkingGuideBottomModalProps> = ({
  isVisible,
  onClose,
}) => {
  if (!isVisible) return null;

  const handleBackgroundClick = () => {
    onClose();
  };

  return (
    <>
      <ModalOverlay onClick={handleBackgroundClick} />
      <MinganParkingGuideModalContainer isVisible={isVisible}>
        <div style={{ paddingTop: '25px', paddingRight: '25px', textAlign: 'right' }}>
          <CloseIcon src={icCloseLightGray} alt="닫기 아이콘" onClick={onClose} />
        </div>
        {items.map((item, index) => (
          <DashContainer isLast={index === items.length - 1}>
            <NumberTittleContentsContainer>
              <NumberSpan>{item.number}</NumberSpan>
              <TitleContentsContainer>
                <TitleSpan>{item.title}</TitleSpan>
                <ContentsSpan>
                  {item.content.split('\n').map((text, i) => (
                    <div key={i}>
                      {text}
                      <br />
                    </div>
                  ))}
                </ContentsSpan>
              </TitleContentsContainer>
            </NumberTittleContentsContainer>
            <IconContainer>
              <img src={item.icon} alt={`${item.title} 아이콘`} />
            </IconContainer>
          </DashContainer>
        ))}

        <BottomContainer>
          <div>
            <span>고객센터</span>
            <span
              style={{
                color: '#8B63BB',
              }}
            >
              1666-4369
            </span>
          </div>
          <OperatingHoursContainer>평일 09시 ~ 18시</OperatingHoursContainer>
        </BottomContainer>
      </MinganParkingGuideModalContainer>
    </>
  );
};

export default MinganParkingGuideBottomModal;
