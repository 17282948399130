import { makeAutoObservable } from 'mobx';
import UserInfoService from '../../../service/UserInfo/userInfoService';
import BaseError from '../../../models/baseError';
import { getBankIcon } from '../../../utils/bankIcon';
import { DesiredArea, IMemberData, IUserInfo } from '../../../models/UserInfo/userInfoModel';

class UserInfoStore {
  memberUID: number = 0;
  bankName: string = '';
  accountHolder: string = '';
  accountNumber: string = '';
  bankIconSrc: string = '';
  hasEmptyAccount: string = '';
  wdPassword: string = '';
  phoneNumber: string = '';
  isLoading: boolean = false;
  isErrorToast: boolean = false;
  memberData: IMemberData | null = null;
  loginType: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setMemberUID(memberUID: number) {
    this.memberUID = memberUID;
  }

  setBankName(bankName: string) {
    this.bankName = bankName;
  }

  setAccountHolder(accountHolder: string) {
    this.accountHolder = accountHolder;
  }

  setAccountNumber(accountNumber: string) {
    this.accountNumber = accountNumber;
  }

  setBankIconSrc(bankIcon: string) {
    this.bankIconSrc = bankIcon;
  }

  setHasEmptyAccount(hasEmptyAccount: string) {
    this.hasEmptyAccount = hasEmptyAccount;
  }

  setWdPassword(password: string) {
    this.wdPassword = password;
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setIsErrorToast(errorToast: boolean) {
    this.isErrorToast = errorToast;
  }

  setMemberData(data: IMemberData | null) {
    this.memberData = data;
  }

  async fetchUserInfoSearch(requestBody: IUserInfo, iv: string, salt: string) {
    this.setLoading(true);
    this.setIsErrorToast(false);

    try {
      const response = await UserInfoService.userInfoSearch(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log(response.errorMessage);
        this.setBankName('');
        this.setAccountHolder('');
        this.setAccountNumber('');
        this.setBankIconSrc('');
        this.setHasEmptyAccount('');
        this.setWdPassword('');
        this.setPhoneNumber('');
        this.setMemberUID(0);
        this.setMemberData(null);

        this.setIsErrorToast(true);
      } else {
        if (response.data.accountData) {
          console.log(response.data.accountData, 1234);
          const bankIcon = getBankIcon(response.data.accountData.bankCode);
          this.setBankName(response.data.accountData.bankName);
          this.setAccountHolder(response.data.accountData.accountHolder);
          this.setAccountNumber(response.data.accountData.accountNumber);
          this.setBankIconSrc(bankIcon);
          this.setHasEmptyAccount(response.data.memberData.accountYN);
          this.setWdPassword(response.data.memberData.wdPassword);
          this.setPhoneNumber(response.data.memberData.memberPhone);
          this.setMemberUID(response.data.memberData.memberUID);
          this.setMemberData(response.data.memberData);
        } else {
          console.error('No account data found');
        }
      }
    } catch (error) {
      console.error('사용자 정보를 가져오는 중 오류가 발생했습니다.', error);
    } finally {
      this.setLoading(false);
    }
  }
}

export const userInfoStore = new UserInfoStore();
