import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const MinganParkingInfoModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  maxHeight: '600px',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const ModalOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
});

export const CloseIcon = styled('img', {
  cursor: 'pointer',
  width: '18px',
  height: '18px',
});

export const DashContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  borderBottom: `2px dashed ${colors.gray02}`,
  margin: '0px 25px',
  paddingTop: 15,
  paddingBottom: 15,

  variants: {
    isFirst: {
      true: { paddingTop: 2 }, // 첫 번째 인덱스일 때만 paddingTop 2px
      false: {},
    },
    isLast: {
      true: { paddingBottom: 40, borderBottom: 'none' }, // 마지막 인덱스일 때만 paddingBottom 40px
      false: {},
    },
  },
});

export const DotTittleContentsContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

export const TitleSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ContentsSpan = styled('span', {
  color: '#868686',
  fontSize: '14px',
  marginTop: '4px',
  wordBreak: 'keep-all',
});

export const TitleContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
});
