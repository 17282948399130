import { makeAutoObservable } from 'mobx';
import BaseError from 'models/baseError';
import shareParkingService from 'service/Share/shareParkingService';

import { IShareResidentSlotDetailInfoData } from 'models/Share/shareResidentSlotDetailInfo';
import { IShareResidentUseCompleteSlotListData } from 'models/Share/shareResidentUseCompleteSlotListModel';
import { IShareResidentSlotShareTimeDetailData } from 'models/Share/shareResidentSlotShareTimeDetail';
import {
  IShareModifyResidentSlotShareStopTime,
  IShareModifyResidentSlotShareStopTimeData,
} from 'models/Share/shareModifyResidentSlotShareStopTime';
import { IShareStartResidentSlotShareData } from 'models/Share/shareStartResidentSlotShare';
import { IShareModifyResidentSlotShareTime } from 'models/Share/shareModifyResidentSlotShareTime';

class ShareResidentStore {
  isLoading = false;
  isDetailLoading = false;
  error: string | null = null;
  residentSlotDetailInfo: IShareResidentSlotDetailInfoData | undefined = undefined;
  residentUseCompleteSlotList: IShareResidentUseCompleteSlotListData[] = [];
  residentSlotShareTimeDetail: IShareResidentSlotShareTimeDetailData | undefined = undefined;
  modifyResidentSlotShareStopTime: IShareModifyResidentSlotShareStopTimeData | undefined =
    undefined;
  startResidentSlotShare: IShareStartResidentSlotShareData | undefined = undefined;
  residentUseCompleteSlotListTotalCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsDetailLoading(value: boolean) {
    this.isDetailLoading = value;
  }

  setResidentSlotDetailInfo(data: IShareResidentSlotDetailInfoData) {
    this.residentSlotDetailInfo = data;
  }

  setResidentUseCompleteSlotList(data: IShareResidentUseCompleteSlotListData[]) {
    this.residentUseCompleteSlotList = data;
  }

  setResidentUseCompleteSlotListTotalCount(data: number) {
    this.residentUseCompleteSlotListTotalCount = data;
  }

  setResidentSlotShareTimeDetail(data: IShareResidentSlotShareTimeDetailData) {
    this.residentSlotShareTimeDetail = data;
  }

  setModifyResidentSlotShareStopTime(data: IShareModifyResidentSlotShareStopTimeData) {
    this.modifyResidentSlotShareStopTime = data;
  }

  setStartResidentSlotShare(data: IShareStartResidentSlotShareData) {
    this.startResidentSlotShare = data;
  }

  async fetchResidentSlotDetailInfo(slotCode: string, regionCode: number) {
    const requestBody = {
      slotCode,
      regionCode,
    };

    this.setIsDetailLoading(true);

    const response = await shareParkingService.getResidentSlotDetailInfo(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setIsDetailLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setResidentSlotDetailInfo(data);
      }
      this.setIsDetailLoading(false);
      return data;
    }
  }

  async fetchResidentUseCompleteSlotList(
    slotCode: string,
    regionCode: number,
    filter: string,
    type: string,
    pageNum: number,
  ) {
    const requestBody = {
      slotCode,
      regionCode,
      [type]: filter,
    };

    const response = await shareParkingService.getResidentUseCompleteSlotList(requestBody, pageNum);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      return false;
    } else {
      const { data, totalCount } = response;

      if (pageNum === 1) {
        this.setResidentUseCompleteSlotList(data);
      } else {
        this.setResidentUseCompleteSlotList([...(this.residentUseCompleteSlotList ?? []), ...data]);
      }

      this.setResidentUseCompleteSlotListTotalCount(totalCount);

      return { data, totalCount };
    }
  }

  async fetchGetResidentSlotShareTimeDetail(sharePerDayIdx: number) {
    const requestBody = {
      sharePerDayIdx,
    };

    this.setLoading(true);

    const response = await shareParkingService.getResidentSlotShareTimeDetail(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setResidentSlotShareTimeDetail(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchModifyResidentSlotShareStopTime(slotIdx: number, shareEndTime: string) {
    const requestBody = {
      slotIdx,
      shareEndTime,
    };

    this.setLoading(true);

    const response = await shareParkingService.modifyResidentSlotShareStopTime(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setModifyResidentSlotShareStopTime(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchModifyResidentSlotShareTime(
    slotCode: string,
    regionCode: number,
    sharePerDayIdx: number,
    share: Record<string, string>,
  ) {
    const requestBody = {
      slotCode,
      regionCode,
      sharePerDayIdx,
      ...share,
    } as IShareModifyResidentSlotShareTime;

    this.setLoading(true);

    const response = await shareParkingService.modifyResidentSlotShareTime(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setModifyResidentSlotShareStopTime(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchStartResidentSlotShare(slotIdx: number) {
    const requestBody = {
      slotIdx,
    };

    this.setLoading(true);

    const response = await shareParkingService.startResidentSlotShare(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      if (data) {
        this.setStartResidentSlotShare(data);
      }
      this.setLoading(false);
      return data;
    }
  }

  async fetchResidentSlotShareStop(slotIdx: number, sharePerDayIdx: number) {
    const requestBody = {
      slotIdx,
      sharePerDayIdx,
    };

    this.setLoading(true);

    const response = await shareParkingService.residentSlotShareStop(requestBody);

    if (response instanceof BaseError) {
      this.error = response.errorMessage;
      this.setLoading(false);
      return false;
    } else {
      const { data } = response;

      // if (data) {
      //   this.setStartResidentSlotShare(data);
      // }
      this.setLoading(false);
      return data;
    }
  }
}

export const shareResidentStore = new ShareResidentStore();
