import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AgreeButton,
  BottomButtonContainer,
  RequiredText,
  StyledCheckbox,
  CautionsContentsContainer,
  TimeTicketAllContentsContainer,
  TimeTicketCautionBottomContentsContainer,
  CautionTitleContainer,
  ExclamationBlackIc,
  AgreeContainer,
  CautionContainer,
  CautionColumnContainer,
  ProductNameWrap,
  CautionIcon,
  BannerWrap,
  WarningContainer,
} from './styles';
import { terraceTowerProductStore } from 'stores/store/TerraceTower/terraceTowerProductStore';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { colors } from 'styles/colors';
import TimeTicketCautionsComponent from 'containers/timeTicket/TimeTicketCautions/TimeTicketCautionsComponent';
import { MainContainerNoMargin } from 'components/styles';
import NonSafeAreaTitle from 'components/atoms/Title/NonSafeAreaTitle';
import Divider from 'components/atoms/Divider';

import icCarCaution from 'assets/ic/ic_car_caution.svg';
import icSteeringWheel from 'assets/ic/ic_steering_wheel_caution.svg';
import icWarningPurple from 'assets/ic/ic_warning_purple.svg';
import icExclamationBlack from 'assets/ic/ic_exclamation_black.svg';
import { generateTimeSlots } from 'utils/timeUtils';
import PopupModal from 'components/atoms/Modal/PopupModal';

const DailyReservationCautionWebPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productUID = location.state?.productUID || queryParams.get('productUID');
  const mLinkUID = location.state?.productUID || queryParams.get('mLinkUID');

  const [isChecked, setIsChecked] = useState(false);
  const [isPaymentFail, setIsPaymentFail] = useState(false);

  const { productName, cautions, operationTime } = terraceTowerProductStore.terraceTowerOrder || {};

  const handleAgreeClick = () => {
    setIsChecked(prev => !prev);
  };

  useEffect(() => {
    const fetchTerraceTowerProduct = async () => {
      await terraceTowerProductStore.fetchTerraceTowerProduct(productUID);
      console.log(productUID);
    };
    fetchTerraceTowerProduct().catch(error => {
      console.log('주문정보 불러오는 중 에러', error);
      alert(error);
    });
  }, []);

  const handleButtonClick = () => {
    if (operationTime) {
      const [startTime, endTime] = operationTime.split('~');
      if (generateTimeSlots(startTime, endTime).length === 1) {
        setIsPaymentFail(true);
      } else {
        if (isChecked) {
          sessionStorage.setItem('carNumber', '');
          sessionStorage.setItem('isNotFocus', 'false');
          sessionStorage.setItem('isPolicyPersonalCheck', 'false');
          navigate(`/dailyPayment?productUID=${productUID}&mLinkUID=${mLinkUID}`);
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsChecked(true);
  }, []);

  const handleWebViewClose = () => {
    // webViewClose();
    navigate(-1);
  };

  return (
    <MainContainerNoMargin
      style={{
        margin: '0 auto',
      }}
    >
      <NonSafeAreaTitle
        title="유의사항"
        leftArrowButtonVisible={true}
        leftOnClick={handleWebViewClose}
      />
      <TimeTicketAllContentsContainer>
        <ProductNameWrap>
          <CautionColumnContainer>
            <Headline weight={'bold'} style={{ color: colors.primary }}>
              {productName ? productName : ''}
            </Headline>
            <Headline weight={'bold'} style={{ color: colors.black3 }}>
              유의사항을 확인해 주세요
            </Headline>
          </CautionColumnContainer>
          <CautionIcon src={icWarningPurple} alt={'보라색 경고'} />
        </ProductNameWrap>

        <BannerWrap>
          <WarningContainer>
            <img src={icCarCaution} alt={'첫번째 경고 이미지'} width="50px" height="50px" />
            <CautionColumnContainer>
              <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>
                만차 및 현장 사정으로
              </Caption2>
              <Caption1 weight={'semibold'}>주차가 어려울 수 있습니다</Caption1>
            </CautionColumnContainer>
          </WarningContainer>
          <WarningContainer>
            <img src={icSteeringWheel} alt={'두번째 경고 이미지'} width="50px" height="50px" />
            <CautionColumnContainer>
              <Caption2 weight={'regular'} style={{ color: colors.gray08 }}>
                유의사항 미숙지로 발생된 피해는
              </Caption2>
              <Caption1 weight={'semibold'}>운전자에게 책임이 있습니다</Caption1>
            </CautionColumnContainer>
          </WarningContainer>
        </BannerWrap>
        <Divider
          style={{
            height: 6,
            backgroundColor: colors.primaryBackground,
            marginTop: 8,
          }}
        />

        <CautionsContentsContainer>
          <CautionTitleContainer>
            <ExclamationBlackIc src={icExclamationBlack} alt={'검정 배경 느낌표'} />
            <SubHeadline weight={'bold'} style={{ color: colors.black3, marginLeft: 10 }}>
              주차권 유의사항
            </SubHeadline>
          </CautionTitleContainer>
          <Divider
            style={{
              height: 1,
              backgroundColor: colors.gray03,
              marginTop: 16,
            }}
          />
          <TimeTicketCautionsComponent cautions={cautions} />
        </CautionsContentsContainer>
      </TimeTicketAllContentsContainer>

      <TimeTicketCautionBottomContentsContainer>
        <CautionContainer style={{ margin: '0 20px' }}>
          <Caption3 weight={'regular'} css={{ color: colors.gray06 }}>
            유의사항 미숙지로 인해 발생한 피해와 주차장 내에서
          </Caption3>
          <Caption3 weight={'regular'} css={{ color: colors.gray06 }}>
            발생한 사고에 대해서는 일체 책임지지 않습니다.
          </Caption3>
        </CautionContainer>
        <AgreeContainer onClick={handleAgreeClick}>
          <StyledCheckbox type="checkbox" checked={isChecked} readOnly />
          <RequiredText>(필수)</RequiredText>
          <Caption2 weight={'regular'} style={{ color: colors.gray09 }}>
            주차권 유의사항 동의
          </Caption2>
        </AgreeContainer>
        <BottomButtonContainer onClick={handleButtonClick}>
          <AgreeButton state={isChecked ? 'agreeCheck' : 'notCheck'}>
            유의사항 확인했어요
          </AgreeButton>
        </BottomButtonContainer>
      </TimeTicketCautionBottomContentsContainer>

      <PopupModal
        isVisible={isPaymentFail}
        onClose={() => {
          setIsPaymentFail(false);
        }}
        title1={'결제 가능한 시간이 없습니다'}
        leftClick={() => {
          setIsPaymentFail(false);
        }}
        leftText={'확인'}
      />
    </MainContainerNoMargin>
  );
});

export default DailyReservationCautionWebPage;
