import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MinganInfoModalContainer,
  MinganListNumberContainer,
  TicketContainer,
  TitleContainer,
  Badge,
} from './styles';
import { Body, Caption2, Caption3, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';

import { colors } from 'styles/colors';
import { chargerListData, monthlyListData } from 'containers/home/HomeMap/mock';

interface ChargingInfoListModalProps {
  isVisible: boolean;
  onClose: () => void;
  setIsChargingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChargingInfoListModal: React.FC<ChargingInfoListModalProps> = ({
  isVisible,
  onClose,
  setIsChargingModal,
}) => {
  const navigate = useNavigate();

  if (!isVisible) return null;

  const item = chargerListData.chargerStat;

  return (
    <>
      <MinganInfoModalContainer
        isVisible={isVisible}
        onClick={e => {
          e.stopPropagation();
          onClose();
        }}
        isScroll={chargerListData.result.length > 2}
      >
        <TitleContainer>
          <span style={{ color: '#8B63BB' }}>충전소</span>
          <span>를 선택해 주세요</span>
        </TitleContainer>

        <MinganListNumberContainer>총 {chargerListData.result.length}건</MinganListNumberContainer>

        <div
          style={{
            overflowY: 'auto',
          }}
        >
          {chargerListData.result.map((item, index) => (
            <TicketContainer onClick={() => setIsChargingModal(true)}>
              <div>
                <Row>
                  <img width={18} height={18} src={item.logoImagePath} style={{ marginRight: 5 }} />
                  <Caption3 weight={'regular'} color={'gray09'}>
                    {item.busiNm}
                  </Caption3>
                </Row>
                <Body
                  weight={'bold'}
                  color={'black1'}
                  style={{
                    width: window.innerWidth * 0.52,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.statNm}
                </Body>
              </div>

              <div>
                {chargerListData.chargerStat.rapidTotalCnt ? (
                  <Row style={{ marginBottom: 5 }}>
                    <Badge style={{ backgroundColor: colors.blue }}>
                      <Caption4 weight={'bold'} color={'white'}>
                        급속
                      </Caption4>
                    </Badge>
                    <Caption2 weight={'bold'} color={'red'}>
                      {chargerListData.chargerStat.slowAvailableCnt}
                    </Caption2>
                    <Caption2 weight={'bold'} color={'gray07'}>
                      /
                    </Caption2>
                    <Caption2 weight={'bold'} color={'gray07'}>
                      {chargerListData.chargerStat.rapidTotalCnt}
                    </Caption2>
                  </Row>
                ) : null}

                {chargerListData.chargerStat.slowTotalCnt ? (
                  <Row>
                    <Badge style={{ backgroundColor: colors.gray01 }}>
                      <Caption4 weight={'bold'} color={'gray09'}>
                        완속
                      </Caption4>
                    </Badge>
                    <Caption2 weight={'bold'} color={'red'} style={{ color: '#20AEE5' }}>
                      {chargerListData.chargerStat.slowAvailableCnt}
                    </Caption2>
                    <Caption2 weight={'bold'} color={'gray07'}>
                      /
                    </Caption2>
                    <Caption2 weight={'bold'} color={'gray07'}>
                      {chargerListData.chargerStat.slowTotalCnt}
                    </Caption2>
                  </Row>
                ) : null}
              </div>
            </TicketContainer>
          ))}
        </div>
      </MinganInfoModalContainer>
    </>
  );
};

export default ChargingInfoListModal;
