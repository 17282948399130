import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Title from 'components/atoms/Title/PageTitle';
import { MainContainerNoMargin } from 'components/styles';
import Divider from 'components/atoms/Divider';
import { colors } from 'styles/colors';
import Button from 'components/atoms/Button/Button';
import { Caption1 } from 'styles/typography';
import { Container } from 'pages/Share/MinganProductDetailPage/styles';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import MinganParkProductInfo from 'containers/share/MinganParkProductInfo';
import ApproveRequest from 'containers/share/ApproveRequest';
import MinganParkingUsing from 'containers/share/MinganParkingUsing';
import MinganParkingUsingHistory from 'containers/share/MinganParkingUsingHistory';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import YearSelectBottomModal from 'components/atoms/Modal/YearSelectBottomModal';
import MinganParkApproveBottomModal from 'components/atoms/Modal/MinganParkApproveBottomModal';
import MinganParkDisagreeModal from 'components/atoms/Modal/MinganParkDisagreeModal';
import { shareStore } from 'stores/store/Share/shareStore';
import { handleChatClick, truncateText } from 'utils/common';
import { IMonthlyProductInfo } from 'models/Share/shareProductListModel';
import CancelResaonStore from 'stores/store/CancelReason/CancelResaonStore';
import { CompleteDriver, WaitingDriverList } from 'models/Share/shareProductDetailModel';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { formatDateRange } from 'utils/dateUtils';

const saleStopText = {
  title: '상품 판매를 중지시키나요?',
  description:
    '상품 판매 중지는 “주만사 월주차” 카톡 채널로 문의 후 중지할 수 있어요. 상품 판매를 중지하면 현재 이용중인 건도 중단됩니다.',
};

const saleStopTimeTicketText = {
  title: '상품 판매를 중지시키나요?',
  description:
    '중지한 시점부터 즉시 중단되며, 미사용 주차권은 자동으로 환불 되지 않습니다.\n' +
    '판매 재개는 앱 내에서 직접 설정해 주셔야 합니다.',
};

const saleRestartText = {
  title: '판매를 다시 시작하나요?',
  description:
    '판매 재개는 담당자가 확인한 후에 처리돼요.\n' + '평일 영업시간 09시 ~18시 내 연락드립니다.',
};

const timeOverText = {
  title: `처리 가능한 날짜가 지났어요.`,
  description:
    '날짜가 지나서 해당 요청건은 사라져요. 지나간 요청건 관련 문의사항이 있다면 고객센터로 연락해 주세요.',
};

const MinganProductDetailPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const productUID = location.state?.productUID || queryParams.get('productUID');
  const slotUID = location.state?.slotUID || queryParams.get('slotUID');
  const type = location.state?.type || queryParams.get('type');

  const [isShare, setIsShare] = useState(false);
  const [isSaleModal, setIsSaleModal] = useState(false);
  const [isYearSelectBottomModal, setIsYearSelectBottomModal] = useState(false);
  const [selectYear, setSelectYear] = useState(0);
  const [isApprove, setIsApprove] = useState(false);
  const [isExpiration, setIsExpiration] = useState(false);
  const [isDisagree, setIsDisagree] = useState(false);

  const [waitingDriver, setWaitingDriver] = useState<WaitingDriverList | null>(null);
  const [usingDriver, setUsingDriver] = useState<CompleteDriver | null>(null);
  const [hasMore, setHasMore] = useState(true);

  const { phoneNumber, memberData } = userInfoStore;
  const { productDetail, productList, isProductDetailLoading } = shareStore;

  const isSale: boolean = productDetail?.productInfo.productStatus === 'SALE';

  const shareStopText = {
    title: `${usingDriver?.carNumber} ${usingDriver?.carType}의\n주차장 이용을 중지시키나요?`,
    description:
      '주차장 이용중지는 “주만사 월주차” 카톡 채널로 문의 후 가능해요. 이용 기간 내 취소하는 경우 정산 금액은 달라지니 유의해 주세요.',
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  const fetchData = async () => {
    try {
      await shareStore.fetchGetLotProductList(slotUID);
      await shareStore.fetchGetProductDetail(productUID);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // 데이터를 가져오는 함수 정의

    fetchData(); // 데이터 가져오기 호출
  }, []);

  const createYearList = (baseYear: string): string[] => {
    const currentYear = new Date().getFullYear(); // 현재 연도 가져오기
    const yearList = [];

    for (let year = currentYear; year >= Number(baseYear); year--) {
      yearList.push(year + '년'); // 현재 연도부터 주어진 연도까지 추가
    }

    return yearList;
  };

  const year = createYearList(memberData?.regDate.split('-')[0] ?? '2018');

  const handleSelectYear = async (index: number) => {
    setSelectYear(index);
    setHasMore(true);
  };

  const onCloseShareModal = () => {
    setIsShare(false);
  };

  const onCloseSaleModal = () => {
    setIsSaleModal(false);
  };

  const onCloseExpirationModal = async () => {
    setIsExpiration(false);
    if (waitingDriver) {
      await shareStore.fetchApprovalRequestDeny(
        waitingDriver?.saleHistoryUID,
        CancelResaonStore.reasonValue ?? '',
      );

      await shareStore.fetchGetProductDetail(productUID);
    }
  };

  const approveRequestApprove = async () => {
    if (waitingDriver?.saleHistoryUID) {
      try {
        const approvalRes = await shareStore.fetchApprovalRequestApproval(
          waitingDriver?.saleHistoryUID,
        );

        if (approvalRes) {
          showToast(`${truncateText(waitingDriver?.carType, 5)}차량의 주차장 이용을 승인했어요.`);
          await shareStore.fetchGetProductDetail(productUID);
        }
      } catch (err) {
        console.log(err, 1234);
        alert(err);
      }
      setIsApprove(false);
    }
  };

  const denyRequestApprove = async () => {
    try {
      if (waitingDriver?.saleHistoryUID) {
        const denyRes = await shareStore.fetchApprovalRequestDeny(
          waitingDriver?.saleHistoryUID,
          CancelResaonStore.reasonValue ?? '',
        );

        if (denyRes) {
          showToast(`${truncateText(waitingDriver?.carType, 5)}차량의 주차장 이용을 거절했어요.`);
          await shareStore.fetchGetProductDetail(productUID);
        }
      }
    } catch (err) {
      console.log(err, 1234);
    }
    setIsDisagree(false);
  };

  const requestSaleRestartMonthly = async () => {
    if (isSale) {
      onCloseSaleModal();
      handleChatClick();
    } else {
      await shareStore.fetchProductSaleResumeRequest(
        phoneNumber,
        `{ slotUID: ${slotUID}, productUID: ${productUID}`,
      );
      showToast('판매 재개를 신청 완료했어요.', icSuccessCheckGreen);
    }
  };

  const requestSaleRestartDaily = async () => {
    // 상품 번호, 감소 , 증가
    if (productDetail) {
      if (isSale) {
        onCloseSaleModal();
        await shareStore.fetchUpdateProductQuantity(
          productUID,
          productDetail?.productInfo.saleProductCnt ?? 0,
          0,
        );
        showToast('판매 중지를 완료했어요.', icSuccessCheckGreen);
      } else {
        await shareStore.fetchUpdateProductQuantity(
          productUID,
          0,
          productDetail?.productInfo.saleProductTotalCnt ?? 0,
        );
        showToast('판매 재개를 완료했어요.', icSuccessCheckGreen);
      }
      fetchData();
    }
  };

  if (isProductDetailLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
        }}
      >
        <Spinner />
      </div>
    );
  }

  function isDatePast(dateRange: string) {
    // 한국 시간 기준 현재 날짜
    const now = new Date();
    const koreaTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));

    // 입력에서 시작 날짜 추출
    const startDate = dateRange.split('~')[0].trim();

    // 시작 날짜와 현재 한국 날짜 비교
    const startDateTime = new Date(`${startDate}T00:00:00+09:00`); // 한국 시간 적용

    // 날짜 비교 (포함 여부 판단)
    return startDateTime < koreaTime;
  }

  return (
    <MainContainerNoMargin>
      <Title
        title="내 상품 상세"
        leftArrowButtonVisible
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
        rightTitle={'운영정보'}
        rightOnClick={() => {
          navigate('/operatingInfo', {
            state: {
              productUID,
            },
          });
        }}
      />
      <Container>
        {productDetail?.productInfo ? (
          <MinganParkProductInfo isSale={isSale} data={productDetail?.productInfo} />
        ) : null}
        {isSale && productDetail ? (
          <>
            {productDetail?.saleHistoryResponse.waitingDriverList.length !== 0 ? (
              <ApproveRequest
                data={productDetail?.saleHistoryResponse}
                onClick={index => {
                  setWaitingDriver(productDetail?.saleHistoryResponse.waitingDriverList[index]);
                  if (
                    isDatePast(
                      productDetail?.saleHistoryResponse.waitingDriverList[index]?.useDate ?? '',
                    )
                  ) {
                    setIsExpiration(true);
                  } else {
                    setIsApprove(true);
                  }
                }}
                onClick2={index => {
                  setWaitingDriver(productDetail?.saleHistoryResponse.waitingDriverList[index]);
                  if (
                    isDatePast(
                      productDetail?.saleHistoryResponse.waitingDriverList[index]?.useDate ?? '',
                    )
                  ) {
                    setIsExpiration(true);
                  } else {
                    setIsDisagree(true);
                  }
                }}
              />
            ) : (
              <Divider style={{ height: 8, backgroundColor: colors.gray01 }} />
            )}
            <MinganParkingUsing
              data={productDetail?.saleHistoryResponse}
              onClick={index => {
                setUsingDriver(productDetail?.saleHistoryResponse.completeDriverList[index]);
                setIsShare(true);
              }}
            />
          </>
        ) : null}

        <Divider style={{ height: 8, backgroundColor: colors.gray01 }} />
        {productDetail ? (
          <MinganParkingUsingHistory
            onClick={() => setIsYearSelectBottomModal(true)}
            selectYear={year[selectYear]}
            productUID={productUID}
            hasMore={hasMore}
            setHasMore={setHasMore}
          />
        ) : null}
      </Container>
      <Button
        style={{
          height: 40,
          boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.25)',
          position: 'fixed',
          bottom: 40,
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
        horizontal={16}
        radius={20}
        borderColor={colors.gray09}
        onClick={() => setIsSaleModal(true)}
      >
        <Caption1 weight={'medium'} color={'gray09'}>
          {isSale ? '상품 판매 중지하기' : '상품 판매를 다시 시작할까요?'}
        </Caption1>
      </Button>

      <CustomBottomModal
        isVisible={isShare}
        onClose={onCloseShareModal}
        title1={shareStopText.title}
        description={shareStopText.description}
        leftClick={onCloseShareModal}
        leftText={'취소'}
        rightClick={() => {
          handleChatClick();
          onCloseShareModal();
        }}
        rightText={'문의하러 가기'}
      />
      <CustomBottomModal
        isVisible={isExpiration}
        onClose={onCloseExpirationModal}
        title1={timeOverText.title}
        description={timeOverText.description}
        leftClick={onCloseExpirationModal}
        leftText={'확인'}
      />

      <CustomBottomModal
        isVisible={isSaleModal}
        onClose={onCloseSaleModal}
        title1={
          isSale && type === 'monthly'
            ? saleStopText.title
            : isSale && type === 'daily'
              ? saleStopTimeTicketText.title
              : saleRestartText.title
        }
        description={
          isSale && type === 'monthly'
            ? saleStopText.description
            : isSale && type === 'daily'
              ? saleStopTimeTicketText.description
              : saleRestartText.description
        }
        leftClick={onCloseSaleModal}
        leftText={'취소'}
        rightClick={async () => {
          if (type === 'monthly') {
            await requestSaleRestartMonthly();
          } else {
            await requestSaleRestartDaily();
          }
        }}
        rightText={
          isSale && type === 'monthly'
            ? '문의하러 가기'
            : isSale && type === 'daily'
              ? '중지하기'
              : '판매 재개 신청하기'
        }
      />

      <YearSelectBottomModal
        isVisible={isYearSelectBottomModal}
        onClose={() => {
          setIsYearSelectBottomModal(false);
        }}
        onSelectYear={handleSelectYear}
        year={year}
      />

      <MinganParkApproveBottomModal
        isVisible={isApprove}
        onClose={() => {
          setIsApprove(false);
        }}
        rightClick={() => {
          approveRequestApprove();
        }}
        usingDate={`이용 기간 : ${formatDateRange(waitingDriver?.useDate ?? '')}`}
        carType={`${waitingDriver?.carType}`}
      />
      <MinganParkDisagreeModal
        isVisible={isDisagree}
        onClose={() => {
          setIsDisagree(false);
          CancelResaonStore.setReason(null);
        }}
        rightClick={() => {
          denyRequestApprove();
        }}
        title={`${waitingDriver?.carType}의\n주차장 이용을 거절할까요?`}
        usingDate={`이용 기간 : ${formatDateRange(waitingDriver?.useDate ?? '')}`}
      />
    </MainContainerNoMargin>
  );
});

export default MinganProductDetailPage;
