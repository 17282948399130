import React, { useEffect, useRef, useState } from 'react';

import { Container, WrapItem } from './styles';
import { Caption1, Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import { dailyDetail } from 'containers/home/HomeMap/mock';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { formatPriceWithCommas } from 'utils/number';

interface Props {
  handleSelected: (item: number) => void;
  selected: number | null;
}

const TimeTicketList: React.FC<Props> = ({ handleSelected, selected }) => {
  const { memberData } = userInfoStore;

  const data = dailyDetail;

  return (
    <Container>
      <SubHeadline weight={'bold'} color={'black1'}>
        주차권
      </SubHeadline>
      {data.productList.map((el, index) => {
        return (
          <WrapItem
            key={index}
            onClick={() => {
              if (!el.isOpenNow || el.productStatus !== 'SALE') return;
              handleSelected(index);
            }}
            style={{
              border:
                !el.isOpenNow || el.productStatus !== 'SALE'
                  ? 'transparent'
                  : selected === index
                    ? '1px solid #8B63BB'
                    : '1px solid #CCCCCC',
              backgroundColor:
                !el.isOpenNow || el.productStatus !== 'SALE'
                  ? '#E6E6E6'
                  : selected === index
                    ? '#EBEBFB'
                    : '#fff',
            }}
          >
            <Row justifyContent={'space-between'} style={{ marginBottom: 8 }}>
              <SubHeadline
                weight={'semibold'}
                color={!el.isOpenNow || el.productStatus !== 'SALE' ? 'gray06' : 'black3'}
              >
                {el.productName}
              </SubHeadline>
              <SubHeadline weight={'semibold'} color={'black3'}>
                {formatPriceWithCommas(el.salePrice)}원
              </SubHeadline>
            </Row>
            <Caption3
              weight={'medium'}
              color={!el.isOpenNow || el.productStatus !== 'SALE' ? 'gray06' : 'primary'}
            >
              {el.parkingAvailableText}
            </Caption3>
          </WrapItem>
        );
      })}
    </Container>
  );
};

export default TimeTicketList;
