import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { MainContainer } from 'components/styles';
import useUserInfo from 'hooks/useUserInfo';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import { IUserInfo } from 'models/UserInfo/userInfoModel';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Title from 'components/atoms/Title/PageTitle';
import { LINK_UID } from 'constants/userInfoConstants';
import { IPointSmsCert, IPointSmsCertSuccess } from 'models/Point/pointSmsCertModel';
import { identityVerificationStore } from 'stores/store/Auth/IdentityVerificationStore';

import {
  CertNumberContentsContainer,
  CertNumberInput,
  CertTransmissionButtonContainer,
  CertTransmissionContainer,
  CloseIcon,
  PhoneInfoContainer,
  PhoneNumberSpan,
  PhoneNumberTextSpan,
  SafeTextSpan,
  TimerSpan,
  TitleContainer,
  TransmissionButton,
} from 'pages/Point/PointWithdrawal/PointWithdrawalIdentityCert/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/atoms/Button/Button';
import icCloseCircleGray from 'assets/ic/ic_close_circle_gray.svg';
import PurpleOneButtonCheckModal from 'components/atoms/Modal/PurpleOneButtonCheckModal';
import BaseError from 'models/baseError';
import { ResponseStatus } from '../../../enums/responseState';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import { isVisible } from '@testing-library/user-event/utils/misc/isVisible';
import {
  BottomButtonContainer,
  ButtonEnableDisable, CertNumberContainer,
  PhoneNumberBorderContainer,
} from 'pages/DeleteAccount/DeleteAccountCert/styles';
import PopupModal from 'components/atoms/Modal/PopupModal';
import { Body2, SubHeadline } from 'styles/typography';
import { pointWithdrawalMoneyStore } from 'stores/store/Point/pointWithdrawalMoneyStore';
import { isIOS } from 'react-device-detect';
import useKeyboardVisible from 'hooks/useKeyboardVisible';

interface IFormInputs {
  certNumber: string;
}

const DeleteAccountCertPage = observer(() => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('전송');
  const [timerVisible, setTimerVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [inputPlaceholder, setInputPlaceholder] = useState('[전송]을 눌러 주세요.');
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isCertButtonAndCloseIconVisible, setIsCertButtonAndCloseIconVisible] = useState(false);
  const { showToast } = useToast();
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const { iv, salt } = getRandomString();

  const { memberUID, phoneNumber } = userInfoStore;

  const [isModal, setIsModal] = useState(false);

  const certNumberInputRef = useRef<HTMLInputElement | null>(null);

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  const [popupState, setPopupState] = useState({
    title1: '본인 인증이 완료되었습니다.',
    description: '',
    isBackgroundTouchable: true,
  });

  const PARKING_USE_SHARING_MESSAGES = {
    TITLE: '공유 중인 주차장이 있어요.',
    DESCRIPTION: '고객센터(1666-6248)를 통해 공유 종료한 후에 다시 시도해 주세요.',
  };

  const PARKING_USE_SLOT_MESSAGES = {
    TITLE: '이용 중인 주차장이 있어요.',
    DESCRIPTION: '고객센터(1666-6248)를 통해 이용 종료한 후에 다시 시도해 주세요.',
  };

  const PARKING_USE_SHARING_AND_SLOT_MESSAGES = {
    TITLE: '이용 중 / 공유 중인 주차장이 있어요.',
    DESCRIPTION: '고객센터(1666-6248)를 통해 이용 종료 / 공유 종류 후에 다시 시도해 주세요.',
  };

  const PARKING_USE_SUCCESS_MESSAGES = {
    TITLE: '본인 인증이 완료되었습니다.',
    DESCRIPTION: '',
  };

  const handleModalLeftClick = () => {
    if (popupState.title1 === PARKING_USE_SUCCESS_MESSAGES.TITLE) {
      navigate('/deleteAccountNotification');
      return;
    }
    setIsModal(false);
  };

  const phoneEnc = encTextWord(phoneNumber, iv, salt);

  const handleSendClick = async () => {
    setButtonText('재전송');
    setTimerVisible(true);
    setTimeLeft(180);
    setInputPlaceholder('6자리를 입력해주세요.');
    setIsInputDisabled(false);
    setIsCertButtonAndCloseIconVisible(true);

    if (identityVerificationStore.isLoading) {
      return;
    }

    try {
      await identityVerificationStore.fetchAuthCodeByTellWhenDeactivation(phoneEnc, iv, salt);
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }

    if (identityVerificationStore.isWithdrawalMember) {
      setTimerVisible(false);
      certNumberInputRef.current?.blur();
    } else {
      if (certNumberInputRef.current) {
        requestAnimationFrame(() => {
          certNumberInputRef.current?.focus();
        });
      }
    }
  };

  const { register, watch, setValue, setFocus } = useForm<IFormInputs>({
    defaultValues: {
      certNumber: '',
    },
  });

  const certNumberValue = watch('certNumber', '');

  const handleClearInput = () => {
    console.log('Clearing input...');
    setValue('certNumber', '', { shouldDirty: false, shouldTouch: false });
  };

  const handleCertSuccess = async () => {
    const res = await identityVerificationStore.fetchValidateAuthAndCheckUsingOrSharingSlots(
      phoneEnc,
      memberUID ?? 0,
      certNumberValue,
      iv,
      salt,
    );

    // 인증번호 실패
    if (res?.data === ResponseStatus.VALIDATE_FAIL) {

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      showToast('인증번호가 틀렸어요.', icFailCheckRed);
      handleClearInput();
    }

    // 이용중인 주차장은 있고, 공유 중인 주차장은 없다
    if (res?.data === ResponseStatus.USING_SLOT_EXIST) {
      setPopupState({
        title1: PARKING_USE_SLOT_MESSAGES.TITLE,
        description: PARKING_USE_SLOT_MESSAGES.TITLE,
        isBackgroundTouchable: true,
      });
      setIsModal(true);
      return;
    }

    // 공유중인 주차장은 있고, 이용 중인 주차장은 없다
    if (res?.data === ResponseStatus.SHARING_SLOT_EXIST) {
      setPopupState({
        title1: PARKING_USE_SHARING_MESSAGES.TITLE,
        description: PARKING_USE_SHARING_MESSAGES.DESCRIPTION,
        isBackgroundTouchable: true,
      });
      setIsModal(true);
      return;
    }

    // 이용중 / 공유중 주차장 둘다 있다
    if (res?.data === ResponseStatus.SLOT_EXIST) {
      setPopupState({
        title1: PARKING_USE_SHARING_AND_SLOT_MESSAGES.TITLE,
        description: PARKING_USE_SHARING_AND_SLOT_MESSAGES.DESCRIPTION,
        isBackgroundTouchable: true,
      });
      setIsModal(true);
      return;
    }

    // 이용중 / 공유중 주차장 둘다 없다 회원 탈퇴 가능
    if (res?.data === ResponseStatus.SUCCESS) {
      setPopupState({
        title1: PARKING_USE_SUCCESS_MESSAGES.TITLE,
        description: PARKING_USE_SUCCESS_MESSAGES.DESCRIPTION,
        isBackgroundTouchable: false,
      });
      certNumberInputRef.current?.blur();
      setIsModal(true);
      return;
    }
  };

  // 사파리 브라우저 문제로 포커스 따로 세팅
  useEffect(() => {
    if (!isInputDisabled) {
      if (isIOS && certNumberInputRef.current) {
        certNumberInputRef.current.focus();
      }
    }
  }, [isInputDisabled, setFocus]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timerVisible && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setButtonText('전송');
      setTimerVisible(false);
      setIsInputDisabled(false);
      setInputPlaceholder('[전송]을 눌러 주세요');
      setIsCertButtonAndCloseIconVisible(false);
      showToast('인증 시간이 지났어요.[전송]을 눌러 주세요.', icFailCheckRed);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timerVisible, timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <MainContainer>
      <Title
        title="회원 탈퇴"
        leftArrowButtonVisible={true}
        leftOnClick={() => handleLeftOnClick()}
        rightTextButtonVisible={false}
      />
      <TitleContainer>
        <SafeTextSpan>안전한 서비스 이용</SafeTextSpan>
        <span>을 위해</span>
        <br />
        <span>본인인증을 먼저 진행해 주세요.</span>
      </TitleContainer>
      <PhoneNumberBorderContainer>
        <SubHeadline weight={'bold'} color={'primary'}>
          휴대폰 번호
        </SubHeadline>
        <Body2 weight={'regular'} color={'black3'} style={{ marginTop: 7 }}>
          {userInfoStore.phoneNumber}
        </Body2>
      </PhoneNumberBorderContainer>
      <CertNumberContainer
        selected={focusedField === 'certNumber'}
        css={{ marginTop: '13px' }}
        onClick={() => {
          setFocusedField('certNumber');
        }}
      >
        <CertNumberContentsContainer>
          <span>인증번호</span>
          {timerVisible && <TimerSpan>{formatTime(timeLeft)}</TimerSpan>}
        </CertNumberContentsContainer>
        <CertNumberContentsContainer>
          <CertTransmissionContainer>
            <CertNumberInput
              type="text"
              inputMode="numeric"
              id={'certNumber'}
              placeholder={inputPlaceholder}
              value={certNumberValue}
              {...register('certNumber', {
                required: '인증번호를 입력해주세요.',

                onChange: e => {
                  const value = e.target.value.slice(0, 6);
                  setValue('certNumber', value);
                  setIsCertButtonAndCloseIconVisible(value.length > 0);
                },
              })}
              onFocus={e => {
                setFocusedField('certNumber');
              }}
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }, 100);
              }}
              onBlur={() =>
                setFocusedField(null)
              }
              ref={certNumberInputRef}
              maxLength={6}
              disabled={isInputDisabled}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  (e.target as HTMLInputElement).blur();
                }
              }}
              style={{ backgroundColor: focusedField === 'certNumber' ? '#fff' : '#F8F8FA' }}
            />
            <CertTransmissionButtonContainer>
              {buttonText === '재전송' && isCertButtonAndCloseIconVisible && (
                <CloseIcon src={icCloseCircleGray} alt="닫기 아이콘" onClick={handleClearInput} />
              )}
              <TransmissionButton onClick={handleSendClick}>{buttonText}</TransmissionButton>
            </CertTransmissionButtonContainer>
          </CertTransmissionContainer>
        </CertNumberContentsContainer>
      </CertNumberContainer>
      <BottomButtonContainer>
        {isCertButtonAndCloseIconVisible && (
          <ButtonEnableDisable
            onMouseDown={event => event.preventDefault()}
            onClick={async () => {
              if (certNumberValue.length >= 6) {
                await handleCertSuccess();
              }
            }}
            disabled={certNumberValue.length < 6}
          >
            {certNumberValue.length >= 6 ? '인증하기' : '다음'}
          </ButtonEnableDisable>
        )}
      </BottomButtonContainer>

      {identityVerificationStore.isWithdrawalMember && (
        <PopupModal
          isVisible={identityVerificationStore.isWithdrawalMember}
          onClose={() => {
            identityVerificationStore.setIsWithdrawalMember(false);
          }}
          title1={'인증번호는 최대 5번 전송 가능합니다. 내일 인증을 해주세요.'}
          description={''}
          leftClick={() => {
            navigate('/settingMain');
          }}
          leftText={'확인'}
          isBackgroundTouchable={false}
        />
      )}

      <PopupModal
        isVisible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        title1={popupState.title1}
        description={popupState.description}
        leftClick={handleModalLeftClick}
        leftText={'확인'}
        isBackgroundTouchable={popupState.isBackgroundTouchable}
      />
    </MainContainer>
  );
});

export default DeleteAccountCertPage;
