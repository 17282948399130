import { styled } from '@stitches/react';
import { isIOS } from 'react-device-detect';
import { colors } from 'styles/colors';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100vh',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 10000,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});

export const BottomContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  width: 'calc(100%)',
  backgroundColor: '#fff',
  zIndex: 10,
});

export const Text = styled('p', {
  color: colors.gray06,
  fontSize: 12,
  fontWeight: 400,
  whiteSpace: 'pre-wrap',
});
