import React, { useEffect, useState } from 'react';

import { Dialog, Container, WrapDesc, ColorText, WrapItem, Item, WrapBottom } from './styles';
import Row from 'components/atoms/Row';
import { Caption2, Caption3, Caption4, Headline, SubHeadline } from 'styles/typography';
import { TimeSlot } from 'utils/timeUtils';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

import { IcArrowDown16SVG, IcArrowUp16SVG, IcClose48SVG, IcTooltipAlertSVG } from 'assets/ic';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  timeSlots: TimeSlot[];
  onSelectTime: (index: number) => void;
  selectTime: number | null;
}
const ExpectedEntryTimePickerModal: React.FC<Props> = ({
  isVisible,
  onClose,
  timeSlots,
  onSelectTime,
  selectTime,
}) => {
  const [isDesc, setIsDesc] = useState(true);

  useEffect(() => {
    setIsDesc(true);
  }, [isVisible]);

  return (
    <Dialog onClick={onClose} isVisible={isVisible}>
      <Container isVisible={isVisible} onClick={e => e.stopPropagation()}>
        <Row
          style={{
            margin: '0px 6px 0px 25px',
          }}
          justifyContent={'space-between'}
        >
          <Headline weight={'bold'} color={'black1'}>
            입차 예정시간을 선택해 주세요.
          </Headline>
          <IcClose48SVG onClick={onClose} />
        </Row>
        <WrapDesc>
          <Row justifyContent={'space-between'}>
            <Row>
              <IcTooltipAlertSVG fill={'#99A9B9'} width={12} height={12} />

              <Caption3 weight={'bold'} color={'gray08'} style={{ marginLeft: 5 }}>
                입차 예정시간 전후로도 차량 입차가 가능해요.
              </Caption3>
            </Row>
            <div
              onClick={() => {
                setIsDesc(!isDesc);
              }}
            >
              {isDesc ? <IcArrowUp16SVG /> : <IcArrowDown16SVG />}
            </div>
          </Row>

          {isDesc ? (
            <>
              <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
                <Caption2
                  weight={'bold'}
                  color={'gray08'}
                  style={{ marginRight: 5, marginLeft: 16 }}
                >
                  ·
                </Caption2>
                <Row style={{ flexWrap: 'wrap' }}>
                  <ColorText>
                    선택한 입차 예정 시간을 벗어나도
                    <span> 주차권의 이용 가능 시간 내에 입차 시 이용 가능</span>
                    합니다
                  </ColorText>
                </Row>
              </Row>

              <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
                <Caption2
                  weight={'bold'}
                  color={'gray08'}
                  style={{ marginRight: 5, marginLeft: 16 }}
                >
                  ·
                </Caption2>
                <Caption4 weight={'regular'} color={'gray08'}>
                  당일에만 구매 및 이용 가능한 상품입니다.
                </Caption4>
              </Row>

              <Row alignItems={'flex-start'} style={{ marginTop: 8 }}>
                <Caption2
                  weight={'bold'}
                  color={'gray08'}
                  style={{ marginRight: 5, marginLeft: 16 }}
                >
                  ·
                </Caption2>
                <Caption4 weight={'regular'} color={'gray08'}>
                  입차 예정시간은 운영 참고 목적으로만 활용됩니다.
                </Caption4>
              </Row>
            </>
          ) : null}
        </WrapDesc>

        <WrapItem>
          {timeSlots.map((el, index) => {
            return (
              <Item
                key={index}
                onClick={() => {
                  onSelectTime(index);
                }}
                style={{
                  backgroundColor: selectTime === index ? '#FFF' : '#FBFBFB',
                  border: selectTime === index ? `1px solid ${colors.primary}` : 'none',
                  marginTop: index === 0 ? 0 : 10,
                }}
              >
                <SubHeadline weight={'medium'} color={'gray09'}>
                  {el.time}
                </SubHeadline>
              </Item>
            );
          })}
        </WrapItem>
        <WrapBottom>
          <Button
            style={{
              width: 'calc(100% - 50px)',
              height: 48,
              marginBottom: 40,
            }}
            backgroundColor={colors.primary}
            radius={4}
            onClick={onClose}
          >
            <SubHeadline weight={'medium'} color={'white'}>
              다음
            </SubHeadline>
          </Button>
        </WrapBottom>
      </Container>
    </Dialog>
  );
};

export default ExpectedEntryTimePickerModal;
