import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainContainerNoMargin } from 'components/styles';
import { Container } from './styles';
import { Body, SubHeadline, Title3 } from 'styles/typography';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import DesiredAreaRegisterList from 'containers/monthlyParking/DesiredAreaRegisterList';
import { monthlyStore } from 'stores/store/Monthly/monthlyStore';
import { Spinner } from 'components/atoms/LoadingSpinner/LoadingSpinnerImage/styles';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { moveJSP } from 'utils/common';
import Row from 'components/atoms/Row';

import { IcClose48SVG } from 'assets/ic';

const DesiredAreaManagementPage = observer(() => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { state } = useLocation();
  const { nextPage } = state;

  const { isLoading } = monthlyStore;

  const { memberUID } = userInfoStore;

  const handleLeftOnClick = () => {
    if (nextPage === '/myInfo') {
      navigate(-1);
      return;
    }
    moveJSP();
    // navigate('home');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    // 데이터를 가져오는 함수 정의
    if (memberUID) {
      const fetchData = async () => {
        try {
          await monthlyStore.fetchGetDesiredAddrs(memberUID);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData(); // 데이터 가져오기 호출
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <MainContainerNoMargin>
      <Row
        justifyContent={'space-between'}
        style={{
          height: 66,
        }}
      >
        <div></div>
        <Body
          weight={'bold'}
          color={'primaryDark'}
          style={{
            textAlign: 'center', // 텍스트 가운데 정렬
            position: 'absolute', // 절대 위치 지정
            left: '50%', // 수평 중앙
            transform: 'translateX(-50%)',
          }}
        >
          희망 지역 관리
        </Body>
        <IcClose48SVG
          style={{ marginRight: 4 }}
          onClick={() => {
            handleLeftOnClick();
          }}
        />
      </Row>

      <Container>
        <Title3 weight={'bold'} color={'black3'}>
          월 주차 희망지역
        </Title3>
        <SubHeadline weight={'regular'} color={'gray09'} style={{ marginTop: 16 }}>
          지역을 등록하면, 희망 지역 인근에 있는 주차장을 알려드려요.
        </SubHeadline>

        <DesiredAreaRegisterList />
      </Container>
    </MainContainerNoMargin>
  );
});

export default DesiredAreaManagementPage;
