import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '30px 20px 20px 20px',
});

export const ParkingInfoSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const WarpChargerItem = styled('div', {
  display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  borderRadius: 5,
  marginTop: 10,
  padding: '16px 20px',
  flexDirection: 'column',
});
