export interface ErrorData<T = any> {
  status: number;
  errorMessage?: string;
  errorType?: string;
  response?: T;
  error_msg?: string;
  err_msg?: string;
}

export default class BaseError<T = any> extends Error {
  status: number;
  errorMessage: string;
  errorType: string;
  response: T | undefined;
  error_msg: string;
  err_msg: string;

  constructor(errorData: ErrorData) {
    super(errorData.errorMessage);

    this.status = errorData.status;
    this.errorMessage = errorData.errorMessage || '';
    this.errorType = errorData.errorType || '';
    this.response = errorData.response || '';
    this.error_msg = errorData.error_msg || '';
    this.err_msg = errorData.err_msg || '';

    Object.setPrototypeOf(this, BaseError.prototype);
  }
}
