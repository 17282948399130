import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalContainer, RoadWrap } from './styles';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { Body, Caption1, Caption2, Caption4 } from 'styles/typography';
import { publicDetail } from 'containers/home/HomeMap/mock';
import { formatPriceWithCommas } from 'utils/number';
import { setDial } from 'utils/deviceUtils';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

import { IcRoadFindSVG } from 'assets/ic';
import GeojuParkingPublicDetailModal from 'components/atoms/Modal/GeojuParkingPublicDetailModal';

interface GeojuPublicInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
  lat: string;
  lon: string;
}

const GeojuPublicInfoModal: React.FC<GeojuPublicInfoModalProps> = ({
  isVisible,
  onClose,
  lat,
  lon,
}) => {
  const navigate = useNavigate();
  const { memberData } = userInfoStore;

  const [isModal, setIsModal] = useState(false);

  if (!isVisible) return null;

  return (
    <ModalContainer
      isVisible={isVisible}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Row alignItems={'center'} justifyContent={'space-between'}>
        <div>
          <Row>
            <Button backgroundColor={'#C4C4C4'} radius={3} horizontal={5} vertical={3}>
              <Caption4 weight={'regular'} color={'white'}>
                공영
              </Caption4>
            </Button>

            <Body weight={'regular'} color={'black1'} style={{ marginLeft: 8 }}>
              {publicDetail.slotList[0].prkplceNm}
            </Body>
          </Row>

          <Row style={{ marginTop: 8 }}>
            <Caption4 weight={'regular'} color={'gray06'}>
              {publicDetail.slotList[0].prkcmprt}면
            </Caption4>
            <Caption4 weight={'regular'} color={'gray06'} style={{ marginLeft: 4, marginRight: 4 }}>
              |
            </Caption4>
            <Caption4 weight={'regular'} color={'gray06'}>
              {publicDetail.slotList[0].prkplceType}
            </Caption4>
          </Row>
        </div>
        <RoadWrap
          onClick={() => {
            const name = '현대백화점 판교점';
            openNavi(String(lat), String(lon), name, memberData?.naviType ?? '');
          }}
        >
          <IcRoadFindSVG fill={'#fff'} />
        </RoadWrap>
      </Row>

      <Button
        backgroundColor={'#F2F2F2'}
        radius={5}
        style={{
          width: '100%',
          height: 40,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <Caption2 weight={'regular'} color={'gray06'}>
          앱 결제를 지원하지 않는 주차장입니다
        </Caption2>
      </Button>

      <Row justifyContent={'space-between'}>
        <Caption1 weight={'regular'} color={'gray06'}>
          주차 요금
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {publicDetail.slotList[0].basicTime}분 당{' '}
          {formatPriceWithCommas(publicDetail.slotList[0].basicCharge)}원
        </Caption1>
      </Row>

      <Row justifyContent={'space-between'} style={{ marginTop: 10 }}>
        <Caption1 weight={'regular'} color={'gray06'}>
          운영 시간
        </Caption1>
        <Caption1 weight={'regular'} color={'black3'}>
          {publicDetail.slotList[0].weekdayOperOpenHhmm} ~{' '}
          {publicDetail.slotList[0].weekdayOperColseHhmm} 운영
        </Caption1>
      </Row>

      <Row style={{ gap: '10px', marginTop: 20 }}>
        <Button
          backgroundColor={colors.white}
          borderColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
          }}
          onClick={() => {
            if (publicDetail.slotList[0].phoneNumber === '') {
              alert('연결전화번호가 없습니다.');
            } else {
              setDial(publicDetail.slotList[0].phoneNumber);
            }
            // onClose();
          }}
        >
          <Caption2 weight={'bold'} color={'primary'}>
            전화연결
          </Caption2>
        </Button>
        <Button
          backgroundColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
          }}
          onClick={() => {
            setIsModal(true);
          }}
        >
          <Caption2 weight={'bold'} color={'white'}>
            상세보기
          </Caption2>
        </Button>
      </Row>

      <GeojuParkingPublicDetailModal
        isVisible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        type={'public'}
      />
    </ModalContainer>
  );
};

export default observer(GeojuPublicInfoModal);
