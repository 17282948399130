import React, { useState, useEffect } from 'react';

import { Modal, WrapDesc } from './styles';
import { Caption1, Caption2, Headline, SubHeadline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';

interface BottomModalProps {
  isVisible: boolean;
  onClose: () => void;
  rightClick?: () => void;
  usingDate: string;
  carType: string;
}

const MinganParkApproveBottomModal: React.FC<BottomModalProps> = ({
  isVisible,
  onClose,
  rightClick,
  usingDate,
  carType,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
        onClick={onClose}
      />
      <Modal isVisible={isVisible}>
        <Row style={{ whiteSpace: 'pre-wrap' }}>
          <Headline
            weight={'medium'}
            color={'black1'}
          >{`${carType}의\n주차장 이용을 승인할까요?`}</Headline>
        </Row>
        <Caption1
          weight={'regular'}
          color={'gray08'}
          style={{ marginTop: 16, wordBreak: 'keep-all' }}
        >
          승인 시 운전자에게 바로 승인 알림 메시지가 갑니다.
        </Caption1>

        <WrapDesc>
          <Caption1 weight={'regular'} color={'primary'} style={{ wordBreak: 'keep-all' }}>
            {usingDate}
          </Caption1>
        </WrapDesc>
        <Row style={{ gap: '10px' }}>
          <Button
            backgroundColor={colors.gray01}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Caption2 weight={'semibold'} color={'gray06'}>
              취소
            </Caption2>
          </Button>
          <Button
            backgroundColor={colors.primary}
            radius={4}
            style={{
              height: 46,
              width: 'calc(100%)',
              marginTop: 28,
            }}
            onClick={() => {
              rightClick && rightClick();
            }}
          >
            <Caption2 weight={'semibold'} color={'white'}>
              승인하기
            </Caption2>
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default MinganParkApproveBottomModal;
