import axios from 'axios';
import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';

import { IDesiredAddress, IDesiredAddressResponse } from 'models/Monthly/desiredAddressModel';
import {
  IDesiredAddressInsert,
  IDesiredAddressInsertResponse,
} from 'models/Monthly/desiredAddressInsertModel';
import {
  IDesiredAddressDelete,
  IDesiredAddressDeleteResponse,
} from 'models/Monthly/desiredAddressDeleteModel';

interface AddressApiEndpoint {
  DESIRED_ADDRESS: string;
  DESIRED_ADDRESS_INSERT: string;
  DESIRED_ADDRESS_DELETE: string;
}

class MonthlyAddressService extends BaseService<AddressApiEndpoint> {
  apiEndpoints: AddressApiEndpoint = {
    DESIRED_ADDRESS: 'getDesiredAddrs',
    DESIRED_ADDRESS_INSERT: 'insertDesireAddr',
    DESIRED_ADDRESS_DELETE: 'deleteDesireAddr',
  };

  constructor() {
    super();
  }

  getDesiredAddrs = async (
    requestBody: IDesiredAddress,
  ): Promise<IDesiredAddressResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()

        .url(`${this.apiEndpoints.DESIRED_ADDRESS}/${requestBody.mLinkUID}`)
        .build<IDesiredAddressResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  insertDesireAddr = async (
    requestBody: IDesiredAddressInsert,
  ): Promise<IDesiredAddressInsertResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.DESIRED_ADDRESS_INSERT}`)
        .build<IDesiredAddressInsertResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  deleteDesireAddr = async (
    requestBody: IDesiredAddressDelete,
  ): Promise<IDesiredAddressDeleteResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .delete()
        .url(`${this.apiEndpoints.DESIRED_ADDRESS_DELETE}/${requestBody.addrUID}`)
        .build<IDesiredAddressDeleteResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new MonthlyAddressService();

export const fetchAddressData = async (address: string): Promise<any | null> => {
  try {
    // API 호출 (XML 형식의 응답 받기)ㄴ
    const apiUrl = `https://www.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=10&keyword=${encodeURIComponent(
      address,
    )}&confmKey=U01TX0FVVEgyMDIyMDUyNzE1NTIyMDExMjYyMjM=&resultType=xml`;

    const response = await axios.get(apiUrl, { headers: { Accept: 'application/xml' } });
    const xmlData = response.data; // XML 형식으로 응답 받음

    // XML 파싱
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, 'text/xml');
    const elementRoot = xmlDoc.documentElement;
    const nodeList = elementRoot.childNodes;

    let resultData: Record<string, string> = {};

    // XML의 첫 번째 자식 요소가 'juso' 요소일 때 처리
    if (nodeList.length > 1) {
      const jusoNodes = nodeList.item(1)?.childNodes;

      if (jusoNodes) {
        Array.from(jusoNodes).forEach((node: any) => {
          if (node.nodeType === 1) {
            // Element 노드인 경우
            const element = node;
            resultData[element.nodeName] = element.textContent || '';
          }
        });
      }
    }

    // 주소 추가
    resultData['addr'] = address;
    // 최종 응답 객체 반환
    return resultData;
  } catch (error) {
    console.error('주소 검색 오류:', error);
    return null; // 에러 발생 시 null 반환
  }
};
