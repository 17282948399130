import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ModalContainer,
  MinganPlacePhotoWeekContainer,
  MinganPlaceWeekContainer,
  MinganParkingKindContainer,
  MinganKindListContainer,
  MinganListSpan,
  AmountContainer,
  Badge,
} from './styles';
import { Body, Caption2, Caption3, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { colors } from 'styles/colors';
import { chargerListData } from 'containers/home/HomeMap/mock';
import ChargingStationDetailModal from 'components/atoms/Modal/ChargingStationDetailModal';
import Button from 'components/atoms/Button/Button';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';

import { IcDirections16SVG, IcRoadFindSVG } from 'assets/ic';

interface ChargingInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const MAP_CHARGER_TYPE = new Map([
  ['01', 'DC차데모'],
  ['02', 'AC완속'],
  ['03', 'DC차데모+AC3상'],
  ['04', 'DC콤보'],
  ['05', 'DC차데모+DC콤보'],
  ['06', 'DC차데모+AC3상+DC콤보'],
  ['07', 'AC3상'],
  ['08', 'DC콤보(완속)'],
  ['89', 'H2'],
]);

const ChargingInfoModal: React.FC<ChargingInfoModalProps> = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const [chargerTypes, setChargerTypes] = useState<any>([]);
  const [isModal, setIsModal] = useState(false);

  const { memberData } = userInfoStore;

  const item = chargerListData.chargerStat;

  useEffect(() => {
    const uniqueChargerTypes = Array.from(
      new Set(
        chargerListData?.result.map(el => el.chgerType) || [], // 중복 제거 및 null 안전 처리
      ),
    ).map(type => MAP_CHARGER_TYPE.get(type));
    setChargerTypes(uniqueChargerTypes);
  }, []);

  if (!isVisible) return null;

  return (
    <ModalContainer onClick={e => e.stopPropagation()}>
      <div>
        <Button
          backgroundColor={'#404246'}
          radius={16}
          style={{
            position: 'absolute',
            right: 0,
            top: -42,
            height: 32,
          }}
          onClick={() => {
            const name = '현대백화점 판교점';
            const lat = 37.508725;
            const lng = 126.891295;
            openNavi(String(lat), String(lng), name, memberData?.naviType ?? '');
          }}
        >
          <Row>
            <IcDirections16SVG fill={'#fff'} />
            <Caption2 weight={'semibold'} color={'white'} style={{ marginLeft: 4 }}>
              길찾기
            </Caption2>
          </Row>
        </Button>

        <MinganPlacePhotoWeekContainer
          onClick={() => {
            setIsModal(true);
          }}
        >
          <MinganPlaceWeekContainer>
            <Row>
              <img
                width={18}
                height={18}
                src={item.logoImagePath}
                style={{ marginRight: 5 }}
                alt={'기관로고'}
              />
              <Caption3 weight={'regular'} color={'gray09'}>
                {item.busiNm}
              </Caption3>
            </Row>

            <Body
              weight={'bold'}
              color={'black1'}
              style={{
                marginBottom: 3,
                width: '100%',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                textOverflow: 'ellipsis',
              }}
            >
              {item.statNm}
            </Body>
            <Caption3 weight={'regular'} color={'gray06'}>
              {chargerListData.result[0].addr}
            </Caption3>
          </MinganPlaceWeekContainer>
        </MinganPlacePhotoWeekContainer>

        <MinganParkingKindContainer>
          {chargerTypes.map((el: any, index: number) => {
            return (
              <MinganKindListContainer key={index}>
                <MinganListSpan>{el}</MinganListSpan>
              </MinganKindListContainer>
            );
          })}
        </MinganParkingKindContainer>
        <AmountContainer>
          <Row style={{ width: '50%' }}>
            <Badge style={{ backgroundColor: colors.blue }}>
              <Caption4 weight={'bold'} color={'white'}>
                급속
              </Caption4>
            </Badge>
            <Caption2 weight={'bold'} color={'red'}>
              {item.rapidAvailableCnt}
            </Caption2>
            <Caption2 weight={'bold'} color={'gray07'}>
              /
            </Caption2>
            <Caption2 weight={'bold'} color={'gray07'}>
              {item.rapidTotalCnt}
            </Caption2>
          </Row>

          <Row>
            <Badge style={{ backgroundColor: colors.gray01 }}>
              <Caption4 weight={'bold'} color={'gray09'}>
                완속
              </Caption4>
            </Badge>
            <Caption2 weight={'bold'} color={'blue'} style={{ color: '#20AEE5' }}>
              {item.slowAvailableCnt}
            </Caption2>
            <Caption2 weight={'bold'} color={'gray07'}>
              /
            </Caption2>
            <Caption2 weight={'bold'} color={'gray07'}>
              {item.slowTotalCnt}
            </Caption2>
          </Row>
        </AmountContainer>
      </div>

      <ChargingStationDetailModal
        isVisible={isModal}
        onClose={() => {
          setIsModal(false);
        }}
      />
    </ModalContainer>
  );
};

export default ChargingInfoModal;
