import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Dot,
  ReductionChoiceContentsContainer,
  BottomContainer,
  ReductionNotifyNumberText,
  ReductionNotifyNumberTitleText,
  ReductionNotifyTitleContainer,
  ReductionNotifyTitleText,
  ReductionTitleContainer,
  ReductionTypeArrowImg,
  ReductionTypeChoiceContainer,
  ReductionTypeChoiceText,
  ReductionTypeText,
  SeongdongGuCheckContainer,
  ViewDivideContainer,
} from '../styles';
import {
  NextButton,
  ReductionChoiceContainer,
  ReductionNotifyFirstContentsText,
  ReductionNotifyFirstListContainer,
} from './styles';
import CareReductionBottomModal from 'components/atoms/Modal/CareReductionBottomModal';
import { MainContainer } from 'components/styles';
import Title from 'components/atoms/Title/PageTitle';
import { taxReductionStore } from 'stores/store/TaxReduction/taxReductionStore';
import { ITaxReductionTypeData } from 'models/TaxReduction/taxReductionTypeModel';

import icArrowBottomPurple from 'assets/ic/ic_arrow_bottom_purple.svg';
import icExclamationPurple from 'assets/ic/ic_exclamation_purple.svg';
import { carStore } from 'stores/store/Car/carStore';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { useToast } from 'components/atoms/Toast/ToastProvider';

const ReductionCategory = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { type } = state;
  const { showToast } = useToast();

  const [isCarReductionBottomModalVisible, setIsCarReductionBottomModalVisible] = useState(false);
  const [selectedReduction, setSelectedReduction] = useState<ITaxReductionTypeData | null>(null);

  const { taxReductionList } = taxReductionStore;

  const handleLeftOnClick = () => {
    navigate(-2);
    showToast('차량 정보가 등록되었어요.', icSuccessCheckGreen);
    carStore.setCarNumber('');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await taxReductionStore.fetchGetTaxReductionType();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData().catch(error => {
      console.error('감면 정보 가져오기 실패:', error);
    });
  }, []);

  const handleConfirm = (item: ITaxReductionTypeData) => {
    setSelectedReduction(item);
  };

  const handleNext = () => {
    if (selectedReduction) {
      navigate('/reductionFileAttach', {
        state: {
          taxReductionTypeUID: selectedReduction.taxReductionTypeUID,
          type,
        },
      });
    }
  };

  return (
    <MainContainer
      style={{ minHeight: 'calc(100vh - env(safe-area-inset-top))', position: 'relative' }}
    >
      <Title
        title="복지대상 신청"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={true}
        rightOnClick={handleLeftOnClick}
        rightTitle={'건너뛰기'}
      />

      <ReductionTitleContainer>
        <span>공영 주차장</span>
        <br />
        <span style={{ color: '#8B63BB' }}>
          요금 감면 대상자<span style={{ color: '#333' }}>이신가요?</span>
        </span>
      </ReductionTitleContainer>
      <ReductionChoiceContainer onClick={() => setIsCarReductionBottomModalVisible(true)}>
        <ReductionChoiceContentsContainer>
          <ReductionTypeText>
            요금 감면 유형
            <br />
            <ReductionTypeChoiceContainer>
              <ReductionTypeChoiceText selected={!!selectedReduction}>
                {selectedReduction?.category || '요금 감면 유형 선택'}
              </ReductionTypeChoiceText>
              <ReductionTypeArrowImg src={icArrowBottomPurple} alt={'애로우 바텀 아이콘'} />
            </ReductionTypeChoiceContainer>
          </ReductionTypeText>
        </ReductionChoiceContentsContainer>
      </ReductionChoiceContainer>
      <BottomContainer>
        <div>
          <ReductionNotifyTitleContainer>
            <img src={icExclamationPurple} alt="유의사항 아이콘" style={{ marginRight: '8px' }} />
            <ReductionNotifyTitleText>유의사항</ReductionNotifyTitleText>
          </ReductionNotifyTitleContainer>
          <ViewDivideContainer />
          <div style={{ marginBottom: '6px' }}>
            <ReductionNotifyNumberText>01</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              아래의 유형은 온라인 등록이 제한됩니다.
            </ReductionNotifyNumberTitleText>
          </div>
          <ReductionNotifyFirstListContainer>
            <ReductionNotifyFirstContentsText>
              본인 명의 차량이 아닌 경우
            </ReductionNotifyFirstContentsText>
            <ReductionNotifyFirstContentsText>헌혈증 소지자</ReductionNotifyFirstContentsText>
            <ReductionNotifyFirstContentsText>공동명의 차량</ReductionNotifyFirstContentsText>
            <ReductionNotifyFirstContentsText>
              법인명의 차량 (렌트카 포함)
            </ReductionNotifyFirstContentsText>
          </ReductionNotifyFirstListContainer>
          <div style={{ marginBottom: '10px' }}>
            <ReductionNotifyNumberText>02</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              <span>
                감면 대상 등록 시<span style={{ color: '#ED1659' }}> 일부 지역</span> 에서만 주만사
                앱
              </span>
              <br />
              <span style={{ marginLeft: '26px' }}>에서 요금 감면이 가능합니다.</span>
            </ReductionNotifyNumberTitleText>
            <SeongdongGuCheckContainer>
              <div style={{ marginTop: '4px' }}>
                <Dot style={{ color: '#ED1659' }}>•</Dot>
                <span style={{ color: '#ED1659', fontSize: 16 }}>성동구</span>
              </div>
            </SeongdongGuCheckContainer>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <ReductionNotifyNumberText>03</ReductionNotifyNumberText>
            <ReductionNotifyNumberTitleText>
              <span>
                주차 요금 무인 감면 서비스는 본인명의(공동명의 포<br />
                <span style={{ marginLeft: '26px' }}>
                  함) 차량 1대만 등록이 가능하며, 부정이용 시 이용
                </span>
                <br />
                <span style={{ marginLeft: '26px' }}>이 제한 될 수 있습니다.</span>
              </span>
            </ReductionNotifyNumberTitleText>
          </div>
        </div>
        <NextButton onClick={handleNext} disabled={!selectedReduction}>
          다음
        </NextButton>
      </BottomContainer>
      <CareReductionBottomModal
        isVisible={isCarReductionBottomModalVisible}
        onClose={() => setIsCarReductionBottomModalVisible(false)}
        onConfirm={handleConfirm}
        selectedItem={selectedReduction}
        reductionsItems={taxReductionList}
      />
    </MainContainer>
  );
});

export default ReductionCategory;
