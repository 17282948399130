import {
  IMyParkingAllPastHistoryData,
  IMyParkingSeasonTicketPastHistoryData, IMyParkingTimeTicketPastHistoryData
} from "models/MyParking/myParkingPastHistoryModel";
import {makeAutoObservable} from "mobx";
import myParkingPastListService from "service/MyParking/myParkingPastListService";
import BaseError from "models/baseError";

class MyParkingPastListStore {

  constructor() {
    makeAutoObservable(this)
  }

  // 전체
  isAllTicketLoading = false
  allTicketError: string | null = null
  allParkingPastHistoryList: IMyParkingAllPastHistoryData[] = []
  allTotalCount = 0
  allCurrentPage = 1
  allItemsPerPage = 20


  // 정기권
  isSeasonTicketLoading = false
  seasonTicketError: string | null = null
  seasonTicketParkingPastHistoryList: IMyParkingSeasonTicketPastHistoryData[] = []
  seasonTicketTotalCount = 0
  seasonTicketCurrentPage = 1
  seasonTicketItemsPerPage = 20


  // 시간권 or 시간제
  isTimeTicketLoading = false
  timeTicketError: string | null = null
  timeTicketParkingPastHistoryList: IMyParkingTimeTicketPastHistoryData [] = []
  timeTicketTotalCount = 0
  timeTicketCurrentPage = 1
  timeTicketItemsPerPage = 20

  // 전체
  setAllTicketLoading(value: boolean) {
    this.isAllTicketLoading = value
  }

  setAllTicketError(error: string) {
    this.allTicketError = error
  }

  setAllTicketPastHistoryList(data: IMyParkingAllPastHistoryData[]) {
    this.allParkingPastHistoryList = data
  }

  setAllTotalCount(count: number) {
    this.allTotalCount = count
  }

  setAllCurrentPage(page: number) {
    this.allCurrentPage = page
  }


  // 정기권
  setSeasonTicketLoading(value: boolean) {
    this.isSeasonTicketLoading = value
  }

  setSeasonTicketError(error: string) {
    this.seasonTicketError = error
  }

  setSeasonTicketParkingUseHistoryList(data: IMyParkingSeasonTicketPastHistoryData[]) {
    this.seasonTicketParkingPastHistoryList = data
  }

  setSeasonTicketTotalCount(count: number) {
    this.seasonTicketTotalCount = count
  }

  setSeasonTicketCurrentPage(page: number) {
    this.seasonTicketCurrentPage = page
  }


  // 시간제, 시간권
  setTimeTicketLoading(value: boolean) {
    this.isTimeTicketLoading = value
  }

  setTimeTicketError(error: string) {
    this.timeTicketError = error
  }

  setTimeTicketParkingUseHistoryList(data: IMyParkingTimeTicketPastHistoryData[]) {
    this.timeTicketParkingPastHistoryList = data
  }

  setTimeTicketTotalCount(count: number) {
    this.timeTicketTotalCount = count
  }

  setTimeTicketCurrentPage(page: number) {
    this.timeTicketCurrentPage = page
  }

  async fetchGetMyParkingAllPastHistory(mLinkUID: number, searchTime: string, allPageNumber: number) {
    if (this.isAllTicketLoading) return

    const requestBody = { mLinkUID, searchTime }

    this.setAllTicketLoading(true)

    const response = await myParkingPastListService.getAllTicketPastHistory(requestBody, allPageNumber)

    if (response instanceof BaseError) {
      this.setAllTicketError(response.errorMessage)
      this.setAllTicketLoading(false)
      return false
    } else {
      const { data, totalCount } = response

      if (Array.isArray(data)) {
        if (allPageNumber === 1) {
          this.setAllTicketPastHistoryList(data)
        } else {
          this.setAllTicketPastHistoryList([
            ...this.allParkingPastHistoryList,
            ...data,
          ])
        }
        this.setAllTotalCount(totalCount)
        this.setAllCurrentPage(allPageNumber)
      }
      this.setAllTicketLoading(false)
      return true
    }
  }

  async fetchGetMyParkingSeasonTicketPastHistory(mLinkUID: number, searchTime: string, seasonTicketPageNumber: number) {
    if (this.isSeasonTicketLoading) return

    const requestBody = { mLinkUID, searchTime }

    this.setSeasonTicketLoading(true)

    const response = await myParkingPastListService.getSeasonTicketPastHistory(requestBody, seasonTicketPageNumber)

    if (response instanceof BaseError) {
      this.setSeasonTicketError(response.errorMessage)
      this.setSeasonTicketLoading(false)
      return false
    } else {
      const { data, totalCount } = response

      if (Array.isArray(data)) {
        if (seasonTicketPageNumber === 1) {
          this.setSeasonTicketParkingUseHistoryList(data)
        } else {
          this.setSeasonTicketParkingUseHistoryList([
            ...this.seasonTicketParkingPastHistoryList,
            ...data,
          ])
        }
        this.setSeasonTicketTotalCount(totalCount)
        this.setSeasonTicketCurrentPage(seasonTicketPageNumber)
      }
      this.setSeasonTicketLoading(false)
      return true
    }
  }

  async fetchGetMyParkingTimeTicketPastHistory(mLinkUID: number, searchTime: string, timeTicketPageNumber: number) {
    if (this.isTimeTicketLoading) return

    const requestBody = { mLinkUID, searchTime }

    this.setTimeTicketLoading(true)

    const response = await myParkingPastListService.getTimeTicketPastHistory(requestBody, timeTicketPageNumber)

    if (response instanceof BaseError) {
      this.setTimeTicketError(response.errorMessage)
      this.setTimeTicketLoading(false)
      return false
    } else {
      const { data, totalCount } = response

      if (Array.isArray(data)) {
        if (timeTicketPageNumber === 1) {
          this.setTimeTicketParkingUseHistoryList(data)
        } else {
          this.setTimeTicketParkingUseHistoryList([
            ...this.timeTicketParkingPastHistoryList,
            ...data,
          ])
        }
        this.setTimeTicketTotalCount(totalCount)
        this.setTimeTicketCurrentPage(timeTicketPageNumber)
      }
      this.setTimeTicketLoading(false)
      return true
    }
  }
}

export const myParkingPastHistoryStore = new MyParkingPastListStore()
