import BaseError from 'models/baseError';
import AxiosClient from 'utils/axiosClient';
import ErrorHandler from 'utils/error/errorHandler';
import BaseService from 'service/baseService';

import {
  IMonthlyParkingProduct,
  IMonthlyParkingProductResponse,
} from 'models/Monthly/parkingProductModel';
import {
  ICreateMonthlyPassOrder,
  ICreateMonthlyPassOrderResponse,
} from 'models/Monthly/createMonthlyPassOrderModel';
import { ICreateWaiting, ICreateWaitingResponse } from 'models/Monthly/createWaitingDataModel';

interface MonthlyParkingApiEndpoint {
  GET_MONTHLY_PRODUCT: string;
  CREATE_MONTHLY_PASS_ORDER: string;
  CREATE_WAITING_DATA: string;
}

class MonthlyParkingService extends BaseService<MonthlyParkingApiEndpoint> {
  apiEndpoints: MonthlyParkingApiEndpoint = {
    GET_MONTHLY_PRODUCT: 'getMonthlyProduct',
    CREATE_MONTHLY_PASS_ORDER: 'createMonthlyPassOrder',
    CREATE_WAITING_DATA: 'createWaitingData',
  };

  constructor() {
    super();
  }

  getMonthlyProduct = async (
    requestBody: IMonthlyParkingProduct,
  ): Promise<IMonthlyParkingProductResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()

        .url(`${this.apiEndpoints.GET_MONTHLY_PRODUCT}/${requestBody.productUID}`)
        .build<IMonthlyParkingProductResponse>();

      if (response.data.res_code === 200) {
        return response.data;
      } else {
        return ErrorHandler.create(new Error(response.data.err_msg)).getError();
      }
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  createMonthlyPassOrder = async (
    requestBody: ICreateMonthlyPassOrder,
  ): Promise<ICreateMonthlyPassOrderResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.CREATE_MONTHLY_PASS_ORDER}/`)
        .build<ICreateMonthlyPassOrderResponse>();

      return response.data;
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };

  createWaitingData = async (
    requestBody: ICreateWaiting,
  ): Promise<ICreateWaitingResponse | BaseError> => {
    try {
      const response = await AxiosClient.create()
        .post()
        .data({ ...requestBody })
        .url(`${this.apiEndpoints.CREATE_WAITING_DATA}/`)
        .build<ICreateWaitingResponse>();

      return response.data;
    } catch (error) {
      console.error(error);
      return ErrorHandler.create(error).getError();
    }
  };
}

export default new MonthlyParkingService();
