import { styled } from '@stitches/react';
import icCheckWhite from 'assets/ic/ic_check_white.svg';
import icCheckGray from 'assets/ic/ic_check_gray.svg';
import { colors } from 'styles/colors';
import { isIOS } from 'react-device-detect';

// 나중에 변경 필요하기 때문에 컨테이너 이름을 TimeTicket 으로 설정 합니다
export const TimeTicketAllContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const TitleContainer = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'white',
  zIndex: 10,
  marginTop: isIOS ? '30px' : '0px',
  padding: '10px', // 타이틀 상단에 여백 추가 (필요시 조정)
});

export const CautionsContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: 20,
  marginBottom: 0,
});

export const RequiredText = styled('span', {
  color: '#FF0000',
  marginRight: '4px',
});

export const BottomButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  textAlign: 'center',
});

export const AgreeButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#868686',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',

  variants: {
    state: {
      agreeCheck: {
        backgroundColor: '#8B63BB', // 선택된 상태일 때 배경색
      },
      notCheck: {
        backgroundColor: '#868686', // 비활성화된 상태일 때 배경색
      },
    },
  },
});

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',
  backgroundImage: `url(${icCheckGray})`,
  backgroundSize: '12px 12px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',

  '&:checked': {
    backgroundColor: '#514263',
    borderColor: '#514263',
    backgroundImage: `url(${icCheckWhite})`,
    backgroundSize: '12px 12px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
});

// 나중에 변경 필요하기 때문에 컨테이너 이름을 TimeTicket 으로 설정 합니다
export const TimeTicketCautionBottomContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: colors.white,
  zIndex: 10,
  paddingTop: 20,
});

export const CautionTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ExclamationBlackIc = styled('img', {
  width: '18px',
  height: '18px',
});

export const AgreeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '20px 20px 28px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const CautionContainer = styled('div', {
  padding: '10px 16px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '10px',
});

export const CautionColumnContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ProductNameWrap = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '28px 20px 20px 20px',
});

export const CautionIcon = styled('img', {
  width: '50px',
  height: '56px',
});

export const BannerWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
});

export const WarningContainer = styled('div', {
  display: 'flex',
  padding: '12px',

  backgroundColor: '#F8F8FA',
  borderRadius: '4px',
  alignItems: 'center', // 상단 정렬
  justifyContent: 'flex-start', // 왼쪽 정렬
  gap: '10px', // 이미지와 텍스트 간격 조정
  '&:first-child': {
    marginTop: 0,
  },
  marginTop: '10px',
});
