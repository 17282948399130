import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, WrapInfo } from './styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import Row from 'components/atoms/Row';
import { Caption2, Caption3, Headline, SubHeadline } from 'styles/typography';
import { IMonthlyProductInfo } from 'models/Share/shareProductListModel';
import { formatPriceWithCommas } from 'utils/number';

interface Props {
  isSale: boolean;
  data: IMonthlyProductInfo;
}

const MinganParkProductInfo: React.FC<Props> = ({ isSale, data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        backgroundColor={isSale ? colors.primarySubSub : colors.gray03}
        vertical={3}
        horizontal={8}
      >
        <Row>
          <Caption2 weight={'medium'} color={isSale ? 'primary' : 'gray07'}>
            {data.productStatus === 'SALE' ? '판매중' : '판매중지'}
          </Caption2>
        </Row>
      </Button>
      <Headline weight={'semibold'} color={'black1'} style={{ marginTop: 8 }}>
        {data.productName}
      </Headline>
      <WrapInfo>
        <Row justifyContent={'space-between'} style={{ marginBottom: 9 }}>
          <Caption2 weight={'medium'} color={'gray09'}>
            기본 수익금
          </Caption2>
          <SubHeadline weight={'bold'} color={'black3'}>
            {formatPriceWithCommas(data.settlement)}원
          </SubHeadline>
        </Row>
        <Row justifyContent={'space-between'}>
          <Caption2 weight={'medium'} color={'gray09'}>
            수량
          </Caption2>
          <SubHeadline weight={'bold'} color={'black3'}>
            {data.saleProductTotalCnt}
          </SubHeadline>
        </Row>
      </WrapInfo>
      <Caption3
        weight={'regular'}
        color={'gray07'}
        style={{ marginTop: 16, wordBreak: 'keep-all' }}
      >
        기본 수익금은 상품 등록 시 설정한 수익금입니다. 상품 수량 변경을 원할 경우 “주만사 월주차”
        카톡채널로 연락 주세요.
      </Caption3>
    </Container>
  );
};

export default MinganParkProductInfo;
