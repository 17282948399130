import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal, WrapDesc } from './styles';
import { Caption2, Headline } from 'styles/typography';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { minyoung } from 'containers/home/HomeMap/mock';
import CancelReasonStore from 'stores/store/CancelReason/CancelResaonStore';

import { IcCloseBlack28SVG } from 'assets/ic';

interface GeojuCautionModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const GeojuCautionModal: React.FC<GeojuCautionModalProps> = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  if (!isVisible) return null;

  const handleBackgroundClick = (e: React.MouseEvent) => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 9999,
        }}
        onClick={handleBackgroundClick}
      />
      <Modal isVisible={isVisible}>
        <Row
          style={{
            whiteSpace: 'pre-wrap',
          }}
          justifyContent={'space-between'}
        >
          <Headline weight={'bold'} color={'black1'}>
            유의사항 안내
          </Headline>
          <IcCloseBlack28SVG onClick={onClose} />
        </Row>
        <Caption2
          weight={'regular'}
          color={'red'}
          style={{ wordBreak: 'keep-all', whiteSpace: 'pre-wrap', marginTop: 12, marginBottom: 12 }}
        >
          유의사항을 반드시 확인 바라며 미숙지로 인한 피해는 주만사에서 책임지지 않습니다.
        </Caption2>

        <WrapDesc>
          <Caption2 weight={'semibold'} color={'black3'}>
            1. 거주자 우선 공유 주차장으로 거주자 출차 요청 불 이행 시 단속 및 견인 조치 될 수
            있습니다.
          </Caption2>
          <Caption2 weight={'semibold'} color={'black3'} style={{ marginTop: 10 }}>
            2. 등록한 차량 정보와 실제 주차정보가 다를 경우 단속될 수 있습니다.
          </Caption2>
          <Caption2 weight={'semibold'} color={'black3'} style={{ marginTop: 10 }}>
            3. 주차 시작 클릭시점부터 요금이 부과되며, 실제 이용시간보다 요금이 초과 될 수 있습니다.
          </Caption2>
          <Caption2 weight={'semibold'} color={'black3'} style={{ marginTop: 10 }}>
            4. 예약 주차는 지원하지 않으며, 부정주차 또는 현장 상황으로 주차가 지연될 수 있습니다.
          </Caption2>
          <Caption2 weight={'semibold'} color={'black3'} style={{ marginTop: 10 }}>
            5. 저공해차, 경차 등 별도 할인 없습니다.
          </Caption2>
        </WrapDesc>
        <Button
          backgroundColor={colors.primary}
          radius={4}
          style={{
            height: 46,
            width: 'calc(100%)',
            marginTop: 20,
          }}
          onClick={() => {
            onClose();
            navigate('/geojuParkingUsing', {
              state: {
                data: minyoung.slotList[CancelReasonStore.selectedReason ?? 0],
              },
            });
          }}
        >
          <Caption2 weight={'semibold'} color={'white'}>
            유의사항을 확인 하였습니다
          </Caption2>
        </Button>
      </Modal>
    </>
  );
};

export default GeojuCautionModal;
