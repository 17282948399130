import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '30px 20px 20px 20px',
});

export const ImageTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const ParkingTitleSpan = styled('span', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const OperatingHourContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
});

export const ParkingInfoSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const TimeOperatingHoursSpan = styled('span', {
  color: '#555',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
});

export const PaymentAccountDepositContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
});

export const PaymentAccountDepositContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});
