import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const ModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto', // 모달의 높이 설정
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateY(100%)', // 모달을 화면 아래로 숨김
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 25px 40px 25px',
  boxSizing: 'border-box',
  borderRadius: '20px 20px 0px 0px',
  zIndex: 9999,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' }, // 모달을 화면 위로 슬라이드
      false: { transform: 'translateY(100%)' }, // 화면 아래로 숨김
    },
  },
});

export const RoadWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.primary,
  width: 42,
  height: 42,
  borderRadius: 8,
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 5px 3px 0px rgba(0, 0, 0, 0.05)',
});
