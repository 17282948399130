import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const NotUseParkingContainer = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: colors.gray10,
  overflow: 'hidden',
});

export const NotPastParkingContainer = styled('div', {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.white,
  overflow: 'hidden',
});

export const TicketItemContainer = styled('div', {
  margin: '10px 20px 12px 20px',
  backgroundColor: '#FFF',
  borderRadius: 10,
  padding: '20px',
});

export const TicketTopContainer = styled('div', {});

export const TicketTypeContainer = styled('div', {
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'flex',
  variants: {
    ticketType: {
      seasonTicket: {
        backgroundColor: '#EBEBFB',
      },
      default: {
        backgroundColor: '#E3F0FD',
      },
    },
  },
  defaultVariants: {
    ticketType: '#EBEBFB',
  },
});

export const TicketContentsContainer = styled('div', {
  display: 'flex',
  marginTop: '20px',
  flexDirection: 'column',
});

export const PastTicketContentsContainer = styled('div', {
  display: 'flex',
  marginTop: '20px',
  flexDirection: 'column',
  marginLeft: '16px',
  marginRight: '20px',
  marginBottom: '26px',
});

export const TicketContentsDetailContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const MyParkingTicketPassTypeContainer = styled('div', {
  marginLeft: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
});

export const MyParkingTicketColumnContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const MyParkingTicketFlexStartContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

export const ToggleButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '20px',
  marginBottom: '28px',
});

export const ToggleContainer = styled('div', {
  border: '1px solid #E4E4E4',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '6px 10px',
  marginRight: '10px',

  variants: {
    isClicked: {
      true: {
        backgroundColor: '#47474A',
        borderColor: '#47474A',
      },
      false: {
        backgroundColor: 'transparent',
        borderColor: '#E4E4E4',
      },
    },
  },
  defaultVariants: {
    isClicked: false,
  },
});

export const ToggleSpan = styled('span', {
  color: '#E4E4E4',

  variants: {
    isClicked: {
      true: {
        color: '#FFFFFF',
      },
      false: {
        color: '#47474A',
      },
    },
  },
});

export const PastContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: 24,

  '&:last-child': {
    marginBottom: '40px',
  },
});

export const DivideContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const DivideLine = styled('div', {
  width: '1px',
  height: '100%',
  backgroundColor: '#EBEBFB',
});

export const PastContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 20px)',
  flex: 1,
});

export const PastStatusContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px',
  marginLeft: '16px',
  marginRight: '20px',
  alignItems: 'flex-start',
});

export const BorderBottomContainer = styled('div', {
  marginLeft: '16px',
  marginRight: '20px',
});

export const BorderBottomSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: 'normal',
  color: '#1A1A1A',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  maxWidth: '100%',
  borderBottom: '1px solid black',
  paddingBottom: '1px',
  marginTop: 5,
});

export const SpinnerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const YearMonthContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const ThreeMonthSpan = styled('span', {
  color: colors.gray05,
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',

  variants: {
    isThreeMonthHistory: {
      true: {
        color: colors.gray08,
        fontWeight: 600,
      },
      false: {
        color: colors.gray05,
        fontWeight: 400,
      },
    },
    isDisabled: {
      true: {
        color: colors.gray05,
      },
      false: {
        color: colors.gray08,
      },
    },
  },
});

export const SixMonthSpan = styled('span', {
  color: colors.gray05,
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',

  variants: {
    isSixMonthHistory: {
      true: {
        color: colors.gray08,
        fontWeight: 600,
      },
      false: {
        color: colors.gray05,
        fontWeight: 400,
      },
    },
    isDisabled: {
      true: {
        color: colors.gray05,
      },
      false: {
        color: colors.gray08,
      },
    },
  },
});

export const YearContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
