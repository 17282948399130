import { styled } from "@stitches/react";

export const ToastContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ToastContentsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(33, 37, 47, 0.80)',
  borderRadius: '8px',
  padding: '16px 20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  position: 'fixed',
  bottom: '20px',
  left: '20px',
  right: '20px',
  zIndex: 10001,

  variants: {
    isVisible: {
      true: {
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },

  '@media (min-width: 375px) and (min-height: 812px)': {
    bottom: '100px',
  },
})

export const ToastMessage = styled('span', {
  color: '#fff',
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
});

export const ToastImage = styled('img', {
  width: '24px',
  height: '24px',
  objectFit: 'cover',
  borderRadius: '4px',
});