import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Dialog = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  // transition: 'transform 0.3s ease-in-out',
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
});
export const Container = styled('div', {
  position: 'relative',
  bottom: 0,
  left: 0,
  right: 0,
  height: '90vh',
  width: '100%',
  background: '#fff',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  zIndex: 11,
  variants: {
    isVisible: {
      true: { transform: 'translateY(0)' },
      false: { transform: 'translateY(100%)' },
    },
  },
  padding: '16px 0px 40px 0px',
  borderRadius: '30px 30px 0px 0px',
});

export const WrapDesc = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  backgroundColor: '#F6F7FB',
  padding: 15,
  borderRadius: 8,
  margin: '0px 20px',
});

export const ColorText = styled('div', {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 'normal',
  color: colors.gray08,
  '& span': {
    color: '#ED1659',
  },
});

export const WrapItem = styled('div', {
  overflowY: 'auto',
  height: '60vh',
  margin: '20px 0px 80px 0px',
});

export const Item = styled('div', {
  padding: 15,
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  margin: '0px 25px',
});

export const WrapBottom = styled('div', {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
