import { IUserLogin } from 'models/Login/loginModel';
import { loginService } from 'service/Login/loginService';
import BaseError from 'models/baseError';
import { makeAutoObservable } from 'mobx';

class LoginStore {
  isZmsLoginLoading = false
  error_msg: string | null = null
  isLoginSuccessful = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsZmsLoginLoading(value: boolean) {
    this.isZmsLoginLoading = value
  }

  setErrorMsg(value: string) {
    this.error_msg = value
  }

  setIsLoginSuccessful(value: boolean) {
    this.isLoginSuccessful = value;
  }

  async fetchUserLogin(
    requestBody: IUserLogin, iv: string, salt: string
  ) {
    this.setIsZmsLoginLoading(true);
    this.error_msg = null;

    try {
      const response = await loginService.postUserLogin(requestBody, iv, salt);

      if (response instanceof BaseError) {
        console.log("Received BaseError:", response);
        this.setErrorMsg(response.error_msg);
        this.setIsLoginSuccessful(false)
      } else {
        this.setIsLoginSuccessful(true)
      }
    } catch (error) {
      this.error_msg = '주만사 로그인 중 오류가 발생했습니다.';
      this.setIsLoginSuccessful(false)
    } finally {
      this.isZmsLoginLoading = false
    }
  }
}

export const loginStore = new LoginStore()
