import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Container, UserInfoInput } from './styles';
import Row from 'components/atoms/Row';
import { Caption2, SubHeadline } from 'styles/typography';
import Title from 'components/atoms/Title/PageTitle';
import {
  BottomButtonContainer,
  ButtonEnableDisable,
  CertificationContainer,
} from 'pages/DeleteAccount/DeleteAccountCert/styles';
import Button from 'components/atoms/Button/Button';
import { colors } from 'styles/colors';
import { IcCloseCircleGraySVG, IcDeleteTextSVG } from 'assets/ic';
import { useToast } from 'components/atoms/Toast/ToastProvider';
import { handleChatClick, moveJSP } from 'utils/common';
import { MainContainerNoMargin } from 'components/styles';
import { identityVerificationStore } from 'stores/store/Auth/IdentityVerificationStore';
import { encTextWord, getRandomString } from 'utils/cryptoJS';
import CustomBottomModal from 'components/atoms/Modal/CustomBottomModal';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import { ResponseStatus } from 'enums/responseState';
import icFailCheckRed from 'assets/ic/ic_fail_check_red.svg';
import icSuccessCheckGreen from 'assets/ic/ic_success_check_green.svg';
import { authCodeStore } from 'stores/store/Auth/authCodeStore';

interface IFormInputs {
  certification: string;
  phone: string;
}

const IdentityVerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { iv, salt } = getRandomString();

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [isCertification, setIsCertification] = useState(false);
  const [time, setTime] = useState(180);
  const [isFocused, setIsFocused] = useState(false);
  const [isCertificationFocused, setIsCertificationFocused] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const { memberUID } = userInfoStore;

  const { register, handleSubmit, watch, setValue } = useForm<IFormInputs>({
    defaultValues: {
      phone: '',
      certification: '',
    },
  });

  const phone = watch('phone', '');
  const certification = watch('certification', '');
  const phoneEnc = encTextWord(phone, iv, salt);
  const isPhoneValid = /^010\d{8}$/.test(phone);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (/[^0-9]/.test(value)) {
      value = value.replace(/[^0-9]/g, '');
    }
    setValue('phone', value);
  };

  const handleCertificationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (/[^0-9]/.test(value)) {
      value = value.replace(/[^0-9]/g, '');
    }

    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    e.target.value = value;

    setValue('certification', value);
  };

  const handleClearPhoneNumberInput = () => {
    setValue('phone', '', { shouldDirty: false, shouldTouch: false });
    document.getElementById('phone')?.focus();
  };

  const handleClearCertNumberInput = () => {
    setValue('certification', '', { shouldDirty: false, shouldTouch: false });
    document.getElementById('certification')?.focus();
  };

  useEffect(() => {
    if (phone === '') {
      document.getElementById('phone')?.focus();
    }
  }, []);

  useEffect(() => {
    if (time === 0) {
      showToast('인증 시간이 지났어요. [전송]을 눌러 주세요.', icFailCheckRed);
      (document.activeElement as HTMLElement).blur();
    }
  }, [time]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleLeftOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      handleLeftOnClick();
    };
    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  useEffect(() => {
    if (window) {
      let prevVisualViewport = window.visualViewport?.height;
      const handleVisualViewportResize = () => {
        const currentVisualViewport = Number(window.visualViewport?.height);
        if (prevVisualViewport && prevVisualViewport - 30 > currentVisualViewport) {
          setTimeout(() => {
            window.scrollBy({
              top: -66,
              behavior: 'smooth',
            });
          }, 0);
        }
        // 키보드가 닫히면 document.body의 position을 원래대로 복구
        if (prevVisualViewport && currentVisualViewport > prevVisualViewport) {
        }

        prevVisualViewport = currentVisualViewport;
      };

      if (window.visualViewport) {
        window.visualViewport.onresize = handleVisualViewportResize;
      }
    }
  }, []);

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    console.log('폼 데이터:', data);

    if (!isCertification) {
      return;
    }

    const res = await identityVerificationStore.fetchValidateAuthAndModifyMemberTell(
      phoneEnc,
      memberUID,
      data.certification,
      iv,
      salt,
    );

    if (res?.res_code === 500) {
      if (res?.err_msg === '회원 전화번호 수정 실패') {
        setIsDuplicate(true);
      } else if (res?.err_msg === '인증번호 검증에 실패했습니다.') {
        showToast('인증번호가 틀렸어요.', icFailCheckRed);
      }
    } else {
      showToast('번호를 변경했어요.', icSuccessCheckGreen);
      moveJSP();
    }
  };

  const getCode = async () => {
    await authCodeStore.fetchAuthCode({ memberTell: phoneEnc }, iv, salt);

    setValue('certification', '', { shouldDirty: false, shouldTouch: false });
    setIsCertification(true);

    setTimeout(() => {
      document.getElementById('certification')?.focus();
    }, 0);

    setTime(180);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 0) {
          if (timerRef.current) clearInterval(timerRef.current);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const closeModal = () => {
    setIsDuplicate(false);
  };

  return (
    <MainContainerNoMargin>
      <Title
        title="본인 인증"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: 20 }}>
          <CertificationContainer isFocused={isFocused}>
            <Row justifyContent={'space-between'}>
              <SubHeadline weight={'bold'} color={'primary'}>
                휴대폰 번호
              </SubHeadline>
            </Row>
            <Row justifyContent={'space-between'} style={{ marginTop: 8 }}>
              <UserInfoInput
                type="tel"
                placeholder={'휴대폰 번호를 입력해주세요.'}
                id={'phone'}
                {...register('phone', { required: '필수 입력 항목입니다.' })}
                disabled={isCertification}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={handlePhoneChange}
                maxLength={11}
              />
              {!isCertification && (
                <div
                  onClick={() => {
                    handleClearPhoneNumberInput();
                  }}
                >
                  <IcDeleteTextSVG />
                </div>
              )}
            </Row>
          </CertificationContainer>
          {isCertification ? (
            <CertificationContainer isFocused={isCertificationFocused}>
              <Row justifyContent={'space-between'}>
                <SubHeadline weight={'bold'} color={'primary'}>
                  인증 번호
                </SubHeadline>

                {time !== 0 ? (
                  <Caption2 weight={'regular'} color={'gray06'}>
                    {formatTime(time)}
                  </Caption2>
                ) : null}
              </Row>
              <Row justifyContent={'space-between'} style={{ marginTop: 8, width: '100%' }}>
                <UserInfoInput
                  type="number"
                  placeholder={isCertification ? '6자리를 입력해 주세요' : '[전송]을 눌러주세요'}
                  id="certification"
                  maxLength={6}
                  {...register('certification', { required: '필수 입력 항목입니다.' })}
                  onFocus={() => setIsCertificationFocused(true)}
                  onChange={handleCertificationChange}
                />

                <div
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    handleClearCertNumberInput();
                  }}
                >
                  <IcCloseCircleGraySVG />
                </div>
                <Button
                  horizontal={12}
                  style={{ height: 32 }}
                  backgroundColor={isCertification && time > 150 ? colors.gray05 : colors.primary}
                  radius={4}
                  onClick={async () => {
                    setValue('certification', '');
                    try {
                      await getCode();
                    } catch (error) {
                      console.error('Error while getting the code:', error);
                    }
                  }}
                  disabled={isCertification && time > 150}
                >
                  <Caption2 weight={'semibold'} color={'white'} style={{ whiteSpace: 'nowrap' }}>
                    {isCertification && time !== 0 ? '재전송' : '전송'}
                  </Caption2>
                </Button>
              </Row>
            </CertificationContainer>
          ) : null}
        </form>
        <BottomButtonContainer>
          {isPhoneValid && !isCertification ? (
            <ButtonEnableDisable
              onClick={async () => {
                await getCode();
              }}
            >
              인증번호 보내기
            </ButtonEnableDisable>
          ) : isCertification && time !== 0 && certification.length === 6 ? (
            <ButtonEnableDisable onClick={handleSubmit(onSubmit)}>인증하기</ButtonEnableDisable>
          ) : null}
        </BottomButtonContainer>
      </Container>

      <CustomBottomModal
        isVisible={isDuplicate}
        onClose={() => {}}
        title1={'고객센터 문의가 필요해요.'}
        description={
          '해당 번호는 이전에 가입했던 회원의 휴대폰 번호예요.\n이 번호의 실제 사용자라면 문의해 주세요.'
        }
        leftClick={closeModal}
        leftText={'취소'}
        rightClick={() => {
          handleChatClick();
          closeModal();
        }}
        rightText={'문의하러 가기'}
      />
    </MainContainerNoMargin>
  );
};

export default IdentityVerificationPage;
