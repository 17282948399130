import { styled } from '@stitches/react';

export const RoadViewContainer = styled('div', {
  width: '100%',
  height: '100vh',
  bottom: 0,
  top: 0,
  position: 'fixed',
})

export const NotRoadViewContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'red',
  fontSize: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  position: 'fixed',
  top: 0,
  bottom: 0,
})