import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from 'components/atoms/Title/PageTitle';
import MyPageList from 'containers/my/MyPageList';
import { MainContainer, MainContainerNoMargin } from 'components/styles';
import { moveJSP } from 'utils/common';

const MyPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLeftOnClick = () => {
    moveJSP();
    // navigate('/home');
  };

  useEffect(() => {
    window.onBtnBackClicked = function () {
      moveJSP();
      // handleLeftOnClick();
    };

    return () => {
      delete window.onBtnBackClicked;
    };
  }, []);

  return (
    <MainContainerNoMargin>
      <Title
        title="내 정보"
        leftArrowButtonVisible={true}
        leftOnClick={handleLeftOnClick}
        rightTextButtonVisible={false}
      />

      <div
        style={{
          margin: '0px 20px',
        }}
      >
        <MyPageList />
      </div>
    </MainContainerNoMargin>
  );
};
export default MyPage;
