import { styled } from '@stitches/react';
import icCheck from 'assets/ic/ic_check_white.svg';

export const TerraceTowerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
});

export const TerraceCautionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '28px 20px 20px 20px',
});

export const TerraceCautionColumnContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const TerraceCautionIcon = styled('img', {
  width: '50px',
  height: '56px',
});

export const AddressContainer = styled('div', {
  marginTop: '8px',
  display: 'flex',
});

export const ViewDivide1 = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
  marginTop: '8px',
});

export const ViewDivide2 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '16px',
});

export const TicketTimeInfoContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  marginTop: '28px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '20px',
});

export const TicketTimeInfoDetailContainer = styled('div', {
  backgroundColor: '#F8F8FA',
  margin: '28px 20px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const TicketSpan = styled('span', {
  margin: '20px 20px',
  color: '#333',
  fontSize: '16px',
  fontWeight: 600,
});

export const ViewDivide3 = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  margin: '20px 0px',
});

export const CarParkingInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  // padding: '20px',
});

export const CarNumberParkingDateSpan = styled('span', {
  color: '#555',
  fontSize: '15px',
  fontWeight: 500,
});

export const ParkingInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  variants: {
    isFirst: {
      true: {
        marginTop: '0px',
      },
      false: {
        marginTop: '8px',
      },
    },
  },
});

export const CautionsAndUseSlotGuideListContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    first: {
      true: { marginTop: '20px' },
      false: {
        marginTop: '10px',
      },
    },
  },
});

export const ListCheckContainer = styled('div', {
  marginTop: '20px',
});

export const ListItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  variants: {
    hasMargin: {
      true: { marginTop: '10px' },
      false: { marginTop: '0' },
    },
  },
  wordBreak: 'keep-all',
});

export const UseSlotGuideListItemDotIc = styled('img', {
  alignSelf: 'flex-start',
  marginTop: 8,
  marginLeft: 8,
  marginRight: 8,
});

export const CautionListItemDotIcon = styled('img', {
  alignSelf: 'flex-start',
  marginTop: 8,
  marginRight: 8,
});

export const CautionListContainer = styled('div', {
  marginTop: 20,
});

export const ListItemCheckIc = styled('img', {
  alignSelf: 'flex-start',
});

export const ListItemSpan = styled('span', {
  marginLeft: '8px',
});

export const SubTitleSpan = styled('span', {
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const PaymentInfoContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ReceiptContainer = styled('div', {
  height: '28px',
  display: 'flex',
  paddingLeft: '14px',
  paddingRight: '14px',
  flexDirection: 'row',
  borderRadius: '14px',
  border: '1px solid #CCC',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ReceiptIc = styled('img', {
  width: '13px',
  height: '14px',
});

export const ReceiptSpan = styled('span', {
  color: '#555',
  fontSize: '13px',
  fontWeight: 'normal',
  marginLeft: '4px',
});

export const PaymentItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: '#868686',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  variants: {
    isFirst: {
      true: { marginTop: '20px' },
      false: { marginTop: '10px' },
    },
  },
});

export const PaymentValue = styled('span', {
  fontSize: '15px',
  fontWeight: 500,
  variants: {
    isUsePayment: {
      true: { color: '#8B63BB', fontSize: '15px' }, // 첫 번째 항목 스타일
      false: { color: '#555', fontSize: '15px' }, // 나머지 항목 스타일
    },
  },
});

export const PaymentCancelSpan = styled('span', {
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: '#6B6B6B',
});

export const BottomButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  margin: '0 20px 40px',
  width: 'calc(100% - 40px)',
  bottom: '0',
  left: '0',
  textAlign: 'center',
  position: 'fixed',
  zIndex: 10,
  maxWidth: '440px',
  '@media (max-height: 667px)': {
    position: 'relative',
  },
});

export const PaymentCancelContainer = styled('div', {
  width: '100%',
  height: '46px',
  display: 'flex',
  marginTop: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  boxSizing: 'border-box',
});

export const BottomLeftButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
});

export const BottomRightButton = styled('button', {
  flex: 1,
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
});

export const TerraceTowerBottomContentsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '28px 20px 40px',
});

export const BottomDifferentParkingTicketButton = styled('button', {
  width: '100%',
  height: '46px',
  fontSize: '14px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#6B6B6B',
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  justifyContent: 'center',
});

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  width: '20px',
  height: '20px',
  border: '2px solid #E6E6E6',
  borderRadius: '4px',
  outline: 'none',

  marginRight: '8px', // 체크 박스와 레이블 사이의 간격
  '&:checked': {
    backgroundColor: '#8B63BB',
    borderColor: '#8B63BB',
    backgroundImage: `url(${icCheck})`, // 불러온 이미지를 URL로 설정
    backgroundSize: '12px 12px', // 이미지 크기
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&:focus': {
    borderColor: '#8B63BB',
  },
});

export const CautionContainer = styled('div', {
  padding: '10px 16px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '10px',
});

export const CautionSpan = styled('span', {
  marginTop: '4px',
});

export const WarningContainer = styled('div', {
  display: 'flex',
  padding: '12px',

  backgroundColor: '#F8F8FA',
  borderRadius: '4px',
  alignItems: 'center', // 상단 정렬
  justifyContent: 'flex-start', // 왼쪽 정렬
  gap: '10px', // 이미지와 텍스트 간격 조정
  '&:first-child': {
    marginTop: 0,
  },
  marginTop: '10px',
});

export const AgreeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '20px 20px 28px',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const WarningSpan = styled('span', {
  color: '#ED1659',
});

export const BlueColorSpan = styled('span', {
  color: '#1D75CA',
});

export const BlueCautionSpan = styled('span', {
  marginTop: '10px',
  color: '#1D75CA',
});

// 나중에 변경 필요하기 때문에 컨테이너 이름을 TimeTicket 으로 설정 합니다
export const TimeTicketNotCautionContainer = styled('div', {
  marginTop: 20,
  display: 'flex',
  flexDirection: 'row',
});
