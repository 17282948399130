import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalContainer, PlacePhotoWeekContainer, PlaceWeekContainer, Badge } from './styles';
import { Body, Caption2, Caption3, Caption4 } from 'styles/typography';
import Row from 'components/atoms/Row';
import { colors } from 'styles/colors';
import { dailyDetail } from 'containers/home/HomeMap/mock';
import Button from 'components/atoms/Button/Button';
import { openNavi } from 'utils/common';
import { userInfoStore } from 'stores/store/UserInfo/userInfoStore';
import Divider from 'components/atoms/Divider';
import { formatPriceWithCommas } from 'utils/number';

import { IcDirections16SVG } from 'assets/ic';
import icZoomansaLogo from 'assets/ic/ic_zoomansa_logo.svg';
import TimeTicketDetailModal from 'components/atoms/Modal/TimeTicketDetailModal';

interface TimeTicketInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const TimeTicketInfoModal: React.FC<TimeTicketInfoModalProps> = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const { memberData } = userInfoStore;

  const data = dailyDetail;

  if (!isVisible) return null;

  return (
    <ModalContainer
      onClick={e => {
        e.stopPropagation();
        // setIsTimeTicketDetail(true);
        onClose();
      }}
    >
      <div>
        <Button
          backgroundColor={'#404246'}
          radius={16}
          style={{
            position: 'absolute',
            right: 0,
            top: -42,
            height: 32,
          }}
          onClick={() => {
            const name = data.lotName;
            const lat = data.latitude;
            const lng = data.longitude;
            openNavi(String(lat), String(lng), name, memberData?.naviType ?? '');
          }}
        >
          <Row>
            <IcDirections16SVG fill={'#fff'} />
            <Caption2 weight={'semibold'} color={'white'} style={{ marginLeft: 4 }}>
              길찾기
            </Caption2>
          </Row>
        </Button>

        <PlacePhotoWeekContainer>
          <PlaceWeekContainer>
            <Body weight={'bold'} color={'black1'}>
              {data.lotName}
            </Body>
            <Row style={{ marginTop: 8 }}>
              <Caption4 weight={'semibold'} color={data.sumCount ? 'primary' : 'gray08'}>
                {data.sumCount ? '판매중' : '품절'}
              </Caption4>
              <Caption4
                weight={'semibold'}
                color={'gray06'}
                style={{ marginLeft: 4, marginRight: 2 }}
              >
                |
              </Caption4>
              <Caption4 weight={'semibold'} color={'gray06'}>
                {data.slotCount}면
              </Caption4>
            </Row>
          </PlaceWeekContainer>
          {/*<div>*/}
          {/*  <img>*/}
          {/*</div>*/}
        </PlacePhotoWeekContainer>

        <Row style={{ marginTop: 13 }}>
          <Badge style={{ border: `1px solid ${colors.primary}` }}>
            <img
              style={{
                width: '12px',
                height: '12px',
                marginRight: 4,
              }}
              src={icZoomansaLogo}
              alt={'주만사 아이콘'}
            />
            <Caption3 weight={'semibold'} color={'primary'}>
              공유
            </Caption3>
          </Badge>

          <Badge style={{ marginLeft: 4, backgroundColor: '#F2F4F7' }}>
            <Caption4 weight={'semibold'} color={'gray07'}>
              카드결제
            </Caption4>
          </Badge>
        </Row>
      </div>
      <Divider style={{ backgroundColor: colors.gray01, marginTop: 10, marginBottom: 10 }} />

      <Row>
        {data.productList.map((el, index) => {
          return (
            <Button
              radius={3}
              vertical={6}
              horizontal={11}
              style={{
                marginRight: 4,
              }}
              key={index}
              borderColor={colors.gray04}
            >
              <Caption3 weight={'medium'} color={'gray05'}>
                {el.productName} {formatPriceWithCommas(el.salePrice)}원
              </Caption3>
            </Button>
          );
        })}
      </Row>
    </ModalContainer>
  );
};

export default TimeTicketInfoModal;
