import { showWebBrowser } from 'utils/deviceUtils';
import { JSP_LOGIN_URL, JSP_URL } from 'constants/baseConstants';

export const pxToRem = (px: number, base: number = 16): string => {
  return `${px / base}rem`;
};

export const handleChatClick = () => {
  showWebBrowser('https://pf.kakao.com/_UxdeGK');
};

export const idRegex = /^[a-z0-9_-]{5,20}$/;

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

export function phoneNumberCheck(phone: string) {
  let result = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  return result.test(phone);
}

export const isValidPhoneNumber = (phone: string): boolean => {
  return /^010\d{8}$/.test(phone) && phone.length === 11;
};

export const isValidName = (name: string): boolean => {
  return /^[가-힣]{2,}$/.test(name); // 2글자 이상의 한글만 허용
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export const moveJSP = () => {
  window.location.href = JSP_URL ?? '';
};

export const moveLoginJSP = () => {
  window.location.href = JSP_LOGIN_URL ?? '';
};

export const openNavi = (lat: string, lon: string, lotName: string, navType: string | null) => {
  if (navType === 'NAVER') {
    if (window.arsparkingapp) {
      window.arsparkingapp.openNaverNavi(lat, lon, lotName);
    }

    if (window.webkit) {
      window.webkit.messageHandlers.arsparkingapp.postMessage({
        func: 'openNaverNavi',
        lat: lat,
        lon: lon,
        lotName: lotName,
      });
    }
  } else {
    // @ts-ignore
    Kakao.Navi.start({
      name: lotName,
      x: Number(lat),
      y: Number(lon),
      coordType: 'wgs84',
    });
  }
};

export const formatDateToYYYYMMDDHHmmss = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
