export const API_URL = process.env.REACT_APP_API_URL;
export const JSP_URL = process.env.REACT_APP_JSP_URL;
export const JSP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const JQUERY_SCRIPT_URL = 'https://code.jquery.com/jquery-3.6.0.min.js';
export const INNOPAY_SCRIPT_URL = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
export const TERRACE_PAYMENT_RETURN_URL = process.env.REACT_APP_TERRACE_RESULT_URL;
// 주만사 개인정보 처리 방침 이전 버전
export const PAST_POLICY_PERSONAL =
  'https://zooparking.notion.site/2023-12-01-ver-14ab9c4fccce80619f56d26d271f92f0';
// 주만사 서비스 이용약관 이전 버전
export const PAST_POLICY_SERVICE =
  'https://zooparking.notion.site/2024-12-16-13bb9c4fccce80c297d1c2af9ac2f97d';
export const BANNER1_URL = 'http://hostlanding.zoomansa.com?type=ars이용완료';
export const BANNER2_URL = 'http://guestlanding.zoomansa.com?type=ars이용완료';

// 애플로그인, APPLE_REDIRECT_URI 는 API 엮고 로그인 으로변경 그리고 해당 주석 지워주세요
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const APPLE_SCOPE = 'email';
const krTerraceTowerReservationCaution =
  'https://test.web.zoomansa.co.kr/terraceTowerReservationCaution';
export const APPLE_REDIRECT_URI = krTerraceTowerReservationCaution;
export const APPLE_STATE = process.env.REACT_APP_APPLE_STATE;
export const APPLE_NONCE = process.env.REACT_APP_APPLE_NONCE;

export const MONTHLY_PAYMENT_URL = process.env.REACT_APP_MONTHLY_PAYMENT_URL;

// 네이버 로그인 REDIRECT_URI 는 API 엮고 로그인 으로변경 그리고 해당 주석 지워주세요
export const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_SEARCH_CLIENT_ID;

export const OAUTH_REDIRECT_URI = 'http://192.168.0.198:3000/login';

export const STATE = Math.random().toString(36).substring(2, 15);
export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${OAUTH_REDIRECT_URI}`;

export const LOCATION_MARKER =
  'https://parking.zoomansa.com/datafiles/upload/img_marker_locate.svg';
export const PARKING_LOCATION_MARKER =
  'https://parking.zoomansa.com/datafiles/upload/img_marker_parking.png';
export const AREA_LOCATION_MARKER =
  'https://parking.zoomansa.com/datafiles/upload/img_area_marker.svg';
