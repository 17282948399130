import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const Container = styled('div', {
  padding: '0px 20px 28px 20px',
});

export const ToggleStyled = styled('div', {});

export const RoadViewImageViewContainer = styled('div', {
  height: '180px',
  backgroundColor: '#F8F8FA',
  margin: '10px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '5px',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});
