import React, { startTransition, useEffect, useRef, useState } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps } from 'react-naver-maps';
import { PARKING_LOCATION_MARKER } from 'constants/baseConstants';

interface MapProps {
  height?: string;
  lat: number;
  lng: number;
}

const ParkingLocation: React.FC<MapProps> = ({ height = '100vh', lat, lng }) => {
  const navermaps = useNavermaps();
  const [marker, setMarker] = useState<any>(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    if (map && navermaps && lat && lng) {
      startTransition(() => {
        handleMarker();
      });
    }
  }, [map, navermaps, lat, lng]);

  const handleMarker = async () => {
    if (!map || !navermaps) {
      console.warn('지도 객체가 아직 초기화되지 않았습니다.');
      return;
    }
    try {
      const latLng = new navermaps.LatLng(lat, lng);

      // 기존 마커 삭제
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }

      map.setCenter(latLng);
      map.setZoom(15, true);

      // 새로운 마커 생성
      const newMarker = new navermaps.Marker({
        position: latLng,
        map: map,

        icon: {
          content: `
            <div style="
              width: 52px; /* 원하는 크기로 설정 */
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
              <img 
                src="${PARKING_LOCATION_MARKER}" 
                style="
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                " 
              />
            </div>
          `,
          size: new navermaps.Size(52, 52), // 이미지 크기
          anchor: new navermaps.Point(26, 26),
        },
      });

      setMarker(newMarker);
    } catch (error) {
      console.error('주소 검색 중 오류 발생:', error);
    }
  };

  return (
    <MapDiv
      style={{
        width: '100%',
        height,
        borderRadius: 8,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <NaverMap
        ref={setMap}
        draggable={false}
        pinchZoom={false}
        mapTypeControl={false}
        scrollWheel={false}
        logoControl={false}
        disableDoubleClickZoom={true}
        disableDoubleTapZoom={true}
      ></NaverMap>
    </MapDiv>
  );
};

export default ParkingLocation;
