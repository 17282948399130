import React, { useEffect } from 'react';

import { WrapDesc } from './styles';
import { WrapDescProps } from 'models/Notice/NoticeModel';

import './text.css';
import { moveToPage } from 'utils/deviceUtils';

const Content: React.FC<WrapDescProps> = ({ children }) => {
  function convertLineBreaksToHTML(children: string) {
    // 줄바꿈 문자를 <br />로 변환
    let formattedText = children.replace(/(\r\n|\n|\r)/g, '<br />');

    return formattedText;
  }

  useEffect(() => {
    // 이벤트 리스너 추가
    const links = document.querySelectorAll('.custom-content a') as NodeListOf<HTMLAnchorElement>;
    links.forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    // 클린업 함수 (컴포넌트가 언마운트될 때 이벤트 리스너 제거)
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [children]);

  const handleLinkClick = (event: Event) => {
    const target = event.target as HTMLAnchorElement;
    const pageURL = target.href; // 클릭된 링크의 href 값 가져오기
    event.preventDefault(); // 기본 동작인 링크 이동을 방지
    moveToPage(pageURL); // 링크 클릭 시 moveToPage 실행
  };

  return (
    <WrapDesc
      className={'custom-content'}
      dangerouslySetInnerHTML={{
        __html: convertLineBreaksToHTML(children), // HTML을 문자열로 전달
      }}
    />
  );
};

export default Content;
