import { styled } from '@stitches/react';
import { colors } from 'styles/colors';

export const ParkingNumberSpan = styled('div', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const ImageTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  margin: '12px 8px 0px 0px',
});

export const TopParkingInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 0px 20px',
});

export const TopParkingInfoSpan = styled('span', {
  marginLeft: '8px',
});

export const ParkingIcon = styled('img', {
  marginRight: '8px',
});

export const ViewDivide1 = styled('div', {
  width: '100%',
  height: '6px',
  backgroundColor: '#F8F8FA',
  marginTop: '20px',
});

export const CenterContentsContainer = styled('div', {
  margin: '25px',
  textAlign: 'center',
});

export const ExclamationIcon = styled('img', {
  width: '20px',
  height: '20px',
  marginLeft: 'auto',
});

export const ToggleStyled = styled('div', {
  marginTop: '20px',
});

export const RoadViewImageViewContainer = styled('div', {
  height: '180px',
  backgroundColor: '#F8F8FA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '5px',
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  position: 'relative',
});

export const ParkingInfoSpan = styled('span', {
  color: '#8B63BB',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const TimeOperatingHoursSpan = styled('span', {
  color: '#1A1A1A',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const DiscountAmountSpan = styled('span', {
  color: '#B0B0B0',
  fontFamily: 'Pretendard',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '13px',
  textDecoration: 'line-through',
});

export const PaymentAccountDepositContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
});

export const PaymentAccountDepositContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const ParkingTitleSpan = styled('span', {
  color: '#6B6B6B',
  fontFamily: 'Pretendard',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

export const OperatingHourContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
});

export const WeekContainer = styled('div', {
  backgroundColor: '#8B63BB',
  color: '#fff',
  borderRadius: '20px',
  textAlign: 'center',
  padding: '2px 5px',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const DepositStatusContainer = styled('div', {
  backgroundColor: '#EBEBFB',
  color: '#8B63BB',
  borderRadius: '5px',
  textAlign: 'center',
  padding: '5px 5px',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const WeekInfoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px',
  marginTop: '10px',
});

export const ParkingInfoStatusContainer = styled('div', {
  display: 'flex',
  overflowX: 'auto',
  // padding: '10px ',
  whiteSpace: 'nowrap',
  scrollBehavior: 'smooth',
  marginTop: '25px',
});

export const ParkingInfoIconStyled = styled('img', {
  height: '60px',
  marginRight: '10px',
});

export const BottomButtonContainer = styled('div', {
  position: 'fixed',
  width: '100%',
  height: '106px',
  bottom: '0',
  left: '0',
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
});

export const ButtonEnableDisable = styled('button', {
  display: 'flex',
  margin: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: 'calc(100% - 40px)',
  height: '46px',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 'normal',
  borderRadius: '5px',
  backgroundColor: '#8B63BB',
  fontFamily: 'Pretendard',
  color: '#fff',
  border: 'none',
  cursor: 'none',
});

export const RoadViewContainer = styled('div', {
  width: '100%',
  height: 180,
  marginTop: 10,
  borderRadius: 5,
});

export const SliderIndicator = styled('div', {
  width: 52,
  height: 18,
  position: 'absolute',
  bottom: 10,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.black3,
  opacity: 0.5,
  whiteSpace: 'pre-line',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
