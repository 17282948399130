import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import icRoadView from 'assets/ic/ic_road_view.svg';
import icNotImage from 'assets/ic/ic_not_image.svg';
import {
  PhotoCountContainer,
  RoadViewContainer,
  RoadViewIcon,
  RoadViewSpan,
  RoadViewTextContainer,
  UploadedImage,
  UploadedImageContainer,
  UploadImageSection,
} from 'containers/myParkingTicket/styles';

interface UploadImageSectionProps {
  photoAvailableYN: boolean;
  images: { src: string; alt: string }[];
}

const RoadView: React.FC<UploadImageSectionProps> = ({ photoAvailableYN, images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index)
  }

  const slideSettings = {
    dots: false,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    zIndex: 1,
    arrows: false,
    afterChange: handleAfterChange,
  }

  return (
    <div>
      {photoAvailableYN ? (
        <UploadImageSection>
          <RoadViewTextContainer>
            <RoadViewIcon src={icRoadView} alt="로드뷰 아이콘" />
            <RoadViewSpan>로드뷰</RoadViewSpan>
          </RoadViewTextContainer>
          <PhotoCountContainer>{currentSlide + 1}/3</PhotoCountContainer>
          <Slider {...slideSettings}>
            {images.map((image, index) => (
              <UploadedImageContainer key={index}>
                <UploadedImage src={image.src} alt={image.alt} />
              </UploadedImageContainer>
            ))}
          </Slider>
        </UploadImageSection>
      ) : (
        <RoadViewContainer>
          <img src={icNotImage} alt={'이미지 없는 아이콘'} />
          <span style={{ marginTop: '10px' }}>이미지 준비중이에요</span>
        </RoadViewContainer>
      )}
    </div>
  );
};

export default RoadView;
