import { styled, keyframes } from '@stitches/react';
import 'react-calendar/dist/Calendar.css';

// 슬라이드 업 애니메이션
export const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)', opacity: 0 },
  '100%': { transform: 'translateY(0)', opacity: 1 },
});

export const ModalContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  backgroundColor: '#fff',
  borderRadius: '8px',
  width: 'calc(100% - 40px)',
  maxWidth: '480px',
  margin: '20px',
  marginBottom: '40px',
  padding: '16px',
  border: '2px solid #E4E4E4',
  animation: `${slideUp} 0.3s ease-out`,
  zIndex: 10000,
});

export const MinganPlacePhotoWeekContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MinganPlaceWeekContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const MinganParkingKindContainer = styled('div', {
  borderBottom: '1px solid #E6E6E6',
  marginTop: '10px',
  paddingBottom: '13px',
  display: 'flex',
  flexDirection: 'row',
});

export const MinganKindListContainer = styled('div', {
  fontSize: '13px',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  color: '#E6E6E6',
  marginRight: '4px',
});

export const MinganListSpan = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '3px',
  border: '1px solid #E6E6E6',
  padding: '3px 9px',
  textAlign: 'center',
  color: '#868686',
});

export const AmountContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '8px',
  alignItems: 'center',
});

export const Badge = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  padding: '2px 9px',
  borderRadius: 3,
  marginRight: 6.5,
});
